define("@vollersgroup/hub-frontend/admin/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "@vollersgroup/hub-frontend/mixins/reset-scroll"], function (_exports, _authenticatedRouteMixin, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The controller for admin route.
   *
   * @namespace Route
   * @extends Ember.Route
   * @uses Mixin.ResetScrollMixin
   * @uses Mixin.AuthenticatedRouteMixin
   */
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _resetScroll.default, {
    // -------------------------------------------------------------------------
    // Dependencies
    userSession: Ember.inject.service(),
    toast: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Renders templates for admin route manually.
     * Per default, it renders secondary nav items
     * from manage pod to `secondary-nav` outlet.
     *
     * @public
     * @method renderTemplate
     * @return {Void}
     */
    renderTemplate: function renderTemplate() {
      this.render();
      this.render('admin/manage/navbar', {
        outlet: 'secondary-nav',
        into: 'admin'
      });
    },

    /**
     * Loads available shops for user.
     *
     * @public
     * @method model
     * @return Ember.RSVP.Promise
     */
    model: function model() {
      return Ember.get(this, 'availableData.shops');
    },

    /**
     * Checks if user is authenticated and
     * in that case if a company is already
     * set up, otherwise redirects to setup
     * page.
     *
     * @public
     * @method afterModel
     * @return Ember.RSVP.Promise
     */
    afterModel: function afterModel() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var user = Ember.get(_this, 'userSession.currentUser');

        if (Ember.isNone(user)) {
          reject();
          return;
        }

        user.get('company').then(function (company) {
          if (Ember.isEmpty(company.get('location'))) {
            var location = _this.store.createRecord('location', {
              type: 'COMPANY'
            });

            Ember.set(company, 'location', location);
          }

          resolve();
        });
      });
    },

    /**
     * Resets `afterSave` query parameter.
     *
     * @public
     * @method resetController
     * @return {Void}
     */
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        Ember.set(controller, 'afterSave', null);
      }
    },
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Persists user model in DB from data.
       *
       * @event errors
       * @param {Object} data
       * @return {Void}
       * @private
       */
      saveCurrentUser: function saveCurrentUser(data) {
        var _this2 = this;

        var user = Ember.get(this, 'userSession.currentUser');
        user.setProperties({
          fullName: Ember.get(data, 'fullName'),
          dateFormat: Ember.get(data, 'dateFormat'),
          timeFormat: Ember.get(data, 'timeFormat'),
          timezone: Ember.get(data, 'timezone'),
          avatar: Ember.get(data, 'avatar'),
          locale: Ember.get(data, 'language')
        });

        if (!user.get('hasDirtyAttributes')) {
          return;
        }

        var successCallback = function successCallback() {
          Ember.get(_this2, 'toast').success(Ember.get(_this2, 'l10n').t('Your profile has been updated!'));
        };

        var failureCallback = function failureCallback() {
          Ember.get(_this2, 'toast').error(Ember.get(_this2, 'l10n').t('Could not save your profile!'));
          user.rollbackAttributes();
        };

        user.save().then(successCallback, failureCallback);
      },

      /**
       * Persists company model in DB from data.
       *
       * @event errors
       * @return {Void}
       * @private
       */
      saveCurrentCompany: function saveCurrentCompany() {
        var _this3 = this;

        var companyCallback = function companyCallback(company) {
          var successCallback = function successCallback() {
            Ember.get(_this3, 'toast').success(Ember.get(_this3, 'l10n').t('Your company has been updated!'));

            var params = _this3.paramsFor(_this3.routeName);

            var route = params.afterSave;

            if (!route) {
              return;
            }

            _this3.transitionTo(route, {});
          };

          var failureCallback = function failureCallback() {
            Ember.get(_this3, 'toast').error(Ember.get(_this3, 'l10n').t('Could not save company profile!'));
            company.rollbackAttributes();
          };

          company.save().then(successCallback, failureCallback);
        };

        Ember.get(this, 'userSession.currentCompany').then(companyCallback);
      }
    }
  });

  _exports.default = _default;
});