define("@vollersgroup/hub-frontend/admin/manage/overview/index/controller", ["exports", "@vollersgroup/hub-frontend/mixins/orderable"], function (_exports, _orderable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_orderable.default, {
    // -------------------------------------------------------------------------
    // Dependencies
    availableData: Ember.inject.service(),
    listingPublisher: Ember.inject.service(),
    keenTracking: Ember.inject.service(),
    can: Ember.inject.service(),
    router: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Query parameter definition.
     *
     * @property queryParams
     * @type {Array}
     * @public
     */
    queryParams: [{
      selectedShopId: {
        as: 'shop'
      }
    }, {
      selectedAuctionId: {
        as: 'auction'
      }
    }, {
      orderBy: {
        as: 'orderBy'
      }
    }, {
      filterStatus: {
        as: 'status'
      }
    }, {
      filterOrigins: {
        as: 'origins'
      }
    }, {
      filterWarehouses: {
        as: 'warehouses'
      }
    }, {
      page: {
        as: 'page'
      }
    }, {
      pageSize: {
        as: 'pageSize'
      }
    }, {
      showArchived: {
        as: 'archived'
      }
    }, {
      showRatings: {
        as: 'ratings'
      }
    }, {
      searchTerm: {
        as: 'term'
      }
    }, {
      salesNumber: {
        as: 'sales'
      }
    }],

    /**
     * The current page
     *
     * @property page
     * @public
     * @type Number
     */
    page: 1,

    /**
     * The current page size.
     *
     * @property pageSize
     * @public
     * @type Number
     */
    pageSize: 20,

    /**
     * The maximum amount of pages
     *
     * @property maxPages
     * @public
     * @type Number
     */
    maxPages: 1,

    /**
     * The total amount of listings over all pages.
     *
     * @property totalListings
     * @public
     * @type Number
     */
    totalListings: 0,

    /**
     * Hash map containing listings rating by `id`.
     * Filled by route's `afterModel()` hook with:
     *
     * {
     *   BAD: <number>
     *   SO_SO: <number>
     *   GOOD: <number>
     * }
     *
     * @public
     * @property ratings
     * @type Object
     */
    ratingsMap: Ember.computed(function () {
      return {};
    }),

    /**
     * Transformed listings from Ember RecordArray to
     * make collection mutable for the `copy` feature.
     *
     * @property listings
     * @public
     * @type Model.Listing[]
     */
    listings: Ember.computed('model.[]', {
      get: function get() {
        // we need mutable collection for copy feature,
        // so transform DS.RecordArray to mutable array
        // https://github.com/emberjs/data/issues/3530
        return Ember.get(this, 'model').toArray();
      }
    }),

    /**
     * Filtered listings for view taking any changes of
     * `status`, `archived` and `isDeleted` into account.
     *
     * @property filteredListings
     * @type Model.Listing[]
     */
    filteredListings: Ember.computed('filterStatus', 'listings.@each.{archived,isDeleted,shopId,status}', 'selectedShopId', 'showArchived', {
      get: function get() {
        var listings = Ember.get(this, 'listings');
        var showArchived = Ember.get(this, 'showArchived');
        var filterStatus = Ember.get(this, 'filterStatus');
        var selectedShopId = Ember.get(this, 'selectedShopId');

        var filterCallback = function filterCallback(listing) {
          var props = Ember.getProperties(listing, ['shopId', 'isDeleted', 'status', 'archived']);

          if (!Ember.isEmpty(filterStatus) && !filterStatus.includes(props.status)) {
            return false;
          }

          if (!Ember.isNone(selectedShopId) && selectedShopId != props.shopId) {
            return false;
          }

          if (props.archived !== showArchived) {
            return false;
          }

          if (props.isDeleted) {
            return false;
          }

          return true;
        };

        return listings.filter(filterCallback);
      }
    }),

    /**
     * The currently selected listings
     *
     * @property selectedListings
     * @type Model.Listing[]
     * @private
     */
    selectedListings: Ember.computed(function () {
      return [];
    }),

    /**
     * The current selected listings
     * as hashmap width id: <active>.
     *
     * @property selectedMap
     * @type {Object}
     * @private
     */
    selectedMap: Ember.computed({
      get: function get()
      /*key*/
      {
        return {};
      }
    }),

    /**
     * A search term to search for coffees.
     *
     * @property searchTerm
     * @type String
     */
    searchTerm: '',

    /**
     * A sales number to search for coffees.
     *
     * @property salesNumber
     * @type String
     */
    salesNumber: '',

    /**
     * The ID of the auction to select. This is persisted in the URL.
     *
     * @property selectedAuctionId
     * @type String
     */
    selectedAuctionId: null,

    /**
     * The ID of the shop to select.This is persisted in the URL.
     *
     * @property selectedShopId
     * @type String
     */
    selectedShopId: null,

    /**
     * If only archived listings should be displayed.
     *
     * @property showArchived
     * @type Boolean
     * @default false
     */
    showArchived: false,

    /**
     * If only rated listings should be displayed.
     *
     * @property showRatings
     * @type Boolean
     * @default false
     */
    showRatings: false,

    /**
     * The statuses that should be displayed. Multiple can be selected.
     *
     * @property filterStatus
     * @type Array
     * @default []
     */
    filterStatus: Ember.A(),

    /**
     * The origins that should be displayed. Multiple can be selected.
     *
     * @property filterOrigins
     * @type Array
     * @default []
     */
    filterOrigins: Ember.A(),

    /**
     * The warehouses that should be displayed. Multiple can be selected.
     *
     * @property filterWarehouses
     * @type Array
     * @default []
     */
    filterWarehouses: Ember.A(),

    /**
     * The current order settings. One of "DATE", "AMOUNT", "NAME", "STATUS",
     * "PRICE", "ICO_NUMBER", "SALES_NUMBER", "AVAILABILITY" and "ORIGIN".
     *
     * @property orderBy
     * @type String
     * @default "DATE"
     */
    orderBy: 'DATE',

    /**
     * All auctions of this company.
     *
     * @property availableAuctions
     * @type Model.Auction[]
     */
    availableAuctions: Ember.computed('selectedShopId', 'availableData.auctions.[]', {
      get: function get()
      /*key*/
      {
        var auctions = Ember.get(this, 'availableData.auctions');
        var selectedShopId = Ember.get(this, 'selectedShopId');

        if (selectedShopId) {
          auctions = auctions.filter(function (auction) {
            var shopId = Ember.get(auction, 'shop.id');
            return shopId === selectedShopId;
          });
        }

        return auctions;
      }
    }),

    /**
     * All warehouses of this company.
     *
     * @property availableWarehouses
     * @type Model.Location[]
     */
    availableWarehouses: Ember.computed.reads('availableData.locations'),

    /**
     * All producers of this company.
     *
     * @property availableProducers
     * @type Model.Producer[]
     */
    availableProducers: Ember.computed.reads('availableData.producers'),
    hasActiveShop: Ember.computed('availableData.activeShops.length', function () {
      return Ember.get(this, 'availableData.activeShops.length') > 0;
    }),

    /**
     * The available options by which it is possible to order.
     *
     * @property orderByOptions
     * @type Array
     * @final
     */
    orderByOptions: Ember.computed('l10n', {
      get: function get()
      /*key*/
      {
        return [{
          value: 'DATE',
          label: Ember.get(this, 'l10n').t('Most recent')
        }, {
          value: 'AMOUNT',
          label: Ember.get(this, 'l10n').t('Amount')
        }, {
          value: 'NAME',
          label: Ember.get(this, 'l10n').t('Name')
        }, {
          value: 'STATUS',
          label: Ember.get(this, 'l10n').t('Status')
        }, {
          value: 'PRICE',
          label: Ember.get(this, 'l10n').t('Price')
        }, {
          value: 'ICO_NUMBER',
          label: Ember.get(this, 'l10n').t('ICO #')
        }, {
          value: 'SALES_NUMBER',
          label: Ember.get(this, 'l10n').t('Sales #')
        }, {
          value: 'AVAILABILITY',
          label: Ember.get(this, 'l10n').t('Availability')
        }, {
          value: 'ORIGIN',
          label: Ember.get(this, 'l10n').t('Origin')
        }];
      }
    }),

    /**
     * The available status to filter from.
     *
     * @property filterStatusOptions
     * @type Array
     * @final
     */
    filterStatusOptions: Ember.computed('l10n', {
      get: function get()
      /*key*/
      {
        return [{
          value: 'DRAFT',
          label: Ember.get(this, 'l10n').t('Draft')
        }, {
          value: 'ACTIVE',
          label: Ember.get(this, 'l10n').t('Active')
        }, {
          value: 'EXPIRED',
          label: Ember.get(this, 'l10n').t('Expired')
        }, {
          value: 'INACTIVE',
          label: Ember.get(this, 'l10n').t('Inactive')
        }, {
          value: 'SOLD_OUT',
          label: Ember.get(this, 'l10n').t('Sold out')
        }];
      }
    }),

    /**
     * The available page size options.
     *
     * @property pageSizeOptions
     * @type Array
     * @final
     */
    pageSizeOptions: Ember.computed({
      get: function get()
      /*key*/
      {
        return [{
          value: 10,
          label: 10
        }, {
          value: 20,
          label: 20
        }, {
          value: 40,
          label: 40
        }, {
          value: 80,
          label: 80
        }, {
          value: 160,
          label: 160
        }, {
          value: 320,
          label: 320
        }];
      }
    }),
    quickLinkListingOptions: Ember.computed('can', 'l10n', function () {
      var quickLinksListingOptions = [{
        label: this.get('l10n').t('Add one'),
        route: 'admin.manage.listing.create'
      }];

      if (Ember.get(this, 'can').can('edit settings')) {
        quickLinksListingOptions.push({
          label: this.get('l10n').t('Add many'),
          route: 'admin.manage.bulk.listing'
        });
      }

      return quickLinksListingOptions;
    }),
    printOptions: Ember.computed('l10n', function () {
      var l10n = Ember.get(this, 'l10n');
      return [{
        label: l10n.t('Offer list'),
        value: 'printOfferListClick'
      }, {
        label: l10n.t('Event labels (Hub Tag)'),
        value: 'printLabelsClick'
      }];
    }),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Resets current selection.
     *
     * @public
     * @method resetSelection
     * @return {Void}
     */
    resetSelection: function resetSelection() {
      Ember.set(this, 'selectedListings', []);
      Ember.set(this, 'selectedMap', {});
    },
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      printOption: function printOption(option) {
        var actionName = option.value;
        this.send(actionName);
      },

      /**
       * Publish a listing. This sets the state to ACTIVE.
       * The company pays for this. This is only possible coming from DRAFT or INACTIVE.
       *
       * @event publishListing
       * @param Model.Listing listing
       * @private
       */
      publishListing: function publishListing(listing) {
        Ember.get(this, 'listingPublisher').publish(listing);
      },

      /**
       * Deactivate a listing. This sets the state to INACTIVE.
       * This is only possible coming from ACTIVE or EXPIRED.
       *
       * @event deactivateListing
       * @param Model.Listing listing
       * @private
       */
      deactivateListing: function deactivateListing(listing) {
        Ember.get(this, 'listingPublisher').deactivate(listing);
      },

      /**
       * Delete a listing. This actually deletes the listing.
       * It is not recoverable. This is only possible coming from DRAFT.
       *
       * @event deleteListing
       * @param Model.Listing listing
       * @private
       */
      deleteListing: function deleteListing(listing) {
        Ember.get(this, 'listingPublisher').delete(listing);
      },

      /**
       * Archive a listing. This sets the archived flag.
       * This is only possible coming from INACTIVE, SOLD_OUT and EXPIRED.
       *
       * @event archiveListing
       * @param Model.Listing listing
       * @private
       */
      archiveListing: function archiveListing(listing) {
        Ember.get(this, 'listingPublisher').archive(listing);
      },

      /**
       * Mark listing as SOLD_OUT.
       * This is only possible when ACTIVE.
       *
       * @event soldListing
       * @param Model.Listing listing
       * @private
       */
      soldListing: function soldListing(listing) {
        Ember.get(this, 'listingPublisher').sold(listing);
      },

      /**
       * Unarchive a listing. This unsets the archived flag.
       *
       * @event unarchiveListing
       * @param Model.Listing listing
       * @private
       */
      unarchiveListing: function unarchiveListing(listing) {
        Ember.get(this, 'listingPublisher').unarchive(listing);
      },

      /**
       * Renew an expired listing. The company is billed for this.
       * This is only possible coming from EXPIRED.
       *
       * @event renewListing
       * @param Model.Listing listing
       * @private
       */
      renewListing: function renewListing(listing) {
        Ember.get(this, 'listingPublisher').renew(listing);
      },

      /**
       * Edit an expired listing before re-publishing it.  This sets the state to INACTIVE
       * and redirects to the edit screen for this listing. This is only possible coming from EXPIRED.
       *
       * @event editRenewListing
       * @param Model.Listing listing
       * @private
       */
      editRenewListing: function editRenewListing(listing) {
        var _this = this;

        if (listing.get('status') === 'EXPIRED') {
          listing.set('status', 'INACTIVE');
          listing.set('auction', null);
          listing.save().then(function () {
            Ember.get(_this, 'toast').info(Ember.get(_this, 'l10n').t('You can now review and edit your coffee.'));

            _this.transitionToRoute('admin.manage.listing.edit', listing);
          }, function () {
            Ember.get(_this, 'toast').warning(Ember.get(_this, 'l10n').t('Sorry, but an error occurred when trying to renew this listing.'));
          });
        } else {
          Ember.get(this, 'toast').warning(Ember.get(this, 'l10n').t('You can only renew expired coffee listings.'));
        }
      },

      /**
       * Set the currently selected listings.
       *
       * @event selectMultiple
       * @param listings Model.Listing[]
       * @private
       */
      selectMultiple: function selectMultiple(selectedMap) {
        Ember.set(this, 'selectedListings', Object.keys(selectedMap));
      },

      /**
       * Transitions to bulk-edit view with selected listings as query param.
       *
       * @event editSelection
       * @private
       */
      editSelection: function editSelection() {
        this.transitionToRoute('admin.manage.bulk.listing-edit', {
          queryParams: {
            ids: Ember.get(this, 'selectedListings')
          }
        });
      },
      printOfferListClick: function printOfferListClick() {
        var selectedListings = Ember.get(this, 'selectedListings');
        this.get('keenTracking').addEvent('print-offer-list-btn-click', {
          selectedListings: selectedListings
        });
        this.transitionToRoute('admin.manage.print-offer-list', {
          queryParams: {
            ids: selectedListings
          }
        });
      },
      printLabelsClick: function printLabelsClick() {
        var selectedListings = Ember.get(this, 'selectedListings');
        this.get('keenTracking').addEvent('print-labels-btn-click', {
          selectedListings: selectedListings
        });
        this.transitionToRoute('admin.manage.print-labels', {
          queryParams: {
            ids: selectedListings
          }
        });
      },

      /**
       * Handles after copy tasks and closes moda
       *
       * @event selectMultiple
       * @param {string} state
       * @param {Function} closeModal
       * @param {Model.Listing|Object|null} [copyArg=null]
       * @private
       */
      afterCopy: function afterCopy(state, closeModal) {
        var copyArg = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

        switch (state) {
          case 'cancel':
            // noop
            break;

          case 'failure':
            // noop
            break;

          case 'success':
            // add to current collection
            // eslint-disable-next-line no-case-declarations
            var listings = Ember.get(this, 'listings');
            listings.unshiftObject(copyArg);
            this.resetSelection();
            break;

          default:
        }

        closeModal();
      }
    }
  });

  _exports.default = _default;
});