define("@vollersgroup/hub-frontend/services/bulk-lookup-data", ["exports", "ember-bulk-manager/services/bulk-lookup-data", "ember-launch-darkly"], function (_exports, _bulkLookupData, _emberLaunchDarkly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bulkLookupData.default.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    l10n: Ember.inject.service(),
    validator: Ember.inject.service(),
    userSession: Ember.inject.service(),
    availableData: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Hashmap of validation rules from validator.
     *
     * @property listingValidations
     * @type {Object}
     * @private
     */
    validations: Ember.computed('validator.listing', 'validator.producer', {
      get: function get()
      /*key*/
      {
        var producer = Ember.get(this, 'validator.producer');
        var listing = Ember.get(this, 'validator.listing');
        var validations = {
          producer: {},
          listing: {}
        };
        producer.forEach(function (validation) {
          validations.producer[validation.field] = validation;
        });
        listing.forEach(function (validation) {
          validations.listing[validation.field] = validation;
        });
        return validations;
      }
    }),

    /**
     * Collection of available fields for bulk workflows.
     * This consists of a hash for different models such
     * as `listings` etc. They contain all necessary data
     * for feeding bulk components and serivces, whereas
     * all fields need to have `value` and `label`, but
     * can add `default`, `prefillOptions` and also
     * `gridOptions` optionally.
     *
     * @property availableFields
     * @type {Object}
     * @public
     */
    availableFields: Ember.computed('_checkboxRenderer', '_validate', 'availableData.certifications.content', 'availableData.descriptors.content', 'availableData.varieties.content', 'availableData.{allLocations,allProducers,baseUnits,countries,listingAvailabilities,locations,packageUnits,packagings,processings,producerTypes,producers,warehouses}', 'forceRecomputationOfFields', 'l10n', 'settings.dynamicPricingEnabled', 'userSession.culture', {
      get: function get()
      /*key*/
      {
        var _this = this;

        var listing = {};
        listing.name = {
          value: 'name',
          label: Ember.get(this, 'l10n').t('Name'),
          prefillOptions: {
            skip: true
          },
          gridOptions: {
            allowEmpty: false,
            validator: Ember.run.bind(this, this._validate, 'listing', 'name')
          }
        };
        listing.erpId = {
          value: 'erpId',
          label: Ember.get(this, 'l10n').t('Reference ID'),
          prefillOptions: {
            skip: true
          },
          gridOptions: {
            validator: Ember.run.bind(this, this._validate, 'listing', 'erpId')
          }
        };
        listing.icoNumber = {
          value: 'icoNumber',
          label: Ember.get(this, 'l10n').t('ICO #'),
          prefillOptions: {
            skip: true
          },
          gridOptions: {
            validator: Ember.run.bind(this, this._validate, 'listing', 'icoNumber')
          }
        };
        listing.salesOfferNumber = {
          value: 'salesOfferNumber',
          label: Ember.get(this, 'l10n').t('Sales offer #'),
          prefillOptions: {
            skip: true
          },
          gridOptions: {
            validator: Ember.run.bind(this, this._validate, 'listing', 'salesOfferNumber')
          }
        };
        listing.description = {
          value: 'description',
          label: Ember.get(this, 'l10n').t('Short description'),
          prefillOptions: {
            skip: true
          },
          gridOptions: {
            validator: Ember.run.bind(this, this._validate, 'listing', 'description')
          }
        };
        listing.estimatedArrivalDate = {
          value: 'estimatedArrivalDate',
          label: Ember.get(this, 'l10n').t('Estimated arrival date'),
          prefillOptions: {
            type: 'timestamp',
            format: 'DD.MM.YYYY'
          },
          gridOptions: {
            type: 'timestamp',
            format: 'DD.MM.YYYY',
            validator: Ember.run.bind(this, this._validate, 'listing', 'estimatedArrivalDate')
          }
        };
        listing.price = {
          value: 'price',
          label: Ember.get(this, 'l10n').t('Price'),
          prefillOptions: {
            type: 'numeric'
          },
          gridOptions: {
            type: 'numeric',
            numericFormat: {
              pattern: '0,0.00',
              culture: Ember.get(this, 'userSession.culture')
            },
            allowEmpty: false,
            validator: Ember.run.bind(this, this._validate, 'listing', 'price')
          }
        }; // needs `dynamicPricingEnabled` flag to be set

        if (Ember.get(this, 'settings.dynamicPricingEnabled')) {
          listing.priceDynamic = {
            value: 'priceDynamic',
            label: Ember.get(this, 'l10n').t('Dynamic pricing'),
            prefillOptions: {
              type: 'checkbox'
            },
            gridOptions: {
              type: 'checkbox',
              renderer: this._checkboxRenderer,
              validator: Ember.run.bind(this, this._validate, 'listing', 'priceDynamic')
            }
          };
          listing.cmarketPremiumAmount = {
            value: 'cmarketPremiumAmount',
            label: Ember.get(this, 'l10n').t('Premium (in USD ¢ / LBS)'),
            prefillOptions: {
              disabled: true,
              type: 'numeric'
            },
            gridOptions: {
              type: 'numeric',
              numericFormat: {
                pattern: '0,0.00',
                culture: Ember.get(this, 'userSession.culture')
              },
              allowEmpty: false,
              validator: Ember.run.bind(this, this._validate, 'listing', 'cmarketPremiumAmount')
            }
          };
        }
        /*
        listing.priceCurrency = {
          value: 'priceCurrency',
          label: get(this, 'l10n').t('Currency')
        },
        */


        listing.priceBaseUnit = {
          value: 'priceBaseUnit',
          label: Ember.get(this, 'l10n').t('Price base unit'),
          prefillOptions: {
            type: 'json',
            reference: false,
            source: Ember.get(this, 'availableData.baseUnits')
          },
          gridOptions: {
            type: 'json',
            reference: false,
            allowEmpty: false,
            idProperty: 'value',
            labelProperty: 'label',
            source: Ember.get(this, 'availableData.baseUnits'),
            validator: Ember.run.bind(this, this._validate, 'listing', 'priceBaseUnit')
          }
        };
        listing.samplePrice = {
          value: 'samplePrice',
          label: Ember.get(this, 'l10n').t('Sample price'),
          prefillOptions: {
            type: 'numeric'
          },
          gridOptions: {
            type: 'numeric',
            numericFormat: {
              pattern: '0,0.00',
              culture: Ember.get(this, 'userSession.culture')
            }
          }
        };
        /*
        listing.samplePriceCurrency = {
          value: 'samplePriceCurrency',
          label: get(this, 'l10n').t('Sample currency')
        },
        */

        listing.availableAmount = {
          value: 'availableAmount',
          label: Ember.get(this, 'l10n').t('Available amount'),
          prefillOptions: {
            type: 'numeric'
          },
          gridOptions: {
            type: 'numeric',
            allowEmpty: false,
            validator: Ember.run.bind(this, this._validate, 'listing', 'availableAmount')
          }
        };
        listing.availableAmountUnit = {
          value: 'availableAmountUnit',
          label: Ember.get(this, 'l10n').t('Package unit'),
          prefillOptions: {
            type: 'json',
            reference: false,
            source: Ember.get(this, 'availableData.packageUnits')
          },
          gridOptions: {
            type: 'json',
            reference: false,
            allowEmpty: false,
            idProperty: 'value',
            labelProperty: 'label',
            source: Ember.get(this, 'availableData.packageUnits'),
            validator: Ember.run.bind(this, this._validate, 'listing', 'availableAmountUnit')
          }
        };
        listing.minOrderAmount = {
          value: 'minOrderAmount',
          label: Ember.get(this, 'l10n').t('Min. amount'),
          prefillOptions: {
            type: 'numeric'
          },
          gridOptions: {
            type: 'numeric',
            allowEmpty: false,
            validator: Ember.run.bind(this, this._validate, 'listing', 'minOrderAmount')
          }
        };
        /*
        listing.decaf = {
          value: 'decaf',
          label: get(this, 'l10n').t('Decaf'),
          prefillOptions: {
            type: 'boolean',
          },
          gridOptions: {
            type: 'checkbox',
            className: 'htCenter'
          }
        },
        */

        listing.sensorialDescriptors = {
          value: 'sensorialDescriptors',
          label: Ember.get(this, 'l10n').t('Flavors'),
          prefillOptions: {
            skip: true,
            type: 'json',
            multiple: true,
            labelProperty: 'name',
            source: Ember.get(this, 'availableData.descriptors.content')
          },
          gridOptions: {
            type: 'json',
            multiple: true,
            idProperty: 'id',
            labelProperty: 'name',
            source: Ember.get(this, 'availableData.descriptors.content'),
            validator: Ember.run.bind(this, this._validate, 'listing', 'sensorialDescriptors')
          }
        };
        listing.sensorialScore = {
          value: 'sensorialScore',
          label: Ember.get(this, 'l10n').t('Final score'),
          prefillOptions: {
            skip: true
          },
          gridOptions: {
            type: 'numeric',
            placeholder: Ember.get(this, 'l10n').t('from 0 to 100'),
            validator: Ember.run.bind(this, this._validate, 'listing', 'sensorialScore')
          }
        };
        listing.sensorialCuppingDescription = {
          value: 'sensorialCuppingDescription',
          label: Ember.get(this, 'l10n').t('Cupping notes'),
          prefillOptions: {
            skip: true
          },
          gridOptions: {
            validator: Ember.run.bind(this, this._validate, 'listing', 'sensorialCuppingDescription')
          }
        };
        listing.availability = {
          value: 'availability',
          label: Ember.get(this, 'l10n').t('Availability'),
          prefillOptions: {
            type: 'json',
            reference: false,
            source: Ember.get(this, 'availableData.listingAvailabilities')
          },
          gridOptions: {
            type: 'json',
            reference: false,
            idProperty: 'value',
            labelProperty: 'label',
            source: Ember.get(this, 'availableData.listingAvailabilities'),
            validator: Ember.run.bind(this, this._validate, 'listing', 'availability')
          }
        };
        listing.varieties = {
          value: 'varieties',
          label: Ember.get(this, 'l10n').t('Varieties'),
          prefillOptions: {
            type: 'json',
            multiple: true,
            labelProperty: 'name',
            source: Ember.get(this, 'availableData.varieties.content')
          },
          gridOptions: {
            type: 'json',
            multiple: true,
            idProperty: 'id',
            labelProperty: 'name',
            source: Ember.get(this, 'availableData.varieties.content'),
            validator: Ember.run.bind(this, this._validate, 'listing', 'varieties')
          }
        };
        listing.grade = {
          value: 'grade',
          label: Ember.get(this, 'l10n').t('Grade'),
          prefillOptions: {
            skip: true
          },
          gridOptions: {
            placeholder: Ember.get(this, 'l10n').t('e.g. AA'),
            validator: Ember.run.bind(this, this._validate, 'listing', 'grade')
          }
        };
        listing.processing = {
          value: 'processing',
          label: Ember.get(this, 'l10n').t('Processing'),
          prefillOptions: {
            type: 'json',
            reference: false,
            source: Ember.get(this, 'availableData.processings')
          },
          gridOptions: {
            type: 'json',
            reference: false,
            allowEmpty: true,
            idProperty: 'value',
            labelProperty: 'label',
            source: Ember.get(this, 'availableData.processings'),
            validator: Ember.run.bind(this, this._validate, 'listing', 'processing')
          }
        };
        listing.certifications = {
          value: 'certifications',
          label: Ember.get(this, 'l10n').t('Certifications'),
          gridOptions: {
            type: 'json',
            multiple: true,
            idProperty: 'id',
            labelProperty: 'name',
            source: Ember.get(this, 'availableData.certifications.content'),
            validator: Ember.run.bind(this, this._validate, 'listing', 'certifications')
          },
          prefillOptions: {
            type: 'json',
            multiple: true,
            labelProperty: 'name',
            source: Ember.get(this, 'availableData.certifications.content')
          }
        };
        listing.packaging = {
          value: 'packaging',
          label: Ember.get(this, 'l10n').t('Packaging'),
          prefillOptions: {
            type: 'json',
            reference: false,
            source: Ember.get(this, 'availableData.packagings')
          },
          gridOptions: {
            type: 'json',
            reference: false,
            idProperty: 'value',
            labelProperty: 'label',
            source: Ember.get(this, 'availableData.packagings'),
            validator: Ember.run.bind(this, this._validate, 'listing', 'packaging')
          }
        };
        listing.cropYear = {
          value: 'cropYear',
          label: Ember.get(this, 'l10n').t('Crop year'),
          gridOptions: {
            placeholder: Ember.get(this, 'l10n').t('e.g. 2012'),
            validator: Ember.run.bind(this, this._validate, 'listing', 'cropYear')
          }
        };
        listing.moisture = {
          value: 'moisture',
          label: Ember.get(this, 'l10n').t('Moisture'),
          prefillOptions: {
            skip: true
          },
          gridOptions: {
            type: 'numeric',
            placeholder: Ember.get(this, 'l10n').t('in %'),
            validator: Ember.run.bind(this, this._validate, 'listing', 'moisture')
          }
        };
        listing.waterActivity = {
          value: 'waterActivity',
          label: Ember.get(this, 'l10n').t('Water activity'),
          prefillOptions: {
            skip: true
          },
          gridOptions: {
            type: 'numeric',
            validator: Ember.run.bind(this, this._validate, 'listing', 'waterActivity')
          }
        };
        listing.screenSize = {
          value: 'screenSize',
          label: Ember.get(this, 'l10n').t('Screen size'),
          prefillOptions: {
            skip: true
          },
          gridOptions: {
            type: 'numeric',
            placeholder: Ember.get(this, 'l10n').t('from 0 to 20'),
            validator: Ember.run.bind(this, this._validate, 'listing', 'screenSize')
          }
        };

        if ((0, _emberLaunchDarkly.variation)('new-warehouses')) {
          listing.warehouse = {
            value: 'warehouse',
            label: Ember.get(this, 'l10n').t('Warehouse'),
            prefillOptions: {
              type: 'json',
              labelProperty: 'displayName',
              source: Ember.get(this, 'availableData.warehouses')
            },
            gridOptions: {
              type: 'json',
              idProperty: 'id',
              allowEmpty: false,
              labelProperty: function labelProperty(item) {
                return item && Ember.get(item, 'displayName');
              },
              disabledProperty: function disabledProperty(item) {
                return item && !Ember.get(item, 'active');
              },
              source: function source() {
                return Ember.get(_this, 'availableData.warehouses').toArray();
              },
              validator: Ember.run.bind(this, this._validate, 'listing', 'warehouse')
            }
          };
        } else {
          listing.legacyWarehouse = {
            value: 'legacyWarehouse',
            label: Ember.get(this, 'l10n').t('Warehouse'),
            prefillOptions: {
              type: 'json',
              labelProperty: 'name',
              source: Ember.get(this, 'availableData.locations')
            },
            gridOptions: {
              type: 'json',
              idProperty: 'id',
              allowEmpty: false,
              labelProperty: function labelProperty(item) {
                return item && Ember.get(item, 'name');
              },
              disabledProperty: function disabledProperty(item) {
                return item && Ember.get(item, 'archived');
              },
              source: function source() {
                return Ember.get(_this, 'availableData.allLocations').toArray();
              },
              validator: Ember.run.bind(this, this._validate, 'listing', 'legacyWarehouse')
            }
          };
        }

        listing.producer = {
          value: 'producer',
          label: Ember.get(this, 'l10n').t('Origin'),
          prefillOptions: {
            type: 'json',
            labelProperty: 'name',
            source: Ember.get(this, 'availableData.producers')
          },
          gridOptions: {
            type: 'json',
            idProperty: 'id',
            allowEmpty: false,
            labelProperty: function labelProperty(item) {
              return item && Ember.get(item, 'name');
            },
            disabledProperty: function disabledProperty(item) {
              return item && Ember.get(item, 'archived');
            },
            source: function source() {
              return Ember.get(_this, 'availableData.allProducers').toArray();
            },
            validator: Ember.run.bind(this, this._validate, 'listing', 'producer')
          }
        };
        listing.specialOffer = {
          value: 'specialOffer',
          label: Ember.get(this, 'l10n').t('Special offer'),
          prefillOptions: {
            type: 'checkbox'
          },
          gridOptions: {
            type: 'checkbox',
            renderer: this._checkboxRenderer,
            validator: Ember.run.bind(this, this._validate, 'listing', 'specialOffer')
          }
        };
        listing.priceHidden = {
          value: 'priceHidden',
          label: Ember.get(this, 'l10n').t('Price hidden'),
          prefillOptions: {
            type: 'checkbox'
          },
          gridOptions: {
            type: 'checkbox',
            renderer: this._checkboxRenderer,
            validator: Ember.run.bind(this, this._validate, 'listing', 'priceHidden')
          }
        };
        listing.autoRenew = {
          value: 'autoRenew',
          label: Ember.get(this, 'l10n').t('Auto-republish'),
          prefillOptions: {
            type: 'checkbox'
          },
          gridOptions: {
            type: 'checkbox',
            renderer: this._checkboxRenderer,
            validator: Ember.run.bind(this, this._validate, 'listing', 'autoRenew')
          }
        };
        var producer = {
          name: {
            value: 'name',
            label: Ember.get(this, 'l10n').t('Name'),
            prefillOptions: {
              skip: true
            },
            gridOptions: {
              allowEmpty: false,
              validator: Ember.run.bind(this, this._validate, 'producer', 'name')
            }
          },
          farmName: {
            value: 'farmName',
            label: Ember.get(this, 'l10n').t('Farm name'),
            prefillOptions: {
              skip: true
            },
            gridOptions: {
              validator: Ember.run.bind(this, this._validate, 'producer', 'farmName')
            }
          },
          type: {
            value: 'type',
            default: 'FARM',
            label: Ember.get(this, 'l10n').t('Type'),
            prefillOptions: {
              type: 'json',
              reference: false,
              labelProperty: 'label',
              source: Ember.get(this, 'availableData.producerTypes')
            },
            gridOptions: {
              type: 'json',
              reference: false,
              searchable: false,
              allowEmpty: false,
              idProperty: 'value',
              labelProperty: 'label',
              source: function source() {
                return Ember.get(_this, 'availableData.producerTypes').toArray();
              },
              validator: Ember.run.bind(this, this._validate, 'producer', 'type')
            }
          },
          foundingYear: {
            value: 'foundingYear',
            label: Ember.get(this, 'l10n').t('Founded'),
            prefillOptions: {
              skip: true
            },
            gridOptions: {
              placeholder: Ember.get(this, 'l10n').t('YYYY'),
              validator: Ember.run.bind(this, this._validate, 'producer', 'foundingYear')
            }
          },
          altitude: {
            value: 'altitude',
            label: Ember.get(this, 'l10n').t('Altitude'),
            prefillOptions: {
              skip: true
            },
            gridOptions: {
              validator: Ember.run.bind(this, this._validate, 'producer', 'altitude')
            }
          },
          numFarms: {
            value: 'numFarms',
            label: Ember.get(this, 'l10n').t('Number of farms'),
            prefillOptions: {
              skip: true
            },
            gridOptions: {
              type: 'numeric',
              validator: Ember.run.bind(this, this._validate, 'producer', 'numFarms')
            }
          },
          numMembers: {
            value: 'numMembers',
            label: Ember.get(this, 'l10n').t('Number of farm members'),
            prefillOptions: {
              skip: true
            },
            gridOptions: {
              type: 'numeric',
              validator: Ember.run.bind(this, this._validate, 'producer', 'numMembers')
            }
          },
          country: {
            value: 'country',
            label: Ember.get(this, 'l10n').t('Country'),
            prefillOptions: {
              type: 'json',
              reference: false,
              labelProperty: 'label',
              source: Ember.get(this, 'availableData.countries')
            },
            gridOptions: {
              type: 'json',
              reference: false,
              allowEmpty: false,
              idProperty: 'value',
              labelProperty: 'label',
              source: function source() {
                return Ember.get(_this, 'availableData.countries').toArray();
              },
              validator: Ember.run.bind(this, this._validate, 'producer', 'location.country')
            }
          },
          region: {
            value: 'region',
            label: Ember.get(this, 'l10n').t('Region'),
            prefillOptions: {
              skip: true
            },
            gridOptions: {
              validator: Ember.run.bind(this, this._validate, 'producer', 'location.region')
            }
          },
          subregion: {
            value: 'subregion',
            label: Ember.get(this, 'l10n').t('Subregion'),
            prefillOptions: {
              skip: true
            },
            gridOptions: {
              validator: Ember.run.bind(this, this._validate, 'producer', 'location.subregion')
            }
          },
          farmSize: {
            value: 'farmSize',
            label: Ember.get(this, 'l10n').t('Farm size'),
            prefillOptions: {
              skip: true
            },
            gridOptions: {
              type: 'numeric',
              placeholder: Ember.get(this, 'l10n').t('in ha'),
              validator: Ember.run.bind(this, this._validate, 'producer', 'farmSize')
            }
          },
          coffeeGrowingArea: {
            value: 'coffeeGrowingArea',
            label: Ember.get(this, 'l10n').t('Coffee growing area'),
            prefillOptions: {
              skip: true
            },
            gridOptions: {
              type: 'numeric',
              placeholder: Ember.get(this, 'l10n').t('in ha'),
              validator: Ember.run.bind(this, this._validate, 'producer', 'coffeeGrowingArea')
            }
          },
          story: {
            value: 'story',
            label: Ember.get(this, 'l10n').t('Producer story'),
            prefillOptions: {
              skip: true
            },
            gridOptions: {
              validator: Ember.run.bind(this, this._validate, 'producer', 'story')
            }
          }
        };
        return {
          listing: listing,
          producer: producer
        };
      }
    }),

    /**
     * Alias to user's company settings for dynamic pricing.
     *
     * @property settings
     * @type {Model.Settings}
     * @public
     */
    settings: Ember.computed.reads('userSession.currentCompany.settings'),

    /**
     * Simple counter forcing a recomputation of available fields.
     *
     * @property forceRecomputationOfFields
     * @type {Number}
     * @public
     */
    forceRecomputationOfFields: 0,
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Resets runtime state of service.
     */
    reset: function reset() {
      this.incrementProperty('forceRecomputationOfFields');
    },

    /**
     * Proxy for HUB validations service.
     *
     * @private
     * @method _validate
     * @param {String} modelName
     * @param {String} field
     * @param {Mixed} value
     * @param {Object} CellProperty
     * @throws ValidationError
     * @return {Boolean}
     */
    _validate: function _validate(modelName, field, value, cellProperties) {
      var validations = Ember.get(this, "validations.".concat(modelName));
      var validation = validations[field]; // read only cells are ignored

      if (cellProperties.readOnly) {
        return true;
      } // skip missing validations


      if (Ember.isNone(validation)) {
        return true;
      }

      var row = cellProperties.row;
      var table = cellProperties.instance;
      var bulkGridRow = table.getSourceDataAtRow(row); // skip empty rows

      if (Ember.isNone(bulkGridRow)) {
        return true;
      } // create fake model from row data and
      // apply current property to model cause
      // it's actually not set when validating


      var model = bulkGridRow.getData();
      Ember.set(model, cellProperties.prop, value);

      if (validation.validate(value, model)) {
        return true;
      }

      throw new Error(validation.errorMessage);
    },

    /**
     * Custom renderer for HUB checkboxes.
     *
     * @private
     * @method _checkboxRenderer
     * @param  {Object} instance
     * @param  {DOMNode} td
     * @param  {Integer} row
     * @param  {Integer} col
     * @param  {String} prop
     * @param  {String} value
     * @param  {Object} cellProperties
     *
     * @return {DOMNode}
     */
    _checkboxRenderer: function _checkboxRenderer(instance, td, row, col, prop, value
    /*, cellProperties*/
    ) {
      var label = Ember.$("<label class=\"input__checkbox--label\" for=\"checkbox__".concat(row, "__").concat(col, "\"></label>"));
      var checkbox = Ember.$("<input id=\"checkbox__".concat(row, "__").concat(col, "\" type=\"checkbox\" class=\"input__checkbox\">"));
      checkbox.on('change', function () {
        instance.setDataAtCell(row, col, checkbox.prop('checked'));
      });
      Ember.$(td).addClass('text-center').empty().append(checkbox).append(label);
      checkbox.prop('checked', value);
      return td;
    }
  });

  _exports.default = _default;
});