define("@vollersgroup/hub-frontend/components/message-multiple/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VAs+ILQF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"input__wrapper\"],[9],[0,\"\\n    \"],[1,[27,\"input\",null,[[\"id\",\"value\",\"required\",\"class\",\"placeholder\"],[\"message-subject\",[23,[\"messageSubject\"]],[23,[\"showSubject\"]],\"input__txt input--full\",[27,\"t\",[\"Enter your message subject here\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"input__wrapper\"],[9],[0,\"\\n    \"],[1,[27,\"textarea\",null,[[\"rows\",\"required\",\"id\",\"value\",\"class\",\"placeholder\"],[3,true,\"message-text\",[23,[\"messageText\"]],\"input__textarea input--full\",[27,\"t\",[\"Enter your message text here\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"button__container button__container--inline\"],[9],[0,\"\\n\"],[4,\"c-button\",null,[[\"type\",\"class\",\"isLoading\",\"disabled\",\"preventDefault\",\"loadingText\"],[\"submit\",\"button\",[23,[\"isSending\"]],[23,[\"isDisabled\"]],false,[27,\"t\",[\"Sending...\"],null]]],{\"statements\":[[0,\"      \"],[1,[21,\"buttonText\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"canRetry\"]]],null,{\"statements\":[[4,\"c-button\",null,[[\"class\",\"action\"],[\"button button--special\",\"retry\"]],{\"statements\":[[0,\"        \"],[1,[27,\"t\",[\"Retry\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"send\"],[[\"on\"],[\"submit\"]]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/message-multiple/template.hbs"
    }
  });

  _exports.default = _default;
});