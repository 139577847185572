define("@vollersgroup/hub-frontend/validators/slug", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    l10n: Ember.inject.service(),
    validate: function validate(value, options
    /*, model, attribute*/
    ) {
      var l10n = Ember.get(this, 'l10n');
      options = options || {};
      var description = options.description || 'Slug';

      if (!value || value.length < 4) {
        var message = l10n.t('{{description}} must be at least 4 characters long.', {
          description: description
        });
        return Ember.RSVP.Promise.resolve(message);
      }

      var lowercase = /[a-z]/;

      if (!lowercase.test(value)) {
        var _message = l10n.t('{{description}} must contain at least one lowercase letter.', {
          description: description
        });

        return Ember.RSVP.Promise.resolve(_message);
      }

      var onlyFromAllowedChars = /^[a-z0-9-]+$/;

      if (!onlyFromAllowedChars.test(value)) {
        var _message2 = l10n.t('{{description}} can contain only lowercase letters, numbers and dashes.', {
          description: description
        });

        return Ember.RSVP.Promise.resolve(_message2);
      }

      if (value.length > 200) {
        var _message3 = l10n.t('{{description}} is too long (maximum is 200 characters).', {
          description: description
        });

        return Ember.RSVP.Promise.resolve(_message3);
      }

      return Ember.RSVP.Promise.resolve(true);
    }
  });

  _exports.default = _default;
});