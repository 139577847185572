define("@vollersgroup/hub-frontend/components/manage-shop-policies/component", ["exports", "@vollersgroup/hub-frontend/mixins/error-validation"], function (_exports, _errorValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A module to edit a shop's policies.
   *
   * ```html
   * {{manage-shop-policies shop=myShop action="myAction"}}
   * ```
   *
   * The action should have the following format:
   *
   * ```js
   * myAction: function(shop) {
   *     // Do something with the shop
   *     shop.save();
   * }
   * ```
   *
   * The action is only called when the shop is valid.
   *
   * @namespace Component
   * @class ManageShopPolicies
   * @extends Ember.Component
   * @uses Mixin.ErrorValidation
   */
  var _default = Ember.Component.extend(_errorValidation.default, {
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * The shop that should be edited / created.
     *
     * @attribute shop
     * @type Model.Shop
     * @required
     */
    shop: null,

    /**
     * The name of the action to call when the creation / editing of the shop is done.
     * This action receives the edited shop as a single parameter.
     *
     * @attribute action
     * @type string
     * @required
     */
    action: null,

    /**
     * If this is set to true, this is an edit dialog (instead of a create dialog)
     *
     * @attribute isEdit
     * @type Boolean
     * @optional
     */
    isEdit: false,

    /**
     * The name of the action to call when the creation / editing of the shop is done.
     * This action receives the edited shop as a single parameter.
     * This action is called when the process should be ended instead of continuing to the next step.
     *
     * @attribute actionExit
     * @type string
     * @required
     */
    actionExit: null,
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      save: function save() {
        var _this = this;

        if (this.checkValidations()) {
          this.set('isLoading', true);
          this.getAttr('shop').save().then(function () {
            Ember.run.next(function () {
              _this.sendAction('action', _this.getAttr('shop'));
            });
          }, function () {
            _this.set('isLoading', false);

            _this.get('errors').push({
              errorMessage: _this.get('l10n').t('An error occurred. Please review your input and try again or contact our support team.')
            });
          });
        }
      },
      saveExit: function saveExit() {
        var _this2 = this;

        if (this.checkValidations()) {
          this.set('isLoading', true);
          this.getAttr('shop').save().then(function () {
            Ember.run.next(function () {
              _this2.sendAction('actionExit', _this2.getAttr('shop'));
            });
          }, function () {
            _this2.set('isLoading', false);

            _this2.get('errors').push({
              errorMessage: _this2.get('l10n').t('An error occurred. Please review your input and try again or contact our support team.')
            });
          });
        }
      }
    },
    // -------------------------------------------------------------------------
    // Properties

    /**
     * When this is true, some computation is currently occuring.
     *
     * @property isLoading
     * @type Boolean
     * @private
     */
    isLoading: false,

    /**
     * The validation configuration.
     *
     * @property validations
     * @type Array
     * @private
     */
    validations: Ember.computed(function () {
      return [];
    })
  });

  _exports.default = _default;
});