define("@vollersgroup/hub-frontend/components/c-checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UHn/VL1p",
    "block": "{\"symbols\":[\"@standalone\"],\"statements\":[[4,\"if\",[[22,1,[]]],null,{\"statements\":[[0,\"  \"],[7,\"input\"],[12,\"id\",[21,\"domId\"]],[12,\"name\",[21,\"domId\"]],[12,\"required\",[21,\"required\"]],[12,\"disabled\",[21,\"disabled\"]],[12,\"class\",[28,[[21,\"class\"],\" input__checkbox\"]]],[12,\"checked\",[27,\"if\",[[23,[\"checked\"]],true,false],null]],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"update\"],[[\"value\"],[\"target.checked\"]]]],[11,\"type\",\"checkbox\"],[9],[10],[0,\"\\n  \"],[7,\"label\"],[12,\"for\",[21,\"domId\"]],[11,\"class\",\"input__checkbox--label\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"input\"],[12,\"id\",[21,\"domId\"]],[12,\"name\",[21,\"domId\"]],[12,\"class\",[21,\"class\"]],[12,\"required\",[21,\"required\"]],[12,\"disabled\",[21,\"disabled\"]],[12,\"checked\",[27,\"if\",[[23,[\"checked\"]],true,false],null]],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"update\"],[[\"value\"],[\"target.checked\"]]]],[11,\"type\",\"checkbox\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/c-checkbox/template.hbs"
    }
  });

  _exports.default = _default;
});