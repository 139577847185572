define("@vollersgroup/hub-frontend/auction/ability", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend({
    userSession: Ember.inject.service(),
    roleLevel: Ember.computed.alias('userSession.currentUser.roleLevel'),
    canCreate: Ember.computed('roleLevel', 'userSession.currentCompany.auctionsEnabled', function () {
      return this.get('roleLevel') >= 3 && this.get('userSession.currentCompany.auctionsEnabled');
    }),
    canEdit: Ember.computed('roleLevel', 'model.isOwner', 'model.company.auctionsEnabled', 'model.archived', 'model.isClosed', 'model.isRunning', function () {
      if (Ember.get(this, 'roleLevel') < 3) {
        return false;
      }

      if (!Ember.get(this, 'model.isOwner')) {
        return false;
      }

      if (!Ember.get(this, 'model.company.auctionsEnabled')) {
        return false;
      }

      if (Ember.get(this, 'model.archived')) {
        return false;
      }

      if (Ember.get(this, 'model.isClosed')) {
        return false;
      }

      return !Ember.get(this, 'model.isRunning');
    }),
    canPublish: Ember.computed('roleLevel', 'userSession.currentCompany.auctionsEnabled', function () {
      return this.get('roleLevel') >= 3 && this.get('userSession.currentCompany.auctionsEnabled');
    }),
    canRegister: Ember.computed('model.status', 'userSession.session.isAuthenticated', 'model.isOwner', 'model.currentAuctionParticipant', 'roleLevel', function () {
      if (Ember.get(this, 'model.status') === 'CLOSED') {
        return false;
      } // Allow register to anonymous users


      if (!Ember.get(this, 'userSession.session.isAuthenticated')) {
        return true;
      }

      if (Ember.get(this, 'model.isOwner')) {
        return false;
      }

      if (Ember.get(this, 'model.currentAuctionParticipant')) {
        return false;
      }

      return Ember.get(this, 'roleLevel') >= 3;
    }),
    canBid: Ember.computed('roleLevel', 'model.isAccepted', 'model.isRunning', function () {
      if (Ember.get(this, 'roleLevel') < 3) {
        return false;
      }

      if (!Ember.get(this, 'model.isRunning')) {
        return false;
      }

      return Ember.get(this, 'model.isAccepted');
    }),
    // TODO: double check what is this used for exactly and rename to better reflect usage
    canBuy: Ember.computed('roleLevel', 'userSession.isAuthenticated', 'model.isOwner', function () {
      if (Ember.get(this, 'roleLevel') < 3) {
        return false;
      }

      if (!Ember.get(this, 'userSession.isAuthenticated')) {
        return false;
      }

      return !Ember.get(this, 'model.isOwner');
    }),
    canViewAutobids: Ember.computed.gte('roleLevel', 3)
  });

  _exports.default = _default;
});