define("@vollersgroup/hub-frontend/components/auction-registration-form/component", ["exports", "ember-can", "@vollersgroup/hub-frontend/company/model", "@vollersgroup/hub-frontend/utils/get-first-adapter-error-message", "@vollersgroup/hub-frontend/utils/scroll-into-the-first-error-if-needed", "ember-cp-validations"], function (_exports, _emberCan, _model2, _getFirstAdapterErrorMessage, _scrollIntoTheFirstErrorIfNeeded, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    password: {
      validators: [(0, _emberCpValidations.validator)('length', {
        dependentKeys: ['model.user.isNew'],
        allowNone: false,
        min: 8,
        message: 'Please enter at least 8 characters.',
        disabled: Ember.computed.not('model.user.isNew')
      })]
    },
    importerName: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        dependentKeys: ['model.importerRequirement'],
        presence: true,
        ignoreBlank: true,
        message: 'Please enter an importer name.',
        disabled: Ember.computed('model.importerRequirement', function () {
          var importerRequirement = Ember.get(this, 'model.importerRequirement');
          return importerRequirement !== 'IMPORTER_PROVIDED';
        })
      })]
    },
    termsAccepted: {
      validators: [(0, _emberCpValidations.validator)(function (value, _options, _model, _attribute) {
        return !value ? 'Please accept auction terms.' : true;
      })]
    }
  });

  var _default = Ember.Component.extend(_emberCan.CanMixin, Validations, {
    // -------------------------------------------------------------------------
    // Dependencies
    store: Ember.inject.service(),
    toast: Ember.inject.service(),
    ajax: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * CSS class of form field wrapper.
     *
     * @property tagName
     * @type String
     * @optional
     * @default ""
     */
    class: '',

    /**
     * An existing or new user instance.
     *
     * @attribute user
     * @type Object
     * @optional
     */
    user: null,

    /**
     * A shipping address.
     *
     * @attribute shippingAddress
     * @type Object
     * @optional
     */
    shippingAddress: null,

    /**
     * If profile fields should be shown.
     *
     * @property showProfile
     * @type Boolean
     * @optional
     * @default true
     */
    showProfile: true,

    /**
     * The action called after a user
     * has been successful created or
     * updated in database.
     *
     * @property saveAction
     * @type function
     */
    saveAction: null,
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Component's current loading state.
     *
     * @property isLoading
     * @type Boolean
     */
    isLoading: false,

    /**
     * Flag indicating whether all error messages should be displayed.
     */
    didValidate: false,

    /**
     *
     */
    importerName: null,

    /**
     * The password for new users.
     *
     * @property password
     * @type String
     */
    password: '',

    /**
     * Accept terms checkbox value
     */
    termsAccepted: null,

    /**
     * Stack with similar components set
     * company check for new users.
     *
     * @property similarCompanies
     * @type Array
     */
    similarCompanies: Ember.computed(function () {
      return [];
    }),

    /**
     * If the second address line is shown for company.
     */
    showOptionalCompanyAddress: false,

    /**
     * If the second address line is shown for shipping address.
     */
    showOptionalShippingAddress: false,

    /**
     * If the current user can edit this form.
     *
     * @property companyDisabled
     * @type Boolean
     */
    companyDisabled: Ember.computed('user.isNew', function () {
      return !Ember.get(this, 'user.isNew') && !this.can('edit company');
    }),
    isFormValid: Ember.computed.and('validations.isValid', 'user.validations.isValid', 'user.company.validations.isValid'),
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Persists new/existing user if
       * all validations pass through.
       *
       * @method showOptionalAddress
       * @param {Boolean} checkCompany
       * @return {Void}
       * @public
       */
      saveUserAndCompany: function saveUserAndCompany() {
        var _this = this;

        var checkCompany = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
        var isValid = Ember.get(this, 'isFormValid');

        if (!Ember.get(this, 'shippingSameAsBilling')) {
          isValid &= this.get('shippingAddress').validateSync().validations.get('isValid');
        }

        this.set('didValidate', true);

        if (!isValid) {
          (0, _scrollIntoTheFirstErrorIfNeeded.default)();
          return;
        }

        Ember.set(this, 'isLoading', true);

        var userSuccessCallback = function userSuccessCallback() {
          _this.get('saveAction')();
        };

        var userFailureCallback = function userFailureCallback() {
          _this.send('clearSimilarCompanies');
        };

        var userFinallyCallback = function userFinallyCallback() {
          Ember.set(_this, 'isLoading', false);
        };

        var companySuccessCallback = function companySuccessCallback() {
          _this._createUser().then(userSuccessCallback, userFailureCallback).finally(userFinallyCallback);
        };

        var companyFailureCallback = function companyFailureCallback() {
          Ember.set(_this, 'isLoading', false);
        }; // create new user


        if (Ember.get(this, 'user.isNew')) {
          if (!checkCompany) {
            companySuccessCallback();
            return;
          }

          this._checkCompany().then(companySuccessCallback, companyFailureCallback);

          return;
        }

        this._updateUser().then(userSuccessCallback).catch(userFailureCallback).finally(userFinallyCallback);
      },

      /**
       * Forces creation of a user.
       *
       * @method create
       * @return {Void}
       * @public
       */
      create: function create() {
        this.send('clearSimilarCompanies');
        this.send('saveUserAndCompany', false);
      },

      /**
       * Clears similar companies.
       *
       * @method create
       * @return {Void}
       * @public
       */
      clearSimilarCompanies: function clearSimilarCompanies() {
        Ember.set(this, 'similarCompanies', []);
      }
    },
    // -------------------------------------------------------------------------
    // Methods
    init: function init() {
      this._super.apply(this, arguments);

      (false && !(Ember.get(this, 'user')) && Ember.assert('user is required', Ember.get(this, 'user')));
    },

    /**
     * Adds a company for new users.
     *
     * @method didReceiveAttrs
     * @return {Void}
     * @public
     */
    didReceiveAttrs: function didReceiveAttrs() {
      var _this2 = this;

      this._super.apply(this, arguments);

      var user = Ember.get(this, 'user');

      if (Ember.isEmpty(user)) {
        return;
      }

      var companyCallback = function companyCallback(company) {
        var newLocation = Ember.get(_this2, 'store').createRecord('location', {
          type: 'COMPANY'
        });
        var newCompany = Ember.get(_this2, 'store').createRecord('company');

        if (Ember.isEmpty(company)) {
          Ember.set(newCompany, 'location', newLocation);
          Ember.set(_this2, 'user.company', newCompany);
          return;
        }

        if (Ember.isEmpty(Ember.get(company, 'location'))) {
          Ember.set(company, 'location', newLocation);
          newCompany.rollbackAttributes();
          return;
        }

        newLocation.rollbackAttributes();
        newCompany.rollbackAttributes();
      };

      Ember.get(user, 'company').then(companyCallback);
    },

    /**
     * Rolls back current user as
     * well as all corresponding
     * relationships.
     *
     * @method willDestroyElement
     * @return {Void}
     * @public
     */
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var user = Ember.get(this, 'user');

      if (Ember.isEmpty(user) || Ember.get(user, 'isDeleted') || Ember.get(user, 'isNew')) {
        return;
      }

      var companyCallback = function companyCallback(company) {
        if (Ember.isEmpty(company)) {
          return;
        }

        company.rollbackAttributes();
        var location = Ember.get(company, 'location');

        if (Ember.isEmpty(location)) {
          return;
        }

        location.rollbackAttributes();
      };

      user.rollbackAttributes();
      Ember.get(user, 'company').then(companyCallback);
    },

    /**
     * Creates a new user in database.
     *
     * @method _createUser
     * @private
     *
     * @return {Promise}
     */
    _createUser: function _createUser() {
      var _this3 = this;

      var promiseCallback = function promiseCallback(resolve, reject) {
        var saveUserSuccessCallback = function saveUserSuccessCallback() {
          resolve();
        };

        var saveUserFailureCallback = function saveUserFailureCallback(reason) {
          reject(reason);
        };

        var loginUserSuccessCallback = function loginUserSuccessCallback() {
          _this3._saveUser().then(saveUserSuccessCallback, saveUserFailureCallback);
        };

        var loginUserFailureCallback = function loginUserFailureCallback(reason) {
          reject(reason);
        };

        var createUserSuccessCallback = function createUserSuccessCallback() {
          _this3._loginUser().then(loginUserSuccessCallback, loginUserFailureCallback);
        };

        var createUserFailureCallback = function createUserFailureCallback(error) {
          var serverErrorMessage = Ember.get(error, 'payload.error');
          var errorMessage = Ember.get(_this3, 'l10n').t('An error occurred while trying to create your account: {{serverErrorMessage}}', {
            serverErrorMessage: serverErrorMessage
          });
          Ember.get(_this3, 'toast').error(errorMessage);
          reject();
        };

        Ember.get(_this3, 'ajax').post('/user', {
          data: {
            email: Ember.get(_this3, 'user.email'),
            password: Ember.get(_this3, 'password'),
            fullName: Ember.get(_this3, 'user.fullName'),
            timezone: Ember.get(_this3, 'user.timezone'),
            companyName: Ember.get(_this3, 'user.company.name')
          }
        }).then(createUserSuccessCallback, createUserFailureCallback);
      };

      return new Ember.RSVP.Promise(promiseCallback);
    },

    /**
     * Updates an existing user in database.
     *
     * @method _updateUser
     * @private
     *
     * @return {Promise}
     */
    _updateUser: function _updateUser() {
      var _this4 = this;

      var promiseCallback = function promiseCallback(resolve, reject) {
        var successCallback = function successCallback() {
          resolve();
        };

        var failureCallback = function failureCallback(reason) {
          reject(reason);
        };

        _this4._saveUser().then(successCallback, failureCallback);
      };

      return new Ember.RSVP.Promise(promiseCallback);
    },

    /**
     * Establishes a user session for new user.
     *
     * @method _loginUser
     * @private
     *
     * @return {Promise}
     */
    _loginUser: function _loginUser() {
      var _this5 = this;

      var promiseCallback = function promiseCallback(resolve, reject) {
        var companySuccessCallback = function companySuccessCallback(serverCompany) {
          // note: we've to copy current attributes from client
          // to server company, which was implicitly created by
          // POST request from _createUser() only with `name`!
          var sessionUser = Ember.get(_this5, 'userSession.currentUser');
          var clientCompany = Ember.get(_this5, 'user.company');
          Ember.get(_model2.default, 'fields').forEach(function (kind, key) {
            var value = Ember.get(clientCompany, key);
            Ember.set(serverCompany, key, value);
          });
          Ember.set(sessionUser, 'company', serverCompany);
          Ember.set(_this5, 'user', sessionUser);
          resolve();
        };

        var companyFailureCallback = function companyFailureCallback(reason) {
          var errorMessage = Ember.get(_this5, 'l10n').t('An error occurred while setting up your session. Please log in and try again.');
          Ember.get(_this5, 'toast').error(errorMessage);
          reject(reason);
        };

        var authenticateSuccessCallback = function authenticateSuccessCallback() {
          Ember.get(_this5, 'userSession.currentCompany').then(companySuccessCallback, companyFailureCallback);
        };

        var autenticateFailureCallback = function autenticateFailureCallback(reason) {
          var errorMessage = Ember.get(_this5, 'l10n').t('An error occurred while setting up your session. Please log in and try again.');
          Ember.get(_this5, 'toast').error(errorMessage);
          reject(reason);
        };

        Ember.get(_this5, 'session').authenticate('authenticator:custom', {
          email: Ember.get(_this5, 'user.email'),
          password: Ember.get(_this5, 'password')
        }).then(authenticateSuccessCallback, autenticateFailureCallback);
      };

      return new Ember.RSVP.Promise(promiseCallback);
    },

    /**
     * Persists company including location
     * from current user input in database .
     *
     * @method _saveUser
     * @private
     *
     * @return {Promise}
     */
    _saveUser: function _saveUser() {
      var _this6 = this;

      var promiseCallback = function promiseCallback(resolve, reject) {
        var saveSuccessCallback = function saveSuccessCallback() {
          resolve();
        };

        var saveFailureCallback = function saveFailureCallback(adapterError) {
          var serverErrorMessage = (0, _getFirstAdapterErrorMessage.default)(adapterError);
          var errorMessage = Ember.get(_this6, 'l10n').t('An error occurred while trying to save your profile: {{serverErrorMessage}}', {
            serverErrorMessage: serverErrorMessage
          });
          Ember.get(_this6, 'toast').error(errorMessage);
          reject(adapterError);
        };

        var companySuccessCallback = function companySuccessCallback(company) {
          var promises = [];

          if (Ember.get(_this6, 'showProfile')) {
            promises.push(Ember.get(_this6, 'user').save());
          }

          promises.push(company.save());
          Ember.RSVP.Promise.all(promises).then(saveSuccessCallback, saveFailureCallback);
        };

        var companyFailureCallback = function companyFailureCallback(error) {
          var serverErrorMessage = Ember.get(error, 'payload.error');
          var errorMessage = Ember.get(_this6, 'l10n').t('An error occurred while trying to fetch your company: {{serverErrorMessage}}', {
            serverErrorMessage: serverErrorMessage
          });
          Ember.get(_this6, 'toast').error(errorMessage);
          reject(error);
        };

        Ember.get(_this6, 'user.company').then(companySuccessCallback, companyFailureCallback);
      };

      return new Ember.RSVP.Promise(promiseCallback);
    },

    /**
     * Checks if a similar company already
     * exists through /search/company api.
     *
     * @method _checkCompany
     * @private
     *
     * @return {Promise}
     */
    _checkCompany: function _checkCompany() {
      var _this7 = this;

      var promiseCallback = function promiseCallback(resolve, reject) {
        var successCallback = function successCallback(companies) {
          if (Ember.isEmpty(companies)) {
            resolve();
            return;
          }

          Ember.set(_this7, 'similarCompanies', companies);
          reject(null);
        };

        var failureCallback = function failureCallback(error) {
          var serverErrorMessage = Ember.get(error, 'payload.error');
          var errorMessage = Ember.get(_this7, 'l10n').t('An error occurred while trying to fetch similar companies: {{serverErrorMessage}}', {
            serverErrorMessage: serverErrorMessage
          });
          Ember.get(_this7, 'toast').error(errorMessage);
          reject();
        };

        Ember.get(_this7, 'user.company').then(function (company) {
          Ember.get(_this7, 'ajax').request('/search/company', {
            data: {
              term: Ember.get(company, 'name')
            }
          }).then(successCallback, failureCallback);
        });
      };

      return new Ember.RSVP.Promise(promiseCallback);
    }
  });

  _exports.default = _default;
});