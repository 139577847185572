define("@vollersgroup/hub-frontend/admin/orders/seller/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WRfj+tBu",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"page-title\",[[27,\"t\",[\"Seller details\"],null]],null],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"wrapper\"],[9],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"margin-bottom-3\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"admin.orders.show\",[23,[\"model\"]]],[[\"class\"],[\"inline-block margin-bottom-3\"]],{\"statements\":[[0,\"      « \"],[1,[27,\"t\",[\"Back to order\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"     | \\n    \"],[1,[27,\"t\",[\"Profile: {{companyName}}\"],[[\"companyName\"],[[23,[\"model\",\"sellerCompany\",\"name\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[1,[27,\"user-profile\",null,[[\"userRole\",\"company\"],[\"SELLER\",[23,[\"model\",\"sellerCompany\"]]]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/admin/orders/seller/template.hbs"
    }
  });

  _exports.default = _default;
});