define("@vollersgroup/hub-frontend/admin/manage/listing/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "@vollersgroup/hub-frontend/mixins/reset-scroll", "@vollersgroup/hub-frontend/mixins/company-verified"], function (_exports, _authenticatedRouteMixin, _resetScroll, _companyVerified) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _resetScroll.default, _companyVerified.default, {
    // -------------------------------------------------------------------------
    // Dependencies
    availableData: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Loads warehouse locations for listings.
     *
     * @public
     * @method beforeModel
     * @return {Void}
     */
    afterModel: function afterModel() {
      return this.get('availableData.locations');
    }
  });

  _exports.default = _default;
});