define("@vollersgroup/hub-frontend/admin/manage/bulk/bulk-listing-route", ["exports", "@vollersgroup/hub-frontend/admin/manage/bulk/bulk-route-mixin", "@vollersgroup/hub-frontend/admin/manage/bulk/bulk-listing-mixin"], function (_exports, _bulkRouteMixin, _bulkListingMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Listing route for create and edit.
   *
   * @namespace Route
   * @uses Mixin.BulkRouteMixin
   * @uses Mixin.BulkListingMixin
   * @class BulkListingRoute
   * @extends Ember.Route
   */
  var _default = Ember.Route.extend(_bulkRouteMixin.default, _bulkListingMixin.default, {
    queryParams: {
      currentShopId: {
        as: 'shop',
        refreshModel: true
      },
      currentAuctionId: {
        as: 'auction',
        refreshModel: true
      },
      filterStatus: {
        as: 'status',
        refreshModel: true
      },
      filterOrigins: {
        as: 'origins',
        refreshModel: true
      },
      filterWarehouses: {
        as: 'warehouses',
        refreshModel: true
      },
      showArchived: {
        as: 'archived',
        refreshModel: true
      },
      showRatings: {
        as: 'ratings',
        refreshModel: true
      },
      orderBy: {
        as: 'orderBy',
        refreshModel: true
      },
      searchTerm: {
        as: 'term',
        refreshModel: true
      },
      salesNumber: {
        as: 'sales',
        refreshModel: true
      }
    },
    model: function model(params) {
      var _this = this;

      if (!Ember.get(this, 'isEdit') || !Ember.isEmpty(params.ids)) {
        return this._super.apply(this, arguments);
      }

      var query = {
        page: 1,
        pageSize: 99999,
        company: Ember.get(this, 'userSession.currentCompany.id'),
        sort: params.orderBy || 'DATE',
        hasRating: params.showRatings || false,
        archived: params.showArchived || false,
        auction: params.currentAuctionId || '',
        shop: params.currentShopId || '',
        status: !Ember.isEmpty(params.filterStatus) ? params.filterStatus : ['DRAFT', 'ACTIVE', 'EXPIRED', 'INACTIVE', 'SOLD_OUT'],
        term: params.searchTerm || '',
        producers: params.filterOrigins,
        salesOfferNumber: params.salesNumber || ''
      };
      query.sortDirection = query.sort === 'DATE' ? 'DESC' : 'ASC';

      if (Ember.isArray(params.filterWarehouses)) {
        query.warehouses = [];
        params.filterWarehouses.forEach(function (id) {
          if (!id || !_this.store.hasRecordForId('location', id)) {
            return;
          }

          var warehouse = _this.store.peekRecord('location', id);

          query.warehouses.push(Ember.get(warehouse, 'name'));
        });
      }

      return this.store.query(Ember.get(this, 'modelName'), query);
    }
  });

  _exports.default = _default;
});