define("@vollersgroup/hub-frontend/components/user-profile/component", ["exports", "@vollersgroup/hub-frontend/utils/get-first-adapter-error-message"], function (_exports, _getFirstAdapterErrorMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The base user profile for compact and extended public user profiles.
   * Provide either `user` or `company` together with a `userRole`:
   *
   * - `REGISTRANT`
   * - `SELLER`
   * - `BUYER`
   *
   * Optionally, hide stats/address with `showStats` or `showAddress`.
   * Additionally, provide `shippingAddress` and `billingAddress`.
   *
   * ```html
   * {{#user-profile
   *    user=user
   *    company=company
   *    showStats=true|false
   *    showAddress=true|false
   *    blacklistItem=blacklistItem
   *    billingAddress=billingAddress
   *    shippingAddress=shippingAddress
   *    userRole='BUYER|SELLER|REGISTRANT'}}
   *    {{!-- Custom Content --}}
   * {{/user-profile}}
   * ```
   *
   * @namespace Component
   * @class UserProfile
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    l10n: Ember.inject.service(),
    toast: Ember.inject.service(),
    store: Ember.inject.service(),
    showStats: false,
    showAddress: true,
    showContact: true,
    userRole: 'BUYER',
    isBusy: false,
    reportNotes: null,
    blacklistItem: null,
    showReportModal: false,
    user: null,
    company: Ember.computed.reads('user.company'),
    shippingAddress: null,
    billingAddress: Ember.computed.reads('company.location'),
    country: Ember.computed.reads('company.location.country'),
    logo: Ember.computed('user.avatarUrl', 'company.logoUrl', function () {
      var avatar = Ember.get(this, 'user.avatarUrl');
      var icon = Ember.get(this, 'company.logoUrl');
      return avatar || icon || null;
    }),
    isBuyer: Ember.computed.equal('userRole', 'BUYER'),
    isSeller: Ember.computed.equal('userRole', 'SELLER'),
    isRegistrant: Ember.computed.equal('userRole', 'REGISTRANT'),
    shippingEqualsBilling: Ember.computed('shippingAddress.{fullName,company,country,state,zip,city,street,addressAddition,phone}', 'billingAddress.{fullName,company,country,state,zip,city,street,addressAddition,phone}', function () {
      var billing = Ember.get(this, 'billingAddress');
      var shipping = Ember.get(this, 'shippingAddress'); // assume equality if billing but no shipping address

      if (billing && !shipping) {
        return true;
      } // both addresses are required for equality checks


      if (!billing || !shipping) {
        return false;
      }

      return shipping.isEqual(billing);
    }),
    prefillUser: Ember.computed.or('user', 'company.owner'),
    messageMode: Ember.computed('userRole', 'user', function () {
      var userRole = Ember.get(this, 'userRole');
      var user = Ember.get(this, 'user');

      switch (userRole) {
        case 'REGISTRANT':
        case 'BUYER':
          return user ? 'USER' : 'COMPANY';

        case 'SELLER':
          return 'COMPANY';

        default:
          (false && !(false) && Ember.assert("Invalid userrole: ".concat(userRole)));
      }
    }),
    messageTo: Ember.computed('company.id', 'messageMode', 'user.id', function () {
      var messageMode = Ember.get(this, 'messageMode');
      var companyId = Ember.get(this, 'company.id');
      var userId = Ember.get(this, 'user.id');

      switch (messageMode) {
        case 'COMPANY':
          return companyId;

        case 'USER':
          return userId;

        default:
          (false && !(false) && Ember.assert("Invalid message mode: ".concat(messageMode)));
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      (false && !(!!Ember.get(this, 'user') || !!Ember.get(this, 'company')) && Ember.assert('You must either provider a user OR a company.', !!Ember.get(this, 'user') || !!Ember.get(this, 'company')));
    },
    actions: {
      reportUser: function reportUser(closeModal) {
        var _this = this;

        var l10n = Ember.get(this, 'l10n');
        var toast = Ember.get(this, 'toast');
        var store = Ember.get(this, 'store');
        var notes = Ember.get(this, 'reportNotes');
        var flaggedCompany = Ember.get(this, 'company');

        var successCallback = function successCallback(blacklistItem) {
          toast.success(l10n.t('Thanks for reporting this buyer - we will investigate as soon as possible!')); // set manually after creation as inverse
          // relationship isn't updated automatically

          Ember.set(_this, 'blacklistItem', blacklistItem);
          closeModal();
        };

        var failureCallback = function failureCallback(error) {
          toast.error(l10n.t('An error occurred while reporting this user: {{serverErrorMessage}}', {
            serverErrorMessage: (0, _getFirstAdapterErrorMessage.default)(error)
          }));
        };

        var finallyCallback = function finallyCallback() {
          Ember.set(_this, 'isBusy', false);
        };

        var blacklistItem = store.createRecord('blacklist-item', {
          flaggedCompany: flaggedCompany,
          notes: notes
        });
        Ember.set(this, 'isBusy', true);
        return blacklistItem.save().then(successCallback, failureCallback).finally(finallyCallback);
      }
    }
  });

  _exports.default = _default;
});