define("@vollersgroup/hub-frontend/components/message-detail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "engnkdBy",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"flex width-100p\"],[9],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"flex flex-shrink-0\"],[9],[0,\"\\n    \"],[7,\"img\"],[12,\"src\",[21,\"avatarUrl\"]],[12,\"alt\",[21,\"avatarAlt\"]],[11,\"class\",\"width-2 border-radius-full self-start\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"message__detail__text flex flex-grow-1 flex-column padding-left-2 padding-right-2\"],[9],[0,\"\\n    \"],[7,\"div\"],[12,\"class\",[28,[\"bold \",[27,\"unless\",[[23,[\"isOpen\"]],\"truncate\"],null]]]],[9],[0,\"\\n      \"],[1,[23,[\"message\",\"fromUser\",\"fullName\"]],false],[0,\", \"],[1,[23,[\"message\",\"fromCompany\",\"name\"]],false],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[12,\"class\",[27,\"unless\",[[23,[\"isOpen\"]],\"truncate\"],null]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isOpen\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"format-nl2br\",[[23,[\"message\",\"text\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[23,[\"message\",\"text\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"flex justify-end flex-shrink-0 width-4 font-size-7\"],[9],[0,\"\\n    \"],[1,[27,\"moment-format\",[[23,[\"message\",\"created\"]]],null],false],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/message-detail/template.hbs"
    }
  });

  _exports.default = _default;
});