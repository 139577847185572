define("@vollersgroup/hub-frontend/admin/manage/bulk/producer-edit/controller", ["exports", "@vollersgroup/hub-frontend/admin/manage/bulk/bulk-producer-controller"], function (_exports, _bulkProducerController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Producer controller for edit.
   *
   * @namespace Controller
   * @class BulkProducerEditController
   * @extends BulkProducerController
   */
  var _default = _bulkProducerController.default.extend({
    // -------------------------------------------------------------------------
    // Properties

    /**
     * @property isEdit
     */
    isEdit: true
  });

  _exports.default = _default;
});