define("@vollersgroup/hub-frontend/admin/manage/shop/edit/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "@vollersgroup/hub-frontend/mixins/reset-scroll", "ember-can"], function (_exports, _authenticatedRouteMixin, _resetScroll, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The route to edit a shop.
   *
   * @namespace Route
   * @class ManageShopCreate
   * @extends Ember.Route
   * @uses Mixin.CanMixin
   * @uses Mixin.ResetScroll
   * @uses SimpleAuth.Mixin.AuthenticatedRouteMixin
   */
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _resetScroll.default, _emberCan.CanMixin, {
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Renders templates for admin route manually.
     * Per default, it renders secondary nav items
     * from manage pod to `secondary-nav` outlet.
     *
     * @public
     * @method renderTemplate
     * @return {Void}
     */
    renderTemplate: function renderTemplate() {
      this.controller.set('navbar', {
        route: 'admin.manage.overview.shop',
        title: Ember.get(this, 'l10n').t('Edit shop')
      });
      this.render();
      this.render('admin/manage/navbar-subpage', {
        outlet: 'secondary-nav',
        into: 'admin'
      });
    },

    /**
     * Invokes a change for controller's `allSteps`.
     *
     * @public
     * @method deactivate
     * @return {Void}
     */
    activate: function activate() {
      var _this = this;

      Ember.run.once(function () {
        _this.controller.notifyPropertyChange('allSteps');
      });
    },

    /**
     * Performs ability check if user can create a shop.
     *
     * @public
     * @method beforeModel
     * @return {Promise}
     */
    beforeModel: function beforeModel() {
      // Only managers are allowed to create / edit a shop
      if (!this.can('edit shop')) {
        this.transitionTo('admin.manage.overview.shop');
        return;
      }

      return this._super.apply(this, arguments);
    },

    /**
     * Get selected shop object.
     *
     * @public
     * @method model
     * @returns {Model.Shop}
     */
    model: function model(params) {
      return this.store.findRecord('shop', params.shop_id);
    },

    /**
     * Abort the transition if the model
     * is not editable, otherwise load
     * corresponding company settings.
     *
     * @method afterModel
     * @param model
     * @param transition
     * @return {Promise}
     */
    afterModel: function afterModel(model, transition) {
      var _this2 = this;

      var abortTransition = function abortTransition() {
        Ember.get(_this2, 'toast').warning(Ember.get(_this2, 'l10n').t('You cannot edit this shop!'));

        if (transition.sequence === 0) {
          _this2.transitionTo('admin.manage.overview.shop');
        } else {
          transition.abort();
        }
      };

      if (!Ember.get(model, 'isEditable')) {
        abortTransition();
        return;
      }

      return Ember.get(model, 'company').then(function (company) {
        var currentId = Ember.get(_this2, 'userSession.currentCompany.id');

        if (company.id !== currentId) {
          abortTransition();
        }
      });
    },

    /**
     * Resets route's model and controller step.
     *
     * @public
     * @method deactivate
     * @return {Void}
     */
    deactivate: function deactivate() {
      this.currentModel.rollbackAttributes();
      Ember.set(this, 'controller.step', 0);
    },
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Redirect to overview on errors.
       *
       * @event error
       * @return {Void}
       */
      error: function error() {
        Ember.get(this, 'toast').warning(Ember.get(this, 'l10n').t('You cannot edit this shop!'));
        this.transitionTo('admin.manage.overview.shop');
      }
    }
  });

  _exports.default = _default;
});