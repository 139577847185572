define("@vollersgroup/hub-frontend/bid/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var belongsTo = _emberData.default.belongsTo;
  var attr = _emberData.default.attr;

  /**
   * The bid model
   *
   * @namespace Model
   * @class Bid
   * @extends Model
   */
  var _default = Model.extend({
    /**
     * The amount of money which is bid per base unit.
     *
     * @attribute bidAmount
     * @type Number
     */
    bidAmount: attr('number'),

    /**
     * The currency of the bid.
     *
     * @attribute bidCurrency
     * @type Number
     */
    bidCurrency: attr('string'),

    /**
     * The date the bid was made.
     *
     * @attribute createdDate
     * @type Date
     */
    createdDate: attr('timestamp'),

    /**
     * The listing the bid belongs to.
     *
     * @attribute listing
     * @type Model.Listing
     */
    listing: belongsTo('listing', {
      async: true,
      inverse: 'bids'
    }),

    /**
     * The company that has made this bid.
     * Is null during auctions if not your own company.
     *
     * @attribute company
     * @type Model.Company|null
     */
    company: belongsTo('company', {
      async: true
    }),

    /**
     * The user that has made this bid.
     * Is null during auctions if not by your own company.
     *
     * @attribute user
     * @type Model.User|null
     */
    user: belongsTo('user', {
      async: true
    }),

    /**
     * The ID of the autoBid of this bid.
     * This is only set for your own bids or if you are an admin of this auction.
     *
     * @attribute autoBid
     * @type String
     * @optional
     */
    autoBid: attr('string'),

    /**
     * Anonymized company number
     * This number is distinct for each auction.
     * It is used to track if the same company is bidding repeatedly, but without knowing which company it actually is.
     *
     * @attribute participantName
     * @type String
     */
    participantName: attr('string'),

    /**
     * The id of the bidding auction participant.
     *
     * @attribute auctionParticipant
     * @type String
     */
    auctionParticipant: attr('string'),

    /**
     * The id as a number. Mostly used in sorting operations.
     *
     * @attribute numericId
     * @type Number
     */
    numericId: Ember.computed('id', function () {
      return Ember.get(this, 'id') * 1 || null;
    }),
    isOwn: Ember.computed('auctionParticipant', 'listing.auction.currentAuctionParticipant.id', function () {
      if (!this.get('listing.auction.currentAuctionParticipant.id')) {
        return false;
      }

      return this.get('auctionParticipant') === this.get('listing.auction.currentAuctionParticipant.id');
    })
  });

  _exports.default = _default;
});