define("@vollersgroup/hub-frontend/components/flash-messages/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n7Z/oiAx",
    "block": "{\"symbols\":[\"message\",\"message\",\"message\",\"&default\"],\"statements\":[[4,\"if\",[[23,[\"flashMessages\",\"hasMessage\"]]],null,{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[27,\"unless\",[[23,[\"noMargin\"]],\"margin-bottom-4\"],null]],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"flashMessages\",\"errorMessages\"]]],null,{\"statements\":[[4,\"info-message\",null,[[\"type\",\"class\",\"title\"],[\"ERROR\",\"margin-bottom-2\",[22,3,[\"title\"]]]],{\"statements\":[[0,\"        \"],[1,[22,3,[\"message\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[3]},null],[0,\"\\n\"],[4,\"each\",[[23,[\"flashMessages\",\"successMessages\"]]],null,{\"statements\":[[4,\"info-message\",null,[[\"type\",\"class\",\"title\"],[\"SUCCESS\",\"margin-bottom-2\",[22,2,[\"title\"]]]],{\"statements\":[[0,\"        \"],[1,[22,2,[\"message\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[2]},null],[0,\"\\n\"],[4,\"each\",[[23,[\"flashMessages\",\"infoMessages\"]]],null,{\"statements\":[[4,\"info-message\",null,[[\"type\",\"class\",\"title\"],[\"INFO\",\"margin-bottom-2\",[22,1,[\"title\"]]]],{\"statements\":[[0,\"        \"],[1,[22,1,[\"message\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"\\n    \"],[14,4],[0,\"\\n  \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/flash-messages/template.hbs"
    }
  });

  _exports.default = _default;
});