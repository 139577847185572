define("@vollersgroup/hub-frontend/checkout/review/route", ["exports", "@vollersgroup/hub-frontend/mixins/reset-scroll", "moment", "@vollersgroup/hub-frontend/utils/get-first-adapter-error-message"], function (_exports, _resetScroll, _moment, _getFirstAdapterErrorMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_resetScroll.default, {
    checkout: Ember.inject.service(),
    shoppingCart: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    toast: Ember.inject.service(),
    userSession: Ember.inject.service(),
    ajax: Ember.inject.service(),
    availableData: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var checkout = this.get('checkout');
      var checkoutSteps = checkout.get('steps');

      if (!Ember.get(checkoutSteps[checkoutSteps.length - 2], 'isDone')) {
        this.transitionTo(Ember.get(checkoutSteps[checkoutSteps.length - 2], 'route'));
        return;
      }

      this.get('checkout').set('currentStep', checkoutSteps.length - 1);
    },
    actions: {
      putOrder: function putOrder() {
        var _this2 = this;

        var order = this.currentModel;
        var checkout = this.get('checkout');
        var controller = this.controllerFor(this.get('routeName'));
        var flashMessages = this.get('flashMessages');
        flashMessages.clear();
        controller.set('isSaving', true);
        order.setProperties({
          buyerCompany: this.get('userSession.currentCompany'),
          vatNumber: checkout.get('vatNumber'),
          shippingAddress: checkout.get('shippingAddress'),
          billingAddress: checkout.get('billingAddress'),
          requestCreatedDate: (0, _moment.default)()
        });
        var priorPaymentRequired = order.get('priorPaymentRequired');
        var steps = checkout.get('steps');

        if (order.get('isNew')) {
          order.set('status', 'CART');
          order.save().then(function (order) {
            // This is reset because the server doesn't know yet that prior payment is required
            // Because of this, it has to be set again here
            order.set('priorPaymentRequired', priorPaymentRequired); // Also reset the steps' statuses

            var newSteps = checkout.get('steps');

            for (var i = 0; i < newSteps.length; i++) {
              Ember.set(newSteps[i], 'isDone', Ember.get(steps[i], 'isDone'));
            }

            _this2._processCart(order);
          }).catch(function (adapterError) {
            var serverErrorMessage = (0, _getFirstAdapterErrorMessage.default)(adapterError);
            var errorMessage = Ember.get(_this2, 'l10n').t('An error occurred while creating your order: {{serverErrorMessage}}', {
              serverErrorMessage: serverErrorMessage
            });

            _this2._setError(errorMessage);
          });
        } else {
          this._processCart(order);
        }
      }
    },
    _processCart: function _processCart(order) {
      var _this3 = this;

      this._saveOrderItems(order).then(function () {
        if (!order.get('priorPaymentRequired')) {
          _this3._changeOrderToRequest(order);
        } else {
          _this3._processPayment(order);
        }
      });
    },
    _saveOrderItems: function _saveOrderItems(order) {
      var _this4 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        order.get('orderItems').then(function (orderItems) {
          var openOrderItems = orderItems.toArray();
          var _this = _this4;

          var _saveNextOrderItem = function _saveNextOrderItem() {
            var _this5 = this;

            var firstOrderItem = openOrderItems.splice(0, 1);
            firstOrderItem = firstOrderItem.length === 1 ? firstOrderItem[0] : null;

            if (firstOrderItem) {
              if (firstOrderItem.get('hasDirtyAttributes')) {
                firstOrderItem.save().then(function () {
                  _saveNextOrderItem();
                }).catch(function (adapterError) {
                  var serverErrorMessage = (0, _getFirstAdapterErrorMessage.default)(adapterError);
                  var errorMessage = Ember.get(_this5, 'l10n').t('An error occurred while saving order items: {{serverErrorMessage}}', {
                    serverErrorMessage: serverErrorMessage
                  });

                  _this._setError(errorMessage);

                  reject();
                });
              } else {
                _saveNextOrderItem();
              }
            } else {
              resolve(order);
            }
          };

          _saveNextOrderItem();
        });
      });
    },
    _processPayment: function _processPayment(order) {
      var _this6 = this;

      if (order.get('paid')) {
        this._changeOrderToRequest(order);
      }

      order.set('status', 'REQUEST');
      var orderJSON = order.toJSON();
      orderJSON.id = order.get('id');
      this.get('ajax').post("/order/".concat(order.get('id'), "/pay?token=").concat(this.get('checkout.cardToken')), {
        data: orderJSON
      }).then(function () {
        order.reload().then(function (order) {
          _this6._completeOrder(order);
        });
      }).catch(function (error) {
        order.set('status', 'CART');
        var serverErrorMessage = Ember.get(error, 'payload.error');

        var errorMessage = _this6.get('l10n').t('An error occurred while trying to process the payment: {{serverErrorMessage}}', {
          serverErrorMessage: serverErrorMessage
        });

        _this6._setError(errorMessage);
      });
    },
    _changeOrderToRequest: function _changeOrderToRequest(order) {
      var _this7 = this;

      if (order.get('auction') && order.get('auction.content') && order.get('hasCoffee')) {
        order.set('status', 'ACCEPTED');
      } else {
        order.set('status', 'REQUEST');
      }

      order.save().then(function (order) {
        _this7._completeOrder(order);
      }).catch(function (adapterError) {
        order.set('status', 'CART');

        _this7._setError(adapterError);
      });
    },
    _completeOrder: function _completeOrder(order) {
      var controller = this.controllerFor(this.get('routeName'));
      var flashMessages = this.get('flashMessages');
      flashMessages.clear();

      this._initConversationForOrder(order);

      this.get('shoppingCart').removeCart(order, true);
      controller.set('isSaving', false);
      flashMessages.addSuccess({
        title: this.get('l10n').t('Your purchase has been successfully handled.'),
        message: this.get('l10n').t('The seller will get back to you with details shortly.')
      });
      this.transitionTo('cart');
    },
    _initConversationForOrder: function _initConversationForOrder(order) {
      var _this8 = this;

      order.get('conversation').then(function (conversation) {
        if (!conversation) {
          return;
        }

        if (_this8.get('checkout.initialMessage')) {
          var message = _this8.store.createRecord('message', {
            conversation: conversation,
            text: _this8.get('checkout.initialMessage'),
            created: (0, _moment.default)(),
            fromUser: _this8.get('userSession.currentUser'),
            fromCompany: _this8.get('userSession.currentCompany')
          });

          message.save().then(function () {
            conversation.reload();
          });
        }
      });
    },
    _setError: function _setError(adapterError) {
      var controller = this.controllerFor(this.get('routeName'));
      var flashMessages = this.get('flashMessages');
      var toast = this.get('toast');
      controller.set('isSaving', false);
      var errorMessage = (0, _getFirstAdapterErrorMessage.default)(adapterError);
      flashMessages.setErrors([errorMessage]);
      toast.error(errorMessage);
    }
  });

  _exports.default = _default;
});