define("@vollersgroup/hub-frontend/admin/manage/bulk/listing/route", ["exports", "@vollersgroup/hub-frontend/admin/manage/bulk/bulk-listing-route"], function (_exports, _bulkListingRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Listing bulk route for edit.
   *
   * @namespace Route
   * @class BulkListingCreateRoute
   * @extends BulkListingRoute
   */
  var _default = _bulkListingRoute.default.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    availableData: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * @property defaultStep
     * @type {String}
     * @public
     */
    defaultStep: 'shop',
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Preloads settings, shops, locations and producers from user.
     *
     * @public
     * @method model
     * @param {Object} params
     * @return {RSVP.Promise}
     */
    model: function model()
    /*params*/
    {
      return Ember.RSVP.hash({
        settings: Ember.get(this, 'userSession.currentCompany.settings'),
        locations: Ember.get(this, 'availableData.locations'),
        producers: Ember.get(this, 'availableData.producers'),
        shops: Ember.get(this, 'availableData.shops'),
        step: Ember.get(this, 'defaultStep')
      });
    }
  });

  _exports.default = _default;
});