define("@vollersgroup/hub-frontend/admin/manage/overview/warehouse/controller", ["exports", "@vollersgroup/hub-frontend/mixins/orderable"], function (_exports, _orderable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_orderable.default, {
    // -------------------------------------------------------------------------
    // Dependencies
    availableData: Ember.inject.service(),
    userSession: Ember.inject.service(),
    l10n: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * If only archived shops should be displayed.
     *
     * @property showArchived
     * @type Boolean
     * @default false
     */
    showArchived: false,

    /**
     * All producers for this company.
     *
     * @property producers
     * @type Model.Producer[]
     */
    addresses: Ember.computed('availableData.locations.length', 'orderBy', 'showArchived', 'store', 'userSession.currentCompany.id', function () {
      if (!this.get('availableData.locations.length')) {
        return [];
      }

      var query = {
        company: this.get('userSession.currentCompany.id'),
        archived: this.get('showArchived'),
        sort: this.get('orderBy'),
        type: 'WAREHOUSE'
      };
      return this.store.query('location', query);
    }),
    orderBy: 'NAME',
    orderByOptions: Ember.computed('l10n', function () {
      return [{
        value: 'NAME',
        label: this.get('l10n').t('Name')
      }, {
        value: 'COUNTRY',
        label: this.get('l10n').t('Country')
      }, {
        value: 'CITY',
        label: this.get('l10n').t('City')
      }, {
        value: 'STATE',
        label: this.get('l10n').t('State')
      }];
    }),
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Archive a warehouse.
       *
       * @event archiveProducer
       * @param Model.Location warehouse
       * @private
       */
      archiveLocation: function archiveLocation(location) {
        var _this = this;

        location.set('archived', true);
        location.save().then(function () {
          _this.notifyPropertyChange('orderBy');

          _this.get('toast').info(_this.get('l10n').t('The warehouse has been archived.'));
        });
      },

      /**
       * Unarchive a warehouse.
       *
       * @event unarchiveProducer
       * @param Model.Producer producer
       * @private
       */
      unarchiveLocation: function unarchiveLocation(location) {
        var _this2 = this;

        location.set('archived', false);
        location.save().then(function () {
          _this2.notifyPropertyChange('orderBy');

          _this2.get('toast').info(_this2.get('l10n').t('The warehouse has been unarchived.'));
        });
      }
    }
  });

  _exports.default = _default;
});