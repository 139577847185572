define("@vollersgroup/hub-frontend/helpers/format-currency", ["exports", "@vollersgroup/hub-frontend/helpers/format-number"], function (_exports, _formatNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatCurrency = formatCurrency;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function formatCurrency(number, currency) {
    var afterComma = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2;
    var language = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'en';

    if (Ember.typeOf(number) !== 'number' || !currency) {
      return '';
    }

    var num = (0, _formatNumber.formatNumber)([number], {
      lang: language,
      showDecimal: afterComma
    });
    return "".concat(Ember.get(currency, 'sign'), " ").concat(num, " ").concat(Ember.get(currency, 'label'));
  }

  var _default = Ember.Helper.extend({
    userSession: Ember.inject.service(),
    availableData: Ember.inject.service(),
    compute: function compute(_ref, options) {
      var _ref2 = _slicedToArray(_ref, 2),
          value = _ref2[0],
          currencyCode = _ref2[1];

      var language = this.get('userSession.language');
      var currencies = this.get('availableData.currencies');
      var currency = currencies.findBy('value', currencyCode);
      var afterComma = Ember.get(options, 'afterComma') || 2;

      if (value) {
        value *= 1;
      }

      return formatCurrency(value, currency, afterComma, language);
    }
  });

  _exports.default = _default;
});