define("@vollersgroup/hub-frontend/admin/manage/bulk/listing-edit/route", ["exports", "@vollersgroup/hub-frontend/admin/manage/bulk/bulk-listing-route"], function (_exports, _bulkListingRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Bulk listing route for edit.
   *
   * @namespace Route
   * @class BulkListingEditRoute
   * @extends BulkListingRoute
   */
  var _default = _bulkListingRoute.default.extend({
    // -------------------------------------------------------------------------
    // Properties

    /**
     * @property isEdit
     */
    isEdit: true,
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Preloads settings, locations and producers from user.
     *
     * @public
     * @method beforeModel
     * @param {Object} transition
     * @return {RSVP.Promise}
     */
    beforeModel: function beforeModel()
    /*transition*/
    {
      return Ember.RSVP.hash({
        settings: Ember.get(this, 'userSession.currentCompany.settings'),
        locations: Ember.get(this, 'availableData.locations'),
        producers: Ember.get(this, 'availableData.producers')
      });
    }
  });

  _exports.default = _default;
});