define("@vollersgroup/hub-frontend/admin/manage/overview/warehouses/owner/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TKvoQGRu",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"page-title\",[[27,\"t\",[\"Choose Warehouse Owner\"],null]],null],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"wrapper padding-bottom-3\"],[9],[0,\"\\n  \"],[7,\"h1\"],[11,\"class\",\"font-size-3\"],[9],[1,[27,\"t\",[\"Choose Warehouse Owner\"],null],false],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"text-gray-500\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"Please choose the company that owns or manages the warehouse.\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"wrapper padding-bottom-4\"],[9],[0,\"\\n    \"],[1,[21,\"warehouses/warehouse-owner/select\"],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/admin/manage/overview/warehouses/owner/index/template.hbs"
    }
  });

  _exports.default = _default;
});