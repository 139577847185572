define("@vollersgroup/hub-frontend/components/expandable-text/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A text block which can be expanded by clicking on "read more"
   *
   * ```html
   * {{expandable-text text=myText}}
   * ```
   *
   * @namespace Component
   * @class ExpandableText
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * The text to show
     *
     * @attribute text
     * @type String
     * @required
     * @public
     */
    text: '',
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Toggle the expanded text
       *
       * @event toggle
       * @private
       */
      toggle: function toggle() {
        this.toggleProperty('isOpen');

        if (this.get('action')) {
          this.sendAction('action', this.get('isOpen'));
        }
      }
    },
    // -------------------------------------------------------------------------
    // Properties

    /**
     * If the full text is displayed or not
     *
     * @property isOpen
     * @type Boolean
     * @private
     */
    isOpen: false,

    /**
     * How many characters should be displayed when not expanded
     *
     * @property charLimit
     * @type Number
     * @final
     * @private
     */
    charLimit: 400,

    /**
     * The shortened text
     *
     * @property textShort
     * @type String
     * @private
     */
    textShort: Ember.computed('charLimit', 'text', function () {
      var text = this.getAttr('text');

      if (!text) {
        return '';
      }

      text = text.toString().replace(/(<([^>]+)>)/gi, '').substring(0, this.get('charLimit'));
      return text + '...';
    }),

    /**
     * If the text is long enough to be expandable
     *
     * @property isExpandable
     * @type Boolean
     * @private
     */
    isExpandable: Ember.computed('charLimit', 'text', function () {
      if (!this.getAttr('text')) {
        return false;
      }

      return this.getAttr('text').toString().length > this.get('charLimit');
    })
  });

  _exports.default = _default;
});