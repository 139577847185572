define("@vollersgroup/hub-frontend/settings/serializer", ["exports", "@vollersgroup/hub-frontend/application/serializer"], function (_exports, _serializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _serializer.default.extend({
    /**
     * We use plural model name per default,
     * as Settings always contains multiple.
     *
     * @public
     * @method modelNameFromPayloadKey
     * @param  {String} payloadKey
     * @return {String}
     */
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
      switch (payloadKey) {
        case 'settings':
          return payloadKey;

        default:
          return this._super.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});