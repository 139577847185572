define("@vollersgroup/hub-frontend/helpers/get-constant-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * This helper makes a simple lookup within available data
   * to retrieve the actual translation for given constant
   * value, which are all collected within the service.
   *
   * ```html
   * {{get-constant-label 'YOUR_CONSTANT' context='optionalContext'}}
   * ```
   *
   * @namespace Helper
   * @extends Ember.Helper
   * @class GetConstantLabel
   * @public
   */
  var _default = Ember.Helper.extend({
    availableData: Ember.inject.service(),

    /*
     * Recompute label when switching language.
     */
    _watchForLanguageChange: Ember.observer('availableData.constants', function () {
      this.recompute();
    }),

    /**
     * Makes hash map lookup for constant translations.
     *
     * @method compute
     * @param {String} constant
     * @param {Object} [hash={}]
     * @public
     *
     * @return {String}
     */
    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 1),
          constant = _ref2[0];

      var hash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (Ember.typeOf(constant) !== 'string') {
        return constant;
      }

      var constants = Ember.get(this, 'availableData.constants');
      var item = constants[constant];

      if (Ember.isNone(item)) {
        // TODO: might be a good idea to throw an error (in dev)
        // to discover missing translations sooner.
        return constant;
      }

      var index = 0;

      if (hash.context) {
        for (var i = 0; i < item.length; i++) {
          var context = item[i].context;

          if (context === hash.context) {
            index = i;
            break;
          }
        }
      }

      return item[index].label;
    }
  });

  _exports.default = _default;
});