define("@vollersgroup/hub-frontend/auction-participant/ability", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend({
    userSession: Ember.inject.service(),
    roleLevel: Ember.computed.alias('userSession.currentUser.roleLevel'),
    canCreate: Ember.computed.gte('roleLevel', 3),
    canEdit: Ember.computed.gte('roleLevel', 3)
  });

  _exports.default = _default;
});