define("@vollersgroup/hub-frontend/components/pricing-plans/cards/pricing-header/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    prices: null,
    isCustom: Ember.computed.equal('prices', 'custom'),
    price: Ember.computed('currency', 'prices', 'billingType', function () {
      var symbol = this.currency === 'USD' ? '$' : '€';
      return "".concat(symbol).concat(this.prices[this.currency][this.billingType]);
    }),
    currentBilling: Ember.computed('billingType', 'currency', function () {
      var type = this.billingType === 'yearly' ? 'year' : 'mo';
      return "".concat(this.currency, " / ").concat(type);
    }),
    otherBilling: Ember.computed('billingType', 'currency', 'prices', function () {
      var otherType = this.billingType === 'yearly' ? 'monthly' : 'yearly';
      var otherPrice = this.prices[this.currency][otherType];
      return "Billed ".concat(otherType, " (").concat(otherPrice, ")");
    })
  });

  _exports.default = _default;
});