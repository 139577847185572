define("@vollersgroup/hub-frontend/admin/manage/bulk/producer/controller", ["exports", "@vollersgroup/hub-frontend/admin/manage/bulk/bulk-producer-controller"], function (_exports, _bulkProducerController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Producer controller for create.
   *
   * @namespace Controller
   * @class BulkProducerCreateController
   * @extends BulkProducerController
   */
  var _default = _bulkProducerController.default.extend({});

  _exports.default = _default;
});