define("@vollersgroup/hub-frontend/validators/messages", ["exports", "ember-cp-validations/validators/messages"], function (_exports, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _messages.default.extend({
    l10n: Ember.inject.service(),
    getDescriptionFor: function getDescriptionFor(attribute) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var l10n = Ember.get(this, 'l10n');
      var key = attribute;

      if (!Ember.isEmpty(options.description)) {
        key = options.description;
      }

      return l10n.tVar(key, options);
    },
    getMessageFor: function getMessageFor(message) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var l10n = Ember.get(this, 'l10n');

      if (!Ember.isEmpty(options.message)) {
        var _key = options.message;
        return l10n.tVar(_key, options);
      }

      var key = message;
      return l10n.tVar(key, options);
    }
  });

  _exports.default = _default;
});