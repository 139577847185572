define("@vollersgroup/hub-frontend/components/progress-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c4J+nnXo",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"h-100 bg-color-primary absolute top-0 left-0\"],[12,\"style\",[21,\"barStyle\"]],[9],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"flex items-center justify-center h-100 relative\"],[9],[1,[21,\"progress\"],false],[0,\" %\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/progress-bar/template.hbs"
    }
  });

  _exports.default = _default;
});