define("@vollersgroup/hub-frontend/validators/auction-bid-step", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    l10n: Ember.inject.service(),
    validate: function validate(value, options, model, _attribute) {
      (false && !(Ember.typeOf(Ember.get(model, 'listing.price')) === 'number') && Ember.assert('price must be number', Ember.typeOf(Ember.get(model, 'listing.price')) === 'number'));
      (false && !(Ember.typeOf(Ember.get(model, 'listing.auction.bidStepAmount')) === 'number') && Ember.assert('bidStepAmount must be number', Ember.typeOf(Ember.get(model, 'listing.auction.bidStepAmount')) === 'number'));
      var l10n = Ember.get(this, 'l10n');
      var errMsg = l10n.t('Bid amount must be the start price plus a multiple of the bid step.');

      if (Ember.typeOf(value) !== 'number') {
        // This might happen when user enters empty string
        return errMsg;
      } // This code assumes that bid step can only have two decimal places
      // To prevent rounding issues, all values are multiplied by 100
      // and the remaining is rounded
      // 4.10 => 410
      // 0.1 => 10
      // etc.


      var startingPrice = Math.round(Ember.get(model, 'listing.price') * 100);
      var bidStepAmount = Math.round(Ember.get(model, 'listing.auction.bidStepAmount') * 100);
      var bidAmount = Math.round(value * 100);
      var bidIncrease = bidAmount - startingPrice;
      var remainder = bidIncrease % bidStepAmount;

      if (remainder === 0) {
        return true;
      }

      return errMsg;
    }
  });

  _exports.default = _default;
});