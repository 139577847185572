define("@vollersgroup/hub-frontend/services/local-asset-loader", ["exports", "ember-ajax/services/ajax", "@vollersgroup/hub-frontend/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var assetPrependURL = _environment.default.assetPrependURL || '';

  if (assetPrependURL[assetPrependURL.length - 1] === '/') {
    assetPrependURL = assetPrependURL.substr(0, assetPrependURL.length - 1);
  }
  /**
   * This service is used to fetch data / scripts from the Ember app.
   * It has no host set and can be used to fetch data from e.g. the /assets folder.
   *
   * @namespace Service
   * @class LocalAssetLoader
   */


  var _default = _ajax.default.extend({
    host: assetPrependURL,
    headers: Ember.computed(function () {
      return {};
    }),

    /**
     * Returns a promise that loads a script file
     *
     * @param {string} url
     * @returns Ember.RSVP.Promise
     */
    loadScript: function loadScript(url) {
      return this.request(url, {
        dataType: 'script',
        xhrFields: {
          withCredentials: false
        },
        beforeSend: function beforeSend(_xhr) {} // override default from our ajax-setup initializer

      });
    }
  });

  _exports.default = _default;
});