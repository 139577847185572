define("@vollersgroup/hub-frontend/admin/messages/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JF+k1Cc/",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"page-title\",[[27,\"t\",[\"Inbox\"],null]],null],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"wrapper\"],[9],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"layout__sidebar--left layout__sidebar--left--small\"],[9],[0,\"\\n    \"],[7,\"ul\"],[11,\"class\",\"third-nav\"],[9],[0,\"\\n      \"],[7,\"li\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"admin.messages.index\"],[[\"class\"],[\"third-nav__item\"]],{\"statements\":[[0,\"          \"],[1,[27,\"t\",[\"Messages\"],null],false],[0,\"\\n\"],[4,\"if\",[[23,[\"messageCenter\",\"unreadMessages\",\"length\"]]],null,{\"statements\":[[0,\"            (\"],[1,[23,[\"messageCenter\",\"unreadMessages\",\"length\"]],false],[0,\")\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n      \"],[7,\"li\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"admin.messages.notifications\"],[[\"class\"],[\"third-nav__item\"]],{\"statements\":[[0,\"          \"],[1,[27,\"t\",[\"Notifications\"],null],false],[0,\"\\n\"],[4,\"if\",[[23,[\"messageCenter\",\"unreadNotifications\",\"length\"]]],null,{\"statements\":[[0,\"            (\"],[1,[23,[\"messageCenter\",\"unreadNotifications\",\"length\"]],false],[0,\")\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"layout__sidebar--main\"],[9],[0,\"\\n    \"],[1,[21,\"outlet\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/admin/messages/template.hbs"
    }
  });

  _exports.default = _default;
});