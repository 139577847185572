define("@vollersgroup/hub-frontend/components/message-detail-reply/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A reply box for a message
   *
   * ```html
   * {{message-reply action="newMessage"}}
   * ```
   *
   * The action receives a parameter "message". For example:
   *
   * ```js
   * export default Ember.Controller.extend({
   *     actions: {
   *         newMessage: function(message) {
   *             // actually create a new message
   *         }
   *     }
   * });
   * ```
   *
   * @namespace Component
   * @class MessageDetailReply
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * The action to trigger on the container element when a new message is sent.
     * The action receives a parameter "message" which is the new message string.
     *
     * @attribute action
     * @type String
     * @required
     */
    action: '',

    /**
     * The placeholder for the input field.
     *
     * @attribute placeholder
     * @type String
     * @default "Reply to message"
     */
    placeholder: 'Reply to message',
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Send the new message
       *
       * @event send
       * @private
       */
      send: function send() {
        var message = this.get('messageReply');
        this.sendAction('action', message);
        this.set('messageReply', '');
      }
    },
    // -------------------------------------------------------------------------
    // Properties

    /**
     * The content of the new message
     *
     * @property messageReply
     * @type String
     * @private
     */
    messageReply: ''
  });

  _exports.default = _default;
});