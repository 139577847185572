define("@vollersgroup/hub-frontend/components/manage-shop-publish/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A module to publish a shop.
   *
   * ```html
   * {{manage-shop-publish shop=myShop action="myAction"}}
   * ```
   *
   * The action is called without parameters when the shop is published.
   *
   * @namespace Component
   * @class ManageShopPublish
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * The shop that should be published.
     *
     * @attribute shop
     * @type Model.Shop
     * @required
     */
    shop: null,

    /**
     * The name of the action to call when the shop is published.
     * This action receives no parameters.
     *
     * @attribute action
     * @type string
     * @required
     */
    action: null,
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Actually publish the shop.
       * This is only possible if the company has already been verified.
       *
       * @event publish
       * @private
       */
      publish: function publish() {
        var _this = this;

        var shop = this.getAttr('shop');

        if (this.get('userSession.currentCompany.isVerified')) {
          shop.set('status', 'ACTIVE');
          this.set('isLoading', true);
          shop.save().then(function () {
            _this.get('toast').success(_this.get('l10n').t('Congratulations! You have successfully published your shop.'));

            _this.sendAction('action', false);
          }, function () {
            _this.set('isLoading', false);

            _this.get('toast').error(_this.get('l10n').t('Your shop could not be published. Have you received a verification confirmation?'));
          });
        }
      },

      /**
       * Deactivate the shop.
       * This is only possible if the company has already been verified.
       *
       * @event deactivate
       * @private
       */
      deactivate: function deactivate() {
        var _this2 = this;

        var shop = this.getAttr('shop');

        if (this.get('userSession.currentCompany.isVerified')) {
          shop.set('status', 'DRAFT');
          this.set('isLoading', true);
          shop.save().then(function () {
            _this2.get('toast').success(_this2.get('l10n').t('You have successfully deactivated your shop!'));

            _this2.sendAction('action', false);
          }, function () {
            _this2.set('isLoading', false);

            _this2.get('toast').error(_this2.get('l10n').t('Your shop could not be deactivated.'));
          });
        }
      }
    },
    // -------------------------------------------------------------------------
    // Properties

    /**
     * When this is true, some computation is currently occuring.
     *
     * @property isLoading
     * @type Boolean
     * @private
     */
    isLoading: false
  });

  _exports.default = _default;
});