define("@vollersgroup/hub-frontend/authenticators/custom", ["exports", "ember-simple-auth/authenticators/base", "ember-launch-darkly"], function (_exports, _base, _emberLaunchDarkly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    l10n: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    userSession: Ember.inject.service(),
    restore: function restore(data) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.get('ajax').request('/user/loggedIn').then(function (response) {
          if (!Ember.get(response, 'loggedIn')) {
            reject();
            return;
          }

          _this.get('ajax').request('/user/current').then(function (response) {
            _this.get('store').pushPayload('user', {
              user: response
            });

            var user = _this.get('store').peekRecord('user', response.id);

            _this.get('userSession').loggedIn(user);

            resolve(data);
          }, function () {
            reject();
          });
        });
      });
    },
    authenticate: function authenticate(credentials) {
      var _this2 = this;

      var l10n = this.get('l10n');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2.get('ajax').post('/login', {
          data: {
            username: credentials.email,
            password: credentials.password,
            rememberMe: credentials.rememberMe ? 1 : 0
          },
          dataType: 'text',
          contentType: 'application/x-www-form-urlencoded; charset=utf-8'
        }).then(function () {
          _this2.get('ajax').request('/user/current').then(function (response) {
            _this2.get('store').pushPayload('user', {
              user: response
            });

            var user = _this2.get('store').peekRecord('user', response.id);

            _this2.get('userSession').loggedIn(user);

            l10n.setLocale(user.get('locale'));
            (0, _emberLaunchDarkly.identify)({
              key: user.id,
              fullName: user.fullName,
              email: user.email
            });
            resolve({
              isAuthenticated: true
            });
          });
        }).catch(function () {
          l10n.setLocale(l10n.detectLocale());
          var errorMessage = l10n.t('Invalid Username / Password combination');
          reject(errorMessage);
        });
      });
    },
    invalidate: function invalidate(data, sessionTimeout) {
      var _this3 = this;

      var resetTranslationLocale = function resetTranslationLocale() {
        var l10n = _this3.get('l10n');

        var locale = l10n.detectLocale();
        l10n.setLocale(locale);
      }; // server responded with 401 previously,
      // so no /logout request necessary here!


      if (sessionTimeout === true) {
        resetTranslationLocale();
        return Ember.RSVP.resolve();
      }

      return new Ember.RSVP.Promise(function (resolve) {
        _this3.get('ajax').post('/logout', {
          dataType: 'text'
        }).then(resolve, resolve).finally(resetTranslationLocale);
      });
    }
  });

  _exports.default = _default;
});