define("@vollersgroup/hub-frontend/components/auction-tour-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Modal component to show a tour about auctions
   */
  var _default = Ember.Component.extend({
    l10n: Ember.inject.service(),
    step: 1,
    maxStep: 5,
    auction: null,
    title: Ember.computed('l10n', 'step', function () {
      var step = this.get('step');
      var l10n = this.get('l10n');

      switch (step) {
        case 1:
          return l10n.t('Auction - lot view');

        case 2:
        case 3:
        case 4:
          return l10n.t('Auction - bidding');

        case 5:
          return l10n.t('Auction - joining');
      } // Hopefully, this would never happen.


      return '';
    }),
    actions: {
      next: function next() {
        if (this.get('step') < this.get('maxStep')) {
          this.incrementProperty('step');
        } else {
          this.get('closeModal')();
        }
      },
      prev: function prev() {
        if (this.get('step') > 1) {
          this.decrementProperty('step');
        }
      }
    }
  });

  _exports.default = _default;
});