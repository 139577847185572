define("@vollersgroup/hub-frontend/components/filter-item-array/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DPlm7axN",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"c-nested-tree\",null,[[\"id\",\"filter\",\"valueProperty\",\"labelProperty\",\"activeProperty\",\"activateChildren\",\"deactivateParent\",\"items\",\"updateAction\"],[[23,[\"id\"]],false,\"id\",\"name\",\"active\",false,false,[27,\"mut\",[[23,[\"filteredItems\"]]],null],[27,\"action\",[[22,0,[]],\"update\"],null]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"searchable\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"filter__box__separator\"],[9],[10],[0,\"\\n  \"],[7,\"input\"],[11,\"class\",\"input__txt awesomeplete\"],[12,\"placeholder\",[27,\"t\",[\"Search further\"],null]],[11,\"type\",\"text\"],[9],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"noResults\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"filter__item__error\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"No results, try something else.\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/filter-item-array/template.hbs"
    }
  });

  _exports.default = _default;
});