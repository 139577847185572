define("@vollersgroup/hub-frontend/helpers/get-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * This helper generates a route from given params.
   * It has the same syntax as Ember's link-to helper,
   * meaning that you have to pass a route name and can
   * optionally pass models plus queryParams as a hash.
   *
   * ```html
   * {{get-route
   *    'routeName'
   *    model1
   *    model 2
   *    absoluteUrl=true
   *    queryParams=(hash param1=value1 param2=value2)}}
   * ```
   *
   * @namespace Helper
   * @extends Ember.Helper
   * @class GetRoute
   * @public
   */
  var _default = Ember.Helper.extend({
    router: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Generates route with given params.
     * Set `absoluteUrl` to true to create
     * a fully qualified URI instead of path.
     *
     * @method compute
     * @param {Array} params
     * @param {Object} [hash={}]
     * @public
     *
     * @return {String}
     */
    compute: function compute(_ref, hash) {
      var _ref2 = _toArray(_ref),
          route = _ref2[0],
          rest = _ref2.slice(1);

      if (Ember.isEmpty(route)) {
        return '';
      } // `hash` is immutable since Ember 2.10


      var params = Object.assign({}, hash);
      var baseUrl = params.absoluteUrl ? window.location.origin : '';
      delete params.absoluteUrl; // only apply `params` if there are
      // some specified, otherwise router
      // service would throw an exception

      if (Object.keys(params).length > 0) {
        rest = rest.concat([params]);
      }

      var router = Ember.get(this, 'router');
      var path = router.urlFor.apply(router, [route].concat(_toConsumableArray(rest)));
      return "".concat(baseUrl).concat(path);
    },

    /**
     * Recomputes route on transitions.
     *
     * @type observer _didTransition
     * @private
     */
    _didTransition: Ember.observer('router.currentRouteName', function () {
      this.recompute();
    })
  });

  _exports.default = _default;
});