define("@vollersgroup/hub-frontend/services/bulk-template", ["exports", "ember-bulk-manager/services/bulk-template", "@vollersgroup/hub-frontend/utils/get-first-adapter-error-message"], function (_exports, _bulkTemplate, _getFirstAdapterErrorMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bulkTemplate.default.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    toast: Ember.inject.service(),
    userSession: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Alias to company's settings model.
     *
     * @property settings
     * @type {Model.Settings}
     * @public
     */
    settings: Ember.computed.reads('userSession.currentCompany.settings'),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Fetches all templates from database.
     *
     * @method fetch
     * @return {Promise}
     * @public
     */
    fetch: function fetch() {
      var _this = this;

      var promiseCallback = function promiseCallback(resolve, reject) {
        var successCallback = function successCallback(settings) {
          var templates = Ember.get(settings, 'contents.templates');
          resolve(templates || {});
        };

        var failureCallback = function failureCallback(reason) {
          reject(reason);
        };

        Ember.get(_this, 'settings').then(successCallback, failureCallback);
      };

      return new Ember.RSVP.Promise(promiseCallback);
    },

    /**
     * Implements template persistence in database.
     * This overrides default behaviour using the
     * local storage interface from user browser.
     *
     * @method save
     * @param {Object} template
     * @return {Promise}
     * @public
     */
    save: function save(template) {
      // call internal method first to add
      // template to the internal hash map
      this.save_(template); // invoke a PUT with primitive method

      return this._writeTemplates();
    },

    /**
     * Implements template deletion in database.
     * This overrides default behaviour using the
     * local storage interface from user browser.
     *
     * @method delete
     * @param {Object} template
     * @return {Promise}
     * @public
     */
    delete: function _delete(template) {
      // call internal method first to remove
      // template from the internal hash map
      this.delete_(template); // invoke a PUT with primitive method

      return this._writeTemplates();
    },

    /**
     * Primitive method invoking a PUT on settings model.
     *
     * @method _writeTemplates
     * @return {Promise}
     * @private
     */
    _writeTemplates: function _writeTemplates() {
      var _this2 = this;

      var promiseCallback = function promiseCallback(resolve, reject) {
        var showError = function showError(adapterError) {
          var serverErrorMessage = (0, _getFirstAdapterErrorMessage.default)(adapterError);
          var errorMessage = Ember.get(_this2, 'l10n').t('An error occurred while trying to update templates: {{serverErrorMessage}}', {
            serverErrorMessage: serverErrorMessage
          });
          Ember.get(_this2, 'toast').error(errorMessage);
        };

        var saveSuccessCallback = function saveSuccessCallback() {
          resolve(true);
        };

        var saveFailureCallback = function saveFailureCallback(reason) {
          showError(reason);
          reject(reason);
        };

        var settingsSuccessCallback = function settingsSuccessCallback(settings) {
          var templates = Ember.get(_this2, 'templates');
          Ember.set(settings, 'contents.templates', templates);
          settings.save().then(saveSuccessCallback, saveFailureCallback);
        };

        var settingsFailureCallback = function settingsFailureCallback(reason) {
          showError(reason);
          reject(reason);
        };

        Ember.get(_this2, 'settings').then(settingsSuccessCallback, settingsFailureCallback);
      };

      return new Ember.RSVP.Promise(promiseCallback);
    }
  });

  _exports.default = _default;
});