define("@vollersgroup/hub-frontend/components/header-navigation/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The header nav component. Shows either the login/register links, or the navigation for logged in users.
   *
   * ```html
   * {{header-navigation}}
   * ```
   *
   * @namespace Component
   * @class HeaderNavigation
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies

    /**
     * @property shoppingCart
     * @type Service.ShoppingCart
     * @protected
     */
    shoppingCart: Ember.inject.service(),

    /**
     * @property messageCenter
     * @type Service.MessageCenter
     * @protected
     */
    messageCenter: Ember.inject.service(),

    /**
     * @property userSession
     * @type Service.UserSession
     * @protected
     */
    userSession: Ember.inject.service(),

    /**
     * @property availableData
     * @type Service.AvailableData
     * @protected
     */
    availableData: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Element Properties

    /**
     * @property classNames
     * @type Array
     * @private
     * @final
     */
    classNames: ['button__container', 'button__container--inline', 'search-bar__nav'],
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * If this is set, the user should be redirected to this route after he logged in.
     *
     * @attribute redirectAfterLogin
     * @type String
     */
    redirectAfterLogin: null,
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Log the user out
       *
       * @event invalidateSession
       * @private
       */
      invalidateSession: function invalidateSession() {
        this.get('userSession').logout();
      }
    }
  });

  _exports.default = _default;
});