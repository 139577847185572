define("@vollersgroup/hub-frontend/store/auctions/show/controller", ["exports", "@vollersgroup/hub-frontend/mixins/search"], function (_exports, _search) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_search.SearchControllerMixin, {
    queryParams: ['auctionBidders'],
    // -------------------------------------------------------------------------
    // Dependencies
    checkout: Ember.inject.service(),
    websocket: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Ordering type. Can be one of
     * "DEFAULT", "DATE" or "SCORE".
     *
     * @property orderBy
     * @public
     * @type {String}
     * @default "DEFAULT"
     */
    orderBy: 'SCORE',
    // Auction bidders modal query params
    auctionBidders: null,
    actions: {
      dismissAuctionBiddersModal: function dismissAuctionBiddersModal() {
        this.set('auctionBidders', null);
      }
    }
  });

  _exports.default = _default;
});