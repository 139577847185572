define("@vollersgroup/hub-frontend/rating/model", ["exports", "ember-data", "@vollersgroup/hub-frontend/services/l10n", "ember-cp-validations"], function (_exports, _emberData, _l10n, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var belongsTo = _emberData.default.belongsTo;
  var attr = _emberData.default.attr;
  var l10n = (0, _l10n.getSingleton)();
  var RatingValidations = (0, _emberCpValidations.buildValidations)({
    email: {
      validators: [(0, _emberCpValidations.validator)('format', {
        allowBlank: true,
        type: 'email',
        message: "Email you've provided is not valid."
      })]
    },
    notes: {
      validators: [(0, _emberCpValidations.validator)('length', {
        allowBlank: true,
        max: 200,
        message: "Notes can't be longer than 200 characters."
      })]
    }
  });

  var _default = Model.extend(RatingValidations, {
    availableData: Ember.inject.service(),
    l10n: Ember.inject.service(),

    /**
     * Creation date of the rating. Ratings will be sorted by `createdDate` DESC.
     */
    createdDate: attr('timestamp'),

    /**
     * Provided rating. Might be one of GOOD, SO_SO, BAD, null.
     */
    rating: attr('string'),

    /**
     * An array of sensorial descriptors.
     *
     * @type Array
     */
    sensorialDescriptors: attr('sensorial-descriptors', {
      defaultValue: function defaultValue() {
        return [];
      },
      label: Ember.computed({
        get: function get()
        /*key*/
        {
          return l10n.t('Flavors');
        }
      })
    }),

    /**
     * Short notes in plain text.
     */
    notes: attr('string'),

    /**
     * Private rating is not seen by seller.
     */
    private: attr('boolean', {
      defaultValue: true
    }),

    /**
     * User who provided the rating. Might be null for anonymous user.
     */
    user: belongsTo('user', {
      async: true
    }),

    /**
     * Email address of the user who gave the rating.
     */
    email: attr('string'),

    /**
     * Listing this rating belongs to.
     */
    listing: belongsTo('listing', {
      async: true,
      inverse: null
    }),

    /**
     * Mapped descriptors from ratings containing `name`.
     */
    selectedDescriptors: Ember.computed('availableData.descriptorsMap', 'sensorialDescriptors', function () {
      var sensorialDescriptorsMap = Ember.get(this, 'availableData.descriptorsMap');
      var ratingSensorialDescriptors = Ember.get(this, 'sensorialDescriptors');
      var descriptors = [];
      ratingSensorialDescriptors.forEach(function (id) {
        var descriptor = sensorialDescriptorsMap[id];

        if (descriptor) {
          descriptors.push(descriptor);
        }
      });
      return descriptors;
    })
  });

  _exports.default = _default;
});