define("@vollersgroup/hub-frontend/admin/manage/listing/create/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    availableData: Ember.inject.service(),
    l10n: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Query parameters.
     *
     * @final
     * @public
     * @type {Array}
     */
    queryParams: [{
      addToShop: {
        as: 'shop'
      }
    }, {
      addToAuction: {
        as: 'auction'
      }
    }],

    /**
     * Pre-select the shop to which the listing should be added.
     * This should be the ID of a shop.
     *
     * @property addToShop
     * @type String
     */
    addToShop: null,

    /**
     * Pre-select an auction to add the listing to once its finished.
     * This should be the ID of an auction.
     *
     * @property addToAuction
     * @type String
     */
    addToAuction: null,

    /**
     * Definition of all sub steps depending
     * on current amount of available shops.
     *
     * @public
     * @property allSteps
     * @type Array
     */
    allSteps: Ember.computed('availableData.activeShops.length', 'l10n', {
      get: function get()
      /*key*/
      {
        var steps = [];

        if (Ember.get(this, 'availableData.activeShops.length') !== 1) {
          steps.push({
            label: Ember.get(this, 'l10n').t('Select shop'),
            step: 0,
            subSteps: []
          });
        }

        steps.push({
          label: Ember.get(this, 'l10n').t('Create coffee'),
          step: 1,
          subSteps: [{
            label: Ember.get(this, 'l10n').t('Base information'),
            anchor: '#listing-manage-1'
          }, {
            label: Ember.get(this, 'l10n').t('Details'),
            anchor: '#listing-manage-2'
          }, {
            label: Ember.get(this, 'l10n').t('Sensorial & Cupping'),
            anchor: '#listing-manage-3'
          }]
        });
        steps.push({
          label: Ember.get(this, 'l10n').t('Origin'),
          step: 2,
          subSteps: []
        });
        steps.push({
          label: Ember.get(this, 'l10n').t('Publish'),
          step: 3,
          subSteps: []
        });
        return steps;
      }
    })
  });

  _exports.default = _default;
});