define("@vollersgroup/hub-frontend/search-agent/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  /**
   * The search agent model
   *
   * A simple model holding saved searches
   * of user done in store, shop or auction
   * searches by saving defined filter sets.
   *
   * @namespace Model
   * @class Setting
   * @extends Model
   */

  var _default = Model.extend({
    /**
     * Custom name of search agent.
     *
     * @attribute name
     * @type String
     */
    name: attr('string'),

    /**
     * Optional field indicating that this is
     * search agent was set using the "Follow"
     * feature. This means that it's an agent
     * without filters except of a `shop` or a
     * `auction` property in `listingFilter`.
     * So it's one of `SHOP` or `AUCTION`.
     *
     * @attribute follow
     * @type String
     */
    follow: attr('string'),

    /**
     * If set to true, user will receive daily
     * updates on saved searches whenever there
     * are new listings available for settings.
     *
     * @attribute mailEnabled
     * @type Boolean
     */
    mailEnabled: attr('boolean', {
      defaultValue: true
    }),

    /**
     * Nested object holding all filters from
     * this search agent as a POJO object.
     *
     * @attribute listingFilter
     * @type Object
     */
    listingFilter: attr({
      defaultValue: function defaultValue() {
        return {};
      }
    })
  });

  _exports.default = _default;
});