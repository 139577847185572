define("@vollersgroup/hub-frontend/components/bulk-workflow/master/create/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IxQP1Z7w",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"wrapper\"],[9],[0,\"\\n\\n\"],[0,\"  \"],[7,\"div\"],[11,\"class\",\"margin-bottom-3\"],[9],[0,\"\\n    \"],[1,[27,\"navigation-steps\",null,[[\"steps\",\"onClick\",\"class\",\"current\"],[[27,\"readonly\",[[23,[\"steps\"]]],null],[27,\"action\",[[22,0,[]],\"goToStep\"],null],\"margin-bottom-3\",[27,\"readonly\",[[23,[\"currentStep\"]]],null]]]],false],[0,\"\\n    \"],[7,\"hr\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"partialBody\"]]],null,{\"statements\":[[0,\"    \"],[15,[23,[\"partialBody\"]],[]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[14,1,[[22,0,[]],\"step_content\"]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[0,\"  \"],[15,[23,[\"partialFooter\"]],[]],[0,\"\\n\"],[10]],\"hasEval\":true}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/bulk-workflow/master/create/template.hbs"
    }
  });

  _exports.default = _default;
});