define("@vollersgroup/hub-frontend/helpers/region-label", ["exports", "@vollersgroup/hub-frontend/utils/region-data"], function (_exports, _regionData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.regionLabel = regionLabel;
  _exports.default = void 0;

  function regionLabel(params
  /*, hash*/
  ) {
    var code = params[0];

    if (!code) {
      return '';
    }

    return _regionData.default[code] || code;
  }

  var _default = Ember.Helper.helper(regionLabel);

  _exports.default = _default;
});