define("@vollersgroup/hub-frontend/components/auction-participant-table-row-listing/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h7zDa884",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\"],[11,\"width\",\"69%\"],[11,\"colspan\",\"5\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"store.listing\",[23,[\"listing\"]]],null,{\"statements\":[[0,\"    \"],[1,[23,[\"listing\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"],[7,\"td\"],[11,\"width\",\"12%\"],[9],[0,\"\\n  \"],[1,[27,\"n\",[\"{{count}} bid\",\"{{count}} bids\",[23,[\"participantBids\",\"length\"]]],[[\"count\"],[[23,[\"participantBids\",\"length\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[11,\"width\",\"19%\"],[11,\"class\",\"text-right\"],[9],[0,\"\\n  \"],[1,[27,\"format-currency\",[[23,[\"listing\",\"totalPrice\"]],[23,[\"participant\",\"auction\",\"shop\",\"currency\"]]],null],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/auction-participant-table-row-listing/template.hbs"
    }
  });

  _exports.default = _default;
});