define("@vollersgroup/hub-frontend/components/auction-bidders/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    listing: null,
    dateFormat: 'MMM D, YYYY',
    timeFormat: 'HH:mm',
    chartOptions: Ember.computed(function () {
      return {
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        },
        layout: {
          padding: 10
        },
        scales: {
          xAxes: [{
            gridLines: {
              display: false
            }
          }],
          yAxes: [{
            gridLines: {
              display: false
            }
          }]
        }
      };
    }),
    chartData: Ember.computed('listing.bids.[]', 'timeFormat', function () {
      var bids = Ember.get(this, 'listing.bids');

      if (!Ember.isArray(bids)) {
        return [];
      }

      var sortedBids = bids.sortBy('numericId');
      var timeFormat = Ember.get(this, 'timeFormat');
      return {
        labels: sortedBids.mapBy('createdDate').map(function (d) {
          return d.format(timeFormat);
        }),
        backgroundColor: '#00ff00',
        datasets: [{
          data: sortedBids.mapBy('bidAmount'),
          borderColor: '#B36F9C',
          pointBackgroundColor: '#B36F9C',
          pointBorderColor: '#ffffff',
          pointBorderWidth: 2,
          fill: false,
          // Make lines straight instead of curves
          lineTension: 0
        }]
      };
    }),
    numberOfBidders: Ember.computed('listing.bids.[]', function () {
      var bids = Ember.get(this, 'listing.bids');

      if (!Ember.isArray(bids)) {
        return 0;
      }

      return bids.mapBy('participantName').uniq().length;
    })
  });

  _exports.default = _default;
});