define("@vollersgroup/hub-frontend/components/c-radio-buttons/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A group of radio buttons.
   *
   * ```html
   * {{c-radio-buttons inline=false required=false value=selectedValue options=optionsObject}}
   *
   * @namespace Component
   * @class CRadioButtons
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    classNameBindings: ['wrapperClass'],
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * If the input is required
     *
     * @attribute required
     * @optional
     * @default false
     * @type Boolean
     */
    required: false,

    /**
     * The value of the input
     *
     * @attribute value
     * @required
     * @type String
     */
    value: '',

    /**
     * If the input is disabled
     *
     * @attribute disabled
     * @optional
     * @default false
     * @type Boolean
     */
    disabled: false,

    /**
     * The options for the radio buttons. The array elements have to be objects with `value` and `label` fields.
     *
     * @attribute options
     * @optional
     * @type Array
     * @default false
     */
    options: false,

    /**
     * If the radio buttons should be inline instead of block.
     *
     * @attribute inline
     * @type Boolean
     * @default false
     */
    inline: false,

    /**
     * The action to call when the radio group is updated.
     *
     * @attribute action
     * @type String
     * @optional
     */
    action: null,

    /**
     * Extra class that will be added to wrapping container.
     *
     * @type String
     * @optional
     */
    wrapperClass: null,

    /**
     * Extra class that will be added to each option.
     *
     * @type String
     * @optional
     */
    optionClass: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._initValue();
    },
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * @event update
       * @param {String} val The new value of the radio button group
       * @private
       */
      update: function update(val) {
        var oldVal = this.get('value');

        if (val === 'true') {
          val = true;
        }

        if (val === 'false') {
          val = false;
        }

        if (val * 1 === parseInt(val, 10)) {
          val = val * 1;
        }

        this.set('value', val);

        if (this.getAttr('action') && oldVal !== val) {
          this.sendAction('action', val);
        }
      }
    },
    // -------------------------------------------------------------------------
    // Properties

    /**
     * A random id to use for this element
     *
     * @property randomId
     * @type String
     * @private
     */
    randomId: Ember.computed(function () {
      return Math.random().toString(36).replace(/[^a-z]+/g, '');
    }),

    /**
     * Parse the options and mark the options which equals the value as selected.
     *
     * @property optionsParsed
     * @private
     */
    optionsParsed: Ember.computed('options.[]', 'randomId', 'value', function () {
      var _this = this;

      var value = this.getAttr('value');

      if (!this.getAttr('options')) {
        return [];
      }

      if (!value) {
        value = this.getAttr('options').get('firstObject.value');
      }

      return this.getAttr('options').map(function (item) {
        if (Ember.get(item, 'id') && !Ember.get(item, 'value')) {
          Ember.set(item, 'value', Ember.get(item, 'id'));
        }

        if (Ember.get(item, 'name') && !Ember.get(item, 'label')) {
          Ember.set(item, 'label', Ember.get(item, 'name'));
        }

        Ember.set(item, 'elementId', _this.get('randomId') + '_' + Ember.get(item, 'value'));
        Ember.set(item, 'isSelected', value + '' === Ember.get(item, 'value') + '');
        return item;
      });
    }),
    // -------------------------------------------------------------------------
    // Observers
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Update the field value on load.
     *
     * @method _initValue
     * @privat
     */
    _initValue: function _initValue() {
      var el = this.$().find('input:checked');

      if (!el || !el.length) {
        return;
      }

      el = el[0];
    }
  });

  _exports.default = _default;
});