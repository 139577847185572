define("@vollersgroup/hub-frontend/admin/manage/bulk/bulk-producer-controller", ["exports", "@vollersgroup/hub-frontend/admin/manage/bulk/bulk-producer-mixin", "@vollersgroup/hub-frontend/admin/manage/bulk/bulk-controller-mixin"], function (_exports, _bulkProducerMixin, _bulkControllerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Producer controller for create and edit.
   *
   * @namespace Controller
   * @uses Mixin.BulkProducerMixin
   * @uses Mixin.BulkControllerMixin
   * @class BulkProducerController
   * @extends Ember.Controller
   */
  var _default = Ember.Controller.extend(_bulkControllerMixin.default, _bulkProducerMixin.default, {
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Custom configuration for handsontable.
     *
     * @property handsontableSettings
     * @type {Object}
     * @public
     */
    handsontableSettings: Ember.computed('instance', {
      get: function get()
      /*key*/
      {
        return {
          cells: function cells(row, col, prop) {
            var data = this.instance.getSourceData();
            var type = data[row] && data[row].type;
            var cellProperties = {};

            switch (prop) {
              case 'numFarms':
              case 'numMembers':
                switch (type) {
                  case 'COOP':
                  case 'ASSOCIATION':
                    cellProperties.readOnly = false;
                    break;

                  default:
                    cellProperties.readOnly = true;
                }

                break;

              default:
            }

            return cellProperties;
          },
          readOnlyCellClassName: 'bulk-grid--readonly'
        };
      }
    }),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * @method transformToData_
     */
    transformToData_: function transformToData_(model, tableFields) {
      var json = this._super(model, tableFields.concat(['location']));

      json.subregion = Ember.get(json, 'location.subregion');
      json.country = Ember.get(json, 'location.country');
      json.region = Ember.get(json, 'location.region');
      return json;
    },

    /**
     * @method transformFromData_
     */
    transformFromData_: function transformFromData_(json, modelFields) {
      if (Ember.isNone(json.location)) {
        json.location = this.store.createRecord('location', {
          type: 'PRODUCER'
        });
      }

      Ember.set(json, 'location.subregion', json.subregion);
      Ember.set(json, 'location.country', json.country);
      Ember.set(json, 'location.region', json.region);
      delete json.subregion;
      delete json.country;
      delete json.region;
      return this._super(json, modelFields);
    },

    /**
     * @method prepareDataForSave_
     */
    prepareDataForSave_: function prepareDataForSave_(json, model) {
      if (Ember.get(model, 'isNew')) {
        json.company = Ember.get(this, 'userSession.currentCompany');
      }

      return json;
    },

    /**
     * @method afterSave_
     */
    afterSave_: function afterSave_() {
      Ember.get(this, 'availableData').notifyPropertyChange('producers');
    }
  });

  _exports.default = _default;
});