define("@vollersgroup/hub-frontend/components/loading-spinner/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Display a loading spinner
   *
   * ```html
   * {{loading-spinner}}
   * ```
   *
   * @namespace Component
   * @class LoadingSpinner
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    /**
     * If the loading spinner should be compact (without padding)
     *
     * @attribute isCompact
     * @type Boolean
     * @default false
     */
    isCompact: false
  });

  _exports.default = _default;
});