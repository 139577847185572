define("@vollersgroup/hub-frontend/components/api-data-viewer/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A simple component providing an overview about API data.
   * Can be used to show list for varieties, descriptors etc.
   * Dataset should be an available set from available-data
   * service, so make sure that it's persisted there.
   *
   * ```html
   * {{api-data-viewer dataset="YOUR_DATA_SET"}}
   * ```
   *
   * @namespace Component
   * @class APIDataViewer
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    availableData: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * Data set to retrieve from `availableData` service.
     *
     * @attribute dataSet
     * @type {String}
     */
    dataSet: '',

    /**
     * If set to true, a text input for filtering is shown.
     *
     * @attribute filterable
     * @type {Boolean}
     * @default true
     */
    filterable: true,

    /**
     * Placeholder text for filter input field.
     *
     * @attribute filterPlaceholder
     * @type {String}
     * @default 'Filter items'
     */
    filterPlaceholder: Ember.computed('l10n.locale', function () {
      return this.get('l10n').t('Filter items');
    }),

    /**
     * Search expression to filter data items by.
     *
     * @attribute filterTerm
     * @type {String}
     * @default ''
     */
    filterTerm: '',

    /**
     * Name of filter property from a single item.
     *
     * @attribute filterProperty
     * @type {String}
     * @default 'name'
     */
    filterProperty: 'name',

    /**
     * Name of label property from a single item.
     *
     * @attribute labelProperty
     * @type {String}
     * @default 'name'
     */
    labelProperty: Ember.computed.reads('filterProperty'),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Data items to retrieve from `availableData` service.
     *
     * @attribute dataItems
     * @type {Array}
     * @private
     */
    dataItems: null,

    /**
     * Internal flag to state if items are ready.
     *
     * @attribute isLoading
     * @type {Boolean}
     * @private
     */
    isLoading: true,

    /**
     * Filtered data items taking `filterBy` property into account.
     *
     * @attribute filteredItems
     * @type {Array}
     * @private
     */
    filteredItems: Ember.computed('dataItems.[]', 'filterProperty', 'filterTerm', function () {
      var _this = this;

      return this.get('dataItems').filter(function (item) {
        var prop = _this.get('filterProperty');

        var term = _this.get('filterTerm');

        var text = Ember.get(item, '_cache'); // cache original one time

        if (Ember.isNone(text)) {
          text = Ember.get(item, prop) || '';
          Ember.set(item, '_cache', text);
        } // reset if no match was found


        var regex = new RegExp(term, 'gi');
        var index = text.search(regex);

        if (index < 0) {
          Ember.set(item, prop, text);
          return false;
        } // highlight matched text in bold if any


        text = text.replace(regex, function (match) {
          return "<strong>".concat(match, "</strong>");
        });
        Ember.set(item, prop, text);
        return true;
      });
    }),
    // -------------------------------------------------------------------------
    // Methods
    init: function init() {
      this._super.apply(this, arguments);

      this.set('dataItems', []);
    },

    /**
     * Checks if `dataSet` is a valid .
     *
     * @public
     * @method didReceiveAttrs
     * @return {Void}
     */
    didReceiveAttrs: function didReceiveAttrs() {
      var _this2 = this;

      this._super.apply(this, arguments);

      this.set('isLoading', true);
      var availableData = this.get('availableData');
      var dataSet = this.get('dataSet') || '';

      if (Ember.isNone(dataSet)) {
        console.error("api-data-viewer.js: You need to provide \"dataSet\" attribute!");
        return;
      }

      var dataItems = availableData.get(dataSet);

      if (Ember.isNone(dataItems)) {
        console.error("api-data-viewer.js: The data set \"".concat(dataSet, "\"\" is not within available data service!"));
        return;
      }

      var setItems = function setItems(items) {
        try {
          _this2.set('dataItems', items.map(function (item) {
            return Ember.copy(item);
          }));

          _this2.set('isLoading', false);
        } catch (e) {
          /* could be destroyed */
        }
      };

      if (Ember.typeOf(dataItems.then) === 'function') {
        dataItems.then(setItems);
        return;
      }

      setItems(dataItems);
    }
  });

  _exports.default = _default;
});