define("@vollersgroup/hub-frontend/info-center/getting-started/offer-list-widget/route", ["exports", "@vollersgroup/hub-frontend/mixins/reset-scroll"], function (_exports, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_resetScroll.default, {
    model: function model() {
      return this.store.query('shop', {
        ids: [12, // = onyx-coffee,
        10, // = nordic-approach,
        21 // = latorre-and-dutch-coffee-traders-pty-ltd
        ]
      });
    }
  });

  _exports.default = _default;
});