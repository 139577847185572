define("@vollersgroup/hub-frontend/components/sticky-block/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7K/5GqFR",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"sticky-block \",[27,\"if\",[[23,[\"isFixed\"]],\"sticky-block--fixed\"],null],\" \",[27,\"if\",[[23,[\"isFixedBottom\"]],\"sticky-block--fixed--bottom\"],null]]]],[9],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/sticky-block/template.hbs"
    }
  });

  _exports.default = _default;
});