define("@vollersgroup/hub-frontend/listing/model", ["exports", "ember-data", "ember-data-copyable", "@vollersgroup/hub-frontend/services/l10n", "ember-cp-validations", "@vollersgroup/hub-frontend/utils/unit-calculator"], function (_exports, _emberData, _emberDataCopyable, _l10n, _emberCpValidations, _unitCalculator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var hasMany = _emberData.default.hasMany;
  var belongsTo = _emberData.default.belongsTo;
  var attr = _emberData.default.attr;
  var l10n = (0, _l10n.getSingleton)();
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      validators: [(0, _emberCpValidations.validator)('length', {
        allowNone: false,
        min: 3,
        message: 'Please enter at least 3 characters.'
      })]
    },
    icoNumber: {
      validators: [(0, _emberCpValidations.validator)('length', {
        allowNone: true,
        max: 45,
        // Limitation set in db
        message: "The ICO number can't be longer than 45 characters."
      })]
    },
    price: {
      validators: [(0, _emberCpValidations.validator)('number', {
        allowNone: false,
        allowString: false,
        gt: 0,
        message: 'Please enter a price for this listing.',
        disabled: Ember.computed.bool('model.priceDynamic')
      })]
    },
    priceBaseUnit: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true,
        message: 'You must select a base price unit for the listing.'
      })]
    },
    cmarketPremiumAmount: {
      validators: [(0, _emberCpValidations.validator)('number', {
        allowNone: false,
        gt: 0,
        message: 'You must enter a premium for this listing.',
        disabled: Ember.computed.not('model.priceDynamic').volatile()
      })]
    },
    availableAmountUnit: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true,
        message: 'You must select a package unit for the listing.'
      })]
    },
    availableAmount: {
      validators: [(0, _emberCpValidations.validator)('number', {
        allowNone: false,
        integer: true,
        gte: 0,
        message: 'Please enter available amount.'
      }), (0, _emberCpValidations.validator)('number', {
        allowNone: false,
        integer: true,
        gte: Ember.computed.alias('model.minOrderAmount'),
        disabled: Ember.computed.equal('model.availableAmount', 0),
        message: "Available amount can't be smaller than minimum package order amount."
      })]
    },
    minOrderAmount: {
      validators: [(0, _emberCpValidations.validator)('number', {
        allowNone: false,
        integer: true,
        gt: 0,
        message: 'Please enter a minimum package order amount larger than 0.'
      })]
    },
    samplePrice: {
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: true,
        allowNone: true,
        gte: 0,
        message: 'Please enter a number greater than 0, or leave it empty.'
      })]
    },
    erpId: {
      validators: [(0, _emberCpValidations.validator)('length', {
        allowNone: true,
        max: 64,
        // Limitation set in db
        message: "The Reference ID can't be longer than 64 characters."
      })]
    },
    grade: {
      validators: [(0, _emberCpValidations.validator)('length', {
        allowNone: true,
        max: 45,
        // Limitation set in db
        message: "The grade can't be longer than 45 characters."
      })]
    },
    cropYear: {
      validators: [(0, _emberCpValidations.validator)('length', {
        allowNone: true,
        max: 45,
        // Limitation set in db
        message: "The crop year can't be longer than 45 characters."
      })]
    },
    moisture: {
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: true,
        allowNone: true,
        gte: 0,
        lte: 100,
        message: 'Please enter a number from 0 to 100.'
      })]
    },
    sensorialScore: {
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: true,
        allowNone: true,
        gte: 0,
        lte: 100,
        message: 'Please enter a number from 0 to 100.'
      })]
    },
    sensorialCuppingDescription: {
      validators: [(0, _emberCpValidations.validator)('length', {
        allowNone: true,
        max: 1024,
        // Limitation set in db
        message: "The detailed cupping description can't be longer than 1024 characters."
      })]
    },
    waterActivity: {
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: true,
        allowNone: true,
        gte: 0,
        message: 'Please enter a number greater than 0.'
      })]
    },
    screenSize: {
      validators: [(0, _emberCpValidations.validator)('length', {
        allowNone: true,
        max: 45,
        // Limitation set in db
        message: "The screen size can't be longer than 45 characters."
      })]
    },
    // Validate legacyWarehouse.id instead of warehouse property itself, because
    // we want to check if the warehouse is selected, but this property
    // is never null so we check if it has an id instead => it means it's
    // saved on server and some warehouse is actually selected.
    'legacyWarehouse.id': {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true,
        message: 'Please select a warehouse.'
      })]
    },
    // Validate shop.id instead of shop property itself, because
    // we want to check if the shop is selected, but this property
    // is never null so we check if it has an id instead => it means it's
    // saved on server and some shop is actually selected.
    'shop.id': {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true,
        message: 'Please select a shop.'
      })]
    }
  });
  /**
   * The listing model
   *
   * If the listings has an associated auction, it is an auction-item.
   *
   * @namespace Model
   * @class Listing
   * @extends Model
   * @uses Mixins.UnitCalculator
   */

  var _default = Model.extend(_emberDataCopyable.default, Validations, {
    // -------------------------------------------------------------------------
    // Dependencies
    ajax: Ember.inject.service(),
    userSession: Ember.inject.service(),
    shoppingCart: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * Encoded ID.
     *
     * @attribute encId
     * @type String
     */
    encId: attr('string'),

    /**
     * The listing's title.
     *
     * @attribute name
     * @type String
     */
    name: attr('string', {
      label: l10n.t('Name'),
      printOptions: {
        selectable: true,
        selected: true,
        property: ''
      }
    }),

    /**
     * The internal number of the listing.
     * Used by the listing's owner internally.
     *
     * @attribute icoNumber
     * @type String
     * @optional
     */
    icoNumber: attr('string', {
      label: l10n.t('ICO #'),
      printOptions: {
        selectable: true,
        selected: false,
        property: ''
      }
    }),

    /**
     * The seller's internal sales offer number.
     *
     * @attribute salesOfferNumber
     * @type String
     * @optional
     */
    salesOfferNumber: attr('string', {
      label: l10n.t('Sales offer #'),
      printOptions: {
        selectable: true,
        selected: false,
        property: ''
      }
    }),

    /**
     * A short description for the listing.
     *
     * @attribute description
     * @type String
     * @optional
     */
    description: attr('string', {
      label: l10n.t('Short description'),
      printOptions: {
        selectable: true,
        selected: false,
        property: ''
      }
    }),

    /**
     * The status of this listing.
     *
     * @attribute status
     * @type "DRAFT"|"ACTIVE"|"INACTIVE"|"SOLD_OUT"|"EXPIRED"
     * @default "DRAFT"
     */
    status: attr('string', {
      defaultValue: 'DRAFT',
      label: l10n.t('Status'),
      printOptions: {
        selectable: false,
        selected: false,
        property: ''
      }
    }),

    /**
     * If the listing is archived. This is set in addition to the status. It can be combined with all statuses except
     * for ACTIVE.
     *
     * @attribute archived
     * @type Boolean
     * @default false
     */
    archived: attr('boolean', {
      defaultValue: false,
      label: l10n.t('Archived'),
      printOptions: {
        selectable: false,
        selected: false,
        property: ''
      }
    }),

    /**
     * The date when this listing was created.
     *
     * @attribute createdDate
     * @type Date
     */
    createdDate: attr('timestamp', {
      label: l10n.t('Created date'),
      printOptions: {
        selectable: false,
        selected: false,
        property: ''
      }
    }),

    /**
     * The date when this listing was first published.
     * Is null if the listing has never been published.
     *
     * @attribute firstPublishedDate
     * @type Date
     */
    firstPublishedDate: attr('timestamp', {
      label: l10n.t('First published date'),
      printOptions: {
        selectable: false,
        selected: false,
        property: ''
      }
    }),

    /**
     * The date when this listing will arrive.
     * This stands in relation to availability
     *
     * @attribute estimatedArrivalDate
     * @type Date
     */
    estimatedArrivalDate: attr('timestamp', {
      defaultValue: null,
      label: l10n.t('Estimated arrival date'),
      printOptions: {
        selectable: false,
        selected: false,
        property: ''
      }
    }),

    /**
     * The date when this listing will expire.
     *
     * @attribute expiredDate
     * @type Date
     */
    expiredDate: attr('timestamp', {
      label: l10n.t('Expired date'),
      printOptions: {
        selectable: false,
        selected: false,
        property: ''
      }
    }),

    /**
     * The date when this listing was last modified.
     *
     * @attribute lastModifiedDate
     * @type Date
     */
    lastModifiedDate: attr('timestamp', {
      label: l10n.t('Last modified date'),
      printOptions: {
        selectable: false,
        selected: false,
        property: ''
      }
    }),

    /**
     * The price for this listing.
     *
     * @attribute price
     * @type Number
     * @default null
     */
    price: attr('number', {
      label: l10n.t('Price'),
      printOptions: {
        selectable: true,
        selected: false,
        property: ''
      }
    }),

    /**
     * The price visibility for this listing.
     *
     * @attribute priceHidden
     * @type Boolean
     * @default false
     */
    priceHidden: attr('boolean', {
      defaultValue: false,
      label: l10n.t('Price hidden'),
      printOptions: {
        selectable: false,
        selected: false,
        property: ''
      }
    }),

    /**
     * The currency for this listing.
     *
     * @attribute priceCurrency
     * @type "USD"|"EUR"
     * @default "USD"
     */
    priceCurrency: attr('string', {
      defaultValue: 'USD',
      label: l10n.t('Currency'),
      printOptions: {
        selectable: false,
        selected: false,
        property: ''
      }
    }),

    /**
     * The base unit for the price.
     *
     * @attribute priceBaseUnit
     * @type "lbs"|"kg"
     * @default "lbs"
     */
    priceBaseUnit: attr('string', {
      defaultValue: 'KG',
      label: l10n.t('Base unit'),
      printOptions: {
        selectable: false,
        selected: false,
        property: ''
      }
    }),

    /**
     * The price for a sample of this listing.
     * If this is null, then the sample is free.
     *
     * @attribute samplePrice
     * @type {Number}
     */
    samplePrice: attr('number', {
      defaultValue: 0,
      label: l10n.t('Sample price'),
      printOptions: {
        selectable: true,
        selected: false,
        property: ''
      }
    }),

    /**
     * The currency for for a sample of this listing.
     *
     * @attribute samplePriceCurrency
     * @type {Number}
     */
    samplePriceCurrency: attr('string', {
      defaultValue: 'USD',
      label: l10n.t('Sample currency'),
      printOptions: {
        selectable: false,
        selected: false,
        property: ''
      }
    }),

    /**
     * The special offer flag for this listing.
     *
     * @attribute specialOffer
     * @type Boolean
     * @default false
     */
    specialOffer: attr('boolean', {
      defaultValue: false,
      label: l10n.t('Special offer'),
      printOptions: {
        selectable: false,
        selected: false,
        property: ''
      }
    }),

    /**
     * The number of available units.
     *
     * @attribute availableAmount
     * @type number
     */
    availableAmount: attr('number', {
      label: l10n.t('Available amount'),
      printOptions: {
        selectable: true,
        selected: true,
        property: ''
      }
    }),

    /**
     * The unit this listing is in. e.g. "BAG69KG"
     *
     * @attribute availableAmountUnit
     * @type String
     */
    availableAmountUnit: attr('string', {
      label: l10n.t('Package unit'),
      printOptions: {
        selectable: true,
        selected: false,
        property: ''
      }
    }),

    /**
     * The number of sold units.
     *
     * @attribute soldUnits
     * @type number
     */
    soldUnits: attr('number', {
      label: l10n.t('Sold amount'),
      printOptions: {
        selectable: false,
        selected: false,
        property: ''
      }
    }),

    /**
     * The minimum amount that can be purchased of this listing.
     *
     * @attribute minOrderAmount
     * @type Number
     * @default null
     */
    minOrderAmount: attr('number', {
      defaultValue: null,
      label: l10n.t('Min. amount'),
      printOptions: {
        selectable: true,
        selected: false,
        property: ''
      }
    }),

    /**
     * The display name of the winner of this listing.
     *
     * @attribute auctionWinningBidder
     * @type {String}
     */
    auctionWinningBidder: attr('string', {
      label: l10n.t('Auction winning bidder'),
      printOptions: {
        selectable: false,
        selected: false,
        property: ''
      }
    }),

    /**
     * If the listing is decaf.
     *
     * @attribute decaf
     * @type Boolean
     * @default false
     */
    decaf: attr('boolean', {
      defaultValue: false,
      label: l10n.t('Decaf'),
      printOptions: {
        selectable: false,
        selected: false,
        property: ''
      }
    }),

    /**
     * An array of sensorial descriptors.
     *
     * @attribute sensorialDescriptors
     * @type Array
     */
    sensorialDescriptors: attr({
      defaultValue: function defaultValue() {
        return [];
      },
      label: l10n.t('Flavors'),
      printOptions: {
        selectable: true,
        selected: true,
        property: ''
      }
    }),

    /**
     * The cupping score of this listing.
     *
     * @attribute sensorialScore
     * @type Number
     */
    sensorialScore: attr('number', {
      label: l10n.t('Final score'),
      printOptions: {
        selectable: true,
        selected: false,
        property: ''
      }
    }),

    /**
     * The description of this cupping.
     *
     * @attribute sensorialCuppingDescription
     * @type String
     * @optional
     */
    sensorialCuppingDescription: attr('string', {
      label: l10n.t('Cupping notes'),
      printOptions: {
        selectable: true,
        selected: false,
        property: ''
      }
    }),

    /**
     * The status/availability of the listing.
     *
     * @attribute availability
     * @type String
     */
    availability: attr('string', {
      label: l10n.t('Availability'),
      printOptions: {
        selectable: true,
        selected: false,
        property: ''
      }
    }),

    /**
     * The country of origin of this listing. Contains a 2-letter country code.
     *
     * @attribute country
     * @type String
     */
    country: attr('string', {
      label: l10n.t('Country'),
      printOptions: {
        selectable: false,
        selected: false,
        property: ''
      }
    }),

    /**
     * An array of varieties as id-value pairs.
     *
     * @attribute varieties
     * @type Array
     */
    varieties: attr({
      defaultValue: function defaultValue() {
        return [];
      },
      label: l10n.t('Varieties'),
      printOptions: {
        selectable: true,
        selected: false,
        property: ''
      }
    }),

    /**
     * The grade of the listing, e.g. AAA.
     *
     * @attribute grade
     * @type String
     */
    grade: attr('string', {
      label: l10n.t('Grade'),
      printOptions: {
        selectable: true,
        selected: false,
        property: ''
      }
    }),

    /**
     * The processing of the listing, e.g. WASHED
     *
     * @attribute processing
     * @type String
     */
    processing: attr('string', {
      label: l10n.t('Processing'),
      printOptions: {
        selectable: true,
        selected: false,
        property: ''
      }
    }),

    /**
     * An array of certifications.
     *
     * @attribute certifications
     * @type Array
     */
    certifications: attr({
      defaultValue: function defaultValue() {
        return [];
      },
      label: l10n.t('Certifications'),
      printOptions: {
        selectable: true,
        selected: false,
        property: ''
      }
    }),

    /**
     * The packaging (type of material) for this listing.
     *
     * @attribute packaging
     * @type String
     */
    packaging: attr('string', {
      label: l10n.t('Packaging'),
      printOptions: {
        selectable: true,
        selected: false,
        property: ''
      }
    }),

    /**
     * The year this listing was cropped. Can also be something like "2014-2015".
     *
     * @attribute cropYear
     * @type String
     */
    cropYear: attr('string', {
      label: l10n.t('Crop year'),
      printOptions: {
        selectable: true,
        selected: false,
        property: ''
      }
    }),

    /**
     * The moisture content of the listing.
     *
     * @attribute moisture
     * @type Number
     * @optional
     */
    moisture: attr('number', {
      label: l10n.t('Moisture'),
      printOptions: {
        selectable: true,
        selected: false,
        property: ''
      }
    }),

    /**
     * The water activity of the listing.
     *
     * @attribute waterActivity
     * @type Number
     */
    waterActivity: attr('number', {
      label: l10n.t('Water activity'),
      printOptions: {
        selectable: true,
        selected: false,
        property: ''
      }
    }),

    /**
     * The screen size of the listing. e.g. 15+
     *
     * @attribute screenSize
     * @type String
     */
    screenSize: attr('string', {
      label: l10n.t('Screen size'),
      printOptions: {
        selectable: true,
        selected: false,
        property: ''
      }
    }),

    /**
     * The warehouse location of this listing.
     *
     * @attribute warehouse
     * @type Model.Address
     */
    legacyWarehouse: belongsTo('location', {
      async: true,
      label: l10n.t('Warehouse'),
      printOptions: {
        selectable: false,
        selected: false,
        property: ''
      }
    }),

    /*
     * This is the value of new warehouse Records
     *
     * @property companyId
     * @type String
     */
    warehouse: attr('string', {
      label: l10n.t('Warehouse ID'),
      printOptions: {
        selectable: false,
        selected: false,
        property: ''
      }
    }),

    /**
     * The user who created the listing.
     *
     * @attribute user
     * @type Model.User
     */
    createdBy: belongsTo('user', {
      async: true,
      label: l10n.t('Created by'),
      printOptions: {
        selectable: false,
        selected: false,
        property: ''
      }
    }),

    /**
     * This is just a proxy for the producer id.
     *
     * @property producerId
     * @type String
     */
    producerId: attr('string', {
      label: l10n.t('Producer ID'),
      printOptions: {
        selectable: false,
        selected: false,
        property: ''
      }
    }),

    /**
     * The producer this listing belongs to.
     *
     * @attribute producer
     * @type Model.Producer
     */
    producer: belongsTo('producer', {
      async: true,
      label: l10n.t('Origin'),
      printOptions: {
        selectable: true,
        selected: true,
        property: 'name'
      }
    }),

    /**
     * This is just a proxy for the company id to make it able to link to companies without loading them.
     *
     * @property companyId
     * @type String
     */
    // TODO: check if still required
    companyId: attr('string', {
      label: l10n.t('Company ID'),
      printOptions: {
        selectable: false,
        selected: false,
        property: ''
      }
    }),

    /**
     * The company this listing belongs to.
     *
     * @attribute company
     * @type Model.Company
     */
    company: belongsTo('company', {
      async: true,
      inverse: null,
      label: l10n.t('Company'),
      printOptions: {
        selectable: false,
        selected: false,
        property: 'name'
      }
    }),

    /**
     * The shop this listing belongs to.
     *
     * @attribute shop
     * @type Model.Shop
     */
    shop: belongsTo('shop', {
      async: true,
      label: l10n.t('Shop'),
      printOptions: {
        selectable: false,
        selected: false,
        property: 'name'
      }
    }),

    /**
     * This is just a proxy for the shop id to make it able to link to shops without loading them.
     *
     * @property shopId
     * @type String
     */
    shopId: attr('string', {
      label: l10n.t('Shop ID'),
      printOptions: {
        selectable: false,
        selected: false,
        property: ''
      }
    }),

    /**
     * The name of the shop this listing belongs to. This is included here so that it is not neccessary to load the full shop of a listing
     * just to display its name.
     *
     * @attribute shopName
     * @type String
     */
    shopName: attr('string', {
      label: l10n.t('Shop name'),
      printOptions: {
        selectable: false,
        selected: false,
        property: ''
      }
    }),

    /**
     * The name of the warehouse this listing belongs to. This is included here so that it is not neccessary to load the full warehouse of a listing
     * just to display its name.
     *
     * @attribute warehouseName
     * @type String
     */
    warehouseName: attr('string', {
      label: l10n.t('Warehouse name'),
      printOptions: {
        selectable: true,
        selected: true,
        property: ''
      }
    }),

    /**
     * This is just a proxy for the auction id.
     *
     * @property auctionId
     * @type String
     */
    auctionId: attr('string', {
      label: l10n.t('Auction ID'),
      printOptions: {
        selectable: false,
        selected: false,
        property: ''
      }
    }),

    /**
     * The auction this listing belongs to. If this is null, this does not belong to an auction.
     *
     * @attribute auction
     * @type Model.Auction|null
     */
    auction: belongsTo('auction', {
      async: true,
      label: l10n.t('Auction'),
      printOptions: {
        selectable: false,
        selected: false,
        property: 'name'
      }
    }),

    /**
     * The highest bid for this listing in an auction.
     *
     * @attribute highestBid
     * @type Model.Bid
     */
    highestBid: belongsTo('bid', {
      async: true,
      inverse: null,
      label: l10n.t('Highest bid'),
      printOptions: {
        selectable: false,
        selected: false,
        property: ''
      }
    }),

    /**
     * The auction-bids belonging to this listing.
     *
     * @attribute auctionBids
     * @type Model.Bid[]|[]
     */
    bids: hasMany('bid', {
      async: true,
      label: l10n.t('Bids'),
      printOptions: {
        selectable: false,
        selected: false,
        property: ''
      }
    }),

    /**
     * The number of bids for this listing.
     *
     * @property bidCount
     * @type Array
     */
    bidCount: attr('number', {
      defaultValue: 0,
      label: l10n.t('Bid count'),
      printOptions: {
        selectable: false,
        selected: false,
        property: ''
      }
    }),

    /**
     * If this flag is set to true, server will
     * automatically renew listing after expiration.
     *
     * @attribute autoRenew
     * @type {Boolean}
     */
    autoRenew: attr('boolean', {
      defaultValue: true,
      label: l10n.t('Auto-republish'),
      printOptions: {
        selectable: false,
        selected: false,
        property: ''
      }
    }),

    /**
     * Reference to foreign ID from ERP systems.
     *
     * @attribute erpId
     * @type {String}
     */
    erpId: attr('string', {
      label: l10n.t('Reference ID'),
      printOptions: {
        selectable: false,
        selected: false,
        property: ''
      }
    }),

    /**
     * Flag if dynamic pricing is enabled.
     *
     * @attribute priceDynamic
     * @type {Boolean}
     */
    priceDynamic: attr('boolean', {
      defaultValue: false,
      label: l10n.t('Dynamic pricing'),
      printOptions: {
        selectable: false,
        selected: false,
        property: ''
      }
    }),

    /**
     * A custom premium added to the dynamic
     * (C-Market) prices in US Cent / LBS.
     *
     * @attribute cmarketPremiumAmount
     * @type {Number}
     */
    cmarketPremiumAmount: attr('number', {
      defaultValue: 0,
      label: l10n.t('C-Market premium'),
      printOptions: {
        selectable: false,
        selected: false,
        property: ''
      }
    }),

    /**
     * Corresponding currency of `cmarketPremiumAmount`.
     *
     * @attribute cmarketPremiumCurrency
     * @type {String}
     */
    cmarketPremiumCurrency: attr('string', {
      defaultValue: 'USD',
      label: l10n.t('C-Market currency'),
      printOptions: {
        selectable: false,
        selected: false,
        property: ''
      }
    }),
    // -------------------------------------------------------------------------
    // Properties

    /**
     *
     * @property currentAutoBid
     * @type Object
     */
    currentAutoBid: null,

    /**
     * Either the SO number (if available) or the ICO number, or null
     *
     * @property listingNumber
     * @type String
     * @readOnly
     */
    listingNumber: Ember.computed('icoNumber', 'salesOfferNumber', {
      get: function get()
      /*key*/
      {
        if (Ember.get(this, 'salesOfferNumber')) {
          return Ember.get(this, 'salesOfferNumber');
        }

        if (Ember.get(this, 'icoNumber')) {
          return Ember.get(this, 'icoNumber');
        }

        return null;
      }
    }),

    /**
     * A complete name, built from the listing name and eventually from the salesOfferNumber or icoNumber (if available).
     *
     * @property listingFullName
     * @type String
     * @readOnly
     */
    listingFullName: Ember.computed('name', 'listingNumber', function () {
      var name = Ember.get(this, 'name');

      if (Ember.get(this, 'listingNumber')) {
        return Ember.get(this, 'listingNumber') + ' - ' + name;
      }

      return name;
    }),

    /**
     * The auction-bids sorted by id descending (from the newest to the oldest).
     */
    sortedBids: Ember.computed('bids.[]', function () {
      return Ember.get(this, 'bids').toArray().sort(function (a, b) {
        return b.get('numericId') - a.get('numericId');
      });
    }),

    /**
     * The currently highest price of this listing. Displays either the price or the last auction-bid amount.
     *
     * @property currentPrice
     * @type Number
     */
    currentPrice: Ember.computed('highestBid.bidAmount', 'price', function () {
      var highestBidAmount = Ember.get(this, 'highestBid.bidAmount');

      if (highestBidAmount) {
        return highestBidAmount;
      }

      return Ember.get(this, 'price');
    }),

    /*
     * Listing's total price in current auction.
     */
    totalPrice: Ember.computed('highestBid.bidAmount', 'totalAmount', 'availableAmountUnit', 'priceBaseUnit', function () {
      var bidAmount = Ember.get(this, 'highestBid.bidAmount');

      if (Ember.isNone(bidAmount)) {
        return 0;
      }

      var totalAmount = Ember.get(this, 'totalAmount');
      var availableAmountUnit = Ember.get(this, 'availableAmountUnit');
      var priceBaseUnit = Ember.get(this, 'priceBaseUnit');
      var weight = (0, _unitCalculator.unitCalculatorWeight)(totalAmount, availableAmountUnit, priceBaseUnit);
      return (bidAmount * weight).toFixed(2) * 1;
    }),

    /**
     * Flag stating if listing has an auction.
     *
     * @property hasAuction
     * @type Boolean
     */
    hasAuction: Ember.computed('auction.{content,isLoaded}', {
      get: function get()
      /*key*/
      {
        if (!Ember.get(this, 'auction') || !Ember.get(this, 'auction.content')) {
          return false;
        }

        return true;
      }
    }),

    /**
     * Returns true if listing is active.
     *
     * @property isActive
     * @type Boolean
     */
    isActive: Ember.computed('status', 'archived', 'shop.isActive', function () {
      var status = Ember.get(this, 'status');

      if (status !== 'ACTIVE') {
        return false;
      }

      if (Ember.get(this, 'archived')) {
        return false;
      }

      return Ember.get(this, 'shop.isActive');
    }),
    isDraft: Ember.computed.equal('status', 'DRAFT'),
    isExpired: Ember.computed.equal('status', 'EXPIRED'),
    isInactive: Ember.computed.equal('status', 'INACTIVE'),
    isSoldOut: Ember.computed.equal('status', 'SOLD_OUT'),

    /**
     * Returns true if the listing is editable
     *
     * @property isEditable
     * @type Boolean
     */
    isEditable: Ember.computed('status', 'archived', 'auction.status', {
      get: function get()
      /*key*/
      {
        if (Ember.get(this, 'archived')) {
          return false;
        }

        if (Ember.get(this, 'status') === 'EXPIRED') {
          return false;
        }

        if (['RUNNING', 'CLOSED', 'ON_HOLD'].indexOf(Ember.get(this, 'auction.status')) > -1) {
          return false;
        }

        return true;
      }
    }),

    /**
     * Returns true if the listing is fully editable. This means that the listing was never published before.
     *
     * @property isEditable
     * @type Boolean
     */
    // TODO: rename to wasPublished or something like that
    isEditableAll: Ember.computed('status', 'archived', {
      get: function get()
      /*key*/
      {
        return Ember.get(this, 'status') === 'DRAFT' && !Ember.get(this, 'archived');
      }
    }),

    /**
     * Flag stating if current user is listing's owner.
     *
     * @property hasAuction
     * @type Boolean
     */
    isOwner: Ember.computed('companyId', 'userSession.currentCompany.id', {
      get: function get()
      /*key*/
      {
        return Ember.get(this, 'companyId') + '' === Ember.get(this, 'userSession.currentCompany.id') + '';
      }
    }),

    /**
     * Connected rating of current user session if any.
     *
     * @property wishlistItem
     * @type {Model.WishlistItem}
     */
    rating: Ember.computed('id', 'userSession.currentUser.ratingListings', function () {
      var ratings = Ember.get(this, 'userSession.currentUser.ratingListings');
      return ratings && ratings[this.id] || null;
    }),

    /**
     * Connected wish list item of current user session if any.
     *
     * @property wishlistItem
     * @type {Model.WishlistItem}
     */
    wishlistItem: Ember.computed('id', 'userSession.currentUser.wishlistListings', function () {
      var wishlistItems = Ember.get(this, 'userSession.currentUser.wishlistListings');
      return wishlistItems && wishlistItems[this.id] || null;
    }),

    /**
     * The preview image for this listing. Is either the first producer image,
     * or the flag of the shop's image.
     *
     * @property previewImage
     * @type String
     */
    previewImage: Ember.computed('shop.header.s300x300', 'producer.images.firstObject.s300x300', {
      get: function get()
      /*key*/
      {
        if (Ember.get(this, 'producer.images.firstObject.s300x300')) {
          return Ember.get(this, 'producer.images.firstObject.s300x300');
        }

        return Ember.get(this, 'shop.header.s300x300');
      }
    }),

    /**
     * Linked order item in user's current shopping carts if any.
     *
     * @property orderItemInShoppingCart
     * @type {Model.OrderItem|null}
     */
    orderItemInShoppingCart: Ember.computed('id', 'shoppingCart.cartSize', {
      get: function get()
      /*key*/
      {
        return Ember.get(this, "shoppingCart.listingMap.".concat(this.id));
      }
    }),

    /**
     * Sums `availableAmount` and `soldUnits`.
     *
     * @property totalAmount
     * @type Number
     */
    totalAmount: Ember.computed('availableAmount', 'soldUnits', {
      get: function get()
      /*key*/
      {
        return Ember.get(this, 'availableAmount') + Ember.get(this, 'soldUnits');
      }
    }),
    groupColor: Ember.computed('salesOfferNumber', function () {
      var salesOfferNumber = Ember.get(this, 'salesOfferNumber');

      if (!salesOfferNumber) {
        return 'white';
      } // To prevent similar values like 'AB-1' and 'AB-2' look
      // almost the same, repeat the string 10x to make
      // resulting hash much more different


      var stringToHash = salesOfferNumber.repeat(10);
      var hash = 5381; // = djb2 (dan bernstein)

      for (var i = 0; i < stringToHash.length; i++) {
        var char = stringToHash.charCodeAt(i);
        hash = (hash << 5) + hash + char;
      }

      return "hsl(".concat(hash % 359, ", 100%, 40%)");
    }),
    canDeleteBid: Ember.computed('bidCount', 'auction.isOnHold', function () {
      var isOnHold = Ember.get(this, 'auction.isOnHold');

      if (!isOnHold) {
        return false;
      }

      var bidCount = Ember.get(this, 'bidCount');

      if (bidCount === 0) {
        return false;
      }

      return true;
    }),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Loads auto bid from `/auction/<id>/autoBid`.
     * Extracts autobid afterwards for `currentAutoBid`.
     *
     * @private
     * @method _loadAutoBid
     * @return {Void}
     */
    _loadAutoBid: function _loadAutoBid() {
      var _this = this;

      var successCallback = function successCallback(response) {
        _this._extractAutoBid(response);
      };

      var failureCallback = function failureCallback() {
        console.error('model.js: Error fetching autoBid!');
      };

      var url = "/auction/".concat(Ember.get(this, 'auctionId'), "/autoBid");
      Ember.get(this, 'ajax').request(url).then(successCallback, failureCallback);
    },

    /**
     * Tries to evaluate the current auto bid
     * by given auto bids collection in order
     * to set `currentAutoBid` on this model.
     *
     * @private
     * @method _loadAutoBid
     * @return {Void}
     */
    _extractAutoBid: function _extractAutoBid(autoBids) {
      var _this2 = this;

      var currentAutoBid = Ember.get(this, 'currentAutoBid');
      currentAutoBid = autoBids.toArray().find(function (bid) {
        return bid.listing === +Ember.get(_this2, 'id');
      });
      Ember.set(this, 'currentAutoBid', currentAutoBid || null);
    },
    // -------------------------------------------------------------------------
    // Observers

    /**
     * Loads autobids depending on auction's
     * `autoBids` relation being fullfilled.
     *
     * @event _loadInitialAutoBids
     * @private
     */
    _loadInitialAutoBids: Ember.observer('auction.autoBids.isFulfilled', function () {
      var autoBids = Ember.get(this, 'auction.autoBids');

      if (Ember.isNone(autoBids) || typeof autoBids.toArray !== 'function') {
        return;
      }

      this._extractAutoBid(autoBids.toArray());
    }),

    /**
     * Reloads autobids depending on highest
     * bids's `autoBid` and `bidAmount`.
     *
     * @event _reloadAutoBid
     * @private
     */
    _reloadAutoBid: Ember.observer('highestBid.autoBid', 'highestBid.bidAmount', function () {
      if (!Ember.get(this, 'auction.isRunning') || Ember.get(this, 'company.id') !== Ember.get(this, 'userSession.currentCompany.id')) {
        return;
      }

      if (!Ember.get(this, 'auction.autoBids') || Ember.get(this, 'auction.autoBids.isPending') || Ember.get(this, 'highestBid.autoBid') === +Ember.get(this, 'currentAutoBid.id')) {
        return;
      }

      Ember.run.throttle(this, this._loadAutoBid, 100, false);
    }),

    /**
     * Copy options for ember-data-copyable addon.
     */
    copyableOptions: Ember.computed('name', 'userSession.currentUser', function () {
      return {
        ignoreAttributes: [// unique ids
        'erpId', 'encId', // auction (= unique)
        'bids', 'auction', 'bidCount', 'highestBid', 'auctionWinningBidder', // derived attributes
        'shopId', 'auctionId', 'companyId', 'producerId', // server assigned
        'shopName', 'soldUnits', 'createdDate', 'warehouseName', 'lastModifiedDate', 'firstPublishedDate'],
        overwrite: {
          // append `COPY` suffix to name
          name: l10n.t('{{name}} COPY', {
            name: Ember.get(this, 'name')
          }),
          // use the current user from session
          createdBy: Ember.get(this, 'userSession.currentUser')
        }
      };
    })
  });

  _exports.default = _default;
});