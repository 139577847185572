define("@vollersgroup/hub-frontend/transforms/address", ["exports", "ember-data", "@vollersgroup/hub-frontend/custom-objects/address-object"], function (_exports, _emberData, _addressObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Transform = _emberData.default.Transform;

  var _default = Transform.extend({
    deserialize: function deserialize(obj) {
      if (!obj) {
        return _addressObject.default.create();
      }

      return _addressObject.default.create({
        country: obj.country || null,
        state: obj.state || null,
        zip: obj.zip || null,
        city: obj.city || null,
        street: obj.street || null,
        addressAddition: obj.addressAddition || null,
        phone: obj.phone || null,
        // Optional for display
        latitude: obj.latitude || null,
        longitude: obj.longitude || null,
        fullName: obj.nameAddition || null,
        company: obj.name || null
      });
    },
    serialize: function serialize(addressObject) {
      if (!addressObject) {
        return null;
      }

      return {
        country: addressObject.get('country'),
        state: addressObject.get('state'),
        zip: addressObject.get('zip'),
        city: addressObject.get('city'),
        street: addressObject.get('street'),
        addressAddition: addressObject.get('addressAddition'),
        phone: addressObject.get('phone'),
        // Optional for locations
        nameAddition: addressObject.get('fullName'),
        name: addressObject.get('company')
      };
    }
  });

  _exports.default = _default;
});