define("@vollersgroup/hub-frontend/helpers/flag-url", ["exports", "@vollersgroup/hub-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.flagUrl = flagUrl;
  _exports.default = void 0;
  var assetPrependURL = _environment.default.assetPrependURL;

  function flagUrl(params
  /*, hash*/
  ) {
    var url = params[0];

    if (!url) {
      return '';
    }

    url = assetPrependURL + '/assets/img/flags/' + url;
    return Ember.String.htmlSafe(url);
  }

  var _default = Ember.Helper.helper(flagUrl);

  _exports.default = _default;
});