define("@vollersgroup/hub-frontend/listing/serializer", ["exports", "ember-data", "@vollersgroup/hub-frontend/application/serializer"], function (_exports, _emberData, _serializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;

  var _default = _serializer.default.extend(EmbeddedRecordsMixin, {
    attrs: {
      legacyWarehouse: 'location',
      warehouseName: 'locationName',
      price: 'priceAmount',
      samplePrice: 'samplePriceAmount'
    },
    normalize: function normalize(type, json, property) {
      json['shopId'] = json.shop;
      json['auctionId'] = json.auction;
      json['companyId'] = json.company;
      json['producerId'] = json.producer; // necessary for bid deletion in case all
      // bids have been deleted, server doesn't
      // inlucde the payload if all bids deleted

      json.highestBid = json.highestBid || null;
      return this._super(type, json, property);
    },
    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);

      delete json.shopId;
      delete json.auctionId;
      delete json.companyId;
      delete json.producerId;

      if (!json.samplePriceAmount || !json.samplePriceCurrency) {
        json.samplePriceAmount = null;
        json.samplePriceCurrency = null;
      }

      if (!json.priceAmount || !json.priceCurrency) {
        json.priceAmount = null;
        json.priceCurrency = null;
      }

      return json;
    }
  });

  _exports.default = _default;
});