define("@vollersgroup/hub-frontend/store/shops/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    // -------------------------------------------------------------------------
    // Properties
    queryParams: {
      searchText: {
        as: 'q',
        refreshModel: true
      },
      orderBy: {
        as: 'o',
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      pageSize: {
        refreshModel: true
      }
    },
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Searches either for shop by search term or
     * delivers full list of all shops in database.
     *
     * @public
     * @method model
     * @return {Model.Shop}
     */
    model: function model(params) {
      return this.store.query('shop', {
        term: params.searchText,
        sort: params.orderBy,
        page: params.page || 2,
        pageSize: params.pageSize || 12
      });
    },

    /**
     * Resets potentially remaining query parameters
     * in case you use breadcrumb navigation from a
     * listing view, which would keep params sticky!
     *
     * @public
     * @method setupController
     * @return {Void}
     */
    setupController: function setupController() {
      this._super.apply(this, arguments);

      var controller = this.controllerFor('store.shop');
      controller.setProperties(controller.get('defaults'));
    }
  });

  _exports.default = _default;
});