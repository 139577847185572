define("@vollersgroup/hub-frontend/components/auction-participant-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wZrnrja3",
    "block": "{\"symbols\":[\"participant\"],\"statements\":[[7,\"thead\"],[11,\"class\",\"table__header\"],[9],[0,\"\\n  \"],[7,\"tr\"],[9],[0,\"\\n    \"],[7,\"th\"],[11,\"style\",\"width:20%\"],[9],[1,[27,\"t\",[\"Name\"],null],false],[10],[0,\"\\n    \"],[7,\"th\"],[9],[1,[27,\"t\",[\"Online\"],null],false],[10],[0,\"\\n    \"],[7,\"th\"],[11,\"style\",\"width:15%\"],[9],[1,[27,\"t\",[\"User\"],null],false],[10],[0,\"\\n    \"],[7,\"th\"],[11,\"style\",\"width:10%\"],[9],[1,[27,\"t\",[\"Status\"],null],false],[10],[0,\"\\n    \"],[7,\"th\"],[11,\"style\",\"width:10%\"],[9],[1,[27,\"t\",[\"Registered\"],null],false],[10],[0,\"\\n    \"],[7,\"th\"],[11,\"style\",\"width:10%\"],[9],[1,[27,\"t\",[\"Coffees bid On\"],null],false],[10],[0,\"\\n    \"],[7,\"th\"],[11,\"style\",\"width:10%\"],[9],[1,[27,\"t\",[\"Coffee's highest bidder\"],null],false],[10],[0,\"\\n    \"],[7,\"th\"],[11,\"style\",\"width:8%\"],[9],[1,[27,\"t\",[\"All bids\"],null],false],[10],[0,\"\\n    \"],[7,\"th\"],[11,\"style\",\"width:15%\"],[11,\"class\",\"text-right\"],[9],[1,[27,\"t\",[\"Total\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"auction\",\"auctionRegistrations\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"auction-participant-table-row\",null,[[\"participant\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"    \"],[7,\"tr\"],[9],[0,\"\\n      \"],[7,\"td\"],[11,\"colspan\",\"9\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"No companies registered for this auction yet.\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/auction-participant-table/template.hbs"
    }
  });

  _exports.default = _default;
});