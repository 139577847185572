define("@vollersgroup/hub-frontend/admin/manage/overview/auction/registrations/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "@vollersgroup/hub-frontend/mixins/reset-scroll"], function (_exports, _authenticatedRouteMixin, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Display all auctions of the current user's company.
   *
   * @namespace Route
   * @class ManageAuctionIndex
   * @extends Ember.Route
   * @uses Mixin.ResetScroll
   * @uses SimpleAuth.Mixin.AuthenticatedRouteMixin
   */
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _resetScroll.default, {
    // -------------------------------------------------------------------------
    // Dependencies
    userSession: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Query parameters.
     *
     * @final
     * @public
     * @type {Object}
     */
    queryParams: {
      selectedAuctionId: {
        refreshModel: true,
        as: 'auction'
      },
      orderBy: {
        refreshModel: true,
        as: 'orderBy'
      },
      filterStatus: {
        refreshModel: true,
        as: 'filterStatus'
      }
    },
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Fetches `auction-participant` from store with query params.
     *
     * @public
     * @method model
     * @params {Object} params
     * @return {Ember.RSVP.Promise}
     */
    model: function model(params) {
      var query = {
        sort: params.orderBy,
        auctioneerCompany: this.get('userSession.currentCompany.id')
      };

      if (params.selectedAuctionId) {
        query.auctions = [params.selectedAuctionId];
      }

      if (params.filterStatus && params.filterStatus.length) {
        query.registrationStates = params.filterStatus;
      } else {
        query.registrationStates = ['PENDING', 'ACCEPTED', 'REJECTED'];
      }

      return this.store.query('auction-participant', query);
    },
    init: function init() {
      this._super.apply(this, arguments);

      this._onBeforeUnload = this.onBeforeUnload.bind(this);
      window.addEventListener('beforeunload', this._onBeforeUnload);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      window.removeEventListener('beforeunload', this._onBeforeUnload);
    },
    onBeforeUnload: function onBeforeUnload(event) {
      var controller = this.controllerFor(this.routeName);

      if (Ember.get(controller, 'isSending')) {
        event = event || window.event;
        var confirmationMessage = this.get('l10n').t("Your are currently sending messages, are you sure you want to leave the page?");
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    },
    actions: {
      willTransition: function willTransition(transition) {
        var controller = this.controllerFor(this.routeName);

        if (Ember.get(controller, 'isSending')) {
          transition.abort();
        }
      }
    }
  });

  _exports.default = _default;
});