define("@vollersgroup/hub-frontend/auction/model", ["exports", "ember-data", "moment", "ember-can", "ember-cp-validations", "@vollersgroup/hub-frontend/config/environment"], function (_exports, _emberData, _moment, _emberCan, _emberCpValidations, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;
  var attr = _emberData.default.attr;
  var PromiseArray = _emberData.default.PromiseArray;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      validators: [(0, _emberCpValidations.validator)('length', {
        allowNone: false,
        min: 3,
        message: 'Please enter at least 3 characters.'
      })]
    },
    bidStepAmount: {
      validators: [(0, _emberCpValidations.validator)('number', {
        allowNone: false,
        allowString: false,
        gt: 0,
        message: 'Please enter a number greater than 0.'
      })]
    },
    about: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true,
        message: 'Please enter a short description.'
      })]
    },
    auctionPolicy: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true,
        message: 'Please enter an auction policy.'
      })]
    },
    slug: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true,
        message: 'Please enter at least 4 characters with at least 1 letter. Only lowercase letters, numbers and dashes are allowed.'
      }), (0, _emberCpValidations.validator)('slug', {
        description: 'Auction URL'
      }), (0, _emberCpValidations.validator)('unique-auction-slug', {
        disabled: Ember.computed.not('model.isNew')
      })]
    },
    startedDate: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true,
        message: 'Please enter a future date and start time for the auction.'
      }), (0, _emberCpValidations.validator)('date', {
        after: 'now',
        precision: 'minute',
        message: 'Auction start time must be future date.',
        // In case the auction is paused, do not validate date for future value.
        disabled: Ember.computed.bool('model.isOnHold')
      })]
    },
    finalSampleOrderDate: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true,
        disabled: Ember.computed.equal('model.orderableSampleBundles', 0),
        message: 'Please select a date that samples can be ordered until.'
      }), (0, _emberCpValidations.validator)('date', {
        onOrAfter: 'now',
        precision: 'day',
        message: 'The date must be today or some future date.',
        disabled: Ember.computed('model.orderableSampleBundles', 'model.isOnHold', function () {
          if (Ember.get(this, 'model.orderableSampleBundles') === 0) {
            // If there are no samples to order, no need to fill in this field.
            return true;
          } // In case the auction is paused, no need to validate for future value.


          return !!Ember.get(this, 'model.isOnHold');
        })
      }), (0, _emberCpValidations.validator)('date', {
        dependentKeys: ['model.startedDate'],
        onOrBefore: Ember.computed.alias('model.startedDate'),
        precision: 'day',
        message: 'The date must be on or before the auction start date.',
        disabled: Ember.computed('model.orderableSampleBundles', 'model.isOnHold', function () {
          if (Ember.get(this, 'model.orderableSampleBundles') === 0) {
            // If there are no samples to order, no need to fill in this field.
            return true;
          } // In case the auction is paused, no need to validate for future value.


          return !!Ember.get(this, 'model.isOnHold');
        })
      })]
    }
  });
  /**
   * The auction model
   *
   * @namespace Model
   * @class Auction
   * @extends Model
   * @uses Mixin.Can
   */

  var _default = Model.extend(_emberCan.CanMixin, Validations, {
    ajax: Ember.inject.service(),
    userSession: Ember.inject.service(),
    auctionManager: Ember.inject.service(),
    availableData: Ember.inject.service(),

    /**
     * The title of the auction
     *
     * @attribute name
     * @type String
     */
    name: attr('string'),

    /**
     * A human-readable id for the URL.
     *
     * @attribute slug
     * @type String
     */
    slug: attr('string'),

    /**
     * The start date of the auction
     *
     * @attribute startedDate
     * @type Date
     */
    startedDate: attr('timestamp'),

    /**
     * Number of milliseconds till the auction end.
     * Provided by server. It's more accurate than closedDate
     * and doesn't require client's time to be correct.
     *
     * @attribute remainingClockTime
     * @type Number
     */
    remainingClockTime: attr('number'),

    /**
     * A short about text for this auction.
     *
     * @attribute about
     * @type String
     */
    about: attr('string'),

    /**
     * The minimum bid step for this auction. e.g. 0.1
     *
     * @attribute bidStepAmount
     * @type Number
     */
    bidStepAmount: attr('number'),

    /**
     * The currency for the bid step. Has to be the same as the shop's currency.
     *
     * @attribute bidStepCurrency
     * @type String
     */
    bidStepCurrency: attr('string', {
      defaultValue: 'USD'
    }),

    /**
     * The type of this auction. e.g. "HELMSMAN"
     *
     * @attribute type
     * @type String
     * @default "HELMSMAN"
     */
    type: attr('string', {
      defaultValue: 'HELMSMAN'
    }),

    /**
     * The duration of this auction. For Helmsman auctions, this specifies for how long no bids have to occur to finish the auction.
     * Should be in milliseconds.
     *
     * @attribute duration
     * @type Number
     */
    duration: attr('number'),

    /**
     * If manual verification of all registrations is required for this auction.
     *
     * @attribute registrationRequired
     * @type Boolean
     * @default false
     */
    registrationRequired: attr('boolean', {
      defaultValue: false
    }),

    /**
     * The final date at which samples can be ordered for this auction.
     *
     * @attribute finalSampleOrderDate
     * @type Date
     */
    finalSampleOrderDate: attr('timestamp'),

    /**
     * The policy for this auction. Every attendee has to accept this policy before registering for the auction.
     *
     * @attribute auctionPolicy
     * @type String
     */
    auctionPolicy: attr('string'),

    /**
     * A longer information text about the auction. Can include markdown.
     *
     * @attribute auctionInformation
     * @type String
     */
    auctionInformation: attr('string'),

    /**
     * The max. number of sample bundles which can be ordered for this auction.
     *
     * @attribute orderableSampleBundles
     * @type Number
     */
    orderableSampleBundles: attr('number'),

    /**
     * The status of this auction.
     *
     * @attribute status
     * @type DRAFT|ACTIVE|CLOSED|INACTIVE|RUNNING|ON_HOLD
     */
    status: attr('string', {
      defaultValue: 'ACTIVE'
    }),

    /**
     * If the auction is archived.
     *
     * @attribute isArchived
     * @type Boolean
     * @default false
     */
    archived: attr('boolean', {
      defaultValue: false
    }),

    /**
     * If the auction timer start was forced even if
     * there have not been bids on all listings yet.
     *
     * @attribute timerStartForced
     * @type Boolean
     * @default false
     */
    timerStartForced: attr('boolean', {
      defaultValue: false
    }),

    /**
     * The number of listings this auction has.
     *
     * @attribute listingActiveCount
     * @type Number
     * @default 0
     */
    listingActiveCount: attr('number', {
      defaultValue: 0
    }),

    /**
     * The end date of the auction.
     *
     * @attribute closedDate
     * @type Date
     * @readOnly
     */
    closedDate: attr('timestamp'),

    /**
     * Number of milliseconds till the auction start.
     * Provided by server. It's more accurate than startedDate
     * and doesn't require client's time to be correct.
     *
     * @property remainingTimeToStart
     * @type Number
     */
    remainingTimeToStart: attr('number'),

    /**
     * The shop this auction belongs to.
     *
     * @attribute shop
     * @type Model.Shop
     */
    shop: belongsTo('shop', {
      async: true
    }),

    /**
     * The company this auction belongs to.
     *
     * @attribute company
     * @type Model.Company
     */
    company: belongsTo('company', {
      async: true,
      inverse: null
    }),

    /*
     * Flag that validates whether air shipping is available.
     */
    airShippingAvailable: attr('boolean'),

    /*
     * Returns true if the auction timer is running.
     */
    isTimerRunning: Ember.computed.bool('remainingClockTime'),

    /*
     * List of online users of participating companies for this auction.
     * Available only to auction owner.
     * NOTE: Do not use this property directly, use onlineUsers
     * where we check for permissions to avoid unnecessary requests.
     */
    users: hasMany('user', {
      async: true
    }),
    onlineUsers: Ember.computed('users', 'isOwner', function () {
      if (!Ember.get(this, 'isOwner')) {
        return [];
      }

      return Ember.get(this, 'users');
    }),

    /**
     * The current user's auction participant for this auction.
     * Is null if the user is not registered.
     *
     * @property currentAuctionParticipant
     * @type Model.AuctionParticipant
     */
    currentAuctionParticipant: Ember.computed('id', 'userSession.auctionRegistrations.{isFulfilled,length}', function () {
      var _this = this;

      var registrations = this.get('userSession.auctionRegistrations');
      var isRegistered = null;
      registrations.forEach(function (registration) {
        if (registration.get('auction.id') === _this.get('id')) {
          isRegistered = registration;
        }
      });
      return isRegistered;
    }),

    /**
     * The total price of all samples for this auction.
     *
     * @property sampleBundlePrice
     * @type {Number}
     */
    sampleBundlePrice: Ember.computed('listings.@each.samplePrice', 'listings.[]', function () {
      var listings = Ember.get(this, 'listings');

      if (Ember.isEmpty(listings)) {
        return 0;
      }

      return Ember.get(this, 'listings').mapBy('samplePrice').filter(function (p) {
        return !Ember.isEmpty(p);
      }).reduce(function (sum, price) {
        return sum + price;
      }, 0).toFixed(2) * 1;
    }),

    /**
     * If the user's company is currently registered to the auction.
     */
    isAccepted: Ember.computed.alias('currentAuctionParticipant.isAccepted'),
    typeENUM: Ember.computed('type', function () {
      return 'Helmsman';
    }),

    /**
     * The end date of the auction as time in the format 2:59 (mm:ss)
     *
     * @property endDateDuration
     * @type String
     * @readOnly
     */
    endDateDuration: Ember.computed('remainingClockTime', function () {
      var remainingClockTime = Ember.get(this, 'remainingClockTime');

      if (!remainingClockTime) {
        return '00:00';
      }

      return remainingClockTime > 0 ? (0, _moment.default)(remainingClockTime).format('mm:ss') : '00:00';
    }),

    /*
     *  This "kind of observer" subtracts time from remaining time every second
     *  if the auction is running. remainingClockTime property is updated with every bid
     *  to it's original value.
     */
    _subtractRemainingClockTime: Ember.on('didLoad', function subtractRemainingClockTime() {
      if (_environment.default.environment === 'test' || Ember.get(this, 'isDestroyed') || Ember.get(this, 'isClosed')) {
        return;
      }

      Ember.run.later(this, subtractRemainingClockTime, 1000);

      if (!Ember.get(this, 'isRunning')) {
        return;
      }

      var remainingClockTime = Ember.get(this, 'remainingClockTime');

      if (!remainingClockTime || remainingClockTime <= 0) {
        return;
      }

      var newTime = remainingClockTime - 1000;
      Ember.set(this, 'remainingClockTime', newTime);
    }),

    /**
     * Returns true if auction is active or running.
     *
     * @property isActiveOrRunning
     * @type Boolean
     */
    isActiveOrRunning: Ember.computed('status', function () {
      var status = this.get('status');
      return status === 'ACTIVE' || status === 'RUNNING';
    }),

    /**
     * If the auction is active.
     */
    isActive: Ember.computed.equal('status', 'ACTIVE'),

    /**
     * If the auction is draft.
     */
    isDraft: Ember.computed.equal('status', 'DRAFT'),

    /**
     * If the auction is inactive.
     */
    isInactive: Ember.computed.equal('status', 'INACTIVE'),

    /**
     * If the auction is currently running.
     */
    isRunning: Ember.computed.equal('status', 'RUNNING'),

    /**
     * If the auction is on hold.
     */
    isOnHold: Ember.computed.equal('status', 'ON_HOLD'),

    /**
     * If the auction is currently closed.
     */
    isClosed: Ember.computed.equal('status', 'CLOSED'),

    /**
     * If it is possible to order samples for this auction.
     *
     * @property canOrderSamples
     * @type Boolean
     */
    // TODO: This is permission check. Should be in ability.js
    canOrderSamples: Ember.computed('hasAvailableSamples', 'currentAuctionParticipant.isPending', 'currentAuctionParticipant.isAccepted', 'currentAuctionParticipant.isRejected', function () {
      if (!Ember.get(this, 'hasAvailableSamples')) {
        return false;
      }

      if (Ember.get(this, 'currentAuctionParticipant.isPending')) {
        return true;
      }

      if (Ember.get(this, 'currentAuctionParticipant.isAccepted')) {
        return true;
      }

      if (Ember.get(this, 'currentAuctionParticipant.isRejected')) {
        return false;
      }

      return false;
    }),

    /**
     * If this auction has available samples to order.
     * @property hasAvailableSamples
     * @type Boolean
     */
    hasAvailableSamples: Ember.computed('isActive', 'orderableSampleBundles', 'finalSampleOrderDate', function () {
      if (!Ember.get(this, 'isActive')) {
        return false;
      }

      if (!Ember.get(this, 'orderableSampleBundles')) {
        return false;
      }

      if (!(0, _moment.default)(this.get('finalSampleOrderDate')).isAfter((0, _moment.default)())) {
        return false;
      }

      return true;
    }),

    /**
     * Get all listings for this auction.
     *
     * @attribute listings
     * @type Model.Listing[]
     */
    listings: hasMany('listing', {
      async: true
    }),

    /**
     * At the moment server delivers ONE autoBid PER listing
     * within this collection. We may change this serverside
     * on later point of time if needed for autoBid history
     * or something else. Please see {Model.Listing} for
     * retrieval of corresponding autoBid.
     *
     * @property autoBids
     * @type {Array}
     */
    autoBids: Ember.computed('ajax', 'id', 'status', 'userSession.currentCompany.id', 'userSession.session.isAuthenticated', function () {
      if (!this.get('userSession.session.isAuthenticated') || !this.can('view autobids for auction') || this.get('status') !== 'RUNNING' && this.get('status') !== 'CLOSED' && this.get('status') !== 'ON_HOLD') {
        return [];
      }

      return PromiseArray.create({
        promise: this.get('ajax').request('/auction/' + this.get('id') + '/autoBid')
      });
    }),

    /**
     * The highest bids of all the auction's listings.
     *
     * @property highestBids
     * @type {Model.Bid[]}
     * @readOnly
     * @protected
     */
    highestBids: Ember.computed('listings.@each.highestBid', function () {
      var listings = this.get('listings');
      return PromiseArray.create({
        promise: Ember.RSVP.all(listings.mapBy('highestBid'))
      });
    }),

    /*
     * All bids in auction.
     */
    allAuctionBids: Ember.computed('listings.@each.bids', function () {
      return Ember.get(this, 'listings').mapBy('bids').filterBy('length') // Flatten all bids into one array
      .reduce(function (allBids, bids) {
        return allBids.concat(bids.toArray());
      }, []);
    }),

    /**
     * Total auction price for the current auction.
     * @type Number
     */
    totalAuctionPrice: Ember.computed('listings.@each.totalPrice', function () {
      return Ember.get(this, 'listings').mapBy('totalPrice').reduce(function (sum, price) {
        return sum + price;
      }, 0).toFixed(2) * 1;
    }),

    /**
     * Flag if current user is auctioneer.
     *
     * @property isOwner
     * @type Boolean
     *
     */
    isOwner: Ember.computed('userSession.currentCompany.id', 'company.id', function () {
      var currentCompanyId = Ember.get(this, 'userSession.currentCompany.id');
      var auctionCompanyId = Ember.get(this, 'company.id');

      if (!currentCompanyId || !auctionCompanyId) {
        return false;
      }

      return currentCompanyId === auctionCompanyId;
    }),

    /**
     * Get all registrations for this auction.
     *
     * @property auctionRegistrations
     * @type Model.AuctionParticipant[]
     */
    auctionRegistrations: Ember.computed('id', 'store', 'userSession.currentCompany.id', function () {
      return this.get('store').query('auction-participant', {
        auctioneerCompany: this.get('userSession.currentCompany.id'),
        auctions: [this.get('id')]
      });
    }),

    /**
     * Get all accepted registrations for this auction.
     */
    auctionRegistrationsRegistered: Ember.computed.filterBy('auctionRegistrations', 'isAccepted', true),

    /**
     * Get all pending registrations for this auction.
     */
    auctionRegistrationsPending: Ember.computed.filterBy('auctionRegistrations', 'isPending', true),

    /**
     * Available importer requirements.
     */
    availableImporterRequirements: Ember.computed('airShippingAvailable', 'availableData.importerRequirements', function () {
      var importerRequirements = Ember.get(this, 'availableData.importerRequirements');

      if (!Ember.get(this, 'airShippingAvailable')) {
        return importerRequirements.filter(function (r) {
          return r.value !== 'AIR_SHIPPING';
        });
      }

      return importerRequirements;
    }).readOnly(),

    /**
     * Initializes auction via manager instance.
     *
     * @event _initAuction
     * @private
     */
    _initAuction: Ember.on('didLoad', function () {
      this.get('auctionManager').initAuction(this);
    })
  });

  _exports.default = _default;
});