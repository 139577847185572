define("@vollersgroup/hub-frontend/custom-objects/address-object", ["exports", "ember-cp-validations", "@vollersgroup/hub-frontend/mixins/country"], function (_exports, _emberCpValidations, _country) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AddressValidations = (0, _emberCpValidations.buildValidations)({
    fullName: {
      validators: [(0, _emberCpValidations.validator)('length', {
        allowNone: false,
        min: 3,
        max: 250,
        message: 'Please enter at least 3 characters.'
      })]
    },
    company: {
      validators: [(0, _emberCpValidations.validator)('length', {
        allowNone: false,
        min: 3,
        max: 250,
        message: 'Please enter at least 3 characters.'
      })]
    },
    country: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true,
        message: 'Please select a country.'
      })]
    },
    street: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true,
        message: 'Please enter an address.'
      })]
    },
    zip: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true,
        message: 'Please enter a zip code.'
      }), (0, _emberCpValidations.validator)('length', {
        allowNone: false,
        max: 12,
        message: "Zip code can't be longer than 12 characters."
      })]
    },
    city: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true,
        message: 'Please enter a city.'
      })]
    },
    phone: {
      validators: [(0, _emberCpValidations.validator)('format', {
        allowBlank: false,
        // this is very basic validation that provided value is phone number
        // It must contain at least one number
        regex: /\d/,
        message: 'Please enter a phone number.'
      })]
    }
  });
  /**
   * An address that's being used as a shipping or billing address object.
   */

  var _default = Ember.Object.extend(_country.default, AddressValidations, {
    /**
     * The full name of the recipient for this address.
     *
     * @property fullName
     * @type String
     */
    fullName: null,

    /**
     * The company name for this address.
     *
     * @property company
     * @type String
     */
    company: null,

    /**
     * The 2-letter country code of this location.
     *
     * @property country
     * @type String
     */
    country: null,

    /**
     * The State of this location.
     *
     * @property state
     * @type String
     * @optional
     */
    state: null,

    /**
     * The Zip code of this location.
     *
     * @property zip
     * @type String
     */
    zip: null,

    /**
     * The city of this location.
     *
     * @property city
     * @type String
     */
    city: null,

    /**
     * The street (+ house number) of this location.
     *
     * @property street
     * @type String
     */
    street: null,

    /**
     * The optional second address line.
     *
     * @property addressAddition
     * @type String
     * @optional
     */
    addressAddition: null,

    /**
     * The phone number of this location.
     *
     * @property phone
     * @type String
     */
    phone: null,

    /**
     * Returns true if the address is valid.
     */
    isValid: Ember.computed('fullName', 'company', 'country', 'zip', 'city', 'street', 'phone', function () {
      if (Ember.isBlank(this.get('fullName')) || Ember.isBlank(this.get('company')) || Ember.isBlank(this.get('country')) || Ember.isBlank(this.get('zip')) || Ember.isBlank(this.get('city')) || Ember.isBlank(this.get('street')) || Ember.isBlank(this.get('phone'))) {
        return false;
      }

      return true;
    }),

    /**
     * Compares to address objects for equality.
     *
     * @method isEqual
     * @param {CustomObject.Address} other
     * @return Boolean
     * @public
     */
    // TODO: revisit why we need isEqual and isEqualStrict
    isEqual: function isEqual(other) {
      if (this.get('company') !== other.get('company')) {
        return false;
      }

      if (this.get('country') !== other.get('country')) {
        return false;
      }

      if (this.get('state') !== other.get('state')) {
        return false;
      }

      if (this.get('street') !== other.get('street')) {
        return false;
      }

      if (this.get('addressAddition') !== other.get('addressAddition')) {
        return false;
      }

      if (this.get('city') !== other.get('city')) {
        return false;
      }

      if (this.get('zip') !== other.get('zip')) {
        return false;
      }

      return true;
    },

    /**
     * Compares to address objects for equality including fullName property.
     *
     * @param {CustomObject.Address} other
     */
    isEqualStrict: function isEqualStrict(other) {
      if (this.get('fullName') !== other.get('fullName')) {
        return false;
      }

      return this.isEqual(other);
    },

    /*
     * Copies over properties from instance address to target address.
     */
    copyTo: function copyTo(targetAddress) {
      return targetAddress.setProperties({
        fullName: this.get('fullName'),
        company: this.get('company'),
        country: this.get('country'),
        state: this.get('state'),
        zip: this.get('zip'),
        city: this.get('city'),
        street: this.get('street'),
        addressAddition: this.get('addressAddition'),
        phone: this.get('phone')
      });
    }
  });

  _exports.default = _default;
});