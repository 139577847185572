define("@vollersgroup/hub-frontend/components/info-tooltip/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "toAN/emi",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"img\"],[11,\"src\",\"/assets/img/icons/tip-icon.svg\"],[12,\"alt\",[27,\"t\",[\"Tip\"],null]],[11,\"class\",\"tooltip__icon\"],[9],[10],[0,\"\\n\"],[14,1],[0,\"\\n\"],[7,\"button\"],[11,\"class\",\"button button--plain tooltip__hide\"],[9],[0,\"\\n  \"],[1,[27,\"t\",[\"Got it\"],null],false],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"dismiss\"]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/info-tooltip/template.hbs"
    }
  });

  _exports.default = _default;
});