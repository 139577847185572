define("@vollersgroup/hub-frontend/components/manage-shop-shipping/component", ["exports", "@vollersgroup/hub-frontend/utils/scroll-into-the-first-error-if-needed", "@vollersgroup/hub-frontend/utils/country-data", "@vollersgroup/hub-frontend/utils/get-first-adapter-error-message"], function (_exports, _scrollIntoTheFirstErrorIfNeeded, _countryData, _getFirstAdapterErrorMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A module to edit a shop's shipping.
   *
   * ```html
   * {{manage-shop-shipping shop=myShop action="myAction"}}
   * ```
   *
   * The action should have the following format:
   *
   * ```js
   * myAction: function(shop) {
   *     // Do something with the shop
   *     shop.save();
   * }
   * ```
   *
   * The action is only called when the shop is valid.
   *
   * @namespace Component
   * @class ManageShopPolicies
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    ajax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    l10n: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * The shop that should be edited / created.
     *
     * @attribute shop
     * @type Model.Shop
     * @required
     */
    shop: null,

    /**
     * The name of the action to call when the creation / editing of the shop is done.
     * This action receives the edited shop as a single parameter.
     *
     * @attribute action
     * @type string
     * @required
     */
    action: null,

    /**
     * If this is set to true, this is an edit dialog (instead of a create dialog)
     *
     * @attribute isEdit
     * @type Boolean
     * @optional
     */
    isEdit: false,

    /**
     * The name of the action to call when the creation / editing of the shop is done.
     * This action receives the edited shop as a single parameter.
     * This action is called when the process should be ended instead of continuing to the next step.
     *
     * @attribute actionExit
     * @type string
     * @required
     */
    actionExit: null,

    /**
     * Flag indicating wheter all error messages should be displayed.
     */
    didValidate: false,
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Initializes radio button and nested tree
     * components according to shop's settings.
     *
     * @method didReceiveAttrs
     * @public
     */
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      Ember.run.once(this, this._init);
    },
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Sets "REGIONS" shipping mode if flat rate pricing
       * gets enabled, as worlwide shipping is prohibited.
       *
       * @event toggleFlatRatePriced
       * @param {Boolean} checked
       * @private
       */
      toggleFlatRatePriced: function toggleFlatRatePriced(checked) {
        if (checked) {
          Ember.set(this, 'shippingMode', 'REGIONS');
          this.send('updateMode', 'REGIONS');
        }
      },

      /**
       * Switches between "WORLDWIDE" and "REGIONS".
       * In latter case retrieves regions from api.
       *
       * @event selectMode
       * @param {String} mode Selected shipping mode.
       * @private
       */
      updateMode: function updateMode(mode) {
        var _this = this;

        switch (mode) {
          case 'WORLDWIDE':
            // do not reset immediately if user switches back to
            // "REGIONS" all settings would be lost, reset later
            // @see: save()
            break;

          case 'REGIONS':
            // eslint-disable-next-line no-case-declarations
            var shippingInfos = Ember.get(this, 'shop.shippingInfos'); // eslint-disable-next-line no-case-declarations

            var setShippingRegions = function setShippingRegions(regions) {
              var shippingRegions = regions.map(function (region) {
                var countries = region.countries.map(function (country) {
                  return {
                    label: _countryData.default[country] || country,
                    isActive: !!shippingInfos.findBy('country', country),
                    value: country
                  };
                });
                return {
                  label: region.name,
                  value: null,
                  items: countries
                };
              });
              Ember.set(_this, 'shippingRegions', shippingRegions);
            }; // eslint-disable-next-line no-case-declarations


            var shippingRegions = Ember.get(this, 'shippingRegions');

            if (Ember.isEmpty(shippingRegions)) {
              Ember.get(this, 'ajax').request('/region').then(setShippingRegions);
            }

            break;

          default:
        }
      },

      /**
       * Switches between "WORLDWIDE" and "REGIONS".
       * In latter case retrieves regions from api.
       *
       * @event selectMode
       * @param {String} mode Selected shipping mode.
       * @private
       */
      updateCountry: function updateCountry(item) {
        var shippingInfos = Ember.get(this, 'shop.shippingInfos');
        var country = Ember.get(item, 'value'); // skip root regions

        if (country) {
          var shippingInfo = shippingInfos.findBy('country', country); // remove unchecked items from stack

          if (!Ember.get(item, 'isActive')) {
            shippingInfos.removeObject(shippingInfo);
            return;
          } // add checked items if not in stack


          if (!shippingInfo) {
            shippingInfos.pushObject({
              country: country,
              shippingCostInfo: ''
            });
          }
        }
      },

      /**
       * Saves shop and triggers "action".
       *
       * @event save
       * @private
       */
      save: function save() {
        var _this2 = this;

        this._save().then(function () {
          return _this2.sendAction('action', Ember.get(_this2, 'shop'));
        });
      },

      /**
       * Saves shop and triggers "actionExit".
       *
       * @event saveExit
       * @private
       */
      saveExit: function saveExit() {
        var _this3 = this;

        this._save().then(function () {
          return _this3.sendAction('actionExit', Ember.get(_this3, 'shop'));
        });
      }
    },
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Initializes "shippingMode" depending
     * on shop's "shippingInfos" settings.
     *
     * @method _init
     * @private
     */
    _init: function _init() {
      var flatRatePriced = Ember.get(this, 'shop.flatRatePriced');
      var shippingInfos = Ember.get(this, 'shop.shippingInfos');
      var shippingMode = !shippingInfos.length ? 'WORLDWIDE' : 'REGIONS';
      Ember.set(this, 'flatRatePriced', flatRatePriced);
      Ember.set(this, 'shippingMode', shippingMode);
      this.send('updateMode', shippingMode);
    },

    /**
     * Primitive save method handling
     * common loading and error state.
     *
     * @method _save
     * @return {RSVP.Promise}
     * @private
     */
    _save: function _save() {
      var _this4 = this;

      var shop = Ember.get(this, 'shop');
      return shop.validate().then(function (_ref) {
        var validations = _ref.validations;

        _this4.set('didValidate', true);

        if (!validations.get('isValid')) {
          (0, _scrollIntoTheFirstErrorIfNeeded.default)();
          return Ember.RSVP.Promise.reject();
        }
      }).then(function () {
        // set shipping infos if user selected worlwide
        if (Ember.get(_this4, 'shippingMode') === 'WORLDWIDE') {
          Ember.set(_this4, 'shop.shippingInfos', []);
        }

        Ember.set(_this4, 'isLoading', true);
        return shop.save().catch(function (adapterError) {
          window.scrollTo(0, 0);
          Ember.set(_this4, 'isLoading', false);
          var errorMessage = (0, _getFirstAdapterErrorMessage.default)(adapterError);
          Ember.get(_this4, 'flashMessages').setErrors([errorMessage]);
          return Ember.RSVP.Promise.reject();
        }).finally(function () {
          return Ember.set(_this4, 'isLoading', false);
        });
      });
    },
    // -------------------------------------------------------------------------
    // Properties

    /**
     * When this is true, some computation is currently occuring.
     *
     * @property isLoading
     * @type Boolean
     * @private
     */
    isLoading: false,

    /**
     * Basic options of shipping countries.
     *
     * @property shippingModeOptions
     * @type Array
     * @private
     */
    shippingModeOptions: Ember.computed('l10n', {
      get: function get() {
        var l10n = Ember.get(this, 'l10n');
        return [{
          label: l10n.t('Worldwide'),
          value: 'WORLDWIDE'
        }, {
          label: l10n.t('Selected regions'),
          value: 'REGIONS'
        }];
      }
    }),

    /**
     * Currently selected shipping mode based
     * on 'shippingInfos' field of shop model.
     *
     * @property shippingMode
     * @type String
     * @private
     */
    shippingMode: null,

    /**
     * Collection of available shipping regions
     * to be used for nested tree component.
     *
     * @property shippingCountries
     * @type Array
     * @private
     */
    shippingRegions: Ember.computed(function () {
      return [];
    }),

    /**
     * Depending on shipping mode this flag
     * will be either true or false for view.
     *
     * @property shippingMode
     * @type Boolean
     * @public
     */
    showCountries: Ember.computed.equal('shippingMode', 'REGIONS')
  });

  _exports.default = _default;
});