define("@vollersgroup/hub-frontend/components/manage-shop-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A module to allow the selection of a shop.
   *
   * ```html
   * {{manage-shop-select action="selectShop"}}
   * ```
   *
   * The action receives the selected shop as parameter:
   *
   * ```js
   * selectShop: function(shop) {
   *    // do something with the shop
   * }
   * ```
   *
   * @namespace Component
   * @class ManageShopSelect
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    store: Ember.inject.service(),
    availableData: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * The name of the action to call when the creation / editing of the shop is done.
     * This action receives the edited shop as a single parameter.
     *
     * @attribute action
     * @type string
     * @required
     */
    action: null,

    /**
     * The shop to which this should be selected.
     * This should be the ID of this shop.
     *
     * @attribute selectedShopId
     * @type String
     */
    selectedShopId: null,
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Invokes `action` with `selectedShop`.
       *
       * @event submit
       * @private
       */
      submit: function submit() {
        this.sendAction('action', Ember.get(this, 'selectedShop'));
      },

      /**
       * Actually select a shop and continue.
       *
       * @event select
       * @param {String} id
       * @private
       */
      select: function select(id) {
        Ember.set(this, 'selectedShopId', id);
      }
    },
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Load the actual shop object for the selected shop id.
     *
     * @property selectedShop
     * @type Model.Shop
     * @private
     */
    selectedShop: Ember.computed('availableData.activeShops.[]', 'availableData.activeShops.firstObject.id', 'selectedShopId', 'store', function () {
      var id = Ember.get(this, 'selectedShopId') || Ember.get(this, 'availableData.activeShops.firstObject.id');
      return Ember.get(this, 'store').peekRecord('shop', id);
    })
  });

  _exports.default = _default;
});