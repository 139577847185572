define("@vollersgroup/hub-frontend/admin/manage/bulk/bulk-controller-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Base mixin for bulk controllers.
   *
   * @namespace Mixin
   * @class BulkControllerMixin
   * @extends Ember.Mixin
   */
  var _default = Ember.Mixin.create({
    // -------------------------------------------------------------------------
    // Dependencies
    availableData: Ember.inject.service(),
    bulkLookupData: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Query parameter definition.
     *
     * @property queryParams
     * @type {Array}
     * @public
     */
    queryParams: ['ids', 'step'],

    /**
     * Collection of selected model ids.
     *
     * @property ids
     * @type {Array}
     * @public
     */
    ids: [],

    /**
     * Current import step of bulk workflow.
     *
     * @property step
     * @type {String}
     * @public
     */
    step: 'upload',

    /**
     * Flag whether this controller is used
     * either for edit or create route.
     *
     * @property isEdit
     * @type {Boolean}
     * @public
     */
    isEdit: false,

    /**
     * Current model name for bulk importing.
     * This has to in sync with named routing
     * and model name, f.e. 'listing' on the
     * route /admin/manage/bulk/listing[-edit].
     *
     * @property currentImportMode
     * @type {String}
     * @public
     */
    modelName: null,

    /**
     * Name of overview route.
     *
     * @property overviewRoute
     * @type {String}
     * @public
     */
    overviewRoute: null,

    /**
     * Reference to handsontable output data.
     *
     * @property handsontableOutput
     * @type {Array}
     * @public
     */
    handsontableOutput: Ember.computed({
      get: function get()
      /*key*/
      {
        return [];
      }
    }),

    /**
     * Reference to handsontable input data.
     *
     * @property handsontableInput
     * @type {Array}
     * @public
     */
    handsontableInput: Ember.computed({
      get: function get()
      /*key*/
      {
        return [];
      }
    }),

    /**
     * Hashmap of available fields for bulk
     * workflow depending on `modelName`.
     *
     * @property availableFields
     * @type {Object}
     * @public
     */
    availableFields: Ember.computed('bulkLookupData', 'modelName', 'saveState', {
      get: function get()
      /*key*/
      {
        var modelName = Ember.get(this, 'modelName');
        var bulkLookupData = Ember.get(this, 'bulkLookupData');
        return Ember.get(bulkLookupData, "availableFields.".concat(modelName));
      }
    }),

    /**
     * Array of model keys valid for bulk grid.
     * Per default this is composition of all
     * `availableFields` plus `id` property.
     *
     *
     * @property fields
     * @type {Array}
     * @public
     */
    tableFields: Ember.computed('availableFields', 'modelName', 'saveState', {
      get: function get()
      /*key*/
      {
        var availableFields = Ember.get(this, 'availableFields');
        var fields = Object.keys(availableFields);
        fields = fields.concat(['id']);
        return fields;
      }
    }),

    /**
     * Map of all fields from current model.
     * This can be used to sanitize output
     * of bulk grid json data before call
     * store's `createRecord()` method.
     *
     * @property modelFields
     * @type {Map}
     * @public
     */
    modelFields: Ember.computed('modelName', 'saveState', 'store', {
      get: function get()
      /*key*/
      {
        var modelName = Ember.get(this, 'modelName');
        var modelClass = this.store.modelFor(modelName);
        var modelFields = Ember.get(modelClass, 'fields'); // `fields` doesn't contain `id`!

        modelFields.set('id', 'attribute');
        return modelFields;
      }
    }),

    /**
     * Hash keeping save state information
     * and consists of `isRunning`, `isFinished`,
     * `logs`, `addCount`, `editCount`, `errorCount`,
     * `totalCount` and `currentCount`. Note: This is
     * lazy initialized by init() and will be reset by
     * invoking the reset() method most likely by route.
     *
     * @property listingState
     * @type {Object}
     * @public
     */
    saveState: Ember.computed({
      get: function get()
      /*key*/
      {
        return {};
      }
    }),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Sets up initial state.
     *
     * @public
     * @method init
     * @return {Void}
     */
    init: function init() {
      this._super.apply(this, arguments);

      this.reset();
    },

    /**
     * Sets up `handsontableInput`
     * by mapping models to POJOs
     * if `isEdit` is set to true.
     *
     * @public
     * @method setup
     * @return {Void}
     */
    setup: function setup() {
      if (!Ember.get(this, 'isEdit')) {
        var step = Ember.get(this, 'model.step');
        Ember.set(this, 'step', step);
        return;
      }

      var model = Ember.get(this, 'model');
      var data = this.mapModelsToData_(model);
      Ember.set(this, 'handsontableInput', data);
    },

    /**
     * Resets `saveState` when the
     * controller gets destructed.
     *
     * @public
     * @method reset
     * @return {Void}
     */
    reset: function reset() {
      Ember.set(this, 'saveState', {
        logs: [],
        addCount: 0,
        editCount: 0,
        errorCount: 0,
        totalCount: 0,
        currentCount: 0,
        isRunning: false,
        isFinished: false
      });
      Ember.get(this, 'bulkLookupData').reset();
    },

    /**
     * Saves mapped models from table within an asynchrounous task queue.
     *
     * @private
     * @method _save
     * @param {Array} models Mapped models from table.
     *
     * @return {Void}
     */
    _save: function _save(models) {
      var _this = this;

      if (!Ember.get(this, 'saveState.isRunning')) {
        Ember.set(this, 'saveState.totalCount', models.length);
        Ember.set(this, 'saveState.currentCount', 1);
        Ember.set(this, 'saveState.isRunning', true);
      }

      var logs = Ember.get(this, 'saveState.logs');
      var model = models.shiftObject();
      var isNew = Ember.get(model, 'isNew');

      var successCallback = function successCallback() {
        if (isNew) {
          var addCount = Ember.get(_this, 'saveState.addCount');
          Ember.set(_this, 'saveState.addCount', addCount + 1);
        } else {
          var editCount = Ember.get(_this, 'saveState.editCount');
          Ember.set(_this, 'saveState.editCount', editCount + 1);
        }
      };

      var failureCallback = function failureCallback() {
        var errorCount = Ember.get(_this, 'saveState.errorCount');
        Ember.set(_this, 'saveState.errorCount', errorCount + 1);
      };

      var finallyCallback = function finallyCallback() {
        logs.pushObject({
          model: model,
          isNew: isNew
        });

        if (Ember.isEmpty(models)) {
          Ember.set(_this, 'saveState.isRunning', false);
          Ember.set(_this, 'saveState.isFinished', true);

          _this.afterSave_();

          return;
        }

        var currentCount = Ember.get(_this, 'saveState.currentCount');
        Ember.set(_this, 'saveState.currentCount', currentCount + 1); // >>> RECURSIVE CALL

        _this._save(models);
      };

      model.save().then(successCallback, failureCallback).finally(finallyCallback);
    },

    /**
     * Maps JSON data from table to models.
     *
     * @protected
     * @method mapDataToModels_
     * @return {Promise}
     */
    mapDataToModels_: function mapDataToModels_() {
      var _this2 = this;

      var customProperties = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var models = [];

      var promiseCallback = function promiseCallback(resolve, reject) {
        var data = Ember.get(_this2, 'handsontableOutput');
        var modelFields = Ember.get(_this2, 'modelFields'); // 2) iterate fetched or created listings
        // and update properties from table data

        var allSuccessCallback = function allSuccessCallback(results) {
          results.forEach(function (result) {
            // get model and json from RSVP response hash
            var model = result.model;
            var json = result.json; // invoke template methods to get model and json

            model = _this2.prepareModelForSave_(model, json);
            json = _this2.prepareDataForSave_(json, model); // merge new data from `json`

            model.setProperties(json); // and add model to stack

            models.push(model);
          }); // finally resolve listings

          resolve(models);
        };

        var allFailureCallback = function allFailureCallback() {
          reject();
        }; // 1) iterate table data and retrieve producers
        // either directly from store, server or create


        var promises = [];
        data.forEach(function (bulkGridRow) {
          // process only valid grid rows
          if (!bulkGridRow.getValid()) {
            return;
          } // create copy of row data and merge
          // custom properties into json data


          var json = bulkGridRow.getData();
          json = _this2.transformFromData_(json, modelFields);
          Ember.merge(json, customProperties); // try to reference model either by its ID
          // (in edit mode) or `erpId` attribute (in
          // create  mode), otherwise it's a new one

          var model = _this2.getModelFromData_(json); // push promise with listing/json to stack


          promises.push(Ember.RSVP.hash({
            model: model,
            json: json
          }));
        }); // now resolve all promises

        Ember.RSVP.all(promises).then(allSuccessCallback, allFailureCallback);
      };

      return new Ember.RSVP.Promise(promiseCallback);
    },

    /**
     * Maps models to JSON data for table.
     *
     * @protected
     * @param {Array} models
     * @method mapModelsToData_
     *
     * @return {Array}
     */
    mapModelsToData_: function mapModelsToData_(models) {
      var _this3 = this;

      var tableFields = Ember.get(this, 'tableFields');
      var data = [];
      models.forEach(function (model) {
        // append JSON representation of required fields by
        // using getProperties() method with given fields
        data.push(_this3.transformToData_(model, tableFields));
      });
      return data;
    },

    /**
     * Prepares model for bulk row. Can be used to
     * apply custom transforms before it's handed
     * over to handsontable. By default it calls
     * getProperties() on model with all fields
     * configured within `availableFields`.
     *
     * @protected
     * @param {Model} model
     * @param {Array} tableFields
     * @method transformToData_
     *
     * @return {Model}
     */
    transformToData_: function transformToData_(model, tableFields) {
      return model.getProperties(tableFields);
    },

    /**
     * Prepares data from bulk row. Can be used to
     * apply custom transforms before it's handed
     * over to model instance. By default it strips
     * all properties which are innalid model props,
     * otherwise this could lead to errors in store.
     * If you depend on data before stripping, call
     * `_super()` AFTER your transformations!
     *
     * @protected
     * @param {Object} json
     * @param {Array} fields
     * @method transformFromData_
     *
     * @return {Model}
     */
    transformFromData_: function transformFromData_(json, modelFields) {
      var sanitized = {};

      for (var key in json) {
        if (modelFields.has(key)) {
          sanitized[key] = json[key];
        }
      }

      return sanitized;
    },

    /**
     * Tries to retrieve model from store if there's
     * either an `id` or `erpId`. If both properties
     * are missing, it's assumed to be a new record.
     *
     * @protected
     * @param {Object} json
     * @method getModelFromData_
     *
     * @return {Model}
     */
    getModelFromData_: function getModelFromData_(json) {
      var modelName = Ember.get(this, 'modelName');
      var model;

      if (!Ember.isEmpty(json.id)) {
        if (this.store.hasRecordForId(modelName, json.id)) {
          model = this.store.peekRecord(modelName, json.id);
        } else {
          model = this.store.findRecord(modelName, json.id);
        }
      } else if (!Ember.isEmpty(json.erpId)) {
        model = this.store.query('listing', {
          company: Ember.get(this, 'userSession.currentCompany.id'),
          erpIds: [json.erpId]
        });
      } else {
        model = this.store.createRecord(modelName, json);
      }

      return model;
    },

    /**
     * Prepares model before invoking save().
     * This can be used to transform value
     * returned by getModelFromData_(). By
     * default, it checks for responses of
     * calls with an `erpId` query param.
     *
     * @protected
     * @param {Model} model
     * @param {Object} json
     * @method prepareModelForSave_
     *
     * @return {Model}
     */
    prepareModelForSave_: function prepareModelForSave_(model, json) {
      // /company/<id>/<model>?erpIds=[] delivers
      // always collection as result, therefore we
      // have to check if given `erpId` was found
      if (Ember.isArray(model)) {
        // try to get the first array entry as
        // it can only contain one listing here
        model = Ember.get(model, 'firstObject'); // create a new listing

        if (Ember.isNone(model)) {
          model = this.store.createRecord(Ember.get(this, 'modelName'), json);
        }
      }

      return model;
    },

    /**
     * Prepares json before invoking save().
     * This can be used to apply relations
     * and other custom data properties. By
     * default, this method doesn't apply
     * any transforms.
     *
     * @protected
     * @param {Object} json
     * @param {Model} model
     * @method prepareDataForSave_
     *
     * @return {Object}
     */
    prepareDataForSave_: function prepareDataForSave_(json
    /*, model*/
    ) {
      return json;
    },

    /**
     * Callback to be used for custom actions
     * after recursive save has been finished.
     *
     * @protected
     * @method afterSave_
     *
     * @return {Object}
     */
    afterSave_: function afterSave_() {},
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      //
      // Generic
      //

      /**
       * Transitions to given step with query param `step`.
       *
       * @event goToStep
       * @param {Object} step
       * @return {Void}
       * @private
       */
      goToStep: function goToStep(step) {
        if (Ember.isNone(step)) {
          return;
        }

        var currentStep = Ember.get(this, 'step');
        var stepId = Ember.get(step, 'id');

        if (currentStep === step) {
          return;
        }

        this.transitionToRoute({
          queryParams: {
            step: stepId
          }
        });
      },

      /**
       * Invokes recursive save queue.
       *
       * @event save
       * @param {Object} [customProperties={}]
       * @return {Void}
       * @private
       */
      save: function save() {
        var customProperties = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        this.mapDataToModels_(customProperties).then(Ember.run.bind(this, this._save));
      },

      /**
       * Resets `saveState` and redirects
       * to overview page from `modelName`.
       *
       * @event finish
       * @return {Void}
       * @private
       */
      finish: function finish() {
        this.transitionToRoute(Ember.get(this, 'overviewRoute'));
      }
    }
  });

  _exports.default = _default;
});