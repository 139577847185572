define("@vollersgroup/hub-frontend/pricing/route", ["exports", "ember-launch-darkly"], function (_exports, _emberLaunchDarkly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var pricingFlag = (0, _emberLaunchDarkly.variation)('pricing-page');

      if (!pricingFlag) {
        return this.transitionTo('index');
      }
    }
  });

  _exports.default = _default;
});