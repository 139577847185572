define("@vollersgroup/hub-frontend/company/model", ["exports", "ember-data", "ember-cp-validations", "@vollersgroup/hub-frontend/config/environment"], function (_exports, _emberData, _emberCpValidations, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var hasMany = _emberData.default.hasMany;
  var belongsTo = _emberData.default.belongsTo;
  var attr = _emberData.default.attr;
  var assetPrependURL = _environment.default.assetPrependURL;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      validators: [(0, _emberCpValidations.validator)('length', {
        allowNone: false,
        min: 3,
        max: 250,
        message: 'Please enter at least 3 characters.'
      })]
    },
    'location.country': {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true,
        message: 'Please select a country.'
      })]
    },
    'location.street': {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true,
        message: 'Please enter an address.'
      })]
    },
    'location.zip': {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true,
        message: 'Please enter a zip code.'
      }), (0, _emberCpValidations.validator)('length', {
        allowNone: false,
        max: 12,
        message: "Zip code can't be longer than 12 characters."
      })]
    },
    'location.city': {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true,
        message: 'Please enter a city'
      })]
    },
    'location.phone': {
      validators: [(0, _emberCpValidations.validator)('format', {
        allowBlank: false,
        // this is very basic validation that provided value is phone number
        // It must contain at least one number
        regex: /\d/,
        message: 'Please enter a phone number.'
      })]
    },
    'location.email': {
      validators: [(0, _emberCpValidations.validator)('format', {
        allowBlank: false,
        type: 'email',
        message: 'Please enter an email.'
      })]
    },
    'location.website': {
      validators: [(0, _emberCpValidations.validator)('format', {
        allowBlank: false,
        type: 'url',
        message: 'Please enter a website.'
      })]
    },
    vatNumber: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true,
        message: 'Please enter your VAT number.',
        disabled: Ember.computed.not('model.location.hasVatNumber')
      })]
    }
  });
  /**
   * The company model
   *
   * @namespace Model
   * @class Company
   * @extends Model
   */

  var _default = Model.extend(Validations, {
    /**
     * The company's name.
     *
     * @attribute name
     * @type String
     */
    name: attr('string'),

    /**
     * The optional VAT Number of this company. Used to prefill for the checkout.
     *
     * @attribute vatNumber
     * @type String
     */
    vatNumber: attr('string'),

    /**
     * The companies location
     *
     * @attribute location
     * @type Model.Location
     */
    location: belongsTo('location', {
      async: false
    }),

    /**
     * The image object for the shop's logo.
     *
     * @attribute logo
     * @type Object
     */
    logo: attr('image'),

    /**
     * The full URL to the companies' logo, in 500x500.
     *
     * @property logoUrl
     * @type string
     */
    logoUrl: Ember.computed('logo.s500x500', function () {
      var logo = this.get('logo.s500x500');

      if (logo) {
        return logo;
      }

      return assetPrependURL + '/assets/img/placeholder-company-logo.png';
    }),

    /**
     * If the company has already been verified as a seller.
     *
     * @attribute verificationStatus
     * @type null|PENDING|VERIFIED|REJECTED
     * @default null
     */
    verificationStatus: attr('string', {
      defaultValue: null
    }),

    /**
     * The date & time when the verified-status was last updated.
     * This is either the time when the request for verification was made (when verified === PENDING)
     * or the time when the request was accepted/rejected,
     * or null when no request has been made yet.
     *
     * @attribute verifiedDate
     * @type Date
     * @default null
     */
    verifiedDate: attr('timestamp'),

    /**
     * The date & time when the request for verification was made (when verified === PENDING)
     *
     * @attribute verificationRequestedDate
     * @type Date
     * @default null
     */
    verificationRequestedDate: attr('timestamp'),

    /**
     * Flag if company is a verified buyer account.
     * This is if is known Vollers customer (f.e. CSAR) and NOT a seller.
     * Sellers are verified by hand before they can sell on V-Hub and are ALWAYS verified.
     *
     * @attribute verifiedBuyerAccount
     * @type Boolean
     * @default false
     */
    verifiedBuyerAccount: attr('boolean', {
      defaultValue: false
    }),

    /**
     * The date & time when the company has been rejected.
     *
     * @attribute rejectedDate
     * @type Date
     * @default null
     */
    rejectedDate: attr('timestamp'),

    /**
     * The date & time when the company has been created.
     *
     * @attribute createdDate
     * @type Date
     * @default null
     */
    createdDate: attr('timestamp'),

    /**
     * If company has activated the auctions module.
     *
     * @attribute auctionsEnabled
     * @type Boolean
     * @default false
     */
    auctionsEnabled: attr('boolean', {
      defaultValue: false
    }),

    /**
     * The owner of this company.
     *
     * @attribute owner
     * @type Model.User
     */
    owner: belongsTo('user', {
      async: true,
      inverse: null
    }),

    /**
     * The company subscription
     *
     * @attribute subscription
     * @type Model.Subscription
     */
    subscription: belongsTo('subscription'),

    /**
     * A list of users that are part of this company.
     *
     * @attribute users
     * @type Model.User[]
     */
    users: hasMany('user', {
      async: true,
      inverse: 'company'
    }),

    /**
     * An array of shops that belong to the company.
     *
     * @attribute shops
     * @type Model.Shop[]
     */
    shops: hasMany('shop', {
      async: true,
      inverse: 'company'
    }),

    /**
     * A dedicated setting model containing company
     * wide settings for shops, templates etc. as a
     * nested JSON structure.
     *
     * @attribute settings
     * @type Model.Settings
     */
    settings: belongsTo('settings', {
      async: true
    }),

    /**
     * An array of blacklisted buyers from company.
     *
     * @attribute blacklistItems
     * @type Model.Shop[]
     */
    blacklistItems: hasMany('blacklist-item', {
      inverse: 'flaggedCompany',
      async: true
    }),

    /**
     * Returns true if verified seller or buyer account.
     */
    isVerifiedAccount: Ember.computed.or('isVerified', 'verifiedBuyerAccount'),

    /**
     * Returns true if the company has been verified.
     */
    isVerified: Ember.computed.equal('verificationStatus', 'VERIFIED'),

    /**
     * Returns true if the company has been rejected.
     */
    isRejected: Ember.computed.equal('verificationStatus', 'REJECTED'),

    /**
     * Returns true if the company is pending for verification.
     */
    isPending: Ember.computed.equal('verificationStatus', 'PENDING'),

    /**
     * If the company has at least 1 shop
     *
     * @attribute hasShops
     * @type Boolean
     */
    hasShops: Ember.computed.gt('shops.length', 0),

    /**
     * Hash map of black list items with company id as key.
     *
     * @property blacklistCompanies
     * @type Object
     */
    blacklistCompanies: Ember.computed('blacklistItems.[]', function () {
      var map = {};
      Ember.get(this, 'blacklistItems').forEach(function (blacklistItem) {
        map[Ember.get(blacklistItem, 'flaggedCompany.id')] = blacklistItem;
      });
      return map;
    })
  });

  _exports.default = _default;
});