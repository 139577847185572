define("@vollersgroup/hub-frontend/components/bulk-workflow/master/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3t7AF8lD",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"wrapper\"],[9],[0,\"\\n\"],[0,\"\\n\"],[4,\"if\",[[23,[\"partialBody\"]]],null,{\"statements\":[[0,\"    \"],[15,[23,[\"partialBody\"]],[]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[14,1,[[22,0,[]],\"step_content\"]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[0,\"  \"],[15,[23,[\"partialFooter\"]],[]],[0,\"\\n\"],[10]],\"hasEval\":true}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/bulk-workflow/master/edit/template.hbs"
    }
  });

  _exports.default = _default;
});