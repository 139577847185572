define("@vollersgroup/hub-frontend/admin/manage/overview/warehouse/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ember-launch-darkly"], function (_exports, _authenticatedRouteMixin, _emberLaunchDarkly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    availableData: Ember.inject.service(),
    legacyWarehouseMigration: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (!this.legacyWarehouseMigration.hasLegacyWarehouses && (0, _emberLaunchDarkly.variation)('new-warehouses')) {
        this.transitionTo('admin.manage.overview.warehouses.index');
      }
    },
    activate: function activate() {
      this.controllerFor(this.get('routeName')).notifyPropertyChange('orderBy');
    }
  });

  _exports.default = _default;
});