define("@vollersgroup/hub-frontend/components/auction-listing/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Display a single listing for auction.
   */
  var _default = Ember.Component.extend({
    websocket: Ember.inject.service(),
    userSession: Ember.inject.service(),
    tagName: 'div',
    classNames: ['card', 'table-running-auction__tr'],
    classNameBindings: ['hasNewBid:recent-bid', 'showScore::table-running-auction__tr--no-score'],
    listing: null,

    /*
     * Action passed from the parent to mark coffee as favorite.
     */
    markAsFavoriteAction: null,

    /*
     * List of listing ids marked as favorite
     */
    favoriteListingIds: null,
    hasNewBid: false,
    _watchForNewBid: Ember.observer('listing.bidCount', function () {
      if (Ember.get(this, 'isDestroyed')) {
        return;
      }

      Ember.set(this, 'hasNewBid', true);
      Ember.run.debounce(this, function () {
        if (Ember.get(this, 'isDestroyed')) {
          return;
        }

        Ember.set(this, 'hasNewBid', false);
      }, 7000);
    }),

    /**
     * Flag for score column visibility.
     *
     * @attribute showScore
     * @type {Boolean}
     * @optional
     */
    showScore: true,
    isFavorite: Ember.computed('favoriteListingIds.[]', 'listing.id', function () {
      return Ember.get(this, 'favoriteListingIds').includes(Ember.get(this, 'listing.id'));
    }),
    style: Ember.computed('listing.groupColor', function () {
      return Ember.String.htmlSafe("border-color: ".concat(Ember.get(this, 'listing.groupColor')));
    }),
    isRunningNotConnected: Ember.computed('websocket.isConnected', 'listing.auction.isRunning', function () {
      return !this.get('websocket.isConnected') && this.get('listing.auction.isRunning');
    })
  });

  _exports.default = _default;
});