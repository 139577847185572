define("@vollersgroup/hub-frontend/listing/ability", ["exports", "ember-can", "@vollersgroup/hub-frontend/config/environment"], function (_exports, _emberCan, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend({
    userSession: Ember.inject.service(),
    roleLevel: Ember.computed.alias('userSession.currentUser.roleLevel'),
    // Collaborators should be able to do all listing maintenance,
    // including publishing them
    canCreate: Ember.computed.gte('roleLevel', 2),
    canEdit: Ember.computed.gte('roleLevel', 2),
    canPublish: Ember.computed.gte('roleLevel', 2),
    canBuy: Ember.computed('roleLevel', 'userSession.currentCompany.id', 'model.company.id', 'model.isActive', function () {
      if (this.get('roleLevel') < 3) {
        return false;
      }

      var currentCompanyId = this.get('userSession.currentCompany.id');
      var listingCompanyId = this.get('model.company.id');

      if (currentCompanyId === listingCompanyId) {
        return false;
      }

      if (!this.get('model.isActive')) {
        return false;
      }

      return true;
    }),
    canViewPrice: Ember.computed('userSession.isAuthenticated', 'model.priceHidden', 'model.bidCount', 'model.auction.isAccepted', 'model.auction.isOwner', 'model.auction.isClosed', function () {
      if (Ember.get(this, 'model.auction.isClosed')) {
        return true;
      }

      if (!this.get('userSession.isAuthenticated')) {
        return false;
      }

      if (!Ember.get(this, 'model.priceHidden')) {
        this._reloadPrice();

        return true;
      } // This check here is required, because of the property listing.currentPrice.
      // That property is visible only to admin and auction participant unless there
      // is a highest bid on listing.


      if (Ember.get(this, 'model.bidCount')) {
        this._reloadPrice();

        return true;
      }

      if (Ember.get(this, 'model.auction.isAccepted')) {
        this._reloadPrice();

        return true;
      }

      if (Ember.get(this, 'model.auction.isOwner')) {
        this._reloadPrice();

        return true;
      }

      return false;
    }),

    /*
     * we may need to reload current model, because initial
     * server response doesn't include `priceAmount`!
     */
    _reloadPrice: function _reloadPrice() {
      if (_environment.default.environment === 'test') {
        return;
      }

      if (Ember.get(this, 'model.isLoading')) {
        return;
      }

      if (!Ember.get(this, 'model.price')) {
        Ember.get(this, 'model').reload();
      }
    }
  });

  _exports.default = _default;
});