define("@vollersgroup/hub-frontend/components/manage-shop-defaults/component", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PromiseObject = _emberData.default.PromiseObject;
  /**
   * A module to edit a shop's shipping.
   *
   * ```html
   * {{manage-shop-defaults shop=myShop action="myAction"}}
   * ```
   *
   * The action should have the following format:
   *
   * ```js
   * myAction: function(shop) {
   *     // Do something with the shop
   *     shop.save();
   * }
   * ```
   *
   * The action is only called when the shop is valid.
   *
   * @namespace Component
   * @class ManageShopDefaults
   * @extends Ember.Component
   */

  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    ajax: Ember.inject.service(),
    availableData: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * The shop that should be edited / created.
     *
     * @attribute shop
     * @type Model.Shop
     * @required
     */
    shop: null,

    /**
     * The name of the action to call when the creation / editing of the shop is done.
     * This action receives the edited shop as a single parameter.
     *
     * @attribute action
     * @type string
     * @required
     */
    action: null,

    /**
     * If this is set to true, this is an edit dialog (instead of a create dialog)
     *
     * @attribute isEdit
     * @type Boolean
     * @optional
     */
    isEdit: false,

    /**
     * The name of the action to call when the creation / editing of the shop is done.
     * This action receives the edited shop as a single parameter.
     * This action is called when the process should be ended instead of continuing to the next step.
     *
     * @attribute actionExit
     * @type string
     * @required
     */
    actionExit: null,
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Saves settings and triggers "action".
       *
       * @event save
       * @private
       */
      save: function save() {
        var _this = this;

        this._save().then(function () {
          return _this.sendAction('action', _this.getAttr('shop'));
        });
      },

      /**
       * Saves settings and triggers "actionExit".
       *
       * @event saveExit
       * @private
       */
      saveExit: function saveExit() {
        var _this2 = this;

        this._save().then(function () {
          return _this2.sendAction('actionExit', _this2.getAttr('shop'));
        });
      }
    },
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Primitive save method handling
     * common loading and error state.
     *
     * @method _save
     * @return {RSVP.Promise}
     * @private
     */
    _save: function _save() {
      var _this3 = this;

      var settings = Ember.get(this, 'shop.company.settings');
      return settings.then(function (settings) {
        Ember.set(_this3, 'isLoading', true);
        return settings.save().catch(function () {
          window.scrollTo(0, 0);
          Ember.set(_this3, 'isLoading', false);
          var errorMessage = Ember.get(_this3, 'l10n').t('An error occurred. Please review your input and try again or contact our support team.');
          Ember.get(_this3, 'flashMessages').setErrors([errorMessage]);
        });
      });
    },
    // -------------------------------------------------------------------------
    // Properties

    /**
     * When this is true, some computation is currently occuring.
     *
     * @property isLoading
     * @type Boolean
     * @private
     */
    isLoading: false,

    /**
     * Reference to shop settings from `setting` model, which
     * is an object under path `setting.contents.${shopId}`.
     *
     * @property shopSettings
     * @type Object
     * @private
     */
    shopSettings: Ember.computed('shop.company.settings', 'shop.slug', {
      get: function get()
      /*key*/
      {
        var _this4 = this;

        var promiseCallback = function promiseCallback(settings) {
          var contents = Ember.get(settings, 'contents');
          var slug = Ember.get(_this4, 'shop.slug');

          if (Ember.isNone(Ember.get(contents, slug))) {
            Ember.set(contents, slug, {});
          }

          return Ember.get(contents, slug);
        };

        return PromiseObject.create({
          promise: Ember.get(this, 'shop.company.settings').then(promiseCallback)
        });
      }
    }),

    /**
     * Reference to shop setting's default values for listings.
     *
     * @property defaults
     * @type Object
     * @private
     */
    defaults: Ember.computed('shopSettings', {
      get: function get()
      /*key*/
      {
        var promiseCallback = function promiseCallback(shopSettings) {
          if (Ember.isNone(Ember.get(shopSettings, 'defaults'))) {
            Ember.set(shopSettings, 'defaults', {});
          }

          return Ember.get(shopSettings, 'defaults');
        };

        return PromiseObject.create({
          promise: Ember.get(this, 'shopSettings').then(promiseCallback)
        });
      }
    })
  });

  _exports.default = _default;
});