define("@vollersgroup/hub-frontend/components/sales-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A single order from the seller-view.
   *
   * ```html
   * {{sales-item order=order}}
   * ```
   *
   * @namespace Component
   * @class SalesItem
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Element Properties
    classNameBindings: [':listing__item', ':card', ':card__order', ':card--grey'],
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * The order which should be displayed.
     *
     * @attribute order
     * @type Model.Order
     */
    order: null,
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Links to order if clicked in an area
     * outside of the default one specified.
     *
     * @param  {Object} event jQuery event.
     * @return {Void}
     */
    click: function click() {
      this.get('router').transitionTo('admin.sales.show', this.get('order'));
    }
  });

  _exports.default = _default;
});