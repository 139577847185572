define("@vollersgroup/hub-frontend/components/user-password-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @namespace Ember.Component
   * @class UserPasswordForm
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    isSavingPassword: false,
    newPassword: null,
    oldPassword: null,
    hasErrorPassword: '',
    hasSuccessPassword: false,
    actions: {
      savePassword: function savePassword() {
        var _this = this;

        var newPassword = this.get('newPassword');
        var oldPassword = this.get('oldPassword');
        this.set('hasErrorPassword', null);
        this.set('hasSuccessPassword', false);

        if (!newPassword || !oldPassword) {
          return;
        }

        if (newPassword.length < 8) {
          this.set('hasErrorPassword', this.get('l10n').t('Your new password must be at least 8 characters long.'));
          return;
        }

        this.set('isSavingPassword', true);
        this.get('ajax').post('/user/changePassword', {
          data: {
            oldPassword: oldPassword,
            newPassword: newPassword
          }
        }).then(function () {
          _this.set('isSavingPassword', false);

          _this.set('oldPassword', '');

          _this.set('newPassword', '');

          _this.set('hasSuccessPassword', true);
        }).catch(function () {
          _this.set('hasErrorPassword', _this.get('l10n').t('Your current password is incorrect!'));

          _this.set('isSavingPassword', false);
        });
      }
    }
  });

  _exports.default = _default;
});