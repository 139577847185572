define("@vollersgroup/hub-frontend/components/sales-by-listing/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    orderManager: Ember.inject.service(),

    /**
     * The orders to display.
     *
     * @attribute ordersByCoffee
     * @type Model.Orders[]
     */
    ordersByCoffee: null,

    /**
     * If the shipping address should be displayed
     *
     * @attribute showShippingAddress
     * @type boolean
     * @default false
     */
    showShippingAddress: false
  });

  _exports.default = _default;
});