define("@vollersgroup/hub-frontend/components/bulk-workflow/mapping/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dVfkZDM5",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"h3\"],[9],[1,[27,\"t\",[\"Match your columns\"],null],false],[10],[0,\"\\n\"],[7,\"p\"],[9],[1,[27,\"t\",[\"Manage how your data should be imported into the schema of V-Hub. This will be saved as an import template for all upcoming uploads and can be edited at any time. If you want to ignore data of your spreadsheet, 'skip' the column. For the remaining, click 'edit' and choose the suitable available field on V-Hub.\"],null],false],[10],[0,\"\\n\\n\"],[14,1,[[22,0,[]],\"before_component\"]],[0,\"\\n\\n\"],[1,[27,\"bulk-import-mapper\",null,[[\"template\",\"availableFields\",\"templateData\",\"onMappingSuccess\",\"onSetTemplateName\"],[[27,\"readonly\",[[23,[\"currentTemplate\"]]],null],[27,\"readonly\",[[23,[\"availableFields\"]]],null],[27,\"readonly\",[[23,[\"currentTemplateData\"]]],null],[27,\"action\",[[22,0,[]],\"onMappingSuccess\"],null],[27,\"action\",[[22,0,[]],\"onSetTemplateName\"],null]]]],false],[0,\"\\n\\n\"],[14,1,[[22,0,[]],\"after_component\"]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/bulk-workflow/mapping/template.hbs"
    }
  });

  _exports.default = _default;
});