define("@vollersgroup/hub-frontend/components/qr-code/component", ["exports", "qrious"], function (_exports, _qrious) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'canvas',
    size: 150,
    value: '',
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      new _qrious.default({
        element: this.element,
        size: Ember.get(this, 'size'),
        value: Ember.get(this, 'value'),
        level: 'M'
      });
      var logoImg = document.createElement('img');
      logoImg.src = '/assets/img/v-hub-logo-small.png';

      logoImg.onload = function () {
        _this._drawLogo(logoImg);
      };
    },
    _drawLogo: function _drawLogo(logoImg) {
      var canvas = this.element;
      var ctx = canvas.getContext('2d');
      var qrCodeSize = Ember.get(this, 'size');
      var imgWidth = logoImg.width;
      var imgHeight = logoImg.height;
      var logoWidth = qrCodeSize / 7;
      var logoHeight = imgHeight / imgWidth * logoWidth;
      var x = qrCodeSize / 2 - logoWidth / 2;
      var y = qrCodeSize / 2 - logoHeight / 2;
      var padding = qrCodeSize / 10;
      var rectangleX = x - padding / 2;
      var rectangleY = y - padding / 2;
      var rectangleWidth = logoWidth + padding;
      var rectangleHeight = logoHeight + padding;
      ctx.fillStyle = 'white';
      ctx.rect(rectangleX, rectangleY, rectangleWidth, rectangleHeight);
      ctx.fill();
      ctx.drawImage(logoImg, x, y, logoWidth, logoHeight);
    }
  });

  _exports.default = _default;
});