define("@vollersgroup/hub-frontend/forgot-password/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    actions: {
      sendForgotPasswordRequest: function sendForgotPasswordRequest() {
        var _this = this;

        if (this.get('isLoading')) {
          return;
        }

        this.set('errors', []);
        this.set('isLoading', true);
        this.get('ajax').post('/user/resetPassword/request', {
          data: {
            email: this.get('email')
          }
        }).then(function () {
          _this.set('isLoading', false);

          _this.set('success', true);
        }, function () {
          _this.set('errors', [{
            errorTitle: '',
            errorMessage: _this.get('l10n').t('An error occurred! Please try again later.')
          }]);

          _this.set('isLoading', false);
        });
      }
    },

    /**
     * If a loading process is currently happening.
     *
     * @property isLoading
     * @type Boolean
     * @default false
     */
    isLoading: false,

    /**
     * Errors that have happened.
     *
     * @property errors
     * @type Array
     */
    errors: Ember.computed(function () {
      return [];
    }),

    /**
     * If the request was successful.
     *
     * @property success
     * @type Boolean
     */
    success: false,

    /**
     * The email of the user.
     *
     * @property email
     * @type String
     */
    email: null
  });

  _exports.default = _default;
});