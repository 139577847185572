define("@vollersgroup/hub-frontend/admin/manage/bulk/bulk-listing-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Base mixin for listing route and controller.
   *
   * @namespace Mixin
   * @class BulkListingMixin
   * @extends Ember.Mixin
   */
  var _default = Ember.Mixin.create({
    l10n: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * @property modelName
     */
    modelName: 'listing',

    /**
     * @property overviewRoute
     */
    overviewRoute: 'admin.manage.overview',

    /**
     * @property modelTranslations
     */
    modelTranslations: Ember.computed('l10n', {
      get: function get()
      /*key*/
      {
        return {
          singular: Ember.get(this, 'l10n').t('listing'),
          plural: Ember.get(this, 'l10n').t('listings')
        };
      }
    })
  });

  _exports.default = _default;
});