define("@vollersgroup/hub-frontend/components/c-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5xBhKl5K",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"input\",[[27,\"-input-type\",[[23,[\"type\"]]],null]],[[\"id\",\"name\",\"type\",\"value\",\"required\",\"disabled\",\"placeholder\",\"minlength\",\"maxlength\",\"autocomplete\",\"input\",\"class\",\"step\",\"min\",\"max\"],[[23,[\"domId\"]],[23,[\"domId\"]],[23,[\"type\"]],[23,[\"_value\"]],[23,[\"required\"]],[23,[\"disabled\"]],[23,[\"placeholder\"]],[23,[\"minlength\"]],[23,[\"maxlength\"]],[23,[\"autocomplete\"]],[27,\"action\",[[22,0,[]],\"update\"],null],[27,\"concat\",[[23,[\"class\"]],[27,\"if\",[[23,[\"hasError\"]],\" input--has-error\",\"\"],null]],null],[27,\"if\",[[27,\"eq\",[[23,[\"type\"]],\"number\"],null],[23,[\"step\"]],null],null],[27,\"if\",[[27,\"eq\",[[23,[\"type\"]],\"number\"],null],[23,[\"min\"]],null],null],[27,\"if\",[[27,\"eq\",[[23,[\"type\"]],\"number\"],null],[23,[\"max\"]],null],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/c-input/template.hbs"
    }
  });

  _exports.default = _default;
});