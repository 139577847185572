define("@vollersgroup/hub-frontend/admin/sales/list-loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bT+k42gj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"wrapper\"],[9],[0,\"\\n\\n  \"],[7,\"h1\"],[11,\"class\",\"font-size-3 inline-block margin-right-2\"],[9],[1,[27,\"t\",[\"Sales\"],null],false],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"no-screen inline-block\"],[9],[0,\"(\"],[1,[27,\"moment-format\",[[23,[\"currentDate\"]]],null],false],[0,\")\"],[10],[0,\"\\n\\n  \"],[7,\"button\"],[11,\"class\",\"button button--secondary button--half-height margin-right-2\"],[9],[0,\"\\n    \"],[1,[27,\"svg-icon\",[\"print\"],[[\"class\"],[\"inline-icon margin-right-2\"]]],false],[0,\"\\n    \"],[7,\"span\"],[9],[1,[27,\"t\",[\"Print list\"],null],false],[10],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"print\"]],[10],[0,\"\\n\\n  \"],[7,\"button\"],[11,\"class\",\"button button--secondary button--half-height\"],[9],[0,\"\\n    \"],[1,[27,\"svg-icon\",[\"print-labels-on-hub\"],[[\"class\"],[\"inline-icon margin-right-2\"]]],false],[0,\"\\n    \"],[7,\"span\"],[9],[1,[27,\"t\",[\"Print labels\"],null],false],[10],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"printLabels\"]],[10],[0,\"\\n\\n  \"],[7,\"ul\"],[11,\"class\",\"tab__header tab__header__sales float-right\"],[9],[0,\"\\n\\n    \"],[7,\"li\"],[11,\"class\",\"tab__item-wrapper\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"admin.sales.list.index\"],null,{\"statements\":[[0,\"        \"],[1,[27,\"t\",[\"By order\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n    \"],[7,\"li\"],[11,\"class\",\"tab__item-wrapper\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"admin.sales.list.coffee\"],null,{\"statements\":[[0,\"        \"],[1,[27,\"t\",[\"By coffee\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"wrapper\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"box--bg\"],[9],[0,\"\\n    \"],[1,[21,\"loading-spinner\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/admin/sales/list-loading/template.hbs"
    }
  });

  _exports.default = _default;
});