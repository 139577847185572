define("@vollersgroup/hub-frontend/initializers/l10n", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.inject('model', 'l10n', 'service:l10n');
    application.inject('route', 'l10n', 'service:l10n');
    application.inject('controller', 'l10n', 'service:l10n');
    application.inject('component', 'l10n', 'service:l10n');
  }

  var _default = {
    name: 'l10n',
    initialize: initialize
  };
  _exports.default = _default;
});