define("@vollersgroup/hub-frontend/components/user-settings-form/component", ["exports", "@vollersgroup/hub-frontend/utils/scroll-into-the-first-error-if-needed", "@vollersgroup/hub-frontend/config/environment", "moment", "@vollersgroup/hub-frontend/helpers/prettify-timezone", "@vollersgroup/hub-frontend/user/model"], function (_exports, _scrollIntoTheFirstErrorIfNeeded, _environment, _moment, _prettifyTimezone, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The form to update a user's personal settings.
   *
   * ```hbs
   * {{user-settings-form user=(readonly model) action="saveUser"}}
   * ```
   *
   * @namespace Component
   * @class UserSettingsForm
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend(_model.UserValidations, {
    l10n: Ember.inject.service(),
    availableData: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * The user to change the settings for.
     *
     * @attribute user
     * @type Model.User
     */
    user: null,

    /**
     * If the image upload should be hidden.
     *
     * @attribute hideImageUpload
     * @type Boolean
     */
    hideImageUpload: false,

    /**
     * If this is set to true, the form should be hidden after saving and a message should be displayed.
     *
     * @attribute hideAfterSave
     * @type Boolean
     * @optional
     * @default false
     */
    hideAfterSave: false,

    /**
     * The action to call when clicking on the save button.
     * The action receives the user as parameter.
     *
     * @event action
     * @param {Object} data The new user data
     * @public
     */
    action: null,
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Is called when an image should be uploaded.
       *
       * @event uploadUserAvatar
       * @param file The file object
       * @private
       */
      uploadUserAvatar: function uploadUserAvatar(file) {
        var _this = this;

        var headers = {
          'Cropster-Authenticate': 'suppress-www-authenticate'
        };
        var data = {
          entity: 'AVATAR'
        };
        var url = _environment.default.apiBaseUrl + '/' + _environment.default.apiNamespace + '/media';
        file.upload({
          url: url,
          data: data,
          headers: headers
        }).then(function (response) {
          var data = response.body;
          var id = data.id;
          var url = data.url;

          _this.set('avatar', Ember.Object.create({
            id: id,
            fullxfull: url,
            s300x300: url
          }));

          _this.set('hasChanged', true);

          _this.set('isSaved', false);
        }).catch(function () {
          var l10n = Ember.get(_this, 'l10n');
          var errMessage = l10n.t('An error occurred while trying to upload the image. Please try again with a different file.');

          _this.get('toast').error(errMessage); // Remove file itself from queue so user can try upload again


          file.get('queue').remove(file);
        });
      },

      /**
       * Is called when the user clicks the save button.
       *
       * @event save
       * @private
       */
      save: function save() {
        this.set('didValidate', true);

        if (!this.validateSync({
          on: ['fullName']
        }).validations.get('isValid')) {
          (0, _scrollIntoTheFirstErrorIfNeeded.default)();
          return;
        }

        if (this.getAttr('action')) {
          this.sendAction('action', {
            fullName: this.get('fullName'),
            dateFormat: this.get('dateFormat'),
            timeFormat: this.get('timeFormat'),
            timezone: this.get('timezone'),
            language: this.get('language'),
            avatar: this.get('avatar')
          });
        }

        this.set('isSaved', true);
        this.set('hasChanged', false);
      },
      changeFullName: function changeFullName(val) {
        this.set('fullName', val);
        this.set('hasChanged', true);
        this.set('isSaved', false);
      },
      changeTimezone: function changeTimezone(val) {
        this.set('timezone', val);
        this.set('hasChanged', true);
        this.set('isSaved', false);
      },
      changeDateFormat: function changeDateFormat(val) {
        this.set('dateFormat', val);
        this.set('hasChanged', true);
        this.set('isSaved', false);
      },
      changeTimeFormat: function changeTimeFormat(val) {
        this.set('timeFormat', val);
        this.set('hasChanged', true);
        this.set('isSaved', false);
      },
      changeLanguage: function changeLanguage(val) {
        this.get('l10n').setLocale(val);
      }
    },
    // -------------------------------------------------------------------------
    // Properties

    /**
     * If any errors have been detected.
     *
     * @property hasError
     * @type Boolean
     * @default false
     * @private
     */
    hasError: false,
    hasChanged: false,

    /**
     * If the form has been saved.
     *
     * @property isSaved
     * @type Boolean
     * @default false
     * @private
     */
    isSaved: false,
    availableTimezones: Ember.computed(function () {
      var timezones = _moment.default.tz.names();

      return timezones.map(function (timezone) {
        return {
          value: timezone,
          label: (0, _prettifyTimezone.prettifyTimezone)([timezone])
        };
      });
    }),
    availableLanguages: Ember.computed('l10n.availableLocales', function () {
      var availableLocales = this.get('l10n.availableLocales');
      var languageOptions = [];

      for (var key in availableLocales) {
        var label = availableLocales[key];
        languageOptions.push({
          id: key,
          label: label
        });
      }

      return languageOptions;
    }),
    fullName: null,
    avatar: null,
    timezone: null,
    dateFormat: null,
    timeFormat: null,
    language: Ember.computed.reads('l10n.locale'),
    // -------------------------------------------------------------------------
    // Methods
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var user = this.getAttr('user');

      if (user) {
        this.setProperties({
          fullName: user.get('fullName'),
          avatar: user.get('avatar'),
          timezone: user.get('timezone'),
          dateFormat: user.get('dateFormat'),
          timeFormat: user.get('timeFormat')
        });
      }
    },
    // -------------------------------------------------------------------------
    // Observers

    /**
     * This is for the case that user changes
     * language not within component's UI, but
     * within another global element, f.e. footer.
     *
     * @type observer _languageChanged
     * @private
     */
    _languageChanged: Ember.observer('l10n.locale', function () {
      this.set('hasChanged', true);
      this.set('isSaved', false);
    })
  });

  _exports.default = _default;
});