define("@vollersgroup/hub-frontend/shop/model", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var belongsTo = _emberData.default.belongsTo;
  var attr = _emberData.default.attr;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      validators: [(0, _emberCpValidations.validator)('length', {
        allowNone: false,
        min: 3,
        message: 'Please enter at least 3 characters.'
      })]
    },
    shortDescription: [(0, _emberCpValidations.validator)('length', {
      allowNone: false,
      min: 5,
      message: 'Please enter at least 5 characters.'
    })],
    slug: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true,
        message: 'Please enter at least 4 characters with a minimum of 1 letter. Only lowercase letters, numbers and dashes are allowed.'
      }), (0, _emberCpValidations.validator)('slug', {
        description: 'URL'
      }), (0, _emberCpValidations.validator)('unique-shop-slug', {
        disabled: Ember.computed.not('model.isNew')
      })]
    },
    storyHeadline: [(0, _emberCpValidations.validator)('length', {
      allowNone: false,
      min: 5,
      message: 'Please enter at least 5 characters.'
    })],
    story: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'Please enter your story.'
    })],
    'location.city': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'Please enter a city.'
    })],
    'location.country': {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true,
        message: 'Please select a country.'
      })]
    },
    logoUrl: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true,
        message: 'Please upload a logo.'
      })]
    },
    headerImageUrl: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true,
        message: 'Please upload a header image.'
      })]
    },
    usdExchangeRate: {
      validators: [(0, _emberCpValidations.validator)('number', {
        allowString: false,
        allowNone: false,
        gt: 0,
        message: 'The exchange rate must be a number greater than 0.'
      })]
    },
    shippingInfos: {
      validators: [(0, _emberCpValidations.validator)('length', {
        dependentKeys: ['model.shippingInfos.length'],
        allowBlank: false,
        allowNone: false,
        min: 1,
        message: 'Please choose at least one shipping country.',
        disabled: Ember.computed.not('model.flatRatePriced')
      })]
    }
  });
  /**
   * The shop model
   *
   * @namespace Model
   * @class Shop
   * @extends Model
   */

  var _default = Model.extend(Validations, {
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * The shop's name.
     *
     * @attribute name
     * @type String
     */
    name: attr('string'),

    /**
     * The shop's short description. Does not accept markdown.
     *
     * @attribute shortDescription
     * @type String
     */
    shortDescription: attr('string'),

    /**
     * A human-readable id for the URL.
     *
     * @attribute slug
     * @type String
     */
    slug: attr('string'),

    /**
     * The ISO code (alphabetic) for the seller's native currency.
     * e.g. USD
     *
     * @attribute currency
     * @type String
     * @default "USD"
     */
    currency: attr('string', {
      defaultValue: 'USD'
    }),

    /**
     * The number of active listings in the shop.
     *
     * @attribute listingActiveCount
     * @type Number
     */
    listingActiveCount: attr('number'),

    /**
     * The shops location.
     *
     * @attribute location
     * @type Model.Location
     */
    location: belongsTo('location', {
      async: false
    }),

    /**
     * The shop's creation date
     *
     * @attribute createdDate
     * @type Date
     */
    createdDate: attr('timestamp'),

    /**
     * The last date when the shop was modified
     *
     * @attribute lastModifiedDate
     * @type Date
     */
    lastModifiedDate: attr('timestamp'),

    /**
     * The full URL to the logos shop
     *
     * @attribute logo
     * @type Object
     */
    logo: attr('image'),

    /**
     * A shorthand for the 500x500.
     *
     * @property logoUrl
     * @type String
     * @readOnly
     */
    logoUrl: Ember.computed.alias('logo.s500x500'),

    /**
     * The URLs to the shop's header images
     *
     * @attribute header
     * @type Object
     */
    header: attr('image'),

    /**
     * A shorthand for the 1920x500.
     *
     * @property headerImageUrl
     * @type String
     * @readOnly
     */
    headerImageUrl: Ember.computed.alias('header.s1920x500'),

    /**
     * If roasted samples can be ordered from this shop.
     *
     * @attribute roastedSamplesAvailable
     * @type {Boolean}
     */
    roastedSamplesAvailable: attr('boolean', {
      defaultValue: false
    }),

    /**
     * If shop offers flat rate shipping enabling direct CC payments.
     *
     * @attribute flatRatePriced
     * @type {Boolean}
     */
    flatRatePriced: attr('boolean', {
      defaultValue: false
    }),

    /**
     * The introductory text from the top of the seller's policies page (may be blank).
     *
     * @attribute policyWelcome
     * @type String
     * @optional
     */
    policyWelcome: attr('string'),

    /**
     * The seller's policy on payment (may be blank).
     *
     * @attribute policyPayment
     * @type String
     * @optional
     */
    policyPayment: attr('string'),

    /**
     * The seller's policy on shipping (may be blank).
     *
     * @attribute policyShipping
     * @type String
     * @optional
     */
    policyShipping: attr('string'),

    /**
     * The seller's policy on refunds (may be blank).
     *
     * @attribute policyRefunds
     * @type String
     * @optional
     */
    policyRefunds: attr('string'),

    /**
     * Any additional policy information the seller provides (may be blank).
     *
     * @attribute policyAdditional
     * @type String
     * @optional
     */
    policyAdditional: attr('string'),

    /**
     * The seller's policy for samples (may be blank).
     *
     * @attribute policySample
     * @type String
     * @optional
     */
    policySample: attr('string'),

    /**
     * The seller's shipping settings deciding where
     * to ship coffee to. This collection can be left
     * empty indicating that worlwide shipping applies.
     *
     * @attribute shippingInfos
     * @type Array
     * @optional
     */
    shippingInfos: attr('array'),

    /**
     * The status of the shop. One of: DRAFT, ACTIVE, INACTIVE
     *
     * @attribute status
     * @type String
     * @default "DRAFT"
     */
    status: attr('string', {
      defaultValue: 'DRAFT'
    }),

    /**
     * If the shop has been archived (=soft deleted)
     *
     * @attribute archived
     * @type Boolean
     * @default false
     */
    archived: attr('boolean', {
      defaultValue: false
    }),

    /**
     * The headline of the story
     *
     * @attribute storyHeadline
     * @type String
     */
    storyHeadline: attr('string'),

    /**
     * The full story text (incl. the leading paragraph!)
     *
     * @attribute story
     * @type String
     */
    story: attr('string'),

    /**
     * The company who owns this shop.
     *
     * @attribute company
     * @type Model.Company
     */
    company: belongsTo('company', {
      async: true
    }),

    /**
     * Exchange rate used if the shop
     * is currently not configured in
     * USD to be used when calculating
     * dynamic (C-Market) prices,
     *
     * @attribute usdExchangeRate
     * @type Number
     */
    usdExchangeRate: attr('number', {
      defaultValue: 1
    }),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * An array of auctions within the shop.
     *
     * @attribute auctions
     * @type Model.Auction[]
     */
    auctions: Ember.computed('id', 'store', function () {
      return this.store.query('auction', {
        shop: this.get('id')
      });
    }),

    /**
     * All past auctions of this shop.
     *
     * @property pastAuctions
     * @type Model.Auction[]
     */
    pastAuctions: Ember.computed.filterBy('auctions', 'status', 'CLOSED'),

    /**
     * All upcoming auctions of this shop.
     *
     * @property upcomingAuctions
     * @type Model.Auction[]
     */
    upcomingAuctions: Ember.computed.filterBy('auctions', 'status', 'ACTIVE'),

    /**
     * All running auctions of this shop.
     *
     * @property runningAuctions
     * @type Model.Auction[]
     */
    runningAuctions: Ember.computed.filterBy('auctions', 'status', 'RUNNING'),

    /**
     * Returns true if shop is active.
     *
     * @property isActive
     * @type Boolean
     */
    isActive: Ember.computed.equal('status', 'ACTIVE'),

    /**
     * Returns true if the shop is editable
     *
     * @property isEditable
     * @type Boolean
     */
    isEditable: Ember.computed.not('archived'),

    /**
     * Returns true if the shop is fully editable. This means that the shop was never published before.
     *
     * @property isEditable
     * @type Boolean
     */
    // TODO: rename to was publish or something like that.
    isEditableAll: Ember.computed('status', 'archived', function () {
      return this.get('status') === 'DRAFT' && !this.get('archived');
    })
  });

  _exports.default = _default;
});