define("@vollersgroup/hub-frontend/components/user-search/component", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PromiseArray = _emberData.default.PromiseArray;
  /**
   * A form to search for users & companies by name.
   *
   * ```hbs
   * {{user-search selectUserAction="selectUser" selectCompanyAction="selectCompany"}}
   * ```
   *
   * @namespace Component
   * @class UserSearch
   * @extends Ember.Component
   */

  var _default = Ember.Component.extend({
    /**
     * @property store
     * @type Store
     * @protected
     */
    store: Ember.inject.service(),

    /**
     * @property ajax
     * @type Service.Ajax
     * @protected
     */
    ajax: Ember.inject.service(),

    /**
     * @property l10n
     * @type {Service.Ajax}
     * @protected
     */
    l10n: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * If it is possible to select companies.
     *
     * @attribute allowCompany
     * @type Boolean
     * @default true
     */
    allowCompany: true,

    /**
     * If this is false, do not allow selection of users.
     *
     * @attribute allowUser
     * @type Boolean
     * @default true
     */
    allowUser: true,

    /**
     * If the user search should be read-only. Works only if prefillCompany is set.
     *
     * @attribute readonly
     * @type Boolean
     * @default false
     */
    readonly: false,

    /**
     * Prefill the search with a company. Expects that allowCompany is true.
     *
     * @attribute prefillCompany
     * @type Model.Company
     * @optional
     */
    prefillCompany: null,

    /**
     * Prefill the search with a user. Expects that allowUser is true.
     *
     * @attribute prefillCompany
     * @type Model.Company
     * @optional
     */
    prefillUser: null,

    /**
     * The action to call when a user is selected.
     *
     * @event selectUserAction
     * @param {Model.User} user The selected user
     * @public
     */
    selectUserAction: null,

    /**
     * The action to call when a company is selected.
     *
     * @event selectCompanyAction
     * @param {Model.Company} company The selected company
     * @public
     */
    selectCompanyAction: null,
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Tries to select the first suggestion in list.
       *
       * @event selectFirst
       * @private
       */
      selectFirst: function selectFirst() {
        var results = this.get('results');
        var first = results.get('firstObject');

        if (first) {
          if (this.getAttr('allowCompany') !== false) {
            this.send('selectCompany', Ember.get(first, 'id'));
          }
        }
      },

      /**
       * This action is called whenever the search field changes.
       *
       * @event changeSearch
       * @param {String} val The search string
       * @private
       */
      changeSearch: function changeSearch() {
        Ember.run.debounce(this, this._updateSearch, 500);
      },

      /**
       * This action is called when a user is selected.
       *
       * @event selectUser
       * @param {String|Number} userId The ID of the selected user
       * @private
       */
      selectUser: function selectUser(userId) {
        var _this = this;

        this.set('isLoadingSelected', true);
        this.get('store').find('user', userId).then(function (user) {
          _this.set('selectedUser', user);

          _this.set('selectedCompany', null);

          _this.set('isLoadingSelected', false);

          if (_this.getAttr('selectUserAction')) {
            _this.sendAction('selectUserAction', user);
          }
        });
      },

      /**
       * This action is called when a company is selected.
       *
       * @event selectCompany
       * @param {String|Number} companyId The ID of the selected company
       * @private
       */
      selectCompany: function selectCompany(companyId) {
        var _this2 = this;

        this.set('isLoadingSelected', true);
        this.get('store').find('company', companyId).then(function (company) {
          _this2.set('selectedUser', null);

          _this2.set('isLoadingSelected', false);

          _this2.set('selectedCompany', company);

          if (_this2.get('selectCompanyAction')) {
            _this2.sendAction('selectCompanyAction', company);
          }
        });
      },

      /**
       * Clears the search field.
       *
       * @event clear
       * @private
       */
      clear: function clear() {
        this.set('selectedUser', null);
        this.set('selectedCompany', null);

        if (this.get('selectCompanyAction')) {
          this.sendAction('selectCompanyAction', null);
        }

        if (this.get('selectUserAction')) {
          this.sendAction('selectUserAction', null);
        }
      },

      /**
       * Blur the input and reset it.
       *
       * @event blurInput
       * @private
       */
      blurInput: function blurInput() {
        this.set('searchValue', '');
        this.send('changeSearch');
      }
    },
    // -------------------------------------------------------------------------
    // Properties

    /**
     * The currently selected user
     *
     * @property selectedUser
     * @type Model.User
     * @private
     */
    selectedUser: null,

    /**
     * The currently selected user
     *
     * @property selectedCompany
     * @type Model.Company
     * @private
     */
    selectedCompany: null,

    /**
     * The term that is searched.
     *
     * @property search
     * @type String
     * @private
     */
    search: '',

    /**
     * Value bound to input field.
     *
     * @property searchValue
     * @type String
     * @private
     */
    searchValue: '',

    /**
     * If the results are currently loading.
     */
    isLoading: Ember.computed.or('resultsUsers.isPending', 'resultsCompanies.isPending'),

    /**
     * If a selection is being loaded.
     *
     * @property isLoadingSelected
     * @type Boolean
     * @private
     * @default false
     */
    isLoadingSelected: false,

    /**
     * The placeholder for the input.
     *
     * @property placeholder
     * @type String
     * @private
     */
    placeholder: Ember.computed('allowCompany', 'allowUser', 'l10n', function () {
      var stringUserSearch = this.get('l10n').t('Search for user');
      var stringCompanySearch = this.get('l10n').t('Search for company');
      var stringCompanyUserSearch = this.get('l10n').t('Search for user or company');

      if (this.get('allowUser') && !this.get('allowCompany')) {
        return stringUserSearch;
      }

      if (!this.get('allowUser') && this.get('allowCompany')) {
        return stringCompanySearch;
      }

      return stringCompanyUserSearch;
    }),

    /**
     * The result set for the current search.
     *
     * @property resultsUsers
     * @type Array
     * @private
     */
    resultsUsers: Ember.computed('ajax', 'allowUser', 'search', function () {
      if (this.getAttr('allowUser') === false) {
        return [];
      }

      var query = this.get('search');

      if (!query) {
        return [];
      }

      var promise = this.get('ajax').request('/search/user', {
        data: {
          term: query
        }
      });
      return PromiseArray.create({
        promise: promise
      });
    }),

    /**
     * The result set for the current search.
     *
     * @property resultsCompanies
     * @type Array
     * @private
     */
    resultsCompanies: Ember.computed('ajax', 'allowCompany', 'search', function () {
      if (this.getAttr('allowCompany') === false) {
        return [];
      }

      var query = this.get('search');

      if (!query) {
        return [];
      }

      var promise = this.get('ajax').request('/search/company', {
        data: {
          term: query
        }
      });
      return PromiseArray.create({
        promise: promise
      });
    }),

    /**
     * The combined results from companies and users.
     *
     * @property results
     * @type Array
     * @private
     */
    results: Ember.computed('resultsUsers.[]', 'resultsCompanies.[]', function () {
      var users = this.get('resultsUsers') || [];
      var companies = this.get('resultsCompanies') || [];
      companies = companies.map(function (company) {
        return {
          id: company.id,
          label: company.name,
          users: []
        };
      });
      users.forEach(function (user) {
        var company = companies.find(function (company) {
          return company.id === user.company;
        });

        if (company) {
          company.users.push({
            id: user.id,
            label: user.name
          });
        } else {
          var _company = {
            id: user.company,
            label: user.companyName,
            isGenerated: true,
            users: [{
              id: user.id,
              label: user.name
            }]
          };
          companies.pushObject(_company);
        }
      });
      return companies;
    }),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Prefill the company.
     *
     * @method didReceiveAttrs
     * @public
     */
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.getAttr('prefillCompany')) {
        this.set('selectedCompany', this.getAttr('prefillCompany'));
      }

      if (this.getAttr('prefillUser')) {
        this.set('selectedUser', this.getAttr('prefillUser'));
      }
    },

    /**
     * Actually update the search query.
     *
     * @method _updateSearch
     * @private
     */
    _updateSearch: function _updateSearch() {
      if (this.get('isDestroyed')) {
        return;
      }

      this.set('search', this.get('searchValue'));
    }
  });

  _exports.default = _default;
});