define("@vollersgroup/hub-frontend/search-agent/serializer", ["exports", "@vollersgroup/hub-frontend/application/serializer"], function (_exports, _serializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _serializer.default.extend({
    normalize: function normalize(type, json, property) {
      if (json && json.listingFilter) {
        delete json.listingFilter.originalSearchResultId;
      }

      return this._super(type, json, property);
    }
  });

  _exports.default = _default;
});