define("@vollersgroup/hub-frontend/components/listing-offer-list-print/component", ["exports", "@vollersgroup/hub-frontend/listing/model", "@vollersgroup/hub-frontend/mixins/country", "@vollersgroup/hub-frontend/utils/union-array"], function (_exports, _model, _country, _unionArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // TODO: remove LABELS option from this code. It was refactored into it's own component.
  var LABELS = 'LABELS';
  var OFFER_LIST = 'OFFER_LIST';
  /**
   * Hub's printable sheet (offer list widget) as component.
   *
   * ```html
   * {{listing-offer-list-print listings=listings}}
   *  Optional Header
   * {{/listing-offer-list-print}}
   * ```
   *
   * @namespace Component
   * @class ListingPrint
   * @extends Ember.Component
   */

  var _default = Ember.Component.extend({
    localStorage: Ember.inject.service(),
    availableData: Ember.inject.service(),
    l10n: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * Listings to be represented in this printable sheet.
     *
     * @attribute listings
     * @type {Array}
     */
    listings: Ember.computed(function () {
      return [];
    }),

    /**
     * Current layout to be used. Can be one of 'OFFER_LIST'
     * or 'LABELS'.
     *
     * @attribute layoutMode
     * @type {String}
     */
    layoutMode: OFFER_LIST,

    /**
     * Per default settings from listing model options
     * are used, but if you want to provide a custom
     * set of selectable fields use this property
     * with an array of objects like, where `key`
     * is listing's property and `property` an
     * optional relational attribute:
     * [
     *  {
     *    selected: <Boolean>,
     *    property: <String>,
     *    label: <String>,
     *    key: <String>
     *  },
     *  ...
     * ]
     *
     * @attribute selectableFields
     * @type {Array}
     */
    selectableFields: Ember.computed(function () {
      return [];
    }),

    /**
     * Additional fields for layoutMode `OFFER_LIST`.
     * This is especially helpful to show information
     * contained in a relation from `listing` model,
     * f.e. `producer.location.region`.
     *
     * @attribute additionalOfferListFields
     * @type {Array}
     */
    additionalOfferListFields: Ember.computed('l10n', function () {
      return [{
        key: 'producer',
        selected: true,
        property: 'location.region',
        label: this.get('l10n').t('Region')
      }];
    }),

    /**
     * Additional fields for layoutMode `LABELS`.
     * This is especially helpful to show information
     * contained in a relation from `listing` model,
     * f.e. `producer.country`.
     *
     * @attribute additionalOfferListFields
     * @type {Array}
     */
    additionalLabelsFields: Ember.computed('l10n', function () {
      return [{
        key: 'producer',
        selected: true,
        property: 'location.country',
        label: this.get('l10n').t('Country')
      }];
    }),

    /**
     * If this is true, print sheet will group listings
     * by their origin country rather than listed plain.
     * Note: This only applies for `OFFER_LIST` mode!
     *
     * @attribute groupByCountry
     * @type {Boolean}
     */
    groupByCountry: true,

    /**
     * If this is true, print sheet will include the
     * company's logo at the outer left field of item.
     * Note: This only applies for `LABELS` mode!
     *
     * @attribute groupByCountry
     * @type {Boolean}
     */
    showCompanyLogo: true,

    /**
     * If this is true, listings will be repeated
     * depending on `repeatAmount` property.
     *
     * @attribute repeatItems
     * @type {Boolean}
     */
    repeatItems: false,

    /**
     * If this is true, listings will be repeated
     * depending on `repeatAmount` property.
     *
     * @attribute repeatAmount
     * @type {Number}
     */
    repeatAmount: 3,

    /**
     * Amount of fields to be determined if a warning
     * is shown for user above print button. This will
     * only apply in layout mode `OFFER_LIST`.
     *
     * @attribute warningFields
     * @type {Number}
     */
    warningFields: 8,
    // -------------------------------------------------------------------------
    // Properties
    //
    // LAYOUT
    //

    /**
     * Shorthand for detection of `OFFER_LIST` layout.
     *
     * @property isOfferList
     * @type {Boolean}
     * public
     */
    isOfferList: Ember.computed.equal('layoutMode', OFFER_LIST),

    /**
     * Shorthand for detection of `LABELS` layout.
     *
     * @property isLabels
     * @type {Boolean}
     * public
     */
    isLabels: Ember.computed.equal('layoutMode', LABELS),
    //
    // FIELDS
    //

    /**
     * Composition of selectable fields in dropdown which will be shown.
     *
     * @property baseFields
     * @type Array
     * @private
     */
    baseFields: Ember.computed('selectableFields', 'selectableFields.[]', function () {
      var _this = this;

      var baseFields = [];
      var attrs = Ember.get(_model.default, 'attributes');
      var relations = Ember.get(_model.default, 'relationshipsByName');
      var selectableFields = this.get('selectableFields');

      var iterator = function iterator(collection) {
        collection.forEach(function (item) {
          var defaultSelectable = Ember.get(item, 'options.printOptions.selectable');
          var defaultSelected = Ember.get(item, 'options.printOptions.selected');
          var defaultProperty = Ember.get(item, 'options.printOptions.property');
          var defaultLabel = Ember.get(item, 'options.label');
          var property = Ember.get(item, 'property');
          var selected = Ember.get(item, 'selected');
          var label = Ember.get(item, 'label');
          var key = Ember.get(item, 'key');
          key = key || Ember.get(item, 'name');
          var isSelectable = !Ember.isEmpty(selectableFields) ? selectableFields.findBy('key', key) : defaultSelectable;

          if (isSelectable) {
            var field = {
              property: property ? property : defaultProperty,
              selected: selected ? selected : defaultSelected,
              label: label ? label : defaultLabel,
              key: key
            };
            Ember.set(field, 'value', _this._getFieldPath(field));
            baseFields.push(field);
          }
        });
      };

      iterator(attrs);
      iterator(relations);
      return baseFields;
    }),

    /**
     * Provides corresponding fields depending on current `layoutMode`.
     * Returns `offerListFields` for layout mode `OFFER_LIST`.
     * Returns `labelsFields` for layout mode `LABELS`.
     *
     * @property currentFields
     * @type Array
     * @private
     */
    currentFields: Ember.computed('layoutMode', function () {
      return this._getForLayout({
        OFFER_LIST: 'offerListFields',
        LABELS: 'labelsFields',
        defaultValue: []
      });
    }),

    /**
     * Provides corresponding sort fields depending on current `layoutMode`.
     * Returns `sortedOfferListFields` for layout mode `OFFER_LIST`.
     * Returns `sortedLabelsFields` for layout mode `LABELS`.
     *
     * @property currentSortFields
     * @type Array
     * @private
     */
    currentSortFields: Ember.computed('sortedLabelsFields', 'sortedLabelsFields.[]', 'sortedOfferListFields', 'sortedOfferListFields.[]', 'sortedLabelsFields.@each.selected', 'sortedOfferListFields.@each.selected', function () {
      return this._getForLayout({
        OFFER_LIST: 'sortedOfferListFields',
        LABELS: 'sortedLabelsFields',
        defaultValue: []
      });
    }),

    /**
     * Holds a copy of fields composition for mode `OFFER_LIST`.
     * They are created by a combination of `baseFields` and
     * `additionalFields` for this layout mode and computed
     * only on one time during initialization.
     *
     * @property offerListFields
     * @type {Array}
     * @private
     */
    offerListFields: Ember.computed(function () {
      return [];
    }),

    /**
     * Holds a copy of fields composition for mode `LABELS`.
     * They are created by a combination of `baseFields` and
     * `additionalFields` for this layout mode and computed
     * only on one time during initialization.
     *
     * @property labelsFields
     * @type {Array}
     * @private
     */
    labelsFields: Ember.computed(function () {
      return [];
    }),

    /**
     * Current offer list selection of the visible fields,
     * which will be handed over to sorting component and
     * will be changed by dragging fields within UI.
     * NOTE: They get restored from local storage.
     *
     * @property selectedFields
     * @type Array
     * @private
     */
    selectedOfferListFields: Ember.computed('offerListFields', 'offerListFields.[]', 'offerListFields.@each.selected', {
      set: function set(key, val) {
        // for restoring fields
        return val;
      },
      get: function get() {
        var baseFields = this.get('offerListFields');
        return this._getSelectedFields(baseFields);
      }
    }),

    /**
     * Current labels selection of the visible fields,
     * which will be handed over to dragging component
     * and will be changed by dragging fields within UI.
     * NOTE: They get restored from local storage.
     *
     * @property selectedFields
     * @type Array
     * @private
     */
    selectedLabelsFields: Ember.computed('labelsFields', 'labelsFields.[]', 'labelsFields.@each.selected', {
      set: function set(key, val) {
        // for restoring fields
        return val;
      },
      get: function get() {
        var baseFields = this.get('labelsFields');
        return this._getSelectedFields(baseFields);
      }
    }),

    /**
     * Sorted label fields are generated based on current
     * `selectedLabelsFields` (= visible fields).
     *
     * @property sortedLabelsFields
     * @type Array
     * @private
     */
    sortedLabelsFields: Ember.computed('layoutMode', 'selectedLabelsFields', 'selectedLabelsFields.[]', 'selectedLabelsFields.@each.selected', function () {
      var baseFields = this.get('selectedLabelsFields');
      return this._getSortedFields(baseFields);
    }),

    /**
     * Sorted offer list fields are generated based on current
     * `selectedOfferListFields` (= visible fields).
     *
     * @property sortedOfferListFields
     * @type Array
     * @private
     */
    sortedOfferListFields: Ember.computed('layoutMode', 'selectedOfferListFields', 'selectedOfferListFields.[]', 'selectedOfferListFields.@each.selected', function () {
      var baseFields = this.get('selectedOfferListFields');
      return this._getSortedFields(baseFields);
    }),
    //
    // LISTINGS
    //

    /**
     * Composition of listings by country groups, which
     * only applies if `groupByCountry` is set to true.
     *
     * @property offerListListings
     * @type Array
     * @private
     */
    offerListListings: Ember.computed('groupByCountry', 'groupClass', 'sortedOfferListListings.[]', function () {
      var groups = {};
      var keysGroup = [];
      var sortedGroups = {};
      var Group = this.get('groupClass');
      var groupByCountry = this.get('groupByCountry');
      var listings = this.get('sortedOfferListListings');

      if (groupByCountry === false) {
        Ember.set(groups, '', Group.create({
          country: '',
          listings: listings
        }));
        return groups;
      }

      listings.forEach(function (listing) {
        var country = Ember.get(listing, 'country');

        if (Ember.isNone(country)) {
          return;
        }

        if (Ember.isNone(Ember.get(groups, country))) {
          Ember.set(groups, country, Group.create({
            country: country,
            listings: []
          }));
        }

        var listings = Ember.get(groups, "".concat(country, ".listings"));
        listings.pushObject(listing);
        keysGroup.addObject(country);
      });
      keysGroup.sort();
      keysGroup.forEach(function (key) {
        Ember.set(sortedGroups, key, Ember.get(groups, key));
      });
      return sortedGroups;
    }),

    /**
     * Simple alias for `listings` - no preprocessing needed.
     *
     * @property labelsListings
     * @type Array
     * @private
     */
    labelsListings: Ember.computed.alias('sortedLabelsListings'),

    /**
     * Sorted listings for `OFFER_LIST` mode to be consumed by `offerListListings`.
     *
     * @property sortedOfferListListings
     * @type Array
     * @private
     */
    sortedOfferListListings: Ember.computed.sort('baseListings', 'sortProperty'),

    /**
     * Sorted listings for `LABELS` mode to be consumed by `labelsListings`.
     *
     * @property sortedLabelsListings
     * @type Array
     * @private
     */
    sortedLabelsListings: Ember.computed.sort('baseListings', 'sortProperty'),
    //
    // SORTING
    //

    /**
     * Current sorting field for layout mode `OFFER_LIST`.
     *
     * @attribute sortByOfferList
     * @type {String}
     * @default 'name'
     */
    sortByOfferList: 'name',

    /**
     * Current sorting field for layout mode `LABELS`.
     *
     * @attribute sortByOfferList
     * @type {String}
     * @default 'name'
     */
    sortByLabels: 'name',

    /**
     * Provides corresponding sorting field for current `layoutMode`.
     * Returns `sortByOfferList` for layout mode `OFFER_LIST`.
     * Returns `sortByLabels` for layout mode `LABELS`.
     *
     * @attribute sortBy
     * @type {String}
     */
    sortBy: Ember.computed('layoutMode', 'sortByLabels', 'sortByOfferList', function () {
      return this._getForLayout({
        OFFER_LIST: 'sortByOfferList',
        LABELS: 'sortByLabels',
        defaultValue: 'name'
      });
    }),

    /**
     * Current sorting direction for layout mode `LABELS`.
     *
     * @attribute sortOrderLabels
     * @type {String}
     * @default 'asc'
     */
    sortOrderLabels: 'asc',

    /**
     * Current sorting direction for layout mode `OFFER_LIST`.
     *
     * @attribute sortOrderOfferList
     * @type {String}
     * @default 'asc'
     */
    sortOrderOfferList: 'asc',

    /**
     * Provides corresponding sorting order for current `layoutMode`.
     * Returns `sortOrderOfferList` for layout mode `OFFER_LIST`.
     * Returns `sortOrderLabels` for layout mode `LABELS`.
     *
     * @attribute sortOrder
     * @type {String}
     */
    sortOrder: Ember.computed('layoutMode', 'sortOrderLabels', 'sortOrderOfferList', function () {
      return this._getForLayout({
        OFFER_LIST: 'sortOrderOfferList',
        LABELS: 'sortOrderLabels',
        defaultValue: 'asc'
      });
    }),

    /**
     * Array holding possible sort order options for this component.
     *
     * @property baseSortOrders
     * @type {Array}
     * public
     */
    baseSortOrders: Ember.computed('l10n', function () {
      return [{
        value: 'asc',
        selected: true,
        label: this.get('l10n').t('Ascending')
      }, {
        value: 'desc',
        selected: false,
        label: this.get('l10n').t('Descending')
      }];
    }),

    /**
     * Provides corresponding sorting orders for current `layoutMode`.
     * Returns `sortOrdersOfferList` for layout mode `OFFER_LIST`.
     * Returns `sortOrdersLabels` for layout mode `LABELS`.
     *
     * @attribute currentSortOrders
     * @type {String}
     */
    currentSortOrders: Ember.computed('layoutMode', function () {
      return this._getForLayout({
        OFFER_LIST: 'sortOrdersOfferList',
        LABELS: 'sortOrdersLabels',
        defaultValue: []
      });
    }),

    /**
     * Holds a copy of sort orders  for mode `OFFER_LIST`.
     * They are computed only during initialization time.
     *
     * @property sortOrdersOfferList
     * @type {Array}
     * @private
     */
    sortOrdersOfferList: Ember.computed(function () {
      return [];
    }),

    /**
     * Holds a copy of sort orders  for mode `LABELS`.
     * They are computed only during initialization time.
     *
     * @property sortOrdersLabels
     * @type {Array}
     * @private
     */
    sortOrdersLabels: Ember.computed(function () {
      return [];
    }),

    /**
     * Computes sort property for computed.sort by combining `sortBy` and `sortOrder`.
     *
     * @attribute sortProperty
     * @type {Array}
     */
    sortProperty: Ember.computed('sortBy', 'sortOrder', function () {
      var sortKey = this.get('sortBy');
      var sortOrder = this.get('sortOrder');
      return ["".concat(sortKey, ":").concat(sortOrder)];
    }),
    //
    // MISC
    //

    /**
     * Base settings applying to both layout modes.
     *
     * @property baseSettings
     * @type {Array}
     * @private
     */
    baseSettings: Ember.computed('l10n', 'repeatItems', {
      get: function get()
      /*key*/
      {
        return [{
          value: 'repeatItems',
          selected: this.get('repeatItems'),
          label: this.get('l10n').t('Repeat items')
        }];
      }
    }),

    /**
     * Currents settings depending on `layoutMode`.
     *
     * @property currentSettings
     * @type {Array}
     * @private
     */
    currentSettings: Ember.computed('baseSettings', 'groupByCountry', 'l10n', 'layoutMode', 'showCompanyLogo', {
      get: function get()
      /*key*/
      {
        var baseSettings = Ember.get(this, 'baseSettings');
        var currentSettings = Ember.copy(baseSettings, true);

        switch (Ember.get(this, 'layoutMode')) {
          case OFFER_LIST:
            currentSettings.push({
              value: 'groupByCountry',
              selected: this.get('groupByCountry'),
              label: this.get('l10n').t('Group by country')
            });
            break;

          case LABELS:
            currentSettings.push({
              value: 'showCompanyLogo',
              selected: this.get('showCompanyLogo'),
              label: this.get('l10n').t('Show company logo')
            });
            break;

          default:
        }

        return currentSettings;
      }
    }),

    /**
      * @property listingShop
     * @type {Mixed}
     * @private
     */
    baseListings: Ember.computed('repeatItems', 'repeatAmount', 'listings.[]', function () {
      var repeatAmount = this.get('repeatAmount') || 1;
      var repeatItems = this.get('repeatItems');
      var listings = this.get('listings');

      if (!repeatItems) {
        return listings;
      }

      var baseListings = [];
      listings.forEach(function (listing) {
        for (var i = 0; i < repeatAmount; i++) {
          baseListings.push(listing);
        }
      });
      return baseListings;
    }),

    /**
     * Flag determining if a warning should be shown for user,
     * as there could be a problem printing so much fields.
     *
     * @property showWarning
     * @type {Boolean}
     * @private
     */
    showWarning: Ember.computed('layoutMode', 'warningFields', 'selectedLabelsFields.length', 'selectedOfferListFields.length', function () {
      var cur = this._getForLayout({
        OFFER_LIST: 'selectedOfferListFields.length',
        LABELS: 'selectedLabelsFields.length',
        defaultValue: 0
      });

      var max = this.get('warningFields');
      return cur > max;
    }),

    /**
     * Ember object extending country mixin, which will
     * be used to make composition of grouped listings.
     *
     * @property groupClass
     * @type {Object}
     * @private
     */
    groupClass: Ember.Object.extend(_country.default),

    /**
     * Key to be used to put offer list settings into store.
     *
     * @property storageKey
     * @type {String}
     * @private
     */
    storageKey: 'hub_listing_print_settings',
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Updates `layoutMode` and local storage with current settings.
       *
       * @event layoutModeSelectionChanged
       * @param {Object} mode
       * @private
       */
      settingsChanged: function settingsChanged(setting) {
        var val = Ember.get(setting, 'selected');
        var key = Ember.get(setting, 'value');
        Ember.set(this, key, val);

        this._persistSettings();
      },

      /**
       * Updates local storage with current settings.
       *
       * @event repeatAmountChanged
       * @param {Number} value
       * @private
       */
      repeatAmountChanged: function repeatAmountChanged()
      /*value*/
      {
        this._persistSettings();
      },

      /**
       * Updates `layoutMode` and local storage with current settings.
       *
       * @event layoutModeSelectionChanged
       * @param {Object} mode
       * @private
       */
      layoutModeSelectionChanged: function layoutModeSelectionChanged(layoutMode) {
        var value = Ember.get(layoutMode, 'value');
        this.set('layoutMode', value);

        this._persistSettings();
      },

      /**
       * Updates local storage with current settings.
       *
       * @event fieldSelectionChanged
       * @param {Object} field
       * @private
       */
      fieldSelectionChanged: function fieldSelectionChanged()
      /*field*/
      {
        this._persistSettings();
      },

      /**
       * Updates corresponding sort by properties depending on
       * current `layoutMode` and local storage with current
       * settings.
       *
       * @event sortBySelectionChanged
       * @param {Object} field
       * @private
       */
      sortBySelectionChanged: function sortBySelectionChanged(field) {
        var sortBy = this._getFieldPath(field);

        this._setForLayout({
          OFFER_LIST: ['sortByOfferList', sortBy],
          LABELS: ['sortByLabels', sortBy],
          defaultValue: 'name'
        });

        this._persistSettings();
      },

      /**
       * Updates corresponding sort by properties depending on
       * current `layoutMode` and local storage with current
       * settings.
       *
       * @event sortOrderSelectionChanged
       * @param {Object} field
       * @private
       */
      sortOrderSelectionChanged: function sortOrderSelectionChanged(field) {
        var sortOrder = Ember.get(field, 'value');

        this._setForLayout({
          OFFER_LIST: ['sortOrderOfferList', sortOrder],
          LABELS: ['sortOrderLabels', sortOrder],
          defaultValue: 'asc'
        });

        this._persistSettings();
      },

      /**
       * Updates local storage with current settings.
       *
       * @event sortingChanged
       * @param {Object} Drag event.
       * @private
       */
      sortingChanged: function sortingChanged()
      /*event*/
      {
        this._persistSettings();
      },

      /**
       * Invokes print dialog for sheet.
       *
       * @event printSheet
       * @private
       */
      printSheet: function printSheet() {
        window.print();
      }
    },
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Tries to fetch settings from previous export from storage.
     *
     * @public
     * @method init
     * @return {Void}
     */
    init: function init() {
      this._super.apply(this, arguments);

      this._setDefaultOptions();

      this._desistSettings();
    },

    /**
     * Retrieves full field path depending on `key` and `property`.
     *
     * @private
     * @method _getFieldPath
     * @return {String}
     */
    _getFieldPath: function _getFieldPath(field) {
      var property = Ember.get(field, 'property');
      var key = Ember.get(field, 'key');

      if (Ember.isEmpty(property)) {
        return key;
      }

      return "".concat(key, ".").concat(property);
    },

    /**
     * Primitive method to retrieve values depending on current
     * layout mode. The paramater is an object containing keys
     * for each layout holding component key and can specify
     * an optional default value if property was not found.
     *
     * @private
     * @method _getForLayout
     * @param {Object} values
     * @return {String}
     */
    _getForLayout: function _getForLayout() {
      var values = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var defaultValue = Ember.get(values, 'defaultValue') || null;
      var layoutMode = this.get('layoutMode');
      var key = Ember.get(values, layoutMode);

      if (Ember.isNone(key)) {
        return defaultValue;
      }

      var value = this.get(key);

      if (Ember.isNone(value)) {
        return defaultValue;
      }

      return value;
    },

    /**
     * Primitive method for retrieving selection fields. They will
     * be computed by filtering provided `baseFields` by `selected`
     * property.
     *
     * @private
     * @method _getSelectedFields
     * @param {Array} baseFields
     * @return {Array}
     */
    _getSelectedFields: function _getSelectedFields(baseFields) {
      return baseFields.filter(function (field) {
        return Ember.get(field, 'selected');
      });
    },

    /**
     * Primitive method for retrieving sorting fields. They will
     * be computed by a copy of provided `baseFields` and will be
     * activated by current `sortBy` if available, otherwise will
     * default to the first entry.
     *
     * @private
     * @method _getSortFields
     * @param {Array} baseFields
     * @return {Array}
     */
    _getSortedFields: function _getSortedFields(baseFields) {
      var _this2 = this;

      var sortBy = this.get('sortBy');
      var fields = Ember.copy(baseFields, true);
      var first = fields.get('firstObject'); // disable `selected` from all fields are
      // we are copying from selection fields!

      var current = first;
      fields.forEach(function (field) {
        var path = _this2._getFieldPath(field);

        if (path === sortBy) {
          current = field;
        }

        Ember.set(field, 'selected', false);
      });

      if (!Ember.isNone(current)) {
        // keep sorting in sync if changed due to
        // removal of sorting field in selection!
        var path = this._getFieldPath(current);

        if (path !== sortBy) {
          Ember.run.once(this, this.send, 'sortBySelectionChanged', current);
        } // activate correct current selection


        Ember.set(current, 'selected', true);
      }

      return fields;
    },

    /**
     * Primitive method to apply values depending on current
     * layout mode. The paramater is an object containing keys
     * for each layout holding component key and can specify
     * an optional default value if property was not found.
     *
     * @private
     * @method _setForLayout
     * @param {Object} values
     * @return {String}
     */
    _setForLayout: function _setForLayout() {
      var values = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var defaultValue = Ember.get(values, 'defaultValue') || null;
      var layoutMode = this.get('layoutMode');
      var data = Ember.get(values, layoutMode);

      if (!Ember.isArray(data) || Ember.isEmpty(data)) {
        return;
      }

      var key = data[0];

      if (Ember.isNone(this.get(key))) {
        return;
      }

      var value = data[1];

      if (Ember.isNone(value)) {
        value = defaultValue;
      }

      this.set(key, value);
    },

    /**
     * Saves default fields at initialization time, which are composition of `baseFields` and
     * custom `additionalFields` depending on `layoutMode`. Besides, it also creates copies
     * for `sortOrders`, which are actually static options, but need to be layout unique.
     *
     * @private
     * @method _setdefaultFields
     * @return {Void}
     */
    _setDefaultOptions: function _setDefaultOptions() {
      // `offerListFields` and `labelsFields`
      var additionalOfferListFields = this.get('additionalOfferListFields') || [];
      var additionalLabelsFields = this.get('additionalLabelsFields') || [];
      var baseFields = this.get('baseFields');
      var offerListFields = Ember.copy(baseFields, true);
      var labelsFields = Ember.copy(baseFields, true);
      this.set('offerListFields', offerListFields.concat(additionalOfferListFields));
      this.set('labelsFields', labelsFields.concat(additionalLabelsFields)); // `sortOrdersOfferList` and `sortOrdersLabels`

      var baseSortOrders = this.get('baseSortOrders');
      this.set('sortOrdersOfferList', Ember.copy(baseSortOrders, true));
      this.set('sortOrdersLabels', Ember.copy(baseSortOrders, true));
    },

    /**
     * Removes current settings from storage.
     *
     * @private
     * @method _deleteSettings
     * @return {Void}
     */
    _deleteSettings: function _deleteSettings() {
      var storageKey = this.get('storageKey');
      var localStorage = this.get('localStorage');
      localStorage.removeItem(storageKey);
    },

    /**
     * Persists settings in store for returning users.
     *
     * @private
     * @method _persistSettings
     * @return {Void}
     */
    _persistSettings: function _persistSettings() {
      var storageKey = this.get('storageKey');
      var localStorage = this.get('localStorage');
      localStorage.setItem(storageKey, {
        selectedOfferListFields: this.get('selectedOfferListFields'),
        selectedLabelsFields: this.get('selectedLabelsFields'),
        showCompanyLogo: this.get('showCompanyLogo'),
        groupByCountry: this.get('groupByCountry'),
        repeatAmount: this.get('repeatAmount'),
        repeatItems: this.get('repeatItems'),
        sortOfferList: {
          sortOrder: this.get('sortOrderOfferList'),
          sortBy: this.get('sortByOfferList')
        },
        sortLabels: {
          sortOrder: this.get('sortOrderLabels'),
          sortBy: this.get('sortByLabels')
        }
      });
    },

    /**
     * Desists settings from store for returning users.
     *
     * @private
     * @method _desistSettings
     * @return {Void}
     */
    _desistSettings: function _desistSettings() {
      var _this3 = this;

      var storageKey = this.get('storageKey');
      var localStorage = this.get('localStorage');

      var updateRadioOptions = function updateRadioOptions(options, value) {
        options.forEach(function (option) {
          var current = Ember.get(option, 'value');
          Ember.set(option, 'selected', current === value);
        });
      }; // try to fetch settings from local storage by key


      var settings = localStorage.getItem(storageKey);

      if (Ember.isNone(settings)) {
        return;
      } // try to reset `groupByCountry` setting from storage


      var groupByCountry = Ember.get(settings, 'groupByCountry');

      if (!Ember.isNone(groupByCountry)) {
        this.set('groupByCountry', groupByCountry);
      } // try to reset `showCompanyLogo` setting from storage


      var showCompanyLogo = Ember.get(settings, 'showCompanyLogo');

      if (!Ember.isNone(showCompanyLogo)) {
        this.set('showCompanyLogo', showCompanyLogo);
      } // try to reset `repeatItems` setting from storage


      var repeatItems = Ember.get(settings, 'repeatItems');

      if (!Ember.isNone(repeatItems)) {
        this.set('repeatItems', repeatItems);
      } // try to reset `repeatAmount` setting from storage


      var repeatAmount = Ember.get(settings, 'repeatAmount');

      if (!Ember.isNone(repeatAmount)) {
        this.set('repeatAmount', repeatAmount);
      } // try to reset `sortBy` and `sortOrder` for `OFFER_LIST`


      var sortOfferList = Ember.get(settings, 'sortOfferList');

      if (!Ember.isNone(sortOfferList)) {
        var sortByOfferList = Ember.get(sortOfferList, 'sortBy');
        var sortOrderOfferList = Ember.get(sortOfferList, 'sortOrder');

        if (!Ember.isNone(sortByOfferList)) {
          // auto selection is made by computed property
          this.set('sortByOfferList', sortByOfferList);
        }

        if (!Ember.isNone(sortOrderOfferList)) {
          this.set('sortOrderOfferList', sortOrderOfferList); // update current options to reset current selection

          var sortOrdersOfferList = this.get('sortOrdersOfferList');
          updateRadioOptions(sortOrdersOfferList, sortOrderOfferList);
        }
      } // try to reset `sortBy` and `sortOrder` for 'LABELS'


      var sortLabels = Ember.get(settings, 'sortLabels');

      if (!Ember.isNone(sortLabels)) {
        var sortByLabels = Ember.get(sortLabels, 'sortBy');
        var sortOrderLabels = Ember.get(sortLabels, 'sortOrder');

        if (!Ember.isNone(sortByLabels)) {
          // auto selection is made by computed property
          this.set('sortByLabels', sortByLabels);
        }

        if (!Ember.isNone(sortOrderLabels)) {
          this.set('sortOrderLabels', sortOrderLabels); // update current options to reset current selection

          var sortOrdersLabels = this.get('sortOrdersLabels');
          updateRadioOptions(sortOrdersLabels, sortOrderLabels);
        }
      } // comparator evaluates if two array items are considered equal
      // which's the case if `key` plus optional `property` are equal


      var comparator = function comparator(a, b) {
        var keyA = Ember.isEmpty(Ember.get(a, 'property')) ? Ember.get(a, 'key') : "".concat(Ember.get(a, 'key'), ".").concat(Ember.get(a, 'property'));
        var keyB = Ember.isEmpty(Ember.get(b, 'property')) ? Ember.get(b, 'key') : "".concat(Ember.get(b, 'key'), ".").concat(Ember.get(b, 'property'));
        return keyA === keyB;
      }; // helper method to restore selected fields from storage


      var restoreFields = function restoreFields(properteyKey, selectedKey) {
        // grab selected fields from storage and override
        // `selected` property of current field collection
        var selected = Ember.get(settings, selectedKey);

        var fields = _this3.get(properteyKey);

        if (!Ember.isArray(selected)) {
          return;
        } // important: set base fields to false as we override their
        // settings by merging with selected field set from storage


        fields.setEach('selected', false); // union array fields mixin selection to field collection

        var restored = (0, _unionArray.default)(fields, selected, comparator); // then set appropriate fields and selected ones

        _this3.set(properteyKey, restored);

        _this3.set(selectedKey, selected);
      }; // try to reset `offerListFields` setting from storage


      restoreFields('offerListFields', 'selectedOfferListFields'); // try to reset `labelsFields` setting from storage

      restoreFields('labelsFields', 'selectedLabelsFields');
    }
  });

  _exports.default = _default;
});