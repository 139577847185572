define("@vollersgroup/hub-frontend/mixins/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SearchControllerMixin = _exports.SearchRouteMixin = _exports.SearchParameterMixin = void 0;

  /**
   * Mixin providing default search parameter settings.
   *
   * @module
   * @augments ember/mixin
   */
  var SearchParameterMixin = Ember.Mixin.create({
    /**
     * Current page.
     *
     * @property page
     * @public
     * @type Number
     * @default 1
     */
    page: 1,

    /**
     * Items per page.
     *
     * @property pageSize
     * @public
     * @type Number
     * @default 10
     */
    pageSize: 10,

    /**
     * Ordering type. Can be one of
     * "DEFAULT", "DATE" or "SCORE".
     *
     * @property orderBy
     * @public
     * @type String
     * @default "DEFAULT"
     */
    orderBy: 'DEFAULT',

    /**
     * Encoded version of filter.
     *
     * @property filter
     * @public
     * @type String
     * @default ""
     */
    filter: '',

    /**
     * Query for search bar.
     *
     * @property searchText
     * @public
     * @type String
     */
    searchText: '',

    /**
     * Lazy initialization of search agent.
     *
     * @property lazyInit
     * @public
     * @type Boolean
     */
    lazyInit: null,

    /**
     * Preselected search agent.
     *
     * @property searchAgent
     * @public
     * @type String
     */
    searchAgent: null,

    /**
     * Flag for search agent modal.
     *
     * @property searchAgentModal
     * @public
     * @type String
     */
    searchAgentModal: null
  });
  /**
   * Mixin for routes showing searchable listings.
   *
   * @module
   * @augments ember/mixin
   */

  _exports.SearchParameterMixin = SearchParameterMixin;
  var SearchRouteMixin = Ember.Mixin.create({
    // -------------------------------------------------------------------------
    // Dependencies
    userSession: Ember.inject.service(),
    localStorage: Ember.inject.service(),
    headTagsService: Ember.inject.service('head-tags'),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Query parameters.
     *
     * @final
     * @public
     * @type {Object}
     */
    queryParams: {
      page: {
        as: 'p'
      },
      filter: {
        as: 'f'
      },
      lazyInit: {
        as: 'l'
      },
      orderBy: {
        as: 'o'
      },
      pageSize: {
        as: 's'
      },
      searchText: {
        as: 'q'
      },
      searchAgent: {
        as: 'a'
      },
      searchAgentModal: {
        as: 'm'
      }
    },

    /**
     * Current search instance for this route.
     * NOTE: Search is NOT a service, cause each
     * environment uses it's own instance, thus
     * we are able to avoid reset necessity!
     *
     * @public
     * @type {Object}
     */
    search: Ember.computed({
      get: function get() {
        return Ember.getOwner(this).lookup('service:search', {
          singleton: false
        });
      }
    }),

    /**
     * Key to be used to put offer list settings into store.
     *
     * @property storageKey
     * @type {String}
     * @private
     */
    storageKey: 'hub_search_page_size',

    /**
     *
     *
     * @property defaultPageSize
     * @type {Integer}
     * @private
     */
    storageVal: Ember.computed('localStorage', 'storageKey', {
      get: function get() {
        var storage = Ember.get(this, 'localStorage');
        var key = Ember.get(this, 'storageKey');
        return storage.getItem(key);
      }
    }),
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Fired whenever a query parameter's changed. Forwards all query
       * parameters to search service after controller bindings are done.
       *
       * @event queryParamsDidChange
       * @return {Boolean} Bubbles event to parent route.
       */
      queryParamsDidChange: function queryParamsDidChange() {
        Ember.run.scheduleOnce('routerTransitions', this, this._syncToSearch);
        return true;
      }
    },
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Sets up event listeners for search.
     *
     * @public
     * @method activate
     * @return {Void}
     */
    activate: function activate() {
      this._super.apply(this, arguments);

      var search = Ember.get(this, 'search'); // add search listeners

      search.on('search_agent_redirect', this, this._redirectSearchAgent);
      search.on('search_agent_changed', this, this._syncFromSearch);
      search.on('properties_changed', this, this._syncFromSearch);
      search.on('filters_loaded', this, this._resetScrollBar);
      search.on('listings_loaded', this, this._syncHeadTags);
    },

    /**
     * Tears event listeners down.
     *
     * @public
     * @method deactivate
     * @return {Void}
     */
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      var search = Ember.get(this, 'search'); // remove search listeners

      search.off('search_agent_redirect', this, this._redirectSearchAgent);
      search.off('search_agent_changed', this, this._syncFromSearch);
      search.off('properties_changed', this, this._syncFromSearch);
      search.off('filters_loaded', this, this._resetScrollBar);
      search.off('listings_loaded', this, this._syncHeadTags);
    },

    /**
     * Publishes search instance on controller
     * for forwarding to contained components
     * and synchronizes from initial params.
     *
     * @public
     * @method setupController
     * @return {Void}
     */
    setupController: function setupController(controller) {
      this._super.apply(this, arguments); // publish search on controller / set defaults


      var defaults = Ember.get(controller, 'defaults');
      var search = Ember.get(this, 'search');
      Ember.set(controller, 'search', search);
      Ember.set(search, 'defaults', defaults); // initalize page size

      this._restorePageSize(); // sync initial params


      this._syncToSearch(); // check search agents


      this._initSearchAgent(); // finally enable search


      search.enable();
    },

    /**
     * Resets controller defaults on existing to
     * make query parameters working as expected.
     *
     * @public
     * @method resetController
     * @return {Void}
     */
    resetController: function resetController(controller, isExisting, _transition) {
      if (!Ember.isNone(isExisting)) {
        var search = Ember.get(controller, 'search');
        var defaults = Ember.get(controller, 'defaults');
        Ember.run.scheduleOnce('destroy', this, function () {
          controller.setProperties(defaults);
          search.disable();
        });
      }
    },

    /**
     * Tries to init page size from local storage.
     *
     * @private
     * @method _initPageSize
     * @return {Void}
     */
    _restorePageSize: function _restorePageSize() {
      var defaultVal = Ember.get(this, 'controller.defaults.pageSize');
      var currentVal = Ember.get(this, 'controller.pageSize');
      var storedVal = Ember.get(this, 'storageVal.pageSize');

      if (currentVal === defaultVal && storedVal) {
        Ember.set(this, 'controller.pageSize', storedVal);
      }
    },

    /**
     * Delivers correct values depending on current state.
     *
     * @private
     * @method _getQueryParameters
     * @return {Void}
     */
    _getQueryParameters: function _getQueryParameters() {
      var controller = this.controllerFor(Ember.get(this, 'routeName'));
      var params = this.paramsFor(Ember.get(this, 'routeName')); // controller has not been initialized,
      // so query parameters are not set yet

      if (Ember.isNone(this.controller)) {
        return params;
      }

      return controller;
    },

    /**
     * Synchronizes controller properties with changed properties
     * from search service through the event loop whenever changes
     * has been made internally. Please see activate() for events.
     *
     * @private
     * @method _syncFromSearch
     * @return {Void}
     */
    _syncFromSearch: function _syncFromSearch() {
      this.controllerFor(Ember.get(this, 'routeName')).setProperties({
        searchAgent: Ember.get(this, 'search.searchAgent'),
        searchText: Ember.get(this, 'search.searchText'),
        pageSize: Ember.get(this, 'search.pageSize'),
        orderBy: Ember.get(this, 'search.orderBy'),
        filter: Ember.get(this, 'search.filter'),
        page: Ember.get(this, 'search.page')
      });
    },

    /**
     * Syncs controller properties (query params) to search service
     * on changes and after initialization. This should be called
     * after transitions, so has to be invoked in `didTransition`.
     *
     * @method _syncToSearch
     * @private
     * @return {Void}
     */
    _syncToSearch: function _syncToSearch() {
      var controller = this.controllerFor(Ember.get(this, 'routeName'));

      var queryParams = this._getQueryParameters();

      var pageSize = Ember.get(queryParams, 'pageSize');
      var storage = Ember.get(this, 'localStorage');
      var key = Ember.get(this, 'storageKey');
      storage.setItem(key, {
        pageSize: pageSize
      });

      if (Ember.get(queryParams, 'lazyInit')) {
        var lazyInit = Ember.get(controller, 'defaults.lazyInit');
        controller.set('lazyInit', lazyInit);

        this._initSearchAgent();
      }

      Ember.get(this, 'search').setProperties({
        searchAgent: Ember.get(queryParams, 'searchAgent'),
        searchText: Ember.get(queryParams, 'searchText'),
        pageSize: Ember.get(queryParams, 'pageSize'),
        orderBy: Ember.get(queryParams, 'orderBy'),
        filter: Ember.get(queryParams, 'filter'),
        page: Ember.get(queryParams, 'page')
      });
    },

    /**
     * Brings user back to top after loading has finished.
     *
     * @method _resetScrollBar
     * @private
     * @return {Void}
     */
    _resetScrollBar: function _resetScrollBar() {
      Ember.$('html, body').animate({
        scrollTop: 0
      });
    },

    /**
     * Checks initial search agent from controller.
     *
     * @method _initSearchAgent
     * @private
     * @return {Void}
     */
    _initSearchAgent: function _initSearchAgent() {
      var _this = this;

      var queryParams = this._getQueryParameters();

      var searchAgentId = Ember.get(queryParams, 'searchAgent');

      if (Ember.isNone(searchAgentId)) {
        return;
      }

      var currentUser = Ember.get(this, 'userSession.currentUser');

      if (Ember.isNone(currentUser)) {
        Ember.run.scheduleOnce('routerTransitions', this, function () {
          _this.transitionTo({
            queryParams: {
              showLogin: 1,
              afterLogin: "l++1"
            }
          });
        });
        return;
      }

      var search = Ember.get(this, 'search');

      var _successCallback = function _successCallback(_searchAgents) {
        var store = Ember.get(_this, 'store');
        var searchAgent = store.peekRecord('search-agent', searchAgentId);
        search.setSearchAgent(searchAgent);
      };

      var _failureCallback = function _failureCallback() {
        search.reset();
      };

      var searchAgents = Ember.get(this, 'userSession.searchAgents');
      searchAgents.then(_successCallback, _failureCallback);
    },

    /**
     * Redirects search agent according to `shop` and `auction`.
     *
     * @method _redirectSearchAgent
     * @private
     * @return {Void}
     */
    _redirectSearchAgent: function _redirectSearchAgent(searchAgent) {
      var options = {
        queryParams: {
          a: searchAgent.id
        }
      }; // a) redirect if `auction` differs

      var searchAuction = Ember.get(this, 'search.auction') || null;
      var agentAuction = Ember.get(searchAgent, 'listingFilter.auction');

      if (searchAuction != agentAuction) {
        if (agentAuction) {
          this.transitionTo('store.auctions.show', agentAuction, options);
        } else {
          this.transitionTo('store', options);
        }

        return;
      } // b) redirect if `shop` differs


      var searchShop = Ember.get(this, 'search.shop') || null;
      var agentShop = Ember.get(searchAgent, 'listingFilter.shop');

      if (searchShop != agentShop) {
        if (agentShop) {
          this.transitionTo('store.shop', agentShop, options);
        } else {
          this.transitionTo('store', options);
        }

        return;
      } // c) noop

    },

    /**
     * Sets "prev" and "next" meta tags as recommended by Google. Please
     * note that this can only be done  after listings has been fetched
     * with updated meta information.
     *
     * https://support.google.com/webmasters/answer/1663744?hl=en&ref_topic=4617741
     *
     * @event "listings_loaded"
     * @method _syncHeadTags
     * @private
     */
    _syncHeadTags: function _syncHeadTags() {
      var headTags = [];
      var location = window.location;
      var router = Ember.get(this, 'router');

      if (!router) {
        return;
      }

      var page = Ember.get(this, 'search.page');
      var maxPages = Ember.get(this, 'search.maxPages');
      var routePath = router.generate(Ember.get(this, 'routeName'));
      var baseUrl = "".concat(location.protocol, "//").concat(location.host).concat(routePath);

      if (page > 1) {
        headTags.push({
          type: 'link',
          tagId: 'prev',
          attrs: {
            rel: 'prev',
            href: "".concat(baseUrl, "?p=").concat(page - 1)
          }
        });
      }

      if (page < maxPages) {
        headTags.push({
          type: 'link',
          tagId: 'next',
          attrs: {
            rel: 'next',
            href: "".concat(baseUrl, "?p=").concat(page + 1)
          }
        });
      }

      Ember.set(this, 'headTags', headTags);
      Ember.get(this, 'headTagsService').collectHeadTags();
    }
  });
  /**
   * Mixin for routes showing searchable listings.
   *
   * @module
   * @augments ember/mixin
   */

  _exports.SearchRouteMixin = SearchRouteMixin;
  var SearchControllerMixin = Ember.Mixin.create(SearchParameterMixin, {
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Query parameters.
     *
     * @final
     * @public
     * @type {Object}
     */
    queryParams: {
      page: 'p',
      filter: 'f',
      orderBy: 'o',
      lazyInit: 'l',
      pageSize: 's',
      searchText: 'q',
      searchAgent: 'a',
      searchAgentModal: 'm'
    },

    /**
     * Hash storing defaults.
     *
     * @public
     * @type {Object}
     */
    defaults: {},
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Copies default values to
     * hash for search instance.
     *
     * @public
     * @method init
     * @return {Void}
     */
    init: function init() {
      this._super.apply(this, arguments);

      var defaults = Ember.getProperties(this, ['searchAgentModal', 'searchAgent', 'searchText', 'pageSize', 'lazyInit', 'orderBy', 'filter', 'page']);
      Ember.set(this, 'defaults', defaults);
    }
  });
  _exports.SearchControllerMixin = SearchControllerMixin;
});