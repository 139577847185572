define("@vollersgroup/hub-frontend/helpers/window-size-lt", ["exports", "@vollersgroup/ember-cropster-common/helpers/window-size-lt"], function (_exports, _windowSizeLt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _windowSizeLt.default;
    }
  });
  Object.defineProperty(_exports, "windowSizeLt", {
    enumerable: true,
    get: function get() {
      return _windowSizeLt.windowSizeLt;
    }
  });
});