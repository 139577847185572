define("@vollersgroup/hub-frontend/store/listing/route", ["exports", "@vollersgroup/hub-frontend/mixins/reset-scroll"], function (_exports, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_resetScroll.default, {
    shoppingCart: Ember.inject.service(),
    userSession: Ember.inject.service(),
    keenTracking: Ember.inject.service(),
    toast: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord('listing', params.listing_id, {
        reload: true
      });
    },
    _addToWishlist: function _addToWishlist(_sender) {
      var controller = this.controllerFor(this.routeName); // ignore the initial page with `?addToWishlist=1`

      var model = this.modelFor(this.routeName);

      if (!model) {
        return;
      } // only add to wishlist if it's not already added


      var key = 'userSession.currentUser.wishlistListings';
      var wishlistListing = Ember.get(this, "".concat(key, ".").concat(model.id));

      if (!wishlistListing && !Ember.get(model, 'isOwner')) {
        controller.send('addToWishlist');
      } // reset query parameter afterwards


      Ember.set(controller, 'addToWishlist', 0);
    },
    actions: {
      queryParamsDidChange: function queryParamsDidChange(changedParams) {
        var addToWishlist = changedParams.addToWishlist;

        if (addToWishlist) {
          // note: wait for wishlist items before invoking call
          var currentUser = Ember.get(this, 'userSession.currentUser');
          currentUser && Ember.get(currentUser, 'wishlistItems').then(this._addToWishlist.bind(this));
        }
      },

      /**
       * Tracks a `listingView` event for Keen.io service.
       *
       * @event didTransition
       * @return {Void}
       * @private
       */
      didTransition: function didTransition() {
        var model = this.modelFor(this.get('routeName'));

        if (!model) {
          return;
        }

        var listingJSON = model.toJSON();
        listingJSON.id = model.get('id');
        delete listingJSON.description;
        this.get('keenTracking').addEvent('listingView', {
          viewType: 'DETAILS',
          listing: listingJSON
        });
      }
    }
  });

  _exports.default = _default;
});