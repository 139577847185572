define("@vollersgroup/hub-frontend/mixins/fix-qp-array-serializer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
   * Serializing array to query param is broken in Ember. This mixin
   * is (hopefully) temporary workaround for this issue.
   *
   * It's not used globally as I don't want to alter behaviour
   * everywhere without proper testing.
   *
   * TODO: Keep an eye on this, we now test it within our tests
   * but this could have problems in the future as we're overriding
   * private methods on the route
   *
   * GitHub issue: https://github.com/emberjs/ember.js/issues/14174
   * JIRA ticket: https://cropster.atlassian.net/browse/CHF-489
   */
  var _default = Ember.Mixin.create({
    serializeQueryParam: function serializeQueryParam(value, urlKey, defaultValueType) {
      if (defaultValueType === 'array' && isBasicArray(value)) {
        return value.slice();
      }

      return this._super(value, urlKey, defaultValueType);
    },
    deserializeQueryParam: function deserializeQueryParam(value, urlKey, defaultValueType) {
      if (defaultValueType === 'array' && isBasicArray(value)) {
        return value.slice();
      }

      return this._super(value, urlKey, defaultValueType);
    }
  });

  _exports.default = _default;

  function isBasicArray(array) {
    if (Ember.typeOf(array) !== 'array') {
      return false;
    }

    return array.every(function (value) {
      return ['string', 'number'].includes(Ember.typeOf(value));
    });
  }
});