define("@vollersgroup/hub-frontend/checkout/payment/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    checkout: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var checkout = this.get('checkout');
      var checkoutSteps = checkout.get('steps');

      if (checkoutSteps.get('length') < 4 || !Ember.get(checkoutSteps[1], 'isDone')) {
        this.transitionTo(Ember.get(checkoutSteps[1], 'route'));
        return;
      }

      this.get('checkout').set('currentStep', 2);
    },
    _processCreditCard: function _processCreditCard(_card) {},
    actions: {
      resetPaymentMethod: function resetPaymentMethod() {
        var checkout = this.get('checkout');
        checkout.set('cardEnding', null);
        checkout.set('cardToken', null);
        checkout.set('cardType', null);
        Ember.set(checkout.get('steps')[2], 'isDone', false);
      },
      updateCreditCard: function updateCreditCard(card) {
        var checkout = this.get('checkout');

        if (card) {
          var cardNumber = Ember.get(card, 'number');
          var cardType = Ember.get(card, 'type');
          checkout.set('cardEnding', cardNumber.substring(cardNumber.length - 4));
          checkout.set('cardType', cardType);

          this._processCreditCard(card);
        } else {
          checkout.set('cardEnding', null);
          checkout.set('cardToken', null);
          checkout.set('cardType', null);
        }
      },
      completeStep: function completeStep() {
        var checkout = this.get('checkout');
        var flashMessages = this.get('flashMessages');
        flashMessages.clear();

        if (!checkout.get('cardToken')) {
          flashMessages.addError(this.get('l10n').t('You have to complete your payment information. Please enter a valid credit card.'));
          return;
        }

        var gotoRoute = checkout.completeStep(2);

        if (gotoRoute) {
          this.transitionTo(gotoRoute);
        }
      }
    }
  });

  _exports.default = _default;
});