define("@vollersgroup/hub-frontend/add-to-wishlist/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    userSession: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        ratingId: params.rating_id,
        newUser: this.store.createRecord('user'),
        newWishlistItem: this.store.createRecord('wishlist-item'),
        wishlistItems: Ember.get(this, 'userSession.currentUser.wishlistItems')
      });
    },
    deactivate: function deactivate() {
      var _this$currentModel = this.currentModel,
          newUser = _this$currentModel.newUser,
          newWishlistItem = _this$currentModel.newWishlistItem;
      newWishlistItem.rollbackAttributes();
      newUser.rollbackAttributes();
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      if (Ember.get(this, 'userSession.currentUser')) {
        controller.addToWishlist();
      }
    }
  });

  _exports.default = _default;
});