define("@vollersgroup/hub-frontend/gql/hub-backend/region.graphql", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var doc = {
    "kind": "Document",
    "definitions": [{
      "kind": "EnumTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "Region"
      },
      "directives": [],
      "values": [{
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Europe",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "EUROPE"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Asia",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "ASIA"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Australia/Oceania",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "OCEANIA"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Central America",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "CENTRAL_AMERICA"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "North America",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "NORTH_AMERICA"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "South America",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "SOUTH_AMERICA"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Africa",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "AFRICA"
        },
        "directives": []
      }]
    }],
    "loc": {
      "start": 0,
      "end": 286
    }
  };
  var _default = doc;
  _exports.default = _default;
});