define("@vollersgroup/hub-frontend/components/filter-item-value/component", ["exports", "@vollersgroup/hub-frontend/components/filter-item/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A filter item handling filter type "VALUE" with selects except for
   * `format` filter, which will be represented with radio buttons.
   *
   * ```html
   * {{filter-item-value filter=filter update=(action "{YOUR_ACTION}")}}
   * ```
   *
   * @namespace Component
   * @class FilterItemValue
   * @extends Component.FilterItem
   */
  var _default = _component.default.extend({
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Provides UI component type based on filter name.
     * An exception is made for format type by showing
     * radiobutton, all others will use a select.
     *
     * @property subComponent
     * @type {String}
     */
    subComponent: Ember.computed('filter.parameterName', function () {
      switch (Ember.get(this, 'filter.parameterName')) {
        case 'format':
          return 'c-radio-buttons';

        default:
          return 'c-select';
      }
    }),

    /**
     * Finds selected item from sub component by `active`.
     *
     * @property selectedItem
     * @type {Object}
     */
    selectedItem: Ember.computed('filter.items.@each.active', function () {
      var items = Ember.get(this, 'filter.items');
      return items.findBy('active');
    }),

    /**
     * Retrieves `id` of selected item for sub components.
     *
     * @property selectedValue
     * @type {String}
     */
    selectedValue: Ember.computed('selectedItem.id', function () {
      return Ember.get(this, 'selectedItem.id');
    }),
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Propagates item to parent action
       * by calling `update` closure method.
       *
       * @event update
       * @param {String} id Selected id property.
       * @private
       */
      update: function update(id) {
        var selected = Ember.get(this, 'selectedItem');
        var items = Ember.get(this, 'filter.items');
        var item = items.findBy('id', id);

        if (!Ember.isNone(selected)) {
          Ember.set(selected, 'active', false);

          try {
            this.attrs.update(selected);
          } catch (e) {
            /* no action */
          }
        }

        if (!Ember.isNone(item)) {
          Ember.set(item, 'active', true);

          try {
            this.attrs.update(item);
          } catch (e) {
            /* no action */
          }
        }
      }
    }
  });

  _exports.default = _default;
});