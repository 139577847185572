define("@vollersgroup/hub-frontend/admin/manage/auction/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "@vollersgroup/hub-frontend/mixins/reset-scroll", "@vollersgroup/hub-frontend/mixins/company-verified"], function (_exports, _authenticatedRouteMixin, _resetScroll, _companyVerified) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _resetScroll.default, _companyVerified.default, {
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Checks if `auctionsEnabled` flag is set on current company.
     * If not, it will redirect to manage overview index page.
     *
     * @public
     * @method beforeModel
     * @return Promise
     */
    beforeModel: function beforeModel() {
      var _this = this;

      var companySuccessCallback = function companySuccessCallback() {
        if (!_this.can('create auction')) {
          Ember.get(_this, 'toast').info(Ember.get(_this, 'l10n').t('Sorry, but you cannot manage auctions because the module is not active.'));

          _this.transitionTo('admin.manage.index');
        }
      };

      return Ember.get(this, 'userSession.currentCompany').then(companySuccessCallback);
    }
  });

  _exports.default = _default;
});