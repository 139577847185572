define("@vollersgroup/hub-frontend/components/message-detail-reply/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tqr28/BL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"message__reply--wrapper\"],[9],[0,\"\\n  \"],[7,\"form\"],[9],[0,\"\\n    \"],[7,\"label\"],[11,\"for\",\"message-text\"],[11,\"class\",\"hidden\"],[9],[1,[27,\"t\",[\"Message text\"],null],false],[10],[0,\"\\n    \"],[1,[27,\"expanding-textarea\",null,[[\"placeholder\",\"rows\",\"value\",\"name\"],[[23,[\"placeholder\"]],2,[23,[\"messageReply\"]],\"message-text\"]]],false],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"button button--half-height\"],[9],[1,[27,\"t\",[\"Send message\"],null],false],[10],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"send\"],[[\"on\"],[\"submit\"]]],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/message-detail-reply/template.hbs"
    }
  });

  _exports.default = _default;
});