define("@vollersgroup/hub-frontend/helpers/optional-action", ["exports", "@vollersgroup/ember-cropster-common/helpers/optional-action"], function (_exports, _optionalAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _optionalAction.default;
    }
  });
  Object.defineProperty(_exports, "optionalAction", {
    enumerable: true,
    get: function get() {
      return _optionalAction.optionalAction;
    }
  });
});