define("@vollersgroup/hub-frontend/components/shopping-cart/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    shoppingCart: Ember.inject.service(),
    actions: {
      removeCartItem: function removeCartItem(orderItem) {
        this.get('shoppingCart').removeOrderItem(orderItem);
      },
      removeCart: function removeCart(order) {
        this.get('shoppingCart').removeCart(order);
      }
    }
  });

  _exports.default = _default;
});