define("@vollersgroup/hub-frontend/admin/manage/overview/auction/registrations/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    availableData: Ember.inject.service(),
    l10n: Ember.inject.service(),
    queryParams: [{
      selectedAuctionId: {
        as: 'auction'
      }
    }, 'orderBy', 'filterStatus'],
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      toggleForm: function toggleForm() {
        this.toggleProperty('showForm');
      }
    },
    // -------------------------------------------------------------------------
    // Properties

    /**
     * The currently selected auction id selected from dropdown.
     *
     * @property selectedAuctionId
     * @type String
     * @default ""
     */
    selectedAuctionId: '',

    /**
     * Selected participants used to send a message to.
     *
     * @property selectedRegistrations
     * @type {Array}
     * @default []
     */
    selectedRegistrations: Ember.computed(function () {
      return [];
    }),

    /**
     * Selected recipients mapped from `selectedRegistrations`.
     *
     * @property selectedRecipients
     * @type {Array}
     * @default []
     */
    selectedRecipients: Ember.computed('selectedRegistrations.[]', function () {
      var selected = this.get('selectedRegistrations');
      return selected.map(function (registration) {
        return registration.get('user');
      });
    }),

    /**
     * Property used to order auction registrations.
     *
     * @property orderBy
     * @type String
     * @default "DATE"
     */
    orderBy: 'DATE',

    /**
     * If true, a form is shown to contact (multiple) auction participants.
     *
     * @property showMessage
     * @type Boolean
     * @default false
     */
    showForm: false,

    /**
     * If true, the message queue is currently processed.
     *
     * @property showMessage
     * @type Boolean
     * @default false
     */
    isSending: false,

    /**
     * The statuses that should be displayed. Multiple can be selected.
     */
    filterStatus: Ember.computed(function () {
      return [];
    }),

    /**
     * All auctions of this company.
     *
     * @property availableAuctions
     * @type Model.Auction[]
     */
    availableAuctions: Ember.computed.alias('availableData.auctions'),

    /**
     * The available status to filter from.
     *
     * @property filterStatusOptions
     * @type Array
     * @final
     */
    filterStatusOptions: Ember.computed('l10n', function () {
      return [{
        value: 'PENDING',
        label: this.get('l10n').t('Pending')
      }, {
        value: 'ACCEPTED',
        label: this.get('l10n').t('Accepted')
      }, {
        value: 'REJECTED',
        label: this.get('l10n').t('Rejected')
      }];
    })
  });

  _exports.default = _default;
});