define("@vollersgroup/hub-frontend/admin/messages/show/route", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('conversation', params.conversation_id);
    },
    afterModel: function afterModel(model) {
      var _this = this;

      if (!model) {
        return;
      }

      model.get('messages').reload();
      Ember.run.next(function () {
        if (model.get(model.get('fromOrTo') + 'CompanyUnreadCount') !== 0) {
          model.set(model.get('fromOrTo') + 'CompanyUnreadCount', 0);

          _this.send('markAsRead', model);
        }
      });
    },
    actions: {
      reply: function reply(message) {
        var model = this.currentModel;

        if (!model) {
          return;
        }

        var createdMessage = this.store.createRecord('message', {
          text: message,
          created: (0, _moment.default)(),
          fromCompany: this.get('userSession.currentUser.company'),
          fromUser: this.get('userSession.currentUser'),
          conversation: model
        });
        createdMessage.save().then(function () {
          model.reload();
        });
      }
    }
  });

  _exports.default = _default;
});