define("@vollersgroup/hub-frontend/helpers/get-array-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getArrayElement = getArrayElement;
  _exports.default = void 0;

  function getArrayElement(params
  /*, hash*/
  ) {
    var arr = params[0];
    var i = params[1];

    if (!arr || Ember.typeOf(arr) !== 'array') {
      return null;
    }

    return arr[i];
  }

  var _default = Ember.Helper.helper(getArrayElement);

  _exports.default = _default;
});