define("@vollersgroup/hub-frontend/initializers/ember-cli-lightbox", ["exports", "@vollersgroup/hub-frontend/config/environment", "ember-cli-lightbox/initializers/ember-cli-lightbox"], function (_exports, _environment, _emberCliLightbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _emberCliLightbox.default.initialize.apply(null, [_environment.default['ember-cli-lightbox']].concat(args));
  }

  var _default = {
    name: _emberCliLightbox.default.name,
    initialize: initialize
  };
  _exports.default = _default;
});