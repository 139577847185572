define("@vollersgroup/hub-frontend/components/home-header/component", ["exports", "@vollersgroup/hub-frontend/components/header-bar/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The header for the landing page.
   *
   * ```html
   * {{home-header}}
   * ```
   *
   * @namespace Component
   * @class HomeHeader
   * @extends HeaderBar
   */
  var _default = _component.default.extend({});

  _exports.default = _default;
});