define("@vollersgroup/hub-frontend/cart/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Az3SPycI",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"page-title\",[[27,\"t\",[\"Shopping cart\"],null]],null],false],[0,\"\\n\\n\"],[7,\"header\"],[11,\"class\",\"\"],[9],[0,\"\\n  \"],[1,[21,\"header-bar\"],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"section\"],[11,\"class\",\"flex-grow-1 padding-bottom-3 section--highlighted\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"wrapper\"],[9],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"layout__full\"],[9],[0,\"\\n\"],[4,\"flash-messages\",null,[[\"noMargin\"],[true]],{\"statements\":[[0,\"        \"],[4,\"link-to\",[\"admin.orders\"],[[\"class\"],[\"button\"]],{\"statements\":[[1,[27,\"t\",[\"View your orders\"],null],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n      \"],[1,[21,\"shopping-cart\"],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[21,\"footer-main\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/cart/template.hbs"
    }
  });

  _exports.default = _default;
});