define("@vollersgroup/hub-frontend/admin/company/loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hS2QxrCu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"fg-container\"],[9],[0,\"\\n  \"],[1,[21,\"loading-spinner\"],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/admin/company/loading/template.hbs"
    }
  });

  _exports.default = _default;
});