define("@vollersgroup/hub-frontend/components/auction-list-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userSession: Ember.inject.service(),
    tagName: '',
    auction: null
  });

  _exports.default = _default;
});