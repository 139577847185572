define("@vollersgroup/hub-frontend/components/manage-documents/component", ["exports", "@vollersgroup/hub-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    toast: Ember.inject.service(),
    l10n: Ember.inject.service(),

    /**
     * The currently added files.
     *
     * @attribute files
     * @type Array
     */
    files: Ember.computed(function () {
      return [];
    }),

    /**
     * The action to call when saving the changes.
     *
     * @event action
     * @param {Array} files
     * @public
     */
    action: null,

    /*
     * Accepted MIME types for document upload.
     */
    acceptedDocumentMimeTypes: Ember.computed(function () {
      return ['application/pdf', 'application/msword', // .doc
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
      'image/jpeg', 'image/png'].join(',');
    }),
    actions: {
      /**
       * Upload one or multiple files for the order.
       *
       * @event uploadDocument
       * @param {Object} file
       * @private
       */
      uploadDocument: function uploadDocument(file) {
        var _this = this;

        var headers = {
          'Cropster-Authenticate': 'suppress-www-authenticate'
        };
        var data = {
          entity: 'ORDER_FILE'
        };
        var url = _environment.default.apiBaseUrl + '/' + _environment.default.apiNamespace + '/media';
        file.upload({
          url: url,
          data: data,
          headers: headers
        }).then(function (response) {
          var data = response.body;
          var id = data.id;
          var url = data.url;
          var fileName = data.name;
          var file = {
            id: id,
            url: url,
            name: fileName
          };
          Ember.get(_this, 'files').pushObject(file);

          _this.sendAction('action', _this.get('files'));
        }).catch(function () {
          var l10n = Ember.get(_this, 'l10n');
          var errMessage = l10n.t('An error occurred while trying to upload the file. Please try again with a different file.');

          _this.get('toast').error(errMessage); // Remove file itself from queue so user can try upload again


          file.get('queue').remove(file);
        });
      },

      /**
       * Remove an image from the documents.
       *
       * @event removeDocument
       * @param {String} image
       * @private
       */
      removeDocument: function removeDocument(file) {
        this.get('files').removeObject(file);
        this.sendAction('action', this.get('files'));
      }
    }
  });

  _exports.default = _default;
});