define("@vollersgroup/hub-frontend/services/websocket", ["exports", "@vollersgroup/hub-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Handles websocket connections.
   *
   * @namespace Service
   * @class Websocket
   * @extends Ember.Service
   * @uses Service.Toast
   * @uses Service.l10n
   * @uses Service.LocalAssetLoader
   */
  var _default = Ember.Service.extend({
    toast: Ember.inject.service(),
    l10n: Ember.inject.service(),
    localAssetLoader: Ember.inject.service('local-asset-loader'),
    flashMessages: Ember.inject.service(),
    isConnected: true,

    /**
     * All currently opened connections.
     *
     * @property _connections
     * @type Array
     * @private
     */
    _connections: Ember.computed(function () {
      return [];
    }),

    /**
     * Connect to an auction.
     *
     * @method connectAuction
     * @param auctionId The ID of the auction to connect to.
     * @param func The function to call when a message is received.
     * @param errorFunc The function to call when an error occurs.
     * @returns Object The websocket client
     */
    connectAuction: function connectAuction(auctionId, func, errorFunc) {
      var _this2 = this;

      if (!this.get('_isLoaded')) {
        return null;
      }

      if (typeof errorFunc === 'undefined') {
        var _this = this;

        errorFunc = function errorFunc() {
          window.scrollTo(0, 0);

          _this.set('isConnected', false);

          var errMessage = _this.get('l10n').t('An error occurred with your connection to the server. In order to continue receiving updates, please reload now.');

          var errTitle = _this.get('l10n').t('Problem with connection');

          _this.get('toast').error(errMessage, errTitle, {
            timeOut: '0',
            progressBar: false,
            extendedTimeOut: '0'
          });
        };
      }

      var socket = new SockJS(_environment.default.apiBaseUrl + '/async/bidding');
      var stompClient = Stomp.over(socket);

      if (_environment.default.environment === 'production') {
        stompClient.debug = function () {};
      }

      stompClient.connect({}, function () {
        stompClient.subscribe('/auction/' + auctionId, func);

        _this2.set('isConnected', true);

        _this2.set('reconnectWait', 0);
      }, function (err) {
        if (typeof errorFunc === 'function') {
          errorFunc(err);
        }

        _this2.autoReconnect(auctionId, func, errorFunc);
      });
      this.get('_connections').pushObject(stompClient);
      return stompClient;
    },
    reconnectWait: 0,
    reconnectTime: 0,
    _reconnectTimer: null,
    autoReconnect: function autoReconnect(auctionId, func, errorFunc) {
      var reconnectWait = this.get('reconnectWait');

      if (this.get('isConnected')) {
        Ember.run.cancel(this.get('_reconnectTimer'));
        return;
      }

      Ember.run.later(this, function () {
        this.connectAuction(auctionId, func, errorFunc);
      }, reconnectWait * 1000);
      Ember.run.cancel(this.get('_reconnectTimer'));
      this.set('reconnectTime', reconnectWait);
      this.set('reconnectWait', reconnectWait + 5);

      this._reconnectTimeRun();
    },
    _reconnectTimeRun: function _reconnectTimeRun() {
      this.decrementProperty('reconnectTime');

      if (this.get('reconnectTime') > 0) {
        var timer = Ember.run.later(this, this._reconnectTimeRun, 1000);
        this.set('_reconnectTimer', timer);
      }

      if (this.get('isConnected')) {
        this.set('reconnectTime', 0);
        Ember.run.cancel(this.get('_reconnectTimer'));
        return;
      }
    },

    /**
     * Disconnect a client.
     *
     * @method disconnect
     * @param stompClient The client to disconnect.
     */
    disconnect: function disconnect(stompClient) {
      if (!this.get('_isLoaded')) {
        return false;
      }

      this.set('isConnected', false);

      if (stompClient) {
        stompClient.disconnect();
        this.get('_connections').removeObject(stompClient);
      } else {
        this.get('_connections').forEach(function (connection) {
          connection.disconnect();
        });
        this.set('_connections', []);
      }
    },

    /**
     * If the necessary scripts have been loaded yet.
     *
     * @property _isLoaded
     * @type Boolean
     */
    _isLoaded: false,

    /**
     * Checks if all dependencies have been loaded, and if not, loads them.
     *
     * @property isLoaded
     * @type Ember.RSVP.Promise
     */
    isLoaded: Ember.computed('localAssetLoader', function () {
      var _this3 = this;

      return new Ember.RSVP.Promise(function (resolve) {
        _this3.get('localAssetLoader').loadScript('/assets/websockets/sockjs.min.js').then(function () {
          _this3.get('localAssetLoader').request('/assets/websockets/stomp.min.js').then(function () {
            _this3.set('_isLoaded', true);

            resolve(true);
          });
        });
      });
    })
  });

  _exports.default = _default;
});