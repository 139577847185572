define("@vollersgroup/hub-frontend/admin/messages/notifications/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6/DKKSks",
    "block": "{\"symbols\":[\"conversation\"],\"statements\":[[1,[27,\"page-title\",[[27,\"t\",[\"Notifications\"],null]],null],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"margin-bottom-2\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"layout__full hidden block-l\"],[9],[0,\"\\n    \"],[7,\"h1\"],[11,\"class\",\"font-size-3\"],[9],[1,[27,\"t\",[\"Notifications\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[12,\"class\",[28,[\"conversations \",[27,\"if\",[[23,[\"selectedNotification\"]],\"conversations--has-detail\"],null]]]],[9],[0,\"\\n\\n\"],[4,\"info-tooltip\",null,[[\"infoId\",\"class\"],[\"info__notification\",\"tooltip--no-arrow tooltip--full margin-top-3\"]],{\"statements\":[[0,\"    \"],[7,\"p\"],[9],[0,\" \"],[1,[27,\"t\",[\"If there is something new in an auction, a coffee purchase or a sample order you will be notified here.\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"ul\"],[11,\"class\",\"message__preview--list\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[9],[0,\"\\n        \"],[1,[27,\"notification-list-item\",null,[[\"conversation\",\"dismissAction\"],[[22,1,[]],\"remove\"]]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"      \"],[7,\"li\"],[11,\"class\",\"padding-top-3\"],[9],[1,[27,\"t\",[\"Currently you have no notifications.\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"padding-bottom-4\"],[9],[0,\"\\n\"],[4,\"pagination-widget\",null,[[\"page\",\"maxPages\",\"pageSize\",\"changePageSize\"],[[23,[\"page\"]],[23,[\"maxPages\"]],[23,[\"pageSize\"]],[27,\"action\",[[22,0,[]],\"changePageSize\"],null]]],{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"Messages per page\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/admin/messages/notifications/template.hbs"
    }
  });

  _exports.default = _default;
});