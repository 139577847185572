define("@vollersgroup/hub-frontend/settings/ability", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend({
    userSession: Ember.inject.service(),
    canEdit: Ember.computed('userSession.currentUser.roleLevel', {
      get: function get()
      /*key*/
      {
        return Ember.get(this, 'userSession.currentUser.roleLevel') >= 4;
      }
    }),
    canEditTemplate: Ember.computed('userSession.currentUser.roleLevel', {
      get: function get()
      /*key*/
      {
        return Ember.get(this, 'userSession.currentUser.roleLevel') >= 2;
      }
    })
  });

  _exports.default = _default;
});