define("@vollersgroup/hub-frontend/application/serializer", ["exports", "ember-data", "ember-inflector"], function (_exports, _emberData, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RESTSerializer = _emberData.default.RESTSerializer;

  var _default = RESTSerializer.extend({
    normalize: function normalize(type, json, property) {
      // convert links to correct format
      if (Ember.typeOf(json.links) === 'array') {
        var links = {};
        json.links.forEach(function (link) {
          links[link.rel] = link.href;
        });
        json.links = links;
      }

      return this._super(type, json, property);
    },

    /**
     * Change the response format into a format the RESTAdapter expects.
     * The expected format is { myModel: {...}, meta: {...} }
     * The data is pre-processed by the application adapter's handleResponse function before it is passed to this function.
     *
     */
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var modelName = Ember.String.camelize(primaryModelClass.modelName);
      var modelNamePlural = (0, _emberInflector.pluralize)(modelName);
      var json = {
        meta: payload.meta
      };
      json[Ember.typeOf(payload.data) === 'array' ? modelNamePlural : modelName] = payload.data;
      return this._super(store, primaryModelClass, json, id, requestType);
    },
    normalizeDeleteRecordResponse: function normalizeDeleteRecordResponse() {
      return {
        meta: {}
      };
    },
    serializeIntoHash: function serializeIntoHash(data, type, record, options) {
      Ember.merge(data, this.serialize(record, options));
    },
    serialize: function serialize(record, options) {
      options = options ? options : {}; // handle the case where options is undefined

      options.includeId = true;
      return this._super.apply(this, [record, options]); // Call the parent serializer
    }
  });

  _exports.default = _default;
});