define("@vollersgroup/hub-frontend/services/ajax", ["exports", "@vollersgroup/hub-frontend/config/environment", "ember-ajax/services/ajax", "ember-ajax/errors"], function (_exports, _environment, _ajax, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  /**
   * This service is used to fetch data from the API.
   * It has its base URL & namespace set according to the settings from the config.
   * It also handles the sending of the appropriate HTTP headers etc.
   *
   * @namespace Service
   * @class Ajax
   */
  var _default = _ajax.default.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    l10n: Ember.inject.service(),
    session: Ember.inject.service(),
    // Properties

    /**
     * Base host for ajax service. Should point to http://localhost:4200
     * on localhost for making --proxy flag working in ember server, but
     * will be empty for `production` environment.
     *
     * @property host
     * @type {String}
     * @public
     */
    host: _environment.default.apiBaseUrl,

    /**
     * Namespace prefix for ajax calls to the api. Defaults to `/api`.
     *
     * @property namespace
     * @type {String}
     * @public
     */
    namespace: Ember.computed(function () {
      // Add slash to the beginning of the namespace.
      // Fix for https://github.com/ember-cli/ember-ajax/issues/383
      return '/' + _environment.default.apiNamespace;
    }),

    /**
     * Global headers transmitted with each AJAX request.
     *
     * @property headers
     * @type {Object}
     * @public
     */
    headers: Ember.computed('l10n.locale', function () {
      return {
        'Accept-Language': this.get('l10n.locale'),
        'Cropster-Authenticate': 'suppress-www-authenticate'
      };
    }),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Method used for POST requests. Sets common headers for those requests.
     *
     * @method post
     * @return {Ember.RSVP.Promise}
     * @public
     */
    post: function post(url, options) {
      options = options || {};

      if (typeof options.dataType === 'undefined') {
        options.dataType = 'json';
      }

      if (typeof options.contentType === 'undefined') {
        options.contentType = 'application/json; charset=utf-8';
      }

      if (_typeof(options.data) === 'object' && options.contentType === 'application/json; charset=utf-8') {
        options.data = JSON.stringify(options.data);
      }

      return this._super(url, options);
    },

    /**
     * Checks for 401 errors to invalidate session implicitly.
     * @param  {number} status
     * @param  {object} headers
     * @param  {object} payload
     * @param  {object} requestData
     * @return {object}
     */
    // eslint-disable-next-line no-unused-vars
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      var error = this._super.apply(this, arguments);

      if ((0, _errors.isUnauthorizedError)(error)) {
        var session = this.get('session');

        if (session.get('isAuthenticated')) {
          session.invalidate(true);
        }

        return;
      }

      return error;
    }
  });

  _exports.default = _default;
});