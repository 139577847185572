define("@vollersgroup/hub-frontend/admin/manage/auction/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * When the auction information has been entered/updated.
       *
       * @event editAuction
       * @private
       */
      editAuction: function editAuction() {
        this.incrementProperty('step');
        window.scrollTo(0, 0);
      },

      /**
       * Add listings to this auction.
       *
       * @event addListings
       * @param {Model.Listing[]} listings
       * @param {Model.Listing[]} addedListings
       * @param {Model.Listing[]} removedListings
       * @private
       */
      addListings: function addListings(listings, addedListings, removedListings) {
        var model = this.get('model');
        addedListings.forEach(function (listing) {
          listing.set('auction', model);
          listing.set('status', 'ACTIVE');
          listing.save();
        });
        removedListings.forEach(function (listing) {
          listing.set('auction', null);
          listing.set('status', 'INACTIVE');
          listing.save();
        });
        model.set('listings', []);
        model.get('listings').pushObjects(listings);
        this.incrementProperty('step');
        window.scrollTo(0, 0);
      },

      /**
       * Publish the auction.
       *
       * @event publish
       * @param {Boolean} createCoffee
       * @private
       */
      publish: function publish() {
        var createCoffee = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        if (createCoffee) {
          this.transitionToRoute('admin.manage.listing.create', {
            queryParams: {
              addToAuction: this.get('model.id'),
              addtoShop: this.get('model.shop.id')
            }
          });
        } else {
          this.transitionToRoute('admin.manage.overview.auction');
        }
      },

      /**
       * Move to another step.
       *
       * @event gotoStep
       * @param {Number} newStep
       * @private
       */
      gotoStep: function gotoStep(newStep) {
        if (this.get('model.hasDirtyAttributes') && newStep > this.get('step')) {
          // Manually check the start date, because it may be two different moment objects with the same value
          var changedAttributes = this.get('model').changedAttributes();
          var hasChanged = false;

          for (var i in changedAttributes) {
            if (typeof changedAttributes.hasOwnProperty === 'function' && // eslint-disable-next-line no-prototype-builtins
            !changedAttributes.hasOwnProperty(i)) {
              continue;
            }

            if (i !== 'startedDate' && i !== 'finalSampleOrderDate') {
              hasChanged = true;
            }

            if (changedAttributes[i][0].format('x') !== changedAttributes[i][0].format('x')) {
              hasChanged = true;
            }
          }

          if (hasChanged) {
            this.get('toast').warning(this.get('l10n').t('You have to save your changes!'));
            return;
          }
        }

        this.set('step', newStep);
        window.scrollTo(0, 0);
      },

      /**
       * Exit the process and return to the overview.
       *
       * @event exitProcess
       * @private
       */
      exitProcess: function exitProcess() {
        this.transitionToRoute('admin.manage.overview.auction');
      },

      /**
       * Add the selected listings and return to the overview.
       *
       * @event exitProcess
       * @private
       */
      addListingsExit: function addListingsExit(listings, addedListings, removedListings) {
        this.send('addListings', listings, addedListings, removedListings);
        this.transitionToRoute('admin.manage.overview.auction');
      }
    },
    // -------------------------------------------------------------------------
    // Properties

    /**
     * The current step of the process.
     *
     * @property step
     * @type Number
     */
    step: 0,

    /**
     * All steps for the creation process.
     *
     * @property allSteps
     * @type Array
     */
    allSteps: Ember.computed('availableShops.length', 'l10n', function () {
      var steps = [];
      steps.push({
        label: this.get('l10n').t('Edit auction'),
        step: steps.length,
        subSteps: [{
          label: this.get('l10n').t('Base information'),
          anchor: '#auction-manage-1'
        }, {
          label: this.get('l10n').t('Policy'),
          anchor: '#auction-manage-2'
        }]
      });
      steps.push({
        label: this.get('l10n').t('Add coffee'),
        step: steps.length,
        subSteps: []
      });
      steps.push({
        label: this.get('l10n').t('Publish auction'),
        step: steps.length,
        subSteps: []
      });
      return steps;
    }) // -------------------------------------------------------------------------
    // Methods

  });

  _exports.default = _default;
});