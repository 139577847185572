define("@vollersgroup/hub-frontend/components/auction-participant-overview/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A table of auction participants incl. buttons to change the status.
   *
   * ```hbs
   * {{auction-registration-overview auctionRegistrations=myParticipants}}
   * ```
   *
   * @namespace Component
   * @class AuctionRegistrationOverview
   * @extends Ember.Component
   *
   */
  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    l10n: Ember.inject.service(),
    toast: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * The auction registrations to display
     *
     * @attribute auctionRegistrations
     * @type Model.AuctionParticipant
     */
    auctionRegistrations: null,

    /**
     * This action is called whenever the status of a registration changes.
     *
     * @attribute action
     * @type String
     */
    action: null,

    /**
     * If set to true, an additional column with a checkbox will be rendered
     * to select single items from the table to be used for others.
     *
     * @attribute selectable
     * @type {Boolean}
     */
    selectable: false,

    /**
     * Collection of selected registrations if `selectable` is true.
     *
     * @attribute selectedRegistrations
     * @type {Array}
     */
    selectedRegistrations: Ember.computed(function () {
      return [];
    }),

    /**
     * How to order the table. If this is null, the orderBy field is not displayed.
     * Else, the select is linked to this attribute.
     *
     * @attribute orderBy
     * @type {String}
     */
    orderBy: null,
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Available options for ordering, either "DATE" or "NAME".
     *
     * @property orderByOptions
     * @type {Array}
     */
    orderByOptions: Ember.computed('l10n', function () {
      return [{
        value: 'DATE',
        label: this.get('l10n').t('Registration date')
      }, {
        value: 'NAME',
        label: this.get('l10n').t('Company name')
      }];
    }),

    /**
     * Flag indicating if all registrations are selected.
     *
     * @property allSelected
     * @type {Booelan}
     */
    allSelected: false,
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Sets and persists participant's status to "ACCEPTED".
       *
       * @event rejectRegistration
       * @param {Model.AuctionParticipant} registration
       * @private
       */
      acceptRegistration: function acceptRegistration(registration) {
        var _this = this;

        registration.set('registrationStatus', 'ACCEPTED');
        registration.save().then(function () {
          registration.get('auction').notifyPropertyChange('auctionRegistrations');

          if (_this.getAttr('action')) {
            _this.sendAction('action', registration);
          }
        });
        this.get('toast').success(this.get('l10n').t('You have accepted the registration.'));
      },

      /**
       * Sets and persists participant's status to "REJECTED".
       *
       * @event rejectRegistration
       * @param {Model.AuctionParticipant} registration
       * @private
       */
      rejectRegistration: function rejectRegistration(registration) {
        var _this2 = this;

        registration.set('registrationStatus', 'REJECTED');
        registration.save().then(function () {
          registration.get('auction').notifyPropertyChange('auctionRegistrations');

          if (_this2.getAttr('action')) {
            _this2.sendAction('action', registration);
          }
        });
        this.get('toast').info(this.get('l10n').t('You have rejected the registration.'));
      },

      /**
       * Manually set the ordering.
       *
       * @event orderBy
       * @param {String} orderBy
       * @private
       */
      orderBy: function orderBy(_orderBy) {
        this.set('orderBy', _orderBy);
      },

      /**
       * Toggles selection for a single registration.
       *
       * @event toggleSelectAll
       * @param {Model.AuctionParticipant} registration
       * @param {Boolean} checked
       * @private
       */
      toggleSelect: function toggleSelect(registration, checked) {
        var registrations = this.get('auctionRegistrations');
        var selected = this.get('selectedRegistrations');
        registration.set('isSelected', checked);

        if (checked) {
          selected.addObject(registration);
        } else {
          selected.removeObject(registration);
        }

        var total = selected.get('length');
        var current = registrations.get('length');
        this.set('allSelected', total === current);
      },

      /**
       * Toggles selection of all registrations.
       *
       * @event toggleSelectAll
       * @param {Boolean} checked
       * @private
       */
      toggleSelectAll: function toggleSelectAll(checked) {
        var _this3 = this;

        this.get('auctionRegistrations').forEach(function (registration) {
          _this3.send('toggleSelect', registration, checked);
        });
      }
    }
  });

  _exports.default = _default;
});