define("@vollersgroup/hub-frontend/components/filter-item-other/component", ["exports", "@vollersgroup/hub-frontend/components/filter-item/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A filter item handling filter type "OTHER" with checkboxes.
   *
   * ```html
   * {{filter-item-other filter=filter update=(action "{YOUR_ACTION}")}}
   * ```
   *
   * @namespace Component
   * @class FilterItemOther
   * @extends Component.FilterItem
   */
  var _default = _component.default.extend({});

  _exports.default = _default;
});