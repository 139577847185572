define("@vollersgroup/hub-frontend/store/auctions/show/admin/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    toast: Ember.inject.service(),
    userSession: Ember.inject.service(),
    beforeModel: function beforeModel() {
      // Make company lookup for current user
      return this.get('userSession.currentCompany');
    },
    afterModel: function afterModel(model) {
      model.notifyPropertyChange('auctionRegistrations');

      if (!Ember.get(model, 'isOwner')) {
        var redirectMessage = Ember.get(this, 'l10n').t('You do not have access to the administrator view of this auction!');
        Ember.get(this, 'toast').warning(redirectMessage);
        this.replaceWith('store.auctions.show.index');
      }
    }
  });

  _exports.default = _default;
});