define("@vollersgroup/hub-frontend/application/controller", ["exports", "@vollersgroup/hub-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['addSampleToCart', 'sendMessage', 'messageTo', 'messageMode', 'messageSubject', 'messageContent', 'messageAttachListing', 'showLogin', 'gotoRoute', 'gotoModel', 'afterLogin', 'addToCart'],
    // Add sample to cart modal query params
    addSampleToCart: null,
    // Login modal query params
    showLogin: null,
    gotoRoute: null,
    gotoModel: null,
    afterLogin: null,
    // Message modal query params
    sendMessage: null,
    messageSubject: null,
    messageContent: null,
    messageMode: null,
    messageTo: null,
    messageAttachListing: null,
    // Buy coffee modal query params
    addToCart: null,
    // To indicate your current environment on qa
    environment: Ember.computed(function () {
      return _environment.default.hubEnv;
    }),
    environmentBadge: Ember.computed('environment', function () {
      return Ember.get(this, 'environment') === 'qa' ? window.location.host : null;
    }),
    actions: {
      dismissAddSampleToCartModal: function dismissAddSampleToCartModal() {
        this.set('addSampleToCart', null);
      },
      dismissMessageModal: function dismissMessageModal() {
        this.set('sendMessage', null);
        this.set('messageSubject', null);
        this.set('messageContent', null);
        this.set('messageMode', null);
        this.set('messageTo', null);
        this.set('messageAttachListing', null);
      },
      dismissLoginModal: function dismissLoginModal() {
        this.set('showLogin', null);
        this.set('gotoRoute', null);
        this.set('gotoModel', null);
        this.set('afterLogin', null);
      },
      dismissAddToCartModal: function dismissAddToCartModal() {
        this.set('addToCart', null);
      }
    }
  });

  _exports.default = _default;
});