define("@vollersgroup/hub-frontend/admin/index/controller", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_emberCan.CanMixin, {
    userSession: Ember.inject.service(),
    l10n: Ember.inject.service(),
    messageCenter: Ember.inject.service(),
    isLoaded: Ember.computed.and('model.recentOrders.isFulfilled', 'model.recentSales.isFulfilled', 'model.upcomingAuctions.isFulfilled'),
    _showUserSettingsForm: false,
    showUserSettingsForm: Ember.computed('_showUserSettingsForm', 'userSession.currentUser.{email,fullName}', function () {
      if (this.get('_showUserSettingsForm')) {
        return true;
      }

      return this.set('_showUserSettingsForm', !this.get('userSession.currentUser.fullName') || !this.get('userSession.currentUser.email'));
    }),
    quickLinkListingOptions: Ember.computed('l10n', function () {
      var quickLinksListingOptions = [{
        label: this.get('l10n').t('Add one'),
        route: 'admin.manage.listing.create'
      }];

      if (this.can('edit settings')) {
        quickLinksListingOptions.push({
          label: this.get('l10n').t('Add many'),
          route: 'admin.manage.bulk.listing'
        });
      }

      return quickLinksListingOptions;
    }),
    quickLinkProducerOptions: Ember.computed('l10n', function () {
      var quickLinksProducerOptions = [{
        label: this.get('l10n').t('Add one'),
        route: 'admin.manage.producer.create'
      }];

      if (this.can('edit settings')) {
        quickLinksProducerOptions.push({
          label: this.get('l10n').t('Add many'),
          route: 'admin.manage.bulk.producer'
        });
      }

      return quickLinksProducerOptions;
    })
  });

  _exports.default = _default;
});