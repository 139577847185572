define("@vollersgroup/hub-frontend/utils/get-video-metadata", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getVideoMetadata;

  /*
   * Returns service name and video id for Youtube and Vimeo videos.
   *
   * @return { service: <string>, id: <string> }
   */
  function getVideoMetadata(url) {
    if (Ember.typeOf(url) !== 'string') {
      return {
        id: null,
        service: null
      };
    } // remove surrounding whitespace


    url = url.trim(); // remove any leading `www.`

    url = url.replace('/www.', '/');

    if (/youtube\.com|youtu\.be/.test(url)) {
      return {
        id: youtube(url),
        service: 'youtube'
      };
    }

    if (/vimeo\.com/.test(url)) {
      return {
        id: vimeo(url),
        service: 'vimeo'
      };
    }

    return {
      id: null,
      service: null
    };
  }

  function vimeo(url) {
    url = removeHashParams(url);
    url = removeQueryParams(url); // https://vimeo.com/<video-id> pattern

    if (/https?:\/\/vimeo\.com\/[0-9]+$/.test(url)) {
      var arr = url.split('/');
      return arr[arr.length - 1];
    } // https://vimeo.com/channels/.../<video-id>
    // https://vimeo.com/groups/.../<video-id>
    // https://vimeo.com/album/.../<video-id>


    if (/https?:\/\/vimeo\.com\/channels|groups|album/gim.test(url)) {
      var _arr = url.split('/');

      return _arr[_arr.length - 1];
    }

    return null;
  }

  function youtube(url) {
    // Youtube share url https://youtu.be/<video-id>
    var shareFormat = /https?:\/\/youtu\.be\//g;

    if (shareFormat.test(url)) {
      url = removeHashParams(url);
      url = removeQueryParams(url);
      return url.split(shareFormat)[1];
    } // v= or vi= query parameter as in
    // https://www.youtube.com/watch?time_continue=1&v=<video-id>
    // https://www.youtube.com/watch?v=<video-id>


    var parameterv = /v=|vi=/g;

    if (parameterv.test(url)) {
      var arr = url.split(parameterv);
      return arr[1].split('&')[0];
    }
  }

  function removeQueryParams(url) {
    if (url.includes('?')) {
      return url.split('?')[0];
    }

    return url;
  }

  function removeHashParams(url) {
    if (url.includes('#')) {
      return url.split('#')[0];
    }

    return url;
  }
});