define("@vollersgroup/hub-frontend/admin/sales/list/controller", ["exports", "ember-data", "moment", "@vollersgroup/hub-frontend/mixins/label-print", "@vollersgroup/hub-frontend/utils/orders-labels"], function (_exports, _emberData, _moment, _labelPrint, _ordersLabels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PromiseArray = _emberData.default.PromiseArray,
      PromiseObject = _emberData.default.PromiseObject;

  var _default = Ember.Controller.extend(_labelPrint.default, {
    // -------------------------------------------------------------------------
    // Dependencies
    availableData: Ember.inject.service(),
    userSession: Ember.inject.service(),
    store: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Query parameters for route.
     *
     * @property queryParams
     * @type Array
     * @public
     */
    queryParams: ['filterStatus', 'selectedCompanyId', 'selectedShopId', 'showType', 'selectedListingId', 'fromDate', 'toDate', 'roastedSamples', 'page', 'pageSize'],
    page: 1,
    pageSize: 10,
    toDate: null,
    fromDate: null,
    showType: 'ALL',
    filterStatus: Ember.computed(function () {
      return [];
    }),
    selectedShopId: null,
    roastedSamples: false,
    selectedCompanyId: null,
    selectedListingId: null,

    /**
     * Current view mode settable
     * by child routes. Can be one
     * of `ORDER` or `COFFEE`.
     *
     * @property queryParams
     * @type Array
     * @public
     */
    mode: 'ORDER',

    /**
     * Maximum pages returned from server.
     *
     * @property maxPages
     * @type {Number}
     * @public
     */
    maxPages: Ember.computed('model.meta.maxPages', {
      get: function get() {
        return Ember.get(this, 'model.meta.maxPages') || 1;
      }
    }),

    /**
     * Total amount of query items from server.
     *
     * @property totalItems
     * @type {Number}
     * @public
     */
    totalItems: Ember.computed('model.length', 'model.meta.totalItems', {
      get: function get() {
        return Ember.get(this, 'model.meta.totalItems') || Ember.get(this, 'model.length');
      }
    }),

    /**
     * Current date as moment object.
     *
     * @property currentDate
     * @type {Object}
     * @public
     */
    currentDate: Ember.computed({
      get: function get() {
        return (0, _moment.default)();
      }
    }),

    /**
     * Current date format from user.
     *
     * @property dateFormat
     * @type {String}
     * @public
     */
    dateFormat: Ember.computed('userSession.dateFormat', {
      get: function get() {
        return Ember.get(this, 'userSession.dateFormat');
      }
    }),

    /**
     * Status options excluding `CART`.
     *
     * @property filterStatusOptions
     * @type {Array}
     * @public
     */
    filterStatusOptions: Ember.computed.filter('availableData.orderStates', function (orderState) {
      return orderState.value !== 'CART';
    }),

    /**
     * All available buyer companies from all orders.
     *
     * @property availableCompanies
     * @type {Promise}
     * @public
     */
    availableCompanies: Ember.computed('store', 'userSession.currentCompany.id', {
      get: function get() {
        var _this = this;

        var orders = new Ember.RSVP.Promise(function (resolve) {
          _this.store.query('order', {
            sellerCompany: Ember.get(_this, 'userSession.currentCompany.id')
          }).then(resolve);
        });
        var promise = new Ember.RSVP.Promise(function (resolve) {
          orders.then(function (orders) {
            var companies = [];
            var promises = [];
            orders.forEach(function (order) {
              promises.push(order.get('buyerCompany').then(function (company) {
                return companies.addObject(company);
              }));
            });
            Ember.RSVP.Promise.all(promises).then(function () {
              resolve(companies);
            });
          });
        });
        return PromiseArray.create({
          promise: promise
        });
      }
    }),

    /**
     * All available listings from all orders.
     * Will be filled by `ordersByCoffee` prop.
     *
     * @property availableListings
     * @type {Listing[]}
     * @public
     */
    availableListings: Ember.computed({
      get: function get() {
        return [];
      }
    }),

    /**
     * Maximum possible date for `fromDate` = `toDate`.
     *
     * @property fromDateMax
     * @type {Date}
     * @public
     */
    fromDateMax: Ember.computed('toDate', {
      get: function get() {
        var toDate = Ember.get(this, 'toDate');

        if (Ember.isNone(toDate)) {
          return new Date();
        }

        return new Date(+toDate);
      }
    }),

    /**
     * Minimum required date for `toDate` = `fromDate`.
     *
     * @property availableCompanies
     * @type {Date}
     * @public
     */
    toDateMin: Ember.computed('fromDate', {
      get: function get() {
        var fromDate = Ember.get(this, 'fromDate');

        if (Ember.isNone(fromDate)) {
          return new Date();
        }

        return new Date(+fromDate);
      }
    }),

    /**
     * Maximum possible date for `toDate` = today.
     *
     * @property availableCompanies
     * @type {Date}
     * @public
     */
    toDateMax: Ember.computed({
      get: function get() {
        return new Date();
      }
    }),

    /**
     * Groups all orders by `listing` from
     * `orderItem` as a hashmap for `COFFEE`
     * mode.
     *
     * @property ordersByCoffee
     * @type {Object}
     * @public
     */
    ordersByCoffee: Ember.computed('availableListings', 'model.length', {
      get: function get() {
        var _this2 = this;

        var promiseCallback = function promiseCallback(resolve) {
          var availableListings = Ember.get(_this2, 'availableListings');
          var model = Ember.get(_this2, 'model');

          if (Ember.isNone(model)) {
            resolve(null);
            return;
          }

          model = model.slice(0);
          var count = 0;
          var orders = {
            /* listingId: Order[] */
          };

          var nextOrder = function nextOrder() {
            var order = model.popObject();

            if (Ember.isNone(order)) {
              if (count > 0) {
                resolve(orders);
              } else {
                resolve(null);
              }

              return;
            }

            Ember.get(order, 'orderItems').then(orderItemsCallback);
          };

          var orderItemsCallback = function orderItemsCallback(orderItems) {
            var promises = [];
            orderItems.forEach(function (orderItem) {
              var listing = Ember.get(orderItem, 'listing');
              var promise = listing.then(function (listing) {
                if (!orders[listing.id]) {
                  availableListings.addObject(listing);
                  orders[listing.id] = [];
                  count++;
                }

                orders[listing.id].addObject(orderItem);
                return true;
              });
              promises.push(promise);
            });
            Ember.RSVP.all(promises).then(nextOrder);
          };

          nextOrder();
        };

        return PromiseObject.create({
          promise: new Ember.RSVP.Promise(promiseCallback)
        });
      }
    }),
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Prints sales list.
       *
       * @event print
       * @returns void
       * @private
       */
      print: function print() {
        window.print();
      },

      /**
       * Prints sales as labels.
       *
       * @event print
       * @returns void
       * @private
       */
      printLabels: function printLabels() {
        var model = Ember.get(this, 'model');
        var labels = (0, _ordersLabels.getOrdersLabels)(model);

        if (labels && labels.length) {
          this.printLabels(labels);
        }
      },

      /**
       * Sets `fromDate` to the beginning of the day.
       *
       * @event setFromDate
       * @returns void
       * @private
       */
      setFromDate: function setFromDate(fromDate) {
        if (fromDate) {
          fromDate = +fromDate.startOf('day');
        }

        Ember.set(this, 'fromDate', fromDate);
        this.send('resetPage');
      },

      /**
       * Sets `toDate` to the end of the day.
       *
       * @event setFromDate
       * @returns void
       * @private
       */
      setToDate: function setToDate(toDate) {
        if (toDate) {
          toDate = +toDate.endOf('day');
        }

        Ember.set(this, 'toDate', toDate);
        this.send('resetPage');
      },

      /**
       * Sets `page` to initial value of 1.
       *
       * @event resetPage
       * @returns void
       * @private
       */
      resetPage: function resetPage() {
        Ember.set(this, 'page', 1);
      }
    }
  });

  _exports.default = _default;
});