define("@vollersgroup/hub-frontend/helpers/markdown-to-html", ["exports", "marked"], function (_exports, _marked) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.markdownToHtml = markdownToHtml;
  _exports.default = void 0;

  function markdownToHtml(params
  /*, hash*/
  ) {
    var text = params[0];

    if (typeof text !== 'string') {
      return text;
    }

    var parsed = (0, _marked.default)(text, {
      sanitize: true,
      gfm: true
    });
    return Ember.String.htmlSafe(parsed);
  }

  var _default = Ember.Helper.helper(markdownToHtml);

  _exports.default = _default;
});