define("@vollersgroup/hub-frontend/helpers/svg-icon", ["exports", "ember-svg-jar/utils/make-helper", "ember-svg-jar/utils/make-svg"], function (_exports, _makeHelper, _makeSvg) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.svgIcon = svgIcon;
  _exports.default = void 0;

  function getInlineAsset(assetId) {
    try {
      /* eslint-disable no-undef */
      return require("ember-svg-jar/inlined/".concat(assetId)).default;
    } catch (err) {
      return null;
    }
  }

  function svgIcon(assetId, svgAttrs) {
    var options = Object.assign({}, svgAttrs || {});
    options.class = options.class ? "svg icon ".concat(svgAttrs.class) : 'svg icon';
    return (0, _makeSvg.default)(assetId, options, getInlineAsset);
  }

  var _default = (0, _makeHelper.default)(svgIcon);

  _exports.default = _default;
});