define("@vollersgroup/hub-frontend/admin/manage/overview/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "@vollersgroup/hub-frontend/mixins/company-verified", "@vollersgroup/hub-frontend/mixins/fix-qp-array-serializer"], function (_exports, _authenticatedRouteMixin, _companyVerified, _fixQpArraySerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Display all coffees of the current user's company.
   *
   * @namespace Route
   * @class ManageListingIndex
   * @extends Ember.Route
   * @uses Mixin.ResetScroll
   * @uses SimpleAuth.Mixin.AuthenticatedRouteMixin
   */
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _companyVerified.default, _fixQpArraySerializer.default, {
    // -------------------------------------------------------------------------
    // Dependencies
    ajax: Ember.inject.service(),
    toast: Ember.inject.service(),
    userSession: Ember.inject.service(),
    localStorage: Ember.inject.service(),
    availableData: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Provides custom model for import.
     *
     * @property queryParams
     * @type {Object}
     * @public
     */
    queryParams: {
      selectedShopId: {
        as: 'shop',
        refreshModel: true
      },
      selectedAuctionId: {
        as: 'auction',
        refreshModel: true
      },
      filterStatus: {
        as: 'status',
        refreshModel: true
      },
      filterOrigins: {
        as: 'origins',
        refreshModel: true
      },
      filterWarehouses: {
        as: 'warehouses',
        refreshModel: true
      },
      showArchived: {
        as: 'archived',
        refreshModel: true
      },
      showRatings: {
        as: 'ratings',
        refreshModel: true
      },
      page: {
        as: 'page',
        refreshModel: true
      },
      pageSize: {
        as: 'pageSize',
        refreshModel: true
      },
      orderBy: {
        as: 'orderBy',
        refreshModel: true
      },
      searchTerm: {
        as: 'term',
        refreshModel: true
      },
      salesNumber: {
        as: 'sales',
        refreshModel: true
      }
    },

    /**
     * Key to be used to put offer list settings into store.
     *
     * @property storageKey
     * @type {String}
     * @private
     */
    storageKey: 'hub_manage_coffee_page_size',

    /**
     * Default page size - will be overwritten from local storage.
     *
     * @property defaultPageSize
     * @type {Integer}
     * @private
     */
    storageVal: null,
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Loads warehouses from API for filter select.
     *
     * @public
     * @method beforeModel
     * @param {Object} transition
     * @return {Promise}
     */
    beforeModel: function beforeModel(transition) {
      // only override with storage on first call, as `controller`
      // would not be initialized in this case - query parameter
      // defaults cannot be static, therefore we need this patch
      if (Ember.isNone(Ember.get(this, 'controller'))) {
        var storage = Ember.get(this, 'localStorage');
        var value = storage.getItem(Ember.get(this, 'storageKey'));
        var pageSize = Ember.get(transition, 'queryParams.pageSize'); // only set if `pageSize` is missing and value from local
        // storage is available to be overridden with default val

        if (!Ember.isNone(value) && Ember.isNone(pageSize)) {
          Ember.set(transition, 'queryParams.pageSize', value.pageSize);
        }
      }

      return Ember.get(this, 'availableData.locations');
    },

    /**
     * Loads listings from API with current settings.
     *
     * @public
     * @method model
     * @param {Object} params
     * @return {Promise}
     */
    model: function model(params, transition) {
      var _this = this;

      var query = {
        company: Ember.get(this, 'userSession.currentCompany.id'),
        pageSize: transition.queryParams.pageSize || params.pageSize || 20,
        sort: params.orderBy || 'DATE',
        page: params.page || 1
      };
      query.sortDirection = query.sort === 'DATE' ? 'DESC' : 'ASC';
      query.hasRating = params.showRatings || false;
      query.archived = params.showArchived || false;
      query.auction = params.selectedAuctionId || '';
      query.shop = params.selectedShopId || '';
      query.status = !Ember.isEmpty(params.filterStatus) ? params.filterStatus : ['DRAFT', 'ACTIVE', 'EXPIRED', 'INACTIVE', 'SOLD_OUT'];
      query.term = params.searchTerm;
      query.producers = params.filterOrigins;
      query.salesOfferNumber = params.salesNumber;

      if (Ember.isArray(params.filterWarehouses)) {
        query.warehouses = [];
        params.filterWarehouses.forEach(function (id) {
          if (Ember.isNone(id) || !_this.store.hasRecordForId('location', id)) {
            return;
          }

          var warehouse = _this.store.peekRecord('location', id);

          query.warehouses.push(Ember.get(warehouse, 'name'));
        });
      }

      return this.store.query('listing', query);
    },

    /**
     * Extracts meta data from response and handles reset transitions.
     *
     * @public
     * @method afterModel
     * @param {Array.[]} model
     * @param {Object} transition.
     * @return {Promise}
     */
    afterModel: function afterModel(model, transition) {
      var meta = model.get('meta');
      var controller = this.controllerFor(Ember.get(this, 'routeName')); // Forward query parameter to controller AFTER transitions

      Ember.run.scheduleOnce('routerTransitions', this, function () {
        var pageSize = Ember.get(transition, 'queryParams.pageSize');

        if (!Ember.isNone(pageSize)) {
          controller.set('pageSize', pageSize);
        }
      }); // Get max pages from header and update eventually

      if (meta) {
        var maxPages = meta.maxPages;
        controller.set('maxPages', maxPages);
        controller.set('totalListings', meta.totalItems);
      } else {
        controller.set('maxPages', 1);
        controller.set('totalListings', model.length);
      }

      var page = parseInt(Ember.get(transition, 'queryParams.page'));

      if (!isNaN(page) && page > 1 && model.get('length') === 0) {
        this.transitionTo({
          queryParams: {
            page: 1
          }
        });
      }

      return Ember.get(this, 'ajax').request('/ratingSummary', {
        data: {
          company: Ember.get(this, 'userSession.currentCompany.id'),
          listings: model.mapBy('id')
        },
        contentType: 'application/json; charset=utf-8'
      }).then(function (items) {
        items.forEach(function (item) {
          // note: ratings may not contain a key for a
          // non existing rating category so set them!
          // if we need data on other places, move to
          // service for abstracting the uglyness away
          item.ratings.SO_SO = item.ratings.SO_SO || 0;
          item.ratings.GOOD = item.ratings.GOOD || 0;
          item.ratings.BAD = item.ratings.BAD || 0;
          var key = "ratingsMap.".concat(item.listing);
          Ember.set(controller, key, item.ratings);
        });
      });
    },
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Adjusts `filterStatus` property and resets page to 1.
       *
       * @event changeFilterStatus
       * @param {Array} status
       * @private
       */
      changeFilterStatus: function changeFilterStatus(status) {
        var controller = this.controllerFor(Ember.get(this, 'routeName'));
        controller.set('filterStatus', status);
        controller.set('page', 1);
        controller.resetSelection();
      },

      /**
       * Adjusts `filterOrigins` property and resets page to 1.
       *
       * @event changeFilterOrigins
       * @param {Array} val
       * @private
       */
      changeFilterOrigins: function changeFilterOrigins(origins) {
        var controller = this.controllerFor(Ember.get(this, 'routeName'));
        controller.set('filterOrigins', origins);
        controller.set('page', 1);
        controller.resetSelection();
      },

      /**
       * Adjusts `filterWarehouses` property and resets page to 1.
       *
       * @event changeFilterWarehouses
       * @param {Array} val
       * @private
       */
      changeFilterWarehouses: function changeFilterWarehouses(warehouses) {
        var controller = this.controllerFor(Ember.get(this, 'routeName'));
        controller.set('filterWarehouses', warehouses);
        controller.set('page', 1);
        controller.resetSelection();
      },

      /**
       * Adjusts `selectedShopId` property and resets page to 1.
       *
       * @event changeSelectedShop
       * @param {String} val
       * @private
       */
      changeSelectedShop: function changeSelectedShop(val) {
        var controller = this.controllerFor(Ember.get(this, 'routeName'));
        controller.set('selectedShopId', val);
        controller.set('page', 1);
        controller.resetSelection();
      },

      /**
       * Adjusts `selectedAuctionId` property and resets page to 1.
       *
       * @event changeSelectedAuction
       * @param {String} val
       * @private
       */
      changeSelectedAuction: function changeSelectedAuction(val) {
        var controller = this.controllerFor(Ember.get(this, 'routeName'));
        controller.set('selectedAuctionId', val);
        controller.set('page', 1);
        controller.resetSelection();
      },

      /**
       * Adjusts `showArchived` property and resets page to 1.
       *
       * @event changeShowArchived
       * @param {String} val
       * @private
       */
      changeShowArchived: function changeShowArchived(val) {
        var controller = this.controllerFor(Ember.get(this, 'routeName'));
        controller.set('showArchived', val);
        controller.set('page', 1);
        controller.resetSelection();
      },

      /**
       * Adjusts `showRatings` property and resets page to 1.
       *
       * @event changeShowRatings
       * @param {String} val
       * @private
       */
      changeShowRatings: function changeShowRatings(val) {
        var controller = this.controllerFor(Ember.get(this, 'routeName'));
        controller.set('showRatings', val);
        controller.set('page', 1);
        controller.resetSelection();
      },

      /**
       * Adjusts `searchTerm` property and resets page to 1.
       *
       * @event changeSearchTerm
       * @param {String} val
       * @private
       */
      changeSearchTerm: function changeSearchTerm(val) {
        var controller = this.controllerFor(Ember.get(this, 'routeName'));
        controller.set('searchTerm', val);
        controller.set('page', 1);
        controller.resetSelection();
      },

      /**
       * Adjusts `salesNumber` property and resets page to 1.
       *
       * @event changeSalesNumber
       * @param {String} val
       * @private
       */
      changeSalesNumber: function changeSalesNumber(val) {
        var controller = this.controllerFor(Ember.get(this, 'routeName'));
        controller.set('salesNumber', val);
        controller.set('page', 1);
        controller.resetSelection();
      },

      /**
       * Switches to given page and resets scroll position.
       *
       * @event changePageSize
       * @param {Number} pageSize
       * @private
       */
      changePageSize: function changePageSize(pageSize) {
        var storage = Ember.get(this, 'localStorage');
        var key = Ember.get(this, 'storageKey');
        storage.setItem(key, {
          pageSize: pageSize
        });
      }
    }
  });

  _exports.default = _default;
});