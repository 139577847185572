define("@vollersgroup/hub-frontend/services/wishlist", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _this = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    userSession: Ember.inject.service(),
    currentUserId: Ember.computed.alias('userSession.currentUser.id'),
    wishlistItems: Ember.computed('currentUserId', 'store', function () {
      if (!Ember.get(_this, 'currentUserId')) {
        return [];
      }

      return Ember.get(_this, 'store').query('wishlist', {
        user: Ember.get(_this, 'currentUserId')
      });
    }),
    removeFromWishlist: function removeFromWishlist() {},
    addToWishlist: function addToWishlist(_listing) {},
    getWishlistedListings: function getWishlistedListings() {}
  });

  _exports.default = _default;
});