define("@vollersgroup/hub-frontend/components/filter-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y2YYenp2",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"h3\"],[11,\"class\",\"filter__box__title\"],[11,\"tabindex\",\"1\"],[9],[0,\"\\n\"],[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"get-constant-label\",[[23,[\"filter\",\"parameterName\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[27,\"eq\",[[23,[\"filter\",\"parameterName\"]],\"expressCoffee\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"info__tooltip info__tooltip--right info__tooltip__multi-line margin-right-1\"],[12,\"data-info\",[27,\"t\",[\"You can buy these coffees directly with credit card.\"],null]],[9],[0,\"\\n         \"],[7,\"img\"],[11,\"src\",\"/assets/img/icons/credit-card.svg\"],[11,\"alt\",\"Credit Card Icon\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[3,\"action\",[[22,0,[]],\"toggle\"]],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"filter__box__content\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"filter\",\"parameterName\"]],\"warehouses\"],null]],null,{\"statements\":[[0,\"    \"],[1,[27,\"component\",[[23,[\"subComponent\"]]],[[\"displayItems\",\"isLoading\",\"filter\",\"update\"],[7,[23,[\"isLoading\"]],[27,\"mut\",[[23,[\"filter\"]]],null],[27,\"action\",[[22,0,[]],\"update\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[27,\"component\",[[23,[\"subComponent\"]]],[[\"isLoading\",\"filter\",\"update\"],[[23,[\"isLoading\"]],[27,\"mut\",[[23,[\"filter\"]]],null],[27,\"action\",[[22,0,[]],\"update\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/filter-box/template.hbs"
    }
  });

  _exports.default = _default;
});