define("@vollersgroup/hub-frontend/location/serializer", ["exports", "@vollersgroup/hub-frontend/application/serializer"], function (_exports, _serializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _serializer.default.extend({
    attrs: {
      subregion: 'subRegion'
    },
    normalize: function normalize(type, json, property) {
      var website = json.website;

      if (website && !website.match(/^https?:\/\//)) {
        json.website = 'http://' + website;
      }

      return this._super(type, json, property);
    }
  });

  _exports.default = _default;
});