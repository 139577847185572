define("@vollersgroup/hub-frontend/admin/manage/bulk/producer-edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ndJIhtBf",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"page-title\",[[27,\"t\",[\"Edit multiple producers\"],null]],null],false],[0,\"\\n\\n\"],[7,\"section\"],[11,\"class\",\"section padding-bottom-4\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"wrapper margin-bottom-3\"],[9],[0,\"\\n    \"],[7,\"h1\"],[11,\"class\",\"font-size-3\"],[9],[1,[27,\"t\",[\"Edit multiple producers\"],null],false],[10],[0,\"\\n    \"],[7,\"hr\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[1,[27,\"bulk-workflow\",null,[[\"isEdit\",\"modelName\",\"saveState\",\"onSave\",\"onFinish\",\"availableFields\",\"modelTranslations\",\"handsontableInput\",\"handsontableOutput\",\"handsontableSettings\"],[true,[23,[\"modelName\"]],[23,[\"saveState\"]],[27,\"action\",[[22,0,[]],\"save\"],null],[27,\"action\",[[22,0,[]],\"finish\"],null],[23,[\"availableFields\"]],[23,[\"modelTranslations\"]],[23,[\"handsontableInput\"]],[23,[\"handsontableOutput\"]],[23,[\"handsontableSettings\"]]]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/admin/manage/bulk/producer-edit/template.hbs"
    }
  });

  _exports.default = _default;
});