define("@vollersgroup/hub-frontend/components/shop-slug-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tkeZDi2l",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\"],[12,\"class\",[28,[\"label__standard \",[27,\"if\",[[23,[\"hasError\"]],\"label--has-error\"],null]]]],[11,\"for\",\"shop_slug\"],[9],[0,\"\\n  \"],[1,[27,\"t\",[\"URL\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"isNew\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"input__info\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"Please be thoughtful when choosing your shop URL as this cannot be changed later.\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"input__group\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"input__label--left\"],[9],[0,\"\\n      \"],[7,\"label\"],[11,\"for\",\"shop_slug\"],[9],[1,[21,\"slugBaseUrl\"],false],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[1,[27,\"c-input\",null,[[\"class\",\"hasError\",\"placeholder\",\"id\",\"value\"],[\"input__txt input__full input-with-label input-with-label--left js-shop-slug-input\",[23,[\"hasError\"]],[27,\"t\",[\"Enter your shop url here\"],null],\"shop_slug\",[23,[\"value\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"hasError\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"input__error-msg\"],[9],[0,\"\\n      \"],[1,[27,\"get\",[[27,\"get\",[[27,\"get\",[[27,\"get\",[[23,[\"model\"]],\"validations\"],null],\"attrs\"],null],[23,[\"valuePath\"]]],null],\"message\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[27,\"c-input\",null,[[\"class\",\"id\",\"disabled\",\"value\"],[\"input__txt input__full js-shop-slug-input\",\"shop_slug\",true,[27,\"concat\",[[23,[\"slugBaseUrl\"]],[23,[\"value\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/shop-slug-input/template.hbs"
    }
  });

  _exports.default = _default;
});