define("@vollersgroup/hub-frontend/components/filter-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The base filter-item from which other filter items extend. This is an abstract component and should not be used on its own!
   *
   * @namespace Component
   * @class FilterItem
   * @abstract
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Properties

    /**
     * @property classNames
     * @type Array
     * @final
     * @private
     */
    classNames: ['filter__item'],

    /**
     * Unique identifier composed from ember's
     * elementId property and filter's name.
     *
     * @property id
     * @type String
     * @final
     * @private
     */
    id: Ember.computed('filter.parameterName', 'elementId', function () {
      var filterName = Ember.get(this, 'filter.parameterName');
      var elementId = Ember.get(this, 'elementId');
      return "".concat(elementId, "_").concat(filterName);
    }),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * Filter represents model.
     *
     * @attribute filter
     * @type {Object}
     */
    filter: null,
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Propagates item to parent action
       * by calling `update` closure method.
       *
       * @event update
       * @param {Object} item Updated filter item.
       * @private
       */
      update: function update(item) {
        try {
          this.attrs.update(item);
        } catch (e) {
          /* no action */
        }
      }
    }
  });

  _exports.default = _default;
});