define("@vollersgroup/hub-frontend/admin/manage/bulk/listing-edit/controller", ["exports", "@vollersgroup/hub-frontend/admin/manage/bulk/bulk-listing-controller"], function (_exports, _bulkListingController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Bulk listing controller for edit.
   *
   * @namespace Controller
   * @class BulkListingEditController
   * @extends BulkListingController
   */
  var _default = _bulkListingController.default.extend({
    // -------------------------------------------------------------------------
    // Properties

    /**
     * @property isEdit
     */
    isEdit: true,

    /**
     * Caches shop id from related shops to
     * determine whether or not collections
     * belong to one or multiple shops.
     *
     * @property _cachedShopId
     * @type {String}
     * @private
     */
    _cachedShopId: null,
    // -------------------------------------------------------------------------
    // Methods

    /**
     * @method reset
     */
    reset: function reset() {
      this._super.apply(this, arguments);

      Ember.set(this, '_cachedShopId', null);
    },

    /**
     * @method mapModelsToData_
     */
    mapModelsToData_: function mapModelsToData_()
    /*models*/
    {
      var data = this._super.apply(this, arguments); // check if listings belong to same shop,
      // in this case we can set `hiddenShopId`
      // in order to show save to an auction


      var shopId = Ember.get(this, '_cachedShopId');

      if (shopId !== false) {
        Ember.set(this, 'hiddenShopId', shopId);
      }

      return data;
    },

    /**
     * @method transformToData_
     */
    transformToData_: function transformToData_(model
    /*, fields*/
    ) {
      // update `shopId` - if there are multiple
      // shop ids within collection, set to false
      var id = Ember.get(this, '_cachedShopId');
      var newId = Ember.get(model, 'shop.id');
      id = Ember.isNone(id) ? newId : id;
      id = id === newId ? newId : false;
      Ember.set(this, '_cachedShopId', id);
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});