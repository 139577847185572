define("@vollersgroup/hub-frontend/components/user-invitation-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xOt1cCM+",
    "block": "{\"symbols\":[\"invitation\"],\"statements\":[[4,\"if\",[[23,[\"openUserInvitations\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"margin-bottom-4\"],[9],[0,\"\\n    \"],[7,\"h2\"],[11,\"class\",\"font-size-4\"],[9],[1,[27,\"t\",[\"Open invitations\"],null],false],[10],[0,\"\\n    \"],[7,\"table\"],[11,\"class\",\"table table--striped table__block--responsive\"],[11,\"data-name\",\"user-invitation-list\"],[9],[0,\"\\n      \"],[7,\"thead\"],[11,\"class\",\"table__header\"],[9],[0,\"\\n      \"],[7,\"tr\"],[9],[0,\"\\n        \"],[7,\"th\"],[9],[1,[27,\"t\",[\"Email\"],null],false],[10],[0,\"\\n        \"],[7,\"th\"],[9],[1,[27,\"t\",[\"Sent on\"],null],false],[10],[0,\"\\n        \"],[7,\"th\"],[9],[1,[27,\"t\",[\"Expires on\"],null],false],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"openUserInvitationsMapped\"]]],null,{\"statements\":[[0,\"        \"],[7,\"tr\"],[9],[0,\"\\n          \"],[7,\"td\"],[11,\"data-field\",\"email\"],[9],[1,[22,1,[\"email\"]],false],[10],[0,\"\\n          \"],[7,\"td\"],[11,\"data-field\",\"created\"],[9],[1,[27,\"moment-format\",[[22,1,[\"createdDate\"]],[23,[\"userSession\",\"dateFormat\"]]],null],false],[10],[0,\"\\n          \"],[7,\"td\"],[11,\"data-field\",\"expired\"],[9],[1,[27,\"moment-format\",[[22,1,[\"expiredDate\"]],[23,[\"userSession\",\"dateFormat\"]]],null],false],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/user-invitation-list/template.hbs"
    }
  });

  _exports.default = _default;
});