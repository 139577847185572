define("@vollersgroup/hub-frontend/admin/manage/listing/create/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "@vollersgroup/hub-frontend/mixins/reset-scroll", "ember-can", "moment", "@vollersgroup/hub-frontend/utils/get-first-adapter-error-message"], function (_exports, _authenticatedRouteMixin, _resetScroll, _emberCan, _moment, _getFirstAdapterErrorMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _resetScroll.default, _emberCan.CanMixin, {
    // -------------------------------------------------------------------------
    // Dependencies
    validator: Ember.inject.service(),
    userSession: Ember.inject.service(),
    availableData: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Renders templates for admin route manually.
     * Per default, it renders secondary nav items
     * from manage pod to `secondary-nav` outlet.
     *
     * @public
     * @method renderTemplate
     * @return {Void}
     */
    renderTemplate: function renderTemplate() {
      this.controller.set('navbar', {
        route: 'admin.manage.overview.index',
        title: Ember.get(this, 'l10n').t('Create new coffee')
      });
      this.render();
      this.render('admin/manage/navbar-subpage', {
        outlet: 'secondary-nav',
        into: 'admin'
      });
    },

    /**
     * Checks if user allowed to create listings
     * and returns available shops if permitted.
     *
     * @public
     * @method deactivate
     * @return {Void}
     */
    beforeModel: function beforeModel() {
      if (!this.can('create listing')) {
        this.transitionTo('admin.manage.overview.index');
        return;
      }

      return Ember.get(this, 'availableData.shops');
    },

    /**
     * Create a new listing object.
     *
     * @public
     * @method model
     * @returns {Model.Listing}
     */
    model: function model() {
      var hasActiveShop = Ember.get(this, 'availableData.activeShops.length') > 0;

      if (!hasActiveShop) {
        this.transitionTo('admin.manage.overview.index');
        return;
      }

      var listing = this.store.createRecord('listing', {
        createdDate: (0, _moment.default)(),
        createdBy: Ember.get(this, 'userSession.currentUser'),
        company: Ember.get(this, 'userSession.currentCompany'),
        status: 'DRAFT'
      });
      var hasOneActiveShop = Ember.get(this, 'availableData.activeShops.length') === 1;

      if (hasOneActiveShop) {
        var shop = Ember.get(this, 'availableData.activeShops.firstObject');
        listing.setProperties({
          shop: shop,
          shopId: shop.get('id'),
          shopName: shop.get('name'),
          priceCurrency: shop.get('currency')
        });
      }

      return {
        listing: listing,
        step: hasOneActiveShop ? // skip shop selection
        1 : // Go to the shop selection
        0
      };
    },

    /**
     * Resets route's model and controller step.
     *
     * @public
     * @method deactivate
     * @return {Void}
     */
    deactivate: function deactivate() {
      var model = Ember.get(this, 'currentModel.listing');
      model.rollbackAttributes();

      if (model.get('isDestroyed')) {
        model.save();
      }
    },

    /**
     * Changes to provided step.
     *
     * @private
     * @method _goToStep
     * @param {Number} step
     * @return {Void}
     */
    _gotoStep: function _gotoStep(step) {
      Ember.set(this, 'currentModel.step', step);
      window.scrollTo(0, 0);
    },
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Select a shop for this listing.
       *
       * @event selectShop
       * @param Model.Shop shop
       * @private
       */
      selectShop: function selectShop(shop) {
        var model = Ember.get(this, 'currentModel.listing');
        model.setProperties({
          shop: shop,
          shopId: Ember.get(shop, 'id'),
          shopName: Ember.get(shop, 'name'),
          priceCurrency: Ember.get(shop, 'currency')
        });

        this._gotoStep(1);
      },

      /**
       * When the listing information has been entered/updated.
       *
       * @event createListing
       * @param Model.Listing listing
       * @private
       */
      createListing: function createListing(listing) {
        Ember.set(this, 'currentModel.listing', listing);

        this._gotoStep(2);
      },

      /**
       * Select a producer for this listing.
       *
       * @event selectProducer
       * @param Model.Producer producer
       * @private
       */
      selectProducer: function selectProducer(producer) {
        var _this = this;

        var model = Ember.get(this, 'currentModel.listing');
        model.setProperties({
          producer: producer,
          createdDate: (0, _moment.default)(),
          lastModifiedDate: (0, _moment.default)(),
          country: Ember.get(producer, 'location.country')
        });

        var successCallback = function successCallback() {
          _this._gotoStep(3);
        };

        var failureCallback = function failureCallback(adapterError) {
          var serverErrorMessage = (0, _getFirstAdapterErrorMessage.default)(adapterError);
          var errorMessage = Ember.get(_this, 'l10n').t('An error occurred while trying to save the listing: {{serverErrorMessage}}', {
            serverErrorMessage: serverErrorMessage
          });
          Ember.get(_this, 'flashMessages').addError(errorMessage);

          _this._gotoStep(1);
        };

        model.save().then(successCallback, failureCallback);
      },

      /**
       * Finish the creation process.
       *
       * @event finishCreation
       * @param {Boolean} createAnother If another coffee should be created
       * @private
       */
      finishCreation: function finishCreation() {
        var _this2 = this;

        var createAnother = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        var model = Ember.get(this, 'currentModel.listing');

        if (createAnother) {
          this._gotoStep(0);

          this.refresh();
          return;
        }

        Ember.get(model, 'auction').then(function (auction) {
          _this2.transitionTo('admin.manage.overview.index', {
            queryParams: {
              selectedAuctionId: !Ember.isNone(auction) ? Ember.get(auction, 'id') : ''
            }
          });
        });
      },

      /**
       * Move to another step.
       *
       * @event gotoStep
       * @param {Number} newStep
       * @private
       */
      gotoStep: function gotoStep(newStep) {
        if (newStep > Ember.get(this, 'currentModel.step')) {
          Ember.get(this, 'toast').warning(Ember.get(this, 'l10n').t('You have to save your changes!'));
          return;
        }

        this._gotoStep(newStep);
      }
    }
  });

  _exports.default = _default;
});