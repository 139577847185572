define("@vollersgroup/hub-frontend/admin/wishlist/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xCM94hR3",
    "block": "{\"symbols\":[\"wishlistItem\"],\"statements\":[[1,[27,\"page-title\",[[27,\"t\",[\"Wish list\"],null]],null],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"wrapper padding-bottom-4\"],[9],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"flex justify-between margin-bottom-2\"],[9],[0,\"\\n    \"],[7,\"h1\"],[11,\"class\",\"font-size-3\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"Wish list\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[4,\"each\",[[23,[\"model\"]]],null,{\"statements\":[[4,\"listing-list-item\",null,[[\"listing\"],[[22,1,[\"listing\"]]]],{\"statements\":[[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"margin-top-3 listing__item__remove-from-wishlist-button\"],[9],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"button button--plain color-grey-2\"],[9],[0,\"\\n          \"],[1,[27,\"t\",[\"Remove from wish list\"],null],false],[0,\"\\n        \"],[3,\"action\",[[22,0,[]],[27,\"action\",[[22,0,[]],\"removeFromWishlist\",[22,1,[]]],null]],[[\"bubbles\"],[false]]],[10],[0,\"\\n      \"],[10],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"margin-top-1 listing__item__rating\"],[9],[0,\"\\n        \"],[1,[27,\"listing-ratings\",null,[[\"rating\"],[[22,1,[\"listing\",\"rating\"]]]]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},{\"statements\":[[4,\"info-message\",null,[[\"type\",\"class\"],[\"INFO\",\"margin-bottom-2\"]],{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"You have no coffees on your wish list yet. Go to the store to configure and add your first coffee to your wish list.\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"div\"],[11,\"class\",\"margin-top-3\"],[9],[0,\"\\n      \"],[4,\"link-to\",[\"store\"],[[\"class\"],[\"button\"]],{\"statements\":[[1,[27,\"t\",[\"Go to store\"],null],false]],\"parameters\":[]},null],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/admin/wishlist/template.hbs"
    }
  });

  _exports.default = _default;
});