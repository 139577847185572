define("@vollersgroup/hub-frontend/initializers/feature-detection", ["exports", "@vollersgroup/hub-frontend/utils/is-mobile"], function (_exports, _isMobile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    var ios = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    if (ios) {
      Ember.$('html').addClass('ios');
    }

    var browser = Ember.Object.extend({
      isMobile: (0, _isMobile.default)()
    });
    application.register('feature-detection:main', browser);
    application.inject('component', 'browser', 'feature-detection:main');
  }

  var _default = {
    name: 'feature-detection',
    initialize: initialize
  };
  _exports.default = _default;
});