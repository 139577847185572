define("@vollersgroup/hub-frontend/admin/orders/index/controller", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PromiseArray = _emberData.default.PromiseArray;

  var _default = Ember.Controller.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    userSession: Ember.inject.service(),
    availableData: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Query parameters for route.
     *
     * @property queryParams
     * @type Array
     * @public
     */
    queryParams: ['filterStatus', 'selectedCompanyId', 'fromDate', 'toDate', 'page', 'pageSize', 'myDate'],
    page: 1,
    pageSize: 10,
    toDate: null,
    fromDate: null,
    filterStatus: Ember.computed(function () {
      return [];
    }),
    selectedCompanyId: null,

    /**
     * Current date format from user.
     *
     * @property dateFormat
     * @type {String}
     * @public
     */
    dateFormat: Ember.computed('userSession.dateFormat', {
      get: function get() {
        return Ember.get(this, 'userSession.dateFormat');
      }
    }),

    /**
     * Status options excluding `CART`.
     *
     * @property filterStatusOptions
     * @type {Array}
     * @public
     */
    filterStatusOptions: Ember.computed.filter('availableData.orderStates', function (orderState) {
      return orderState.value !== 'CART';
    }),

    /**
     * All available seller companys from all orders.
     *
     * @property availableCompanies
     * @type {Promise}
     * @public
     */
    availableCompanies: Ember.computed('store', 'userSession.currentCompany.id', {
      get: function get() {
        var _this = this;

        var orders = new Ember.RSVP.Promise(function (resolve) {
          _this.store.query('order', {
            buyerCompany: Ember.get(_this, 'userSession.currentCompany.id')
          }).then(resolve);
        });
        var promise = new Ember.RSVP.Promise(function (resolve) {
          orders.then(function (orders) {
            var companies = [];
            var promises = [];
            orders.forEach(function (order) {
              promises.push(order.get('sellerCompany').then(function (company) {
                return companies.addObject(company);
              }));
            });
            Ember.RSVP.Promise.all(promises).then(function () {
              resolve(companies);
            });
          });
        });
        return PromiseArray.create({
          promise: promise
        });
      }
    }),

    /**
     * Maximum possible date for `fromDate` = `toDate`.
     *
     * @property fromDateMax
     * @type {Date}
     * @public
     */
    fromDateMax: Ember.computed('toDate', {
      get: function get() {
        var toDate = Ember.get(this, 'toDate');

        if (Ember.isNone(toDate)) {
          return new Date();
        }

        return new Date(+toDate);
      }
    }),

    /**
     * Minimum required date for `toDate` = `fromDate`.
     *
     * @property availableCompanies
     * @type {Date}
     * @public
     */
    toDateMin: Ember.computed('fromDate', {
      get: function get() {
        var fromDate = Ember.get(this, 'fromDate');

        if (Ember.isNone(fromDate)) {
          return new Date();
        }

        return new Date(+fromDate);
      }
    }),

    /**
     * Maximum possible date for `toDate` = today.
     *
     * @property availableCompanies
     * @type {Date}
     * @public
     */
    toDateMax: Ember.computed({
      get: function get() {
        return new Date();
      }
    }),
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Sets `fromDate` to the beginning of the day.
       *
       * @event setFromDate
       * @returns void
       * @private
       */
      setFromDate: function setFromDate(fromDate) {
        if (fromDate) {
          fromDate = +fromDate.startOf('day');
        }

        Ember.set(this, 'fromDate', fromDate);
        this.send('resetPage');
      },

      /**
       * Sets `toDate` to the end of the day.
       *
       * @event setFromDate
       * @returns void
       * @private
       */
      setToDate: function setToDate(toDate) {
        if (toDate) {
          toDate = +toDate.endOf('day');
        }

        Ember.set(this, 'toDate', toDate);
        this.send('resetPage');
      },

      /**
       * Sets `page` to initial value of 1.
       *
       * @event resetPage
       * @returns void
       * @private
       */
      resetPage: function resetPage() {
        Ember.set(this, 'page', 1);
      }
    }
  });

  _exports.default = _default;
});