define("@vollersgroup/hub-frontend/components/filter-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tYIrhMwk",
    "block": "{\"symbols\":[\"filter\",\"name\",\"items\",\"item\",\"id\"],\"statements\":[[7,\"ul\"],[12,\"class\",[28,[\"filter__item__tag-list \",[27,\"if\",[[23,[\"search\",\"isLoading\"]],\"filter__item__tag-list--loading\"],null]]]],[9],[0,\"\\n\"],[4,\"each\",[[27,\"-each-in\",[[23,[\"filters\"]]],null]],null,{\"statements\":[[4,\"unless\",[[27,\"eq\",[[22,1,[\"parameterType\"]],\"RANGE\"],null]],null,{\"statements\":[[4,\"each\",[[27,\"-each-in\",[[22,1,[\"items\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[11,\"tabindex\",\"1\"],[9],[0,\"\\n        \"],[1,[27,\"get-constant-label\",[[22,2,[]]],null],false],[0,\": \"],[1,[27,\"get-constant-label\",[[22,4,[\"name\"]]],null],false],[0,\"\\n      \"],[3,\"action\",[[22,0,[]],\"remove\",[22,1,[]],[22,4,[]]]],[10],[0,\"\\n\"]],\"parameters\":[4,5]},null]],\"parameters\":[]},{\"statements\":[[4,\"with\",[[22,1,[\"items\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[11,\"tabindex\",\"1\"],[9],[0,\"\\n            \"],[1,[27,\"get-constant-label\",[[22,2,[]]],null],false],[0,\":\\n\"],[4,\"if\",[[27,\"and\",[[22,3,[\"min\"]],[22,3,[\"max\"]]],null]],null,{\"statements\":[[0,\"              \"],[1,[22,3,[\"min\",\"id\"]],false],[0,\" – \"],[1,[22,3,[\"max\",\"id\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[27,\"and\",[[22,3,[\"min\"]],[27,\"not\",[[22,3,[\"max\"]]],null]],null]],null,{\"statements\":[[0,\"              >= \"],[1,[22,3,[\"min\",\"id\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[27,\"and\",[[27,\"not\",[[22,3,[\"min\"]]],null],[22,3,[\"max\"]]],null]],null,{\"statements\":[[0,\"              <= \"],[1,[22,3,[\"max\",\"id\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"            \"],[1,[22,3,[\"unit\",\"id\"]],false],[0,\"\\n        \"],[3,\"action\",[[22,0,[]],\"remove\",[22,1,[]]]],[10],[0,\"\\n\"]],\"parameters\":[3]},null]],\"parameters\":[]}]],\"parameters\":[1,2]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/filter-display/template.hbs"
    }
  });

  _exports.default = _default;
});