define("@vollersgroup/hub-frontend/components/header-bar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import moment from 'moment';

  /**
   * The header. Contains the search bar and the main navigation.
   *
   * ```html
   * {{header-bar}}
   * ```
   *
   * @namespace Component
   * @class HeaderBar
   */
  var _default = Ember.Component.extend({
    tagName: '',
    // -------------------------------------------------------------------------
    // Dependencies
    router: Ember.inject.service(),
    l10n: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Store's index route injected by initializer.
     *
     * @property routes.indexRoute
     * @type Ember.Route
     * @default null
     * @public
     */
    indexRoute: Ember.computed(function () {
      // note: ember sets `routeName` only on actual visits!
      var route = Ember.getOwner(this).lookup('route:store.index');
      Ember.set(route, 'routeName', 'store.index');
      return route;
    }),

    /**
     * Store's shops route injected by initializer.
     *
     * @property routes.shopsRoute
     * @type Ember.Route
     * @default null
     * @public
     */
    shopsRoute: Ember.computed(function () {
      // note: ember sets `routeName` only on actual visits!
      var route = Ember.getOwner(this).lookup('route:store.shops');
      Ember.set(route, 'routeName', 'store.shops');
      return route;
    }),

    /**
     * Store's auctions route injected by initializer.
     *
     * @property routes.auctionsRoute
     * @type Ember.Route
     * @default null
     * @public
     */

    /*
    auctionsRoute: computed(function () {
      // note: ember sets `routeName` only on actual visits!
      let route = getOwner(this).lookup('route:store.auctions.index');
      set(route, 'routeName', 'store.auctions.index');
      return route;
    }),
    */

    /**
     * Prepared navigation items for `dropdown-select`.
     *
     * @property searchTerm
     * @type {Array}
     * @public
     */
    options: Ember.computed( // 'auctionsRoute',
    'indexRoute', 'l10n', 'router.currentRouteName', 'shopsRoute', function () {
      // let auctionsRoute = get(this, 'auctionsRoute');
      var shopsRoute = Ember.get(this, 'shopsRoute');
      var indexRoute = Ember.get(this, 'indexRoute');
      var router = Ember.get(this, 'router');

      if (this.router.currentRouteName.includes('store.loading')) {
        return [];
      }

      return [{
        route: indexRoute.routeName,
        label: Ember.get(this, 'l10n').t('All coffees'),
        selected: router.isActive(indexRoute.routeName),
        params: Ember.get(this, 'indexRoute').controllerFor(indexRoute.routeName)
      }, {
        route: shopsRoute.routeName,
        label: Ember.get(this, 'l10n').t('All shops'),
        selected: router.isActive(shopsRoute.routeName),
        params: Ember.get(this, 'shopsRoute').controllerFor(shopsRoute.routeName)
      }
      /*
      {
        route: auctionsRoute.routeName,
        label: this.get('l10n').t('All auctions'),
        selected: router.isActive(auctionsRoute.routeName),
        params: get(this, 'auctionsRoute').controllerFor(
          auctionsRoute.routeName
        )
      }*/
      ];
    }),

    /**
     * Computes corresponding placeholder depending on `searchType`.
     *
     * @property placeholder
     * @type String
     * @public
     */
    placeholder: Ember.computed('l10n', 'searchType', function () {
      switch (Ember.get(this, 'searchType')) {
        case 'SHOP':
          return Ember.get(this, 'l10n').t('Try "United States"');
        // case 'AUCTION':
        //   return get(this, 'l10n').t('Try "{{year}}"', {
        //     year: this._getLatestAuctionYear()
        //   });

        case 'LISTING':
          return Ember.get(this, 'l10n').t('Try "Chocolate"');

        default:
          return '';
      }
    }),

    /**
     * Current search type depending on route.
     * One of 'LISTING', 'SHOP' or 'AUCTION'.
     *
     * @property searchType
     * @type {String}
     * @default ''
     * @public
     */
    searchType: Ember.computed('_activeRoute.routeName', // 'auctionsRoute.routeName',
    'indexRoute.routeName', 'shopsRoute.routeName', function () {
      switch (Ember.get(this, '_activeRoute.routeName')) {
        // case get(this, 'auctionsRoute.routeName'):
        //   return 'AUCTION';
        case Ember.get(this, 'shopsRoute.routeName'):
          return 'SHOP';

        case Ember.get(this, 'indexRoute.routeName'):
          return 'LISTING';

        default:
          return '';
      }
    }),

    /**
     * Query parameters
     *
     * @property placeholder
     * @type String
     * @public
     */
    queryParams: Ember.computed('_searchTerm', function () {
      return {
        q: Ember.get(this, '_searchTerm')
      };
    }),

    /**
     * The currently active route being one of `index`, `shops` or `auctions`.
     *
     * @property _activeRoute
     * @type Ember.Route
     * @private
     */
    _activeRoute: Ember.computed( // 'auctionsRoute',
    'indexRoute', 'router.currentRouteName', 'shopsRoute', function () {
      // let auctionsRoute = get(this, 'auctionsRoute');
      var shopsRoute = Ember.get(this, 'shopsRoute');
      var indexRoute = Ember.get(this, 'indexRoute');

      switch (Ember.get(this, 'router.currentRouteName')) {
        // case auctionsRoute.routeName:
        //   return auctionsRoute;
        case shopsRoute.routeName:
          return shopsRoute;

        case indexRoute.routeName:
          return indexRoute;

        default:
          return null;
      }
    }),

    /**
     * Current search term for search bar from route params.
     *
     * @property _searchTerm
     * @type String
     * @default ''
     * @private
     */
    _searchTerm: Ember.computed('_activeRoute.controller.searchText', 'router.currentURL', function () {
      var _searchTerm = Ember.get(this, '_activeRoute.controller.searchText');

      return _searchTerm || '';
    }),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Tries to find the latest auction date from auction
     * page's model (containing both upcoming and closed).
     *
     * @private
     * @method _getLatestAuctionYear
     * @return {String}
     */

    /*
    _getLatestAuctionYear() {
      let route = get(this, 'auctionsRoute');
      let name = get(route, 'routeName');
      let model = route.modelFor(name);
      let latest;
       if (model) {
        let upcoming = get(model.upcoming, 'firstObject');
        let closed = get(model.closed, 'firstObject');
        latest = upcoming || closed;
      }
       return (!latest ? moment() : get(latest, 'startedDate')).format('YYYY');
    },
    */
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Reset the search.
       *
       * @event resetSearch
       * @private
       */
      reset: function reset() {
        this.send('update', '');
        this.send('search');
      },

      /**
       * Updates search term.
       *
       * @event resetSearch
       * @private
       */
      update: function update(value) {
        Ember.set(this, 'queryParams.q', value);
      },

      /**
       * Performs a search by navigating to corresponding page
       * with `searchTerm` as query depending on `searchType`.
       *
       * @event search
       * @private
       */
      search: function search() {
        var _activeRoute = Ember.get(this, '_activeRoute');

        var queryParams = Ember.get(this, 'queryParams');

        _activeRoute.transitionTo(_activeRoute.routeName, {
          queryParams: queryParams
        });
      }
    }
  });

  _exports.default = _default;
});