define("@vollersgroup/hub-frontend/components/listing-labels-print/component", ["exports", "@vollersgroup/hub-frontend/listing/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localStorage: Ember.inject.service(),
    keenTracking: Ember.inject.service(),
    l10n: Ember.inject.service(),
    classNames: ['width-a4'],
    // Attributes
    listings: null,
    localStorageKey: 'hub_listing_labels_print_settings',
    // Properties
    showCompanyLogo: true,
    repeatItems: false,
    repeatAmount: 3,
    selectedFields: Ember.computed('allSelectableFields', 'allSelectableFields.@each.selected', function () {
      return Ember.get(this, 'allSelectableFields').filterBy('selected');
    }),
    allSelectableFields: Ember.computed(function () {
      var fields = [];
      var attrs = Ember.get(_model.default, 'attributes');
      var relations = Ember.get(_model.default, 'relationshipsByName');

      var addToFields = function addToFields(attr) {
        if (!Ember.get(attr, 'options.printOptions.selectable')) {
          return;
        }

        var key = Ember.get(attr, 'key') || Ember.get(attr, 'name');

        if (key === 'name') {
          // Listing's name is displayed always. No need to show it as attribute.
          return;
        }

        var property = Ember.get(attr, 'property') || Ember.get(attr, 'options.printOptions.property');
        var selected = Ember.get(attr, 'selected') || Ember.get(attr, 'options.printOptions.selected');
        var label = Ember.get(attr, 'label') || Ember.get(attr, 'options.label');
        var value = Ember.isEmpty(property) ? key : "".concat(key, ".").concat(property);
        var field = {
          property: property,
          selected: selected,
          label: label,
          key: key,
          value: value
        };
        fields.push(field);
      };

      attrs.forEach(addToFields);
      relations.forEach(addToFields);
      return fields;
    }),
    fieldsMenuSort: Ember.computed(function () {
      return ['label:asc'];
    }),
    allSelectableFieldsSortedByLabel: Ember.computed.sort('allSelectableFields', 'fieldsMenuSort'),
    settings: Ember.computed('l10n', 'repeatItems', 'showCompanyLogo', function () {
      return [{
        value: 'repeatItems',
        selected: this.get('repeatItems'),
        label: this.get('l10n').t('Repeat items')
      }, {
        value: 'showCompanyLogo',
        selected: this.get('showCompanyLogo'),
        label: this.get('l10n').t('Show company logo')
      }];
    }),
    sortProperty: Ember.computed(function () {
      return ['name'];
    }),
    sortedListings: Ember.computed.sort('repeatedListings', 'sortProperty'),
    repeatedListings: Ember.computed('repeatItems', 'repeatAmount', 'listings.[]', function () {
      var listings = this.get('listings');
      var repeatItems = this.get('repeatItems');

      if (!repeatItems) {
        return listings;
      }

      var repeatAmount = this.get('repeatAmount') || 1;
      var repeatingListings = [];
      listings.forEach(function (listing) {
        for (var i = 0; i < repeatAmount; i++) {
          repeatingListings.push(listing);
        }
      });
      return repeatingListings;
    }),
    actions: {
      settingsChanged: function settingsChanged(setting) {
        var val = Ember.get(setting, 'selected');
        var key = Ember.get(setting, 'value');
        Ember.set(this, key, val);

        this._saveSettings();
      },
      saveSettings: function saveSettings() {
        this._saveSettings();
      },
      printSheet: function printSheet() {
        this._trackLabelPrint();

        window.print();
      }
    },
    init: function init() {
      this._super.apply(this, arguments);

      (false && !(Ember.get(this, 'listings')) && Ember.assert('listings property is required.', Ember.get(this, 'listings')));

      this._tryLoadSettings();
    },
    _trackLabelPrint: function _trackLabelPrint() {
      var selectedFieldsKeys = this.get('selectedFields').mapBy('key'); // Convert array to object for easier manipulation and searching in keen

      var selectedFieldsAsObject = selectedFieldsKeys.reduce(function (obj, value, index) {
        obj[value] = index;
        return obj;
      }, {});
      var data = {
        selectedFieldKeys: selectedFieldsAsObject,
        numberOfFields: selectedFieldsKeys.get('length'),
        showCompanyLogo: this.get('showCompanyLogo'),
        repeatAmount: this.get('repeatAmount'),
        repeatItems: this.get('repeatItems'),
        totalLabelsToPrint: Ember.get(this, 'repeatedListings.length'),
        uniqueLabelsToPrint: Ember.get(this, 'listings.length')
      };
      Ember.get(this, 'keenTracking').addEvent('print-labels-dialog-invoked', data);
    },
    _saveSettings: function _saveSettings() {
      var localStorageKey = this.get('localStorageKey');
      var localStorage = this.get('localStorage');
      localStorage.setItem(localStorageKey, {
        selectedFieldKeys: this.get('selectedFields').mapBy('key'),
        showCompanyLogo: this.get('showCompanyLogo'),
        repeatAmount: this.get('repeatAmount'),
        repeatItems: this.get('repeatItems')
      });
    },
    _tryLoadSettings: function _tryLoadSettings() {
      var localStorageKey = this.get('localStorageKey');
      var localStorage = this.get('localStorage');
      var settings = localStorage.getItem(localStorageKey);

      if (Ember.isNone(settings)) {
        return;
      }

      var showCompanyLogo = Ember.get(settings, 'showCompanyLogo');

      if (!Ember.isNone(showCompanyLogo)) {
        this.set('showCompanyLogo', showCompanyLogo);
      }

      var repeatItems = Ember.get(settings, 'repeatItems');

      if (!Ember.isNone(repeatItems)) {
        this.set('repeatItems', repeatItems);
      }

      var repeatAmount = Ember.get(settings, 'repeatAmount');

      if (!Ember.isNone(repeatAmount)) {
        this.set('repeatAmount', repeatAmount);
      }

      var selectedFieldKeys = Ember.get(settings, 'selectedFieldKeys');

      if (!Ember.isNone(selectedFieldKeys)) {
        var allSelectableFields = this.get('allSelectableFields');
        allSelectableFields.forEach(function (field) {
          if (selectedFieldKeys.includes(field.key)) {
            Ember.set(field, 'selected', true); // NOTE: add sortIndex to field as a temp value to easily
            // sort fields the way they are stored in local storage

            Ember.set(field, 'sortIndex', selectedFieldKeys.indexOf(field.key));
          } else {
            Ember.set(field, 'selected', false);
          }
        });
        Ember.set(this, 'allSelectableFields', allSelectableFields.sortBy('sortIndex'));
      }
    }
  });

  _exports.default = _default;
});