define("@vollersgroup/hub-frontend/about/legal/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ra83FILC",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"page-title\",[[27,\"t\",[\"Legal notice\"],null]],null],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"padding-bottom-4\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"text-center margin-bottom-4\"],[9],[0,\"\\n    \"],[7,\"h1\"],[11,\"class\",\"font-size-3 text-center\"],[9],[1,[27,\"t\",[\"Legal notice V-Hub\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"wrapper__main\"],[9],[7,\"h3\"],[9],[1,[27,\"t\",[\"Vollers Group GmbH\"],null],false],[10],[0,\"\\n\\n    \"],[7,\"p\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"Speicherhof 308<br>\\n            28217 Bremen<br>\\n            Germany<br><br>\\n            Phone: + 49 421 38 92 00<br>\\n            Fax: + 49 421 38 92 100<br>\\n            E-Mail: <a href=\\\"mailto:v-hub@vollers.com\\\" target=\\\"_blank\\\">v-hub@vollers.com</a><br>\\n            Internet: <a href=\\\"http://www.vollers.com\\\" target=\\\"_blank\\\">www.vollers.com</a>\"],null],true],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"p\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"Registered office: Bremen<br>\\n            Register court: Amtsgericht Bremen (Bremen District Court)<br><br>\\n            Company registration Number: HRB 30224 HB <br>\\n            VAT Registration number: DE 261 521 395 <br><br>\\n            Managing directors: Christian Vollers, Matijs Brand, Christian Brakmann, Philipp Wacker\"],null],true],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"h3\"],[9],[1,[27,\"t\",[\"Notice of Liability\"],null],false],[10],[0,\"\\n\\n    \"],[7,\"p\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"Responsible for the content in accordance with § 55 Par. 2 RStV is the managing director Christian Vollers (contact details as above).\"],null],false],[0,\".\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/about/legal/index/template.hbs"
    }
  });

  _exports.default = _default;
});