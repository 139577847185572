define("@vollersgroup/hub-frontend/components/navigation-steps/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eyx4tYhJ",
    "block": "{\"symbols\":[\"step\",\"key\",\"step\",\"index\"],\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"type\"]],\"array\"],null]],null,{\"statements\":[[4,\"each\",[[23,[\"steps\"]]],null,{\"statements\":[[0,\"    \"],[15,\"components/navigation-steps/step\",[3,4]],[0,\"\\n\"]],\"parameters\":[3,4]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"type\"]],\"object\"],null]],null,{\"statements\":[[4,\"each\",[[27,\"-each-in\",[[23,[\"steps\"]]],null]],null,{\"statements\":[[0,\"    \"],[15,\"components/navigation-steps/step\",[1,2]],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"parameters\":[]},null]],\"hasEval\":true}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/navigation-steps/template.hbs"
    }
  });

  _exports.default = _default;
});