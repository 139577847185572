define("@vollersgroup/hub-frontend/admin/manage/overview/shop/controller", ["exports", "@vollersgroup/hub-frontend/mixins/orderable", "@vollersgroup/hub-frontend/utils/get-first-adapter-error-message"], function (_exports, _orderable, _getFirstAdapterErrorMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_orderable.default, {
    // -------------------------------------------------------------------------
    // Dependencies
    l10n: Ember.inject.service(),
    userSession: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Publish a shop.
       * This sets the state to ACTIVE. This is only possible coming from DRAFT or INACTIVE.
       *
       * @event publishShop
       * @param Model.Shop shop
       * @private
       */
      publishShop: function publishShop(shop) {
        var _this = this;

        if (shop.get('status') === 'DRAFT' || shop.get('status') === 'INACTIVE') {
          shop.set('status', 'ACTIVE');
          shop.save().then(function () {
            _this.notifyPropertyChange('orderBy');
          });
          this.get('toast').success(this.get('l10n').t('Your shop is now active and visible to buyers.'));
        } else {
          this.get('toast').warning(this.get('l10n').t('You can only publish a draft or inactive shop.'));
        }
      },

      /**
       * Deactivate a shop.
       * This sets the state to INACTIVE. This is only possible coming from ACTIVE.
       *
       * @event deactivateShop
       * @param Model.Shop shop
       * @private
       */
      deactivateShop: function deactivateShop(shop) {
        var _this2 = this;

        if (shop.get('status') === 'ACTIVE') {
          shop.set('status', 'INACTIVE');
          shop.save().then(function () {
            _this2.notifyPropertyChange('orderBy');
          });
          this.get('toast').info(this.get('l10n').t('Your shop is now inactive. Only your company can see it.'));
        } else {
          this.get('toast').warning(this.get('l10n').t('You can only deactivate an active shop.'));
        }
      },

      /**
       * Delete a shop.
       * This actually deletes the shop. It is not recoverable. This is only possible coming from DRAFT.
       *
       * @event deleteShop
       * @param Model.Shop shop
       * @private
       */
      deleteShop: function deleteShop(shop) {
        var _this3 = this;

        if (shop.get('status') === 'DRAFT') {
          shop.destroyRecord().then(function () {
            _this3.notifyPropertyChange('orderBy');

            _this3.get('toast').info(_this3.get('l10n').t('Your shop has been DELETED.'));
          }).catch(function (adapterError) {
            shop.rollbackAttributes();
            var serverErrorMessage = (0, _getFirstAdapterErrorMessage.default)(adapterError);
            var errorMessage = Ember.get(_this3, 'l10n').t('An error occurred while deleting the shop: {{serverErrorMessage}}', {
              serverErrorMessage: serverErrorMessage
            });
            Ember.get(_this3, 'toast').error(errorMessage);
          });
        } else {
          this.get('toast').warning(this.get('l10n').t('You can only delete a shop that has not been published.'));
        }
      },

      /**
       * Archive a shop.
       * This sets the archived flag. This is only possible coming from INACTIVE.
       *
       * @event archiveShop
       * @param Model.Shop shop
       * @private
       */
      archiveShop: function archiveShop(shop) {
        var _this4 = this;

        if (shop.get('status') === 'INACTIVE') {
          shop.set('archived', true);
          shop.save().then(function () {
            _this4.notifyPropertyChange('orderBy');
          });
          this.get('toast').info(this.get('l10n').t('Your shop has been archived.'));
        } else {
          this.get('toast').warning(this.get('l10n').t('You can only archive an inactive shop.'));
        }
      },

      /**
       * Unarchive a shop.
       * This sets the archived flag. This is only possible coming from INACTIVE.
       *
       * @event archiveShop
       * @param Model.Shop shop
       * @private
       */
      unarchiveShop: function unarchiveShop(shop) {
        var _this5 = this;

        if (shop.get('status') === 'INACTIVE') {
          shop.set('archived', false);
          shop.save().then(function () {
            _this5.notifyPropertyChange('orderBy');
          });
          this.get('toast').info(this.get('l10n').t('Your shop has been unarchived.'));
        } else {
          this.get('toast').warning(this.get('l10n').t('You can only archive an inactive shop.'));
        }
      },
      testSetVerified: function testSetVerified(verified) {
        this.get('userSession.currentCompany').set('verificationStatus', verified);
      }
    },
    // -------------------------------------------------------------------------
    // Properties

    /**
     * All shops for this company.
     *
     * @property shops
     * @type Model.Shop[]
     */
    shops: Ember.computed('filterStatus.[]', 'orderBy', 'showArchived', 'store', 'userSession.currentCompany.id', function () {
      var query = {
        company: this.get('userSession.currentCompany.id'),
        sort: this.get('orderBy')
      };

      if (this.get('filterStatus') && this.get('filterStatus.length')) {
        query.status = this.get('filterStatus');
      } else {
        query.status = ['DRAFT', 'ACTIVE', 'INACTIVE'];
      }

      query.archived = this.get('showArchived');
      return this.store.query('shop', query);
    }),

    /**
     * The available options by which it is possible to order.
     *
     * @property orderByOptions
     * @type Array
     * @final
     */
    orderByOptions: Ember.computed('l10n', function () {
      return [{
        value: 'DATE',
        label: this.get('l10n').t('Most recent')
      }, {
        value: 'NAME',
        label: this.get('l10n').t('Name')
      }, {
        value: 'STATUS',
        label: this.get('l10n').t('Status')
      }];
    }),

    /**
     * If only archived shops should be displayed.
     *
     * @property showArchived
     * @type Boolean
     * @default false
     */
    showArchived: false,

    /**
     * The statuses that should be displayed. Multiple can be selected.
     *
     * @property filterStatus
     * @type Array
     * @default ["DRAFT", "ACTIVE"]
     */
    filterStatus: Ember.computed(function () {
      return [];
    }),

    /**
     * The available status to filter from.
     *
     * @property filterStatusOptions
     * @type Array
     * @final
     */
    filterStatusOptions: Ember.computed('l10n', function () {
      return [{
        value: 'DRAFT',
        label: this.get('l10n').t('Draft')
      }, {
        value: 'ACTIVE',
        label: this.get('l10n').t('Active')
      }, {
        value: 'INACTIVE',
        label: this.get('l10n').t('Inactive')
      }];
    }) // -------------------------------------------------------------------------
    // Methods

  });

  _exports.default = _default;
});