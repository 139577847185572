define("@vollersgroup/hub-frontend/components/login-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2oK5U58A",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-overlay\",null,[[\"title\",\"close\"],[[27,\"t\",[\"Log in\"],null],[23,[\"closeModal\"]]]],{\"statements\":[[4,\"login-form\",null,[[\"afterLogin\",\"gotoRoute\",\"gotoModel\"],[[23,[\"afterLogin\"]],[23,[\"gotoRoute\"]],[23,[\"gotoModel\"]]]],{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"register\",[27,\"query-params\",null,[[\"showLogin\",\"afterLogin\",\"gotoRoute\",\"gotoModel\"],[null,null,null,null]]]],[[\"class\"],[\"button button--plain\"]],{\"statements\":[[0,\"        \"],[1,[27,\"t\",[\"Create new V-Hub account\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/login-modal/template.hbs"
    }
  });

  _exports.default = _default;
});