define("@vollersgroup/hub-frontend/info-center/getting-started/offer-list-widget/controller", ["exports", "@vollersgroup/hub-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    keenTracking: Ember.inject.service(),
    apiURL: '/' + _environment.default.apiNamespace,
    baseURL: 'https://vll-prod-vhub-offerwidget.azureedge.net/index.html?shop=',
    iframeURL: Ember.computed('baseURL', 'shopSlug', function () {
      var shopSlug = Ember.get(this, 'shopSlug');
      var baseURL = Ember.get(this, 'baseURL');

      if (Ember.isBlank(shopSlug)) {
        return null;
      }

      return "".concat(baseURL).concat(shopSlug);
    }),
    shopSlug: Ember.computed.reads('model.firstObject.slug'),
    selected: Ember.computed.reads('shopSlug'),
    actions: {
      show: function show(shopSlug) {
        var _this = this;

        // track all demo trials with slug in keen
        var keenTracking = Ember.get(this, 'keenTracking');
        keenTracking.addEvent('offer-list-widget-demo', {
          slug: shopSlug
        }); // force rerendering of {{code-block}} item!

        Ember.set(this, 'shopSlug', null);
        Ember.run.next(function () {
          return Ember.set(_this, 'shopSlug', shopSlug);
        });
      },
      hide: function hide() {
        Ember.set(this, 'shopSlug', null); // undo selection of radio button group

        Ember.set(this, 'selected', '__UNKNOWN__');
      },
      reset: function reset(shopSlug) {
        if (!shopSlug) {
          this.send('hide');
        }
      }
    },
    examples: Ember.computed('model.[]', function () {
      return Ember.get(this, 'model').map(function (model) {
        return {
          value: Ember.get(model, 'slug'),
          label: Ember.get(model, 'name')
        };
      });
    })
  });

  _exports.default = _default;
});