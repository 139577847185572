define("@vollersgroup/hub-frontend/components/c-nested-tree/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A nested tree consisting of checkboxes. It's build on base of a (recursive) items structure.
   *
   * ```html
   * {{c-nested-tree id="my_tree" updateAction=(action "onItemUpdated" item) items=items labelProperty="label" valueProperty="value" activeProperty="isActive" openProperty="isOpen" filter=true activateParent=true deactivateParent=true activateChildren=true deactivateChildren=true toggleVisibility=true placeholder="Type to search"}}
   * ```
   *
   * @namespace Component
   * @class CNestedTree
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    l10n: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * The items for the tree. This has to be an array
     * of objects with a (recursive) structure like:
     *
     * ```js
     * [
     *  {
     *    label:"Label 1",
     *    value:"value1",
     *    isActive:true,
     *    isOpen:true,
     *    items:[
     *      {
     *        label:"Sublabel 1",
     *        value:"subvalue1",
     *        isActive:true,
     *        isOpen:false,
     *        items: [ ... ]
     *      },
     *      {
     *        label:"Sublabel 2",
     *        value:"subvalue2,
     *        isActive:false,
     *        isOpen:false,
     *        items: [ ... ]
     *      }
     *    ]
     *  },
     *  ...
     * ]
     * ```
     *
     * @attribute items
     * @required
     * @type Array
     */
    items: null,

    /**
     * Adjustable label property.
     *
     * @attribute labelProperty
     * @optional
     * @type String
     * @default "label"
     */
    labelProperty: 'label',

    /**
     * Adjustable value property.
     *
     * @attribute valueProperty
     * @optional
     * @type String
     * @default "value"
     */
    valueProperty: 'value',

    /**
     * Adjustable active property.
     *
     * @attribute activeProperty
     * @optional
     * @type String
     * @default "isActive"
     */
    activeProperty: 'isActive',

    /**
     * Adjustable open property.
     *
     * @attribute openProperty
     * @optional
     * @type String
     * @default "isOpen"
     */
    openProperty: 'isOpen',

    /**
     * An unique identifier for this component.
     *
     * @attribute id
     * @required
     * @type String
     * @default random
     */
    id: Math.random().toString(36).replace(/[^a-z]+/g, ''),

    /**
     * Shows/Hides children if parent is selected.
     *
     * @attribute toggleVisibility
     * @type Boolean
     * @default true
     */
    toggleVisibility: true,

    /**
     * Activates children if parent is selected.
     *
     * @attribute activateChildren
     * @type Boolean
     * @default true
     */
    activateChildren: true,

    /**
     * Deactivates children if parent is unselected.
     *
     * @attribute deactivateChildren
     * @type Boolean
     * @default true
     */
    deactivateChildren: true,

    /**
     * Activates parent if one child item is selected.
     *
     * @attribute activateParent
     * @type Boolean
     * @default true
     */
    activateParent: true,

    /**
     * Deactivates parent if no child items are selected.
     *
     * @attribute activateParent
     * @type Boolean
     * @default true
     */
    deactivateParent: true,

    /**
     * If the nested structure is filterable.
     *
     * @attribute filter
     * @type Boolean
     * @default true
     */
    filter: true,

    /**
     * Term to filter tree structure for.
     *
     * @attribute query
     * @type String
     * @default ""
     */
    query: '',

    /**
     * Placeholder text for search field.
     *
     * @attribute placeholder
     * @type String
     * @default "Type to search"
     */
    placeholder: Ember.computed('l10n', function () {
      return this.get('l10n').t('Type to search');
    }),

    /**
     * The action to call when an item is updated.
     *
     * @attribute updateAction
     * @type String
     * @optional
     * @public
     */
    updateAction: function updateAction() {},

    /**
     * Current hierarchy level. This is an internal
     * attribute only to calculate item's dom ids.
     *
     * @attribute level
     * @optional
     * @type Number
     * @default 1
     * @private
     */
    level: 1,

    /**
     * The action to call when a subitem is toggled.
     * This is an internal action only and should
     * not be used on the outer component element.
     *
     * @attribute parentAction
     * @type String
     * @optional
     * @private
     */
    parentAction: function parentAction() {},
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * @event toggle
       * @param {Object} item The toggled item
       * @private
       */
      toggle: function toggle(item) {
        var isOpen = this._get(item, 'isOpen');

        Ember.set(item, 'isOpen', !isOpen);
      },

      /**
       * @event update
       * @param {Object} item The clicked item
       * @param {Boolean} isActive Current state of clicked item
       * @private
       */
      update: function update(item, isActive) {
        var _this = this;

        // assert to get always "full" items
        var items = this._get(item, '_items');

        if (!this._get(item, '_items')) {
          items = this._get(item, 'items');
        } // propagate update/parent action up


        this.sendAction('updateAction', item);
        this.sendAction('parentAction', isActive); // check if children should be toggled

        if (Ember.isArray(items)) {
          // toggle visibility of current item
          if (this.get('toggleVisibility')) {
            var isOpen = Ember.get(item, 'isOpen');

            if (!isOpen && isActive) {
              this.send('toggle', item);
            }
          } // activate/deactivate all children depending on settings


          var deactivate = !isActive && this.get('deactivateChildren');
          var activate = isActive && this.get('activateChildren');

          if (activate || deactivate) {
            // >>> TAIL RECURSION
            items.forEach(function (item) {
              _this._set(item, 'isActive', isActive);

              _this.send('update', item, isActive);
            });
          }
        }
      },

      /**
       * @event parent
       * @param {Object} item Parent item of clicked item
       * @param {Boolean} isActive Current state of clicked item
       * @private
       */
      parent: function parent(item, isActive) {
        var _this2 = this;

        var update = function update() {
          // forward active state setting
          _this2._set(item, 'isActive', isActive); // toggle if not already open


          if (!_this2._get(item, 'isOpen')) {
            _this2.send('toggle', item);
          } // and send consequent action up


          _this2.sendAction('updateAction', item);

          _this2.sendAction('parentAction', isActive);
        };

        if (isActive && this.get('activateParent')) {
          // activate if child's set
          if (!this._get(item, 'isActive')) {
            update();
          }
        }

        if (!isActive && this.get('deactivateParent')) {
          // deactivate if ALL children unset
          var items = this._get(item, 'items');

          if (!items.isAny('isActive')) {
            update();
          }
        }
      },

      /**
       * @event filter
       * @private
       */
      filter: function filter() {
        // there seems to be a bug with
        // "key-up" action firing twice!
        var _query = this.get('_query', '');

        var query = this.get('query');

        if (_query === query) {
          return;
        }

        this.set('_query', query); // reset filter if there's no
        // input left in search field

        if (!query) {
          this.send('reset');
          return;
        } // cache original items to be
        // used for actual filtering


        var items = this.get('items');

        if (!this.get('_items')) {
          this.set('_items', items);
        } // now run the actual filter


        this._timer = Ember.run.debounce(this, this._filter, 250);
      },

      /**
       * @event reset
       * @private
       */
      reset: function reset() {
        var _this3 = this;

        var recursor = function recursor(items) {
          items.forEach(function (item) {
            // reassign nested cached nodes
            // and close open parent nodes
            var _items = _this3._get(item, '_items');

            if (!Ember.isArray(_items)) {
              return;
            } // >>> TAIL RECURSION


            var _isOpen = _this3._get(item, '_isOpen');

            _this3._set(item, 'isOpen', !!_isOpen);

            _this3._set(item, 'items', _items);

            recursor(_items);
          });
        }; // reset timer, items and query


        var _items = this.get('_items');

        if (_items) {
          Ember.run.cancel(this._timer);
          this.set('items', _items);
          this.set('_items', null);
          this.set('_query', '');
          this.set('query', '');
          recursor(_items);
        }
      }
    },
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Initializes "isActive" property and
     * checks if there's initial query set.
     *
     * @method _init
     * @private
     */
    init: function init() {
      var _this4 = this;

      this._super.apply(this, arguments);

      Ember.set(this, 'items', this.get('items') || []); // initializes "isActive" for ALL levels correctly

      var recursor = function recursor(items) {
        var parents = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
        var isRoot = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
        items.forEach(function (item) {
          // important: do not run twice!
          if (_this4._get(item, '_initialized')) {
            return;
          }

          _this4._set(item, '_initialized', true); // reset ancestor chain on root


          if (isRoot) {
            parents = [];
          } // and get current children items


          var items = _this4._get(item, 'items'); // handle parent and children items


          if (_this4._get(item, 'isActive')) {
            // open on root level occassionally
            if (_this4.get('toggleVisibility')) {
              _this4._set(item, 'isOpen', isRoot);
            } // open and optionally set parents


            parents.forEach(function (item) {
              if (_this4.get('activateParent')) {
                _this4._set(item, 'isActive', true);
              }

              _this4._set(item, '_initialized', true);

              _this4._set(item, 'isOpen', true);
            }); // activate all children optionally

            if (_this4.get('activateChildren')) {
              if (Ember.isArray(items)) {
                items.setEach('isActive', true);
              }
            }
          } // >>> TAIL RECURSION


          if (Ember.isArray(items)) {
            parents.addObject(item);
            recursor(items, parents, false);
          }
        });
      }; // only on top level necessary


      if (this.get('level') === 1) {
        recursor(this.get('items'));
      } // check if there's query param


      if (this.get('query')) {
        this.send('filter');
      }
    },

    /**
     * Actual filter implementation called
     * with ember run's debounced method.
     *
     * @method _filter
     * @private
     */
    _filter: function _filter() {
      var _this5 = this;

      var query = this.get('query');

      var recursor = function recursor(items) {
        return items.filter(function (item) {
          var label = _this5._get(item, 'label');

          var items = _this5._get(item, 'items');

          var regex = new RegExp(query, 'gi');
          var match = !!label.match(regex); // simple node without
          // nested child nodes

          if (!Ember.isArray(items)) {
            return match;
          } // get cached version for
          // recursive lookup or set
          // cache for the first time


          if (_this5._get(item, '_items')) {
            items = _this5._get(item, '_items');
          } else {
            _this5._set(item, '_items', items);
          } // cache open state of items


          if (!_this5._get(item, '_isOpen')) {
            var isOpen = _this5._get(item, 'isOpen');

            _this5._set(item, '_isOpen', isOpen);
          } // >>> TAIL RECURSION


          var filtered = recursor(items);

          _this5._set(item, 'items', filtered);

          _this5._set(item, 'isOpen', filtered.length);

          return match || filtered.length;
        });
      };

      var items = this.get('_items');
      var result = recursor(items);
      this.set('items', result);
    },

    /**
     * Gets item property depending on
     * specified property attributes.
     *
     * @method _getProp
     * @private
     */
    _get: function _get(object, property) {
      return Ember.get(object, this._map(property));
    },

    /**
     * Sets item property depending on
     * specified property attributes.
     *
     * @method _set
     * @private
     */
    _set: function _set(object, property, value) {
      Ember.set(object, this._map(property), value);
    },

    /**
     * Maps default property values to
     * specified proeprty attributes.
     *
     * @method _map
     * @private
     */
    _map: function _map(property) {
      switch (property) {
        case 'label':
          property = this.get('labelProperty');
          break;

        case 'value':
          property = this.get('valueProperty');
          break;

        case 'isOpen':
          property = this.get('openProperty');
          break;

        case 'isActive':
          property = this.get('activeProperty');
          break;

        default:
      }

      return property;
    },
    // -------------------------------------------------------------------------
    // Properties

    /**
     * If current instance has filter.
     * @property isOpen
     * @type Boolean
     * @private
     */
    hasFilter: Ember.computed('filter', 'level', function () {
      return this.get('level') === 1 && this.get('filter');
    }),

    /**
     * Computed id for next recursion
     *
     * @property nextLevel
     * @type Number
     * @private
     */
    nextId: Ember.computed('id', 'level', function () {
      return "".concat(this.get('id')).concat(this.get('level'));
    }),

    /**
     * Computed level for next recursion
     *
     * @property nextLevel
     * @type Number
     * @private
     */
    nextLevel: Ember.computed('level', function () {
      return this.get('level') + 1;
    })
  });

  _exports.default = _default;
});