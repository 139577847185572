define("@vollersgroup/hub-frontend/components/recent-auction-registrations/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Display the latest registrations to the given company's auctions.
   *
   * ```hbs
   * {{recent-auction-registrations company=myCompany}}
   * ```
   *
   * @namespace Component
   * @class RecentAuctionRegistrations
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),

    /**
     * The company for which the recent registrations should be fetched
     *
     * @attribute company
     * @type Model.Company
     */
    company: null,
    actions: {
      registrationUpdated: function registrationUpdated(registration) {
        this.get('newAuctionParticipants').removeObject(registration);
      }
    },
    auctionParticipants: Ember.computed('company.id', 'isDestroyed', 'orderBy', 'store', function () {
      if (this.get('isDestroyed') || !this.getAttr('company') || !this.getAttr('company').get('id')) {
        return [];
      }

      return this.get('store').query('auction-participant', {
        sort: this.get('orderBy'),
        auctioneerCompany: this.getAttr('company').get('id')
      });
    }),
    newAuctionParticipants: Ember.computed.filterBy('auctionParticipants', 'isPending'),
    orderBy: 'DATE'
  });

  _exports.default = _default;
});