define("@vollersgroup/hub-frontend/register/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jFfox77I",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"page-title\",[[27,\"t\",[\"Create new account\"],null]],null],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"search-bar\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"wrapper\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"layout__full\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"index\"],[[\"class\"],[\"search-bar__logo--full\"]],{\"statements\":[[0,\"        \"],[7,\"img\"],[11,\"src\",\"/assets/img/v-hub-logo.png\"],[11,\"alt\",\"V-Hub\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"section\"],[11,\"class\",\"section flex-grow-1 padding-bottom-4\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"wrapper\"],[9],[0,\"\\n    \"],[7,\"h1\"],[11,\"class\",\"text-center padding-top-4 font-size-3\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"Create a new V-Hub account\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"section\"],[9],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"Do you have a V-Hub account?\"],null],false],[0,\"\\n\"],[4,\"link-to\",[\"login\"],[[\"class\"],[\"button button--plain\"]],{\"statements\":[[0,\"          \"],[1,[27,\"t\",[\"Log in\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"wrapper wrapper--small\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"layout__full\"],[9],[0,\"\\n        \"],[1,[27,\"register-form\",null,[[\"user\",\"companyName\",\"companyToken\",\"afterRegisterAction\"],[[23,[\"model\"]],[27,\"readonly\",[[23,[\"companyName\"]]],null],[27,\"readonly\",[[23,[\"companyToken\"]]],null],[27,\"action\",[[22,0,[]],\"afterRegister\"],null]]]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/register/index/template.hbs"
    }
  });

  _exports.default = _default;
});