define("@vollersgroup/hub-frontend/components/filter-item-range/component", ["exports", "@vollersgroup/hub-frontend/components/filter-item/component", "@vollersgroup/hub-frontend/utils/unit-calculator"], function (_exports, _component, _unitCalculator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A filter item handling filter type "RANGE" with two input fields for
   * minimum and maximum values as well as unit selection.
   *
   * ```html
   * {{filter-item-range filter=filter update=(action "{YOUR_ACTION}")}}
   * ```
   *
   * Please note: Component will fake items for `max`, `max` and `unit`,
   * but they are from the same json structure as when returned by API.
   *
   * @namespace Component
   * @class FilterItemArray
   * @extends Component.FilterItem
   */
  var _default = _component.default.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    availableData: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * Selected min value.
     *
     * @attribute min
     * @type Number
     */
    min: Ember.computed('filter.items[]', {
      get: function get(key) {
        return this._getItemValue(key);
      }
    }),

    /**
     * Selected max value.
     *
     * @attribute max
     * @type Number
     */
    max: Ember.computed('filter.items[]', {
      get: function get(key) {
        return this._getItemValue(key);
      }
    }),

    /**
     * Selected unit value.
     *
     * @attribute unit
     * @type "KB"|"LBS"
     */
    unit: Ember.computed('filter.items[]', 'availableData.baseUnits', {
      get: function get(key) {
        var baseUnits = Ember.get(this, 'availableData.baseUnits');

        var value = this._getItemValue(key);

        if (Ember.isNone(value)) {
          return baseUnits.get('firstObject.value');
        }

        return value;
      }
    }),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Loading state for button.
     *
     * @property isLoading
     * @type {Boolean}
     */
    isLoading: false,

    /**
     * Base unit of input stream.
     *
     * @property baseUnit
     * @type {String}
     * @default "KG"
     */
    baseUnit: 'KG',

    /**
     * Minimum available value for this filter.
     *
     * @attribute minLimit
     * @type Number
     */
    // eslint-disable-next-line ember/require-computed-property-dependencies
    minLimit: Ember.computed(
    /*'unit' adding this breaks the test */
    'filter.minValue', 'baseUnit', {
      get: function get()
      /*key*/
      {
        var minValue = Ember.get(this, 'filter.minValue');
        var base = Ember.get(this, 'baseUnit');
        var unit = Ember.get(this, 'unit');

        if (unit === base) {
          return minValue;
        }

        return Math.round((0, _unitCalculator.unitKgToLbs)(minValue));
      }
    }),

    /**
     * Maximum available value for this filter.
     *
     * @attribute maxLimit
     * @type Number
     */
    // eslint-disable-next-line ember/require-computed-property-dependencies
    maxLimit: Ember.computed(
    /*'unit' adding this breaks the test*/
    'filter.maxValue', 'baseUnit', function () {
      var maxValue = Ember.get(this, 'filter.maxValue');
      var base = Ember.get(this, 'baseUnit');
      var unit = Ember.get(this, 'unit');

      if (unit === base) {
        return maxValue;
      }

      return Math.round((0, _unitCalculator.unitKgToLbs)(maxValue));
    }),
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Adjusts minimum if de- or exceeding boundaries
       * or exceeding maximum. Besides, floating point
       * values are converted to integer values.
       *
       * @event minChange
       * @private
       */
      minChange: function minChange() {
        var minValue = +Ember.get(this, 'minLimit');
        var maxValue = +Ember.get(this, 'maxLimit');

        if (!Ember.get(this, 'min')) {
          return;
        }

        if (+Ember.get(this, 'min') % 1 !== 0) {
          Ember.set(this, 'min', Math.round(+Ember.get(this, 'min')));
        }

        if (+Ember.get(this, 'min') < minValue) {
          Ember.set(this, 'min', minValue);
        }

        if (+Ember.get(this, 'min') >= maxValue) {
          Ember.set(this, 'min', minValue);
        }

        var max = +Ember.get(this, 'max');
        var min = +Ember.get(this, 'min');

        if (!!max && min >= max) {
          Ember.set(this, 'maxValue', maxValue);
        }
      },

      /**
       * Adjusts maximum if de- or exceeding boundaries
       * or deceeding minimum. Besides, floating point
       * values are converted to integer values.
       *
       * @event maxChange
       * @private
       */
      maxChange: function maxChange() {
        var minValue = +Ember.get(this, 'minLimit');
        var maxValue = +Ember.get(this, 'maxLimit');

        if (!Ember.get(this, 'max')) {
          return;
        }

        if (+Ember.get(this, 'max') % 1 !== 0) {
          Ember.set(this, 'max', Math.round(+Ember.get(this, 'max')));
        }

        if (+Ember.get(this, 'max') > maxValue) {
          Ember.set(this, 'max', maxValue);
        }

        if (+Ember.get(this, 'max') <= minValue) {
          Ember.set(this, 'max', maxValue);
        }

        var max = +Ember.get(this, 'max');
        var min = +Ember.get(this, 'min');

        if (!!min && max <= min) {
          Ember.set(this, 'min', minValue);
        }
      },

      /**
       * Converts boundaries and values between different units.
       * At the moment only pounds and kilogram are supported.
       *
       * @event unitChange
       * @private
       */
      unitChange: function unitChange() {
        var max = +Ember.get(this, 'max');
        var min = +Ember.get(this, 'min');
        var unit = Ember.get(this, 'unit');
        var minValue = +Ember.get(this, 'minLimit');
        var maxValue = +Ember.get(this, 'maxLimit');

        switch (Ember.get(this, 'unit')) {
          case 'LBS':
            Ember.set(this, 'min', min ? Math.round((0, _unitCalculator.unitKgToLbs)(min)) : '');
            Ember.set(this, 'max', max ? Math.round((0, _unitCalculator.unitKgToLbs)(max)) : '');
            Ember.set(this, 'minLimit', Math.round((0, _unitCalculator.unitKgToLbs)(minValue)));
            Ember.set(this, 'maxLimit', Math.round((0, _unitCalculator.unitKgToLbs)(maxValue)));
            break;

          case 'KG':
            Ember.set(this, 'min', min ? Math.round((0, _unitCalculator.unitLbsToKg)(min)) : '');
            Ember.set(this, 'max', max ? Math.round((0, _unitCalculator.unitLbsToKg)(max)) : '');
            Ember.set(this, 'minLimit', Math.round((0, _unitCalculator.unitLbsToKg)(minValue)));
            Ember.set(this, 'maxLimit', Math.round((0, _unitCalculator.unitLbsToKg)(maxValue)));
            break;

          default:
            throw new Error("Unit ".concat(unit, " is not implemented!"));
        }
      },

      /**
       * Propagates item to parent action
       * by calling `update` closure method.
       *
       * @event update
       * @param {Object} item Updated filter item.
       * @private
       */
      update: function update() {
        var max = +Ember.get(this, 'max');
        var min = +Ember.get(this, 'min');
        var unit = Ember.get(this, 'unit');
        var items = Ember.get(this, 'filter.items');

        if (!min && !max && items.length === 0) {
          return;
        }

        Ember.set(this, 'isLoading', true);

        this._super({
          id: max,
          name: 'max',
          active: !!max
        });

        this._super({
          id: min,
          name: 'min',
          active: !!min
        });

        this._super({
          id: unit,
          name: 'unit',
          active: !!min || !!max
        });
      }
    },
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Derives an item by its name from
     * filter items and delivers value.
     *
     * @param {String} name Name of item.
     * @return {Object|null}
     */
    _getItemValue: function _getItemValue(name) {
      var items = Ember.get(this, 'filter.items');

      if (!Ember.isEmpty(items)) {
        var item = items.findBy('name', name);

        if (!Ember.isNone(item)) {
          return Ember.get(item, 'id');
        }
      }

      return null;
    }
  });

  _exports.default = _default;
});