define("@vollersgroup/hub-frontend/components/tag-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VmWdciT3",
    "block": "{\"symbols\":[\"tag\"],\"statements\":[[7,\"ul\"],[11,\"class\",\"tag-list\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"sortedTags\"]]],null,{\"statements\":[[4,\"if\",[[22,1,[\"value\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[9],[1,[22,1,[\"value\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[22,1,[\"name\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[9],[1,[22,1,[\"name\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"li\"],[9],[1,[22,1,[]],false],[10],[0,\"\\n    \"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/tag-list/template.hbs"
    }
  });

  _exports.default = _default;
});