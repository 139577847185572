define("@vollersgroup/hub-frontend/components/authenticated-link/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Generate a link which either links to the login or directly to the desired route
   *
   * If the user is not logged in, a login link is generated.
   * After login, the user will be redirected to the originally desired page
   *
   * ```html
   * {{#authenticated-link route="store.listing" model=listing}}Link Text{{/authenticated-link}}
   * {{#authenticated-link queryParamName="addToCart" queryParamValue=listing.id}}Link Text{{/authenticated-link}}
   * ```
   *
   * @namespace Component
   * @class AuthenticatedLink
   * @augments Ember.Component
   */
  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * Don't wrap in a tag
     *
     * @property tagName
     * @final
     * @private
     * @type String
     */
    tagName: '',

    /**
     * An optional parameter for the link-to helper
     *
     * @attribute model
     * @public
     * @type String
     */
    model: null,

    /**
     * The route name for the link-to helper
     *
     * @attribute route
     * @public
     * @type String
     */
    route: null,

    /**
     * The query param name for the link-to helper
     *
     * @attribute queryParamName
     * @public
     * @type String
     */
    queryParamName: null,

    /**
     * The query param value for the link-to helper
     *
     * @attribute queryParamValue
     * @public
     * @type String
     */
    queryParamValue: null,

    /**
     * If the default auction should be prevented.
     *
     * @attribute preventDefault
     * @public
     * @type {Boolean}
     * @default true
     */
    preventDefault: true,

    /**
     * If the event should be bubbled.
     *
     * @attribute bubbles
     * @public
     * @type {Boolean}
     * @default true
     */
    bubbles: true,
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Build the afterLogin query Parameter
     *
     * @property afterLogin
     * @private
     * @returns String
     */
    afterLogin: Ember.computed('queryParamName', 'queryParamValue', function () {
      return this.get('queryParamName') + '++' + this.get('queryParamValue');
    })
  });

  _exports.default = _default;
});