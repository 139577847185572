define("@vollersgroup/hub-frontend/admin/manage/auction/edit/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "@vollersgroup/hub-frontend/mixins/reset-scroll", "ember-can"], function (_exports, _authenticatedRouteMixin, _resetScroll, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _resetScroll.default, _emberCan.CanMixin, {
    /**
     * Renders templates for admin route manually.
     * Per default, it renders secondary nav items
     * from manage pod to `secondary-nav` outlet.
     *
     * @public
     * @method renderTemplate
     * @return {Void}
     */
    renderTemplate: function renderTemplate() {
      this.controller.set('navbar', {
        route: 'admin.manage.overview.auction',
        title: Ember.get(this, 'l10n').t('Edit auction')
      });
      this.render();
      this.render('admin/manage/navbar-subpage', {
        outlet: 'secondary-nav',
        into: 'admin'
      });
    },

    /**
     * Invokes a change for controller's `allSteps`.
     *
     * @public
     * @method deactivate
     * @return {Void}
     */
    activate: function activate() {
      var _this = this;

      Ember.run.once(function () {
        _this.controller.notifyPropertyChange('allSteps');
      });
    },
    model: function model(params) {
      return this.store.findRecord('auction', params.auction_id);
    },
    afterModel: function afterModel(model, _transition) {
      if (!this.can('edit auction', model)) {
        this.get('toast').warning(this.get('l10n').t('You cannot edit this auction!'));
        this.replaceWith('admin.manage.overview.auction');
      }
    },

    /**
     * Resets route's model and controller step.
     *
     * @public
     * @method deactivate
     * @return {Void}
     */
    deactivate: function deactivate() {
      this.currentModel.rollbackAttributes();
      this.controller.set('step', 0);
    },
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Redirects to overview on model errors.
       *
       * @public
       * @event error
       * @return {Void}
       */
      error: function error() {
        this.get('toast').warning(this.get('l10n').t('You cannot edit this auction!'));
        this.transitionTo('admin.manage.overview.auction');
      }
    }
  });

  _exports.default = _default;
});