define("@vollersgroup/hub-frontend/mixins/orderable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This mixin injects an orderBy property, as well as an orderByOptions array and an orderBy action.
   * Overwrite the orderByOptions property with { label: "", value: "" } objects.
   *
   * @namespace Mixin
   * @class Orderable
   * @extends Ember.Mixin
   */
  var _default = Ember.Mixin.create({
    l10n: Ember.inject.service(),
    actions: {
      /**
       * Manually set the ordering.
       *
       * @event orderBy
       * @param {String} orderBy
       * @private
       */
      orderBy: function orderBy(_orderBy) {
        this.set('orderBy', _orderBy);
      }
    },

    /**
     * How the listings should be ordered.
     *
     * @property orderBy
     * @type String
     */
    orderBy: 'DATE',

    /**
     * The available options by which it is possible to order.
     *
     * @property orderByOptions
     * @type Array
     * @final
     */
    orderByOptions: Ember.computed('l10n', function () {
      return [{
        value: 'DATE',
        label: this.get('l10n').t('Most recent')
      }];
    })
  });

  _exports.default = _default;
});