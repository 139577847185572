define("@vollersgroup/hub-frontend/admin/manage/overview/auction/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U3+qYKnX",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"page-title\",[[27,\"t\",[\"Auction overview\"],null]],null],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"wrapper padding-bottom-4\"],[9],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"layout__sidebar--left layout__sidebar--left--small\"],[9],[0,\"\\n    \"],[7,\"ul\"],[11,\"class\",\"third-nav\"],[9],[0,\"\\n      \"],[7,\"li\"],[9],[0,\"\\n        \"],[4,\"link-to\",[\"admin.manage.overview.auction.index\"],[[\"class\"],[\"third-nav__item\"]],{\"statements\":[[1,[27,\"t\",[\"Auctions\"],null],false]],\"parameters\":[]},null],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"li\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"admin.manage.overview.auction.registrations\"],[[\"class\"],[\"third-nav__item\"]],{\"statements\":[[0,\"          \"],[1,[27,\"t\",[\"Registrations\"],null],false],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"pendingRegistrations\",\"length\"]]],null,{\"statements\":[[0,\"            (\"],[1,[23,[\"model\",\"pendingRegistrations\",\"length\"]],false],[0,\")\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"layout__sidebar--main\"],[9],[0,\"\\n    \"],[1,[21,\"outlet\"],false],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/admin/manage/overview/auction/template.hbs"
    }
  });

  _exports.default = _default;
});