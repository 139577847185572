define("@vollersgroup/hub-frontend/admin/manage/navbar-subpage/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2X1r3xDp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"grey__bg\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"wrapper\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"layout__full\"],[9],[0,\"\\n      \"],[4,\"link-to\",[[23,[\"navbar\",\"route\"]]],[[\"class\"],[\"link__back\"]],{\"statements\":[[0,\"« \"],[1,[27,\"t\",[\"Back to overview\"],null],false]],\"parameters\":[]},null],[0,\"\\n      \"],[1,[23,[\"navbar\",\"title\"]],false],[4,\"if\",[[23,[\"model\",\"name\"]]],null,{\"statements\":[[0,\" – \"],[1,[23,[\"model\",\"name\"]],false]],\"parameters\":[]},null],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/admin/manage/navbar-subpage/template.hbs"
    }
  });

  _exports.default = _default;
});