define("@vollersgroup/hub-frontend/application/adapter", ["exports", "ember-data", "@vollersgroup/hub-frontend/config/environment", "ember-simple-auth/mixins/data-adapter-mixin"], function (_exports, _emberData, _environment, _dataAdapterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RESTAdapter = _emberData.default.RESTAdapter;

  var _default = RESTAdapter.extend(_dataAdapterMixin.default, {
    l10n: Ember.inject.service(),
    session: Ember.inject.service(),
    headers: Ember.computed('l10n.locale', function () {
      return {
        'Accept-Language': this.get('l10n.locale')
      };
    }),
    authorizer: 'authorizer:application',
    host: _environment.default.apiBaseUrl,
    namespace: _environment.default.apiNamespace,
    coalesceFindRequests: true,
    shouldReloadAll: function shouldReloadAll() {
      return false;
    },
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return true;
    },
    pathForType: function pathForType(type) {
      return Ember.String.camelize(type);
    },

    /**
     * Intercepts responses and add `meta` properties to JSON payload.
     * Filters invalid responses and provides server error messages.
     *
     * @method  handleResponse
     * @param {Number} status
     * @param {Object} headers
     * @param {Object} payload
     * @param {Object} requestData
     * @return {Mixed}
     */
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      if (this.isSuccess(status, headers, payload)) {
        payload = this._normalizeResponse.apply(this, arguments);
        return this._super(status, headers, payload, requestData);
      }

      if (status === 401) {
        var session = this.get('session');

        if (session.get('isAuthenticated')) {
          session.invalidate(true);
        }
      }

      return this._super(status, headers, payload, requestData);
    },
    normalizeErrorResponse: function normalizeErrorResponse(status, headers, payload) {
      // Server responds with { error: "Error message" } object
      if (payload && payload.error) {
        return [{
          status: "".concat(status),
          title: 'The backend responded with an error',
          detail: "".concat(payload.error)
        }];
      }

      return this._super.apply(this, arguments);
    },

    /**
     * Transforms `payload` to contain `data` and `meta`.
     *
     * @method  _normalizeResponse
     * @param {Number} status
     * @param {Object} headers
     * @param {Object} payload
     * @return {Object}
     */
    _normalizeResponse: function _normalizeResponse(status, headers, payload) {
      var response = {
        data: payload,
        meta: {
          maxPages: null,
          totalItems: null
        }
      };
      var lastPageRegex = /page=(\d+)(?:[^,])*$/;
      var maxPagesMatch = (headers['x-link'] || '').match(lastPageRegex);

      if (maxPagesMatch) {
        response.meta.maxPages = parseInt(maxPagesMatch[1]);
      }

      if (headers['x-total-items']) {
        response.meta.totalItems = parseInt(headers['x-total-items']);
      }

      return response;
    }
  });

  _exports.default = _default;
});