define("@vollersgroup/hub-frontend/services/listing-publisher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    toast: Ember.inject.service(),
    l10n: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Sets a listing to "ACTIVE" state if it's currently in "DRAFT" or "INACTIVE" state.
     *
     * @public
     * @method publish
     * @param {Model.Listing} listing
     * @return {Promise}
     */
    publish: function publish(listing) {
      var _this = this;

      switch (listing.get('status')) {
        case 'DRAFT':
        case 'SOLD_OUT':
        case 'INACTIVE':
          listing.set('status', 'ACTIVE');
          listing.set('auction', null);
          listing.save().then(function () {
            _this.get('toast').success(_this.get('l10n').t('Your coffee is now active and visible to all buyers.'));
          }, function () {
            _this.get('toast').warning(_this.get('l10n').t('Sorry, an error occurred when trying to publish this listing.'));
          });
          break;

        default:
          this.get('toast').warning(this.get('l10n').t('You can only publish draft or inactive coffees.'));
      }
    },

    /**
     * Sets a listing to "INACTIVE" state if it's currently in "ACTIVE" or "EXPIRED" state.
     *
     * @public
     * @method deactivate
     * @param {Model.Listing} listing
     * @return {Promise}
     */
    deactivate: function deactivate(listing) {
      var _this2 = this;

      switch (listing.get('status')) {
        case 'ACTIVE':
        case 'EXPIRED':
          listing.set('status', 'INACTIVE');
          listing.set('auction', null);
          listing.save().then(function () {
            _this2.get('toast').info(_this2.get('l10n').t('Your coffee is now inactive. Only your company can see it.'));
          }, function () {
            _this2.get('toast').warning(_this2.get('l10n').t('Sorry, but an error occurred when trying to deactivate this listing.'));
          });
          break;

        default:
          this.get('toast').warning(this.get('l10n').t('You can only deactivate active or expired coffee.'));
      }
    },

    /**
     * Sets a listing to "SOLD_OUT" state if it's currently in "ACTIVE".
     *
     * @public
     * @method sold
     * @param {Model.Listing} listing
     * @return {Promise}
     */
    sold: function sold(listing) {
      var _this3 = this;

      switch (listing.get('status')) {
        case 'ACTIVE':
          listing.set('status', 'SOLD_OUT');
          listing.save().then(function () {
            _this3.get('toast').info(_this3.get('l10n').t('Your coffee is now marked as sold out - you may want to adjust your available amount. Users can still access it via URL.'));
          }, function () {
            _this3.get('toast').warning(_this3.get('l10n').t('Sorry, but an error occurred when trying to mark this listing as sold out.'));
          });
          break;

        default:
          this.get('toast').warning(this.get('l10n').t('You can only mark active coffee as sold out.'));
      }
    },

    /**
     * Deletes a listing if it's currently in "DRAFT" state.
     *
     * @public
     * @method delete
     * @param {Model.Listing} listing
     * @return {Promise}
     */
    delete: function _delete(listing) {
      var _this4 = this;

      switch (listing.get('status')) {
        case 'DRAFT':
          listing.set('archived', true);
          listing.deleteRecord();
          listing.save().then(function () {
            _this4.get('toast').info(_this4.get('l10n').t('You have deleted your coffee.'));
          }, function () {
            _this4.get('toast').warning(_this4.get('l10n').t('Sorry, but an error occurred when trying to delete this listing.'));
          });
          break;

        default:
          this.get('toast').warning(this.get('l10n').t('You can only delete coffee which has never been published.'));
      }
    },

    /**
     * Sets a listing's `archived` flag if it's currently "INACTIVE", "SOLD_OUT" or "EXPIRED".
     *
     * @public
     * @method archive
     * @param {Model.Listing} listing
     * @return {Promise}
     */
    archive: function archive(listing) {
      var _this5 = this;

      switch (listing.get('status')) {
        case 'EXPIRED':
        case 'INACTIVE':
        case 'SOLD_OUT':
          listing.set('archived', true);
          listing.save().then(function () {
            _this5.get('toast').info(_this5.get('l10n').t('You have archived your coffee.'));
          }, function () {
            _this5.get('toast').warning(_this5.get('l10n').t('Sorry, but an error occurred when trying to archive this listing.'));
          });
          break;

        default:
          this.get('toast').warning(this.get('l10n').t('You can not archive active coffee listings.'));
      }
    },

    /**
     * Resets a listing's `archived` flag if it's currently true.
     *
     * @public
     * @method unarchive
     * @param {Model.Listing} listing
     * @return {Promise}
     */
    unarchive: function unarchive(listing) {
      var _this6 = this;

      if (!listing.get('archived')) {
        this.get('toast').warning(this.get('l10n').t('You can not unarchive active coffee listings.'));
        return;
      }

      listing.set('archived', false);
      listing.save().then(function () {
        _this6.get('toast').info(_this6.get('l10n').t('You have unarchived your coffee.'));
      }, function () {
        _this6.get('toast').warning(_this6.get('l10n').t('Sorry, but an error occurred when trying to unarchive this listing.'));
      });
    },

    /**
     * Renews a listing if it's currently in "EXPIRED" state.
     *
     * @public
     * @method renew
     * @param {Model.Listing} listing
     * @return {Promise}
     */
    renew: function renew(listing) {
      var _this7 = this;

      switch (listing.get('status')) {
        case 'EXPIRED':
          listing.set('status', 'ACTIVE');
          listing.save().then(function () {
            _this7.get('toast').info(_this7.get('l10n').t('You have renewed your coffee.'));
          }, function () {
            _this7.get('toast').warning(_this7.get('l10n').t('Sorry, but an error occurred when trying to renew this listing.'));
          });
          break;

        default:
          this.get('toast').warning(this.get('l10n').t('You can only renew expired coffee listings.'));
      }
    }
  });

  _exports.default = _default;
});