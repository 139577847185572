define("@vollersgroup/hub-frontend/cart/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "@vollersgroup/hub-frontend/mixins/reset-scroll", "ember-can"], function (_exports, _authenticatedRouteMixin, _resetScroll, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _resetScroll.default, _emberCan.CanMixin, {
    flashMessages: Ember.inject.service(),
    userSession: Ember.inject.service(),
    beforeModel: function beforeModel() {
      return Ember.RSVP.Promise.all([this._super.apply(this, arguments), this.get('userSession.currentUser'), this.get('userSession.currentCompany')]);
    },
    afterModel: function afterModel() {
      if (!this.can('create order')) {
        this.transitionTo('index');
        return;
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});