define("@vollersgroup/hub-frontend/components/search-agent-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3KscmQ/o",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\"],[11,\"class\",\"filter__box__title filter__box__title--action display-table width-100p\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"display-table-cell text-left padding-right-3\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"Search agent\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"display-table-cell text-right padding-left-3\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"admin.account.saved-searches\"],[[\"class\"],[\"manage-saved-search\"]],{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"Manage\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"filter__box__content\"],[9],[0,\"\\n  \"],[1,[27,\"c-select\",null,[[\"value\",\"reference\",\"translate\",\"searchable\",\"action\",\"id\",\"placeholder\",\"options\"],[[23,[\"selected\"]],true,true,true,[27,\"action\",[[22,0,[]],\"update\"],null],\"saved_search_agent_select\",[27,\"t\",[\"Select search\"],null],[27,\"readonly\",[[23,[\"searchAgents\"]]],null]]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/search-agent-box/template.hbs"
    }
  });

  _exports.default = _default;
});