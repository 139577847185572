define("@vollersgroup/hub-frontend/components/message-dropdown/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The inbox-dropdown
   *
   * ```html
   * {{message-dropdown}}
   * ```
   *
   * @namespace Component
   * @class MessageDropdown
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies

    /**
     * @property messageCenter
     * @type Service.MessageCenter
     * @private
     */
    messageCenter: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Mark a conversation as read.
       *
       * @event markConversationRead
       * @param Model.Conversation conversation The conversation to mark as read
       * @private
       */
      markConversationRead: function markConversationRead(conversation) {
        this.get('messageCenter').markConversationRead(conversation);
        return true;
      }
    }
  });

  _exports.default = _default;
});