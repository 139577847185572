define("@vollersgroup/hub-frontend/services/validator", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    l10n: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    userSession: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Clear all error messages.
     *
     * @method clear
     * @public
     */
    clear: function clear() {
      this.set('errors', {});
      this.set('allErrors', []);
      this.get('flashMessages').clear();
    },

    /**
     * Validate a model. Eventual errors are added to the flashMessages service. They are also available on a per-field
     * basis via `errors.myFieldName`.
     *
     * You can use the optional ignoreFields parameter if you need incomplete validation, e.g.:
     *
     * ```js
     * this.validate("listing", myListing, ["producer"]); // Do not validate producer field here
     * ```
     *
     * @method validate
     * @param {String} type The type of validation to use
     * @param {Model} model The model/object to validate
     * @param {Array} ignoreFields An optional array of fields to ignore in this validation round
     * @returns {boolean} False if the validation fails, else true
     * @public
     */
    validate: function validate(type, model) {
      var ignoreFields = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
      var def = this.get(type);
      var allErrors = [];
      var errors = {}; // Create a nested object from the field path

      function _assign(obj, keyPath, value) {
        var lastKeyIndex = keyPath.length - 1;

        for (var i = 0; i < lastKeyIndex; ++i) {
          var key = keyPath[i];

          if (!(key in obj)) {
            obj[key] = {};
          }

          obj = obj[key];
        }

        obj[keyPath[lastKeyIndex]] = value;
      }

      def.forEach(function (validation) {
        if (ignoreFields.indexOf(validation.field) > -1) {
          return;
        }

        var val = model.get(validation.field);

        if (!validation.validate(val, model)) {
          var error = {
            field: validation.field,
            title: validation.errorTitle,
            message: validation.errorMessage
          };
          allErrors.push(error);
          var fields = validation.field.split('.');
          var obj = {};

          _assign(obj, fields, error);

          errors = Ember.$.extend(true, errors, obj);
        }
      });
      this.set('errors', errors);
      this.set('allErrors', allErrors);
      this.get('flashMessages').setErrors(allErrors);
      return !allErrors.length;
    },
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Listing validation definition.
     *
     * @property listing
     * @type {Array}
     * @public
     */
    listing: Ember.computed('_validateRequired', 'l10n', {
      get: function get()
      /*key*/
      {
        var _this = this;

        return [{
          field: 'name',
          validate: function validate(val) {
            return _this._validateRequired(val) && _this._validateLength(val, 3);
          },
          errorTitle: this.get('l10n').t('Lot name'),
          errorMessage: this.get('l10n').t('The coffee name must have at least 3 characters.')
        }, {
          field: 'price',
          validate: function validate(val, model) {
            if (Ember.get(model, 'priceDynamic')) {
              return true;
            }

            return _this._validateRequired(val) && _this._validateNumber(val) && val > 0;
          },
          errorTitle: this.get('l10n').t('Price'),
          errorMessage: this.get('l10n').t('You must enter a price for this listing.')
        }, {
          field: 'priceBaseUnit',
          validate: this._validateRequired,
          errorTitle: this.get('l10n').t('Price base unit'),
          errorMessage: this.get('l10n').t('You must select a base price unit for the listing.')
        }, {
          field: 'cmarketPremiumAmount',
          validate: function validate(val, model) {
            if (!Ember.get(model, 'priceDynamic')) {
              return true;
            }

            return _this._validateRequired(val) && _this._validateNumber(val) && val > 0;
          },
          errorTitle: this.get('l10n').t('Premium'),
          errorMessage: this.get('l10n').t('You must enter a premium for this listing.')
        }, {
          field: 'availableAmountUnit',
          validate: this._validateRequired,
          errorTitle: this.get('l10n').t('Package unit'),
          errorMessage: this.get('l10n').t('You must select a package unit for the listing.')
        }, {
          field: 'availableAmount',
          validate: function validate(val) {
            if (isNaN(val)) {
              return false;
            }

            val = parseFloat(val);
            return (val | 0) === val && val >= 0;
          },
          errorTitle: this.get('l10n').t('Available amount'),
          errorMessage: this.get('l10n').t('You must enter a whole number as the available amount of package units.')
        }, {
          field: 'minOrderAmount',
          validate: function validate(val) {
            return _this._validateNumber(val, true) && val > 0;
          },
          errorTitle: this.get('l10n').t('Minimum order amount'),
          errorMessage: this.get('l10n').t('The minimum package order amount must be a whole number and larger than 0.')
        }, {
          field: 'samplePrice',
          validate: function validate(val) {
            return !val || _this._validateNumber(val, false) && val > 0;
          },
          errorTitle: this.get('l10n').t('Sample price'),
          errorMessage: this.get('l10n').t('The sample price must be either empty or a number greater than 0.')
        }, {
          field: 'moisture',
          validate: function validate(val) {
            return !val || _this._validateNumber(val, false) && val > 0;
          },
          errorTitle: this.get('l10n').t('Moisture'),
          errorMessage: this.get('l10n').t('The moisture must be a number greater than 0.')
        }, {
          field: 'waterActivity',
          validate: function validate(val) {
            return !val || _this._validateNumber(val, false) && val > 0;
          },
          errorTitle: this.get('l10n').t('Water activity'),
          errorMessage: this.get('l10n').t('The water activity must be a number greater than 0.')
        }, {
          field: 'shop',
          validate: function validate(val, model) {
            return !Ember.isEmpty(val) && !Ember.isEmpty(Ember.get(model, 'shop.id'));
          },
          errorTitle: this.get('l10n').t('Shop'),
          errorMessage: this.get('l10n').t('You must select a shop.')
        }, {
          field: 'company',
          validate: function validate(val, model) {
            return !Ember.isEmpty(val) && !Ember.isEmpty(Ember.get(model, 'company.id'));
          },
          errorTitle: this.get('l10n').t('Company'),
          errorMessage: this.get('l10n').t('You must select a company.')
        }, {
          field: 'producer',
          validate: function validate(val, model) {
            return !Ember.isEmpty(val) && !Ember.isEmpty(Ember.get(model, 'producer.id'));
          },
          errorTitle: this.get('l10n').t('Origin'),
          errorMessage: this.get('l10n').t('You must select an origin.')
        }, {
          field: 'legacyWarehouse',
          validate: function validate(val, model) {
            return !Ember.isEmpty(val) && !Ember.isEmpty(Ember.get(model, 'legacyWarehouse.id'));
          },
          errorTitle: this.get('l10n').t('Warehouse'),
          errorMessage: this.get('l10n').t('You must select a warehouse.')
        }];
      }
    }),

    /**
     * Auction validation definition.
     *
     * @property auction
     * @type {Array}
     * @public
     */
    auction: Ember.computed('_validateRequired', 'l10n', 'userSession.dateFormat', {
      get: function get()
      /*key*/
      {
        var _this2 = this;

        var dateFormat = Ember.get(this, 'userSession.dateFormat');
        return [{
          field: 'name',
          validate: function validate(val) {
            return _this2._validateLength(val, 3);
          },
          errorTitle: this.get('l10n').t('Auction title'),
          errorMessage: this.get('l10n').t('The auction title must have at least 3 characters.')
        }, {
          field: 'slug',
          validate: function validate(val) {
            return _this2._validateSlug(val, 3);
          },
          errorTitle: this.get('l10n').t('Auction URL'),
          errorMessage: this.get('l10n').t('The auction web address must have at least of 3 characters with at least one letter. Only lowercase letters, numbers and dashes are allowed.')
        }, {
          field: 'bidStepAmount',
          validate: function validate(val) {
            return _this2._validateRequired(val) && _this2._validateNumber(val) && _this2._validateRange(val, 0, null);
          },
          errorTitle: this.get('l10n').t('Min. bid steps'),
          errorMessage: this.get('l10n').t('The minimum bid steps must be a number greater than 0.')
        }, {
          field: 'startedDate',
          validate: function validate(val) {
            return val && val.isValid() && val.isAfter(_moment.default.utc());
          },
          errorTitle: this.get('l10n').t('Auction date'),
          errorMessage: this.get('l10n').t('You must select a valid future date and start time for the auction. The date must have the format {{dateFormat}}.', {
            dateFormat: dateFormat
          })
        }, {
          field: 'finalSampleOrderDate',
          validate: function validate(val, model) {
            return !model.get('orderableSampleBundles') || val && val.valueOf() <= model.get('startedDate').valueOf();
          },
          errorTitle: this.get('l10n').t('Sample order end date'),
          errorMessage: this.get('l10n').t('You must select a valid date that samples can be ordered until. The date must be before the auction date, and have the format {{dateFormat}}.', {
            dateFormat: dateFormat
          })
        }, {
          field: 'about',
          validate: this._validateRequired,
          errorTitle: this.get('l10n').t('Short description'),
          errorMessage: this.get('l10n').t('You must enter a short description.')
        }, {
          field: 'auctionPolicy',
          validate: this._validateRequired,
          errorTitle: this.get('l10n').t('Auction policy'),
          errorMessage: this.get('l10n').t('You must enter an auction policy.')
        }];
      }
    }),

    /**
     * Producer validation definition.
     *
     * @property listing
     * @type {Array}
     * @public
     */
    producer: Ember.computed('_validateRequired', 'l10n', {
      get: function get()
      /*key*/
      {
        var _this3 = this;

        return [{
          field: 'name',
          validate: function validate(val) {
            return _this3._validateLength(val, 3);
          },
          errorTitle: this.get('l10n').t('Origin name'),
          errorMessage: this.get('l10n').t('The origin name must have at least 3 characters.')
        },
        /*{
         field: "farmName",
         validate: (val) => this._validateLength(val, 3),
         errorTitle: "Farm name",
         errorMessage: "The farm name must consist of at least 3 characters."
         },*/
        {
          field: 'type',
          validate: this._validateRequired,
          errorTitle: this.get('l10n').t('Type'),
          errorMessage: this.get('l10n').t('You have to select a type.')
        }, {
          field: 'foundingYear',
          validate: function validate(val) {
            return !val || _this3._validateRange(val, 1500, 2100);
          },
          errorTitle: this.get('l10n').t('Founded'),
          errorMessage: this.get('l10n').t('You have to enter a valid founding year.')
        },
        /*{
         field: "altitude",
         validate: (val) => this._validateRequired(val),
         errorTitle: "Altitude",
         errorMessage: "You have to enter an altitude."
         },*/
        {
          field: 'numFarms',
          validate: function validate(val, model) {
            switch (Ember.get(model, 'type')) {
              case 'COOP':
              case 'ASSOCIATION':
                return !val || _this3._validateRange(val, 1, null);

              default:
                return true;
            }
          },
          errorTitle: this.get('l10n').t('Number of farms'),
          errorMessage: this.get('l10n').t('You have to enter a valid amount of number of farms.')
        }, {
          field: 'numMembers',
          validate: function validate(val, model) {
            switch (Ember.get(model, 'type')) {
              case 'COOP':
              case 'ASSOCIATION':
                return !val || _this3._validateRange(val, 1, null);

              default:
                return true;
            }
          },
          errorTitle: this.get('l10n').t('Number of farm members'),
          errorMessage: this.get('l10n').t('You have to enter a valid amount of number of farm members.')
        }, {
          field: 'location.country',
          validate: this._validateRequired,
          errorTitle: this.get('l10n').t('Country'),
          errorMessage: this.get('l10n').t('You have to select a country.')
        }
        /* {
         field: "farmSize",
         validate: (val) => this._validateRequired(val) && this._validateRange(val, 1, null),
         errorTitle: this.get("l10n").t("Farm size"),
         errorMessage: this.get("l10n").t("You have to enter a valid farm size.")
         },
         {
         field: "coffeeGrowingArea",
         validate: (val) => this._validateRequired(val) && this._validateRange(val, 1, null),
         errorTitle: this.get("l10n").t("Coffee growing area"),
         errorMessage: this.get("l10n").t("You have to enter a valid coffee growing area.")
         },
        {
          field: "story",
          validate: this._validateRequired,
          errorTitle: this.get("l10n").t("Producer story"),
          errorMessage: this.get("l10n").t("You have to enter an origin story.")
        },*/
        ];
      }
    }),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * All errors as array.
     *
     * @property allErrors
     * @type {Array}
     * @public
     */
    allErrors: Ember.computed(function () {
      return [];
    }),

    /**
     * All errors as object. Errors are available via their field name, e.g. via "errors.myField".
     *
     * @property errors
     * @type {Object}
     * @public
     */
    errors: Ember.computed(function () {}),
    // -------------------------------------------------------------------------
    // Validator Functions

    /**
     * Returns true if the value is not empty.
     *
     * @method _validateRequired
     * @param {String}|Object|Number|Boolean|Array value
     * @returns Boolean
     * @private
     */
    _validateRequired: function _validateRequired(value) {
      return !!value;
    },

    /**
     * Validate the length of a string (or array).
     *
     * @method _validateLength
     * @param {String}|Array value
     * @param {Number} min
     * @param {Number} max
     * @returns Boolean
     * @private
     */
    _validateLength: function _validateLength(value) {
      var min = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var max = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

      if (!value || !value.length) {
        if (min !== null) {
          return false;
        }

        return true;
      }

      if (min !== null && value.length < min) {
        return false;
      }

      if (max !== null && value.length > max) {
        return false;
      }

      return true;
    },

    /**
     * Validate the content of a number.
     *
     * @method _validateRange
     * @param {Number} value
     * @param {Number} min
     * @param {Number} max
     * @returns Boolean
     * @private
     */
    _validateRange: function _validateRange(value) {
      var min = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var max = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

      if (!this._validateNumber(value)) {
        return false;
      }

      if (min !== null && value < min) {
        return false;
      }

      if (max !== null && value > max) {
        return false;
      }

      return true;
    },

    /**
     * Validate the field for numbers. Optionally, only validate integers.
     *
     * @method _validateNumber
     * @param {String}|Object|Number|Boolean|Array value
     * @param {Boolean} [onlyInteger]
     * @private
     */
    _validateNumber: function _validateNumber(value) {
      var onlyInteger = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      if (!value) {
        return true;
      }

      if (!onlyInteger) {
        return Ember.typeOf(value * 1) === 'number' && !isNaN(value * 1);
      }

      if (value * 1 === parseInt(value, 10)) {
        return true;
      }

      return false;
    },

    /**
     * Validate a phone number. This is quite lax and should mainly fail for letters in the string.
     *
     * @method _validatePhone
     * @param {String}|Object|Number|Boolean|Array value
     * @returns Boolean
     * @private
     */
    _validatePhone: function _validatePhone(value) {
      if (!value) {
        return true;
      } // eslint-disable-next-line no-useless-escape


      var re = /([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
      return re.test(value);
    },

    /**
     * Validate an email address. This is extremly lax and only checks for the presence of an @ inside of the string.
     *
     * @method _validateEmail
     * @param value
     * @returns Boolean
     * @private
     */
    _validateEmail: function _validateEmail(value) {
      if (!value) {
        return true;
      }

      return (value + '').indexOf('@') > -1;
    },

    /**
     * Validates an url slug name, which have to consist only of
     * lowercase letters, numbers and dashes and should not be a
     * number only value to avoid clashes with entity identifiers.
     * Can be configured to have minimum (and maximum) length too.
     *
     * @method _validateSlug
     * @param value
     * @param min
     * @param max
     * @returns Boolean
     * @private
     */
    _validateSlug: function _validateSlug(value) {
      var min = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      var max = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
      var length;

      if (!min && !max) {
        length = '*';
      } else if (!min && max) {
        length = "{".concat(max, "}");
      } else if (min && !max) {
        length = "{".concat(min, ",}");
      } else {
        length = "{".concat(min, ",").concat(max, "}");
      }

      var re = new RegExp("(?!^[0-9]+$)^[a-z0-9-]".concat(length, "$"));
      return re.test(value);
    }
  });

  _exports.default = _default;
});