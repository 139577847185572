define("@vollersgroup/hub-frontend/components/flickering-fire/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BPBno0GA",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"isOnFire\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"fire__wrapper inline-block\"],[12,\"style\",[21,\"style\"]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"fire__flicker\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"fire__flickr__inner\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"img\"],[11,\"src\",\"/assets/img/icons/fire.svg\"],[12,\"alt\",[27,\"t\",[\"Fire\"],null]],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/flickering-fire/template.hbs"
    }
  });

  _exports.default = _default;
});