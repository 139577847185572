define("@vollersgroup/hub-frontend/admin/sales/list/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Es2v+fzE",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"wrapper padding-bottom-4\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"orders\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"sales-by-order\",null,[[\"orders\",\"showShippingAddress\"],[[23,[\"orders\"]],[23,[\"showShippingAddress\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"text-center padding-all-3\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"No orders in this view.\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/admin/sales/list/index/template.hbs"
    }
  });

  _exports.default = _default;
});