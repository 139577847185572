define("@vollersgroup/hub-frontend/components/listing-running-auction/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V/L3C9cG",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"listing__item__auction__time margin-bottom-2\"],[9],[0,\"\\n  \"],[1,[27,\"listing-timer-price\",null,[[\"listing\"],[[23,[\"listing\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"button__container text-right margin-bottom-2 margin-top-2\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"store.auctions.show\",[23,[\"listing\",\"auction\"]]],[[\"bubbles\",\"class\"],[false,\"button button--special button--half-height\"]],{\"statements\":[[0,\"    \"],[1,[27,\"t\",[\"View auction\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/listing-running-auction/template.hbs"
    }
  });

  _exports.default = _default;
});