define("@vollersgroup/hub-frontend/admin/manage/overview/registrant/route", ["exports", "@vollersgroup/hub-frontend/mixins/reset-scroll", "@vollersgroup/hub-frontend/utils/get-first-adapter-error-message"], function (_exports, _resetScroll, _getFirstAdapterErrorMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_resetScroll.default, {
    model: function model(params) {
      return this.store.find('auction-participant', params.registrant_id);
    },

    /**
     * Proxy route on controller for action.
     *
     * @public
     * @method setupController
     * @param {Ember.Controller} controller
     * @param {Model.AuctionParticipant} model
     * return {Void}
     */
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('route', this);
    },
    actions: {
      saveNotes: function saveNotes() {
        var _this = this;

        var auctionParticipant = this.modelFor(this.get('routeName'));
        auctionParticipant.save().then(function () {
          var message = Ember.get(_this, 'l10n').t('Your notes have been successfully updated!');

          _this.get('toast').success(message);
        }).catch(function (adapterError) {
          var serverErrorMessage = (0, _getFirstAdapterErrorMessage.default)(adapterError);
          var errorMessage = Ember.get(_this, 'l10n').t('An error occurred while saving your notes: {{serverErrorMessage}}', {
            serverErrorMessage: serverErrorMessage
          });

          _this.get('toast').error(errorMessage);
        });
      }
    }
  });

  _exports.default = _default;
});