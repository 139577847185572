define("@vollersgroup/hub-frontend/components/prompt-buttons/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W0xZXEwq",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[22,0,[\"isEditing\"]],[27,\"action\",[[22,0,[]],\"changeEditMode\"],null]]],[0,\"\\n\\n\"],[4,\"if\",[[22,0,[\"isEditing\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\"],[11,\"class\",\"button button--half-height\"],[9],[1,[27,\"t\",[\"Save\"],null],false],[3,\"action\",[[22,0,[]],\"save\"]],[10],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"button button--secondary button--half-height\"],[9],[1,[27,\"t\",[\"Cancel\"],null],false],[3,\"action\",[[22,0,[]],\"cancel\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/prompt-buttons/template.hbs"
    }
  });

  _exports.default = _default;
});