define("@vollersgroup/hub-frontend/pricing/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3y2UdL7f",
    "block": "{\"symbols\":[],\"statements\":[[1,[21,\"header-bar\"],false],[0,\"\\n\\n\"],[1,[21,\"pricing-plans\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/pricing/template.hbs"
    }
  });

  _exports.default = _default;
});