define("@vollersgroup/hub-frontend/initializers/bulk-manager", ["exports", "ember-bulk-manager/components/sub/bulk-select", "ember-bulk-manager/components/sub/bulk-import-prefill-field-checkbox"], function (_exports, _bulkSelect, _bulkImportPrefillFieldCheckbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'bulk-manager',
    initialize: function initialize() {
      /**
       * This extends the bulk-select component to wrap it with
       * a <div class="bulk-select-wrapper"></div> container.
       *
       * @namespace Component
       * @extends BulkSelect
       */
      _bulkSelect.default.reopen({
        // -------------------------------------------------------------------------
        // Methods

        /**
         * Triggered when element gains focus.
         *
         * @public
         * @method focusIn
         * @return {Void}
         */
        focusIn: function focusIn() {
          this.$().parent().addClass('is-focused');
        },

        /**
         * Triggered when element loses focus.
         *
         * @public
         * @method focusOut
         * @return {Void}
         */
        focusOut: function focusOut() {
          this.$().parent().removeClass('is-focused');
        },

        /**
         * Wraps <select> when in DOM.
         *
         * @public
         * @method didInsertElement
         * @return {Void}
         */
        didInsertElement: function didInsertElement() {
          this._super.apply(this, arguments);

          var element = Ember.$('<div class="bulk-select-wrapper"/>');

          if (Ember.get(this, 'disabled')) {
            element.addClass('bulk-select-wrapper--disabled');
          }

          element.addClass(Ember.get(this, 'multiple') ? 'bulk-select-wrapper--multiple' : 'bulk-select-wrapper--single');
          this.$().wrap(element);
        }
      });
      /**
       * This extends the bulk-import-prefill-field-checkbox component
       * and appends an extra label after checkbox to fit hub styling.
       *
       * @namespace Component
       * @extends BulkImportPrefillFieldCheckbox
       */


      _bulkImportPrefillFieldCheckbox.default.reopen({
        // -------------------------------------------------------------------------
        // Methods

        /**
         * Wraps <select> when in DOM.
         *
         * @public
         * @method didInsertElement
         * @return {Void}
         */
        didInsertElement: function didInsertElement() {
          this._super.apply(this, arguments);

          var id = Ember.get(this, 'id');
          this.$().after("<label for=\"".concat(id, "\"/>"));
        }
      });
    }
  };
  _exports.default = _default;
});