define("@vollersgroup/hub-frontend/components/shopping-cart-order-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A single item inside of a cart
   * A cart item belongs to a cart and is associated with a specific cart
   *
   * ```html
   * {{cart-item orderItem=orderItem isEditable=true}}
   * ```
   *
   * @namespace Component
   * @class CartItem
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Element Properties

    /**
     * @property classNames
     * @type Array
     * @final
     * @private
     */
    classNames: ['listing__item', 'listing__item--checkout'],
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * The Order Item that should be displayed
     *
     * @attribute orderItem
     * @type Model.OrderItem
     * @required
     * @public
     */
    orderItem: null,

    /**
     * If the cart-item is editable.
     * If this is false, the cart item can be neither removed nor can its amounts be changed.
     *
     *
     * @attribute isEditable
     * @type Boolean
     * @default true
     * @optional
     * @public
     */
    isEditable: true,
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Delete the cart item
       *
       * @event removeItem
       * @private
       */
      removeItem: function removeItem() {
        if (this.getAttr('removeAction') && this.getAttr('isEditable')) {
          this.attrs.removeAction(this.getAttr('orderItem'));
        }
      }
    }
  });

  _exports.default = _default;
});