define("@vollersgroup/hub-frontend/components/order-review-request/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    availableData: Ember.inject.service(),
    userSession: Ember.inject.service(),
    toast: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * The order to review.
     *
     * @attribute order
     * @type Model.Order
     */
    order: null,

    /**
     * If the order is currently loading / saving.
     *
     * @attribute isLoading
     * @type Boolean
     */
    isLoading: false,

    /**
     * The action to call when the offer is sent.
     *
     * @event action
     * @param {}
     * @public
     */
    action: null,

    /**
     * The action to call when the offer should be rejected.
     *
     * @event rejectAction
     * @public
     */
    rejectAction: null,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this._resetProperties();
    },
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      sendOffer: function sendOffer() {
        var options = {
          orderNumber: this.get('orderNumber'),
          orderNotes: this.get('orderNotes'),
          shippingTerms: this.get('shippingTerms'),
          additionalCosts: this.get('additionalCosts'),
          documents: this.get('documents')
        };
        this.sendAction('action', options);
        this.set('additionalCosts', []);
        this.set('documents', []);
      },
      rejectRequest: function rejectRequest() {
        this.sendAction('rejectAction');
      },

      /**
       * Add an additional cost.
       *
       * @event addAdditionalCost
       * @private
       */
      addAdditionalCost: function addAdditionalCost() {
        var label = this.get('addCostLabel');
        var amount = this.get('addCostAmount');

        if (!label || !amount) {
          return;
        }

        if (Ember.typeOf(amount * 1) !== 'number' || isNaN(amount * 1)) {
          this.get('toast').error(this.get('l10n').t('You have to enter a number as amount for added costs.'));
          return;
        }

        this.get('additionalCosts').pushObject({
          name: label,
          costAmount: amount * 1,
          costCurrency: this.getAttr('order').get('shop.currency')
        });
        this.setProperties({
          addCostLabel: '',
          addCostAmount: ''
        });
      },

      /**
       * Remove an additional cost.
       *
       * @event removeAdditionalCost
       * @param {Object} cost
       * @private
       */
      removeAdditionalCost: function removeAdditionalCost(cost) {
        this.get('additionalCosts').removeObject(cost);
      },

      /**
       * Update the documents.
       *
       * @event changeDocuments
       * @param {Array} files
       * @private
       */
      changeDocuments: function changeDocuments(files) {
        this.set('documents', files);
      },

      /**
       * Toggle the additional notes field.
       *
       * @event toggleAdditionalNotes
       * @private
       */
      toggleAdditionalNotes: function toggleAdditionalNotes() {
        this.toggleProperty('addAdditionalNotes');
      }
    },
    // -------------------------------------------------------------------------
    // Properties
    orderNumber: null,
    shippingTerms: 'FOB',
    additionalCosts: Ember.computed(function () {
      return [];
    }),
    documents: Ember.computed(function () {
      return [];
    }),
    orderNotes: null,

    /**
     * The label of the additional cost to add.
     *
     * @property addCostLabel
     * @type String
     */
    addCostLabel: null,

    /**
     * The amount of the additional cost to add.
     *
     * @property addCostAmount
     * @type Number
     */
    addCostAmount: null,

    /**
     * If the input fields for additional notes should be displayed.
     *
     * @property addAdditionalNotes
     * @type Boolean
     * @private
     */
    addAdditionalNotes: false,

    /**
     * If any price of the offer has changed or if an additional cost has been added.
     *
     * @property offerHasChanged
     * @type Boolean
     */
    canDirectAcceptRequest: Ember.computed('order.priorPaymentRequired', 'order.offerHasChanged', 'additionalCosts.length', 'order.hasCoffee', function () {
      if (Ember.get(this, 'order.priorPaymentRequired')) {
        return true;
      }

      return !this.getAttr('order').get('offerHasChanged') && !this.get('additionalCosts.length') && !this.getAttr('order').get('hasCoffee');
    }),

    /**
     * Determines if seller can request payment for this order.
     *
     * @property canRequestPayment
     * @type Boolean
     */
    canRequestPayment: Ember.computed('estimatedTotalPrice', 'order.priorPaymentRequired', 'userSession.currentCompany.settings.canReceivePayments', function () {
      var canReceivePayments = this.get('userSession.currentCompany.settings.canReceivePayments');
      var priorPaymentRequired = this.get('order.priorPaymentRequired');
      var estimatedTotalPrice = this.get('estimatedTotalPrice'); // a) company must be enabled by cropster

      if (!canReceivePayments) {
        return false;
      } // b) order must not be express checkout


      if (priorPaymentRequired) {
        return false;
      } // c) ignore free sample orders


      if (!estimatedTotalPrice) {
        return false;
      }

      return true;
    }),

    /**
     * The date until which the offer will be valid.
     *
     * @property offerValidUntil
     * @type Date
     */
    offerValidUntil: Ember.computed(function () {
      var date = (0, _moment.default)();
      date = date.add(2, 'weeks');
      return date;
    }),

    /**
     * The estimated total price of all order-items of this order.
     * Is the sum of the estimated prices of all order-items.
     *
     * @property estimatedTotalPrice
     * @type Number
     * @readOnly
     */
    estimatedTotalPrice: Ember.computed('order.orderItemsCoffee.@each.estimatedTotalPrice', 'additionalCosts.@each.costAmount', 'order.estimatedTotalSamplePrice', function () {
      var total = 0;
      this.getAttr('order').get('orderItemsCoffee').forEach(function (item) {
        if (item.get('estimatedTotalPrice')) {
          total += item.get('estimatedTotalPrice') * 1;
        }
      });
      this.get('additionalCosts').forEach(function (item) {
        total += item.costAmount * 1;
      });

      if (Ember.get(this, 'order.estimatedTotalSamplePrice')) {
        total += Ember.get(this, 'order.estimatedTotalSamplePrice') * 1;
      }

      return total;
    }),
    _resetProperties: function _resetProperties() {
      this.set('additionalCosts', []);
      this.set('documents', []);
    }
  });

  _exports.default = _default;
});