define("@vollersgroup/hub-frontend/admin/account/saved-searches/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vUPqJoZq",
    "block": "{\"symbols\":[\"searchAgent\"],\"statements\":[[7,\"h1\"],[11,\"class\",\"font-size-3\"],[9],[0,\"\\n  \"],[1,[27,\"t\",[\"Saved searches\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"each\",[[23,[\"model\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"search-agent-item\",null,[[\"searchAgent\",\"class\"],[[22,1,[]],\"margin-bottom-3\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[4,\"info-message\",null,[[\"type\",\"class\"],[\"INFO\",\"margin-bottom-2\"]],{\"statements\":[[0,\"    \"],[1,[27,\"t\",[\"You have no saved search yet. Go to the store to configure and save your first search agent.\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\"],[11,\"class\",\"margin-top-3\"],[9],[0,\"\\n    \"],[4,\"link-to\",[\"store\"],[[\"class\"],[\"button\"]],{\"statements\":[[1,[27,\"t\",[\"Go to store\"],null],false]],\"parameters\":[]},null],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/admin/account/saved-searches/template.hbs"
    }
  });

  _exports.default = _default;
});