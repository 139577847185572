define("@vollersgroup/hub-frontend/components/c-date-picker/component", ["exports", "moment", "pikaday"], function (_exports, _moment, _pikaday) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A simple date picker. Uses pikadayResponsive to show either a Pikaday-Datepicker or a native date picker on mobile devices.
   *
   * ```html
   * {{c-date-picker value=myDate placeholder="my-placeholder" id="my-id"}}
   * ```
   *
   * @namespace Component
   * @class CDatePicker
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    type: 'text',
    tagName: 'input',
    classNames: ['input__txt', 'input__full'],
    classNameBindings: ['hasError:input--has-error'],
    attributeBindings: ['type', 'disabled', 'required', 'readonly', 'placeholder', 'elementId:name'],
    // -------------------------------------------------------------------------
    // Dependencies
    l10n: Ember.inject.service(),
    userSession: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * Initial date value of the picker. This can
     * be a formatted date string, a timestamp, a
     * native date object or a moment object.
     *
     * @attribute value
     * @optional
     * @type mixed
     * @default null
     */
    value: null,

    /**
     * A custom date format. Falls back to the
     * `dateFormat` from user's account settings.
     *
     * @attribute format
     * @optional
     * @type String
     */
    format: null,

    /**
     * Minimum date required for this picker.
     *
     * @attribute minDate
     * @optional
     * @type Date
     */
    minDate: null,

    /**
     * Maximum date required for this picker.
     *
     * @attribute maxDate
     * @optional
     * @type Date
     */
    maxDate: null,

    /**
     * The action to call when the date is changed.
     * This can be used in conjunction with ember's
     * `readonly` helper for one-way-bindings.
     *
     * @attribute action
     * @type String
     * @optional
     */
    action: null,

    /**
     * Indicated wheter editor should display error.
     *
     * @attribute hasError
     * @optional
     * @type Boolean
     * @default false
     */
    hasError: false,
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Keeps reference to pikaday instance.
     *
     * @private
     * @property _picker
     * @type {String}
     */
    _picker: null,

    /**
     * Gets current format to be used depending
     * on component attributes and user session.
     *
     * @private
     * @property _format
     * @type {String}
     */
    _format: Ember.computed('format', 'userSession.dateFormat', {
      get: function get()
      /*key*/
      {
        var userFormat = Ember.get(this, 'userSession.dateFormat');
        var customFormat = Ember.get(this, 'format');
        return customFormat || userFormat;
      }
    }),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Initializes pikaday with configured values.
     *
     * @public
     * @method didInsertElement
     * @return {Void}
     */
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var l10n = Ember.get(this, 'l10n');
      var format = Ember.get(this, '_format');
      var minDate = Ember.get(this, 'minDate');
      var maxDate = Ember.get(this, 'maxDate');

      var _picker = new _pikaday.default({
        format: format,
        firstDay: 1,
        field: this.$().get(0),
        i18n: {
          nextMonth: l10n.t('Next month'),
          previousMonth: l10n.t('Previous month'),
          weekdaysShort: _moment.default.weekdaysShort(),
          weekdays: _moment.default.weekdays(),
          months: _moment.default.months()
        },
        minDate: this._toDate(minDate),
        maxDate: this._toDate(maxDate),
        toString: Ember.run.bind(this, this._toString),
        onSelect: Ember.run.bind(this, this._onSelect),
        onClose: Ember.run.bind(this, this._onClose),
        parse: function parse(dateString, format) {
          // get rid of JS' Date.parse() inconcistency by
          // using constantly the UTC parsed date string!
          return _moment.default.utc(dateString, format).toDate();
        }
      });

      Ember.set(this, '_picker', _picker);

      this._setDate();
    },

    /**
     * Tears down listeners for `pikaday` plugin.
     *
     * @public
     * @method willDestroyElement
     * @return {Void}
     */
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var _picker = Ember.get(this, '_picker');

      if (Ember.isNone(_picker)) {
        return;
      }

      _picker.destroy();
    },

    /**
     * Update `minDate`, `maxDate` and `date` in picker.
     *
     * @public
     * @method didUpdateAttrs
     * @return {Void}
     */
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this._setMinDate();

      this._setMaxDate();

      this._setDate();
    },

    /**
     * Bind to `change` event <input type="text">.
     *
     * @method change
     * @param {Object} evt
     * @return {Void}
     * @public
     */
    change: function change(evt) {
      var _picker = Ember.get(this, '_picker');

      if (Ember.isNone(_picker)) {
        return;
      }

      var value = evt.target.value;

      if (!Ember.isBlank(value)) {
        return;
      } // Pikaday doesn't fire `onSelect`
      // if the input field gets cleared,
      // but avoid infinite loop if date
      // is already set to `null` before


      if (Ember.isNone(_picker.getDate())) {
        return;
      } // this resets selected day, but it
      // does NOT invoke `onSelect` call!


      _picker.setDate(null); // so we update value manually!


      this._update(null);
    },

    /**
     * Propagate change to `value` and action.
     *
     * @private
     * @method _update
     * @param {Mixed} value
     * @return {Void}
     */
    _update: function _update(value) {
      var action = Ember.get(this, 'action');

      if (typeof action === 'function') {
        action(value);
      }

      Ember.set(this, 'value', value);
    },

    /**
     * Sets current date from `value` in picker.
     *
     * @private
     * @method _setDate
     * @return {Void}
     */
    _setDate: function _setDate() {
      var _picker = Ember.get(this, '_picker');

      if (Ember.isNone(_picker)) {
        return;
      }

      var value = Ember.get(this, 'value');

      var date = this._toDate(value);

      _picker.setDate(date);
    },

    /**
     * Sets min date from `minDate` in picker.
     *
     * @private
     * @method _setDate
     * @return {Void}
     */
    _setMinDate: function _setMinDate() {
      var _picker = Ember.get(this, '_picker');

      if (Ember.isNone(_picker)) {
        return;
      }

      var value = Ember.get(this, 'minDate');

      var date = this._toDate(value);

      if (Ember.isNone(date)) {
        return;
      }

      _picker.setMinDate(date);
    },

    /**
     * Sets max date from `maxDate` in picker.
     *
     * @private
     * @method _setDate
     * @return {Void}
     */
    _setMaxDate: function _setMaxDate() {
      var _picker = Ember.get(this, '_picker');

      if (Ember.isNone(_picker)) {
        return;
      }

      var value = Ember.get(this, 'maxDate');

      var date = this._toDate(value);

      if (Ember.isNone(date)) {
        return;
      }

      _picker.setMaxDate(date);
    },

    /**
     * Sets `value` to moment from Pikaday
     * and tries to invoke `action` action.
     *
     * @private
     * @method _change
     * @return {Void}
     */
    _onSelect: function _onSelect() {
      var _picker = Ember.get(this, '_picker'); // do NOT use moment object of `Pikaday` cause it
      // consists of a time zone aware moment object!


      var oldDate = this._toMoment(Ember.get(this, 'value'));

      var newDate = this._toMoment(_picker.getDate()); // do NOT invoke update if dates are still same!


      if (!Ember.isNone(oldDate) && !Ember.isNone(newDate)) {
        var newFormatted = newDate.format('YYYY-MM-DD');
        var oldFormatted = oldDate.format('YYYY-MM-DD');

        if (newFormatted === oldFormatted) {
          return;
        }
      }

      this._update(newDate);
    },

    /**
     * Resets picker if no selection was made.
     *
     * @private
     * @method _change
     * @return {Void}
     */
    _onClose: function _onClose() {
      var _picker = Ember.get(this, '_picker');

      var value = Ember.get(this, 'value');

      if (!Ember.isNone(value)) {
        return;
      }

      _picker.setDate(null);
    },

    /**
     * Custom formatting function for Pikaday.
     *
     * @private
     * @method _change
     * @return {Void}
     */
    _toString: function _toString(date, format) {
      date = this._toMoment(date); // displaying current date in
      // picker required ALWAYS the
      // user's current locale zone
      // otherwise it would jump to
      // another date if utc offset

      date.local();
      return date.format(format);
    },

    /**
     * Parses a mixed value into a moment object.
     *
     * @private
     * @method _toMoment
     * @param {mixed} value
     * @param {direction} [string]
     * @return {Object|null}
     */
    _toMoment: function _toMoment(value) {
      // skip undefined/null values
      if (Ember.isNone(value)) {
        return null;
      } // try converting to moment


      if (!_moment.default.isMoment(value)) {
        switch (Ember.typeOf(value)) {
          case 'string':
            // try to parse date strings and even
            // stringified timestamps (attribute)
            // eslint-disable-next-line no-case-declarations
            var format = Ember.get(this, '_format');
            value = !isNaN(+value) ? value = _moment.default.utc(+value) : value = _moment.default.utc(value, format);
            break;

          case 'date':
            // create moment with default zone
            // ONLY from year, month and date
            // to get rid of any time diffs!
            value = _moment.default.utc([value.getFullYear(), value.getMonth(), value.getDate()]);
            break;

          default:
            value = _moment.default.utc(value);
        }
      } // seems to be invalid input


      if (!value.isValid()) {
        return null;
      } // valid moment


      return value;
    },

    /**
     * Parses a mixed value into a native date.
     *
     * @private
     * @method _toDate
     * @param {mixed} value
     * @return {Date|null}
     */
    _toDate: function _toDate(value) {
      var date = this._toMoment(value);

      if (Ember.isNone(date)) {
        return null;
      }

      return new Date(date.year(), date.month(), date.date());
    }
  });

  _exports.default = _default;
});