define("@vollersgroup/hub-frontend/components/c-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A simple button with an optional loading state.
   *
   * ```hbs
   * {{#c-button isLoading=false loadingText="Loading now..." class="button button--plain" action="saveModel"}}Save Now!{{/c-button}}
   * ```
   *
   * @namespace Component
   * @class CButton
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    tagName: '',

    /**
     * While this is true, a loading spinner will be displayed.
     *
     * @attribute isLoading
     * @type Boolean
     * @default false
     */
    isLoading: false,

    /**
     * An optional text that should be displayed while the button is in a loading state.
     *
     * @attribute loadingText
     * @type String
     * @optional
     * @default "Loading..."
     */
    loadingText: null,

    /**
     * If this is set, the button should not be inline.
     *
     * @attribute isBlock
     * @type Boolean
     * @optional
     * @default false
     */
    isBlock: false,

    /**
     * The class for the button.
     *
     * @attribute class
     * @type String
     * @optional
     */
    class: null,

    /**
     * An optional id for the button.
     *
     * @attribute id
     * @type String
     * @optional
     */
    id: null,

    /**
     * The action to call when the button is clicked.
     *
     * @attribute action
     * @type String
     */
    action: null,

    /**
     * If the default action should be prevented
     * when the button action gets invoked.
     *
     * @attribute preventDefault
     * @type Boolean
     * @optional
     * @default true
     */
    preventDefault: true,

    /**
     * The button type to be added to element.
     *
     * @attribute type
     * @type String
     * @optional
     * @default "button"
     */
    type: 'button',

    /**
     * If event bubbling should be avoided
     * when the button action gets invoked.
     *
     * @attribute bubbles
     * @type Boolean
     * @optional
     * @default true
     */
    bubbles: true,
    actions: {
      click: function click(event) {
        var _this$attrs;

        // manual handling necessary cause of this issue:
        // https://github.com/emberjs/ember.js/issues/13062
        if (this.get('preventDefault')) {
          event.preventDefault();
        }

        if (!this.get('bubbles')) {
          event.stopPropagation();
        }

        var action = this.getAttr('action');

        if (!action) {
          return true;
        }

        if (typeof action === 'string') {
          this.sendAction();
          return;
        }

        (_this$attrs = this.attrs).action.apply(_this$attrs, arguments);
      }
    }
  });

  _exports.default = _default;
});