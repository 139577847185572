define("@vollersgroup/hub-frontend/components/auction-participant-table-row-listing/component", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PromiseArray = _emberData.default.PromiseArray;
  /**
   * A row containing a listing, where given participant is currently the
   * highest bidder and stating information about total bids done.
   *
   * ```hbs
   * {{auction-participants-table-row-listing participant=myParticipant listing=myListing}}
   * ```
   *
   * @namespace Component
   * @class AuctionParticipantsTable
   * @extends Ember.Component
   */

  var _default = Ember.Component.extend({
    tagName: 'tr',
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * Listing Model.
     *
     * @attribute listing
     * @type {Model.Listing}
     */
    listing: null,

    /**
     * Auction Participant Model.
     *
     * @attribute participant
     * @type {Model.AuctionParticipant}
     */
    participant: null,
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Array containing all bids of current listing
     * the company bid on in current auction.
     *
     * @property participantBids
     * @type {Model.Bid.[]}
     * @public
     */
    participantBids: Ember.computed('listing.bids.[]', 'participant.companyHash', function () {
      var participantBids = [];
      var listing = this.get('listing');
      var companyHash = this.get('participant.companyHash');
      var promise = new Ember.RSVP.Promise(function (resolve, reject) {
        listing.get('bids').then(function (bids) {
          bids.forEach(function (bid) {
            var participantName = bid.get('participantName');

            if (participantName === companyHash) {
              participantBids.addObject(bid);
            }
          });
          resolve(participantBids);
        }, reject);
      });
      return PromiseArray.create({
        promise: promise
      });
    })
  });

  _exports.default = _default;
});