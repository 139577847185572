define("@vollersgroup/hub-frontend/utils/unit-calculator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unitCalculatorWeight = unitCalculatorWeight;
  _exports.unitKgToLbs = unitKgToLbs;
  _exports.unitLbsToKg = unitLbsToKg;

  /**
   * Calculate the actual weight from a package unit
   *
   * @param {number} amount The amount of packageUnits
   * @param {string} packageUnit The packageUnit to calculate with
   * @param {"kg"|"lbs"} [baseUnit=kg] The baseUnit to return the weight in.
   *
   * @return float Returns the weight in the specified baseUnit
   */
  function unitCalculatorWeight(amount, packageUnit) {
    var baseUnit = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'kg';
    (false && !(Ember.typeOf(amount) === 'number') && Ember.assert('amount is required', Ember.typeOf(amount) === 'number'));
    (false && !(packageUnit) && Ember.assert('packageUnit is required', packageUnit));
    (false && !(baseUnit) && Ember.assert('baseUnit is required', baseUnit));
    var factors = {
      BARREL15KG: 15,
      BAG1KG: 1,
      BAG2KG: 2,
      BAG4KG: 4,
      BAG8KG: 8,
      BAG10KG: 10,
      BAG15KG: 15,
      BAG16KG: 16,
      BAG17KG: 17,
      BOX12_1KG: 12.1,
      BOX16KG: 16,
      BOX20KG: 20,
      BOX24KG: 24,
      BOX24_2KG: 24.2,
      BOX25KG: 25,
      BAG20KG: 20,
      BAG30KG: 30,
      BOX30KG: 30,
      BAG25KG: 25,
      BAG32KG: 32,
      BAG35KG: 35,
      BOX35KG: 35,
      BAG45KG: 45,
      BAG46KG: 46,
      BAG50KG: 50,
      BAG60KG: 60,
      BAG62_5KG: 62.5,
      BAG64KG: 64,
      BAG69KG: 69,
      BAG70KG: 70,
      BAG100LBS: 45.359237,
      BAG50LBS: 22.679619,
      BOX50LBS: 22.679619,
      BOX65LBS: 29.483504,
      LBS: 0.45359237,
      KG: 1
    };
    var factor = factors[packageUnit];
    (false && !(factor) && Ember.assert("Unknown factor for packageUnit:".concat(packageUnit), factor));
    var amountSI = amount * factor;
    baseUnit = baseUnit.toLowerCase();

    if (baseUnit === 'lb' || baseUnit === 'lbs') {
      return unitKgToLbs(amountSI).toFixed(4);
    }

    return amountSI.toFixed(4);
  }
  /**
   * Factor to calculate from LBS to KG.
   *
   * @property
   * @type {float}
   */


  var LBS_TO_KG_FACTOR = 0.45359237;
  /**
   * Converts KG to LBS.
   *
   * @param {number} amount Amount to convert.
   * @return float
   */

  function unitKgToLbs(amount) {
    return amount * (1 / LBS_TO_KG_FACTOR);
  }
  /**
   * Converts LBS to KG.
   *
   * @param {number} amount Amount to convert.
   * @return float
   */


  function unitLbsToKg(amount) {
    return amount * LBS_TO_KG_FACTOR;
  }
});