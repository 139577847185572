define("@vollersgroup/hub-frontend/shop/ability", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend({
    userSession: Ember.inject.service(),
    companySettings: Ember.computed.alias('model.company.settings'),
    roleLevel: Ember.computed.alias('userSession.currentUser.roleLevel'),
    canReceivePayments: Ember.computed('model.isEditableAll', 'companySettings.canReceivePayments', function () {
      var canReceivePayments = Ember.get(this, 'companySettings.canReceivePayments');
      var isEditableAll = Ember.get(this, 'model.isEditableAll');
      return isEditableAll && canReceivePayments;
    }),
    canCreate: Ember.computed('roleLevel', {
      get: function get() {
        return Ember.get(this, 'roleLevel') >= 4;
      }
    }),
    canEdit: Ember.computed.gte('roleLevel', 2)
  });

  _exports.default = _default;
});