define("@vollersgroup/hub-frontend/store/shops/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    l10n: Ember.inject.service(),

    /**
     * Query parameters.
     *
     * @final
     * @public
     * @type {Object}
     */
    queryParams: {
      searchText: 'q',
      orderBy: 'o',
      page: 'page',
      pageSize: 'pageSize'
    },

    /**
     * The current page
     *
     * @property page
     * @public
     * @type Number
     */
    page: 1,

    /**
     * The current page size.
     *
     * @property pageSize
     * @public
     * @type Number
     */
    pageSize: 12,

    /**
     * Search text for shops.
     *
     * @public
     * @type {String}
     */
    searchText: '',

    /**
     * Current order setting,
     * one of "DATE" or "NAME".
     *
     * @public
     * @type {String}
     */
    orderBy: 'DATE',

    /**
     * The available page size options.
     *
     * @property pageSizeOptions
     * @type Array
     * @final
     */
    pageSizeOptions: Ember.computed(function () {
      return [{
        value: 12,
        label: 12
      }, {
        value: 24,
        label: 24
      }, {
        value: 36,
        label: 36
      }, {
        value: 64,
        label: 64
      }, {
        value: 128,
        label: 128
      }];
    }),

    /**
     * Order options for shops.
     *
     * @public
     * @type {Array}
     */
    orderByOptions: Ember.computed('l10n', function () {
      return [{
        value: 'DATE',
        label: this.get('l10n').t('Newest')
      }, {
        value: 'NAME',
        label: this.get('l10n').t('Name')
      }];
    }),
    actions: {
      changePageSize: function changePageSize(value) {
        this.set('pageSize', value);
      }
    }
  });

  _exports.default = _default;
});