define("@vollersgroup/hub-frontend/components/expanding-textarea/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A simple component to show an expanding textarea which automatically fits to the size of its content.
   *
   * ```html
   * {{expanding-textarea id='my_id' required=false value=myTextareaValue placeholder='My placeholder' rows=3}}
   * ```
   *
   * @namespace Component
   * @class ExpandingTextarea
   * @extends Ember.TextArea
   */
  var _default = Ember.TextArea.extend({
    classNames: ['input__textarea', 'input-full'],
    classNameBindings: ['hasError:input--has-error'],
    rows: 2,
    init: function init() {
      this.elementId = this.name;

      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var rows = Ember.get(this, 'rows');
      this.$().css({
        resize: 'none',
        maxHeight: 400
      });

      if (rows) {
        this.$().css('minHeight', "".concat(rows * 1.5, "em"));
      }

      this.$().on('keyup input change', function () {
        Ember.run.debounce(_this, _this._setHeight, 100);
      });
    },
    _setHeight: function _setHeight() {
      if (Ember.get(this, 'isDestroyed')) {
        return;
      }

      var element = this.$().get(0);
      this.$().css('height', 'auto');
      this.$().height(element.scrollHeight);
    }
  });

  _exports.default = _default;
});