define("@vollersgroup/hub-frontend/components/auction-bidders-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "37Sk+8BF",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-overlay\",null,[[\"title\",\"modalClasses\",\"close\"],[[27,\"t\",[\"Bids for {{listingName}}\"],[[\"listingName\"],[[23,[\"listing\",\"name\"]]]]],\"modal--large\",[23,[\"closeModal\"]]]],{\"statements\":[[0,\"  \"],[1,[27,\"auction-bidders\",null,[[\"listing\",\"dateFormat\",\"timeFormat\"],[[23,[\"listing\"]],[23,[\"userSession\",\"dateFormat\"]],[23,[\"userSession\",\"timeFormat\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/auction-bidders-modal/template.hbs"
    }
  });

  _exports.default = _default;
});