define("@vollersgroup/hub-frontend/components/auction-header/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    websocket: Ember.inject.service(),
    userSession: Ember.inject.service(),
    auctionManager: Ember.inject.service(),
    keenTracking: Ember.inject.service(),
    auction: null,
    sampleAmount: 1,
    sampleAmountOptions: Ember.computed('auction.orderableSampleBundles', function () {
      var maxBundles = this.get('auction.orderableSampleBundles');
      var options = [];

      for (var i = 1; i <= maxBundles; i++) {
        options.push({
          value: i,
          label: i + 'x'
        });
      }

      return options;
    }),
    showAuctionTour: false,
    actions: {
      reload: function reload() {
        var model = Ember.get(this, 'auction');
        this.get('auctionManager').loadWebsockets(model);
        model.rollbackAttributes();
        model.reload();
      },
      registerForAuction: function registerForAuction() {
        this.sendAction('registerForAuctionAction');
      },
      addSamples: function addSamples() {
        this.sendAction('addSamplesAction', this.get('sampleAmount'));
      },
      dismissAuctionTour: function dismissAuctionTour() {
        this.set('showAuctionTour', false);
      },
      openAuctionTour: function openAuctionTour() {
        this.set('showAuctionTour', true); // NOTE: Added on 31.1.2017.
        // We want to track if users use auction tour at all.
        // In a half year or so, after a few auction passes, we
        // can see how many users use it in keen stats.

        this.get('keenTracking').addEvent('start-an-auction-tour-click', {
          auctionId: Ember.get(this, 'auction.id'),
          auctionName: Ember.get(this, 'auction.name')
        });
      }
    }
  });

  _exports.default = _default;
});