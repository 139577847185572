define("@vollersgroup/hub-frontend/helpers/capitalize-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.capitalizeText = capitalizeText;
  _exports.default = void 0;

  function capitalizeText(params) {
    if (!params) {
      return '';
    }

    var text = params[0];

    if (!text) {
      return '';
    }

    return text.capitalize();
  }

  var _default = Ember.Helper.helper(capitalizeText);

  _exports.default = _default;
});