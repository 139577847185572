define("@vollersgroup/hub-frontend/admin/controller", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_emberCan.CanMixin, {
    // -------------------------------------------------------------------------
    // Dependencies
    application: Ember.inject.controller(),
    availableData: Ember.inject.service(),
    legacyWarehouseMigration: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties
    queryParams: ['afterSave', 'editWarehouseId', 'createWarehouse', 'inviteUser'],
    // Manage warehouse modal query params
    editWarehouseId: null,
    createWarehouse: null,
    // Invite user modal query params
    inviteUser: null,

    /**
     * After save redirect route.
     *
     * @property afterSave
     * @public
     * @type String
     */
    afterSave: null,
    actions: {
      dismissWarehouseModal: function dismissWarehouseModal() {
        this.set('editWarehouseId', null);
        this.set('createWarehouse', null);
      },
      dismissInviteUserModal: function dismissInviteUserModal() {
        this.set('inviteUser', null);
      }
    }
  });

  _exports.default = _default;
});