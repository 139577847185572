define("@vollersgroup/hub-frontend/components/tag-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A simple list of tags.
   *
   * ```html
   * {{tag-list tags=myArray}}
   * ```
   *
   * @namespace Component
   * @class TagList
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    tagName: '',
    tags: null,
    sortedTags: Ember.computed.sort('tags', function (a, b) {
      switch (Ember.typeOf(a)) {
        case 'object':
        case 'instance':
          a = Ember.get(a, 'value') || Ember.get(a, 'name');
          b = Ember.get(b, 'value') || Ember.get(b, 'name');
          break;

        default:
      }

      return a.localeCompare(b);
    })
  });

  _exports.default = _default;
});