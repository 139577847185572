define("@vollersgroup/hub-frontend/services/flash-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Service.extend({
    errorMessages: Ember.computed(function () {
      return [];
    }),
    successMessages: Ember.computed(function () {
      return [];
    }),
    infoMessages: Ember.computed(function () {
      return [];
    }),
    hasMessage: Ember.computed.or('errorMessages.length', 'successMessages.length', 'infoMessages.length'),
    hasError: Ember.computed('errorMessages.length', function () {
      return !!this.get('errorMessages.length');
    }),
    clear: function clear() {
      this.set('errorMessages', []);
      this.set('successMessages', []);
      this.set('infoMessages', []);
    },
    setErrors: function setErrors() {
      var _this = this;

      var errors = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      this.set('errorMessages', []);
      errors.forEach(function (error) {
        _this.addError(error);
      });
    },
    addError: function addError(message) {
      this.get('errorMessages').pushObject(this._normalizeMessage(message));
    },
    addSuccess: function addSuccess(message) {
      this.get('successMessages').pushObject(this._normalizeMessage(message));
    },
    addInfo: function addInfo(message) {
      this.get('infoMessages').pushObject(this._normalizeMessage(message));
    },
    _normalizeMessage: function _normalizeMessage(message) {
      if (_typeof(message) !== 'object') {
        message = {
          message: message
        };
      }

      return message;
    }
  });

  _exports.default = _default;
});