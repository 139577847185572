define("@vollersgroup/hub-frontend/components/shop-slug-input/component", ["exports", "@vollersgroup/hub-frontend/utils/normalize-for-slug", "@vollersgroup/hub-frontend/components/validated-form-component/component"], function (_exports, _normalizeForSlug, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    router: Ember.inject.service(),
    valuePath: Ember.computed(function () {
      return 'slug';
    }),
    slugBaseUrl: Ember.computed('router', function () {
      var protocol = window.location.protocol;
      var hostname = window.location.hostname;
      var port = window.location.port ? ':' + window.location.port : '';
      var routePath = 'store.shop';
      var path = Ember.get(this, 'router').urlFor(routePath, null);
      var parts = path.split('/'); // Remove last part of the url
      // (e.g.: /store/shop/:shop_id -> /store/shop)

      parts.splice(-1);
      path = parts.join('/');
      return "".concat(protocol, "//").concat(hostname).concat(port).concat(path, "/");
    }),
    _suggestSlug: Ember.observer('model.name', function () {
      var name = Ember.get(this, 'model.name');

      if (!Ember.get(this, 'model.isNew') || Ember.isBlank(name)) {
        return;
      }

      Ember.run.debounce(this, this._debouncedSlugUpdate, 500);
    }),
    _debouncedSlugUpdate: function _debouncedSlugUpdate() {
      var name = Ember.get(this, 'model.name');
      this.send('updateSlug', (0, _normalizeForSlug.default)(name));
    },
    actions: {
      updateSlug: function updateSlug(slug) {
        Ember.set(this, 'model.slug', slug);
      }
    }
  });

  _exports.default = _default;
});