define("@vollersgroup/hub-frontend/components/auction-terms/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uJjZiDK+",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"markdown-to-html\",[[23,[\"auction\",\"auctionPolicy\"]]],null],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/auction-terms/template.hbs"
    }
  });

  _exports.default = _default;
});