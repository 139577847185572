define("@vollersgroup/hub-frontend/admin/manage/overview/shop/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "@vollersgroup/hub-frontend/mixins/company-verified"], function (_exports, _authenticatedRouteMixin, _companyVerified) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Display all shops of the current user's company.
   *
   * @namespace Route
   * @class ManageShopIndex
   * @extends Ember.Route
   * @uses Mixin.ResetScroll
   * @uses SimpleAuth.Mixin.AuthenticatedRouteMixin
   */
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _companyVerified.default, {
    availableData: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        shops: this.get('availableData.shops')
      });
    },
    afterModel: function afterModel(model) {
      model.draftShops = model.shops.filter(function (shop) {
        return shop.get('status') === 'DRAFT';
      });
    },
    activate: function activate() {
      this.controllerFor(this.get('routeName')).notifyPropertyChange('orderBy');
    }
  });

  _exports.default = _default;
});