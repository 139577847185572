define("@vollersgroup/hub-frontend/producer/adapter", ["exports", "@vollersgroup/hub-frontend/application/adapter"], function (_exports, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    query: function query(store, type, _query) {
      if (typeof _query.company === 'undefined') {
        return this._super(store, type, _query);
      }

      var url = [];
      var host = Ember.get(this, 'host');
      var prefix = this.urlPrefix();
      url.push('company/' + _query.company + '/producer');

      if (prefix) {
        url.unshift(prefix);
      }

      url = url.join('/');

      if (!host && url && url.charAt(0) !== '/') {
        url = '/' + url;
      }

      delete _query.company;

      if (this.sortQueryParams) {
        _query = this.sortQueryParams(_query);
      }

      return this.ajax(url, 'GET', {
        data: _query
      });
    }
  });

  _exports.default = _default;
});