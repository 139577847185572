define("@vollersgroup/hub-frontend/transforms/image-array", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var Transform = _emberData.default.Transform;

  var _default = Transform.extend({
    deserialize: function deserialize(serialized) {
      if (Ember.typeOf(serialized) !== 'array') {
        return [];
      }

      return serialized.map(function (image) {
        if (_typeof(image) !== 'object') {
          return null;
        }

        var obj = Ember.Object.create(image);

        obj.toString = function () {
          if (this.get('fullxfull')) {
            return this.get('fullxfull');
          }

          return this.get('url');
        };

        return obj;
      });
    },
    serialize: function serialize(deserialized) {
      if (!deserialized || Ember.typeOf(deserialized) !== 'array') {
        return [];
      }

      return deserialized.map(function (image) {
        return Ember.get(image, 'id');
      });
    }
  });

  _exports.default = _default;
});