define("@vollersgroup/hub-frontend/admin/manage/overview/auction/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "@vollersgroup/hub-frontend/mixins/reset-scroll"], function (_exports, _authenticatedRouteMixin, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Display all auctions of the current user's company.
   *
   * @namespace Route
   * @class ManageAuctionIndex
   * @extends Ember.Route
   * @uses Mixin.ResetScroll
   * @uses SimpleAuth.Mixin.AuthenticatedRouteMixin
   */
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _resetScroll.default, {
    toast: Ember.inject.service(),
    l10n: Ember.inject.service(),
    userSession: Ember.inject.service(),
    queryParams: {
      selectedShopId: {
        as: 'shop',
        refreshModel: true
      },
      orderBy: {
        as: 'orderBy',
        refreshModel: true
      },
      showArchived: {
        as: 'archived',
        refreshModel: true
      },
      filterStatus: {
        as: 'status',
        refreshModel: true
      }
    },
    model: function model(params) {
      var query = {
        company: this.get('userSession.currentCompany.id'),
        sort: Ember.get(params, 'orderBy') ? params.orderBy : 'DATE'
      };

      if (params.selectedShopId) {
        query.shop = params.selectedShopId;
      }

      if (params.filterStatus) {
        query.status = params.filterStatus;
      } else {
        query.status = ['DRAFT', 'ACTIVE', 'INACTIVE', 'CLOSED', 'RUNNING'];
      }

      if (params.showArchived) {
        query.archived = 'true';
      } else {
        query.archived = 'false';
      }

      return this.store.query('auction', query);
    },
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      archiveDraftAuction: function archiveDraftAuction(auction) {
        if (!auction.get('isDraft')) {
          return;
        }

        auction.setProperties({
          status: 'INACTIVE',
          archived: true
        });
        var l10n = this.get('l10n');
        var toast = this.get('toast');
        auction.save().then(function () {
          toast.success(l10n.t('Your auction is now archived.'));
        }).catch(function () {
          var errMessage = l10n.t('Your auction could not be archived. Please try again later or contact us.');
          toast.error(errMessage);
        });
      },
      publishAuction: function publishAuction(auction) {
        var _this = this;

        if (auction.get('status') === 'DRAFT' || auction.get('status') === 'INACTIVE') {
          auction.set('status', 'ACTIVE');
          auction.save().then(function () {
            _this.get('toast').success(_this.get('l10n').t('Your auction is now active and visible to everyone.'));
          }, function () {
            _this.get('toast').error(_this.get('l10n').t('Your auction could not be published. Please try again later or contact us.'));
          });
        } else {
          this.get('toast').warning(this.get('l10n').t('You can only activate a draft or inactive auctions.'));
        }
      },
      deactivateAuction: function deactivateAuction(auction) {
        var _this2 = this;

        if (auction.get('status') === 'ACTIVE') {
          auction.set('status', 'INACTIVE');
          auction.save().then(function () {
            _this2.get('toast').info(_this2.get('l10n').t('Your auction is now inactive. Only members of your company can see it.'));
          }, function () {
            _this2.get('toast').error(_this2.get('l10n').t('Your auction could not be deactivated. Please try again later or contact us.'));
          });
        } else {
          this.get('toast').warning(this.get('l10n').t('You can only deactivate an auction which is active but not yet running.'));
        }
      },
      archiveAuction: function archiveAuction(auction) {
        var _this3 = this;

        if (auction.get('status') === 'CLOSED') {
          auction.set('archived', true);
          auction.save().then(function () {
            _this3.get('toast').info(_this3.get('l10n').t('Your auction is now archived.'));
          }, function () {
            _this3.get('toast').error(_this3.get('l10n').t('Your auction could not be archived. Please try again later or contact us.'));
          });
        } else {
          this.get('toast').warning(this.get('l10n').t('You can only archive a closed auction.'));
        }
      },
      unarchiveAuction: function unarchiveAuction(auction) {
        var _this4 = this;

        if (auction.get('status') === 'CLOSED') {
          auction.set('archived', false);
          auction.save().then(function () {
            _this4.get('toast').info(_this4.get('l10n').t('Your auction is now unarchived.'));
          }, function () {
            _this4.get('toast').error(_this4.get('l10n').t('Your auction could not be unarchived. Please try again later or contact us.'));
          });
        } else {
          this.get('toast').warning(this.get('l10n').t('You can only unarchive a closed auction.'));
        }
      }
    }
  });

  _exports.default = _default;
});