define("@vollersgroup/hub-frontend/components/manage-producer-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A module to allow the selection of a producer. It is also possible to create a new producer inside of this component.
   *
   * ```html
   * {{manage-producer-select action="selectProducer"}}
   * ```
   *
   * The action receives the selected producer as parameter:
   *
   * ```js
   * selectProducer: function(producer) {
   *    // do something with the producer
   * }
   * ```
   *
   * @namespace Component
   * @class ManageProducerSelect
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies

    /**
     * @property store
     * @type DS.Store
     * @private
     */
    store: Ember.inject.service(),
    availableData: Ember.inject.service(),
    userSession: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * The name of the action to call when the selection / creation of the producer is done.
     * This action receives the selected producer as a single parameter.
     *
     * @attribute action
     * @type string
     * @required
     */
    action: null,

    /**
     * If this is set to true, this is an edit dialog (instead of a create dialog)
     *
     * @attribute isEdit
     * @type Boolean
     * @optional
     */
    isEdit: false,

    /**
     * The name of the action to call when the creation / editing of the listing is done.
     * This action receives the edited listing as a single parameter.
     * This action is called when the process should be ended instead of continuing to the next step.
     *
     * @attribute actionExit
     * @type string
     * @required
     */
    actionExit: null,

    /**
     * The ID of the selected producer.
     *
     * @attribute selectedProducerId
     * @type String
     */
    selectedProducerId: null,
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Actually select a producer and continue.
       *
       * @event selectProducer
       * @private
       */
      selectProducer: function selectProducer() {
        var _this = this;

        this.set('isLoading', true);

        if (this.get('selectedProducer.isLoaded')) {
          this.sendAction('action', this.get('selectedProducer'));
        } else {
          this.get('selectedProducer').then(function (producer) {
            _this.sendAction('action', producer);
          });
        }
      },

      /**
       * Actually select a producer and exit the process.
       *
       * @event selectProducerExit
       * @private
       */
      selectProducerExit: function selectProducerExit() {
        var _this2 = this;

        this.set('isLoading', true);

        if (this.get('selectedProducer.isLoaded')) {
          this.sendAction('action', this.get('selectedProducer'), true);
        } else {
          this.get('selectedProducer').then(function (producer) {
            _this2.sendAction('action', producer, true);
          });
        }
      },

      /**
       * Toggle the view between selecting a producer and creating a new producer.
       *
       * @event toggleCreateNew
       * @private
       */
      toggleCreateNew: function toggleCreateNew() {
        this.toggleProperty('createNew');
      },

      /**
       * If a new producer is created, select it and continue.
       *
       * @event createProducer
       * @param Model.Producer producer
       * @private
       */
      createProducer: function createProducer(producer) {
        this.set('selectedProducer', producer);
        this.set('selectedProducerId', producer.get('id'));
        this.send('selectProducer');
      }
    },
    // -------------------------------------------------------------------------
    // Properties

    /**
     * When this is true, some computation is currently occuring.
     *
     * @property isLoading
     * @type Boolean
     * @private
     */
    isLoading: false,

    /**
     * If a new producer should be created or not.
     *
     * @property createNew
     * @type Boolean
     * @default false
     * @private
     */
    createNew: false,
    producerId: null,

    /**
     * A new producer to use for creation.
     *
     * @property newProducer
     * @type Model.Producer
     * @private
     */
    newProducer: Ember.computed('store', 'userSession.currentCompany', function () {
      var location = this.get('store').createRecord('location', {
        type: 'PRODUCER'
      });
      return this.get('store').createRecord('producer', {
        company: this.get('userSession.currentCompany'),
        location: location,
        images: []
      });
    }),

    /**
     * Load the actual producer object for the selected producer id.
     *
     * @property selectedProducer
     * @type Model.Producer
     * @private
     */
    selectedProducer: Ember.computed('availableData.producers.firstObject', 'producerId', 'store', function () {
      if (!this.get('producerId')) {
        return this.get('availableData.producers.firstObject');
      }

      return this.get('store').findRecord('producer', this.get('producerId'));
    }),
    // -------------------------------------------------------------------------
    // Methods
    didReceiveAttrs: function didReceiveAttrs() {
      this._super();

      var selectedProducerId = Ember.get(this, 'selectedProducerId');

      if (selectedProducerId) {
        this.set('producerId', selectedProducerId);
      }
    }
  });

  _exports.default = _default;
});