define("@vollersgroup/hub-frontend/admin/manage/bulk/producer-edit/route", ["exports", "@vollersgroup/hub-frontend/admin/manage/bulk/bulk-producer-route"], function (_exports, _bulkProducerRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Producer route for edit.
   *
   * @namespace Route
   * @class BulkProducerEditRoute
   * @extends BulkProducerRoute
   */
  var _default = _bulkProducerRoute.default.extend({
    // -------------------------------------------------------------------------
    // Properties

    /**
     * @property isEdit
     */
    isEdit: true
  });

  _exports.default = _default;
});