define("@vollersgroup/hub-frontend/components/select-crop-year/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    currentYear: new Date().getFullYear(),
    value: '',
    options: Ember.computed('currentYear', function () {
      var initialYear = this.currentYear - 2;
      var lastYear = this.currentYear + 1;
      var options = [];

      for (var year = initialYear; year <= lastYear; year++) {
        options.push({
          label: year,
          value: year
        });

        if (year !== lastYear) {
          var mixedYear = "".concat(year, "/").concat(year + 1);
          options.push({
            label: mixedYear,
            value: mixedYear
          });
        }
      }

      return options;
    })
  });

  _exports.default = _default;
});