define("@vollersgroup/hub-frontend/admin/orders/index/route", ["exports", "@vollersgroup/hub-frontend/mixins/reset-scroll", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _resetScroll, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This route displays all orders of this user
   *
   * @namespace Route
   * @class AdminOrderIndex
   * @extends Ember.Route
   * @uses SimpleAuth.Mixin.AuthenticatedRouteMixin
   * @uses Mixin.ResetScrollMixin
   */
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _resetScroll.default, {
    userSession: Ember.inject.service(),
    queryParams: {
      page: {
        refreshModel: true
      },
      pageSize: {
        refreshModel: true
      },
      filterStatus: {
        refreshModel: true
      },
      selectedCompanyId: {
        refreshModel: true
      },
      fromDate: {
        refreshModel: true
      },
      toDate: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var query = {
        buyerCompany: Ember.get(this, 'userSession.currentCompany.id')
      };
      query.page = params.page || 1;
      query.pageSize = params.pageSize || 10;
      query.toDate = params.toDate || null;
      query.fromDate = params.fromDate || null;
      query.status = params.filterStatus || [];
      query.sellerCompany = params.selectedCompanyId || null;
      return this.store.query('order', query);
    },
    afterModel: function afterModel(model) {
      var queryParams = this.paramsFor(this.routeName);
      var maxPages = Ember.get(model, 'meta.maxPages');

      if (queryParams.page > maxPages) {
        queryParams.page = 1;
        this.transitionTo({
          queryParams: queryParams
        });
      }
    }
  });

  _exports.default = _default;
});