define("@vollersgroup/hub-frontend/admin/manage/producer/create/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    l10n: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * When the producer information has been entered/updated.
       *
       * @event editProducer
       * @param Model.Producer producer
       * @private
       */
      editProducer: function editProducer() {
        //this.set("step", 1);
        this.get('toast').success(this.get('l10n').t('You have successfully created a new origin.'));
        this.transitionToRoute('admin.manage.overview.producer');
      },

      /**
       * Move to another step.
       *
       * @event gotoStep
       * @param {Number} newStep
       * @private
       */
      gotoStep: function gotoStep(newStep) {
        if (newStep > this.get('step')) {
          this.get('toast').warning(this.get('l10n').t('You have to save your changes!'));
          return;
        }

        this.set('step', newStep);
        window.scrollTo(0, 0);
      }
    },
    // -------------------------------------------------------------------------
    // Properties

    /**
     * The current step of the process.
     *
     * @property step
     * @type Number
     */
    step: 0,

    /**
     * All steps for the creation process.
     *
     * @property allSteps
     * @type Array
     */
    allSteps: Ember.computed('availableShops.length', 'l10n', function () {
      var steps = [];
      steps.push({
        label: this.get('l10n').t('Create origin'),
        step: steps.length,
        subSteps: []
      });
      return steps;
    }) // -------------------------------------------------------------------------
    // Methods

  });

  _exports.default = _default;
});