define("@vollersgroup/hub-frontend/components/message-detail/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * An expandable message detail
   * Each conversation has one message detail for every message inside of this conversation
   *
   * ```html
   * {{message-detail message=message isOpen=false}}
   * ```
   *
   * @namespace Component
   * @class MessageDetail
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    message: null,
    isOpen: false,
    attributeBindings: ['data-test-message-detail'],
    'data-test-message-detail': '',
    classNames: ['bg-hover-color-grey-6', 'message__detail', 'padding-all-2', 'border-bottom'],
    isVisible: Ember.computed.and('message.fromUser.isLoaded', 'message.fromCompany.isLoaded'),
    avatarUrl: Ember.computed.or('message.fromUser.avatarUrl', 'message.fromCompany.logoUrl'),
    avatarAlt: Ember.computed.or('message.fromUser.fullName', 'message.fromCompany.name'),
    click: function click() {
      this.toggleProperty('isOpen');
    }
  });

  _exports.default = _default;
});