define("@vollersgroup/hub-frontend/store/shop/story/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GsHt2u2s",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"wrapper content__editable\"],[9],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"layout__sidebar--right padding-top-4\"],[9],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"section section--highlighted padding-all-0\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"padding-all-3\"],[9],[0,\"\\n        \"],[7,\"h4\"],[9],[1,[27,\"t\",[\"Shop owner\"],null],false],[10],[0,\"\\n\\n        \"],[7,\"p\"],[11,\"class\",\"margin-bottom-2\"],[9],[0,\"\\n          \"],[4,\"link-to\",[\"store.company\",[23,[\"model\",\"company\"]]],null,{\"statements\":[[1,[23,[\"model\",\"company\",\"name\"]],false]],\"parameters\":[]},null],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"img\"],[12,\"src\",[23,[\"model\",\"company\",\"logoUrl\"]]],[11,\"class\",\"h3 margin-bottom-3\"],[11,\"alt\",\"\"],[9],[10],[0,\"\\n\\n\"],[4,\"unless\",[[27,\"eq\",[[23,[\"model\",\"company\",\"id\"]],[23,[\"userSession\",\"currentCompany\",\"id\"]]],null]],null,{\"statements\":[[0,\"          \"],[4,\"authenticated-link\",null,[[\"queryParamName\",\"queryParamValue\",\"class\"],[\"messageTo\",[23,[\"model\",\"company\",\"id\"]],\"button button--secondary button--half-height\"]],{\"statements\":[[1,[27,\"t\",[\"Contact shop owner\"],null],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"layout__sidebar--main\"],[9],[0,\"\\n    \"],[7,\"h2\"],[9],[1,[23,[\"model\",\"storyHeadline\"]],false],[10],[0,\"\\n\\n    \"],[7,\"div\"],[9],[1,[27,\"markdown-to-html\",[[23,[\"model\",\"story\"]]],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/store/shop/story/template.hbs"
    }
  });

  _exports.default = _default;
});