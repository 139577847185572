define("@vollersgroup/hub-frontend/mixins/error-validation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Add error validation capabilities.
   * To use it, you have to specify a `validations´ property on your controller/component.
   * This property is an array of object which contain the properties to validate.
   *
   * Each validation object should have the following format:
   *
   * ```js
   * {
   *     field: "model.name",
   *     validate: function(value) {
   *         return !!value;
   *     }
   * }
   * ```
   *
   * There are a few included functions which can be used for validation:
   * `this._validateRequired(value)`, `this._validateLength(value, min, max)`, `this._validateNumber(value)`,
   * `this._validatePhoneNumber(value)`, `this._validateEmail(value)`, `this._validateRange(value, min, max)`
   *
   * You can then call `this.checkValidations()` to validate. If an error occurs, the `errors` property will be set
   * and a toast will be displayed. You can then use the `errors` property to display the errors. The `errors` property
   * is an object with each failing validation as a object property. In addition, there is the `allErrors` proeprty which
   * is an array of all errors that occured. Each error is an object with the following format:
   *
   * ```js
   * {
   *     field: "model.name",
   *     errorMessage: "Human readable error message."
   *     errorTitle: "An optional error title."
   * }
   * ```
   *
   * @namespace Mixin
   * @class ErrorValdiation
   * @extends Ember.Mixin
   */
  var _default = Ember.Mixin.create({
    // -------------------------------------------------------------------------
    // Properties

    /**
     * All errors as objects available via field name. This can be used to access an error for a specific field, e.g.
     * `{{#if errors.model.name}} Error! {{/if}}`
     *
     * The errors have the format:
     *
     * ```js
     * {
     *     field: "model.name",
     *     errorMessage: "Human readable error message."
     *     errorTitle: "An optional error title."
     * }
     * ```
     *
     * @property errors
     * @type Object
     * @public
     */
    errors: {},

    /**
     * All errors as an array. This can be used to display all errors at once by iterating over it.
     *
     * The errors have the format:
     *
     * ```js
     * {
     *     field: "model.name",
     *     errorMessage: "Human readable error message."
     *     errorTitle: "An optional error title."
     * }
     * ```
     *
     * @property allErrors
     * @type Array
     * @public
     */
    allErrors: [],

    /**
     * The validations that should be used.
     *
     * The validations should have the format:
     *
     * ```js
     * {
     *     field: "model.name",
     *     validate: function(value) {
     *         return !!value;
     *     },
     *     errorTitle: "Error with field Name",
     *     errorMessage: "You have to enter a name."
     * }
     * ```
     *
     * @property validations
     * @type Array
     * @public
     */
    validations: [],
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Actually evaluate the specified validations. Populates the `errors` and `allErrors` properties.
     * Returns true if all validations pass, else false.
     *
     * @method checkValidations
     * @params Boolean [showToast=true] If true, a toast will be displayed when an error occurs.
     * @returns Boolean
     * @public
     */
    checkValidations: function checkValidations() {
      var _this = this;

      var showToast = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var scrollToTop = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var errors = {};
      var allErrors = []; // Create a nested object from the field path

      function _assign(obj, keyPath, value) {
        var lastKeyIndex = keyPath.length - 1;

        for (var i = 0; i < lastKeyIndex; ++i) {
          var key = keyPath[i];

          if (!(key in obj)) {
            obj[key] = {};
          }

          obj = obj[key];
        }

        obj[keyPath[lastKeyIndex]] = value;
      }

      this.get('validations').forEach(function (validation) {
        var val = _this.get(validation.field);

        if (!validation.validate(val)) {
          var error = {
            field: validation.field,
            errorTitle: validation.errorTitle,
            errorMessage: validation.errorMessage
          };
          allErrors.push(error);
          var fields = validation.field.split('.');
          var obj = {};

          _assign(obj, fields, error);

          errors = Ember.$.extend(true, errors, obj);
        }
      });
      this.set('errors', errors);
      this.set('allErrors', allErrors);

      if (showToast && allErrors.length) {
        this.get('toast').error(this.get('l10n').t('An error occured! Please review your input.'));
      }

      if (scrollToTop && allErrors.length) {
        window.scrollTo(0, 0);
      }

      return !allErrors.length;
    },

    /**
     * Returns true if the value is not empty.
     *
     * @method _validateRequired
     * @param {String}|Object|Number|Boolean|Array value
     * @returns Boolean
     * @private
     */
    _validateRequired: function _validateRequired(value) {
      return !!value;
    },

    /**
     * Validate the length of a string (or array).
     *
     * @method _validateLength
     * @param {String}|Array value
     * @param {Number} min
     * @param {Number} max
     * @returns Boolean
     * @private
     */
    _validateLength: function _validateLength(value) {
      var min = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var max = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

      if (!value || !value.length) {
        if (min !== null) {
          return false;
        }

        return true;
      }

      if (min !== null && value.length < min) {
        return false;
      }

      if (max !== null && value.length > max) {
        return false;
      }

      return true;
    },

    /**
     * Validate the content of a number.
     *
     * @method _validateRange
     * @param {Number} value
     * @param {Number} min
     * @param {Number} max
     * @returns Boolean
     * @private
     */
    _validateRange: function _validateRange(value) {
      var min = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var max = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

      if (!this._validateNumber(value)) {
        return false;
      }

      if (min !== null && value < min) {
        return false;
      }

      if (max !== null && value > max) {
        return false;
      }

      return true;
    },

    /**
     * Validate the field for numbers. Optionally, only validate integers.
     *
     * @method _validateNumber
     * @param {String}|Object|Number|Boolean|Array value
     * @param {Boolean} [onlyInteger]
     * @private
     */
    _validateNumber: function _validateNumber(value) {
      var onlyInteger = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      if (!value) {
        return true;
      }

      if (!onlyInteger) {
        return Ember.typeOf(value * 1) === 'number' && !isNaN(value * 1);
      }

      if (value * 1 === parseInt(value, 10)) {
        return true;
      }

      return false;
    },

    /**
     * Validate a phone number. This is quite lax and should mainly fail for letters in the string.
     *
     * @method _validatePhone
     * @param {String}|Object|Number|Boolean|Array value
     * @returns Boolean
     * @private
     */
    _validatePhone: function _validatePhone(value) {
      if (!value) {
        return true;
      } // eslint-disable-next-line no-useless-escape


      var re = /([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
      return re.test(value);
    },

    /**
     * Validate an email address. This is extremly lax and only checks for the presence of an @ inside of the string.
     *
     * @method _validateEmail
     * @param value
     * @returns Boolean
     * @private
     */
    _validateEmail: function _validateEmail(value) {
      if (!value) {
        return true;
      }

      return (value + '').indexOf('@') > -1;
    }
  });

  _exports.default = _default;
});