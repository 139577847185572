define("@vollersgroup/hub-frontend/helpers/format-number", ["exports", "number-format.js"], function (_exports, _numberFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatNumber = formatNumber;
  _exports.default = void 0;

  function formatNumber(params, hash) {
    var num = params[0];

    if (num !== 0 && !num) {
      return '';
    }

    var lang = hash.lang || 'en';
    lang = lang.toLowerCase();

    if (lang === 'en') {
      if (hash.showDecimal) {
        return (0, _numberFormat.default)('#,##0.00', num);
      }

      return (0, _numberFormat.default)('#,##0.##', num);
    }

    if (hash.showDecimal) {
      return (0, _numberFormat.default)('#.##0,00', num);
    }

    return (0, _numberFormat.default)('#.##0,##', num);
  }

  var _default = Ember.Helper.helper(formatNumber);

  _exports.default = _default;
});