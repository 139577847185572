define("@vollersgroup/hub-frontend/mixins/track-pageview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    keenTracking: Ember.inject.service(),
    userSession: Ember.inject.service(),
    didTransition: function didTransition() {
      this._super.apply(this, arguments);

      this._trackPageView();
    },
    _trackPageView: function _trackPageView() {
      var routeName = this.get('currentPath');

      if (routeName.indexOf('loading') > -1) {
        return;
      }

      this.get('keenTracking').addEvent('pageView');
    }
  });

  _exports.default = _default;
});