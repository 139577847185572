define("@vollersgroup/hub-frontend/components/embeded-video/component", ["exports", "@vollersgroup/hub-frontend/utils/get-video-metadata"], function (_exports, _getVideoMetadata) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
   * Displays Youtube or Vimeo video in embeded player.
   */
  var _default = Ember.Component.extend({
    classNames: ['aspect-ratio', 'aspect-ratio--16x9'],
    url: null,
    videoMetadata: Ember.computed('url', function () {
      return (0, _getVideoMetadata.default)(Ember.get(this, 'url'));
    }),
    isYoutube: Ember.computed.equal('videoMetadata.service', 'youtube'),
    isVimeo: Ember.computed.equal('videoMetadata.service', 'vimeo'),
    videoId: Ember.computed.alias('videoMetadata.id'),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments); // Changing iframe src attribute won't trigger it's content update
      // so we need to trigger whole iframe element re-render to reload
      // it's content again.


      this.rerender();
    }
  });

  _exports.default = _default;
});