define("@vollersgroup/hub-frontend/about/legal/terms-of-service/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KwQjjApR",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"page-title\",[[27,\"t\",[\"Terms of service\"],null]],null],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"padding-bottom-4\"],[9],[0,\"\\n  \"],[7,\"h1\"],[11,\"class\",\"font-size-3 text-center\"],[9],[1,[27,\"t\",[\"Terms of service\"],null],false],[10],[0,\"\\n  \"],[1,[21,\"terms-of-service\"],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/about/legal/terms-of-service/template.hbs"
    }
  });

  _exports.default = _default;
});