define("@vollersgroup/hub-frontend/components/listing-ratings/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['font-size-6'],
    availableData: Ember.inject.service(),
    rating: null,
    ratings: null,
    iconOnly: false,
    iconSize: 'icon--medium',
    showSummary: Ember.computed.notEmpty('ratings'),
    singleRating: Ember.computed('availableData.coffeeRatings', 'rating.rating', function () {
      var ratings = Ember.get(this, 'availableData.coffeeRatings');
      var rating = Ember.get(this, 'rating');

      if (!rating) {
        return null;
      }

      return {
        data: ratings.findBy('value', Ember.get(rating, 'rating')),
        rating: rating
      };
    })
  });

  _exports.default = _default;
});