define("@vollersgroup/hub-frontend/store/auctions/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    l10n: Ember.inject.service(),
    queryParams: [{
      shopId: {
        as: 'shop'
      }
    }, {
      searchText: {
        as: 'q'
      }
    }],
    shopId: null,
    searchText: null,
    filterStatus: null,
    pastAuctions: Ember.computed.alias('model.closed'),
    upcomingAuctions: Ember.computed.filterBy('model.upcoming', 'status', 'ACTIVE'),
    runningAuctions: Ember.computed.filter('model.upcoming', function (auction) {
      return auction.get('isRunning') || auction.get('isOnHold');
    }),
    filterStatusOptions: Ember.computed('l10n', function () {
      return [{
        label: this.get('l10n').t('Upcoming auctions'),
        value: 'ACTIVE'
      }, {
        label: this.get('l10n').t('Running auctions'),
        value: 'RUNNING'
      }, {
        label: this.get('l10n').t('Past auctions'),
        value: 'CLOSED'
      }];
    }),
    selectShopOptions: Ember.computed.map('model.shopsWithAuction', function (shop) {
      return {
        label: shop.get('name'),
        value: shop.get('id')
      };
    })
  });

  _exports.default = _default;
});