define("@vollersgroup/hub-frontend/services/search", ["exports", "u-node", "@vollersgroup/hub-frontend/mixins/search"], function (_exports, _uNode, _search) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.filterSpec = _exports.default = void 0;

  /**
   * An object to handle fetching of listings. Besides, it fetches also
   * corresponding filters and maintains state of active filters. Active
   * filters are also propagated in compressed form for query parameter
   * usage in routes.
   *
   * @namespace Service
   * @class Search
   * @extends Service
   * @uses DS.Store
   * @uses Service.Ajax
   */
  var _default = Ember.Service.extend(Ember.Evented, _search.SearchParameterMixin, {
    // -------------------------------------------------------------------------
    // Dependencies
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Optional shop model id for this search.
     *
     * @property shop
     * @public
     * @optional
     * @type Model.Shop
     */
    shop: null,

    /**
     * Optional auction model id for this search.
     *
     * @property auction
     * @public
     * @optional
     * @type Model.Auction
     */
    // TODO: search functionality was removed from auction page so this is not used at the moment.
    auction: null,

    /**
     * Search agent model to be set via `setSearchAgent()`.
     * @public
     * @property searchAgent
     * @type Model.SearchAgent
     */
    searchAgent: null,

    /**
     * Loaded listings for current settings.
     *
     * @property listings
     * @public
     * @type Model.Listing[]
     */
    listings: null,
    // @see: _loadListings()

    /**
     * Maximum amount of pages.
     *
     * @property maxPages
     * @public
     * @type Number
     */
    maxPages: 1,

    /**
     * Total items in database.
     *
     * @property maxPages
     * @public
     * @type Number
     */
    totalItems: 0,

    /**
     * Current loading state of object.
     *
     * @property isLoading
     * @public
     * @type Boolean
     */
    isLoading: true,

    /**
     * Current filters returned from API.
     *
     * @property filters
     * @public
     * @type Array
     */
    filters: Ember.computed({
      get: function get() {
        return [];
      }
    }),

    /**
     * Hashmap containing all active
     * filter items with `name` keys.
     *
     * @property active
     * @public
     * @type Object
     */
    active: Ember.computed({
      get: function get() {
        return {};
      }
    }),

    /**
     * Flag providing information if
     * a previous result set can be
     * restored with restore() method.
     *
     * @property canRestore
     * @public
     * @type Boolean
     */
    canRestore: Ember.computed('_cache', {
      get: function get() {
        return Object.keys(Ember.get(this, '_cache')).length > 0;
      }
    }),

    /**
     * Provides information if active
     * filters apply in current setting.
     *
     * @property hasActive
     * @public
     * @type Boolean
     */
    hasActive: Ember.computed.gt('_activeCount', 0),

    /**
     * If default settings apply at the
     * moment. As soon as property will
     * change, this flag is set to false.
     *
     * @public
     * @property isDefault
     * @type {Boolean}
     */
    isDefault: Ember.computed('defaults.{filter,orderBy,page,pageSize,searchAgent,searchText}', 'filter', 'orderBy', 'page', 'pageSize', 'searchAgent', 'searchText', {
      get: function get() {
        return Ember.get(this, 'page') === Ember.get(this, 'defaults.page') && Ember.get(this, 'filter') === Ember.get(this, 'defaults.filter') && Ember.get(this, 'orderBy') === Ember.get(this, 'defaults.orderBy') && Ember.get(this, 'pageSize') === Ember.get(this, 'defaults.pageSize') && Ember.get(this, 'searchText') === Ember.get(this, 'defaults.searchText') && Ember.get(this, 'searchAgent') === Ember.get(this, 'defaults.searchAgent');
      }
    }),

    /**
     * Query object tp build query string when
     * when calling listings/filters on API.
     *
     * @public
     * @property
     * @type {Object}
     */
    query: Ember.computed('page', 'pageSize', 'searchText', 'orderBy', 'shop', 'auction', '_filter', {
      get: function get() {
        var query = {
          shop: Ember.get(this, 'shop'),
          page: Ember.get(this, 'page'),
          sort: Ember.get(this, 'orderBy'),
          term: Ember.get(this, 'searchText'),
          auction: Ember.get(this, 'auction'),
          pageSize: Ember.get(this, 'pageSize')
        };

        switch (query.sort) {
          case 'DATE':
            query.sortDirection = 'DESC';
            break;

          case 'SCORE':
            query.sortDirection = 'DESC';
            break;

          case 'AMOUNT':
            query.sortDirection = 'DESC';
            break;

          default:
            query.sortDirection = 'ASC';
        }

        var _filter = Ember.get(this, '_filter');

        return Ember.assign({}, query, _filter);
      }
    }),

    /**
     * Saves default values from mixin.
     * Setup on instance initialization.
     *
     * @private
     * @property defaults
     * @type {Object}
     */
    defaults: Ember.computed(function () {}),

    /**
     * Internal property holding search
     * agent model, whereas `searchAgent`
     * holds only the corresponding `id`.
     *
     * @private
     * @property _searchAgent
     * @type {Model.SearchAgent}
     */
    _searchAgent: null,

    /**
     * Counter delivering current amount
     * of active filters applying. Use
     * `hasFilters` to get state info.
     *
     * @property _activeCount
     * @private
     * @type {Number}
     */
    _activeCount: 0,

    /**
     * Cached query from previous call
     * to be used by restore() method.
     *
     * @private
     * @property _cache
     * @type {Object}
     */
    _cache: Ember.computed(function () {
      return {}; // complex type!
    }),

    /**
     * Decoded object from `filter` hash.
     * Will be merged with base query in
     * `query` property for API calls.
     *
     *
     * @private
     * @property _filter
     * @type {Object}
     */
    _filter: Ember.computed(function () {
      return {}; // complex type!
    }),

    /**
     * Flag indicating if `filter`
     * has been decoded internally.
     *
     * @property _hasDecoded
     * @type Boolean
     * @public
     */
    _hasDecoded: false,

    /**
     * Flag indicating if `_filter`
     * has been encoded internally.
     *
     * @property _hasDecoded
     * @type Boolean
     * @public
     */
    _hasEncoded: true,

    /**
     * Flag indicating if observed
     * changes should be processed.
     *
     * @property _hasDecoded
     * @type Boolean
     * @public
     */
    _isEnabled: false,

    /**
     * Flag indicating if dependent
     * search properties has changed
     * since last
     */
    _hasChanged: Ember.computed.none('listings'),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Sets fallback defaults.
     *
     * @public
     * @method init
     * @return {Void}
     */
    init: function init() {
      this._super.apply(this, arguments);

      var defaults = Ember.getProperties(this, ['searchText', 'pageSize', 'orderBy', 'filter', 'page']);
      Ember.set(this, 'defaults', defaults);
    },

    /**
     * Enables and kick offs search.
     * Copies default values from
     * initial state to internal
     * stack for dirty checking.
     *
     *
     * @public
     * @method setup
     * @param options Object
     * @return {Void}
     */
    enable: function enable() {
      Ember.set(this, '_isEnabled', true); // only load if we've no search
      // agent currently set, because
      // this would fire multiple xhr

      if (!Ember.get(this, 'searchAgent')) {
        this._decodeFilter();

        this._loadListings();
      }
    },

    /**
     * Disables search temporarily so
     * that changes won't be processed.
     *
     * @public
     * @method disable
     * @return {Void}
     */
    disable: function disable() {
      Ember.set(this, '_isEnabled', false);
    },

    /**
     * Resets search to default properties
     * and fetches listings from scratch.
     *
     * @method reset
     * @public
     */
    reset: function reset() {
      // for visual user feedback only!
      this._iterator(function (filter, item) {
        Ember.set(item, 'active', false);
      });

      this.setProperties({
        // public properties
        active: {},
        maxPages: 1,
        listings: [],
        // @see: _loadListings()
        totalItems: 0,
        isLoading: true,
        page: Ember.get(this, 'defaults.page'),
        filter: Ember.get(this, 'defaults.filter'),
        orderBy: Ember.get(this, 'defaults.orderBy'),
        pageSize: Ember.get(this, 'defaults.pageSize'),
        searchText: Ember.get(this, 'defaults.searchText'),
        searchAgent: Ember.get(this, 'defaults.searchAgent'),
        // private properties
        _cache: {},
        _filter: {},
        _activeCount: 0,
        _hasChanged: true,
        _hasEncoded: true,
        _hasDecoded: false,
        _searchAgent: null
      });
      this.trigger('reset');

      this._loadListings();
    },

    /**
     * Tries to restore last known result
     * set differing from default results.
     *
     * @method restore
     * @public
     */
    restore: function restore() {
      if (!Ember.get(this, 'canRestore')) {
        return;
      }

      this._desistState();

      this.trigger('restore');
    },

    /**
     * Sets or unsets a filter item from current
     * query hash depending on `active` property.
     * Furthermore, an encoded version of filter
     * hash is calculated and listings are loaded
     * afterwards with a debounced timeout.
     *
     * @method update
     * @param {Object} filter Filter group of item.
     * @param {Object} item Changed filter item.
     * @public
     */
    update: function update(filter, item) {
      var name = filter.parameterName;
      var type = filter.parameterType;

      var _filter = Ember.get(this, '_filter');

      switch (type) {
        case 'OTHER':
          _filter[item.name] = item.active ? item.id : null;
          break;

        case 'VALUE':
          _filter[name] = item.active ? item.id : null;
          break;

        case 'RANGE':
          switch (item.name) {
            case 'min':
              _filter["".concat(name, "Min")] = item.active ? item.id : null;
              break;

            case 'max':
              _filter["".concat(name, "Max")] = item.active ? item.id : null;
              break;

            case 'unit':
              _filter["".concat(name, "Unit")] = item.active ? item.id : null;
              break;

            default:
              throw new Error("Range item \"".concat(item.name, "\" is not implemented!"));
          }

          break;

        case 'ARRAY':
          if (!Ember.isArray(_filter[name])) {
            _filter[name] = [];
          }

          if (item.active) {
            _filter[name].pushObject(item.id);

            break;
          }

          _filter[name].removeObject(item.id);

          break;

        default:
          throw new Error("Filter type ".concat(type, " is not implemented!"));
      }

      this._encodeFilter();

      this._synchronize.apply(this, arguments);

      this.notifyPropertyChange('active');
      this.notifyPropertyChange('_filter');
      Ember.run.debounce(this, this._loadListings, 1500);
    },

    /**
     * Removes an active filter item from hashmap
     * and refetches listings by invoking update().
     *
     * @method remove
     * @param {Object} filter Filter group of item.
     * @param {Object} item Filter item to remove.
     * @public
     */
    remove: function remove(filter, item) {
      Ember.set(item, 'active', false);
      this.update(filter, item);
    },

    /**
     * Applies setting of search agent model
     * and triggers `search_agent_changed`.
     *
     * @method setSearchAgent
     * @param {Model.SearchAgent} searchAgent
     * @return {Void}
     */
    setSearchAgent: function setSearchAgent(searchAgent) {
      if (!this._verifySearchAgent(searchAgent)) {
        this.trigger('search_agent_redirect', searchAgent);
        return;
      }

      if (!Ember.isNone(searchAgent)) {
        var listingFilter = Ember.get(searchAgent, 'listingFilter');
        var properties = Ember.copy(listingFilter, true);
        Ember.set(this, 'searchAgent', searchAgent.id);
        Ember.set(this, '_searchAgent', searchAgent);
        this.setQuery(properties);
      } else {
        Ember.set(this, '_searchAgent', null);
        Ember.set(this, 'searchAgent', null);
        this.reset();
      }

      this.trigger('search_agent_changed');
    },

    /**
     * Retrieves search agent model supplied via
     * `setSearchAgent()` method, whereas property
     * `searchAgent` only holds corresponding `id`.
     *
     * @method getSearchAgent
     * @return {Model.SearchAgent}
     */
    getSearchAgent: function getSearchAgent() {
      return Ember.get(this, '_searchAgent');
    },

    /**
     * Takes query object to apply internal properties
     * and invoke an update of current search results.
     *
     * @method setQuery
     * @param {Object} [defaults={}] Hash containing query.
     * @return {Void}
     */
    setQuery: function setQuery() {
      var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      // 1) move global properties from `query` to instance
      var pageSize = Ember.get(query, 'pageSize');
      var searchText = Ember.get(query, 'term');
      var auction = Ember.get(query, 'auction');
      var orderBy = Ember.get(query, 'sort');
      var shop = Ember.get(query, 'shop');
      var page = Ember.get(query, 'page');
      delete query.pageSize;
      delete query.auction;
      delete query.page;
      delete query.shop;
      delete query.term;
      delete query.sort;
      Ember.set(this, 'searchText', searchText || Ember.get(this, 'defaults.searchText'));
      Ember.set(this, 'pageSize', pageSize || Ember.get(this, 'defaults.pageSize'));
      Ember.set(this, 'orderBy', orderBy || Ember.get(this, 'defaults.orderBy'));
      Ember.set(this, 'page', page || Ember.get(this, 'defaults.page'));
      Ember.set(this, 'auction', !Ember.isNone(auction) ? "".concat(auction) : null);
      Ember.set(this, 'shop', !Ember.isNone(shop) ? "".concat(shop) : null); // 2) apply remaining query as `_filter` property
      // and invoke encoding of hash for public `filter`

      Ember.set(this, '_filter', query);

      this._encodeFilter(); // 3) force a reload of listing


      this._reloadListings();
    },

    /**
     * Synchronizes active items to hashmap, where
     * filters and items uses their names as keys.
     * Besides, nested items are flattend simplify
     * displaying items with handlebar iterators.
     *
     * @method _synchronize
     * @param {Object} filter Filter group of item.
     * @param {Object} item Filter item to remove.
     * @private
     */
    _synchronize: function _synchronize(filter, item) {
      var active = Ember.get(this, 'active');
      var name = filter.parameterName;

      switch (name) {
        case 'format':
          // not really "active" as
          // filter ALWAYS applies!
          break;

        default:
          // create shallow copy overriding
          // items with flattened hash map
          if (Ember.isNone(active[name])) {
            active[name] = Ember.copy(filter);
            active[name].items = {
              /* hash */
            };
          } // eslint-disable-next-line no-case-declarations


          var items = active[name].items; // eslint-disable-next-line no-case-declarations

          var hasItem = !!items[item.name]; // add active items once

          if (item.active && !hasItem) {
            items[item.name] = item;
            this.incrementProperty('_activeCount');
          } // remove inactive items once


          if (!item.active && hasItem) {
            delete items[item.name];
            this.decrementProperty('_activeCount');
          } // remove filter if on items left
          // to propagate binding for ember


          if (Object.keys(items).length === 0) {
            delete active[name];
          }

      }
    },

    /**
     * Helper to iterate all filter items recursively
     * by applying provided callback method.
     *
     * @method _iterator
     * @param {Function} callback Receives filter and item as arguments.
     * @return {Void}
     */
    _iterator: function _iterator(callback) {
      var _this = this;

      Ember.get(this, 'filters').forEach(function (filter) {
        var stack = filter.items.slice();

        while (stack.length !== 0) {
          var item = stack.pop();
          callback.call(_this, filter, item);

          if (Ember.isArray(item.items)) {
            stack = stack.concat(item.items);
          }
        }
      });
    },

    /**
     * Loads listings for the current query. This also gets current
     * `maxPages` attribute from response. After the listings have
     * been loaded, `listings_loaded` gets triggered for listeners.
     *
     * PRIMITIVE METHOD
     *
     * @method _loadListings
     * @private
     */
    _loadListings: function _loadListings() {
      var _this2 = this;

      var isEnabled = Ember.get(this, '_isEnabled');
      var hasChanged = Ember.get(this, '_hasChanged');

      if (!hasChanged || !isEnabled) {
        return;
      }

      Ember.set(this, 'isLoading', true);
      Ember.set(this, '_hasChanged', false);
      this.trigger('properties_changed');
      var store = Ember.get(this, 'store');
      var query = Ember.get(this, 'query');
      var current = Ember.get(this, 'listings');

      var successCallback = function successCallback(listings) {
        // extract server meta information
        var meta = listings.get('meta');
        Ember.set(_this2, 'maxPages', meta.maxPages || 1);
        Ember.set(_this2, 'totalItems', meta.totalItems || 0); // reload in case we are out of range

        var maxPages = Ember.get(_this2, 'maxPages');
        var page = Ember.get(_this2, 'page');

        if (page > maxPages) {
          _this2._reloadListings();

          return;
        } // treat empty listings first


        if (Ember.isEmpty(listings)) {
          // no results on first load
          // should implicitly reset!
          if (Ember.isNone(current)) {
            _this2.reset();

            return;
          } // otherwise show no results


          Ember.set(_this2, 'isLoading', false);
          Ember.set(_this2, 'listings', []);
          return;
        } // now set new listings on stack
        // and fetch all filter from API


        Ember.set(_this2, 'listings', listings);

        _this2._loadFilters();
      };

      var failureCallback = function failureCallback(error) {
        var errorMessage = 'An error occurred while fetching listings.';
        Ember.set(_this2, 'listings', []);
        Ember.set(_this2, 'isLoading', false);
        console.error(errorMessage, error);
      };

      var finallyCallback = function finallyCallback() {
        _this2.trigger('listings_loaded');
      };

      store.query('listing', query).then(successCallback, failureCallback).finally(finallyCallback);
    },

    /**
     * Reloading resets `page` property
     * before fetching listings from API.
     *
     * @method _reloadListings
     * @return {Void}
     */
    _reloadListings: function _reloadListings() {
      if (Ember.get(this, '_isEnabled')) {
        if (Ember.get(this, 'page') > 1) {
          Ember.set(this, 'page', 1);
          return;
        }
      }

      Ember.run.debounce(this, this._loadListings, 100);
    },

    /**
     * Load the filters for the current set of listings.
     * If filters has been loaded, `filters_loaded` event
     * gets triggered for listeners.
     *
     * @method _loadFilters
     * @private
     */
    _loadFilters: function _loadFilters() {
      var _this3 = this;

      var ajax = Ember.get(this, 'ajax');
      var query = Ember.get(this, 'query');

      var successCallback = function successCallback(filters) {
        // server does implicit reset with
        // active filters, thus rebuilding
        // hashmap is necessary each time!
        Ember.set(_this3, 'active', {});
        Ember.set(_this3, '_activeCount', 0);
        Ember.set(_this3, 'filters', filters);
        Ember.set(_this3, '_hasDecoded', false);

        _this3._iterator(_this3._synchronize);

        _this3.notifyPropertyChange('active'); // persist current state now for
        // empty search results situation


        if (!Ember.get(_this3, 'isDefault')) {
          _this3._persistState();
        }
      };

      var failureCallback = function failureCallback(error) {
        var errorMessage = 'An error occurred while fetching filters.';
        Ember.set(_this3, 'filters', []);
        console.error(errorMessage, error);
      };

      var finallyCallback = function finallyCallback() {
        Ember.set(_this3, 'isLoading', false);

        _this3.trigger('filters_loaded');
      };

      ajax.request('/listingFilter', {
        data: query
      }).then(successCallback, failureCallback).finally(finallyCallback);
    },

    /**
     * Persists current object state in cache.
     *
     * @private
     * @method _persistState
     * @return {Void}
     */
    _persistState: function _persistState() {
      var _cache = {
        filters: Ember.copy(Ember.get(this, 'filters'), true),
        // dereferenced!
        searchText: Ember.get(this, 'searchText'),
        maxPages: Ember.get(this, 'maxPages'),
        listings: Ember.get(this, 'listings'),
        pageSize: Ember.get(this, 'pageSize'),
        orderBy: Ember.get(this, 'orderBy'),
        filter: Ember.get(this, 'filter'),
        page: Ember.get(this, 'page')
      };
      Ember.set(this, '_cache', _cache);
    },

    /**
     * Desists old object state from cache.
     *
     * @private
     * @method _desistState
     * @return {Void}
     */
    _desistState: function _desistState() {
      // unset watching flag to avoid
      // ongoing API calls until done
      Ember.set(this, '_isEnabled', false); // copy persisted state objects

      var _cache = Ember.get(this, '_cache');

      for (var key in _cache) {
        Ember.set(this, key, _cache[key]);
      } // renew active items from `filters`


      Ember.set(this, 'active', {});
      Ember.set(this, '_activeCount', 0);

      this._iterator(this._synchronize); // decode `_filters` from `filter`


      this._decodeFilter(); // persist restored state again
      // to update object references


      this._persistState(); // reenable property watching


      Ember.set(this, '_isEnabled', true);
    },

    /**
     * Decodes current filter with u-node.
     * Saves to `_filter` property and can
     * will be merged with base parameters.
     *
     * @method _decode
     * @return {Void}
     */
    _decodeFilter: function _decodeFilter() {
      var filter = Ember.get(this, 'filter');
      Ember.set(this, '_hasDecoded', true);

      if (Ember.isEmpty(filter)) {
        Ember.set(this, '_filter', {});
        return;
      }

      try {
        var _filter = _uNode.default.decode([filterV1, filterV2, filterV3], filter);

        Ember.set(this, '_filter', _filter);
      } catch (e) {
        console.error(e, 'Cannot decode filter query parameters!');
      }
    },

    /**
     * Encodes current filter with u-node.
     * Saves to `filter` property and can
     * be used for query params by routes.
     *
     * @method _encodeFilter
     * @return {Void}
     */
    _encodeFilter: function _encodeFilter() {
      var _filter = Ember.get(this, '_filter');

      var filterKeys = Object.keys(_filter);
      var empty = 0;
      filterKeys.forEach(function (key) {
        if (Ember.isEmpty(_filter[key])) {
          delete _filter[key];
          empty++;
        }
      });
      Ember.set(this, '_hasEncoded', true);

      if (empty === filterKeys.length) {
        var filter = Ember.get(this, 'defaults.filter');
        Ember.set(this, 'filter', filter);
        return;
      }

      try {
        Ember.set(this, 'filter', _uNode.default.encode(filterSpec, _filter));
      } catch (e) {
        console.error('Cannot encode filter query parameters!');
      }
    },

    /**
     * Checks if `shop` and `auction` properties
     * differs in order to redirect properly.
     *
     * @private
     * @method _verifySearchAgent
     * @param  {Model.SearchAgent} searchAgent
     * @return {Boolean}
     */
    _verifySearchAgent: function _verifySearchAgent(searchAgent) {
      if (Ember.isNone(searchAgent)) {
        return true;
      }

      var searchShop = Ember.get(this, 'shop') || null;
      var searchAuction = Ember.get(this, 'auction') || null;
      var agentShop = Ember.get(searchAgent, 'listingFilter.shop');
      var agentAuction = Ember.get(searchAgent, 'listingFilter.auction');
      return searchShop == agentShop && searchAuction == agentAuction;
    },
    // -------------------------------------------------------------------------
    // Observers

    /**
     * Triggers `properties_changed` event and
     * handles reloading of listings and filters.
     * This is best done via an observer as this
     * is the most reliable change detection and
     * will prevent double invocation if service
     * properties are changed both internally and
     * externally (see: search mixin >>> params)!
     *
     * @type observer _watchProperties
     * @private
     */
    _watchProperties: Ember.observer('shop', 'page', 'filter', 'auction', 'orderBy', 'pageSize', 'isDefault', 'searchText', function (sender, key) {
      switch (key) {
        case 'page':
          this._loadListings();

          break;

        case 'searchText':
          // implicitly set `orderBy` to `RELEVANCE`
          // if there's a `searchText` set currently
          // eslint-disable-next-line no-case-declarations
          var searchText = Ember.get(this, 'searchText'); // eslint-disable-next-line no-case-declarations

          var orderBy = searchText ? 'RELEVANCE' : 'DEFAULT';
          Ember.set(this, 'orderBy', orderBy);
        // eslint-disable-next-line no-fallthrough

        case 'shop':
        case 'auction':
        case 'orderBy':
        case 'pageSize':
          this._reloadListings();

          break;

        case 'filter':
          // Note: Only decode and refresh
          // if `filter` has been modified
          // externally, which is indicated
          // by `_hasEncoded` internal flag
          if (!Ember.get(this, '_hasEncoded')) {
            this._decodeFilter();

            this._reloadListings();
          }

          Ember.set(this, '_hasEncoded', false);
          break;

        case 'isDefault':
          if (Ember.get(this, 'isDefault')) {
            Ember.set(this, '_cache', {});
          }

          break;

        default:
      }

      Ember.set(this, '_hasChanged', true);
    })
  });
  /**
   * JSON spec for version 1.
   *
   * @property filterSpecV1
   * @type {Object}
   * @private
   */


  _exports.default = _default;
  var filterSpecV1 = {
    warehouses: ['array', ['varchar']],
    //@todo: change to ids asap (requires backend)
    shipsTo: ['fixedchar', 2],
    descriptors: ['array', ['integer']],
    varieties: ['array', ['integer']],
    processings: ['array', ['oneOf', 'NATURAL', 'FULLY_WASHED', 'SEMI_WASHED', 'PULPED_NATURAL', 'YEAST_FERMENTED', 'HONEY', 'MISC', 'OTHER']],
    availabilities: ['array', ['oneOf', 'SPOT', 'ORIGIN', 'AFLOAT']],
    origins: ['array', ['fixedchar', 2]],
    certifications: ['array', ['integer']],
    format: ['oneOf', 'ALL', 'TRADER_OFFERING', 'AUCTION'],
    grades: ['array', ['varchar']],
    scoreRanges: ['array', ['oneOf', 'UNDER_80', 'FROM_80_TO_85', 'FROM_85_TO_90', 'OVER_90']],
    amountMin: ['integer'],
    amountMax: ['integer'],
    amountUnit: ['oneOf', 'KG', 'LBS'],
    roastedSampleAvailable: ['boolean'],
    decaf: ['boolean']
  };
  /**
   * JSON spec for version 2.
   *
   * @property filterSpecV2
   * @type {Object}
   * @private
   */

  var filterSpecV2 = Ember.assign({}, filterSpecV1, {
    expressCoffee: ['boolean']
  });
  /**
   * JSON spec for version 3.
   *
   * @property filterSpecV3
   * @type {Object}
   * @private
   */

  var filterSpecV3 = Ember.assign({}, filterSpecV2, {
    specialOffer: ['boolean']
  });
  /**
   * Version 1 of URL encoder.
   *
   * @property filterV1
   * @type {Object}
   * @private
   */

  var filterV1 = _uNode.default.fromJson(1, filterSpecV1);
  /**
   * Version 2 of URL encoder.
   *
   * @property filterV2
   * @type {Object}
   * @private
   */


  var filterV2 = _uNode.default.fromJson(2, filterSpecV2, function (o) {
    o.expressCoffee = false;
    return o;
  });
  /**
   * Version 3 of URL encoder.
   *
   * @property filterV3
   * @type {Object}
   * @private
   */


  var filterV3 = _uNode.default.fromJson(3, filterSpecV3, function (o) {
    o.specialOffer = false;
    return o;
  });
  /**
   * Latest filter spec for URL shortening library `u-node`.
   *
   * @property filterSpec
   * @type {Object}
   * @public
   */


  var filterSpec = filterV3;
  _exports.filterSpec = filterSpec;
});