define("@vollersgroup/hub-frontend/admin/orders/show/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "@vollersgroup/hub-frontend/mixins/reset-scroll", "moment"], function (_exports, _authenticatedRouteMixin, _resetScroll, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _resetScroll.default, {
    model: function model(params) {
      return this.store.findRecord('order', params.order_id, {
        reload: true
      });
    },

    /**
     * Redirect to admin.sales.show if this is not an order but a sale
     * @param model
     */
    afterModel: function afterModel(model) {
      var _this = this;

      if (model.get('status') === 'CART') {
        this.transitionTo('admin.orders');
        return;
      }

      return new Ember.RSVP.Promise(function (resolve) {
        model.get('buyerCompany').then(function (company) {
          Ember.run.next(function () {
            if (company.get('id') !== _this.get('userSession.currentCompany.id')) {
              _this.transitionTo('admin.sales.show', model);
            }

            model.reload();
            model.get('orderItems').reload();
            resolve();
          });
        });
      });
    },
    actions: {
      acceptOffer: function acceptOffer() {
        var _this2 = this;

        var model = this.currentModel;
        model.set('status', 'ACCEPTED');
        model.set('closedDate', (0, _moment.default)());
        model.save().then(function () {
          _this2.get('toast').success(_this2.get('l10n').t('You have accepted the offer!'));

          _this2.transitionTo('admin.orders');

          _this2.controllerFor('admin.orders.index').notifyPropertyChange('filterStatus');
        });
      },
      rejectOffer: function rejectOffer() {
        var _this3 = this;

        var model = this.currentModel;
        model.set('status', 'OFFER_REJECTED');
        model.set('closedDate', (0, _moment.default)());
        model.save().then(function () {
          _this3.get('toast').info(_this3.get('l10n').t('You have rejected the offer!'));

          _this3.transitionTo('admin.orders');

          _this3.controllerFor('admin.orders.index').notifyPropertyChange('filterStatus');
        });
      },
      error: function error() {
        this.transitionTo('admin.orders');
      }
    }
  });

  _exports.default = _default;
});