define("@vollersgroup/hub-frontend/admin/manage/bulk/bulk-route-mixin", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Base mixin for bulk routes.
   *
   * @namespace Mixin
   * @class BulkRouteMixin
   * @abstract
   */
  var _default = Ember.Mixin.create(_emberCan.CanMixin, {
    // -------------------------------------------------------------------------
    // Dependencies
    availableData: Ember.inject.service(),
    bulkLookupData: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Query parameter definition.
     *
     * @property queryParams
     * @type {Array}
     * @public
     */
    queryParams: {
      ids: {
        refreshModel: true
      }
    },

    /**
     * Flag whether this route is used
     * either for edit or create route.
     *
     * @property isEdit
     * @type {Boolean}
     * @public
     */
    isEdit: false,

    /**
     * Current model name for bulk importing.
     * This has to in sync with named routing
     * and model name, f.e. 'listing' on the
     * route /admin/manage/bulk/listing[-edit].
     *
     * @property currentImportMode
     * @type {String}
     * @public
     */
    modelName: null,

    /**
     * Configuration of initial step to
     * be started when not in edit mode.
     *
     * @property defaultStep
     * @type {String}
     * @public
     */
    defaultStep: 'upload',

    /**
     * Name of overview route.
     *
     * @property overviewRoute
     * @type {String}
     * @public
     */
    overviewRoute: null,

    /**
     * Translations for `modelName` consisting
     * of an hash with `singluar` and `plural`
     * properties to be used in components.
     *
     * @property modelTranslations
     * @type {Object}
     * @public
     */
    modelTranslations: Ember.computed({
      get: function get()
      /*key*/
      {
        return {
          singular: '',
          plural: ''
        };
      }
    }),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Renders templates for admin route manually.
     * Per default, it renders secondary nav items
     * from manage pod to `secondary-nav` outlet.
     *
     * @public
     * @method renderTemplate
     * @return {Void}
     */
    renderTemplate: function renderTemplate() {
      var modelTranslations = Ember.get(this, 'modelTranslations');
      var overviewRoute = Ember.get(this, 'overviewRoute');
      var title = !Ember.get(this, 'isEdit') ? Ember.get(this, 'l10n').t('Create {{modelName}}', {
        modelName: modelTranslations.plural
      }) : Ember.get(this, 'l10n').t('Edit {{modelName}}', {
        modelName: modelTranslations.plural
      });
      this.controller.set('navbar', {
        route: overviewRoute,
        title: title
      });
      this.render();
      this.render('admin/manage/navbar-subpage', {
        outlet: 'secondary-nav',
        into: 'admin'
      });
    },

    /**
     * Redirects to default query parameter to start at beginning.
     * Note: Actual `step` gets set in controller's setup() method.
     *
     * @public
     * @method beforeModel
     * @param {Object} transition
     * @return {Void}
     */
    beforeModel: function beforeModel()
    /*transition*/
    {
      if (Ember.get(this, 'isEdit')) {
        return;
      }

      if (!this.can('edit template settings')) {
        this._abort();
      }

      this.transitionTo({
        queryParams: {
          step: Ember.get(this, 'defaultStep')
        }
      });
    },

    /**
     * Preloads company, shops, locations and producers from user.
     *
     * @public
     * @method model
     * @param {Object} params
     * @return {RSVP.Promise}
     */
    model: function model(params) {
      if (!Ember.get(this, 'isEdit')) {
        return {
          step: Ember.get(this, 'defaultStep')
        };
      }

      if (!Ember.isEmpty(params.ids)) {
        var modelName = Ember.get(this, 'modelName');
        return this.store.query(modelName, {
          ids: params.ids
        });
      }

      this._abort();
    },

    /**
     * Makes validation checks for loaded model.
     *
     * @public
     * @method model
     * @param {Object} params
     * @return {Promise}
     */
    afterModel: function afterModel(model) {
      if (!Ember.get(this, 'isEdit')) {
        return;
      }

      if (!Ember.isEmpty(model)) {
        return;
      }

      this._abort();
    },

    /**
     * Invokes `setup()` method of controller.
     *
     * @public
     * @method setupController
     * @param {Object} controller
     * @param {Mixed} model
     * @return {Promise}
     */
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.setup();
    },

    /**
     * Invokes `reset()` method of controller.
     *
     * @public
     * @method resetController
     * @param {Object} controller
     * @param {Boolean} isExiting
     * @param {Object} transition
     * @return {Promise}
     */
    resetController: function resetController(controller, isExiting
    /*, transition*/
    ) {
      this._super.apply(this, arguments);

      if (!isExiting) {
        return;
      }

      controller.reset();
    },

    /**
     * Redirects to overview on error cases.
     *
     * @private
     * @method _redirectToOverview
     * @return {Void}
     */
    _abort: function _abort() {
      this.transitionTo(Ember.get(this, 'overviewRoute'));
    }
  });

  _exports.default = _default;
});