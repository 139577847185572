define("@vollersgroup/hub-frontend/admin/manage/producer/edit/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "@vollersgroup/hub-frontend/mixins/reset-scroll", "ember-can"], function (_exports, _authenticatedRouteMixin, _resetScroll, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The route to edit a producer.
   *
   * @namespace Route
   * @class ManageShopCreate
   * @extends Ember.Route
   * @uses Mixin.CanMixin
   * @uses Mixin.ResetScroll
   * @uses SimpleAuth.Mixin.AuthenticatedRouteMixin
   */
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _resetScroll.default, _emberCan.CanMixin, {
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Renders templates for admin route manually.
     * Per default, it renders secondary nav items
     * from manage pod to `secondary-nav` outlet.
     *
     * @public
     * @method renderTemplate
     * @return {Void}
     */
    renderTemplate: function renderTemplate() {
      this.controller.set('navbar', {
        route: 'admin.manage.overview.producer',
        title: Ember.get(this, 'l10n').t('Edit origin')
      });
      this.render();
      this.render('admin/manage/navbar-subpage', {
        outlet: 'secondary-nav',
        into: 'admin'
      });
    },

    /**
     * Performs ability check if user can edit producer.
     *
     * @public
     * @method beforeModel
     * @return {Promise}
     */
    beforeModel: function beforeModel() {
      if (!this.can('edit producer')) {
        this.transitionTo('admin.manage.overview.producer');
        return;
      }

      return this._super.apply(this, arguments);
    },

    /**
     * Get selected producer object.
     *
     * @method model
     * @returns Model.Producer
     */
    model: function model(params) {
      return this.store.findRecord('producer', params.producer_id);
    },

    /**
     * Abort the transition if the model is not editable.
     *
     * @metod afterModel
     * @param model
     * @param transition
     */
    afterModel: function afterModel(model, transition) {
      var _this = this;

      if (!model.get('isEditable')) {
        this.get('toast').warning(this.get('l10n').t('You cannot edit this origin!'));

        if (transition.sequence === 0) {
          this.transitionTo('admin.manage.overview.producer');
        } else {
          transition.abort();
        }

        return;
      }

      return model.get('company').then(function (company) {
        if (company.get('id') + '' !== _this.get('userSession.currentCompany.id') + '') {
          _this.get('toast').warning(_this.get('l10n').t('You cannot edit this origin!'));

          if (transition.sequence === 0) {
            _this.transitionTo('admin.manage.overview.producer');
          } else {
            transition.abort();
          }

          return;
        }

        return model.reload();
      });
    },

    /**
     * Resets route's model and controller step.
     *
     * @public
     * @method deactivate
     * @return {Void}
     */
    deactivate: function deactivate() {
      this.currentModel.rollbackAttributes();
      this.controller.set('step', 0);
    },
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Redirects to overview on model errors.
       *
       * @public
       * @event error
       * @return {Void}
       */
      error: function error() {
        this.get('toast').warning(this.get('l10n').t('You cannot edit this origin!'));
        this.transitionTo('admin.manage.overview.producer');
      }
    }
  });

  _exports.default = _default;
});