define("@vollersgroup/hub-frontend/components/breadcrumbs-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Display a list of breadcrumbs
   *
   * ```html
   * {{breadcrumbs-list}}
   * ```
   *
   * @namespace Component
   * @class BreadcrumbsList
   * @augments Ember.Component
   */
  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    l10n: Ember.inject.service(),
    router: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Store's index route injected by initializer.
     *
     * @property routes.indexRoute
     * @type Ember.Route
     * @default null
     * @public
     */
    indexRoute: Ember.computed(function () {
      // note: ember sets `routeName` only on actual visits!
      var route = Ember.getOwner(this).lookup('route:store.index');
      Ember.set(route, 'routeName', 'store.index');
      return route;
    }),

    /**
     * Store's shops route injected by initializer.
     *
     * @property routes.shopsRoute
     * @type Ember.Route
     * @default null
     * @public
     */
    shopsRoute: Ember.computed(function () {
      // note: ember sets `routeName` only on actual visits!
      var route = Ember.getOwner(this).lookup('route:store.shops');
      Ember.set(route, 'routeName', 'store.shops');
      return route;
    }),

    /**
     * Store's auctions route injected by initializer.
     *
     * @property routes.auctionsRoute
     * @type Ember.Route
     * @default null
     * @public
     */
    auctionsRoute: Ember.computed(function () {
      // note: ember sets `routeName` only on actual visits!
      var route = Ember.getOwner(this).lookup('route:store.auctions');
      Ember.set(route, 'routeName', 'store.auctions');
      return route;
    }),

    /**
     * Store's shop route injected by initializer.
     *
     * @property routes.shopRoute
     * @type Ember.Route
     * @default null
     * @public
     */
    shopRoute: Ember.computed(function () {
      // note: ember sets `routeName` only on actual visits!
      var route = Ember.getOwner(this).lookup('route:store.shop');
      Ember.set(route, 'routeName', 'store.shop');
      return route;
    }),

    /**
     * Store's auction route injected by initializer.
     *
     * @property routes.auctionRoute
     * @type Ember.Route
     * @default null
     * @public
     */
    auctionRoute: Ember.computed(function () {
      // note: ember sets `routeName` only on actual visits!
      var route = Ember.getOwner(this).lookup('route:store.auctions.show');
      Ember.set(route, 'routeName', 'store.auctions.show');
      return route;
    }),

    /**
     * Store's company route injected by initializer.
     *
     * @property routes.companyRoute
     * @type Ember.Route
     * @default null
     * @public
     */
    companyRoute: Ember.computed(function () {
      // note: ember sets `routeName` only on actual visits!
      var route = Ember.getOwner(this).lookup('route:store.company');
      Ember.set(route, 'routeName', 'store.company');
      return route;
    }),

    /**
     * Store's listing route injected by initializer.
     *
     * @property routes.listingRoute
     * @type Ember.Route
     * @default null
     * @public
     */
    listingRoute: Ember.computed(function () {
      // note: ember sets `routeName` only on actual visits!
      var route = Ember.getOwner(this).lookup('route:store.listing');
      Ember.set(route, 'routeName', 'store.listing');
      return route;
    }),

    /**
     * Composition of restore and breadcrumb items.
     *
     * @property items
     * @type {Array}
     */
    items: Ember.computed.union('restoreItems', 'breadcrumbItems'),

    /**
     * Breadcrumb items, where every item consist of the following structure:
     *
     * ```js
     * var breadcrumbItem = {
     *     label: <String>,
     *     route: <String>,
     *     model: <Object>,
     *     params: {
     *      searchText: <String>,
     *      pageSize: <Number>,
     *      orderBy: <Enum>,
     *      filter: <String>,
     *      page: <Number>
     *     }
     * };
     * ```
     *
     * @property items
     * @type Array
     * @default []
     * @public
     */
    // eslint-disable-next-line ember/require-computed-property-dependencies
    breadcrumbItems: Ember.computed('router.currentRouteName', function () {
      if (this.router.currentRouteName.includes('store.loading')) {
        return [];
      }

      var breadcrumbItems = [{
        route: Ember.get(this, 'indexRoute.routeName'),
        label: Ember.get(this, 'l10n').t('All coffees'),
        params: Ember.get(this, 'indexRoute.controller.defaults')
      }];

      switch (true) {
        // --- LISTING PAGE ---
        // a) All coffees > Auctions > Auction > Listing
        // b) All coffees > Shops > Shop > Listing
        case this._isActive('listingRoute'):
          {
            var model = Ember.get(this, 'listingRoute.currentModel'); // a) listing's connected to an auction

            if (!Ember.isNone(Ember.get(model, 'auctionId'))) {
              breadcrumbItems.pushObjects([{
                label: Ember.get(this, 'l10n').t('Auctions'),
                route: Ember.get(this, 'auctionsRoute.routeName')
              }, {
                model: Ember.get(model, 'auction'),
                labelKey: 'model.name',
                route: Ember.get(this, 'auctionRoute.routeName'),
                params: Ember.get(this, 'auctionRoute.controller.defaults')
              }, {
                model: model,
                label: Ember.get(model, 'name'),
                route: Ember.get(this, 'listingRoute.routeName')
              }]);
              break;
            } // b) listing's not connected to an auction


            breadcrumbItems.pushObjects([{
              label: Ember.get(this, 'l10n').t('Shops'),
              route: Ember.get(this, 'shopsRoute.routeName')
            }, {
              model: Ember.get(model, 'shop'),
              labelKey: 'model.name',
              route: Ember.get(this, 'shopRoute.routeName'),
              params: Ember.get(this, 'shopRoute.controller.defaults')
            }, {
              model: model,
              label: Ember.get(model, 'name'),
              route: Ember.get(this, 'listingRoute.routeName')
            }]);
            break;
          }
        // --- AUCTION PAGE ---
        // All coffees > Auctions > Auction

        case this._isActive('auctionRoute'):
          {
            breadcrumbItems.pushObjects([{
              label: Ember.get(this, 'l10n').t('Auctions'),
              route: Ember.get(this, 'auctionsRoute.routeName')
            }, {
              route: Ember.get(this, 'auctionRoute.routeName'),
              model: Ember.get(this, 'auctionRoute.currentModel'),
              label: Ember.get(this, 'auctionRoute.currentModel.name'),
              params: Ember.get(this, 'auctionRoute.controller.defaults')
            }]);
            break;
          }
        // --- SHOP PAGE ---
        // All coffees > Shops > Shop

        case this._isActive('shopRoute'):
          {
            breadcrumbItems.pushObjects([{
              label: Ember.get(this, 'l10n').t('Shops'),
              route: Ember.get(this, 'shopsRoute.routeName')
            }, {
              route: Ember.get(this, 'shopRoute.routeName'),
              model: Ember.get(this, 'shopRoute.currentModel'),
              label: Ember.get(this, 'shopRoute.currentModel.name'),
              params: Ember.get(this, 'shopRoute.controller.defaults')
            }]);
            break;
          }
        // --- COMPANY PAGE ---
        // All coffees > Company

        case this._isActive('companyRoute'):
          {
            breadcrumbItems.pushObject({
              route: Ember.get(this, 'companyRoute.routeName'),
              model: Ember.get(this, 'companyRoute.currentModel'),
              label: Ember.get(this, 'companyRoute.currentModel.name')
            });
            break;
          }
        // --- AUCTIONS PAGE ---
        // All coffees > Auctions

        case this._isActive('auctionsRoute'):
          {
            breadcrumbItems.pushObject({
              label: Ember.get(this, 'l10n').t('Auctions'),
              route: Ember.get(this, 'auctionsRoute.routeName')
            });
            break;
          }
        // --- SHOPS PAGE ---
        // All coffees > Shops

        case this._isActive('shopsRoute'):
          {
            breadcrumbItems.pushObject({
              label: Ember.get(this, 'l10n').t('Shops'),
              route: Ember.get(this, 'shopsRoute.routeName')
            });
            break;
          }
      }

      return breadcrumbItems;
    }),

    /**
     * Restore items, where every item consist of the following structure:
     *
     * ```js
     * var restoreItem = {
     *     label: <String>,
     *     route: <String>,
     *     model: <Object>,
     *     params: {
     *      searchText: <String>,
     *      pageSize: <Number>,
     *      orderBy: <Enum>,
     *      filter: <String>,
     *      page: <Number>
     *     }
     * };
     * ```
     *
     * @property restoreItems
     * @type Array
     * @default []
     * @public
     */
    restoreItems: Ember.computed('_pageVisits', 'router.currentRouteName', function () {
      var _pageVisits = Ember.get(this, '_pageVisits');

      var restoreItem;

      switch (true) {
        // --- LISTING/COMPANY PAGE ---
        case this._isActive('listingRoute'):
        case this._isActive('companyRoute'):
          {
            restoreItem = _pageVisits.auctionRoute || _pageVisits.shopRoute || _pageVisits.indexRoute;
            break;
          }
        // --- AUCTION PAGE ---

        case this._isActive('auctionRoute'):
          {
            restoreItem = _pageVisits.shopRoute || _pageVisits.auctionsRoute || _pageVisits.indexRoute;
            break;
          }
        // --- SHOP PAGE ---

        case this._isActive('shopRoute'):
          {
            restoreItem = _pageVisits.shopsRoute || _pageVisits.indexRoute;
            break;
          }

        default:
          {
            break;
          }
      }

      if (!Ember.isNone(restoreItem)) {
        return [restoreItem];
      }

      return [];
    }),

    /**
     * Listing count of result set.
     *
     * @property searchResults
     * @type {Number}
     * @public
     */
    searchResults: Ember.computed('router.currentRouteName', 'shopRoute.search.totalItems', 'indexRoute.search.totalItems', 'auctionRoute.search.totalItems', function () {
      switch (true) {
        case this._isActive('auctionRoute'):
          {
            return Ember.get(this, 'auctionRoute.search.totalItems');
          }

        case this._isActive('indexRoute'):
          {
            return Ember.get(this, 'indexRoute.search.totalItems');
          }

        case this._isActive('shopRoute'):
          {
            return Ember.get(this, 'shopRoute.search.totalItems');
          }

        default:
          {
            return 0;
          }
      }
    }),

    /**
     * Alias for the last item in stack.
     *
     * @property lastItem
     * @type {Object}
     */
    lastItem: Ember.computed.reads('items.lastObject'),

    /**
     * Page visit hash map for `restoreItems`.
     *
     * @property _pageVisits
     * @type {Number}
     * @public
     */
    _pageVisits: Ember.computed(function () {
      return {};
    }),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Adds router observer.
     *
     * @public
     * @method init
     * @return {Void}
     */
    init: function init() {
      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('routerTransitions', this, this._onRouteChanged);
      this.addObserver('router.currentRouteName', this, this._onRouteChanged);
    },

    /**
     * Removes router observer.
     *
     * @public
     * @method init
     * @return {Void}
     */
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      this.removeObserver('router.currentRouteName', this, this._onRouteChanged);
    },

    /**
     * Checks if given route is active.
     *
     * @method _isActive
     * @param {String} route
     * @return {Void}
     * @private
     */
    _isActive: function _isActive(route) {
      var routeName = Ember.get(this, "".concat(route, ".routeName"));
      return Ember.get(this, 'router').isActive(routeName);
    },

    /**
     * Manages state of `_pageVisits`.
     *
     * @method _updatePageVisits
     * @return {Void}
     * @private
     */
    _onRouteChanged: function _onRouteChanged() {
      var currentRouteName = Ember.get(this, 'router.currentRouteName');

      var _pageVisits = Ember.get(this, '_pageVisits');

      switch (true) {
        // --- LISTING/COMPANY PAGE ---
        case this._isActive('listingRoute'):
        case this._isActive('companyRoute'):
          {
            // opt out from resetting
            break;
          }
        // --- AUCTION PAGE ---

        case this._isActive('auctionRoute'):
          {
            _pageVisits['auctionRoute'] = {
              isRestore: true,
              params: Ember.get(this, 'auctionRoute.search'),
              route: Ember.get(this, 'auctionRoute.routeName'),
              label: Ember.get(this, 'l10n').t('Back to auction'),
              model: Ember.get(this, 'auctionRoute.currentModel')
            };
            break;
          }
        // --- SHOP PAGE ---

        case this._isActive('shopRoute'):
          {
            _pageVisits['shopRoute'] = {
              isRestore: true,
              params: Ember.get(this, 'shopRoute.search'),
              route: Ember.get(this, 'shopRoute.routeName'),
              label: Ember.get(this, 'l10n').t('Back to shop'),
              model: Ember.get(this, 'shopRoute.currentModel')
            };
            break;
          }
        // --- AUCTIONS PAGE ---

        case this._isActive('auctionsRoute'):
          {
            _pageVisits['auctionsRoute'] = {
              isRestore: true,
              route: Ember.get(this, 'auctionsRoute.routeName'),
              label: Ember.get(this, 'l10n').t('Back to auction search')
            }; // delete invalid entries from stack

            delete _pageVisits['auctionRoute'];
            delete _pageVisits['shopRoute'];
            break;
          }
        // --- SHOPS PAGE ---

        case this._isActive('shopsRoute'):
          {
            _pageVisits['shopsRoute'] = {
              isRestore: true,
              route: Ember.get(this, 'shopsRoute.routeName'),
              label: Ember.get(this, 'l10n').t('Back to shop search')
            }; // delete invalid entries from stack

            delete _pageVisits['auctionRoute'];
            delete _pageVisits['shopRoute'];
            break;
          }
        // --- INDEX PAGE ---

        case this._isActive('indexRoute'):
          {
            _pageVisits['indexRoute'] = {
              isRestore: true,
              params: Ember.get(this, 'indexRoute.search'),
              route: Ember.get(this, 'indexRoute.routeName'),
              label: Ember.get(this, 'l10n').t('Back to coffees')
            }; // delete invalid entries from stack

            delete _pageVisits['auctionsRoute'];
            delete _pageVisits['auctionRoute'];
            delete _pageVisits['shopsRoute'];
            delete _pageVisits['shopRoute'];
            break;
          }

        default:
          {
            // reset on all other pages except loading
            // pages (depending on `loading` folders!)
            if (!currentRouteName.match(/loading/)) {
              Ember.set(this, '_pageVisits', {});
            }
          }
      }
    }
  });

  _exports.default = _default;
});