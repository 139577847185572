define("@vollersgroup/hub-frontend/components/footer-main/component", ["exports", "moment", "@vollersgroup/hub-frontend/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The footer which is used everywhere in the app.
   *
   * ```html
   * {{footer-main}}
   * ```
   *
   * @namespace Component
   * @class FooterMain
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Element Properties

    /**
     * @property tagName
     * @type String
     * @final
     * @private
     */
    tagName: '',
    // -------------------------------------------------------------------------
    // Properties

    /**
     * The current year in the format YYYY
     *
     * @property currentYear
     * @type String
     * @private
     */
    currentYear: Ember.computed(function () {
      return (0, _moment.default)().format('YYYY');
    }),
    appVersion: Ember.computed(function () {
      var reg = /\d+[.]\d+[.]\d+|\d+[.]\d+|\d+/;
      var version = _environment.default.APP.version;
      var match = version.match(reg);
      return match[0] || version;
    })
  });

  _exports.default = _default;
});