define("@vollersgroup/hub-frontend/helpers/background-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.backgroundImage = backgroundImage;
  _exports.default = void 0;

  function backgroundImage(params
  /*, hash*/
  ) {
    // returning `background-image: undefined` results
    // in a proxied call to the backend, thus the auth
    // dialog from basic authentication would popup...
    var url = encodeURI(params.join(''));

    if (Ember.isEmpty(url)) {
      return null;
    }

    return Ember.String.htmlSafe("background-image: url(".concat(url, ");"));
  }

  var _default = Ember.Helper.helper(backgroundImage);

  _exports.default = _default;
});