define("@vollersgroup/hub-frontend/admin/manage/auction/create/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    availableData: Ember.inject.service(),
    l10n: Ember.inject.service(),
    queryParams: ['addToShop'],
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Select a shop for this auction.
       *
       * @event selectShop
       * @param {Model.Shop} shop
       * @private
       */
      selectShop: function selectShop(shop) {
        var _this = this;

        this.get('model').setProperties({
          shop: shop,
          bidStepCurrency: shop.get('currency')
        });
        Ember.run.next(function () {
          _this.set('step', 1);

          window.scrollTo(0, 0);
        });
      },

      /**
       * When the auction information has been entered/updated.
       *
       * @event editAuction
       * @private
       */
      editAuction: function editAuction() {
        this.incrementProperty('step');
        window.scrollTo(0, 0);
      },

      /**
       * Add listings to this auction.
       *
       * @event addListings
       * @param {Model.Listing[]} listings
       * @param {Model.Listing[]} addedListings
       * @param {Model.Listing[]} removedListings
       * @private
       */
      addListings: function addListings(listings) {
        var model = this.get('model');
        model.set('listings', []);
        model.get('listings').pushObjects(listings);
        listings.forEach(function (listing) {
          listing.set('auction', model);
          listing.set('status', 'ACTIVE');
          listing.save();
        });
        this.incrementProperty('step');
        window.scrollTo(0, 0);
      },

      /**
       * Publish the auction.
       *
       * @event publish
       * @param {Boolean} createCoffee
       * @private
       */
      publish: function publish() {
        var createCoffee = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        if (createCoffee) {
          this.transitionToRoute('admin.manage.listing.create', {
            queryParams: {
              addToAuction: this.get('model.id'),
              addtoShop: this.get('model.shop.id')
            }
          });
        } else {
          this.transitionToRoute('admin.manage.overview.auction');
        }
      },

      /**
       * Move to another step.
       *
       * @event gotoStep
       * @param {Number} newStep
       * @private
       */
      gotoStep: function gotoStep(newStep) {
        if (newStep > this.get('step')) {
          this.get('toast').warning(this.get('l10n').t('You have to save your changes!'));
          return;
        }

        this.set('step', newStep);
        window.scrollTo(0, 0);
      }
    },
    // -------------------------------------------------------------------------
    // Properties

    /**
     * The current step of the process.
     *
     * @property step
     * @type Number
     */
    step: 0,

    /**
     * Pre-select the shop to which the auction should be added. This should be the ID of a shop.
     *
     * @property addToShop
     * @type String
     */
    addToShop: null,

    /**
     * All shops the user can select from.
     *
     * @property availableShops
     * @type Model.Shop[]
     * @private
     */
    availableShops: Ember.computed.alias('availableData.shops'),

    /**
     * All steps for the creation process.
     *
     * @property allSteps
     * @type Array
     */
    allSteps: Ember.computed('availableShops.{firstObject,length}', 'l10n', function () {
      var steps = [];

      if (this.get('availableShops.length') === 1) {
        this.send('selectShop', this.get('availableShops.firstObject'));
      } else {
        steps.push({
          label: this.get('l10n').t('Select shop'),
          step: 0,
          subSteps: []
        });
      }

      steps.push({
        label: this.get('l10n').t('Create auction'),
        step: 1,
        subSteps: [{
          label: this.get('l10n').t('Base information'),
          anchor: '#auction-manage-1'
        }, {
          label: this.get('l10n').t('Policy'),
          anchor: '#auction-manage-2'
        }]
      });
      steps.push({
        label: this.get('l10n').t('Add coffee'),
        step: 2,
        subSteps: []
      });
      steps.push({
        label: this.get('l10n').t('Publish'),
        step: 3,
        subSteps: []
      });
      return steps;
    }) // -------------------------------------------------------------------------
    // Methods

  });

  _exports.default = _default;
});