define("@vollersgroup/hub-frontend/components/c-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A bound select box
   *
   * ```html
   * {{c-select id="my_id" class="my_class" required=false value=selectedValue options=optionsObject searchable=true}}
   *
   * @namespace Component
   * @class CSelect
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    tagName: '',
    // -------------------------------------------------------------------------
    // Dependencies
    l10n: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * The id for the input
     *
     * @attribute id
     * @optional
     * @type String
     */
    id: null,

    /**
     * A class string for the input
     *
     * @attribute class
     * @optional
     * @type String
     */
    class: null,

    /**
     * If the input is required
     *
     * @attribute required
     * @optional
     * @default false
     * @type Boolean
     */
    required: false,

    /**
     * The value of the input
     *
     * @required
     * @attribute value
     * @type Mixed
     */
    value: '',

    /**
     * If the input is disabled
     *
     * @attribute disabled
     * @optional
     * @default false
     * @type Boolean
     */
    disabled: false,

    /**
     * The placeholder of the input.
     *
     * @attribute placeholder
     * @optional
     * @type String
     */
    placeholder: null,

    /**
     * If the select should be searchable
     *
     * @attribute searchable
     * @optional
     * @type Boolean
     * @default false
     */
    searchable: false,

    /**
     * If the multiple values should be selectable.
     *
     * @attribute multiple
     * @optional
     * @type Boolean
     * @default false
     */
    multiple: false,

    /**
     * The options for the select. The array selects have to be objects with `value` and `label` fields.
     *
     * @attribute options
     * @optional
     * @type Array
     * @default false
     */
    options: false,

    /**
     * The action to call when the select is updated.
     *
     * @attribute action
     * @type String
     * @optional
     */
    action: null,

    /**
     * Indicated wheter it should display error.
     *
     * @attribute hasError
     * @optional
     * @type Boolean
     * @default false
     */
    hasError: false,

    /**
     * If true, the bound object will be passed
     * instead of item's `value` property only.
     *
     * @attribute reference
     * @type Boolean
     * @optional
     */
    reference: false,
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Provides parsed values either by value or
       * or by reference. Besides, it provides an
       * array if `multiple` is set to true.
       *
       * @event update
       * @param {String} value
       * @param {Object} extra
       * @private
       */
      update: function update(value, extra) {
        var multiple = this.getAttr('multiple');
        value = !multiple ? this._parseValue(value) : []; // collect values if `multiple` option is used

        if (multiple) {
          var select = Ember.get(this, 'select').get(0);
          var options = select.options;

          for (var i = 0; i < options.length; i++) {
            if (!Ember.get(options[i], 'selected')) {
              continue;
            }

            var attr = Ember.get(options[i], 'value');
            value.push(this._parseValue(attr));
          }
        } // detect changes on intial trigger, otherwise
        // don't fire an update if `value` is the same


        if (extra && extra.checkEquality) {
          if (!this._hasChanged(value)) {
            return;
          }
        } // otherwise forward changes to consumer


        Ember.set(this, 'value', value);
        this.sendAction('action', value);
      }
    },
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Reference to <select> as jQuery select.
     *
     * @property select
     * @type {Object}
     * @private
     */
    select: Ember.computed('domId', {
      get: function get()
      /*key*/
      {
        return Ember.$("#".concat(Ember.get(this, 'domId')));
      }
    }),

    /**
     * Calculates dom id for <select> either by given
     * `id` attribute or autoassigned select dom id.
     *
     * @property domId
     * @type {String}
     * @private
     */
    domId: Ember.computed('elementId', 'id', {
      get: function get()
      /*key*/
      {
        var id = this.getAttr('id');

        if (!Ember.isNone(id)) {
          return id;
        }

        return "select-".concat(Ember.get(this, 'elementId'));
      }
    }),

    /**
     * Parse the options to accept both `id` or `value`
     * as identifier and `label` or `name` for text.
     *
     * @property parsedOptions
     * @type {Array}
     * @private
     */
    parsedOptions: Ember.computed('options.@each.{id,label,name,value}', 'value', {
      get: function get()
      /*key*/
      {
        var _this = this;

        var options = this.getAttr('options');

        if (!Ember.isArray(options)) {
          return [];
        }

        var hashedOptions = {};
        var parsedOptions = [];
        options.forEach(function (item, index) {
          var value = Ember.get(item, 'id') || Ember.get(item, 'value');
          var label = Ember.get(item, 'name') || Ember.get(item, 'label');
          var disabled = Ember.get(item, 'disabled') || Ember.get(item, 'archived');
          var data = {
            reference: item,
            disabled: disabled,
            label: label,
            value: value,
            index: index
          };
          hashedOptions[value] = data;
          parsedOptions.push(data);
        });
        Ember.set(this, 'hashedOptions', hashedOptions);
        var value = this.get('value');

        if (!Ember.isEmpty(value)) {
          if (!Ember.isArray(value)) {
            value = [value];
          }

          value.forEach(function (item) {
            var key = _this._getKey(item);

            var data = hashedOptions[key];

            if (Ember.isNone(data)) {
              return;
            }

            Ember.set(data, 'selected', true);
          });
        }

        return parsedOptions;
      }
    }),

    /**
     * Internal hash representation of `parsedOptions`
     * for quicker lookups when updating the selection.
     *
     * @property hashedOptions
     * @type {Object}
     * @private
     */
    hashedOptions: Ember.computed({
      get: function get()
      /*key*/
      {
        return {};
      }
    }),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Invokes one-shot transformation of incoming
     * value in case `reference` option and `value`
     * are different, thus assuring type safety.
     *
     * @public
     * @method didReceiveAttrs
     * @return {Void}
     */
    init: function init() {
      this._super.apply(this, arguments);

      if (Ember.get(this, 'parsedOptions.length')) {
        this._initialize();

        return;
      }

      this.addObserver('parsedOptions.[]', this, this._initialize);
      this.addObserver('parsedOptions.[]', this, this._updateChosen);
    },

    /**
     * Updates selected values according to `value` attribute.
     *
     * @public
     * @method didReceiveAttrs
     * @return {Void}
     */
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this._updateSelection();
    },

    /**
     * Fakes focus for `.select__custom` if `searchable` is false.
     * If no `placeholder`, updates field value on load.
     * Inserts the combo box if `searchable` is true.
     *
     * @public
     * @method didInsertElement
     * @return {Void}
     */
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var placeholder = this.getAttr('placeholder');
      var searchable = this.getAttr('searchable');
      var isMobile = Ember.get(this, 'browser.isMobile');
      var select = Ember.get(this, 'select'); // 1) mobile (no chosen)

      if (isMobile) {
        // a) fake `focus` state and set `disabled`
        if (!searchable) {
          var readOnly = this.getAttr('readonly');
          var parent = select.parent();

          if (readOnly) {
            select.attr('disabled', 'disabled');
            parent.attr('disabled', 'disabled');
          }

          select.bind('focus', function () {
            parent.addClass('is-focused');
          });
          select.bind('blur', function () {
            parent.removeClass('is-focused');
          });
          return;
        } // b) wrap custom select for mobile version
        // instead of instantiating `chosen` plugin!


        select.wrap("<div class='select__custom' />");
        return;
      } // 2) desktop (chosen)


      if (searchable) {
        select.wrap("<div class='select__chosen--fix'/>").chosen({
          width: '100%',
          search_contains: true,
          placeholder_text_single: placeholder || Ember.get(this, 'l10n').t('Select an option'),
          placeholder_text_multiple: placeholder || Ember.get(this, 'l10n').t('Select some options')
        });
      }
    },

    /**
     * Clean up and remove bindings.
     *
     * @method willDestroyElement
     * @public
     */
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.removeObserver('parsedOptions.[]', this, this._updateChosen);
      var select = Ember.get(this, 'select');
      select.unbind('focus');
      select.unbind('blur');
    },

    /**
     * Makes initial check as soon as
     * options are ready for operation.
     *
     * @method _initialize
     * @return {Void}
     */
    _initialize: function _initialize() {
      var _this2 = this;

      Ember.run.scheduleOnce('afterRender', function () {
        var select = Ember.get(_this2, 'select');
        select.trigger('change', {
          checkEquality: true
        });
      });
      this.removeObserver('parsedOptions.[]', this, this._initialize);
    },

    /**
     * Updates `selected` property of options
     * each time a new value is passed from
     * attributes.
     *
     * @method willDestroyElement
     * @public
     */
    _updateSelection: function _updateSelection() {
      var _this3 = this;

      var parsedOptions = Ember.get(this, 'parsedOptions');
      var hashedOptions = Ember.get(this, 'hashedOptions');
      var value = this.getAttr('value');

      if (Ember.isEmpty(value)) {
        parsedOptions.setEach('selected', false);

        this._updateChosen();

        return;
      }

      if (!Ember.isArray(value)) {
        value = [value];
      }

      value.forEach(function (value) {
        var key = _this3._getKey(value); // eslint-disable-next-line no-prototype-builtins


        if (hashedOptions.hasOwnProperty(key)) {
          var index = hashedOptions[key].index;
          var option = parsedOptions[index];
          Ember.set(option, 'selected', true);
        }
      });

      this._updateChosen();
    },

    /**
     * Triggers `chosen:updated` if parsed
     * options has been changed externally.
     *
     * @private
     * @method _updateChosen
     * @return {Void}
     */
    _updateChosen: function _updateChosen() {
      var _this4 = this;

      if (!this.getAttr('searchable')) {
        return;
      }

      Ember.run.scheduleOnce('afterRender', function () {
        var select = Ember.get(_this4, 'select');
        select.trigger('chosen:updated');
      });
    },

    /**
     * Checks if provided value has currently
     * changed in comparison to current value.
     *
     * @private
     * @method _hasChanged
     * @param {Object} value
     * @return {Boolean}
     */
    _hasChanged: function _hasChanged(value) {
      var _this5 = this;

      var newValue = value;
      var oldValue = Ember.get(this, 'value');

      var isEqualKey = function isEqualKey(itemA, itemB) {
        var keyA = _this5._getKey(itemA);

        var keyB = _this5._getKey(itemB);

        return keyA === keyB;
      };

      var sortByKey = function sortByKey(items) {
        items.sort(function (itemA, itemB) {
          var keyA = _this5._getKey(itemA);

          var keyB = _this5._getKey(itemB);

          return keyA > keyB;
        });
      };

      var hasChanged = false;

      switch (Ember.typeOf(newValue)) {
        case 'array':
          // eslint-disable-next-line no-case-declarations
          var lengthNew = newValue.length;

          if (Ember.typeOf(oldValue) !== 'array') {
            hasChanged = true;
            break;
          } // eslint-disable-next-line no-case-declarations


          var lengthOld = oldValue.length;

          if (lengthNew !== lengthOld) {
            hasChanged = true;
            break;
          } // eslint-disable-next-line no-case-declarations


          var changes = 0;
          sortByKey(newValue);
          sortByKey(oldValue);
          newValue.forEach(function (newVal, index) {
            var oldVal = oldValue[index];

            if (isEqualKey(newVal, oldVal)) {
              return;
            }

            changes++;
          });
          hasChanged = changes > 0;
          break;

        case 'object':
        case 'instance':
          hasChanged = !isEqualKey(newValue, oldValue);
          break;

        case 'null':
          hasChanged = Ember.isEmpty(oldValue) === false;
          break;

        default:
          /*jslint eqeq: true*/
          hasChanged = !(Ember.isEmpty(newValue) && Ember.isEmpty(oldValue)) ? newValue != oldValue : false;
      }

      return hasChanged;
    },

    /**
     * Retrieves/parses an option value. If `reference` option
     * is used, it will provide the original object/instance,
     * otherwise it will try to cast strings to native types.
     *
     * @private
     * @method _parseValue
     * @param {Mixed} item
     * @return {Mixed}
     */
    _parseValue: function _parseValue(value) {
      var hashedOptions = Ember.get(this, 'hashedOptions');
      var reference = this.getAttr('reference');
      var hashedOption = hashedOptions[value];

      if (hashedOption) {
        return !reference ? hashedOption.value : hashedOption.reference;
      }

      return value || null;
    },

    /**
     * Extracts the unique identifier of an item.
     * This is either `id` or `value` property for
     * objects/instances or the item itself for all
     * primitive data types.
     *
     * @private
     * @method  _getKey
     * @param  {Mixed} item
     * @return {Mixed}
     */
    _getKey: function _getKey(item) {
      var key;

      switch (Ember.typeOf(item)) {
        case 'object':
        case 'instance':
          key = Ember.get(item, 'id') || Ember.get(item, 'value');
          break;

        default:
          key = item;
      }

      return key;
    }
  });

  _exports.default = _default;
});