define("@vollersgroup/hub-frontend/components/manage-listing-publish/component", ["exports", "moment", "@vollersgroup/hub-frontend/utils/get-first-adapter-error-message"], function (_exports, _moment, _getFirstAdapterErrorMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A module to publish a listing.
   *
   * ```html
   * {{manage-listing-publish listing=myListing action="myAction"}}
   * ```
   *
   * The action is called without parameters when the listing is published.
   *
   * @namespace Component
   * @class ManageListingPublish
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies

    /**
     * @property store
     * @type DS.Store
     * @private
     */
    store: Ember.inject.service(),
    userSession: Ember.inject.service(),
    availableData: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * The listing that should be published.
     *
     * @attribute listing
     * @type Model.Listing
     * @required
     */
    listing: null,

    /**
     * The name of the action to call when the listing is published.
     * This action receives no parameters.
     *
     * @attribute action
     * @type string
     * @required
     */
    action: null,

    /**
     * The ID of an auction which the coffee should be added to.
     *
     * @attribute selectedAuctionId
     * @type string
     */
    selectedAuctionId: '',
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Actually publish the listing.
       *
       * @event publish
       * @private
       */
      publish: function publish() {
        var _this = this;

        var listing = this.getAttr('listing');
        listing.set('auction', null);
        listing.set('status', 'ACTIVE');
        listing.set('firstPublishedDate', (0, _moment.default)());
        this.set('isLoading', true);
        this.set('showDetails', null);
        listing.save().then(function () {
          _this.get('toast').success(_this.get('l10n').t('You have successfully published your coffee!'));

          _this.sendAction('action', _this.get('createAnother'));
        }, function () {
          _this.set('isLoading', false);

          _this.get('toast').error(_this.get('l10n').t('Your coffee could not be published.'));
        });
      },

      /**
       * Deactivate the listing.
       *
       * @event deactivate
       * @private
       */
      deactivate: function deactivate() {
        var _this2 = this;

        var listing = this.getAttr('listing');
        listing.set('status', 'INACTIVE');
        listing.set('auction', null);
        this.set('isLoading', true);
        this.set('showDetails', null);
        listing.save().then(function () {
          _this2.get('toast').success(_this2.get('l10n').t('You have successfully deactivated your coffee!'));

          _this2.sendAction('action', _this2.get('createAnother'));
        }, function () {
          _this2.set('isLoading', false);

          _this2.get('toast').error(_this2.get('l10n').t('Your coffee could not be deactivated.'));
        });
      },

      /**
       * Continue without making the listing public.
       *
       * @event continue
       * @private
       */
      continue: function _continue() {
        this.sendAction('action', this.get('createAnother'));
      },

      /**
       * Offer the listing privately.
       *
       * @event privateOffer
       * @private
       */
      privateOffer: function privateOffer() {
        var _this3 = this;

        var listing = this.getAttr('listing');
        listing.set('auction', null);
        listing.set('status', 'ACTIVE');
        listing.set('private', true);
        this.set('isLoading', true);
        this.set('showDetails', null);
        listing.save().then(function () {
          _this3.get('toast').success(_this3.get('l10n').t('You are now offering your coffee privately.'));

          _this3.sendAction('action', _this3.get('createAnother'));
        }, function () {
          _this3.set('isLoading', false);

          _this3.get('toast').error(_this3.get('l10n').t('Your coffee could not be published.'));
        });
      },

      /**
       * Actually select an auction and continue.
       *
       * @event selectShop
       * @private
       */
      selectAuction: function selectAuction() {
        var _this4 = this;

        var listing = this.getAttr('listing');

        if (!this.getAttr('selectedAuctionId')) {
          return;
        }

        this.set('isLoading', true);
        this.get('selectedAuction').then(function (auction) {
          listing.set('auction', auction);
          listing.set('status', 'ACTIVE');
          listing.save().then(function () {
            _this4.get('toast').success(_this4.get('l10n').t('Your coffee has been added to the selected auction.'));

            _this4.sendAction('action', _this4.get('createAnother'));
          });
        });
      },

      /**
       * Expand a section for details.
       *
       * @event showDetails
       * @param val
       * @private
       */
      showDetails: function showDetails(val) {
        this.set('showDetails', val);
      },

      /**
       * Handles updates of auto renew
       * checkbox when toggled by user.
       *
       * @event autoRenew
       * @param {Boolean} value
       * @private
       */
      autoRenew: function autoRenew(value) {
        var _this5 = this;

        var l10n = this.get('l10n');
        var toast = this.get('toast');
        var listing = this.get('listing');

        var successCallback = function successCallback() {
          var activateMessage = l10n.t('You have successfully activated automated publishing.');
          var deactivateMessage = l10n.t('You have successfully deactivated automated publishing.');
          toast.success(value ? activateMessage : deactivateMessage);
        };

        var failureCallback = function failureCallback(adapterError) {
          var serverErrorMessage = (0, _getFirstAdapterErrorMessage.default)(adapterError);
          var errorMessage = Ember.get(_this5, 'l10n').t('An error occurred while changing automated publishing: {{serverErrorMessage}}', {
            serverErrorMessage: serverErrorMessage
          });
          toast.error(errorMessage);
        };

        listing.save().then(successCallback).catch(failureCallback);
      }
    },
    // -------------------------------------------------------------------------
    // Properties

    /**
     * When this is true, some computation is currently occuring.
     *
     * @property isLoading
     * @type Boolean
     * @private
     */
    isLoading: false,

    /**
     * If this is true, then the user will be redirected to "create a coffee" again after he finished.
     *
     * @property createAnother
     * @type Boolean
     * @private
     */
    createAnother: false,

    /**
     * If details for auction or private offerings should be displayed.
     *
     * @property showDetails
     * @type null|AUCTION|PRIVATE
     * @private
     */
    showDetails: Ember.computed('selectedAuctionId', function () {
      if (this.get('selectedAuctionId')) {
        return 'AUCTION';
      }

      return null;
    }),

    /**
     * Load the actual auction object for the selected auction id.
     *
     * @property selectedAuction
     * @type Model.Auction
     * @private
     */
    selectedAuction: Ember.computed('selectedAuctionId', 'store', function () {
      return this.get('store').findRecord('auction', this.getAttr('selectedAuctionId'));
    }),

    /**
     * All auctions the user can select from.
     *
     * @property availableAuctions
     * @type Model.Auction[]
     * @private
     */
    availableAuctions: Ember.computed.filter('availableData.auctions', function (auction) {
      return auction.get('shop.id') === this.getAttr('listing').get('shop.id');
    }),

    /**
     * An options-object parsed from the available auctions.
     *
     * @property auctionOptions
     * @type Array
     * @private
     */
    auctionOptions: Ember.computed('availableAuctions.[]', function () {
      var auctions = this.get('availableAuctions');
      return auctions.map(function (auction) {
        return {
          label: auction.get('name'),
          value: auction.get('id') + ''
        };
      });
    }),
    canPublishToShop: Ember.computed('listing.status', 'listing.auction', function () {
      var status = this.getAttr('listing').get('status');
      return ['DRAFT', 'INACTIVE', 'EXPIRED'].indexOf(status) > -1 || status === 'ACTIVE' && !Ember.isEmpty(this.getAttr('listing').get('auction.content'));
    }),
    canPublishToAuction: Ember.computed('listing.status', 'availableAuctions.length', 'userSession.currentCompany.auctionsEnabled', function () {
      var status = this.getAttr('listing').get('status');
      return ['DRAFT', 'INACTIVE', 'ACTIVE'].indexOf(status) > -1 && this.get('userSession.currentCompany.auctionsEnabled') && this.get('availableAuctions.length') > 0;
    }),
    canDeactivate: Ember.computed('listing.status', function () {
      var status = this.getAttr('listing').get('status');
      return ['ACTIVE', 'EXPIRED'].indexOf(status) > -1;
    })
  });

  _exports.default = _default;
});