define("@vollersgroup/hub-frontend/validators/unique-shop-slug", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    store: Ember.inject.service(),
    l10n: Ember.inject.service(),
    validate: function validate(slug, options, model, _attribute) {
      var _this = this;

      return this.get('store').query('shop', {
        slug: slug
      }).then(function (shops) {
        return shops.get('firstObject');
      }).then(function (shop) {
        var responseId = Ember.get(shop, 'id');
        var shopId = Ember.get(model, 'id'); // Shop id might be null, but responseId can't be null as it come from server,
        // thus we don't need to check for null.

        if (shopId !== responseId) {
          return Ember.get(_this, 'l10n').t("The shop URL '{{slug}}' is already taken.", {
            slug: slug
          });
        }

        return true;
      }) // If server returns NotFound then it's not used
      .catch(function () {
        return true;
      });
    }
  });

  _exports.default = _default;
});