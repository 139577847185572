define("@vollersgroup/hub-frontend/gql/hub-backend/country-code.graphql", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var doc = {
    "kind": "Document",
    "definitions": [{
      "kind": "EnumTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "CountryCode"
      },
      "directives": [],
      "values": [{
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Andorra",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "AD"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "United Arab Emirates",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "AE"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Afghanistan",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "AF"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Antigua and Barbuda",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "AG"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Anguilla",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "AI"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Albania",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "AL"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Armenia",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "AM"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Angola",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "AO"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Antarctica",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "AQ"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Argentina",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "AR"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "American Samoa",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "AS"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Austria",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "AT"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Australia",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "AU"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Aruba",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "AW"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Åland Islands",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "AX"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Azerbaijan",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "AZ"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Bosnia and Herzegovina",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "BA"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Barbados",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "BB"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Bangladesh",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "BD"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Belgium",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "BE"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Burkina Faso",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "BF"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Bulgaria",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "BG"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Bahrain",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "BH"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Burundi",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "BI"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Benin",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "BJ"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Saint Barthélemy",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "BL"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Bermuda",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "BM"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Brunei Darussalam",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "BN"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Bolivia",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "BO"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Bonaire, Sint Eustatius and Saba",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "BQ"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Brazil",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "BR"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Bahamas",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "BS"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Bhutan",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "BT"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Bouvet Island",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "BV"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Botswana",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "BW"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Belarus",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "BY"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Belize",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "BZ"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Canada",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "CA"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Cocos (Keeling) Islands",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "CC"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Congo, the Democratic Republic of the (CD)",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "CD"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Central African Republic",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "CF"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Congo, Republic of the (CG)",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "CG"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Switzerland",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "CH"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Ivory Coast",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "CI"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Cook Islands",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "CK"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Chile",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "CL"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Cameroon",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "CM"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "China",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "CN"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Colombia",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "CO"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Costa Rica",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "CR"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Cuba",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "CU"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Cape Verde",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "CV"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Curaçao",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "CW"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Christmas Island",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "CX"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Cyprus",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "CY"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Czech Republic",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "CZ"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Germany",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "DE"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Djibouti",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "DJ"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Denmark",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "DK"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Dominica",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "DM"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Dominican Republic",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "DO"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Algeria",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "DZ"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Ecuador",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "EC"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Estonia",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "EE"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Egypt",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "EG"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Western Sahara",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "EH"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Eritrea",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "ER"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Spain",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "ES"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Ethiopia",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "ET"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Finland",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "FI"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Fiji",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "FJ"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Falkland Islands (Malvinas)",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "FK"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Micronesia, Federated States of",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "FM"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Faroe Islands",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "FO"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "France",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "FR"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Gabon",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "GA"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "United Kingdom",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "GB"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Grenada",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "GD"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Georgia",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "GE"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "French Guiana",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "GF"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Guernsey",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "GG"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Ghana",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "GH"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Gibraltar",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "GI"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Greenland",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "GL"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Gambia",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "GM"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Guinea",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "GN"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Guadeloupe",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "GP"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Equatorial Guinea",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "GQ"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Greece",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "GR"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "South Georgia and the South Sandwich Islands",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "GS"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Guatemala",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "GT"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Guam",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "GU"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Guinea-Bissau",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "GW"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Guyana",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "GY"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Hong Kong",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "HK"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Heard Island and Mcdonald Islands",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "HM"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Honduras",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "HN"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Croatia",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "HR"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Haiti",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "HT"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Hungary",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "HU"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Indonesia",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "ID"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Ireland",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "IE"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Israel",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "IL"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Isle of Man",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "IM"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "India",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "IN"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "British Indian Ocean Territory",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "IO"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Iraq",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "IQ"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Iran, Islamic Republic of",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "IR"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Iceland",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "IS"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Italy",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "IT"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Jersey",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "JE"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Jamaica",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "JM"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Jordan",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "JO"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Japan",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "JP"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Kenya",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "KE"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Kyrgyzstan",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "KG"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Cambodia",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "KH"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Kiribati",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "KI"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Comoros",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "KM"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Saint Kitts and Nevis",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "KN"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Korea, Democratic People's Republic of",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "KP"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Korea, Republic of",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "KR"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Kuwait",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "KW"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Cayman Islands",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "KY"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Kazakhstan",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "KZ"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Lao, People's Democratic Republic",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "LA"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Lebanon",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "LB"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Saint Lucia",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "LC"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Liechtenstein",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "LI"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Sri Lanka",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "LK"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Liberia",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "LR"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Lesotho",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "LS"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Lithuania",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "LT"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Luxembourg",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "LU"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Latvia",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "LV"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Libya",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "LY"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Morocco",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "MA"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Monaco",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "MC"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Moldova, Republic of",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "MD"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Montenegro",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "ME"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Saint Martin (French part)",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "MF"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Madagascar",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "MG"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Marshall Islands",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "MH"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Macedonia, the Former Yugoslav Republic of",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "MK"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Mali",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "ML"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Myanmar",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "MM"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Mongolia",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "MN"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Macao",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "MO"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Northern Mariana Islands",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "MP"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Martinique",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "MQ"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Mauritania",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "MR"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Montserrat",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "MS"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Malta",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "MT"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Mauritius",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "MU"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Maldives",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "MV"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Malawi",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "MW"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Mexico",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "MX"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Malaysia",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "MY"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Mozambique",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "MZ"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Namibia",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "NA"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "New Caledonia",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "NC"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Niger",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "NE"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Norfolk Island",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "NF"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Nigeria",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "NG"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Nicaragua",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "NI"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Netherlands",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "NL"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Norway",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "NO"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Nepal",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "NP"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Nauru",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "NR"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Niue",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "NU"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "New Zealand",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "NZ"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Oman",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "OM"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Panama",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "PA"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Peru",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "PE"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "French Polynesia",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "PF"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Papua New Guinea",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "PG"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Philippines",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "PH"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Pakistan",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "PK"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Poland",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "PL"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Saint Pierre and Miquelon",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "PM"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Pitcairn Island",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "PN"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Puerto Rico",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "PR"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Palestine, State of",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "PS"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Portugal",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "PT"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Palau",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "PW"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Paraguay",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "PY"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Qatar",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "QA"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Réunion Island",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "RE"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Romania",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "RO"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Serbia",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "RS"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Russian Federation",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "RU"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Rwanda",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "RW"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Saudi Arabia",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "SA"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Solomon Islands",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "SB"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Seychelles",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "SC"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Sudan",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "SD"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Sweden",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "SE"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Singapore",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "SG"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Saint Helena",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "SH"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Slovenia",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "SI"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Svalbard and Jan Mayen Island",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "SJ"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Slovakia",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "SK"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Sierra Leone",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "SL"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "San Marino",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "SM"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Senegal",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "SN"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Somalia",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "SO"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Suriname",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "SR"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "South Sudan",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "SS"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Sao Tome and Principe",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "ST"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "El Salvador",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "SV"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Sint Maarten",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "SX"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Syrian Arab Republic",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "SY"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Swaziland",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "SZ"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Turks and Caicos Islands",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "TC"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Chad",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "TD"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "French Southern Territories",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "TF"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Togo",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "TG"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Thailand",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "TH"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Tajikistan",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "TJ"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Tokelau",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "TK"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Timor-Leste",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "TL"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Turkmenistan",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "TM"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Tunisia",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "TN"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Tonga",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "TO"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Turkey",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "TR"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Trinidad and Tobago",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "TT"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Tuvalu",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "TV"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Taiwan, Province of China",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "TW"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Tanzania, United Republic of",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "TZ"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Ukraine",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "UA"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Uganda",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "UG"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "United States Minor Outlying Islands",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "UM"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "United States",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "US"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Uruguay",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "UY"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Uzbekistan",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "UZ"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Holy See (Vatican City State)",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "VA"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Saint Vincent and the Grenadines",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "VC"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Venezuela",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "VE"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Virgin Islands, (UK)",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "VG"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Virgin Islands, (US)",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "VI"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Viet Nam",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "VN"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Vanuatu",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "VU"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Wallis and Futuna Island",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "WF"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Samoa",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "WS"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Yemen",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "YE"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Mayotte",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "YT"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "South Africa",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "ZA"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Zambia",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "ZM"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Zimbabwe",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "ZW"
        },
        "directives": []
      }]
    }],
    "loc": {
      "start": 0,
      "end": 8034
    }
  };
  var _default = doc;
  _exports.default = _default;
});