define("@vollersgroup/hub-frontend/components/loading-spinner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dmy1meg+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"text-center \",[27,\"if\",[[23,[\"isCompact\"]],\"padding-top-2 padding-bottom-2\",\"padding-top-6 padding-bottom-6\"],null]]]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"loading-circle-basic inline-block vertical-align-middle\"],[9],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/loading-spinner/template.hbs"
    }
  });

  _exports.default = _default;
});