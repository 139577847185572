define("@vollersgroup/hub-frontend/about/legal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PobfkE7u",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"margin-top-4\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"wrapper padding-bottom-4\"],[9],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"layout__sidebar--left layout__sidebar--left--small\"],[9],[0,\"\\n      \"],[7,\"ul\"],[11,\"class\",\"third-nav\"],[9],[0,\"\\n        \"],[7,\"li\"],[9],[4,\"link-to\",[\"about.legal.index\"],[[\"class\"],[\"third-nav__item\"]],{\"statements\":[[1,[27,\"t\",[\"Legal notice\"],null],false]],\"parameters\":[]},null],[10],[0,\"\\n        \"],[7,\"li\"],[9],[4,\"link-to\",[\"about.legal.security\"],[[\"class\"],[\"third-nav__item\"]],{\"statements\":[[1,[27,\"t\",[\"Security\"],null],false]],\"parameters\":[]},null],[10],[0,\"\\n        \"],[7,\"li\"],[9],[4,\"link-to\",[\"about.legal.terms-of-service\"],[[\"class\"],[\"third-nav__item\"]],{\"statements\":[[1,[27,\"t\",[\"Terms of service\"],null],false]],\"parameters\":[]},null],[10],[0,\"\\n        \"],[7,\"li\"],[9],[4,\"link-to\",[\"about.legal.privacy-policy\"],[[\"class\"],[\"third-nav__item\"]],{\"statements\":[[1,[27,\"t\",[\"Privacy policy\"],null],false]],\"parameters\":[]},null],[10],[0,\"\\n        \"],[7,\"li\"],[9],[4,\"link-to\",[\"about.legal.seller-requirements\"],[[\"class\"],[\"third-nav__item\"]],{\"statements\":[[1,[27,\"t\",[\"Seller requirements\"],null],false]],\"parameters\":[]},null],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"layout__sidebar--main\"],[9],[0,\"\\n      \"],[1,[21,\"outlet\"],false],[0,\"\\n    \"],[10],[0,\"\\n\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/about/legal/template.hbs"
    }
  });

  _exports.default = _default;
});