define("@vollersgroup/hub-frontend/components/manage-shop-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SxiHt9hQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\"],[9],[1,[27,\"t\",[\"Select a shop\"],null],false],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"availableData\",\"activeShops\"]]],null,{\"statements\":[[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"margin-bottom-3\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"Please select a shop. Note that you cannot change the shop later on.\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"form\"],[11,\"class\",\"margin-bottom-3\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"padding-bottom-4\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"select__custom input__full\"],[9],[0,\"\\n        \"],[7,\"label\"],[11,\"for\",\"select_shop\"],[11,\"class\",\"hidden\"],[9],[1,[27,\"t\",[\"Select shop\"],null],false],[10],[0,\"\\n        \"],[1,[27,\"c-select\",null,[[\"required\",\"action\",\"id\",\"value\",\"options\"],[true,\"select\",\"select_shop\",[27,\"readonly\",[[23,[\"selectedShopId\"]]],null],[27,\"readonly\",[[23,[\"availableData\",\"activeShops\"]]],null]]]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"padding-bottom-4\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"button\"],[11,\"data-name\",\"select-shop-button\"],[9],[1,[27,\"t\",[\"Select & continue\"],null],false],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"submit\"],[[\"on\"],[\"submit\"]]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"p\"],[11,\"class\",\"margin-bottom-3\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"You need to create a shop first before you can create coffee listings.\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/manage-shop-select/template.hbs"
    }
  });

  _exports.default = _default;
});