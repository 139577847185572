define("@vollersgroup/hub-frontend/components/user-invitation-list/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This component lists open user invitations.
   *
   * It will automatically filter out all consumed and expired invitations.
   * If there are no open invitations, nothing will be displayed.
   *
   * ```hbs
   * {{user-invitation-list invitations=allUserInvitations}}
   * ```
   *
   * @namespace Component
   * @class UserInvitationList
   * @extends Ember.Component
   * @uses Service.UserSession
   */
  var _default = Ember.Component.extend({
    userSession: Ember.inject.service(),

    /**
     * The user invitations to display.
     *
     * @attribute invitations
     * @type Array
     */
    invitations: null,

    /**
     * Filter out expired/consumed user invitations.
     *
     * @property openUserInvitations
     * @type Array
     */
    openUserInvitations: Ember.computed.filter('invitations', function (invitation) {
      return !Ember.get(invitation, 'consumedDate') && (0, _moment.default)().valueOf() < Ember.get(invitation, 'expiredDate');
    }),

    /**
     * Map the invitations to objects with correct moment.js objects for dates.
     *
     * @property openUserInvitationsMapped
     * @type Array
     */
    openUserInvitationsMapped: Ember.computed.map('openUserInvitations', function (invitation) {
      invitation = Ember.Object.create(invitation);
      invitation.set('createdDate', (0, _moment.default)(invitation.get('createdDate')));
      invitation.set('expiredDate', (0, _moment.default)(invitation.get('expiredDate')));
      return invitation;
    })
  });

  _exports.default = _default;
});