define("@vollersgroup/hub-frontend/components/manage-plan-button/component", ["exports", "@vollersgroup/hub-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userSession: Ember.inject.service(),
    ajax: Ember.inject.service(),
    vBillingLoginUrl: "".concat(_environment.default.vBillingUrl, "/api/vhub-login"),
    username: Ember.computed.readOnly('userSession.currentUser.username'),
    actions: {
      managePlanClick: function managePlanClick() {
        this.ajax.post('current/billing-auth-token').then(function (response) {
          var el = document.getElementById('manage-plan-form');
          el[1].value = response.token;
          el.submit();
        });
      }
    }
  });

  _exports.default = _default;
});