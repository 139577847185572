define("@vollersgroup/hub-frontend/router", ["exports", "@vollersgroup/hub-frontend/config/environment", "@vollersgroup/hub-frontend/mixins/track-pageview"], function (_exports, _environment, _trackPageview) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend(_trackPageview.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('loading');
    this.route('add-to-wishlist', {
      path: '/add-to-wishlist/:rating_id'
    });
    this.route('store', function () {
      this.route('listing', {
        path: '/listings/:listing_id'
      });
      this.route('company', {
        path: '/companies/:company_id'
      });
      this.route('shops');
      this.route('shop', {
        path: '/shops/:shop_id'
      }, function () {
        this.route('story');
        this.route('policy');
        this.route('shipment');
        this.route('auctions');
      });
      this.route('auctions', function () {
        this.route('show', {
          path: '/:auction_id'
        }, function () {
          this.route('admin');
          this.route('policy');
          this.route('information');
        });
      });
    });
    this.route('register', function () {
      this.route('auction', {
        path: '/auctions/:auction_id'
      });
    });
    this.route('login');
    this.route('cart');
    this.route('checkout', function () {
      this.route('policy');
      this.route('review');
      this.route('payment');
    });
    this.route('admin', {
      path: '/my-hub'
    }, function () {
      this.route('manage', function () {
        this.route('loading');
        this.route('shop', function () {
          this.route('create');
          this.route('edit', {
            path: '/edit/:shop_id'
          });
        });
        this.route('listing', function () {
          this.route('create');
          this.route('edit', {
            path: '/edit/:listing_id'
          });
        });
        this.route('producer', {
          path: '/origin'
        }, function () {
          this.route('create');
          this.route('edit', {
            path: '/edit/:producer_id'
          });
        });
        this.route('auction', function () {
          this.route('create');
          this.route('edit', {
            path: '/edit/:auction_id'
          });
        });
        this.route('overview', function () {
          this.route('auction', function () {
            this.route('registrations');
          });
          this.route('shop');
          this.route('producer', {
            path: '/origin'
          });
          this.route('warehouse'); // legacy-warehouses

          this.route('warehouse-new', function () {
            this.route('migration');
          });
          this.route('warehouses', function () {
            this.route('add');
            this.route('create');
            this.route('owner', function () {
              this.route('create');
            });
          });
          this.route('registrant', {
            path: '/registrant/:registrant_id'
          });
        });
        this.route('bulk', function () {
          this.route('listing');
          this.route('listing-edit');
          this.route('producer');
          this.route('producer-edit');
        });
        this.route('c-market');
        this.route('print-offer-list');
        this.route('print-labels');
      });
      this.route('messages', {
        path: '/inbox'
      }, function () {
        this.route('notifications');
        this.route('offerings');
        this.route('show', {
          path: '/show/:conversation_id'
        });
      });
      this.route('orders', function () {
        this.route('seller', {
          path: '/seller/:order_id'
        });
        this.route('show', {
          path: '/show/:order_id'
        });
      });
      this.route('account', function () {
        this.route('saved-searches');
        this.route('ratings');
      });
      this.route('company', function () {
        this.route('users');
        this.route('subscription');
      });
      this.route('loading');
      this.route('sales', function () {
        this.route('buyer', {
          path: '/buyer/:order_id'
        });
        this.route('show', {
          path: '/show/:order_id'
        });
        this.route('list', function () {
          this.route('coffee');
        });
      });
      this.route('wishlist');
      this.route('welcome');
    });
    this.route('forgot-password');
    this.route('reset-password');
    this.route('info-center', function () {
      this.route('getting-started', function () {
        this.route('how-to-sell');
        this.route('how-to-buy');
        this.route('offer-list-widget');
        this.route('faq-general');
        this.route('faq-flavors');
        this.route('faq-varieties');
        this.route('why-to-buy');
        this.route('why-to-sell');
      });
      this.route('auctions', function () {
        this.route('how-to-bid');
      });
    });
    this.route('about', function () {
      this.route('legal', function () {
        this.route('security');
        this.route('privacy-policy');
        this.route('terms-of-service');
        this.route('seller-requirements');
        this.route('data-processing', function () {
          this.route('schedule-a');
          this.route('sub-processors');
        });
      });
    });
    this.route('rate', {
      path: '/rate/:listing_enc_id'
    });
    this.route('page-not-found', {
      path: '/*path'
    });
    this.route('pricing');
  });
  var _default = Router;
  _exports.default = _default;
});