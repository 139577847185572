define("@vollersgroup/hub-frontend/components/listing-autobid-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VAbaVdQR",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"and\",[[23,[\"listing\",\"currentAutoBid\"]],[27,\"not\",[[23,[\"listing\",\"auction\",\"isOwner\"]]],null],[23,[\"listing\",\"auction\",\"isRunning\"]]],null]],null,{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"listing__item__auction--autobid\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"listing\",\"highestBid\",\"isOwn\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"opacity-60\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"Your max. bid:\"],null],false],[0,\" \"],[1,[27,\"format-currency\",[[23,[\"listing\",\"currentAutoBid\",\"bidAmount\"]],[23,[\"listing\",\"priceCurrency\"]]],null],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"listing\",\"currentAutoBid\",\"bidAmount\"]],[23,[\"listing\",\"highestBid\",\"bidAmount\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"color-error\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"{{bidPrice}} only equals max bid.\"],[[\"bidPrice\"],[[27,\"format-currency\",[[23,[\"listing\",\"currentAutoBid\",\"bidAmount\"]],[23,[\"listing\",\"priceCurrency\"]]],null]]]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"color-error\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"Your {{bidPrice}} was outbid.\"],[[\"bidPrice\"],[[27,\"format-currency\",[[23,[\"listing\",\"currentAutoBid\",\"bidAmount\"]],[23,[\"listing\",\"priceCurrency\"]]],null]]]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/listing-autobid-info/template.hbs"
    }
  });

  _exports.default = _default;
});