define("@vollersgroup/hub-frontend/admin/manage/shop/create/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    l10n: Ember.inject.service(),
    userSession: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Verify the shop
       *
       * @event verify
       * @private
       */
      verify: function verify() {
        window.scrollTo(0, 0);
        Ember.set(this, 'step', 1);
      },

      /**
       * Actually save the shop
       *
       * @event save
       * @param Model.Shop shop
       * @private
       */
      save: function save() {
        window.scrollTo(0, 0);
        Ember.set(this, 'step', 2);
      },

      /**
       * Save the shops policies
       *
       * @event savePolicies
       * @param Model.Shop shop
       * @private
       */
      savePolicies: function savePolicies() {
        window.scrollTo(0, 0);
        Ember.set(this, 'step', 3);
      },

      /**
       * Save the shops defaults
       *
       * @event saveDefaults
       * @param Model.Shop shop
       * @private
       */
      saveDefaults: function saveDefaults() {
        window.scrollTo(0, 0);
        Ember.set(this, 'step', 4);
      },

      /**
       * Save the shops shipping
       *
       * @event saveShipping
       * @param Model.Shop shop
       * @private
       */
      saveShipping: function saveShipping() {
        window.scrollTo(0, 0);
        Ember.set(this, 'step', 5);
      },

      /**
       * Publish the shop
       *
       * @event publish
       * @param {Boolean} createCoffee
       * @private
       */
      publish: function publish() {
        var createCoffee = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

        if (createCoffee) {
          this.transitionToRoute('admin.manage.listing.create', {
            queryParams: {
              addToShop: Ember.get(this, 'model.id')
            }
          });
        } else {
          this.transitionToRoute('admin.manage.overview.shop');
        }
      },

      /**
       * Move to another step.
       *
       * @event gotoStep
       * @param {Number} newStep
       * @private
       */
      gotoStep: function gotoStep(newStep) {
        if (newStep > Ember.get(this, 'step')) {
          Ember.get(this, 'toast').warning(Ember.get(this, 'l10n').t('You have to save your changes!'));
          return;
        }

        Ember.set(this, 'step', newStep);
        window.scrollTo(0, 0);
      }
    },
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Current sub step of route.
     *
     * @public
     * @property step
     * @type Number
     * @default 0
     */
    step: Ember.computed('userSession.currentCompany.isVerified', function () {
      return Ember.get(this, 'userSession.currentCompany.isVerified') ? 1 : 0;
    }),

    /**
     * Definition of all sub steps depending
     * on current company verification status.
     *
     * @public
     * @property allSteps
     * @type Array
     */
    allSteps: Ember.computed('l10n', 'userSession.currentCompany.isVerified', function () {
      var steps = [];

      if (!Ember.get(this, 'userSession.currentCompany.isVerified')) {
        steps.push({
          label: 'Verification',
          step: 0,
          subSteps: []
        });
      }

      steps.push({
        label: Ember.get(this, 'l10n').t('Create shop'),
        step: 1,
        subSteps: [{
          label: Ember.get(this, 'l10n').t('Shop information'),
          anchor: '#shop-manage-1'
        }, {
          label: Ember.get(this, 'l10n').t('Shop presentation'),
          anchor: '#shop-manage-2'
        }]
      });
      steps.push({
        label: Ember.get(this, 'l10n').t('Shop policies'),
        step: 2,
        subSteps: [{
          label: Ember.get(this, 'l10n').t('Shipping information'),
          anchor: '#shop-manage-policies-1'
        }, {
          label: Ember.get(this, 'l10n').t('Policies'),
          anchor: '#shop-manage-policies-2'
        }]
      });
      steps.push({
        label: Ember.get(this, 'l10n').t('Shop defaults'),
        step: 3,
        subSteps: []
      });
      steps.push({
        label: Ember.get(this, 'l10n').t('Payment & shipping'),
        isNew: true,
        step: 4,
        subSteps: []
      });
      steps.push({
        label: Ember.get(this, 'l10n').t('Publish shop'),
        step: 5,
        subSteps: []
      });
      return steps;
    })
  });

  _exports.default = _default;
});