define("@vollersgroup/hub-frontend/components/expandable-text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JSI7pXtA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"expandable-text__content\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isOpen\"]]],null,{\"statements\":[[0,\"    \"],[1,[21,\"text\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"isExpandable\"]]],null,{\"statements\":[[0,\"      \"],[1,[21,\"textShort\"],true],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[21,\"text\"],true],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"isExpandable\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"text-right\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"button button--plain\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isOpen\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"t\",[\"Read less\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[27,\"t\",[\"Read more\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[3,\"action\",[[22,0,[]],\"toggle\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/expandable-text/template.hbs"
    }
  });

  _exports.default = _default;
});