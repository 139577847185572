define("@vollersgroup/hub-frontend/admin/manage/c-market/route", ["exports", "ember-can", "@vollersgroup/hub-frontend/mixins/reset-scroll", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _emberCan, _resetScroll, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The route for c-market management.
   *
   * @namespace Route
   * @class CMarketRoute
   * @extends Ember.Route
   * @uses Mixin.CanMixin
   * @uses Mixin.ResetScroll
   * @uses SimpleAuth.Mixin.AuthenticatedRouteMixin
   */
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _resetScroll.default, _emberCan.CanMixin, {
    // -------------------------------------------------------------------------
    // Dependencies
    toast: Ember.inject.service(),
    availableData: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Asserts user's permission to manage c-market.
     *
     * @public
     * @method model
     * @return {Promise}
     */
    beforeModel: function beforeModel() {
      if (!this.can('edit settings')) {
        this.transitionTo('admin.manage.overview');
      }
    },

    /**
     * Loads company settings and available shops.
     *
     * @public
     * @method model
     * @return {Promise}
     */
    model: function model() {
      return Ember.RSVP.hash({
        settings: Ember.get(this, 'userSession.currentCompany.settings'),
        shops: Ember.get(this, 'availableData.shops')
      });
    },
    // -------------------------------------------------------------------------
    // Properties
    actions: {
      /**
       * Checks if controller is in dirty state and
       * needs user interaction on how to handle the
       * currently unsaved changes - save or discard?
       *
       * @event willTransition
       * @return {Void}
       * @public
       */
      willTransition: function willTransition(transition) {
        if (!Ember.get(this.controller, 'isDirty')) {
          return true;
        }

        Ember.get(this, 'toast').warning(Ember.get(this, 'l10n').t('You have to save or discard your changes!'), Ember.get(this, 'l10n').t('Warning'));
        transition.abort();
      }
    }
  });

  _exports.default = _default;
});