define("@vollersgroup/hub-frontend/components/pagination-widget/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Show a pagination widget.
   *
   * ```html
   * {{pagination-widget page=1 maxPages=3 action="gotoPage"}}
   * ```
   *
   * The action receives the new page number as parameter:
   *
   * ```js
   * export default Controller.extend({
   *     actions: {
   *         gotoPage: function(newPage) {
   *             // go to the new page
   *         }
   *     }
   * });
   * ```
   *
   * @namespace Component
   * @class PaginationWidget
   * @extends Component
   */
  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * The page to start on.
     *
     * @attribute page
     * @type Number
     * @default 1
     * @optional
     */
    page: 1,

    /**
     * Items per page to show.
     *
     * @attribute pageSize
     * @type Number
     * @default 10
     * @optional
     */
    pageSize: 1,

    /**
     * The maximum number of pages available.
     *
     * @attribute maxPages
     * @type Number
     * @default 1
     * @optional
     */
    maxPages: 1,

    /**
     * If page size dropdown is visible.
     *
     * @attribute showPageSize
     * @type {Boolean}
     * @default true
     * @optional
     */
    showPageSize: true,
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Go to the next page if possible.
       *
       * @event nextPage
       * @private
       */
      nextPage: function nextPage() {
        if (Ember.get(this, 'curPage') < this.getAttr('maxPages')) {
          this.incrementProperty('curPage');
          this.sendAction('action', Ember.get(this, 'curPage'));
        }
      },

      /**
       * Go to the previous page if possible.
       *
       * @event prevPage
       * @private
       */
      prevPage: function prevPage() {
        if (Ember.get(this, 'curPage') > 1) {
          this.decrementProperty('curPage');
          this.sendAction('action', Ember.get(this, 'curPage'));
        }
      },

      /**
       * Go to the specified page if possible.
       *
       * @event gotoPage
       * @param {Number} page The page to go to
       * @private
       */
      gotoPage: function gotoPage(page) {
        if (page > 0 && page <= this.getAttr('maxPages')) {
          Ember.set(this, 'curPage', page);
          this.sendAction('action', Ember.get(this, 'curPage'));
        }
      },

      /**
       * Sends `changePageSize` action.
       *
       * @event changePageSize
       * @return {Void}
       * @private
       */
      changePageSize: function changePageSize(value) {
        this.sendAction('changePageSize', value);
      }
    },
    // -------------------------------------------------------------------------
    // Properties

    /**
     * The current page.
     *
     * @property curPage
     * @type Number
     * @private
     */
    curPage: 1,

    /**
     * The first page.
     *
     * @property firstPage
     * @type Number
     * @private
     */
    firstPage: 1,

    /**
     * The previous page.
     *
     * @property prevPage
     * @type Number
     * @private
     */
    prevPage: Ember.computed('curPage', 'firstPage', function () {
      var firstPage = Ember.get(this, 'firstPage');
      var curPage = Ember.get(this, 'curPage');

      if (curPage > firstPage) {
        return curPage - 1;
      }

      return null;
    }),

    /**
     * The next page.
     *
     * @property nextPage
     * @type Number
     * @private
     */
    nextPage: Ember.computed('curPage', 'lastPage', function () {
      var lastPage = Ember.get(this, 'lastPage');
      var curPage = Ember.get(this, 'curPage');

      if (curPage < lastPage) {
        return curPage + 1;
      }

      return null;
    }),

    /**
     * The last page.
     *
     * @property lastPage
     * @type Number
     * @private
     */
    lastPage: Ember.computed.alias('maxPages'),

    /**
     * If the "goto first page" button should be displayed.
     * The button is only displayed if the first page is not displayed anymore on its own.
     *
     * @property showGotoFirst
     * @type Boolean
     * @private
     */
    showGotoFirst: Ember.computed('curPage', 'maxPages', function () {
      return Ember.get(this, 'curPage') > 5 && this.getAttr('maxPages') > 10;
    }),

    /**
     * if the "goto last page" button should be displayed.
     * The button is only displayed if the last page is not displayed on its own.
     *
     * @property showGotoLast
     * @type Boolean
     * @private
     */
    showGotoLast: Ember.computed('curPage', 'maxPages', function () {
      return Ember.get(this, 'curPage') < this.getAttr('maxPages') - 5 && this.getAttr('maxPages') > 10;
    }),

    /**
     * An array of the pages that are currently displayed in the pagination widget.
     * A maximum of 9 pages are displayed at once, 4 before and 4 after the current page.
     *
     * @property pages
     * @type Array
     * @private
     */
    pages: Ember.computed('maxPages', 'curPage', function () {
      var count = this.getAttr('maxPages');
      var curPage = Ember.get(this, 'curPage');
      var pages = [];
      var start = 1;
      var end = count;

      if (count > 10) {
        start = curPage - 4;

        if (start < 1) {
          start = 1;
        }
      }

      if (end > start + 8) {
        end = start + 8;
      }

      for (var i = start; i <= end; i++) {
        pages.push(i);
      }

      return pages;
    }),

    /**
     * For alternating page button layout.
     *
     * @property isOdd
     * @type Boolean
     * @private
     */
    isOdd: Ember.computed('curPage', 'pages.length', 'showGotoLast', function () {
      var count = Ember.get(this, 'pages.length');
      var curPage = Ember.get(this, 'curPage');
      var maxPages = this.getAttr('maxPages');

      if (curPage > 1) {
        count++;
      }

      if (curPage !== maxPages) {
        count++;
      }

      if (Ember.get(this, 'showGotoLast')) {
        count++;
      }

      return count % 2 > 0;
    }),

    /**
     * For alternating page button layout.
     *
     * @property isOdd
     * @type Boolean
     * @private
     */
    pageSizeOptions: Ember.computed('pageSize', function () {
      var options = [{
        value: 10,
        label: 10
      }, {
        value: 20,
        label: 20
      }, {
        value: 40,
        label: 40
      }, {
        value: 80,
        label: 80
      }];
      var pageSize = +Ember.get(this, 'pageSize');

      if (!options.isAny('value', pageSize)) {
        options.pushObject({
          value: pageSize,
          label: pageSize
        });
      }

      return options.sortBy('value');
    }),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Initialise curPage with the page attribute.
     *
     * @public
     * @method didReceiveAttrs
     * @return {Void}
     */
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var page = Ember.get(this, 'page');
      Ember.set(this, 'curPage', page);
    }
  });

  _exports.default = _default;
});