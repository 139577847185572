define("@vollersgroup/hub-frontend/components/navigation-steps/step/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/ply64M9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"navigation-step\"],[11,\"tabindex\",\"1\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"step\",\"route\"]]],null,{\"statements\":[[0,\"    \"],[4,\"link-to\",[[23,[\"step\",\"route\"]]],[[\"disabledWhen\"],[[23,[\"step\",\"disabled\"]]]],{\"statements\":[[1,[23,[\"step\",\"label\"]],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[27,\"if\",[[23,[\"step\",\"disabled\"]],\"disabled\",[27,\"if\",[[23,[\"step\",\"active\"]],\"active\"],null]],null]],[9],[0,\"\\n      \"],[1,[23,[\"step\",\"label\"]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[3,\"action\",[[22,0,[]],[27,\"action\",[[22,0,[]],\"onClick\",[23,[\"step\"]]],null]]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/navigation-steps/step/template.hbs"
    }
  });

  _exports.default = _default;
});