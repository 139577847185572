define("@vollersgroup/hub-frontend/services/auction-manager", ["exports", "@vollersgroup/hub-frontend/config/environment", "marked"], function (_exports, _environment, _marked) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    l10n: Ember.inject.service(),
    store: Ember.inject.service(),
    toast: Ember.inject.service(),
    websocket: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Instance of connected websocket client.
     *
     * @private
     * @property _websocketClient
     * @type {Object}
     */
    _websocketClient: null,
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Initializes auction depending on
     * current auction status if not in
     * test environment.
     *
     * @public
     * @method initAuction
     * @return {Void}
     */
    initAuction: function initAuction(auction) {
      if (auction.get('isDestroyed') || this._isTestEnvironment()) {
        return;
      }

      switch (auction.get('status')) {
        case 'ACTIVE':
          this._startRunning(auction);

          break;

        case 'RUNNING':
        case 'ON_HOLD':
          this.loadWebsockets(auction);
          break;

        default:
      }
    },

    /**
     * Initializes websockets connection
     * and implements callback logic for
     * acutal auction bidding.
     *
     * @public
     * @method _startRunning
     * @param {Model.Auction} auction
     * @return {Void}
     */
    loadWebsockets: function loadWebsockets(auction) {
      var _this2 = this;

      if (auction.get('isDestroyed') || this._isTestEnvironment()) {
        return;
      } //
      // VARIABLES
      //


      var closeTimer;

      var _this = this; //
      // CLOSURES
      //
      // updates auction `totalPrice` property


      var _updateListingPrice = function _updateListingPrice(listing) {
        // we need this update to trigger recalculation
        // of auction's total price for both auctioneer
        // and participants in auction's `totalPrice`!
        listing.notifyPropertyChange('currentPrice');
      }; // polls server to update closing timer


      var _setCloseTimer = function _setCloseTimer(remainingClockTime) {
        if (!remainingClockTime) {
          return;
        }

        if (closeTimer) {
          Ember.run.cancel(closeTimer);
        }

        closeTimer = Ember.run.later(_this, _this._tryReloadClosed, auction, remainingClockTime);
      }; // updates listing's auction information and handles reloading of highest bid


      var _updateListing = function _updateListing(listing, highestBidJSON, bidCount) {
        if (highestBidJSON) {
          // cache old highest bid for outbid check
          var oldHighestBid = listing.get('highestBid'); // push highest bid information from WS manually to store,
          // which DOES NOT contain a company id >>> ONLY for owner!

          _this.get('store').pushPayload('bid', {
            bid: highestBidJSON
          });

          var newHighestBid = _this.get('store').peekRecord('bid', highestBidJSON.id); // set new highest bid information on listing


          listing.set('highestBid', newHighestBid); // trigger price change based on highest bid

          _updateListingPrice(listing); // reload if owner (company) or if own bid (multi devices)


          if (auction.get('isOwner') || newHighestBid.get('isOwn')) {
            newHighestBid.reload().then(function () {
              // trigger recalculation of total prices AFTER
              // `highestBid` has been loaded cause we need
              // to gather company before recalculating total!
              _updateListingPrice(listing);
            });
          } // show toast message if user was outbid with new highest bid


          if (oldHighestBid.get('isOwn') && !newHighestBid.get('isOwn')) {
            _this.get('toast').warning(_this.get('l10n').t('You have been outbid on <strong>{{listingName}}</strong>!', {
              listingName: listing.get('name')
            }));
          }
        }

        listing.set('bidCount', bidCount);
      }; // loads all bids from listing sent in message


      var _loadBidsFromListing = function _loadBidsFromListing(message) {
        // extract message information
        var listingId = message.listing;
        var bidCount = message.bidCount;
        var highestBid = message.highestBid; // try to fetch listing from store without a request

        var listing = _this.get('store').peekRecord('listing', listingId);

        if (listing) {
          _updateListing(listing, highestBid, bidCount);

          return;
        } // otherwise fetch listing once before setting new highest bid


        _this.get('store').findRecord('listing', listingId).then(function (listing) {
          _updateListing(listing, highestBid, bidCount);
        });
      }; // verifies that an auction participant is already in store, otherwise
      // triggers an update for auction `id` property, which is dependent key
      // for `auctionRegistrations` property, which needs a recalculation!


      var _doRegistrationsLookup = function _doRegistrationsLookup(message) {
        if (message.highestBid) {
          // noop if auction participant's already in store
          var participantId = message.highestBid.auctionParticipant;

          if (_this.get('store').peekRecord('auction-participant', participantId)) {
            return;
          }
        } // force refetch of `auctionRegistrations`


        auction.notifyPropertyChange('id');
      }; // callback for async websocket message


      var _runLoopCallback = function _runLoopCallback(message) {
        // parse response body into json object
        // and normalize response type to array
        message = JSON.parse(message.body);

        if (Ember.typeOf(message) !== 'array') {
          message = [message];
        } // special further operations according
        // auction's to message notification type


        switch (message[0].type) {
          case 'TIMER_FORCE_STARTED':
            auction.setProperties({
              status: message[0].auctionStatus,
              remainingClockTime: message[0].remainingClockTime
            });

            _setCloseTimer(message[0].remainingClockTime);

            break;

          case 'AUCTION_STATUS_CHANGE':
            // = pause/resume
            // important: we need to refetch model instead
            // of setting status without save(), otherwise
            // model will get dirty and the reload routine
            // would fail updating correct model state!
            auction.reload().then(function (auction) {
              auction.hasMany('listings').reload().then(function () {
                // invalidate auction participants properties
                // based on bids (and listings) in case there
                // were bids deleted during the paused state!
                auction.notifyPropertyChange('allAuctionBids');
              });
            });
            break;

          case 'AUCTIONEER_MESSAGE':
            // = bidder notification
            // eslint-disable-next-line no-case-declarations
            var auctionCompanyName = Ember.get(auction, 'company.name'); // eslint-disable-next-line no-case-declarations

            var title = Ember.get(_this2, 'l10n').t('Message from {{auctionCompanyName}}', {
              auctionCompanyName: auctionCompanyName
            }); // eslint-disable-next-line no-case-declarations

            var text = (0, _marked.default)(message[0].message);

            _this2.get('toast').info(text, title, {
              timeOut: '0',
              progressBar: false,
              extendedTimeOut: '0'
            });

            break;

          case 'BID_UPDATE':
            // = bids changes
            message.forEach(function (message) {
              _loadBidsFromListing(message);

              _doRegistrationsLookup(message);
            }); // update close timer from websocket message
            // eslint-disable-next-line no-case-declarations

            var remainingClockTime = message[0].remainingClockTime;
            auction.set('remainingClockTime', remainingClockTime);

            _setCloseTimer(remainingClockTime);

            break;

          default:
        }
      }; // wraps websocket callback into a run loop for ember


      var _websocketCallback = function _websocketCallback(message) {
        // join or create run loop >>> WS async message
        Ember.run(_this, _runLoopCallback, message);
      }; // connects auction with websockets and stores client


      var _websocketLoaded = function _websocketLoaded() {
        var auctionId = auction.get('id');

        var websocket = _this.get('websocket');

        var client = websocket.connectAuction(auctionId, _websocketCallback);

        _this.set('_websocketClient', client);
      }; //
      // CONNECTION/DISCONNECTION
      //


      switch (auction.get('status')) {
        // >>> CONNECT
        case 'RUNNING':
        case 'ON_HOLD':
          _setCloseTimer(auction.get('remainingClockTime'));

          this.get('websocket.isLoaded').then(_websocketLoaded);
          break;
        // >>> DISCONNECT

        default:
          // eslint-disable-next-line no-case-declarations
          var client = this.get('_websocketClient');

          if (client) {
            this.get('websocket').disconnect(client);
          }

      }
    },

    /**
     * Reloads all highestBids for all listings.
     * Primarily used when auction is closed.
     *
     * @private
     * @method reloadBids
     * @param {Model.Auction} auction
     * @return {Void}
     */
    _reloadBids: function _reloadBids(auction) {
      var _hightestBidLoaded = function _hightestBidLoaded(highestBid) {
        if (!highestBid) {
          return;
        }

        highestBid.reload();
      };

      var _listingsLoaded = function _listingsLoaded(listings) {
        listings.reload();
        listings.forEach(function (listing) {
          listing.get('highestBid').then(_hightestBidLoaded);
        });
      };

      auction.get('listings').then(_listingsLoaded);
    },

    /**
     * Polls server for auction to see wheter
     * it's closed or not to reload all bids.
     *
     * @private
     * @method reloadBids
     * @param {Model.Auction} auction
     * @param {Function} callback
     * @param {String} state
     * @return {Void}
     */
    _tryReloadClosed: function _tryReloadClosed(auction) {
      if (auction.get('status') !== 'RUNNING') {
        return;
      }

      this._tryReload(auction, this._reloadBids, 'CLOSED');
    },

    /**
     * Polls server for auction to see wheter
     * it's running or not to init websockets.
     *
     * @private
     * @method _tryReloadRunning
     * @param {Model.Auction} auction
     * @return {Void}
     */
    _tryReloadRunning: function _tryReloadRunning(auction) {
      if (auction.get('status') !== 'ACTIVE') {
        return;
      }

      this._tryReload(auction, this.loadWebsockets, 'RUNNING');
    },

    /**
     * Polls server for auction to fetch state
     * and invokes provided callback function
     * as soon as state matches given one.
     *
     * PRIMITIVE METHOD
     *
     * @private
     * @method reloadBids
     * @param {Model.Auction} auction
     * @param {Function} callback
     * @param {String} state
     * @return {Void}
     */
    _tryReload: function _tryReload(auction, callback, state) {
      var _this3 = this;

      var _hasState = function _hasState(auction) {
        return auction.get('status') === state;
      };

      var _pollAgain = function _pollAgain(auction) {
        Ember.run.debounce(_this3, _this3._tryReload, auction, callback, state, _this3._isTestEnvironment() ? 1 : 2500);
      };

      var _hasLoaded = function _hasLoaded(auction) {
        if (!_hasState(auction)) {
          _pollAgain.call(_this3, auction);

          return;
        }

        callback.call(_this3, auction);
      };

      var _hasError = function _hasError(error) {
        var errorMessage = Ember.get(error, 'payload.error');
        console.error(errorMessage);

        _pollAgain(auction);
      };

      if (_hasState(auction)) {
        return;
      }

      auction.reload().then(_hasLoaded, _hasError);
    },

    /**
     * Starts auction poller to see
     * if auction has started to init
     * websockets as soon as running.
     *
     * @private
     * @method _startRunning
     * @param {Model.Auction} auction
     * @return {Void}
     */
    _startRunning: function _startRunning(auction) {
      var remainingTimeToStart = auction.get('remainingTimeToStart');

      if (!remainingTimeToStart) {
        return;
      }

      var DAY_IN_MS = 24 * 60 * 60 * 1000;

      if (remainingTimeToStart >= DAY_IN_MS) {
        return;
      }

      Ember.run.later(this, this._tryReloadRunning, auction, remainingTimeToStart);
    },

    /**
     * Check whether we're running in a test environment
     * so we can bail out early and not hit functionality problems
     * in tests.
     *
     * This *also* allows us to have a single point to override
     * when we *do* want to test individual methods
     *
     * @private
     * @method _isTestEnvironment
     * @return boolean
     */
    _isTestEnvironment: function _isTestEnvironment() {
      return _environment.default.environment === 'test';
    }
  });

  _exports.default = _default;
});