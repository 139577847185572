define("@vollersgroup/hub-frontend/admin/orders/show/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    l10n: Ember.inject.service(),
    ajax: Ember.inject.service(),
    toast: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    showSellerAdjustmentText: Ember.computed('model.isClosed', 'model.isRejected', 'model.offerHasChanged', function () {
      var order = Ember.get(this, 'model');

      if (order.get('isClosed')) {
        return false;
      }

      if (order.get('isRejected')) {
        return false;
      }

      if (order.get('offerHasChanged')) {
        // Once the offer was changed, there is an info-message saying that offer was changed.
        // No need to show this message anymore.
        return false;
      }

      return true;
    }),
    actions: {
      payAndAccept: function payAndAccept(closeModal) {
        var _this = this;

        var _this$getProperties = this.getProperties(['l10n', 'ajax', 'toast', 'model', 'cardToken', 'flashMessages']),
            l10n = _this$getProperties.l10n,
            ajax = _this$getProperties.ajax,
            toast = _this$getProperties.toast,
            cardToken = _this$getProperties.cardToken,
            order = _this$getProperties.model,
            flashMessages = _this$getProperties.flashMessages;

        if (Ember.isNone(cardToken)) {
          return;
        }

        flashMessages.clear();
        this.set('isBusy', true);
        var data = order.toJSON({
          includeId: true
        }); // order MUST be `ACCEPTED`
        // in order to send token!

        data.status = 'ACCEPTED';

        var successCallback = function successCallback() {
          toast.success(l10n.t('You have paid and accepted the offer!'));

          _this.transitionToRoute('admin.orders');

          closeModal();
        };

        var failureCallback = function failureCallback(error) {
          var serverErrorMessage = Ember.get(error, 'payload.error');
          var errorMessage = l10n.t('An error occurred while processing your payment: {{serverErrorMessage}}', {
            serverErrorMessage: serverErrorMessage
          });
          flashMessages.addError(errorMessage);
        };

        var finallyCallback = function finallyCallback() {
          _this.set('isBusy', false);
        };

        return ajax.post("/order/".concat(data.id, "/pay?token=").concat(cardToken), {
          data: data
        }).then(successCallback, failureCallback).finally(finallyCallback);
      },
      getTokenFromCard: function getTokenFromCard(_card) {}
    }
  });

  _exports.default = _default;
});