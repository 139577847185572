define("@vollersgroup/hub-frontend/admin/manage/auction/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iZZWtLDJ",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"page-title\",[[27,\"t\",[\"Edit auction\"],null]],null],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"wrapper\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"layout__sidebar--left\"],[9],[0,\"\\n\"],[4,\"sticky-block\",null,null,{\"statements\":[[0,\"      \"],[1,[27,\"manage-steps\",null,[[\"currentStep\",\"steps\",\"action\"],[[23,[\"step\"]],[23,[\"allSteps\"]],\"gotoStep\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"layout__sidebar--main padding-bottom-4\"],[9],[0,\"\\n\\n    \"],[7,\"h2\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"step\"]],2],null]],null,{\"statements\":[[0,\"        \"],[1,[27,\"t\",[\"Your auction has been saved\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[27,\"t\",[\"Edit auction\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"hr\"],[9],[10],[0,\"\\n\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"step\"]],0],null]],null,{\"statements\":[[0,\"      \"],[1,[27,\"manage-auction\",null,[[\"auction\",\"action\",\"isEdit\",\"actionExit\"],[[23,[\"model\"]],\"editAuction\",true,\"exitProcess\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"step\"]],1],null]],null,{\"statements\":[[0,\"      \"],[1,[27,\"manage-added-listings\",null,[[\"auction\",\"action\",\"isEdit\",\"actionExit\"],[[23,[\"model\"]],\"addListings\",true,\"addListingsExit\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"step\"]],2],null]],null,{\"statements\":[[0,\"      \"],[1,[27,\"manage-auction-publish\",null,[[\"auction\",\"action\",\"isEdit\"],[[23,[\"model\"]],\"publish\",true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/admin/manage/auction/edit/template.hbs"
    }
  });

  _exports.default = _default;
});