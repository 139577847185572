define("@vollersgroup/hub-frontend/components/listing-copy/component", ["exports", "@vollersgroup/hub-frontend/utils/get-first-adapter-error-message"], function (_exports, _getFirstAdapterErrorMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Component to clone an existing listing.
   *
   * ```html
   * {{listing-copy
   *    listing=listing
   *    onCancel=(action "onCancel")
   *    onSuccess=(action "onSuccess")
   *    onFailure=(action "onFailure")}}
   * ```
   *
   * @namespace Component
   * @class ListingCopy
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    toast: Ember.inject.service(),
    store: Ember.inject.service(),
    userSession: Ember.inject.service(),
    availableData: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * Listing as template for new clone.
     *
     * @attribute listing
     * @type {Model.Listing}
     * @public
     */
    listing: null,
    // -------------------------------------------------------------------------
    // Properties

    /**
     * If component is currently busy.
     *
     * @property isBusy
     * @type {Boolean}
     * @public
     */
    isBusy: false,

    /**
     * Cloned listing from `listing` attribute.
     *
     * @property copyListing
     * @type {Model.Listing}
     * @public
     */
    copyListing: null,
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Creates `copyListing` from `listing`.
     *
     * @method didReceiveAttrs
     * @return {Void}
     * @public
     */
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      var listing = Ember.get(this, 'listing');
      var l10n = Ember.get(this, 'l10n');

      if (Ember.isNone(listing)) {
        return;
      }

      var name = Ember.get(listing, 'name');
      var copyTask = listing.copy(true);

      var successCallback = function successCallback(copyListing) {
        Ember.set(_this, 'copyListing', copyListing);
      };

      var toast = Ember.get(this, 'toast');

      var failureCallback = function failureCallback() {
        toast.error(l10n.t('Could not create a copy of listing {{name}}!', {
          name: name
        }));
        copyTask.cancel();

        _this.send('cancel');
      };

      copyTask.then(successCallback, failureCallback);
    },

    /**
     * Removes `copyListing` from store if unsaved.
     *
     * @method willDestroy
     * @return {Void}
     * @public
     */
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      var copyListing = Ember.get(this, 'copyListing');

      if (!Ember.get(copyListing, 'isNew')) {
        return;
      }

      copyListing.rollbackAttributes();
    },
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Saves `copyListing` in database and
       * tries to invoke the `onSave` action.
       *
       * @event save
       * @private
       */
      save: function save() {
        var _this2 = this;

        var l10n = Ember.get(this, 'l10n');
        var toast = Ember.get(this, 'toast');
        var copyListing = Ember.get(this, 'copyListing');

        var successCallback = function successCallback(savedListing) {
          toast.success(l10n.t('Your listing has been copied successfully!'));
          var onSuccess = Ember.get(_this2, 'attrs.onSuccess');

          if (Ember.typeOf(onSuccess) !== 'function') {
            return;
          }

          return onSuccess(savedListing);
        };

        var failureCallback = function failureCallback(adapterError) {
          var serverErrorMessage = (0, _getFirstAdapterErrorMessage.default)(adapterError);
          var errorMessage = Ember.get(_this2, 'l10n').t('An error occurred while copying your listing: {{serverErrorMessage}}', {
            serverErrorMessage: serverErrorMessage
          });
          toast.error(errorMessage);
          var onFailure = Ember.get(_this2, 'attrs.onFailure');

          if (Ember.typeOf(onFailure) !== 'function') {
            return;
          }

          return onFailure(adapterError);
        };

        var finallyCallback = function finallyCallback() {
          Ember.set(_this2, 'isBusy', false);
        };

        Ember.set(this, 'isBusy', true);
        return copyListing.save().then(successCallback).catch(failureCallback).finally(finallyCallback);
      },

      /**
       * Tries to invoke the `onCancel` action.
       *
       * @event update
       * @private
       */
      cancel: function cancel() {
        var onCancel = Ember.get(this, 'attrs.onCancel');

        if (Ember.typeOf(onCancel) !== 'function') {
          return;
        }

        return onCancel();
      },

      /**
       * Sets implicity listing values on changing `shop`.
       *
       * @event changeShop
       * @param {Model.Shop} shop
       * @private
       */
      changeShop: function changeShop(shop) {
        var json = Ember.isNone(shop) ? {} : shop.toJSON();
        var copyListing = Ember.get(this, 'copyListing');
        Ember.setProperties(copyListing, {
          samplePriceCurrency: json.currency || null,
          priceCurrency: json.currency || null,
          shopName: json.name || null,
          shopId: json.id || null
        });
      },

      /**
       * Sets implicity listing values on changing `warehouse`.
       *
       * @event changeWarehouse
       * @param {Model.Location} warehouse
       * @private
       */
      changeWarehouse: function changeWarehouse(warehouse) {
        var json = Ember.isNone(warehouse) ? {} : warehouse.toJSON();
        var copyListing = Ember.get(this, 'copyListing');
        Ember.setProperties(copyListing, {
          warehouseName: json.name || null
        });
      }
    }
  });

  _exports.default = _default;
});