define("@vollersgroup/hub-frontend/components/listing-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Display a list of all listings available in the Search service
   *
   * ```html
   * {{listing-list shop=currentShop}}
   * ```
   *
   * @namespace Component
   * @class ListingList
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    l10n: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * @property tagName
     * @type String
     * @final
     * @private
     */
    tagName: '',
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * Current instance of search.
     *
     * @attribute search
     * @type {Object}
     */
    search: null,
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Resets search to initial state.
       *
       * @event reset
       * @private
       */
      reset: function reset() {
        this.get('search').reset();
      },

      /**
       * Restores search to last state,
       * which contained search results.
       *
       * @event reset
       * @private
       */
      restore: function restore() {
        this.get('search').restore();
      }
    },
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Chooseable options for ordering.
     *
     * @property orderBy
     * @type {Array}
     */
    orderByOptions: Ember.computed('l10n', 'search.searchText', function () {
      var options = [{
        disabled: true,
        value: 'DEFAULT',
        label: this.get('l10n').t('Select...')
      }, {
        value: 'NAME',
        label: this.get('l10n').t('Lot name')
      }, {
        value: 'DATE',
        label: this.get('l10n').t('Most recently added')
      }, {
        value: 'SCORE',
        label: this.get('l10n').t('Cupping score (high-low)')
      }, {
        value: 'AMOUNT',
        label: this.get('l10n').t('Available amount (high-low)')
      }];

      if (this.get('search.searchText')) {
        options.pushObject({
          value: 'RELEVANCE',
          label: this.get('l10n').t('Relevance (high-low)')
        });
      }

      return options;
    })
  });

  _exports.default = _default;
});