define("@vollersgroup/hub-frontend/components/simple-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/9LJ93MH",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"bg-color-grey-6 padding-all-3 font-highlight border-bottom\"],[9],[0,\"\\n  \"],[1,[21,\"title\"],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"padding-all-3\"],[9],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/simple-box/template.hbs"
    }
  });

  _exports.default = _default;
});