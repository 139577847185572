define("@vollersgroup/hub-frontend/admin/manage/overview/warehouses/add/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h2OU5rB5",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"page-title\",[[27,\"t\",[\"Add Warehouse\"],null]],null],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"wrapper padding-bottom-3\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[22,0,[\"legacyWarehouseMigration\",\"selectedLegacyWarehouse\"]]],null,{\"statements\":[[0,\"    \"],[7,\"h1\"],[11,\"class\",\"font-size-3\"],[9],[1,[27,\"t\",[\"Choose Warehouse\"],null],false],[10],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"text-gray-500\"],[9],[0,\"\\n      \"],[5,\"get-text\",[],[[\"@message\"],[[27,\"t\",[\"Select or create a new warehouse below to move your {{WAREHOUSE_NAME}} warehouse to the new system\"],null]]],{\"statements\":[[0,\"\\n        \"],[7,\"b\"],[9],[1,[22,0,[\"legacyWarehouseMigration\",\"selectedLegacyWarehouse\",\"name\"]],false],[10],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"h1\"],[11,\"class\",\"font-size-3\"],[9],[1,[27,\"t\",[\"Add Warehouse\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"wrapper padding-bottom-4\"],[9],[0,\"\\n  \"],[1,[21,\"warehouses/add-warehouse\"],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/admin/manage/overview/warehouses/add/template.hbs"
    }
  });

  _exports.default = _default;
});