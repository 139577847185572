define("@vollersgroup/hub-frontend/components/filter-display/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Displays active filters and let user
   * remove an active filter by clicking.
   *
   * @namespace Component
   * @class FilterDisplay
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    availableData: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Current instance of search.
     *
     * @attribute search
     * @type {Object}
     */
    search: null,

    /**
     * Active filter hash.
     *
     * @property filters
     * @type {Object}
     */
    filters: Ember.computed.alias('search.active'),
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Removes an active item from search.
       *
       * @event remove
       * @param {Object} filter Filter group to update.
       * @param {Object} item Filter item to update.
       * @private
       */
      remove: function remove(filter, item) {
        var _this = this;

        var remove = function remove(item) {
          _this.get('search').remove(filter, item);
        };

        switch (filter.parameterType) {
          case 'ARRAY':
            // remove all sub items if root
            // item is removed (nested only)
            if (Ember.isArray(item.items)) {
              item.items.forEach(remove);
            }

            remove(item);
            break;

          case 'RANGE':
            // range items have to be removed
            // all at once as they can be seen
            // as a comprehensive virtual item
            for (var name in filter.items) {
              remove(filter.items[name]);
            }

            break;

          default:
            remove(item);
        }
      }
    }
  });

  _exports.default = _default;
});