define("@vollersgroup/hub-frontend/about/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iRFNtFx/",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"page-title\",[[27,\"t\",[\"V-Hub - About\"],null]],null],false],[0,\"\\n\\n\"],[7,\"header\"],[11,\"class\",\"\"],[9],[0,\"\\n  \"],[1,[21,\"header-bar\"],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"dark__bg section margin-all-0\"],[9],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"wrapper\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"layout__full\"],[9],[0,\"\\n      \"],[7,\"ul\"],[11,\"class\",\"secondary-nav\"],[9],[0,\"\\n        \"],[7,\"li\"],[9],[4,\"link-to\",[\"about.index\"],[[\"class\"],[\"secondary-nav__item\"]],{\"statements\":[[1,[27,\"t\",[\"Contact\"],null],false]],\"parameters\":[]},null],[10],[0,\"\\n        \"],[7,\"li\"],[9],[4,\"link-to\",[\"about.legal\"],[[\"class\"],[\"secondary-nav__item\"]],{\"statements\":[[1,[27,\"t\",[\"Legal\"],null],false]],\"parameters\":[]},null],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"section\"],[11,\"class\",\"flex-grow-1\"],[9],[0,\"\\n  \"],[1,[21,\"outlet\"],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[21,\"footer-main\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/about/template.hbs"
    }
  });

  _exports.default = _default;
});