define("@vollersgroup/hub-frontend/helpers/to-string", ["exports", "@vollersgroup/ember-cropster-common/helpers/to-string"], function (_exports, _toString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _toString.default;
    }
  });
  Object.defineProperty(_exports, "toString", {
    enumerable: true,
    get: function get() {
      return _toString.toString;
    }
  });
});