define("@vollersgroup/hub-frontend/components/message-multiple/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A component enabling message sending to multiple recipients at once.
   * Messages are processed as a queue including conversation creation,
   * loading state and error handling. The component expects a collection
   * of recipients (users or companies) to which the message should be
   * sent to. You can specify the recipient type with `mode` attribute.
   *
   * ```html
   * {{message-multiple
   *    recipients=[]
   *    mode="[USER]|COMPANY"
   *    showSubject=[true]|false
   *    subjectText="[]"
   *    buttonText="[Send Message]""
   *    }}
   * ```
   *
   * @namespace Component
   * @class MessageMultiple
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    tagName: '',
    // -------------------------------------------------------------------------
    // Dependencies
    store: Ember.inject.service(),
    availableData: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * Collection of recipients to which the message should be sent.
     *
     * @attribute recipients
     * @type {Array}
     */
    recipients: null,

    /**
     * The type of the recipient, which can be one of
     * "USER" or "COMPANY".
     *
     * @attribute mode
     * @type {String}
     * @default "USER"
     * @optional
     */
    mode: null,

    /**
     * The type of the conversation. Can be one of "GENERAL",
     * "ORDER", "AUCTION", "SOCIAL" or "PRIVATE_OFFERING".
     *
     * @attribute category
     * @type {String}
     * @default "GENERAL"
     * @optional
     */
    category: null,

    /**
     * If true, a subject line is also shown for sender.
     * By setting to false, a predefined subject text
     * must be set via `subjectText` attribute.
     *
     * @attribute showSubject
     * @type {Boolean}
     * @default true
     * @optional
     */
    showSubject: null,

    /**
     * Text of send button to be shown.
     *
     * @attribute buttonText
     * @type {String}
     * @default ""
     * @optional
     */
    subjectText: null,

    /**
     * Text of send button to be shown.
     *
     * @attribute buttonText
     * @type {String}
     * @default "Send message"
     */
    buttonText: null,
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Internal flag to state if messages are being sent.
     *
     * @property isSending
     * @type {Boolean}
     * @private
     */
    isSending: false,

    /**
     * Internal flag to state if form can be submitted.
     *
     * @property isDisabled
     * @type {Boolean}
     * @private
     */
    isDisabled: Ember.computed('recipients.[]', 'messageSubject', 'messageText', 'showSubject', 'subjectText', 'isSending', function () {
      var recipientsCount = this.get('recipients.length');

      if (this.get('isSending') || recipientsCount === 0) {
        return true;
      }

      var hasSubject = !Ember.isBlank(this.get('messageSubject'));
      var hasText = !Ember.isBlank(this.get('messageText'));

      if (this.get('showSubject') === false) {
        return !hasText;
      }

      return !(hasSubject && hasText);
    }),

    /**
     * Messages's subject text.
     *
     * @property messageSubject
     * @type {String}
     * @private
     */
    messageSubject: '',

    /**
     * Message's body text.
     *
     * @property messageText
     * @type {String}
     * @private
     */
    messageText: '',

    /**
     * Internal cache storing failed attempts
     * from a message task queue for a retry.
     *
     * @property failed
     * @type {Array}
     * @private
     */
    failed: Ember.computed(function () {
      return []; // complex type
    }),

    /**
     * Internal flag set to true if there were
     * failed attempts during a message queue.
     *
     * @property canRetry
     * @type {Boolean}
     * @private
     */
    canRetry: false,
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Checks if given component configuration is valid.
     *
     * @public
     * @method didReceiveAttrs
     * @return {Void}
     */
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments); // check required attribute `recipients`


      if (!Ember.isArray(this._initAttr('recipients'))) {
        console.error("message-multiple.js: You have to pass an array with recipients (users/companies)!");
      } // check if `mode` attribute is valid


      switch (this._initAttr('mode', 'USER')) {
        case 'USER':
          break;

        case 'COMPANY':
          break;

        default:
          console.error("message-multiple.js: Unsupported mode ".concat(this.get('mode'), ", use one of \"USER\" or \"COMPANY\" - using \"USER\" as fallback!"));
          this.set('mode', 'USER');
      } // check if `type` attribute is valid


      var validCategory = this.get('availableData.conversationTypes').findBy('id', this._initAttr('category', 'GENERAL'));

      if (validCategory === false) {
        console.error("message-multiple.js: Unsupported category ".concat(this.get('category'), ", use one of \"GENERAL\", \"ORDER\", \"AUCTION\", \"SOCIAL\" or \"PRIVATE OFFERING\" - using \"GENERAL\" as fallback!"));
        this.set('type', 'GENERAL');
      } // check if `showSubject` and `subjectText` are valid


      var subjectText = this._initAttr('subjectText', '');

      var showSubject = this._initAttr('showSubject', true);

      if (showSubject === false && Ember.isBlank(subjectText)) {
        console.error("message-multiple.js: You must provide \"subjectText\" when setting \"showSubject\" to false - using to default settings!");
        this.set('showSubject', true);
      } // set `buttonText` with default value, no check needed


      this._initAttr('buttonText', this.get('l10n').t('Send message'));
    },

    /**
     * Checks if a property is in `attrs` using its
     * value or falls back to a given default value.
     *
     * @private
     * @method _initProperty
     * @param {String} key
     * @param {Mixed} [defaultValue=null]
     * @return {Mixed}
     */
    _initAttr: function _initAttr(key) {
      var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var prop = this.get(key);
      var attr = this.getAttr(key);

      if (Ember.isNone(attr)) {
        this.set(key, prop ? prop : defaultValue);
      } else {
        this.set(key, attr);
      }

      return this.get(key);
    },

    /**
     * Recursor sending messages in a task queue.
     * Calls itself as long as their are remaining
     * recipients in the queue, otherwise resets
     * `isSending` to false.
     *
     * @private
     * @method _sendQueue
     * @param {Array} recipients
     * @return {Void}
     */
    _sendQueue: function _sendQueue(recipients) {
      var _this = this;

      // variables
      var store = this.get('store');
      var failed = this.get('failed'); // parameters

      var recipient = recipients.pop();
      var text = this.get('messageText');
      var category = this.get('category');
      var subject = this.get('showSubject') ? this.get('messageSubject') : this.get('subjectText');
      var fromUser = this.get('userSession.currentUser');
      var fromCompany = this.get('userSession.currentCompany'); // conversation

      var conversation = store.createRecord('conversation', {
        lastMessageDate: (0, _moment.default)(),
        fromCompanyUnreadCount: 0,
        fromCompany: fromCompany,
        toCompanyUnreadCount: 1,
        fromUser: fromUser,
        category: category,
        subject: subject
      });

      this._addRecipient(conversation, recipient); // callbacks


      var messageSuccessCallback = function messageSuccessCallback() {
        // queue has been finished
        if (recipients.length === 0) {
          var failedCount = failed.get('length');

          var totalCount = _this.get('recipients.length');

          if (failedCount > 0) {
            _this.get('toast').error(_this.get('l10n').t("{{failedCount}} of {{totalCount}} messages couldn't be sent. Please try again!", {
              failedCount: failedCount,
              totalCount: totalCount
            }));

            _this.set('canRetry', true);

            return;
          }

          _this.get('toast').success(_this.get('l10n').n('Your message has been sent successfully to {{count}} recipient!', 'Your message has been sent successfully to {{count}} recipients!', totalCount, {
            count: totalCount
          }));

          _this.set('messageSubject', '');

          _this.set('messageText', '');

          _this.set('failed', []);
        }
      };

      var messageFailureCallback = function messageFailureCallback() {
        // save recipient for retry
        failed.pushObject(recipient); // destroy conversation to
        // avoid database trashing

        conversation.destroyRecord();
      };

      var messageFinallyCallback = function messageFinallyCallback() {
        // queue has been finished
        if (recipients.get('length') === 0) {
          _this.set('isSending', false);

          return;
        } // invoke next message now


        _this._sendQueue(recipients);
      };

      var conversationSuccessCallback = function conversationSuccessCallback(conversation) {
        // create new message and reference conversation
        var message = store.createRecord('message', {
          conversation: conversation,
          fromCompany: fromCompany,
          fromUser: fromUser,
          created: (0, _moment.default)(),
          text: text
        }); // persist message in database

        message.save().then(messageSuccessCallback, messageFailureCallback).finally(messageFinallyCallback);
      };

      var conversationFailureCallback = function conversationFailureCallback() {
        // save recipient for retry
        failed.pushObject(recipient);
      };

      conversation.save().then(conversationSuccessCallback, conversationFailureCallback);
    },

    /**
     * Adds `toUser` and `toCompany` according
     * to `mode` attribute on conversation model.
     *
     * @private
     * @method _addRecipient
     * @param {Model.Conversation} conversation
     * @param {DS.Model} recipient
     * @return {Void}
     */
    _addRecipient: function _addRecipient(conversation, recipient) {
      switch (this.get('mode')) {
        case 'USER':
          // eslint-disable-next-line no-case-declarations
          var company = recipient.get('company');
          conversation.set('toCompany', company);
          conversation.set('toUser', recipient);
          break;

        case 'COMPANY':
          conversation.set('toCompany', recipient);
          conversation.set('toUser', null);
          break;

        default:
      }
    },
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Invokes message task queue with given recipients.
       *
       * @event send
       * @param {Array} [recpients=null]
       * @private
       */
      send: function send() {
        var recipients = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

        if (!Ember.isArray(recipients)) {
          recipients = this.get('recipients').slice();
        }

        this.set('isSending', true);

        this._sendQueue(recipients);
      },

      /**
       * Invokes failed attempts again.
       *
       * @event retry
       * @private
       */
      retry: function retry() {
        this.set('canRetry', false);
        this.send('send', this.get('failed'));
      }
    }
  });

  _exports.default = _default;
});