define("@vollersgroup/hub-frontend/register/route", ["exports", "@vollersgroup/hub-frontend/mixins/reset-scroll", "moment"], function (_exports, _resetScroll, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_resetScroll.default, {
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('route', 'admin');
      controller.set('queryParamName', '');
      controller.set('queryParamValue', '');
    },
    model: function model(params) {
      return this.store.createRecord('user', {
        timezone: _moment.default.tz.guess(),
        fullName: params.fullName,
        email: params.email
      });
    },
    deactivate: function deactivate() {
      this.modelFor(this.routeName).rollbackAttributes();
    }
  });

  _exports.default = _default;
});