define("@vollersgroup/hub-frontend/auction-participant/serializer", ["exports", "ember-data", "@vollersgroup/hub-frontend/application/serializer"], function (_exports, _emberData, _serializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;

  var _default = _serializer.default.extend(EmbeddedRecordsMixin, {});

  _exports.default = _default;
});