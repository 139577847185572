define("@vollersgroup/hub-frontend/admin/manage/bulk/bulk-producer-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Base mixin for producer route and controller.
   *
   * @namespace Mixin
   * @class BulkProducerMixin
   * @extends Ember.Mixin
   */
  var _default = Ember.Mixin.create({
    // -------------------------------------------------------------------------
    // Properties

    /**
     * @property modelName
     */
    modelName: 'producer',

    /**
     * @property overviewRoute
     */
    overviewRoute: 'admin.manage.overview.producer',

    /**
     * @property modelTransitions
     */
    modelTranslations: Ember.computed('l10n', {
      get: function get()
      /*key*/
      {
        return {
          singular: Ember.get(this, 'l10n').t('origin'),
          plural: Ember.get(this, 'l10n').t('origins')
        };
      }
    })
  });

  _exports.default = _default;
});