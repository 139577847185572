define("@vollersgroup/hub-frontend/location/model", ["exports", "ember-data", "@vollersgroup/hub-frontend/mixins/country", "ember-cp-validations", "@vollersgroup/hub-frontend/custom-objects/address-object"], function (_exports, _emberData, _country, _emberCpValidations, _addressObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TO_UPDATE = _exports.UP_TO_DATE = void 0;
  var Model = _emberData.default.Model;
  var belongsTo = _emberData.default.belongsTo;
  var attr = _emberData.default.attr;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      validators: [(0, _emberCpValidations.validator)('length', {
        allowNone: false,
        min: 3,
        max: 250,
        message: 'The address name must have at least 3 characters.'
      })]
    },
    country: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Please select a country.'
      })]
    },
    city: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Please enter a city.'
      })]
    }
  });
  /**
   * Geocoding status telling server that geocoordinates (lat/lng) are set by user.
   *
   * @type {String}
   */

  var UP_TO_DATE = 'UP_TO_DATE';
  /**
   * Geocoding status telling server that geocoordinates (lat/lng) should be detected.
   *
   * @type {String}
   */

  _exports.UP_TO_DATE = UP_TO_DATE;
  var TO_UPDATE = 'TO_UPDATE';
  /**
   * A location object.
   *
   * @namespace Model
   * @class Location
   * @extends Model
   * @uses Mixin.CountryMixin
   */

  _exports.TO_UPDATE = TO_UPDATE;

  var _default = Model.extend(_country.default, Validations, {
    userSession: Ember.inject.service(),
    availableData: Ember.inject.service(),

    /**
     * The name/label of this address.
     *
     * @attribute name
     * @type String
     */
    name: attr('string'),

    /**
     * The type of this address. Can be COMPANY, PRODUCER or WAREHOUSE.
     *
     * @attribute type
     * @type String
     */
    type: attr('string'),

    /**
     * The 2-letter country code of this location.
     *
     * @attribute country
     * @type String
     */
    country: attr('string'),

    /**
     * The State of this location.
     *
     * @attribute state
     * @type String
     * @optional
     */
    state: attr('string'),

    /**
     * The Zip code of this location.
     *
     * @attribute zip
     * @type String
     */
    zip: attr('string'),

    /**
     * The city of this location.
     *
     * @attribute city
     * @type String
     */
    city: attr('string'),

    /**
     * The street (+ house number) of this location.
     *
     * @attribute street
     * @type String
     */
    street: attr('string'),

    /**
     * The optional second address line.
     *
     * @attribute addressAddition
     * @type String
     * @optional
     */
    addressAddition: attr('string'),

    /**
     * The region for this location.
     *
     * @attribute region
     * @type String
     * @optional
     */
    region: attr('string'),

    /**
     * The subregion for this location.
     *
     * @attribute subregion
     * @type String
     * @optional
     */
    subregion: attr('string'),

    /**
     * The phone number of this location.
     *
     * @attribute phone
     * @type String
     * @optional
     */
    phone: attr('string'),

    /**
     * The email of this location.
     *
     * @attribute email
     * @type String
     * @optional
     */
    email: attr('string'),

    /**
     * The website of this location.
     *
     * @attribute website
     * @type String
     * @optional
     */
    website: attr('string'),

    /**
     * The latitude of the location.
     *
     * @attribute latitude
     * @type Number
     * @optional
     */
    latitude: attr('number'),

    /**
     * The longitude of the location.
     *
     * @attribute longitude
     * @type Number
     * @optional
     */
    longitude: attr('number'),

    /**
     * Migrated Warehouse id
     *
     * @attribute migratedWarehouse
     * @type Number
     * @optional
     */
    migratedWarehouse: attr('number'),

    /**
     * If the location should be archived.
     *
     * @attribute archived
     * @type Boolean
     * @default false
     */
    archived: attr('boolean', {
      defaultValue: false
    }),

    /**
     * Geocode status being one of `UP_TO_DATE` or `TO_UPDATE`.
     *
     * `UP_TO_DATE` = User has provided geocoordinates manually.
     * `TO_UPDATE` = Geocoordinates should be auto-detected.
     *
     * @attribute website
     * @type String
     * @optional
     */
    geocodeStatus: attr('string'),

    /**
     * The company of this address.
     *
     * @attribute company
     * @type Model.Company
     */
    company: belongsTo('company', {
      async: true
    }),

    /**
     * Active listing count belongs this location
     */
    listingActiveCount: Ember.computed('availableData.shops.@each.listingActiveCount', 'id', function () {
      var locationId = this.id;
      var shops = this.availableData.shops;
      var count = 0;

      if (shops) {
        shops.forEach(function (_ref) {
          var location = _ref.location,
              listingActiveCount = _ref.listingActiveCount;

          if (locationId === location.id) {
            count += listingActiveCount;
          }
        });
      }

      return count;
    }),

    /**
     * Provides validation state of this model. At the
     * moment all address, zip, city, country, website
     * and phone number are required.
     *
     * @property isValid
     * @type Boolean
     */
    isValid: Ember.computed('street', 'zip', 'city', 'country', 'website', 'email', 'phone', function () {
      if (Ember.isBlank(Ember.get(this, 'zip'))) {
        return false;
      }

      if (Ember.isBlank(Ember.get(this, 'city'))) {
        return false;
      }

      if (Ember.isBlank(Ember.get(this, 'street'))) {
        return false;
      }

      if (Ember.isBlank(Ember.get(this, 'country'))) {
        return false;
      }

      if (Ember.isBlank(Ember.get(this, 'website'))) {
        return false;
      }

      if (Ember.isBlank(Ember.get(this, 'phone'))) {
        return false;
      }

      if (Ember.isBlank(Ember.get(this, 'email'))) {
        return false;
      }

      return Ember.get(this, 'email').includes('@');
    }),

    /**
     * Transforms location to an address object.
     */
    toAddressObject: function toAddressObject() {
      return _addressObject.default.create({
        company: this.get('company.name'),
        fullName: this.get('userSession.currentUser.fullName'),
        zip: this.get('zip'),
        city: this.get('city'),
        state: this.get('state'),
        country: this.get('country'),
        street: this.get('street'),
        addressAddition: this.get('addressAddition'),
        phone: this.get('phone')
      });
    }
  });

  _exports.default = _default;
});