define("@vollersgroup/hub-frontend/admin/manage/bulk/producer/route", ["exports", "@vollersgroup/hub-frontend/admin/manage/bulk/bulk-producer-route"], function (_exports, _bulkProducerRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Producer route for create.
   *
   * @namespace Route
   * @class BulkProducerCreateRoute
   * @extends BulkProducerRoute
   */
  var _default = _bulkProducerRoute.default.extend({});

  _exports.default = _default;
});