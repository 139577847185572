define("@vollersgroup/hub-frontend/helpers/prettify-timezone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prettifyTimezone = prettifyTimezone;
  _exports.default = void 0;

  function prettifyTimezone(params
  /*, hash*/
  ) {
    var text = params[0];

    if (typeof text !== 'string') {
      return text;
    }

    return text.replace(/_/g, ' ');
  }

  var _default = Ember.Helper.helper(prettifyTimezone);

  _exports.default = _default;
});