define("@vollersgroup/hub-frontend/admin/messages/notifications/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'pageSize', 'company', 'subject'],
    page: 1,
    pageSize: 10,
    company: null,
    subject: null,
    maxPages: Ember.computed.reads('model.meta.maxPages'),
    actions: {
      changePageSize: function changePageSize() {
        Ember.set(this, 'page', 1);
      }
    }
  });

  _exports.default = _default;
});