define("@vollersgroup/hub-frontend/components/credit-card-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var validCardTypes = ['visa', 'visaelectron', 'amex', 'mastercard', 'dinersclub', 'jcb', 'discover'];

  var _default = Ember.Component.extend({
    flashMessages: Ember.inject.service(),
    cardClasses: 'box box--highlight',
    classNameBindings: ['cardClasses'],
    number: null,
    cardType: null,
    cvc: null,
    expirationDate: Ember.computed(function () {
      return {
        month: NaN,
        year: NaN
      };
    }),
    numberHasError: false,
    expirationDateHasError: false,
    cvcHasError: false,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var $ccNumber = this.$().find('.cc-number');
      var $ccDate = this.$().find('.cc-expiration-date');
      var $ccCVC = this.$().find('.cc-cvc');
      $ccNumber.payment('formatCardNumber');
      $ccDate.payment('formatCardExpiry');
      $ccCVC.payment('formatCardCVC');
    },
    _sendAction: function _sendAction() {
      var cardNumber = this.get('number');
      var expirationDate = this.get('expirationDate');
      var cvc = this.get('cvc');
      var cardType = this.get('cardType');

      if (Ember.$.payment.validateCardExpiry(expirationDate.month, expirationDate.year) && Ember.$.payment.validateCardNumber(cardNumber) && Ember.$.payment.validateCardCVC(cvc, cardType)) {
        this.sendAction('action', {
          type: cardType,
          number: cardNumber,
          cvc: cvc,
          expirationDate: expirationDate
        });
      } else {
        this.sendAction('action', null);
      }
    },
    actions: {
      validateNumber: function validateNumber(cardNumber) {
        var flashMessages = this.get('flashMessages');

        if (!Ember.$.payment.validateCardNumber(cardNumber)) {
          this.set('numberHasError', true);
          flashMessages.clear();
          flashMessages.addError(this.get('l10n').t('Your credit card number seems to be invalid.'));
        } else {
          // only check whitelisted card types if number is valid
          var cardType = Ember.$.payment.cardType(cardNumber);

          if (!cardType || validCardTypes.indexOf(cardType) === -1) {
            this.set('cardType', null);
            this.set('number', null);
            cardNumber = null;
            flashMessages.clear();
            flashMessages.addError(this.get('l10n').t('Sorry, but this type of credit card is currently not supported.'));
          }
        }

        Ember.run.debounce(this, this._sendAction, 100);
      },
      validateExpirationDate: function validateExpirationDate(val) {
        var flashMessages = this.get('flashMessages');
        var expirationDate = Ember.$.payment.cardExpiryVal(val);

        if (!Ember.$.payment.validateCardExpiry(expirationDate.month, expirationDate.year)) {
          this.set('expirationDateHasError', true);
          flashMessages.clear();
          flashMessages.addError(this.get('l10n').t('Your credit card expiration date seems to be invalid.'));
        }

        Ember.run.debounce(this, this._sendAction, 100);
      },
      validateCVC: function validateCVC(cvc) {
        var flashMessages = this.get('flashMessages');

        if (!Ember.$.payment.validateCardCVC(cvc, this.get('cardType'))) {
          this.set('cvcHasError', true);
          flashMessages.clear();
          flashMessages.addError(this.get('l10n').t('Your credit card CVC number seems to be invalid.'));
        }

        Ember.run.debounce(this, this._sendAction, 100);
      },
      updateNumber: function updateNumber(cardNumber) {
        this.set('numberHasError', false);
        var cardType = Ember.$.payment.cardType(cardNumber);
        this.set('cardType', cardType);

        if (Ember.$.payment.validateCardNumber(cardNumber)) {
          this.set('number', cardNumber.replace(/\s+/g, ''));
          var $ccDate = this.$().find('.cc-expiration-date');
          $ccDate.focus();
        } else {
          this.set('number', null);
        }
      },
      updateExpirationDate: function updateExpirationDate(val) {
        this.set('expirationDateHasError', false);
        var expirationDate = Ember.$.payment.cardExpiryVal(val);

        if (Ember.$.payment.validateCardExpiry(expirationDate.month, expirationDate.year)) {
          this.set('expirationDate', expirationDate);
          var $ccCVC = this.$().find('.cc-cvc');
          $ccCVC.focus();
        } else {
          this.set('expirationDate', {
            month: NaN,
            year: NaN
          });
        }
      },
      updateCVC: function updateCVC(cvc) {
        this.set('cvcHasError', false);

        if (Ember.$.payment.validateCardCVC(cvc, this.get('cardType'))) {
          this.set('cvc', cvc.trim());

          if (this.get('cardType') !== 'amex' || cvc.trim().length === 4) {
            var $ccCVC = this.$().find('.cc-cvc');
            $ccCVC.blur();
          }
        } else {
          this.set('cvc', null);
        }
      }
    }
  });

  _exports.default = _default;
});