define("@vollersgroup/hub-frontend/admin/account/ratings/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['id'],
    id: null,
    ratings: Ember.computed('id', 'model', function () {
      var ratings = Ember.get(this, 'model');
      var id = Ember.get(this, 'id');

      if (id) {
        return ratings.filterBy('id', id);
      }

      return ratings;
    })
  });

  _exports.default = _default;
});