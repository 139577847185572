define("@vollersgroup/hub-frontend/components/c-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XPpecuH+",
    "block": "{\"symbols\":[\"@class\",\"@id\",\"@disabled\",\"@type\",\"&default\",\"@loadingText\",\"@isLoading\"],\"statements\":[[4,\"if\",[[22,7,[]]],null,{\"statements\":[[0,\"  \"],[7,\"button\"],[12,\"class\",[28,[[22,1,[]],\" relative button--loading\"]]],[11,\"disabled\",\"disabled\"],[12,\"type\",[27,\"if\",[[22,4,[]],[22,4,[]],[22,0,[\"type\"]]],null]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"loading-circle loading-circle-opacity loading-circle--inline loading-circle--small\"],[9],[10],[0,\"\\n\"],[4,\"if\",[[27,\"and\",[[27,\"not\",[[22,6,[]]],null],[27,\"not\",[[27,\"eq\",[[22,6,[]],\"\"],null]],null]],null]],null,{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"Loading...\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[22,6,[]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"button\"],[12,\"class\",[22,1,[]]],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"click\"],[[\"preventDefault\",\"bubbles\"],[false,true]]]],[12,\"id\",[22,2,[]]],[12,\"disabled\",[22,3,[]]],[12,\"type\",[27,\"if\",[[22,4,[]],[22,4,[]],[22,0,[\"type\"]]],null]],[9],[0,\"\\n    \"],[14,5],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/c-button/template.hbs"
    }
  });

  _exports.default = _default;
});