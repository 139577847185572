define("@vollersgroup/hub-frontend/admin/manage/overview/auction/index/controller", ["exports", "@vollersgroup/hub-frontend/mixins/orderable"], function (_exports, _orderable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_orderable.default, {
    // -------------------------------------------------------------------------
    // Dependencies
    availableData: Ember.inject.service(),
    l10n: Ember.inject.service(),
    queryParams: [{
      selectedShopId: {
        as: 'shop'
      },
      filterStatus: {
        as: 'status'
      },
      orderBy: {
        as: 'orderBy'
      },
      showArchived: {
        as: 'archived'
      }
    }],
    // -------------------------------------------------------------------------
    // Properties
    selectedShopId: '',

    /**
     * If only archived auctions should be displayed.
     *
     * @property showArchived
     * @type Boolean
     * @default false
     */
    showArchived: false,

    /**
     * The statuses that should be displayed. Multiple can be selected.
     *
     * @property filterStatus
     * @type Array
     */
    filterStatus: Ember.computed(function () {
      return [];
    }),
    orderBy: 'DATE',
    orderByOptions: Ember.computed('l10n', function () {
      return [{
        value: 'DATE',
        label: this.get('l10n').t('Upcoming')
      }, {
        value: 'STATUS',
        label: this.get('l10n').t('Status')
      }, {
        value: 'NAME',
        label: this.get('l10n').t('Auction name')
      }];
    }),

    /**
     * The available status to filter from.
     *
     * @property filterStatusOptions
     * @type Array
     * @final
     */
    filterStatusOptions: Ember.computed('l10n', function () {
      return [{
        value: 'DRAFT',
        label: this.get('l10n').t('Draft')
      }, {
        value: 'ACTIVE',
        label: this.get('l10n').t('Active')
      }, {
        value: 'INACTIVE',
        label: this.get('l10n').t('Inactive')
      }, {
        value: 'CLOSED',
        label: this.get('l10n').t('Past')
      }, {
        value: 'RUNNING',
        label: this.get('l10n').t('Running')
      }];
    }),
    filteredAuctions: Ember.computed('model.@each.archived', 'showArchived', function () {
      var _this = this;

      return this.get('model').filter(function (auction) {
        return !_this.get('showArchived') ? !auction.get('archived') : auction.get('archived');
      });
    }) // -------------------------------------------------------------------------
    // Methods

  });

  _exports.default = _default;
});