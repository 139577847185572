define("@vollersgroup/hub-frontend/components/info-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gN9AumiZ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"flex-shrink-0\"],[9],[0,\"\\n  \"],[1,[27,\"svg-icon\",[[23,[\"svgIconName\"]]],[[\"class\"],[\"inline-icon margin-right-2\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"flex flex-column\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"title\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"bold margin-bottom-1\"],[9],[0,\"\\n      \"],[1,[21,\"title\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\"],[9],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/info-message/template.hbs"
    }
  });

  _exports.default = _default;
});