define("@vollersgroup/hub-frontend/components/info-tooltip/component", ["exports", "@vollersgroup/hub-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A dismissible tooltip.
   *
   * ```html
   * {{#info-tooltip infoId="create_shop" hideIf=shops.length}}
   *     The tooltip content goes here!
   * {{/info-tooltip}}
   * ```
   *
   * @namespace Component
   * @class InfoTooltip
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    tagName: 'div',
    classNameBindings: [':v-tooltip', 'hide:tooltip--hidden'],
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * The id of this tooltip. Has to be unique!
     *
     * @attribute infoId
     * @type String
     */
    infoId: null,

    /**
     * Also hide this tooltip if this is true.
     *
     * @attribute hideIf
     * @type Boolean
     */
    hideIf: false,
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Dismiss the tooltip. This is saved to the LocalStorage.
       *
       * @event dismiss
       * @private
       */
      dismiss: function dismiss() {
        if (_environment.default.environment === 'production') {
          var id = this.getAttr('infoId');

          try {
            window.localStorage.setItem(this.get('_namespace') + id, true);
          } catch (e) {
            console.error(e, 'local storage is not available!');
          }
        }

        this.$().fadeOut();
      }
    },
    // -------------------------------------------------------------------------
    // Properties
    _namespace: 'hubTooltips_',
    hide: Ember.computed('_namespace', 'hideIf', function () {
      var id = this.getAttr('infoId');
      return this.get('hideIf') || window.localStorage.getItem(this.get('_namespace') + id);
    })
  });

  _exports.default = _default;
});