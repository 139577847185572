define("@vollersgroup/hub-frontend/initializers/ajax-setup", ["exports", "@vollersgroup/hub-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    try {
      window.localStorage.setItem('ember_simple_auth:session', '{"authenticated":{"authenticator":"authenticator:custom","isAuthenticated":true}}');
    } catch (e) {
      console.error(e, 'local storage is not available!');
    }

    Ember.$.ajaxSetup({
      crossDomain: _environment.default.environment !== 'production',
      xhrFields: {
        withCredentials: true
      },
      beforeSend: function beforeSend(xhr) {
        xhr.setRequestHeader('Cropster-Authenticate', 'suppress-www-authenticate');
      }
    }); // Check if there are pending XHR requests before exiting

    if (_environment.default.environment === 'test') {
      return;
    }

    window.addEventListener('beforeunload', function (e) {
      if (Ember.$.active) {
        var message = 'Some data has not yet been synced with the server. Do you really want to close now? If you do, you might lose some data.';
        e.returnValue = message;
        return message;
      }
    }, false);
  }

  var _default = {
    name: 'ajax-setup',
    initialize: initialize
  };
  _exports.default = _default;
});