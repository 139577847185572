define("@vollersgroup/hub-frontend/services/message-center", ["exports", "@vollersgroup/hub-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This service handles all messages, notifications and private offerings.
   *
   * @namespace Service
   * @class MessageCenter
   * @extends Ember.Service
   * @uses DS.Store
   * @uses Service.UserSession
   */
  var _default = Ember.Service.extend(Ember.Evented, {
    // -------------------------------------------------------------------------
    // Dependencies
    store: Ember.inject.service(),
    userSession: Ember.inject.service(),
    ajax: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * If messages are currently being loaded/reloaded.
     *
     * @property isLoading
     * @type Boolean
     * @public
     */
    isLoading: Ember.computed('allConversationsUnsorted.isFulfilled', 'allNotificationsUnsorted.isFulfilled', function () {
      return !this.get('allConversationsUnsorted.isFulfilled') || !this.get('allNotificationsUnsorted.isFulfilled');
    }),

    /**
     * All unsorted conversations.
     *
     * @property allConversationsUnsorted
     * @type Model.Conversation[]
     * @private
     */
    allConversationsUnsorted: Ember.computed('store', function () {
      return this.get('store').query('conversation', {
        messageType: 'MESSAGE'
      });
    }),

    /**
     * All unsorted notifications.
     *
     * @property allNotificationsUnsorted
     * @type Model.Conversation[]
     * @private
     */
    allNotificationsUnsorted: Ember.computed('store', function () {
      return this.get('store').query('conversation', {
        messageType: 'SYSTEM_NOTIFICATION'
      });
    }),

    /**
     * All conversations, sorted by lastMessageDate.
     *
     * @property allConversations
     * @type Model.Conversation[]
     * @readOnly
     */
    allConversations: Ember.computed.sort('allConversationsUnsorted', function (a, b) {
      return b.get('lastMessageDate') - a.get('lastMessageDate');
    }),

    /**
     * All notifications, sorted by lastMessageDate.
     *
     * @property allNotifications
     * @type Model.Conversation[]
     * @readOnly
     */
    allNotifications: Ember.computed.sort('allNotificationsUnsorted', function (a, b) {
      return b.get('lastMessageDate') - a.get('lastMessageDate');
    }),

    /**
     * All unread conversations.
     *
     * @property unreadConversations
     * @type Model.Conversation[]
     * @readOnly
     */
    unreadConversations: Ember.computed('allConversations.@each.ownUnreadCount', function () {
      return this.get('allConversations').filter(function (item) {
        return item.get('ownUnreadCount');
      });
    }),

    /**
     * All unread notifications.
     *
     * @property unreadNotifications
     * @type Model.Conversation[]
     * @readOnly
     */
    unreadNotifications: Ember.computed('allNotifications.@each.toCompanyUnreadCount', function () {
      return this.get('allNotifications').filter(function (item) {
        return item.get('toCompanyUnreadCount');
      });
    }),

    /**
     * If there is any unread conversation, this will return true.
     *
     * @property hasUnread
     * @type Boolean
     * @readOnly
     */
    hasUnread: Ember.computed.or('unreadConversations.length', 'unreadNotifications.length'),
    _updateTimer: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.update();
    },
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Update the whole inbox.
     *
     * @method update
     * @public
     */
    update: function update() {
      var _this = this;

      Ember.run.next(function () {
        _this.notifyPropertyChange('allConversationsUnsorted');

        _this.notifyPropertyChange('allNotificationsUnsorted');

        _this.trigger('updated');

        if (_environment.default.environment === 'test') {
          return;
        }

        var timer = _this.get('_updateTimer');

        if (timer) {
          Ember.run.cancel(timer);
        }

        timer = Ember.run.later(_this, function () {
          _this.update();
        }, 1000 * 60 * 10); // = 10min

        _this.set('_updateTimer', timer);
      });
    },

    /**
     * Mark a conversation as read.
     *
     * @method markConversationRead
     * @param {Model.Conversation} conversation
     */
    markConversationRead: function markConversationRead(conversation) {
      this.get('ajax').post('/conversation/' + conversation.get('id') + '/markRead').then(function () {
        conversation.set(conversation.get('fromOrTo') + 'CompanyUnreadCount', 0);
      }).catch(function (error) {
        return console.error(error);
      });
    }
  });

  _exports.default = _default;
});