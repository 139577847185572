define("@vollersgroup/hub-frontend/store/index/controller", ["exports", "@vollersgroup/hub-frontend/mixins/search"], function (_exports, _search) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_search.SearchControllerMixin);

  _exports.default = _default;
});