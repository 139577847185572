define("@vollersgroup/hub-frontend/admin/manage/bulk/bulk-producer-route", ["exports", "@vollersgroup/hub-frontend/admin/manage/bulk/bulk-route-mixin", "@vollersgroup/hub-frontend/admin/manage/bulk/bulk-producer-mixin"], function (_exports, _bulkRouteMixin, _bulkProducerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Producer route for create and edit.
   *
   * @namespace Route
   * @uses Mixin.BulkRouteMixin
   * @uses Mixin.BulkProducerMixin
   * @class BulkProducerRoute
   * @extends Ember.Route
   */
  var _default = Ember.Route.extend(_bulkRouteMixin.default, _bulkProducerMixin.default);

  _exports.default = _default;
});