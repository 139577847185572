define("@vollersgroup/hub-frontend/order-item/model", ["exports", "ember-data", "@vollersgroup/hub-frontend/mixins/error-validation", "@vollersgroup/hub-frontend/utils/unit-calculator"], function (_exports, _emberData, _errorValidation, _unitCalculator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var belongsTo = _emberData.default.belongsTo;
  var attr = _emberData.default.attr;

  /**
   * The order-item model
   *
   * An order item belongs to an order and is associated to a listing.
   *
   * @namespace Model
   * @class OrderItem
   * @extends Model
   * @uses Mixin.UnitCalculator
   * @uses Mixin.ErrorValidation
   */
  var _default = Model.extend(_errorValidation.default, {
    /**
     * True if only a sample is ordered.
     *
     * @attribute sample
     * @type Boolean
     * @default false
     */
    sample: attr('boolean', {
      defaultValue: false
    }),

    /**
     * The amount of the listing being purchased.
     * Should also be set for samples! amount 1 = 1 sample ordered, amount 2 = 2 samples ordered, ...
     * For samples, by default put in 1
     *
     * @attribute initialAmount
     * @type Number
     */
    initialAmount: attr('number'),

    /**
     * The unit of the initial amount. Is NUMBER for samples.
     *
     * @attribute initialAmountUnit
     * @type String
     */
    initialAmountUnit: attr('string', {
      defaultValue: 'NUMBER'
    }),

    /**
     * The final amount of this ordered. This is set by the Seller.
     *
     * @attribute finalAmount
     * @type Number
     */
    finalAmount: attr('number'),

    /**
     * The unit of the final amount. Is NUMBER for samples.
     *
     * @attribute finalAmountUnit
     * @type String
     */
    finalAmountUnit: attr('string'),

    /**
     * The price of the listing at the time when the REQUEST is made.
     *
     * @attribute priceInitial
     * @type Number
     */
    priceInitialAmount: attr('number'),

    /**
     * The currency of the initial price.
     *
     * @attribute priceInitialCurrency
     * @type String
     */
    priceInitialCurrency: attr('string', {
      defaultValue: 'USD'
    }),

    /**
     * The final price of the listing. This is set by the Seller when making the offer.
     * It cannot be higher then priceInitialAmount.
     *
     * @attribute priceFinalAmount
     * @type Number
     */
    priceFinalAmount: attr('number'),

    /**
     * The currency of the initial price.
     *
     * @attribute priceFinalCurrency
     * @type String
     */
    priceFinalCurrency: attr('string'),

    /**
     * The listing which is represented by this order-item.
     *
     * @attribute listing
     * @type Model.Listing
     */
    listing: belongsTo('listing', {
      async: true
    }),

    /**
     * The order this order-item belongs to.
     *
     * @attribute order
     * @type Model.Order
     */
    order: belongsTo('order', {
      async: true,
      inverse: 'orderItems'
    }),
    isActive: Ember.computed.reads('listing.isActive'),

    /**
     * The current price of this order-item if it doesn't belong to a fixed order (f.e. auction order),
     * otherwise either `priceFinalAmount` (if already set) or `priceInitialAmount` will be used as pricing base.
     *
     * @property price
     * @type Number
     */
    price: Ember.computed('listing.{price,priceHidden,samplePrice}', 'order.{fixed,status}', 'priceFinalAmount', 'priceInitialAmount', 'sample', function () {
      var price = !Ember.get(this, 'priceFinalAmount') ? Ember.get(this, 'priceInitialAmount') : Ember.get(this, 'priceFinalAmount');

      switch (Ember.get(this, 'order.status')) {
        case 'CART':
          if (!Ember.get(this, 'order.fixed')) {
            if (Ember.get(this, 'sample')) {
              price = Ember.get(this, 'listing.samplePrice');
            } else {
              if (!Ember.get(this, 'listing.priceHidden')) {
                price = Ember.get(this, 'listing.price');
              }
            }
          }

          break;

        default:
      }

      return price ? price : 0;
    }),

    /**
     * The current amount of this order-item. Is either finalAmount (if finalAmount is set) or else initialAmount.
     *
     * @property amount
     */
    amount: Ember.computed('finalAmount', 'initialAmount', function () {
      if (Ember.get(this, 'finalAmount')) {
        return Ember.get(this, 'finalAmount');
      }

      return Ember.get(this, 'initialAmount');
    }),

    /**
     * If the listing price has been changed from the initial price.
     *
     * @property listingPriceHasChanged
     * @Boolean
     */
    listingPriceHasChanged: Ember.computed('priceInitialAmount', 'listing.currentPrice', {
      get: function get()
      /*key*/
      {
        var listingPrice = Ember.get(this, 'listing.currentPrice');
        var priceInitialAmount = Ember.get(this, 'priceInitialAmount');
        return listingPrice !== priceInitialAmount;
      }
    }),

    /**
     * If the listing sample price has been changed from the initial price.
     *
     * @property listingSamplePriceHasChanged
     * @Boolean
     */
    listingSamplePriceHasChanged: Ember.computed('priceInitialAmount', 'listing.samplePrice', {
      get: function get()
      /*key*/
      {
        var listingSamplePrice = Ember.get(this, 'listing.samplePrice');
        var priceInitialAmount = Ember.get(this, 'priceInitialAmount');
        return listingSamplePrice !== priceInitialAmount;
      }
    }),

    /**
     * If the final price has been changed from the initial price.
     *
     * @property priceHasChanged
     * @Boolean
     */
    priceHasChanged: Ember.computed('priceInitialAmount', 'priceFinalAmount', function () {
      return Ember.get(this, 'priceFinalAmount') && Ember.get(this, 'priceFinalAmount') * 1 !== Ember.get(this, 'priceInitialAmount') * 1;
    }),

    /**
     * If the final amount has been changed from the initial amount.
     *
     * @property amountHasChanged
     * @Boolean
     */
    amountHasChanged: Ember.computed('initialAmount', 'finalAmount', function () {
      return Ember.get(this, 'finalAmount') && Ember.get(this, 'finalAmount') * 1 !== Ember.get(this, 'initialAmount') * 1;
    }),

    /**
     * The estimated total price of this order-item.
     * Is automatically calculated based on the current listing price information (not order item price data)
     *
     * @property estimatedTotalPrice
     * @type Number
     */
    estimatedTotalPrice: Ember.computed('amount', 'price', 'listing.priceBaseUnit', 'initialAmountUnit', 'sample', function () {
      var basePrice = Ember.get(this, 'price');
      var baseUnit = Ember.get(this, 'listing.priceBaseUnit');
      var amount = Ember.get(this, 'amount');
      var packageUnit = Ember.get(this, 'initialAmountUnit');

      if (isNaN(basePrice * 1) || isNaN(amount * 1)) {
        return null;
      }

      var amountSI = Ember.get(this, 'sample') ? amount : (0, _unitCalculator.unitCalculatorWeight)(amount, packageUnit, baseUnit);

      if (!amountSI || !basePrice) {
        return null;
      }

      return (amountSI * basePrice).toFixed(2) * 1;
    }),

    /**
     * The validations for the order-item.
     *
     * @property validations
     * @override
     */
    validations: Ember.computed('listing.availableAmount', 'priceInitialAmount', 'sample', function () {
      var _this = this;

      return [{
        field: 'priceFinalAmount',
        validate: function validate(val) {
          if (Ember.get(_this, 'sample')) {
            return true;
          }

          return _this._validateNumber(val) && _this._validateRange(val, 0, null) && val <= Ember.get(_this, 'priceInitialAmount');
        },
        errorTitle: 'Error with the final price',
        errorMessage: 'The final price has to be a positive number and cannot be higher than the initial price.'
      }, {
        field: 'finalAmount',
        validate: function validate(val) {
          if (Ember.get(_this, 'sample')) {
            return true;
          }

          return _this._validateNumber(val, true) && _this._validateRange(val, 0, null);
        },
        errorTitle: 'Error with the final amount',
        errorMessage: 'The final amount has to be a positive whole number.'
      }, {
        field: 'finalAmount',
        validate: function validate(val) {
          if (Ember.get(_this, 'sample')) {
            return true;
          }

          return val <= Ember.get(_this, 'listing.availableAmount');
        },
        errorTitle: 'Error with the final amount',
        errorMessage: 'The final amount cannot be higher than the currently available amount of this listing.'
      }];
    })
  });

  _exports.default = _default;
});