define("@vollersgroup/hub-frontend/admin/manage/bulk/bulk-listing-controller", ["exports", "ember-data", "@vollersgroup/hub-frontend/admin/manage/bulk/bulk-listing-mixin", "@vollersgroup/hub-frontend/admin/manage/bulk/bulk-controller-mixin", "ember-launch-darkly"], function (_exports, _emberData, _bulkListingMixin, _bulkControllerMixin, _emberLaunchDarkly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PromiseObject = _emberData.default.PromiseObject;
  /**
   * Listing bulk controller for create and edit.
   *
   * @namespace Controller
   * @uses Mixin.BulkListingMixin
   * @uses Mixin.BulkControllerMixin
   * @class BulkListingController
   * @extends Ember.Controller
   */

  var _default = Ember.Controller.extend(_bulkControllerMixin.default, _bulkListingMixin.default, {
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Query parameter definition.
     *
     * @property queryParams
     * @type {Array}
     * @public
     */
    queryParams: [{
      currentShopId: {
        as: 'shop'
      }
    }, {
      currentAuctionId: {
        as: 'auction'
      }
    }, {
      orderBy: {
        as: 'orderBy'
      }
    }, {
      filterStatus: {
        as: 'status'
      }
    }, {
      filterOrigins: {
        as: 'origins'
      }
    }, {
      filterWarehouses: {
        as: 'warehouses'
      }
    }, {
      showArchived: {
        as: 'archived'
      }
    }, {
      showRatings: {
        as: 'ratings'
      }
    }, {
      searchTerm: {
        as: 'term'
      }
    }, {
      salesNumber: {
        as: 'sales'
      }
    }],
    currentShopId: null,
    currentAuctionId: null,
    orderBy: 'DATE',
    filterStatus: Ember.A(),
    filterOrigins: Ember.A(),
    filterWarehouses: Ember.A(),
    showArchived: false,
    showRatings: false,
    searchTerm: '',
    salesNumber: '',

    /**
     * Flag if auction selection is shown in UI.
     *
     * @property showAuctions
     * @type {Boolean}
     * @public
     */
    showAuctions: false,

    /**
     * Reference to selected shop.
     *
     * @property currentShop
     * @type {Model.Shop}
     * @public
     */
    currentShop: Ember.computed('currentShopId', 'hiddenShopId', 'store', {
      get: function get()
      /*key*/
      {
        var shopId = Ember.get(this, 'currentShopId') || Ember.get(this, 'hiddenShopId');

        if (Ember.isNone(shopId)) {
          return null;
        }

        if (this.store.hasRecordForId('shop', shopId)) {
          return this.store.peekRecord('shop', shopId);
        }

        return PromiseObject.create({
          promise: this.store.findRecord('shop', shopId)
        });
      }
    }),

    /**
     * Reference to selected auction
     * if 'Publish to auction' used.
     *
     * @property currentAuction
     * @type {Model.Auction}
     * @public
     */
    currentAuction: Ember.computed('currentAuctionId', 'hiddenAuctionId', 'store', {
      get: function get()
      /*key*/
      {
        var auctionId = Ember.get(this, 'currentAuctionId') || Ember.get(this, 'hiddenAuctionId');

        if (Ember.isNone(auctionId)) {
          return null;
        }

        if (this.store.hasRecordForId('auction', auctionId)) {
          return this.store.peekRecord('auction', auctionId);
        }

        return PromiseObject.create({
          promise: this.store.findRecord('auction', auctionId)
        });
      }
    }),

    /**
     * Custom configuration for handsontable.
     *
     * @property handsontableSettings
     * @type {Object}
     * @public
     */
    handsontableSettings: Ember.computed('instance', {
      get: function get()
      /*key*/
      {
        return {
          cells: function cells(row, col, prop) {
            var data = this.instance.getSourceData();
            var cellProperties = {};
            var priceDynamic = data[row] && data[row].priceDynamic;

            switch (prop) {
              case 'price':
                cellProperties.readOnly = priceDynamic;
                cellProperties.allowEmpty = !priceDynamic;
                break;

              case 'cmarketPremiumAmount':
                cellProperties.readOnly = !priceDynamic;
                cellProperties.allowEmpty = priceDynamic;
                break;

              default:
            }

            return cellProperties;
          },
          readOnlyCellClassName: 'bulk-grid--readonly'
        };
      }
    }),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * @method  prepareDataForSave_
     */
    prepareDataForSave_: function prepareDataForSave_(json, model) {
      if (Ember.get(model, 'isNew')) {
        // add missing required data implicitly
        json.shop = Ember.get(this, 'currentShop');
        json.createdBy = Ember.get(this, 'userSession.currentUser');
        json.priceCurrency = Ember.get(this, 'currentShop.currency');
        json.company = Ember.get(this, 'userSession.currentCompany');
        json.samplePriceCurrency = Ember.get(this, 'currentShop.currency');
      } else {
        // assert that immutable properties does not get
        // overridden by json - f.e. no state transition
        var immutableProperties = Ember.getProperties(model, ['id', 'shop', 'status', 'company', 'createdBy', 'priceCurrency', 'samplePriceCurrency']);
        Ember.merge(json, immutableProperties);
      } // assert that arrays are always arrays (even after delete)


      json.sensorialDescriptors = json.sensorialDescriptors || [];
      json.certifications = json.certifications || [];
      json.varieties = json.varieties || []; // assert that monetary values are always numeric
      // cause it's overridden by Handsontable by `null`

      json.cmarketPremiumAmount = json.cmarketPremiumAmount || 0;
      json.samplePrice = json.samplePrice || 0;
      json.price = json.price || 0;

      if (!(0, _emberLaunchDarkly.variation)('subscription-tab')) {
        json.warehouse = json.warehouse ? json.warehouse.id : null;
      }

      return json;
    },
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Updates template reference once the
       * mapping process has been finished.
       *
       * @event saveListings
       * @param {String} mode
       * @return {Void}
       * @private
       */
      save: function save(mode) {
        Ember.set(this, 'showAuctions', false);
        var customProperties = {};

        switch (mode) {
          case 'SAVE':
            // no change of 'status' here (edit)
            break;

          case 'PUBLISH':
            Ember.set(customProperties, 'status', 'ACTIVE');
            break;

          case 'AUCTION':
            // eslint-disable-next-line no-case-declarations
            var auction = Ember.get(this, 'currentAuction');
            Ember.set(customProperties, 'status', 'ACTIVE');
            Ember.set(customProperties, 'auction', auction);
            break;

          case 'DRAFT':
            Ember.set(customProperties, 'status', 'DRAFT');
            break;

          default:
        }

        this._super(customProperties);
      },

      /**
       * Toggles `showAuctions` property.
       *
       * @event toggleShowAuctions
       * @return {Void}
       * @private
       */
      toggleShowAuctions: function toggleShowAuctions() {
        this.toggleProperty('showAuctions');
      },

      /**
       * Persists selected auction in `currentAuction`.
       *
       * @event onSelectAuction
       * @param {Model.Auction} auction
       * @return {Void}
       * @private
       */
      selectAuction: function selectAuction(auction) {
        Ember.set(this, 'currentAuction', auction);
      }
    }
  });

  _exports.default = _default;
});