define("@vollersgroup/hub-frontend/info-center/auctions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TCP4HnWj",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"page-title\",[[27,\"t\",[\"Auctions - How they work\"],null]],null],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"wrapper padding-bottom-4\"],[9],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"fg-row\"],[9],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"fg-column-12 fg-column-4-m fg-column-2-l\"],[9],[0,\"\\n\\n      \"],[7,\"ul\"],[11,\"class\",\"third-nav\"],[9],[0,\"\\n        \"],[7,\"li\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"info-center.auctions.index\"],[[\"class\"],[\"third-nav__item\"]],{\"statements\":[[0,\"            \"],[1,[27,\"t\",[\"Helmsman auctions\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n        \"],[7,\"li\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"info-center.auctions.how-to-bid\"],[[\"class\"],[\"third-nav__item\"]],{\"statements\":[[0,\"            \"],[1,[27,\"t\",[\"How to bid\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"fg-column-12 fg-column-8-m fg-column-10-l\"],[9],[0,\"\\n      \"],[1,[21,\"outlet\"],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/info-center/auctions/template.hbs"
    }
  });

  _exports.default = _default;
});