define("@vollersgroup/hub-frontend/conversation/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var hasMany = _emberData.default.hasMany;
  var belongsTo = _emberData.default.belongsTo;
  var attr = _emberData.default.attr;

  /**
   * The conversation model
   *
   * A conversation can actually be one of three things:
   *
   * * a conversation between two companies
   * * a system notification
   * * a private offering to a company
   *
   * To find out what kind of conversation it is, the `systemNotification` and `privateOffering` properties can be checked.
   *
   * @namespace Model
   * @class Conversation
   * @extends Model
   */
  var _default = Model.extend({
    userSession: Ember.inject.service(),

    /**
     * @attribute subject
     */
    subject: attr('string'),

    /**
     * @attribute lastMessageDate
     */
    lastMessageDate: attr('timestamp'),

    /**
     * @attribute fromCompanyUnreadCount
     */
    fromCompanyUnreadCount: attr('number'),

    /**
     * @attribute toCompanyUnreadCount
     */
    toCompanyUnreadCount: attr('number'),

    /**
     * @attribute fromUser
     */
    fromUser: belongsTo('user', {
      async: true
    }),

    /**
     * @attribute fromCompany
     */
    fromCompany: belongsTo('company', {
      async: true
    }),

    /**
     * @attribute toUser
     */
    toUser: belongsTo('user', {
      async: true
    }),

    /**
     * @attribute toCompany
     */
    toCompany: belongsTo('company', {
      async: true
    }),

    /**
     * @attribute listing
     */
    listing: belongsTo('listing', {
      async: true
    }),

    /**
     * @attribute messages
     */
    messages: hasMany('message', {
      async: true
    }),

    /**
     * An optional order belonging to this conversation.
     * An order can only have one associated conversation!
     *
     * @attribute order
     * @type Model.Order
     */
    order: belongsTo('order', {
      async: true,
      inverse: null
    }),

    /**
     * An optional auction belonging to this conversation. This is used mainly for notifications.
     *
     * @attribute auction
     * @type Model.Auction
     */
    auction: belongsTo('auction', {
      async: true,
      inverse: null
    }),

    /**
     * @attribute category
     */
    category: attr('string', {
      defaultValue: 'GENERAL'
    }),

    /**
     * @attribute canReply
     */
    canReply: attr('boolean', {
      defaultValue: true
    }),

    /**
     * @attribute systemNotification
     */
    systemNotification: attr('boolean', {
      defaultValue: false
    }),

    /**
     * @attribute privateOffering
     */
    privateOffering: attr('boolean', {
      defaultValue: false
    }),

    /**
     * The last message of the thread.
     *
     * @attribute lastMessageText
     * @type String
     */
    lastMessageText: attr('string'),
    ownUnreadCount: Ember.computed('fromOrTo', 'fromCompanyUnreadCount', 'toCompanyUnreadCount', function () {
      return this.get(this.get('fromOrTo') + 'CompanyUnreadCount');
    }),
    fromOrTo: Ember.computed('fromCompany.id', 'privateOffering', 'systemNotification', 'userSession.currentCompany.id', function () {
      if (this.get('systemNotification') || this.get('privateOffering')) {
        return 'to';
      }

      if (this.get('fromCompany.id') + '' === this.get('userSession.currentCompany.id') + '') {
        return 'from';
      }

      return 'to';
    }),
    otherCompany: Ember.computed('fromOrTo', function () {
      var fromOrTo = this.get('fromOrTo') === 'from' ? 'to' : 'from';
      return this.get(fromOrTo + 'Company');
    })
  });

  _exports.default = _default;
});