define("@vollersgroup/hub-frontend/admin/manage/c-market/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Controller for c-market management.
   *
   * @namespace Controller
   * @class CMarketController
   * @extends Ember.Controller
   */
  var _default = Ember.Controller.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    ajax: Ember.inject.service(),
    toast: Ember.inject.service(),
    validator: Ember.inject.service(),
    userSession: Ember.inject.service(),
    availableData: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * If there are current async operations.
     *
     * @property isBusy
     * @type Boolean
     * @private
     */
    isBusy: false,

    /**
     * Overall valid state for controller.
     *
     * @property isValid
     * @type Boolean
     * @private
     */
    isValid: true,

    /**
     * Overall error state for template.
     *
     * @property errors
     * @type Boolean
     * @private
     */
    hasErrors: false,

    /**
     * Toggle state of disclaimer view.
     *
     * @property showDisclaimer
     * @type Boolean
     * @private
     */
    showDisclaimer: false,

    /**
     * Property to cache response from `cmarketGetRoute`:
     *
     * {
     *    date: <Array>,
     *    priceAmount: <Number>,
     *    coffeeFuture: <String>,
     *    priceCurrency: <String>,
     *    retrieved: <Number>
     * }
     *
     * @property latestCmarketPrice
     * @type {Object}
     * @public
     */
    latestCmarketPrice: null,

    /**
     * Custom route for getting latest C-Market price.
     *
     *
     * @property cmarketGetRoute
     * @type {String}
     * @private
     */
    cmarketGetRoute: '/misc/latestCmarketPrice',

    /**
     * Custom route for getting latest C-Market price.
     *
     * @property cmarketGetRoute
     * @type {String}
     * @private
     */
    cmarketPostRoute: Ember.computed('companySettings.id', {
      get: function get()
      /*key*/
      {
        var id = Ember.get(this, 'companySettings.id');
        return "/settings/".concat(id, "/updateCmarketPrice");
      }
    }),

    /**
     * Alias for company settings' `cmarketPriceCurrency`.
     *
     * @property baseCurrency
     * @type {String}
     * @private
     * @default 'USD'
     */
    baseCurrency: Ember.computed.reads('companySettings.cmarketPriceCurrency'),

    /**
     * Alias for company settings' `cmarketPriceCurrency`.
     *
     * @property baseCurrency
     * @type {String}
     * @private
     * @default 'USD'
     */
    baseUnit: Ember.computed.reads('companySettings.cmarketPriceUnit'),

    /**
     * Shops not donating in USD.
     *
     * @property exchangeRateShops
     * @type {Model.Shop[]}
     * @public
     */
    companySettings: Ember.computed.reads('model.settings'),

    /**
     * Shops not donating in USD.
     *
     * @property exchangeRateShops
     * @type {Model.Shop[]}
     * @public
     */
    exchangeRateShops: Ember.computed('model.shops', 'baseCurrency', {
      get: function get()
      /*key*/
      {
        var baseCurrency = Ember.get(this, 'baseCurrency');
        return Ember.get(this, 'model.shops').filter(function (shop) {
          return Ember.get(shop, 'currency') !== baseCurrency;
        });
      }
    }),

    /**
     * Flag indicating if touched
     * models are in dirty state.
     *
     * @property isDirty
     * @type {Boolean}
     * @public
     */
    isDirty: Ember.computed('companySettings.hasDirtyAttributes', 'exchangeRateShops.@each.hasDirtyAttributes', {
      get: function get()
      /*key*/
      {
        var shopsDirty = Ember.get(this, 'exchangeRateShops').findBy('hasDirtyAttributes');
        var settingsDirty = Ember.get(this, 'companySettings.hasDirtyAttributes');
        return shopsDirty || settingsDirty;
      }
    }),

    /**
     * Flag for save button depending on
     * validation and model's dirty state.
     *
     * @property canSave
     * @type {Boolean}
     * @public
     */
    canSave: Ember.computed('isValid', 'isDirty', {
      get: function get()
      /*key*/
      {
        var isValid = Ember.get(this, 'isValid');
        var isDirty = Ember.get(this, 'isDirty');
        return isDirty && isValid;
      }
    }),

    /**
     * Flag if price has been manually
     * changed by user.
     *
     * @property priceManuallyChanged
     * @type {Boolean}
     * @default false
     * @public
     */
    priceManuallyChanged: false,

    /**
     * Computed errors for `cmarketPriceAmount`
     * and `usdExchangeRate` of shops. Besides,
     * it will set `isValid` implicitly.
     *
     * @property errors
     * @type Object
     * @private
     */
    errors: Ember.computed('companySettings.cmarketPriceAmount', 'exchangeRateShops.@each.usdExchangeRate', {
      get: function get()
      /*key*/
      {
        var isNumber = function isNumber(value) {
          return !isNaN(value) && value > 0;
        };

        var validate = function validate(obj, prop) {
          var key = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
          var isError = !isNumber(Ember.get(obj, prop));
          var changeSet = obj.changedAttributes(); // errors visible in UI only if this
          // attribute has been touched by user

          if (!Ember.isNone(Ember.get(changeSet, prop))) {
            Ember.set(errors, key || prop, isError);

            if (isError === true) {
              hasError = true;
            }
          } // validation for internal use does
          // not depend on model dirty state!


          if (isError === true) {
            isValid = false;
          }
        };

        var errors = {};
        var isValid = true;
        var hasError = false;
        Ember.get(this, 'exchangeRateShops').forEach(function (shop) {
          validate(shop, 'usdExchangeRate', Ember.get(shop, 'slug'));
        });
        var settings = Ember.get(this, 'companySettings');
        validate(settings, 'cmarketPriceAmount');
        Ember.set(this, 'hasError', hasError);
        Ember.set(this, 'isValid', isValid);
        return errors;
      }
    }),
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Toggles `showDisclaimer` property.
       *
       * @event toggleDisclaimer
       * @return {Void}
       * @private
       */
      toggleDisclaimer: function toggleDisclaimer() {
        this.toggleProperty('showDisclaimer');
      },

      /**
       * Triggered when checkbox for state
       * of `dynamicPricingEnabled` changes.
       *
       * @event onToggleEnabled
       * @param {Boolean} enabled
       * @return {Void}
       * @private
       */
      onToggledynamicPricingEnabled: function onToggledynamicPricingEnabled(enabled) {
        var price = Ember.get(this, 'companySettings.cmarketPriceAmount');

        if (!enabled || !Ember.isNone(price)) {
          return;
        }

        this.send('getLatestCMarketPrice');
      },

      /**
       * Triggered when price field has
       * been changed by user interaction.
       *
       * @event onChangePriceManually
       * @param {Number} value
       * @return {Void}
       * @private
       */
      onChangePriceManually: function onChangePriceManually()
      /*value*/
      {
        Ember.set(this, 'priceManuallyChanged', true);
      },

      /**
       * Unsets `latestCmarketPrice` property.
       *
       * @event hideLatestCMarketPrice
       * @return {Void}
       * @private
       */
      hideLatestCMarketPrice: function hideLatestCMarketPrice() {
        Ember.set(this, 'latestCmarketPrice', null);
      },

      /**
       * Updates with latest C-Market price
       * via /api/misc/latestcmarketprice.
       *
       * @event getLatestCMarketPrice
       * @return {Void}
       * @private
       */
      getLatestCMarketPrice: function getLatestCMarketPrice() {
        var _this = this;

        var url = Ember.get(this, 'cmarketGetRoute');

        var successCallback = function successCallback(response) {
          var price = response.priceAmount;

          if (Ember.isArray(response.date)) {
            response.date = new Date(response.date[0], // YYYY
            response.date[1] - 1, // MM
            response.date[2] // DD
            );
          }

          Ember.set(_this, 'companySettings.cmarketPriceAmount', price);
          Ember.set(_this, 'latestCmarketPrice', response);
          Ember.set(_this, 'priceManuallyChanged', false);
        };

        var failureCallback = function failureCallback(error) {
          var serverErrorMessage = Ember.get(error, 'payload.error');
          var errorMessage = Ember.get(_this, 'l10n').t('An error occurred while fetching C-Market price: {{serverErrorMessage}}', {
            serverErrorMessage: serverErrorMessage
          });
          Ember.get(_this, 'toast').error(errorMessage);
        };

        var finallyCallback = function finallyCallback() {
          Ember.set(_this, 'isBusy', false);
        };

        Ember.set(this, 'isBusy', true);
        Ember.get(this, 'ajax').request(url).then(successCallback, failureCallback).finally(finallyCallback);
      },

      /**
       * Rolls back all attributes of involved models.
       *
       * @event discard
       * @return {Void}
       * @private
       */
      discard: function discard() {
        var settings = Ember.get(this, 'companySettings');
        var shops = Ember.get(this, 'exchangeRateShops');
        settings.rollbackAttributes();
        shops.forEach(function (shop) {
          shop.rollbackAttributes();
        });
      },

      /**
       * Invokes saving of company settings and all
       * shops recursively, which is precondition to
       * trigger a POST request onto `cmarketPostRoute`.
       *
       * @event save
       * @return {Void}
       * @private
       */
      save: function save() {
        var _this2 = this;

        var settings = Ember.get(this, 'companySettings');
        var shops = Ember.get(this, 'exchangeRateShops');

        var postSuccessCallback = function postSuccessCallback() {
          Ember.get(_this2, 'toast').success(Ember.get(_this2, 'l10n').t('Your C-Market setup has been successfully saved and all coffees have been updated.'));
        };

        var postFailureCallback = function postFailureCallback(error) {
          var serverErrorMessage = Ember.get(error, 'payload.error');
          var errorMessage = Ember.get(_this2, 'l10n').t('An error occurred while updating listings with C-Market prices: {{serverErrorMessage}}', {
            serverErrorMessage: serverErrorMessage
          });
          Ember.get(_this2, 'toast').error(errorMessage);
          Ember.set(_this2, 'canSave', true);
        };

        var postFinallyCallback = function postFinallyCallback() {
          Ember.set(_this2, 'isBusy', false);
        };

        var saveSuccessCallback = function saveSuccessCallback() {
          var url = Ember.get(_this2, 'cmarketPostRoute');
          Ember.get(_this2, 'ajax').post(url).then(postSuccessCallback, postFailureCallback).finally(postFinallyCallback);
        };

        var saveFailureCallback = function saveFailureCallback(error) {
          var serverErrorMessage = Ember.get(error, 'payload.error');
          var errorMessage = Ember.get(_this2, 'l10n').t('An error occurred while saving C-Market settings: {{serverErrorMessage}}', {
            serverErrorMessage: serverErrorMessage
          });
          Ember.get(_this2, 'toast').error(errorMessage);
          Ember.set(_this2, 'isBusy', false);
        };

        var baseCurrency = Ember.get(this, 'baseCurrency');
        Ember.set(settings, 'cmarketPriceCurrency', baseCurrency);
        var promises = [settings.save()];
        shops.forEach(function (shop) {
          promises.push(shop.save());
        });
        var promise = Ember.RSVP.all(promises);
        promise.then(saveSuccessCallback, saveFailureCallback);
        return promise;
      }
    }
  });

  _exports.default = _default;
});