define("@vollersgroup/hub-frontend/admin/manage/producer/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "706nUjjs",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"page-title\",[[27,\"t\",[\"Edit origin\"],null]],null],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"wrapper\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"layout__sidebar--left\"],[9],[0,\"\\n\"],[4,\"sticky-block\",null,null,{\"statements\":[[0,\"      \"],[1,[27,\"manage-steps\",null,[[\"currentStep\",\"steps\",\"action\"],[[23,[\"step\"]],[23,[\"allSteps\"]],\"gotoStep\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"layout__sidebar--main padding-bottom-4\"],[9],[0,\"\\n\\n    \"],[7,\"h2\"],[9],[1,[27,\"t\",[\"Edit origin\"],null],false],[10],[0,\"\\n\\n    \"],[7,\"hr\"],[9],[10],[0,\"\\n\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"step\"]],0],null]],null,{\"statements\":[[0,\"      \"],[1,[27,\"manage-producer\",null,[[\"action\",\"producer\"],[\"editProducer\",[23,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/admin/manage/producer/edit/template.hbs"
    }
  });

  _exports.default = _default;
});