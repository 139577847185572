define("@vollersgroup/hub-frontend/checkout/index/route", ["exports", "@vollersgroup/hub-frontend/mixins/reset-scroll"], function (_exports, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_resetScroll.default, {
    checkout: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this.get('checkout').set('currentStep', 0);
    },
    actions: {
      completeStep: function completeStep() {
        var checkout = this.get('checkout');

        if (checkout.get('billingIsShipping')) {
          var billingAddress = checkout.get('billingAddress');
          billingAddress.copyTo(checkout.get('shippingAddress'));
        }

        if (!checkout.get('shippingAddress.isValid') || !checkout.get('billingAddress.isValid')) {
          this.get('flashMessages').addError(this.get('l10n').t('Please fill in all required fields.'));
          return false;
        }

        var gotoRoute = checkout.completeStep(0);

        if (gotoRoute) {
          this.transitionTo(gotoRoute);
        }
      }
    }
  });

  _exports.default = _default;
});