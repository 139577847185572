define("@vollersgroup/hub-frontend/error/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TwUrJaCL",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"page-title\",[[27,\"t\",[\"Woah! Something went wrong\"],null]],null],false],[0,\"\\n\\n\"],[7,\"header\"],[11,\"class\",\"\"],[9],[0,\"\\n  \"],[1,[21,\"header-bar\"],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"section\"],[11,\"class\",\"margin-all-0 padding-top-3 section--highlighted padding-bottom-4 flex-grow-1\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"wrapper padding-top-4\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"margin-auto max-width-7 text-center\"],[9],[0,\"\\n      \"],[7,\"img\"],[11,\"class\",\"block margin-auto width-50p\"],[11,\"src\",\"/assets/img/chemex-spilled.svg\"],[12,\"alt\",[27,\"t\",[\"Spilled chemex\"],null]],[9],[10],[0,\"\\n\\n      \"],[7,\"h1\"],[11,\"class\",\"font-size-3\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"Woah! Something went wrong.\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n\\n      \"],[7,\"p\"],[9],[0,\"\\n\"],[4,\"get-text\",null,[[\"message\"],[[27,\"t\",[\"Sorry, but an error occurred. Please contact {{cropsterEmail}} if this problem persists.\"],null]]],{\"statements\":[[0,\"          \"],[7,\"a\"],[11,\"href\",\"mailto:support@vollers.com\"],[9],[0,\"support@vollers.com\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[21,\"footer-main\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/error/template.hbs"
    }
  });

  _exports.default = _default;
});