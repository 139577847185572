define("@vollersgroup/hub-frontend/rate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S/4mKCvg",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"page-title\",[[27,\"t\",[\"Rate\"],null]],null],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"flex-grow-1 bg-color-grey-6\"],[9],[0,\"\\n  \"],[1,[27,\"rate-listing\",null,[[\"hasRating\",\"rating\",\"listingEncId\"],[[23,[\"hasRating\"]],[23,[\"model\",\"rating\"]],[23,[\"model\",\"listingEncId\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/rate/template.hbs"
    }
  });

  _exports.default = _default;
});