define("@vollersgroup/hub-frontend/components/pricing-plans/billing-toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zi90WNHb",
    "block": "{\"symbols\":[\"activeClasses\",\"inactiveClasses\",\"@onChange\"],\"statements\":[[7,\"div\"],[11,\"class\",\"relative mt-12 flex justify-center sm:mt-16\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"bg-v-blue-700 p-0.5 rounded-lg flex\"],[9],[0,\"\\n\"],[4,\"let\",[\"bg-white border-v-blue-700 text-v-blue-700 shadow-sm hover:bg-v-blue-50\",\"border-transparent text-v-blue-200 ml-0.5 hover:bg-v-blue-800 border\"],null,{\"statements\":[[0,\"    \"],[7,\"button\"],[12,\"class\",[28,[[27,\"if\",[[27,\"eq\",[[22,0,[\"billingType\"]],\"monthly\"],null],[22,1,[]],[22,2,[]]],null],\" relative py-2 px-6 rounded-md text-sm font-medium\\n            whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-v-blue-700 focus:ring-white focus:z-10\"]]],[11,\"type\",\"button\"],[9],[0,\"\\n      Monthly billing\\n    \"],[3,\"action\",[[22,0,[]],[22,3,[]],\"monthly\"]],[10],[0,\"\\n    \"],[7,\"button\"],[12,\"class\",[28,[[27,\"if\",[[27,\"eq\",[[22,0,[\"billingType\"]],\"yearly\"],null],[22,1,[]],[22,2,[]]],null],\" relative py-2 px-6 rounded-md text-sm font-medium\\n            whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-v-blue-700 focus:ring-white focus:z-10\"]]],[11,\"type\",\"button\"],[9],[0,\"\\n      Yearly billing\\n    \"],[3,\"action\",[[22,0,[]],[22,3,[]],\"yearly\"]],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/pricing-plans/billing-toggle/template.hbs"
    }
  });

  _exports.default = _default;
});