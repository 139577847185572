define("@vollersgroup/hub-frontend/admin/messages/notifications/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "@vollersgroup/hub-frontend/mixins/reset-scroll"], function (_exports, _authenticatedRouteMixin, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _resetScroll.default, {
    messageCenter: Ember.inject.service(),
    queryParams: {
      page: {
        refreshModel: true
      },
      pageSize: {
        refreshModel: true
      },
      company: {
        refreshModel: true
      },
      subject: {
        refreshModel: true
      }
    },
    model: function model(params) {
      params.company = params.company || null;
      params.subject = params.subject || null;
      params.pageSize = params.pageSize || 20;
      params.page = params.page || 1;
      params.messageType = 'SYSTEM_NOTIFICATION';
      return this.get('store').query('conversation', params);
    },
    afterModel: function afterModel(model) {
      var _this = this;

      model.forEach(function (conversation) {
        var toCompanyUnreadCount = Ember.get(conversation, 'toCompanyUnreadCount');

        if (toCompanyUnreadCount === 0) {
          return;
        }

        var messageCenter = Ember.get(_this, 'messageCenter');
        messageCenter.markConversationRead(conversation);
      });
    }
  });

  _exports.default = _default;
});