define("@vollersgroup/hub-frontend/components/auction-delete-bid/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9cu6LoJn",
    "block": "{\"symbols\":[\"closeAction\"],\"statements\":[[7,\"button\"],[11,\"class\",\"button button--warning nowrap\"],[12,\"disabled\",[27,\"not\",[[23,[\"listing\",\"canDeleteBid\"]]],null]],[9],[0,\"\\n  \"],[1,[27,\"t\",[\"Delete highest bid\"],null],false],[0,\"\\n\"],[3,\"action\",[[22,0,[]],[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"showModal\"]]],null],true],null]]],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"showModal\"]]],null,{\"statements\":[[4,\"modal-overlay\",null,[[\"title\",\"modalClasses\",\"close\"],[[27,\"t\",[\"Caution\"],null],\"modal--medium\",[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"showModal\"]]],null],false],null]]],{\"statements\":[[4,\"info-message\",null,[[\"type\",\"class\"],[\"WARNING\",\"margin-bottom-2\"]],{\"statements\":[[0,\"      \"],[7,\"span\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"You are going to delete highest bid for {{listingName}}! Are you sure?\"],[[\"listingName\"],[[23,[\"listing\",\"name\"]]]]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"div\"],[11,\"class\",\"button__container button__container--inline margin-top-3 text-right\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"button button--secondary\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"Cancel\"],null],false],[0,\"\\n      \"],[3,\"action\",[[22,0,[]],[22,1,[]]]],[10],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"button button--warning\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"Yes, delete bid!\"],null],false],[0,\"\\n      \"],[3,\"action\",[[22,0,[]],\"deleteBid\",[22,1,[]]]],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/auction-delete-bid/template.hbs"
    }
  });

  _exports.default = _default;
});