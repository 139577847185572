define("@vollersgroup/hub-frontend/components/auction-participant-table/component", ["exports", "@vollersgroup/hub-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    tagName: 'table',
    classNames: ['table', 'table--striped'],
    auction: null,
    init: function init() {
      this._super.apply(this, arguments);

      this._refreshOnlineUsers();
    },
    _refreshOnlineUsers: function _refreshOnlineUsers() {
      if (_environment.default.environment === 'test') {
        return;
      }

      if (Ember.get(this, 'isDestroyed')) {
        return;
      }

      Ember.get(this, 'auction').hasMany('users').reload();
      var interval = 30 * 1000;
      Ember.run.later(this, this._refreshOnlineUsers, interval);
    }
  });

  _exports.default = _default;
});