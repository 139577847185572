define("@vollersgroup/hub-frontend/components/listing-list-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Display a single listing-item
   *
   * ```html
   * {{listing-list-item listing=myListing}}
   * ```
   *
   * @namespace Component
   * @class ListingListItem
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    shoppingCart: Ember.inject.service(),
    userSession: Ember.inject.service(),
    router: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Element Properties

    /**
     * @property tagName
     * @type String
     * @final
     * @private
     */
    tagName: 'div',
    classNameBindings: [':listing__item', ':card', 'listing.hasAuction:listing__item__auction', 'hasRunningAuction:listing__item__auction--running'],
    'data-test-listing-list-item': Ember.computed.readOnly('listing.id'),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * The listing to display
     *
     * @attribute listing
     * @type Model.Listing
     * @required
     */
    listing: null,
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Add this listing to the shopping cart
       *
       * @event addToCart
       * @private
       */
      addToCart: function addToCart() {
        this.get('shoppingCart').addListing(this.get('listing'));
      }
    },
    // -------------------------------------------------------------------------
    // Properties
    isOwner: Ember.computed('userSession.currentCompany.id', 'listing.companyId', function () {
      return this.get('userSession.currentCompany.id') + '' === this.get('listing.companyId') + '';
    }),
    isActiveAndAuction: Ember.computed.and('listing.hasAuction', 'listing.isActive'),
    isActiveNoAuction: Ember.computed('listing.hasAuction', 'listing.isActive', function () {
      return this.get('listing.isActive') && !this.get('listing.hasAuction');
    }),
    isInShoppingCartPurchase: Ember.computed('listing.orderItemInShoppingCart', 'listing.orderItemInShoppingCart.sample', function () {
      return this.get('listing.orderItemInShoppingCart') && !this.get('listing.orderItemInShoppingCart.sample');
    }),
    isInShoppingCartSample: Ember.computed.and('listing.orderItemInShoppingCart', 'listing.orderItemInShoppingCart.sample'),
    hasRunningAuction: Ember.computed.alias('listing.auction.isRunning'),
    notAuthenticated: Ember.computed.not('userSession.session.isAuthenticated'),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Links to listing if clicked in an area
     * outside of the default one specified.
     *
     * @param  {Object} event jQuery event.
     * @return {Void}
     */
    click: function click() {
      this.get('router').transitionTo('store.listing', this.get('listing'));
    }
  });

  _exports.default = _default;
});