define("@vollersgroup/hub-frontend/utils/get-first-adapter-error-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getFirstAdapterErrorMessage;

  function getFirstAdapterErrorMessage(adapterError) {
    adapterError = adapterError || {};
    var errors = adapterError.errors || [];
    var firstError = errors ? errors[0] : {};
    var message = firstError ? firstError.detail : '';
    return message;
  }
});