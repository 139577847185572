define("@vollersgroup/hub-frontend/admin/manage/listing/edit/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "@vollersgroup/hub-frontend/mixins/reset-scroll", "ember-can", "@vollersgroup/hub-frontend/utils/get-first-adapter-error-message"], function (_exports, _authenticatedRouteMixin, _resetScroll, _emberCan, _getFirstAdapterErrorMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _resetScroll.default, _emberCan.CanMixin, {
    // -------------------------------------------------------------------------
    // Dependencies
    flashMessages: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Renders templates for admin route manually.
     * Per default, it renders secondary nav items
     * from manage pod to `secondary-nav` outlet.
     *
     * @public
     * @method renderTemplate
     * @return {Void}
     */
    renderTemplate: function renderTemplate() {
      this.controller.set('navbar', {
        route: 'admin.manage.overview.index',
        title: Ember.get(this, 'l10n').t('Edit coffee')
      });
      this.render();
      this.render('admin/manage/navbar-subpage', {
        outlet: 'secondary-nav',
        into: 'admin'
      });
    },

    /**
     * Checks if user allowed to create listings
     * and returns available shops if permitted.
     *
     * @public
     * @method deactivate
     * @return {Void}
     */
    beforeModel: function beforeModel() {
      if (!this.can('edit listing')) {
        this.transitionTo('admin.manage.overview.index');
        return;
      }

      return this._super.apply(this, arguments);
    },

    /**
     * Gets selected listing object.
     *
     * @public
     * @method model
     * @returns {Promise}
     */
    model: function model(params) {
      return this.store.findRecord('listing', params.listing_id);
    },

    /**
     * Check if company settings already exists,
     * otherwise creates new record in the store.
     *
     * @method afterModel
     * @param model
     * @param transition
     * @return {Promise}
     */
    afterModel: function afterModel(model, transition) {
      var _this = this;

      var controller = this.controllerFor(Ember.get(this, 'routeName'));
      Ember.set(controller, 'step', 0);

      var abortTransition = function abortTransition() {
        Ember.get(_this, 'toast').warning(Ember.get(_this, 'l10n').t('You cannot edit this coffee!'));

        if (transition.sequence === 0) {
          _this.transitionTo('admin.manage.overview.index');
        } else {
          transition.abort();
        }
      };

      if (!Ember.get(model, 'isEditable')) {
        abortTransition();
        return;
      }

      return Ember.get(model, 'company').then(function (company) {
        var currentId = Ember.get(_this, 'userSession.currentCompany.id');

        if (company.id !== currentId) {
          abortTransition();
          return;
        }
      });
    },

    /**
     * Resets route's model.
     *
     * @public
     * @method deactivate
     * @return {Void}
     */
    deactivate: function deactivate() {
      this.currentModel.rollbackAttributes();
    },

    /**
     * Move to another step.
     *
     * @event gotoStep
     * @param {Number} newStep
     * @private
     */
    _gotoStep: function _gotoStep(step) {
      var controller = this.controllerFor(Ember.get(this, 'routeName'));
      Ember.set(controller, 'step', step);
      window.scrollTo(0, 0);
    },
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * When the listing information has been entered/updated.
       *
       * @event editListing
       * @param Model.Listing listing
       * @private
       */
      editListing: function editListing(listing) {
        var _this2 = this;

        var exitNow = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

        if (!exitNow) {
          this._gotoStep(1);

          return;
        }

        var successCallback = function successCallback() {
          _this2.transitionTo('admin.manage.overview.index');
        };

        var failureCallback = function failureCallback(adapterError) {
          var serverErrorMessage = (0, _getFirstAdapterErrorMessage.default)(adapterError);
          var errorMessage = Ember.get(_this2, 'l10n').t('An error occurred while trying to save the listing: {{serverErrorMessage}}', {
            serverErrorMessage: serverErrorMessage
          });
          Ember.get(_this2, 'flashMessages').addError(errorMessage);

          _this2._gotoStep(0);
        };

        var model = Ember.get(this, 'currentModel');
        model.save().then(successCallback, failureCallback);
      },

      /**
       * Select a producer for this listing.
       *
       * @event selectProducer
       * @param Model.Producer producer
       * @private
       */
      selectProducer: function selectProducer(producer) {
        var _this3 = this;

        var exitNow = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        var model = Ember.get(this, 'currentModel');
        model.setProperties({
          producer: producer,
          country: producer.get('location.country')
        });

        var successCallback = function successCallback() {
          if (exitNow) {
            _this3.transitionTo('admin.manage.overview.index');

            return;
          }

          _this3._gotoStep(2);
        };

        var failureCallback = function failureCallback(adapterError) {
          var serverErrorMessage = (0, _getFirstAdapterErrorMessage.default)(adapterError);
          var errorMessage = Ember.get(_this3, 'l10n').t('An error occurred while trying to save the listing: {{serverErrorMessage}}', {
            serverErrorMessage: serverErrorMessage
          });
          Ember.get(_this3, 'flashMessages').addError(errorMessage);

          _this3._gotoStep(0);
        };

        model.save().then(successCallback, failureCallback);
      },

      /**
       * Finish the creation process.
       *
       * @event finishCreation
       * @param {Boolean} createAnother If another coffee should be created
       * @private
       */
      finishCreation: function finishCreation() {
        var createAnother = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        if (!createAnother) {
          this.transitionTo('admin.manage.overview.index');
          return;
        }

        this.transitionTo('admin.manage.listing.create', {
          queryParams: {
            addToShop: Ember.get(this, 'currentModel.shop.id')
          }
        });
      },

      /**
       * Move to another step.
       *
       * @event gotoStep
       * @param {Number} newStep
       * @private
       */
      gotoStep: function gotoStep(newStep) {
        var controller = this.controllerFor(Ember.get(this, 'routeName'));
        var model = Ember.get(this, 'currentModel');
        var step = Ember.get(controller, 'step');

        if (Ember.get(model, 'hasDirtyAttributes') && newStep > step) {
          Ember.get(this, 'toast').warning(Ember.get(this, 'l10n').t('You have to save your changes!'));
          return;
        }

        this._gotoStep(newStep);
      },

      /**
       * Redirect to overview on errors.
       *
       * @event error
       * @return {Void}
       */
      error: function error() {
        Ember.get(this, 'toast').warning(Ember.get(this, 'l10n').t('You cannot edit this coffee!'));
        this.transitionTo('admin.manage.overview.index');
      }
    }
  });

  _exports.default = _default;
});