define("@vollersgroup/hub-frontend/admin/messages/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "@vollersgroup/hub-frontend/mixins/reset-scroll", "ember-can"], function (_exports, _authenticatedRouteMixin, _resetScroll, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _resetScroll.default, _emberCan.CanMixin, {
    messageCenter: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (!this.can('view conversation')) {
        this.transitionTo('admin');
        return;
      }

      return this._super.apply(this, arguments);
    },
    actions: {
      markAsRead: function markAsRead(conversation) {
        this.get('messageCenter').markConversationRead(conversation);
      },
      reloadMessages: function reloadMessages() {
        this.get('messageCenter').update();
      }
    }
  });

  _exports.default = _default;
});