define("@vollersgroup/hub-frontend/services/checkout", ["exports", "@vollersgroup/hub-frontend/custom-objects/address-object"], function (_exports, _addressObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The service which handles the checkout process.
   *
   * @namespace Service
   * @class Checkout
   * @extends Ember.Service
   */
  var _default = Ember.Service.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    l10n: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * The order (=cart) which is currently being checked out.
     *
     * @property cart
     * @type Model.Order
     */
    // TODO: consider renaming to order to match model name
    cart: null,

    /**
     * The temporary shipping address for the checkout process.
     * This is only copied to the order after the checkout is completed.
     *
     * @property shippingAddress
     * @type CustomObject.AddressObject
     */
    shippingAddress: _addressObject.default.create(),

    /**
     * The temporary billing address for the checkout process.
     * This is only copied to the order after the checkout is completed.
     *
     * @property billingAddress
     * @type CustomObject.AddressObject
     */
    billingAddress: _addressObject.default.create(),

    /**
     * The temporary vat number for the checkout process.
     * This is only copied to the order after the checkout is completed.
     *
     * @property vatNumber
     * @type String
     * @optional
     */
    vatNumber: null,

    /**
     * The last 4 digits of the used credit card.
     * Is null if no card has been set.
     *
     * @property cardEnding
     * @type {String}
     */
    cardEnding: null,

    /**
     * The stripe token for the used credit card.
     *
     * @property cardToken
     * @type {String}
     */
    cardToken: null,

    /**
     * The type of the credit card used.
     *
     * @property cardType
     * @type {String}
     */
    cardType: null,

    /**
     * If this is true, the shipping address will also be used as billing address (no matter the content of billingAddress!)
     *
     * @property billingIsShipping
     * @type Boolean
     * @default true
     */
    billingIsShipping: true,

    /**
     * If the policies have been accepted.
     *
     * @property policyAccepted
     * @type boolean
     * @default false
     */
    policyAccepted: false,

    /**
     * The checkout step the user is currently in.
     *
     * @property currentStep
     * @type Number
     * @default 0
     */
    currentStep: 0,

    /**
     * The initial message for the order's conversation.
     *
     * @property initialMessage
     * @type String
     */
    initialMessage: null,
    steps: Ember.computed('cart.priorPaymentRequired', 'l10n', function () {
      var steps = [{
        route: 'checkout.index',
        label: this.get('l10n').t('Shipping'),
        isDone: false
      }, {
        route: 'checkout.policy',
        label: 'Policies',
        isDone: false
      }];

      if (this.get('cart.priorPaymentRequired')) {
        steps.push({
          route: 'checkout.payment',
          label: this.get('l10n').t('Payment'),
          isDone: false
        });
      }

      steps.push({
        route: 'checkout.review',
        label: this.get('l10n').t('Review'),
        isDone: false
      });
      return steps;
    }),

    /**
     * If a vat number is required.
     */
    hasVatNumber: Ember.computed.alias('billingAddress.hasVatNumber'),
    shippingCountry: Ember.computed('billingIsShipping', 'billingAddress.country', 'shippingAddress.country', function () {
      if (Ember.get(this, 'billingIsShipping')) {
        return Ember.get(this, 'billingAddress.country');
      }

      return Ember.get(this, 'shippingAddress.country');
    }),
    resetAddresses: function resetAddresses() {
      this.set('shippingAddress', _addressObject.default.create());
      this.set('billingAddress', _addressObject.default.create());
    },
    completeStep: function completeStep(step) {
      var steps = this.get('steps');
      var curStep = steps[step];
      var lastStep = step ? steps[step - 1] : null;

      if (!lastStep || Ember.get(lastStep, 'isDone')) {
        Ember.set(curStep, 'isDone', true);
        var nextStep = (step + 1) * 1;
        Ember.set(this, 'currentStep', nextStep);
        return Ember.get(steps[nextStep], 'route');
      }

      return null;
    }
  });

  _exports.default = _default;
});