define("@vollersgroup/hub-frontend/admin/manage/overview/warehouses/owner/create/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MwX+pYY0",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"page-title\",[[27,\"t\",[\"Create Warehouse Owner\"],null]],null],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"wrapper padding-bottom-3\"],[9],[0,\"\\n  \"],[7,\"h1\"],[11,\"class\",\"font-size-3\"],[9],[1,[27,\"t\",[\"Create Warehouse Owner\"],null],false],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"wrapper padding-bottom-4\"],[9],[0,\"\\n  \"],[1,[21,\"warehouses/warehouse-owner/form\"],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/admin/manage/overview/warehouses/owner/create/template.hbs"
    }
  });

  _exports.default = _default;
});