define("@vollersgroup/hub-frontend/components/login-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    session: Ember.inject.service(),
    userSession: Ember.inject.service(),
    router: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * A route to goto after the login.
     * This comes before gotoRoute. It can be any key-value pair as query params.
     *
     * @attribute afterLogin
     * @type String
     */
    afterLogin: null,

    /**
     * Go to this route after the login.
     *
     * @attribute gotoRoute
     * @type String
     */
    gotoRoute: null,

    /**
     * Go to the route with this model after login.
     *
     * @attribute gotoModel
     * @type String
     */
    gotoModel: null,

    /**
     * An action that's performed after successful login.
     */
    afterLoginAction: null,
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Log the user in
       *
       * @event login
       * @private
       */
      login: function login() {
        var _this = this;

        if (this.get('isLoading')) {
          return;
        }

        this.set('errors', []);
        this.set('isLoading', true);
        this.get('session').authenticate('authenticator:custom', {
          email: this.get('username'),
          password: this.get('password'),
          rememberMe: this.get('rememberMe')
        }).then(function () {
          var user = _this.get('userSession.currentUser');

          return user.get('company').then(function (_company) {
            if (_this.get('afterLogin')) {
              var paramName = _this.get('afterLogin').substr(0, _this.get('afterLogin').indexOf('++'));

              var paramValue = _this.get('afterLogin').substr(paramName.length + 2);

              var queryParamObj = {
                showLogin: null,
                afterLogin: null
              };
              queryParamObj[paramName] = paramValue;

              if (paramName === 'messageTo') {
                queryParamObj.sendMessage = 1;
              }

              _this.get('router').transitionTo({
                queryParams: queryParamObj
              });
            } else if (_this.get('gotoRoute')) {
              if (_this.get('gotoModel')) {
                _this.get('router').transitionTo(_this.get('gotoRoute'), _this.get('gotoModel'), {
                  queryParams: {
                    gotoModel: null,
                    gotoRoute: null,
                    showLogin: null,
                    afterLogin: null
                  }
                });
              } else {
                _this.get('router').transitionTo(_this.get('gotoRoute'), {
                  queryParams: {
                    gotoModel: null,
                    gotoRoute: null,
                    showLogin: null,
                    afterLogin: null
                  }
                });
              }
            } else {
              _this.get('router').transitionTo({
                queryParams: {
                  showLogin: null,
                  afterLogin: null,
                  gotoRoute: null,
                  gotoModel: null
                }
              });
            }
          });
        }).then(function () {
          var afterLoginAction = Ember.get(_this, 'afterLoginAction');

          if (afterLoginAction) {
            afterLoginAction();
          }
        }).catch(function (error) {
          _this.set('isLoading', false);

          _this.set('errors', [error]);
        });
      }
    },
    // -------------------------------------------------------------------------
    // Properties

    /**
     * If a loading process is currently happening.
     *
     * @property isLoading
     * @type Boolean
     * @default false
     */
    isLoading: false,

    /**
     * Errors that have happened.
     *
     * @property errors
     * @type Array
     */
    errors: Ember.computed(function () {
      return [];
    }),

    /**
     * The username of the user to login. Is mostly an email, but doesn't have to be.
     *
     * @property username
     * @type String
     */
    username: null,

    /**
     * The password of the user to login.
     *
     * @property password
     * @type String
     */
    password: null,

    /**
     * If the user should stay logged in.
     *
     * @property rememberMe
     * @type Boolean
     * @default true
     */
    rememberMe: true
  });

  _exports.default = _default;
});