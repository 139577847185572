define("@vollersgroup/hub-frontend/components/search-agent-box/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Search agent box for managing search agents in store.
   *
   * ```html
   * {{search-agent-box search=search onSelect=(action "onSelect")}}
   * ```
   *
   * Action signatures:
   *
   * - onSelect(searchAgent)
   *
   * @namespace Component
   * @class SearchAgentBox
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    classNames: ['card', 'filter__box', 'filter__box--open', 'search__agent__box'],
    classNameBindings: ['isLoading:filter__box--loading'],
    // -------------------------------------------------------------------------
    // Dependencies
    userSession: Ember.inject.service(),
    availableData: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Currently selected search agent.
     *
     * @property selected
     * @type Model.SearchAgent
     */
    selected: null,

    /**
     * Disable component while search's busy.
     *
     * @property isLoading
     * @type Boolean
     */
    isLoading: Ember.computed.reads('search.isLoading'),

    /**
     * List of all search agents.
     *
     * @property isLoading
     * @type Model.SearchAgent[]
     */
    searchAgents: Ember.computed.alias('userSession.searchAgents.[]', {
      get: function get() {
        return Ember.get(this, 'userSession.searchAgents');
      }
    }),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Registers search listeners.
     *
     * @public
     * @method init
     * @return {Void}
     */
    init: function init() {
      this._super.apply(this, arguments);

      var search = Ember.get(this, 'search');
      Ember.run.once(this, this._onSearchAgentChanged);
      search.on('reset', this, this._onSearchReset);
      search.on('search_agent_changed', this, this._onSearchAgentChanged);
    },

    /**
     * Unregisters search listeners.
     *
     * @public
     * @method willDestroy
     * @return {Void}
     */
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      var search = Ember.get(this, 'search');
      search.off('reset', this, this._onSearchReset);
      search.off('search_agent_changed', this, this._onSearchAgentChanged);
    },

    /**
     * Unsets `selected` search agent if search was resetted.
     *
     * @private
     * @method _onSearchReset
     * @return {Void}
     */
    _onSearchReset: function _onSearchReset() {
      Ember.set(this, 'selected', null);

      this._sendAction();
    },

    /**
     * Sets `selected` search agent if it has changed.
     *
     * @private
     * @method _onSearchAgentChanged
     * @return {Void}
     */
    _onSearchAgentChanged: function _onSearchAgentChanged() {
      var search = Ember.get(this, 'search');
      var searchAgent = search.getSearchAgent();
      Ember.set(this, 'selected', searchAgent);

      this._sendAction();
    },

    /**
     * Invokes `onSelect` action if provided.
     *
     * @private
     * @method _sendAction
     * @param {Model.SearchAgent} searchAgent
     * @return {Void}
     */
    _sendAction: function _sendAction() {
      var searchAgent = Ember.get(this, 'selected');
      var action = this.attrs.onSelect;

      if (Ember.typeOf(action) === 'function') {
        action(searchAgent);
      }
    },
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Tries to invoke the `onSelect` action.
       *
       * @event update
       * @param {Model.SearchAgent} searchAgent
       * @private
       */
      update: function update(searchAgent) {
        searchAgent = searchAgent || null;
        var search = Ember.get(this, 'search');
        search.setSearchAgent(searchAgent);

        this._sendAction(searchAgent);
      }
    }
  });

  _exports.default = _default;
});