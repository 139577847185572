define("@vollersgroup/hub-frontend/components/validated-form-component/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "44n4lIGi",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"label\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\"],[12,\"class\",[28,[\"label__standard \",[27,\"if\",[[23,[\"hasError\"]],\"label--has-error\"],null]]]],[12,\"for\",[21,\"controlId\"]],[9],[0,\"\\n    \"],[1,[21,\"label\"],false],[0,\"\\n\"],[4,\"if\",[[23,[\"optional\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"label--optional\"],[9],[0,\"\\n        (\"],[1,[27,\"t\",[\"Optional\"],null],false],[0,\")\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"description\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"input__info\"],[9],[0,\"\\n    \"],[1,[21,\"description\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"unless\",[[23,[\"model\",\"isDeleted\"]]],null,{\"statements\":[[0,\"  \"],[14,1,[[23,[\"hasError\"]],[27,\"action\",[[22,0,[]],\"focusOut\"],null]]],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"hasError\"]]],null,{\"statements\":[[0,\"  \"],[7,\"p\"],[11,\"class\",\"input__error-msg\"],[9],[0,\"\\n    \"],[1,[27,\"get\",[[27,\"get\",[[27,\"get\",[[27,\"get\",[[23,[\"model\"]],\"validations\"],null],\"attrs\"],null],[23,[\"valuePath\"]]],null],\"message\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/validated-form-component/template.hbs"
    }
  });

  _exports.default = _default;
});