define("@vollersgroup/hub-frontend/components/c-radio-buttons/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G2rKT5ly",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"each\",[[23,[\"optionsParsed\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[28,[[27,\"if\",[[23,[\"inline\"]],\"input__wrapper--inline\"],null],\" \",[21,\"optionClass\"]]]],[9],[0,\"\\n    \"],[7,\"input\"],[11,\"class\",\"input__radio\"],[12,\"value\",[22,1,[\"value\"]]],[12,\"checked\",[27,\"if\",[[22,1,[\"isSelected\"]],true,false],null]],[12,\"id\",[22,1,[\"elementId\"]]],[12,\"disabled\",[21,\"disabled\"]],[12,\"required\",[21,\"required\"]],[12,\"name\",[21,\"randomId\"]],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"update\"],[[\"value\"],[\"target.value\"]]]],[11,\"type\",\"radio\"],[9],[10],[0,\"\\n\\n    \"],[7,\"label\"],[11,\"class\",\"input__radio--label\"],[12,\"for\",[22,1,[\"elementId\"]]],[9],[0,\"\\n      \"],[1,[27,\"if\",[[22,1,[\"label\"]],[22,1,[\"label\"]],[22,1,[\"value\"]]],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/c-radio-buttons/template.hbs"
    }
  });

  _exports.default = _default;
});