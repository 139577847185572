define("@vollersgroup/hub-frontend/components/manage-producer/component", ["exports", "@vollersgroup/hub-frontend/utils/scroll-into-the-first-error-if-needed", "@vollersgroup/hub-frontend/config/environment", "@vollersgroup/hub-frontend/utils/get-first-adapter-error-message", "@vollersgroup/hub-frontend/location/model"], function (_exports, _scrollIntoTheFirstErrorIfNeeded, _environment, _getFirstAdapterErrorMessage, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A module to create/edit a producer. To create a new producer, create an empty model and pass it to the component.
   *
   * ```html
   * {{manage-producer producer=myProducer action="myAction"}}
   * ```
   */
  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    availableData: Ember.inject.service(),
    toast: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * The producer that should be edited / created.
     *
     * @attribute producer
     * @type Model.Producer
     * @required
     */
    producer: null,

    /**
     * The name of the action to call when the creation / editing of the producer is done.
     * This action receives the edited producer as a single parameter.
     *
     * @attribute action
     * @type string
     * @required
     */
    action: null,
    // -------------------------------------------------------------------------
    // Properties

    /**
     * When this is true, some computation is currently occurring.
     *
     * @property isLoading
     * @type Boolean
     * @private
     */
    isLoading: false,

    /**
     * Flag indicating whether all error messages should be displayed.
     */
    didValidate: false,

    /**
     * The available types for the producer.
     *
     * @property availableTypes
     * @type Array
     * @private
     */
    availableTypes: Ember.computed.alias('availableData.producerTypes'),
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Saves producer if validations are passed.
       *
       * @event submit
       * @private
       */
      submit: function submit() {
        var _this = this;

        var producer = Ember.get(this, 'producer');
        return producer.validate().then(function (_ref) {
          var validations = _ref.validations;

          _this.set('didValidate', true);

          if (!validations.get('isValid')) {
            (0, _scrollIntoTheFirstErrorIfNeeded.default)();
            return Ember.RSVP.Promise.reject();
          }
        }).then(function () {
          var successCallback = function successCallback() {
            Ember.get(_this, 'availableData').notifyPropertyChange('producers');

            _this.sendAction('action', producer);

            Ember.set(_this, 'isLoading', false);
          };

          var failureCallback = function failureCallback(adapterError) {
            var serverErrorMessage = (0, _getFirstAdapterErrorMessage.default)(adapterError);
            var errorMessage = Ember.get(_this, 'l10n').t('An error occurred while trying to save the origin: {{serverErrorMessage}}', {
              serverErrorMessage: serverErrorMessage
            });
            Ember.get(_this, 'toast').error(errorMessage);
          };

          Ember.set(_this, 'isLoading', true);
          return producer.save().then(successCallback).catch(failureCallback);
        });
      },

      /**
       * Upload one or multiple images for the producer.
       *
       * @event uploadProducerImage
       * @param {Object} file
       * @private
       */
      uploadProducerImage: function uploadProducerImage(file) {
        var _this2 = this;

        var successCallback = function successCallback(response) {
          var data = response.body;
          var id = data.id;
          var url = data.url;
          var producer = Ember.get(_this2, 'producer');
          producer.get('images').pushObject(Ember.Object.create({
            id: id,
            fullxfull: url,
            s640x480: url,
            s300x300: url
          }));
        };

        var failureCallback = function failureCallback() {
          var errorMessage = Ember.get(_this2, 'l10n').t('An error occurred while trying to upload the image. Please try again with a different file.');
          Ember.get(_this2, 'toast').error(errorMessage); // Remove file itself from queue so user can try upload again

          file.get('queue').remove(file);
        };

        var headers = {
          'Cropster-Authenticate': 'suppress-www-authenticate'
        };
        var data = {
          entity: 'PRODUCER_IMAGE',
          contentTypeText: true
        };
        var url = "".concat(_environment.default.apiBaseUrl, "/").concat(_environment.default.apiNamespace, "/media");
        file.upload({
          url: url,
          data: data,
          headers: headers
        }).then(successCallback).catch(failureCallback);
      },

      /**
       * Remove an image from the producer images.
       *
       * @event removeImage
       * @param {String} image
       */
      removeImage: function removeImage(image) {
        Ember.get(this, 'producer.images').removeObject(image);
      },

      /**
       * Toggles edit mode for geocoordinates and sets
       * the appropriate status on producer's location.
       *
       * @event toggleGeocoordinates
       * @param {Boolean} checked
       */
      toggleGeocoordinates: function toggleGeocoordinates(checked) {
        var geocodeStatus = checked ? _model.UP_TO_DATE : _model.TO_UPDATE;
        Ember.set(this, 'producer.validateGeolocation', checked);
        Ember.set(this, 'producer.location.geocodeStatus', geocodeStatus);
      },

      /**
       * If user edits either country or region this will lead
       * to geo lookup on API by setting `geocodeStatus` field.
       *
       * @event markForGeocoordinatesLookup
       */
      markForGeocoordinatesLookup: function markForGeocoordinatesLookup() {
        if (this.get('producer.isGeolocationEnteredManually')) {
          return;
        }

        Ember.set(this, 'producer.location.geocodeStatus', _model.TO_UPDATE);
      }
    }
  });

  _exports.default = _default;
});