define("@vollersgroup/hub-frontend/components/draggable-object/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6AvFB7VY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"showHandle\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"isDraggable\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"js-dragHandle no-print cursor-move\"],[9],[0,\"\\n      \"],[1,[27,\"svg-icon\",[\"drag-n-drop\"],[[\"class\"],[\"icon-inline icon--light-grey\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/draggable-object/template.hbs"
    }
  });

  _exports.default = _default;
});