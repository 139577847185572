define("@vollersgroup/hub-frontend/components/sticky-block/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A sticky block which is positioned absolutely until the user scrolled down to its upper border.
   * Then it is made fixed.
   *
   * ```html
   * {{#sticky-block}}
   *     <div class="should-be-sticky">My sticky block.</div>
   * {{/sticky-block}}
   * ```
   *
   * @namespace Component
   * @class StickyBlock
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Element Properties

    /**
     * @property classNameBindings
     * @type Array
     * @final
     * @private
     */
    classNameBindings: [':sticky-block__container'],
    attributeBindings: ['style'],
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * If this is set to true, the min height for the container is not set.
     *
     * @attribute ignoreMinHeight
     * @type Boolean
     * @default false
     * @optional
     */
    ignoreMinHeight: false,
    // -------------------------------------------------------------------------
    // Properties

    /**
     * If the block is currently fixed.
     *
     * @property isFixed
     * @type Boolean
     * @default false
     * @private
     */
    isFixed: false,
    isFixedBottom: false,
    style: Ember.String.htmlSafe('min-height: auto;'),
    // -------------------------------------------------------------------------
    // Methods
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._initialiseScrollListener();
    },
    willDestroyElement: function willDestroyElement() {
      this._unbindScrollListener();

      this._super.apply(this, arguments);
    },

    /**
     * Initialise the scroll listener to check if the block should be absolute or fixed.
     *
     * @method _initialiseScrollListener
     * @private
     */
    _initialiseScrollListener: function _initialiseScrollListener() {
      var _this = this;

      var $el = this.$().find('.sticky-block');
      var elTop = $el.offset().top;
      var elHeight = $el.height(); // Offset of fixed element from top

      var fixedOffset = 20;

      var _scrollFunc = function _scrollFunc() {
        if (_this.get('isDestroyed')) {
          return;
        }

        Ember.run(function () {
          if (elHeight > Ember.$(window).height() - 2 * fixedOffset) {
            _this.set('isFixed', false);

            return;
          }

          if (Ember.$(window).scrollTop() > Ember.$('body').height() - elHeight - fixedOffset) {
            _this.set('isFixedBottom', true);

            return;
          }

          _this.set('isFixedBottom', false);

          if (Ember.$(window).scrollTop() > elTop - fixedOffset) {
            _this.set('isFixed', true);
          } else {
            _this.set('isFixed', false);
          }
        });
      };

      Ember.run.next(function () {
        if (!_this.getAttr('ignoreMinHeight')) {
          _this.set('style', Ember.String.htmlSafe('min-height: ' + elHeight + 'px;'));
        }

        Ember.$(window).bind('scroll', _scrollFunc);

        _scrollFunc();
      });
    },
    _unbindScrollListener: function _unbindScrollListener() {
      Ember.$(window).unbind('scroll');
    }
  });

  _exports.default = _default;
});