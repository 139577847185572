define("@vollersgroup/hub-frontend/validators/unique-auction-slug", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    store: Ember.inject.service(),
    l10n: Ember.inject.service(),
    validate: function validate(slug, options, model, _attribute) {
      var _this = this;

      return this.get('store').query('auction', {
        slug: slug
      }).then(function (auctions) {
        return auctions.get('firstObject');
      }).then(function (auction) {
        var responseId = Ember.get(auction, 'id');
        var auctionId = Ember.get(model, 'id');

        if (!responseId || !auctionId || auctionId !== responseId) {
          return Ember.get(_this, 'l10n').t("The Auction URL '{{slug}}' is already taken.", {
            slug: slug
          });
        }

        return true;
      }) // If server returns NotFound then it's not used
      .catch(function () {
        return true;
      });
    }
  });

  _exports.default = _default;
});