define("@vollersgroup/hub-frontend/components/c-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  /**
   * A simple bound input field
   *
   * ```html
   * {{c-input value=fieldValue id="my_input" required=false class="input-class" placeholder="My placeholder"}}
   * ```
   *
   * @namespace Component
   * @class CInput
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    tagName: '',
    // -------------------------------------------------------------------------
    // Dependencies
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * The id for the input
     *
     * @attribute id
     * @optional
     * @type String
     */
    id: null,

    /**
     * A class string for the input
     *
     * @attribute class
     * @optional
     * @type String
     */
    class: null,

    /**
     * The type of the input field. May be text, email, password, ...
     *
     * @attribute type
     * @type String
     * @default "text"
     */
    type: 'text',

    /**
     * If this input should be automatically focused on render.
     *
     * @attribute autofocus
     * @optional
     * @type Boolean
     * @default false
     */
    autofocus: false,

    /**
     * If this is not false/empty, this field has an error.
     *
     * @attribute hasError
     * @optional
     * @type Boolean
     * @default false
     */
    hasError: false,

    /**
     * If the input is required
     *
     * @attribute required
     * @optional
     * @default false
     * @type Boolean
     */
    required: false,

    /**
     * If the input is disabled
     *
     * @attribute disabled
     * @optional
     * @default false
     * @type Boolean
     */
    disabled: false,

    /**
     * The value of the input
     *
     * @attribute value
     * @required
     * @type String
     */
    _value: '',
    value: Ember.computed('_value', 'type', {
      get: function get() {
        var value = Ember.get(this, '_value');

        if (Ember.get(this, 'type') === 'number') {
          value = parseFloat(value);

          if (isNaN(value)) {
            value = null;
          }
        }

        return value;
      },
      set: function set(key, value) {
        Ember.set(this, '_value', value);
      }
    }),

    /**
     * The placeholder of the input
     * This will automatically be translated!
     *
     * @attribute placeholder
     * @optional
     * @type String
     */
    placeholder: null,

    /**
     * This action is called whenever the input changes.
     * It will receive the updated value as parameter.
     *
     * @attribute action
     * @type String
     * @optional
     */
    action: null,

    /**
     * Step size for numeric inputs. Only
     * applies if `type` is set to "number".
     *
     * @attribute step
     * @required
     * @type Mixed
     * @default "any"
     */
    step: 'any',

    /**
     * Minimun for numeric inputs. Only
     * applies if `type` is set to "number".
     *
     * @attribute min
     * @required
     * @type Number
     * @default 0
     */
    min: 0,

    /**
     * Maximum for numeric inputs. Only
     * applies if `type` is set to "number".
     *
     * @attribute max
     * @required
     * @type Number
     * @default null
     */
    max: null,

    /**
     * Minimum number of characters
     *
     * @attribute minlength
     * @required
     * @type Number
     * @default null
     */
    minlength: null,

    /**
     * Maximum number of characters
     *
     * @attribute maxlength
     * @required
     * @type Number
     * @default null
     */
    maxlength: null,
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Provides updated value with correct type,
       * so if using f.e. type `number`, it will
       * provide the float value for the update.
       *
       * @event update
       * @private
       */
      update: function update() {
        var action = Ember.get(this, 'action');
        var value = Ember.get(this, 'value');

        switch (_typeof(action)) {
          case 'function':
            return action(value);

          case 'string':
            return this.sendAction('action', value);

          default:
            return;
        }
      }
    },
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Reference to <select> as jQuery select.
     *
     * @property select
     * @type {Object}
     * @private
     */
    input: Ember.computed('domId', {
      get: function get()
      /*key*/
      {
        return Ember.$("#".concat(Ember.get(this, 'domId')));
      }
    }),

    /**
     * Calculates dom id for <select> either by given
     * `id` attribute or autoassigned select dom id.
     *
     * @property domId
     * @type {String}
     * @private
     */
    domId: Ember.computed('id', {
      get: function get()
      /*key*/
      {
        var id = Ember.get(this, 'id');

        if (!Ember.isNone(id)) {
          return id;
        }

        id = Ember.guidFor(this);
        return "input-".concat(id);
      }
    }),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Checks if field should be focused.
     *
     * @public
     * @method didRender
     * @return {Void}
     */
    didRender: function didRender() {
      this._super.apply(this, arguments);

      if (!Ember.get(this, 'autofocus')) {
        return;
      }

      var input = Ember.get(this, 'input');
      input.focus();
    }
  });

  _exports.default = _default;
});