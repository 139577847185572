define("@vollersgroup/hub-frontend/components/progress-bar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['h2', 'border-all', 'border-radius-1', 'relative', 'clip'],

    /**
     * Progress value in range 0-100.
     * @number
     */
    progress: null,
    barStyle: Ember.computed('progress', function () {
      var progress = parseInt(Ember.get(this, 'progress'));
      return Ember.String.htmlSafe("width:".concat(progress, "%;"));
    })
  });

  _exports.default = _default;
});