define("@vollersgroup/hub-frontend/page-not-found/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sz6swnj5",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"page-title\",[[27,\"t\",[\"Page not found\"],null]],null],false],[0,\"\\n\\n\"],[7,\"header\"],[11,\"class\",\"\"],[9],[0,\"\\n  \"],[1,[21,\"header-bar\"],false],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"wrapper\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"layout__full\"],[9],[0,\"\\n      \"],[1,[21,\"breadcrumbs-list\"],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"section\"],[11,\"class\",\"section margin-all-0 padding-top-3 padding-bottom-4 section--highlighted flex-grow-1\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"wrapper padding-top-4 text-center\"],[9],[0,\"\\n    \"],[7,\"h1\"],[11,\"class\",\"font-size-3 margin-bottom-4\"],[9],[1,[27,\"t\",[\"Sorry, this page does not exist\"],null],false],[10],[0,\"\\n    \"],[7,\"p\"],[9],[4,\"link-to\",[\"store\"],[[\"class\"],[\"button\"]],{\"statements\":[[1,[27,\"t\",[\"Browse all coffee\"],null],false]],\"parameters\":[]},null],[10],[0,\"\\n    \"],[7,\"p\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"If you can't find what you were looking for, feel free to <a href=\\\"mailto:v-hub@vollers.com\\\">contact us</a> at any time.\"],null],true],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"p\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"You can also browse through our shops and upcoming auctions:\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[4,\"link-to\",[\"store.shops\"],null,{\"statements\":[[1,[27,\"t\",[\"See all shops\"],null],false]],\"parameters\":[]},null],[7,\"br\"],[9],[10],[0,\"\\n    \"],[4,\"link-to\",[\"store.auctions\"],null,{\"statements\":[[1,[27,\"t\",[\"See all auctions\"],null],false]],\"parameters\":[]},null],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/page-not-found/template.hbs"
    }
  });

  _exports.default = _default;
});