define("@vollersgroup/hub-frontend/utils/scroll-into-the-first-error-if-needed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = scrollIntoTheFirstErrorIfNeeded;

  function scrollIntoTheFirstErrorIfNeeded() {
    var querySelector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '.label--has-error';
    Ember.run.next(function () {
      var firstElement = document.querySelector(querySelector);

      if (!firstElement) {
        console.error("[scroll-into-the-first-error-if-needed]: Can't find " + querySelector + '.');
        return;
      }

      if (isElementInViewport(firstElement)) {
        // If it is already in viewport, then do
        // not scroll, it makes bad user experiece.
        return;
      }

      Ember.$('html, body').animate({
        scrollTop: Ember.$(firstElement).offset().top - 20 // Add 20px margin

      }, 200);
    });
  } // Taken from
  // https://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport/7557433#7557433


  function isElementInViewport(el) {
    var rect = el.getBoundingClientRect();
    return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    /*or $(window).height() */
    && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    /*or $(window).width() */
    ;
  }
});