define("@vollersgroup/hub-frontend/rate/route", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var NotFoundError = _emberData.default.NotFoundError;

  var _default = Ember.Route.extend({
    toast: Ember.inject.service(),
    l10n: Ember.inject.service(),
    model: function model(params) {
      var _this = this;

      var rating = this.store.query('listing', {
        encIds: [params.listing_enc_id]
      }).then(function (listings) {
        return Ember.get(listings, 'firstObject');
      }).then(function (listing) {
        if (!listing) {
          throw new NotFoundError();
        }

        return _this.store.createRecord('rating', {
          listing: listing
        });
      });
      return Ember.RSVP.hash({
        rating: rating,
        listingEncId: params.listing_enc_id
      });
    }
  });

  _exports.default = _default;
});