define("@vollersgroup/hub-frontend/admin/manage/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "@vollersgroup/hub-frontend/mixins/reset-scroll"], function (_exports, _authenticatedRouteMixin, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The base route manage.
   *
   * @namespace Route
   * @class Manage
   * @extends Ember.Route
   * @uses Mixin.ResetScroll
   * @uses SimpleAuth.Mixin.AuthenticatedRouteMixin
   */
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _resetScroll.default, {
    // -------------------------------------------------------------------------
    // Dependencies
    availableData: Ember.inject.service(),
    userSession: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Flag if additional assets has
     * already been loaded in app.
     *
     * @property assetsLoaded
     * @type {Boolean}
     * @private
     */
    assetsLoaded: false,
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Load dependencies only needed for this route.
     *
     * @method beforeModel
     * @returns Ember.RSVP.Promise
     */
    beforeModel: function beforeModel() {
      var _this = this;

      if (Ember.get(this, 'assetsLoaded')) {
        return;
      }

      return Ember.RSVP.all([Ember.get(this, 'userSession.currentUser'), Ember.get(this, 'userSession.currentCompany'), Ember.get(this, 'availableData.varieties'), Ember.get(this, 'availableData.descriptors'), Ember.get(this, 'availableData.certifications')]).then(function () {
        _this.set('assetsLoaded', true);
      });
    }
  });

  _exports.default = _default;
});