define("@vollersgroup/hub-frontend/components/auction-terms/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Displays corresponding terms of an auction.
   *
   * @namespace Component
   * @class AuctionTerms
   */
  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * The auction model.
     *
     * @attribute auction
     * @type {Model.Auction}
     */
    auction: null
  });

  _exports.default = _default;
});