define("@vollersgroup/hub-frontend/components/filter-container/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A filter container which automatically renders all filters from the Search service.
   *
   * ```html
   * {{filter-container}}
   * ```
   *
   * @namespace Component
   * @class FilterContainer
   * @extends Component
   */
  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * No wrapping root element.
     *
     * @attribute tagName
     * @type String
     * @final
     * @private
     */
    tagName: '',

    /**
     * Current instance of search.
     *
     * @attribute search
     * @type {Object}
     */
    search: null,

    /**
     * Flag for search agent modal.
     *
     * @attribute searchAgentModal
     * @type {Object}
     */
    searchAgentModal: null,

    /**
     * If filters should be shown on mobile.
     *
     * @attribute showFilters
     * @type Boolean
     * @private
     * @default false
     */
    showFilters: false,

    /**
     * Amount of filters to open after
     * first time loaded from search.
     *
     * @attribute openInitial
     * @type Number
     * @public
     * @default 5
     */
    openInitial: 5,
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Hash to store open filter state.
     *
     * @property openFilters
     * @type Object
     * @private
     */
    openFilters: Ember.computed(function () {
      return [];
    }),

    /**
     * Current search agent id of selection.
     *
     * @property searchAgentId
     * @type {String}
     */
    searchAgentId: null,
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Show the filters. This is only done via
       * CSS and only used for mobile devices.
       *
       * @event toggleFilters
       * @private
       */
      show: function show() {
        this.toggleProperty('showFilters');
      },

      /**
       * Reset all filters.
       *
       * @event reset
       * @private
       */
      reset: function reset() {
        Ember.get(this, 'search').reset();
        Ember.set(this, 'showSearchAgent', false);
      },

      /**
       * Updates filter hashmap with current state.
       *
       * @event toggle
       * @param {Object} filter Corresponding filter.
       * @param {Boolean} state Current open state.
       * @private
       */
      toggle: function toggle(filter, state) {
        var openFilters = Ember.get(this, 'openFilters');
        Ember.set(openFilters, filter.parameterName, state);
      },

      /**
       * Forwards updated item to search service and
       * sends `update` action for parent consumers.
       *
       * @event update
       * @param {Object} filter Group of updated item.
       * @param {Object} item Filter item to update.
       * @private
       */
      update: function update(filter, item) {
        Ember.get(this, 'search').update(filter, item);
        this.sendAction('update', filter, item);
      },

      /**
       * Sets `searchAgentId` property for modal.
       *
       * @event selectSearchAgent
       * @param {Model.SearchAgent} searchAgent
       * @private
       */
      selectSearchAgent: function selectSearchAgent(searchAgent) {
        Ember.set(this, 'searchAgent', searchAgent);
      }
    },
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Adds a `filters_loaded` event waiting for async filters
     * and opens filters initially. Uses `openInitial` to set
     * manipulate the amount of filters to open at start time.
     *
     * @public
     * @method init
     * @return {Void}
     */
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var search = Ember.get(this, 'search');

      if (Ember.isNone(search)) {
        return;
      }

      search.on('filters_loaded', function () {
        var filters = Ember.get(_this, 'search.filters');
        var openFilters = Ember.get(_this, 'openFilters');
        filters.forEach(function (filter, index) {
          var name = filter.parameterName; // open first dynamically depending
          // on `openInitial` property setting

          if (!Ember.get(_this, 'search.filter')) {
            if (index < Ember.get(_this, 'openInitial')) {
              Ember.set(openFilters, name, true);
            }

            return;
          } // otherwise check items if there's
          // at least one active filter item


          var stack = filter.items.slice();

          while (stack.length !== 0) {
            var item = stack.pop();

            if (item.active) {
              Ember.set(openFilters, name, true);
              break;
            }

            if (Ember.isArray(item.items)) {
              stack = stack.concat(item.items);
            }
          }
        });
      });
    },

    /**
     * Removes `filters_loaded` event before destroying.
     *
     * @public
     * @method willDestroyElement
     * @return {Void}
     */
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      Ember.get(this, 'search').off('filters_loaded');
    }
  });

  _exports.default = _default;
});