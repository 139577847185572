define("@vollersgroup/hub-frontend/settings/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var belongsTo = _emberData.default.belongsTo;
  var attr = _emberData.default.attr;
  /**
   * The setting model
   *
   * A simple model holding company wide settings
   * in a nested JSON structure within `contents`.
   *
   * @namespace Model
   * @class Setting
   * @extends Model
   */

  var _default = Model.extend({
    /**
     * Contents contain actual settings
     * object as nested JSON structure.
     *
     * @attribute contents
     * @type Object
     */
    contents: attr({
      defaultValue: function defaultValue() {
        return {};
      }
    }),

    /**
     * If the company has enabled dynamic (C-Market)
     * pricing. This is the overall flag for dynamic
     * prices and will enable the setting of all
     * properties related to it:
     *
     * - `cmarketPrice`: Model.Setting
     * - `usdExchangeRate`: Model.Shop
     * - `priceDynamic`: Model.Listing
     * - `cmarketPremium`: Model.Listing
     *
     * @attribute dynamicPricingEnabled
     * @type Boolean
     */
    dynamicPricingEnabled: attr('boolean', {
      defaultValue: false
    }),

    /**
     * The actual dynamic (C-Market) price for
     * this company. It will be updated by a
     * server-side cronjob daily unless it's
     * not updated manually by a user. NOTE:
     * Manual updates will be overriden by
     * daily cronjob. The current (C-Market)
     * price is retrievable via custom route:
     * /api/misc/latestCmarketPrice
     *
     * @attribute cmarketPriceAmount
     * @type Number
     */
    cmarketPriceAmount: attr('number', {
      defaultValue: 0
    }),

    /**
     * Server-side needs corresponding currency.
     *
     * @attribute cmarketPriceCurrency
     * @type String
     */
    cmarketPriceCurrency: attr('string', {
      defaultValue: 'USD'
    }),

    /**
     * Server-side needs corresponding unit.
     *
     * @attribute cmarketPriceUnit
     * @type String
     */
    cmarketPriceUnit: attr('string', {
      defaultValue: 'LBS'
    }),

    /**
     * If company is enabled to setup shops, which
     * accepts CC payments aka `Express Coffee`.
     *
     * @attribute canReceivePayments
     * @type Boolean
     */
    canReceivePayments: attr('boolean', {
      defaultValue: false
    }),

    /**
     * There's a 1:1 relation to company. All
     * concrete settings are stored in nested
     * JSON structure within `contents`.
     *
     * @attribute company
     * @type Model.Company
     */
    company: belongsTo('company')
  });

  _exports.default = _default;
});