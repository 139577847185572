define("@vollersgroup/hub-frontend/components/order-details/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Display the details for an order.
   *
   * ```html
   * {{order-details order=myOrder}}
   * ```
   *
   * @namespace Component
   * @class OrderDetails
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    /**
     * The order to display the details for
     *
     * @attribute order
     * @type Model.Order
     *
     */
    order: null
  });

  _exports.default = _default;
});