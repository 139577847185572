define("@vollersgroup/hub-frontend/admin/manage/listing/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Current sub step of route.
     *
     * @public
     * @property step
     * @type Number
     * @default 0
     */
    step: 0,

    /**
     * All steps for the creation process.
     *
     * @property allSteps
     * @type Array
     */
    allSteps: Ember.computed('availableShops.length', 'l10n', {
      get: function get()
      /*key*/
      {
        var steps = [];
        steps.push({
          label: this.get('l10n').t('Edit coffee'),
          step: 0,
          subSteps: [{
            label: this.get('l10n').t('Base information'),
            anchor: '#listing-manage-1'
          }, {
            label: this.get('l10n').t('Details'),
            anchor: '#listing-manage-2'
          }, {
            label: this.get('l10n').t('Sensorial & Cupping'),
            anchor: '#listing-manage-3'
          }]
        });
        steps.push({
          label: 'Producer',
          step: 1,
          subSteps: []
        });
        steps.push({
          label: 'Publish',
          step: 2,
          subSteps: []
        });
        return steps;
      }
    })
  });

  _exports.default = _default;
});