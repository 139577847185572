define("@vollersgroup/hub-frontend/components/listing-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Display the given listings in a table.
   *
   * ```hbs
   * {{listing-table listings=myListings publishListing=(action "publishListings")}}
   * ```
   *
   * @namespace Component
   * @class ListingTable
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    /**
     * The listings to display in the table.
     *
     * @attribute listings
     * @type Model.Listing[]
     */
    listings: null,

    /**
     * Currently active listings as hashmap,
     * where all selected ids are persisted.
     *
     * @attribute selectedMap
     * @type {Object}
     */
    selectedMap: Ember.computed({
      get: function get()
      /*key*/
      {
        return {};
      }
    }),

    /**
     * Hash map keeping track of ratings.
     *
     * @attribute ratingsMap
     * @type {Object}
     */
    ratingsMap: Ember.computed({
      get: function get()
      /*key*/
      {
        return {};
      }
    }),

    /**
     * This action is called when orderBy is updated.
     *
     * @attribute orderByUpdated
     * @type Function
     */
    orderByUpdated: null,

    /**
     * Publish a listing. This sets the state to ACTIVE.
     * The company pays for this. This is only possible coming from DRAFT or INACTIVE.
     *
     * @attribute publishListing
     * @param Model.Listing listing
     */
    publishListing: null,

    /**
     * Deactivate a listing. This sets the state to INACTIVE.
     * This is only possible coming from ACTIVE or EXPIRED.
     *
     * @attribute deactivateListing
     * @param Model.Listing listing
     */
    deactivateListing: null,

    /**
     * Delete a listing. This actually deletes the listing. It is not recoverable.
     * This is only possible coming from DRAFT.
     *
     * @attribute deleteListing
     * @param Model.Listing listing
     */
    deleteListing: null,

    /**
     * Archive a listing. This sets the archived flag.
     * This is only possible coming from INACTIVE, SOLD_OUT and EXPIRED.
     *
     * @attribute archiveListing
     * @param Model.Listing listing
     */
    archiveListing: null,

    /**
     * Unarchive a listing. This resets the archived flag. This is only possible if it's currently set.
     *
     * @attribute archiveListing
     * @param Model.Listing listing
     */
    unarchiveListing: null,

    /**
     * Renew an expired listing. The company is billed for this.
     * This is only possible coming from EXPIRED.
     *
     * @attribute renewListing
     * @param Model.Listing listing
     */
    renewListing: null,

    /**
     * Edit an expired listing before re-publishing it.
     * This sets the state to INACTIVE and redirects to the edit screen for this listing.
     * This is only possible coming from EXPIRED.
     *
     * @attribute editRenewListing
     * @param Model.Listing listing
     */
    editRenewListing: null,
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Invokes 'orderByUpdated' action.
       *
       * @event orderBy
       * @param {String} orderBy
       * @private
       */
      orderBy: function orderBy(_orderBy) {
        try {
          this.attrs.orderByUpdated(_orderBy);
        } catch (e) {
          /* noop */
        }
      },

      /**
       * Invokes 'selectMultiple' action.
       *
       * @event toggleSelect
       * @param {Model.Listing} listing
       * @param {Boolean} checked
       * @param {Boolean} [triggerAction=true]
       * @private
       */
      toggleSelect: function toggleSelect(listing, checked) {
        var triggerAction = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

        if (!Ember.get(listing, 'isEditable')) {
          return;
        }

        var map = Ember.get(this, 'selectedMap');
        var id = Ember.get(listing, 'id');

        if (checked) {
          Ember.set(map, id, listing);
        } else {
          Ember.set(map, id, null);
          delete map[id];
        }

        if (triggerAction) {
          try {
            this.attrs.selectMultiple(map);
          } catch (e) {
            /* noop */
          }
        }
      },

      /**
       * (Un)checks all items and invokes 'selectMultiple' action.
       *
       * @event toggleSelectAll
       * @param {Boolean} checked
       * @private
       */
      toggleSelectAll: function toggleSelectAll(checked) {
        var _this = this;

        Ember.get(this, 'listings').forEach(function (listing) {
          _this.send('toggleSelect', listing, checked, false);
        });

        try {
          this.attrs.selectMultiple(Ember.get(this, 'selectedMap'));
        } catch (e) {
          /* noop */
        }
      }
    }
  });

  _exports.default = _default;
});