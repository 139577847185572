define("@vollersgroup/hub-frontend/services/user-session", ["exports", "ember-data", "moment", "@sentry/ember"], function (_exports, _emberData, _moment, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PromiseArray = _emberData.default.PromiseArray;
  /**
   * This session contains information about the current user.
   * It also holds localisation data, e.g. the date format.
   *
   * @namespace Service
   * @class UserSession
   * @extends Service
   * @uses Store
   * @uses EmberSimpleAuth.Service.Session
   *
   */

  var _default = Ember.Service.extend({
    l10n: Ember.inject.service(),
    store: Ember.inject.service(),
    moment: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    availableData: Ember.inject.service(),

    /**
     * The current user. Is null if not logged in.
     *
     * @property currentUser
     * @type Model.User|null
     * @readOnly
     */
    currentUser: null,

    /**
     * The current user's company. Is null if not logged in.
     *
     * @property currentCompany
     * @type Model.Company|null
     * @readOnly
     */
    currentCompany: Ember.computed.alias('currentUser.company'),

    /**
     * Indicates whether user is authenticated.
     */
    isAuthenticated: Ember.computed.readOnly('session.isAuthenticated'),

    /**
     * The date format to use. Takes the date format from the user, or falls back to a default format.
     * This has to be a moment.js compatible format string.
     *
     * @property dateFormat
     * @type String
     * @default "ll"
     * @readOnly
     */
    dateFormat: Ember.computed('currentUser.dateFormat', function () {
      if (this.get('currentUser')) {
        return this.get('currentUser.dateFormat');
      }

      return 'll';
    }),

    /**
     * The time format to use. Takes the date format from the user, or falls back to a default format.
     * This has to be a moment.js compatible format string.
     *
     * @property timeFormat
     * @type String
     * @default "HH:mm"
     * @readOnly
     */
    timeFormat: Ember.computed('currentUser.timeFormat', function () {
      if (this.get('currentUser')) {
        return this.get('currentUser.timeFormat');
      }

      return 'HH:mm';
    }),

    /*
     * Returns true if user's time format is 12h instead of 24h.
     */
    has12HourTimeFormat: Ember.computed.equal('timeFormat', 'hh:mm a'),

    /**
     * The full date format to use. Is auto build from the date & time format.
     *
     * @property fullDateFormat
     * @type String
     * @readOnly
     * @default "ll - HH:mm"
     */
    fullDateFormat: Ember.computed('dateFormat', 'timeFormat', function () {
      return this.get('dateFormat') + ' - ' + this.get('timeFormat');
    }),

    /**
     * The language to use. Takes the language from the current user,
     * or falls back to default language set in l10n service.
     *
     * @property language
     * @type String
     * @readOnly
     * @default "en"
     */
    language: Ember.computed('l10n.locale', 'currentUser.locale', function () {
      if (!Ember.isNone(this.get('currentUser.locale'))) {
        return this.get('currentUser.locale');
      }

      return this.get('l10n.locale');
    }),

    /**
     * The culture to use.
     *
     * @property language
     * @type String
     * @readOnly
     * @default "en-GB"
     */
    culture: Ember.computed.reads('l10n.culture'),

    /**
     * The time zone to use. Takes the time zone
     * from the current user, or tries to determine it.
     *
     * @property timezone
     * @type String
     * @readOnly
     */
    timezone: Ember.computed('currentUser.timezone', function () {
      return this.get('currentUser.timezone') || _moment.default.tz.guess();
    }),

    /**
     * All search agents from the current user session.
     *
     * @property searchAgents
     * @type Model.SearchAgent[]
     */
    searchAgents: Ember.computed('currentUser', 'store', {
      get: function get() {
        var currentUser = this.get('currentUser');

        if (Ember.isNone(currentUser)) {
          return PromiseArray.create({
            promise: Ember.RSVP.all([])
          });
        }

        return this.get('store').findAll('search-agent', {
          reload: true
        });
      }
    }),

    /**
     * The auction registrations for the current user's company.
     *
     * @property auctionRegistrations
     * @type Model.AuctionParticipant[]
     */
    auctionRegistrations: Ember.computed('currentCompany.id', 'store', function () {
      if (!this.get('currentCompany.id')) {
        return PromiseArray.create({
          promise: Ember.RSVP.all([])
        });
      }

      return this.get('store').query('auction-participant', {
        participatingCompany: this.get('currentCompany.id')
      });
    }),
    hasShop: Ember.computed.gt('availableData.shops.length', 0),
    _setMomentTimeZone: Ember.observer('currentUser.timezone', function () {
      // momentService -> ember-moment (used in templates with helpers)
      // moment -> global moment (used mainly in app JS code)
      var momentService = Ember.get(this, 'moment');
      var zone = Ember.get(this, 'timezone');
      momentService.changeTimeZone(zone);

      _moment.default.tz.setDefault(zone);
    }),
    _setMomentDefaultFormat: Ember.observer('fullDateFormat', function () {
      var format = Ember.get(this, 'fullDateFormat');
      var service = Ember.get(this, 'moment');
      Ember.set(service, 'defaultFormat', format);
      Ember.set(_moment.default, 'defaultFormat', format);
    }),
    _setMomentLocale: Ember.observer('l10n.locale', function () {
      var locale = Ember.get(this, 'l10n.locale');
      var service = Ember.get(this, 'moment');
      service.changeLocale(locale);

      _moment.default.locale(locale);
    }),
    loggedIn: function loggedIn(user) {
      this.set('currentUser', user);
      Sentry.setUser({
        id: Ember.get(user, 'id'),
        role: Ember.get(user, 'role'),
        company: Ember.get(user, 'companyId')
      });
      Sentry.setContext('culture', {
        locale: Ember.get(user, 'locale'),
        timezone: Ember.get(user, 'timezone')
      });
    },
    logout: function logout() {
      var _this = this;

      this.get('router').transitionTo('loading').then(function () {
        _this.get('session').invalidate();

        Sentry.setUser(null);
      });
    }
  });

  _exports.default = _default;
});