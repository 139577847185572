define("@vollersgroup/hub-frontend/components/pricing-plans/feature-comparison/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lga/BwuU",
    "block": "{\"symbols\":[\"@currentPlan\",\"@plans\",\"@features\"],\"statements\":[[1,[27,\"pricing-plans/feature-comparison/mobile\",null,[[\"features\",\"plans\",\"currentPlan\"],[[22,3,[]],[22,2,[]],[22,1,[]]]]],false],[0,\"\\n\\n\"],[1,[27,\"pricing-plans/feature-comparison/desktop\",null,[[\"features\",\"plans\",\"currentPlan\"],[[22,3,[]],[22,2,[]],[22,1,[]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/pricing-plans/feature-comparison/template.hbs"
    }
  });

  _exports.default = _default;
});