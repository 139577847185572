define("@vollersgroup/hub-frontend/utils/normalize-for-slug", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = normalizeForSlug;

  function normalizeForSlug(name) {
    if (!name || Ember.typeOf(name) != 'string') {
      return '';
    }

    var forbiddenChars = /[^a-z0-9-]/g;
    return name.toLowerCase() // Remove anything except lowercase a-z 0-9 and dashes
    .replace(forbiddenChars, '-') // Replace multiple dashes with one dash
    .replace(/-+/g, '-') // Remove leanding and ending dash
    .replace(/^-/, '').replace(/-$/, '');
  }
});