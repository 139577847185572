define("@vollersgroup/hub-frontend/auction-participant/model", ["exports", "ember-data", "@vollersgroup/hub-frontend/custom-objects/address-object"], function (_exports, _emberData, _addressObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var belongsTo = _emberData.default.belongsTo;
  var attr = _emberData.default.attr;
  var PromiseObject = _emberData.default.PromiseObject;
  /**
   * A registration for an auction.
   *
   * @namespace Model
   * @class AuctionParticipant
   * @extends Model
   */

  var _default = Model.extend({
    userSession: Ember.inject.service(),
    store: Ember.inject.service(),

    /**
     * A randomized company number for this auction.
     *
     * @attribute companyHash
     * @type String
     */
    companyHash: attr('string'),

    /**
     * The status of the registration.
     *
     * @attribute registrationStatus
     * @type PENDING|ACCEPTED|REJECTED
     */
    registrationStatus: attr('string', {
      default: 'PENDING'
    }),

    /**
     * The date and time when registration was requested.
     *
     * @attribute registrationDate
     * @type Date
     */
    registrationDate: attr('timestamp'),

    /**
     * The email of the user who registered. Only visible for the auctioneer.
     *
     * @attribute email
     * @type String
     */
    userEmail: attr('string'),

    /**
     * Optional notes from auctioneer for this participant.
     *
     * @attribute auctioneerNotes
     * @type String
     * @optional
     */
    auctioneerNotes: attr('string'),

    /**
     * The shipping address of the order.
     *
     * @attribute shippingAddress
     * @type CustomObject.AddressObject
     * @optional
     */
    shippingAddress: attr('address', {
      defaultValue: function defaultValue() {
        return _addressObject.default.create();
      }
    }),

    /**
     * Information about importer when requesting sample
     * bundles during registration. (e.g.: "SELF_IMPORT")
     *
     * @attribute importerRequirement
     * @type String
     */
    importerRequirement: attr('string'),

    /**
     * The name for the importer if `importerRequirement` is "IMPORTER_PROVIDED"
     *
     * @attribute email
     * @type String
     */
    importerName: attr('string'),

    /**
     * The auction this registration belongs to.
     *
     * @attribute auction
     * @type Model.Auction
     */
    auction: belongsTo('auction', {
      async: true
    }),

    /**
     * The company who is registered.
     *
     * @attribute company
     * @type Model.Company
     */
    company: belongsTo('company', {
      async: true
    }),

    /**
     * The user of the company who initiated the registration.
     *
     * @attribute user
     * @type Model.User
     */
    user: belongsTo('user', {
      async: true
    }),
    isAccepted: Ember.computed.equal('registrationStatus', 'ACCEPTED'),
    isPending: Ember.computed.equal('registrationStatus', 'PENDING'),
    isRejected: Ember.computed.equal('registrationStatus', 'REJECTED'),

    /*
     * Returns true when someone from current's participant company is online.
     */
    isSomeoneFromCompanyOnline: Ember.computed('auction.onlineUsers.[]', 'company.id', function () {
      var companyId = Ember.get(this, 'company.id');
      return Ember.get(this, 'auction.onlineUsers').any(function (user) {
        return user.get('company.id') === companyId;
      });
    }),

    /**
     * Order of samples by participant for this auction.
     *
     * @property samplesOrder
     * @type {Model.Order}
     */
    samplesOrder: Ember.computed('auction.id', 'company.id', 'store', 'userSession.currentCompany.id', function () {
      return PromiseObject.create({
        promise: this.store.query('order', {
          sellerCompany: this.get('userSession.currentCompany.id'),
          buyerCompany: this.get('company.id'),
          auction: this.get('auction.id')
        }).then(function (orders) {
          return orders.get('firstObject');
        })
      });
    }),

    /**
     * Amount of sample bundles ordered by participant for this auction.
     *
     * @property sampleBundlesOrderedAmount
     * @type {Number}
     */
    sampleBundlesOrderedAmount: Ember.computed('samplesOrder.orderItemsSample.firstObject.initialAmount', function () {
      return Ember.get(this, 'samplesOrder.orderItemsSample.firstObject.initialAmount') || 0;
    }),

    /**
     * All bids by participant's company.
     */
    allParticipantBids: Ember.computed('auction.allAuctionBids.[]', 'companyHash', function () {
      var companyHash = Ember.get(this, 'companyHash');
      return Ember.get(this, 'auction.allAuctionBids').filterBy('participantName', companyHash);
    }),

    /**
     * All listings that this company bids on.
     */
    bidListings: Ember.computed('allParticipantBids.[]', function () {
      return Ember.get(this, 'allParticipantBids').mapBy('listing') // Might bid on some listings multiple times
      .uniqBy('id');
    }),

    /**
     * Bids which are currently highest bids by participant's company.
     */
    highestBids: Ember.computed('allParticipantBids.[]', function () {
      return Ember.get(this, 'allParticipantBids').filter(function (bid) {
        return bid.get('id') === bid.get('listing.highestBid.id');
      });
    }),

    /**
     * Listings which are currently highest bids by participant's company.
     */
    highestBidListings: Ember.computed.mapBy('highestBids', 'listing'),

    /**
     * Current total price of all highest bids by participant's company.
     */
    totalParticipantPrice: Ember.computed('highestBidListings.@each.totalPrice', function () {
      return Ember.get(this, 'highestBidListings').mapBy('totalPrice').reduce(function (sum, price) {
        return sum + price;
      }, 0).toFixed(2) * 1;
    })
  });

  _exports.default = _default;
});