define("@vollersgroup/hub-frontend/store/shop/controller", ["exports", "@vollersgroup/hub-frontend/mixins/search"], function (_exports, _search) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_search.SearchControllerMixin, {
    // -------------------------------------------------------------------------
    // Dependencies
    toast: Ember.inject.service(),
    userSession: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Query parameters.
     *
     * @final
     * @public
     * @type {Array}
     */
    queryParams: ['followShop'],

    /**
     * If user follows this shop
     *
     * @property followShop
     * @public
     * @type Number
     * @default 0
     */
    followShop: 0,

    /**
     * Ordering type. Can be one of
     * "DEFAULT", "DATE" or "SCORE".
     *
     * @property orderBy
     * @public
     * @type String
     * @default "DEFAULT"
     */
    orderBy: 'DATE',

    /**
     * Search agent matching both `follow` with `SHOP`
     * and current's shop id within `listingFilter.shop`.
     *
     * @public
     * @property followAgent
     * @type {Model.SearchAgent}
     */
    followAgent: Ember.computed('model.id', 'userSession.searchAgents.[]', {
      get: function get() {
        var _this = this;

        var searchAgents = Ember.get(this, 'userSession.searchAgents');
        return searchAgents.find(function (searchAgent) {
          var shopId = Ember.get(searchAgent, 'listingFilter.shop');
          var modelId = Ember.get(_this, 'model.id');

          switch (Ember.get(searchAgent, 'follow')) {
            case 'SHOP':
              /*jslint eqeq: true*/
              return modelId == shopId;

            default:
              return false;
          }
        });
      }
    }),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Tries to create a new follow agent for
     * this shop with `follow` flag set `SHOP`
     * and sets `followAgent` on success.
     *
     * @private
     * @method _followShop
     * @return {RSVP.Promise}
     */
    _followShop: function _followShop() {
      var _this2 = this;

      var followAgent = Ember.get(this, 'followAgent');

      if (!Ember.isNone(followAgent)) {
        return;
      }

      var model = Ember.get(this, 'model');

      var successCallback = function successCallback() {
        var successMessage = Ember.get(_this2, 'l10n').t('You are following this shop now and will be notified whenever new coffees are available!');
        Ember.get(_this2, 'toast').success(successMessage);
      };

      var failureCallback = function failureCallback(error) {
        var serverErrorMessage = Ember.get(error, 'payload.error');
        var errorMessage = Ember.get(_this2, 'l10n').t('An error occurred while following this shop: {{serverErrorMessage}}', {
          serverErrorMessage: serverErrorMessage
        });
        Ember.get(_this2, 'toast').error(errorMessage);
      };

      var finallyCallback = function finallyCallback() {
        Ember.set(_this2, 'followShop', 0);
      };

      var _Ember$getProperties = Ember.getProperties(model, ['id', 'name']),
          id = _Ember$getProperties.id,
          name = _Ember$getProperties.name;

      name = Ember.get(this, 'l10n').t('{{name}} (Follow shop)', {
        name: name
      });
      var searchAgent = this.store.createRecord('search-agent', {
        listingFilter: {
          shop: id
        },
        follow: 'SHOP',
        name: name
      });
      return searchAgent.save().then(successCallback).catch(failureCallback).finally(finallyCallback);
    },

    /**
     * Tries to remove current `followAgent` by
     * deleting search agent from database.
     *
     * @private
     * @method _unfollowShop
     * @return {RSVP.Promise}
     */
    _unfollowShop: function _unfollowShop() {
      var _this3 = this;

      var followAgent = Ember.get(this, 'followAgent');

      if (Ember.isNone(followAgent)) {
        return;
      }

      var successCallback = function successCallback() {
        var successMessage = Ember.get(_this3, 'l10n').t("You are not following this shop anymore and won't receive any updates on new coffees!");
        Ember.get(_this3, 'toast').success(successMessage);
      };

      var failureCallback = function failureCallback(error) {
        var serverErrorMessage = Ember.get(error, 'payload.error');
        var errorMessage = Ember.get(_this3, 'l10n').t('An error occurred while unfollowing this shop: {{serverErrorMessage}}', {
          serverErrorMessage: serverErrorMessage
        });
        Ember.get(_this3, 'toast').error(errorMessage);
      };

      var finallyCallback = function finallyCallback() {
        Ember.set(_this3, 'followShop', 0);
      };

      var promise = followAgent.destroyRecord();
      promise.then(successCallback, failureCallback).finally(finallyCallback);
      return promise;
    },
    // -------------------------------------------------------------------------
    // Observers

    /**
     * Observes `followShop` for changes
     * as this feature requires a logged
     * in user in order to manage search
     * agents for (un)following this shop.
     *
     * @type observer _watchFollowShop
     * @private
     */
    _watchFollowShop: Ember.observer('followShop', function () {
      var _this4 = this;

      Ember.run.scheduleOnce('routerTransitions', this, function () {
        switch (Ember.get(_this4, 'followShop')) {
          case 2:
            _this4._unfollowShop();

            break;

          case 1:
            _this4._followShop();

            break;

          default:
        }
      });
    })
  });

  _exports.default = _default;
});