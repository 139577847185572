define("@vollersgroup/hub-frontend/admin/sales/list/coffee/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    activate: function activate() {
      this._super.apply(this, arguments);

      var parentController = this.controllerFor('admin.sales.list');
      Ember.set(parentController, 'mode', 'COFFEE');
    }
  });

  _exports.default = _default;
});