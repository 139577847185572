define("@vollersgroup/hub-frontend/helpers/pt", ["exports", "ember-l10n/helpers/pt"], function (_exports, _pt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _pt.default;
    }
  });
  Object.defineProperty(_exports, "pt", {
    enumerable: true,
    get: function get() {
      return _pt.pt;
    }
  });
});