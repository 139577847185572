define("@vollersgroup/hub-frontend/admin/wishlist/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    toast: Ember.inject.service(),
    keenTracking: Ember.inject.service(),
    actions: {
      /**
       * Removes wish list item from user's collection.
       *
       * @event removeFromWishlist
       * @private
       */
      removeFromWishlist: function removeFromWishlist(wishlistItem) {
        var _this = this;

        var _successCallback = function _successCallback() {
          var message = Ember.get(_this, 'l10n').t('"{{coffeeName}}" was removed from your wish list.', {
            coffeeName: Ember.get(wishlistItem, 'listing.name')
          });
          Ember.get(_this, 'toast').success(message);
          Ember.get(_this, 'keenTracking').addEvent('remove-from-wishlist-on-listing-item', {
            listingName: Ember.get(wishlistItem, 'listing.name'),
            listingId: Ember.get(wishlistItem, 'listing.id')
          });
        };

        var _failureCallback = function _failureCallback() {
          var message = Ember.get(_this, 'l10n').t('Could not remove "{{coffeeName}}" to your wish list. Please, try it again in a moment.', {
            coffeeName: Ember.get(wishlistItem, 'listing.name')
          });
          Ember.get(_this, 'toast').error(message);
        };

        wishlistItem.destroyRecord().then(_successCallback, _failureCallback);
      }
    }
  });

  _exports.default = _default;
});