define("@vollersgroup/hub-frontend/components/auction-delete-bid/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['inline-block'],
    l10n: Ember.inject.service(),
    ajax: Ember.inject.service(),
    toast: Ember.inject.service(),
    listing: null,
    showModal: false,
    canDelete: Ember.computed.reads('listing.canDeleteBid'),
    actions: {
      deleteBid: function deleteBid(closeAction) {
        var _this = this;

        var canDelete = Ember.get(this, 'canDelete');
        var listing = Ember.get(this, 'listing');
        var toast = Ember.get(this, 'toast');
        var ajax = Ember.get(this, 'ajax');
        var l10n = Ember.get(this, 'l10n');

        if (!canDelete) {
          return;
        }

        var sucessCallback = function sucessCallback(response) {
          var autoBid = response.restoredAutoBid;
          Ember.set(_this, 'listing.currentAutoBid', autoBid); // important to reload listing for new `bidCount`
          // and invalidate `allAuctionBids` for updating
          // admin view with all the auction participants

          Ember.get(listing, 'auction').then(function (auction) {
            listing.reload().then(function (listing) {
              listing.hasMany('bids').reload().then(function () {
                auction.notifyPropertyChange('allAuctionBids');
                auction.notifyPropertyChange('auctionRegistrations');
              });
            });
          });
          var message = l10n.t('Last bid deleted.');
          toast.info(message);
          closeAction();
        };

        var failureCallback = function failureCallback() {
          var message = l10n.t('There was an error while deleting last bid.');
          toast.error(message);
        };

        ajax.delete('/autoBid/listing/' + listing.id).then(sucessCallback, failureCallback);
      }
    }
  });

  _exports.default = _default;
});