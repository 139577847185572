define("@vollersgroup/hub-frontend/components/validated-form-component/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['input__wrapper'],
    controlId: null,
    model: null,
    valuePath: '',
    validation: null,
    showValidations: false,
    didValidate: false,
    label: null,
    description: null,
    optional: false,
    useClosureActions: false,
    hasContent: Ember.computed.notEmpty('value').readOnly(),
    isValid: Ember.computed.and('hasContent', 'validation.isTruelyValid').readOnly(),
    shouldDisplayValidations: Ember.computed.or('showValidations', 'didValidate').readOnly(),
    hasError: Ember.computed.and('shouldDisplayValidations', 'validation.isInvalid').readOnly(),
    init: function init() {
      this._super.apply(this, arguments);

      var valuePath = this.get('valuePath');
      Ember.defineProperty(this, 'validation', Ember.computed.readOnly("model.validations.attrs.".concat(valuePath)));
      Ember.defineProperty(this, 'value', Ember.computed.alias("model.".concat(valuePath))); // Once the value has a content, we want to show
      // validations immediatelly when there is an error

      if (Ember.get(this, 'hasContent')) {
        Ember.set(this, 'showValidations', true);
      }
    },
    actions: {
      focusOut: function focusOut() {
        this.set('showValidations', true);
      }
    },
    focusOut: function focusOut() {
      this._super.apply(this, arguments);

      if (!this.useClosureActions) {
        this.set('showValidations', true);
      }
    }
  });

  _exports.default = _default;
});