define("@vollersgroup/hub-frontend/components/message-list-item-small/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mZi5C0O6",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[23,[\"conversation\",\"order\"]]],null,{\"statements\":[[4,\"link-to\",[\"admin.messages.show\",[23,[\"conversation\"]]],[[\"class\"],[\"truncate\"]],{\"statements\":[[0,\"    (\"],[1,[23,[\"conversation\",\"ownUnreadCount\"]],false],[0,\") \"],[1,[23,[\"conversation\",\"otherCompany\",\"name\"]],false],[0,\"\\n    - \"],[1,[23,[\"conversation\",\"subject\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"link-to\",[\"admin.orders.show\",[23,[\"conversation\",\"order\"]]],[[\"class\"],[\"truncate\"]],{\"statements\":[[0,\"    (\"],[1,[23,[\"conversation\",\"ownUnreadCount\"]],false],[0,\") \"],[1,[23,[\"conversation\",\"otherCompany\",\"name\"]],false],[0,\"\\n    - \"],[1,[23,[\"conversation\",\"subject\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/message-list-item-small/template.hbs"
    }
  });

  _exports.default = _default;
});