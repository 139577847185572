define("@vollersgroup/hub-frontend/components/order-header/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    l10n: Ember.inject.service(),
    userSession: Ember.inject.service(),
    orderManager: Ember.inject.service(),
    messageCenter: Ember.inject.service(),

    /**
     * The order to display the header for.
     *
     * @attribute order
     * @type Model.Order
     */
    order: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._markConversationAsRead();
    },
    actions: {
      updateDocuments: function updateDocuments(files) {
        this.get('orderManager').updateDocuments(this.getAttr('order'), files);
      },

      /**
       * Reply to the conversation.
       *
       * @event reply
       * @param message
       * @private
       */
      reply: function reply(message) {
        var selectedConversation = this.getAttr('order').get('conversation');
        var createdMessage = this.get('store').createRecord('message', {
          text: message,
          created: (0, _moment.default)(),
          fromCompany: this.get('userSession.currentUser.company'),
          fromUser: this.get('userSession.currentUser'),
          conversation: selectedConversation
        });
        createdMessage.save();
        this.set('showMessages', true);
      },

      /**
       * Show/Hide the message thread.
       *
       * @event toggleMessages
       * @private
       */
      toggleMessages: function toggleMessages() {
        this.toggleProperty('showMessages');
      }
    },

    /**
     * The name of the seller. This is either "you" or the name of the company.
     *
     * @property seller
     * @type String
     * @private
     */
    seller: Ember.computed('l10n', 'order.sellerCompany.id', 'order.shop.name', 'userSession.currentCompany.id', function () {
      if (this.get('userSession.currentCompany.id') + '' === this.getAttr('order').get('sellerCompany.id') + '') {
        return this.get('l10n').t('you');
      }

      return this.getAttr('order').get('sellerCompany.name');
    }),

    /**
     * The name of the buyer. This is either "you" or the name of the company.
     *
     * @property buyer
     * @type String
     * @private
     */
    buyer: Ember.computed('l10n', 'order.buyerCompany.name', 'userSession.currentCompany.id', function () {
      if (this.get('userSession.currentCompany.id') + '' === this.getAttr('order').get('buyerCompany.id') + '') {
        return this.get('l10n').t('you');
      }

      return this.getAttr('order').get('buyerCompany.name');
    }),

    /**
     * If the messages should be displayed or not.
     *
     * @property showMessages
     * @type Boolean
     * @private
     */
    showMessages: true,
    _markConversationAsRead: function _markConversationAsRead() {
      var _this = this;

      Ember.run.next(function () {
        if (_this.get('order.conversation.id')) {
          _this.get('messageCenter').markConversationRead(_this.get('order.conversation'));
        }
      });
    }
  });

  _exports.default = _default;
});