define("@vollersgroup/hub-frontend/components/api-data-viewer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7p3Dj40S",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[4,\"if\",[[24,2]],null,{\"statements\":[[7,\"div\"],[11,\"class\",\"margin-bottom-2\"],[9],[0,\"\\n  \"],[14,2],[0,\"\\n\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"filterable\"]]],null,{\"statements\":[[7,\"div\"],[11,\"class\",\"margin-bottom-3\"],[9],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"value\",\"class\",\"placeholder\"],[[23,[\"filterTerm\"]],\"input__txt\",[23,[\"filterPlaceholder\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[27,\"or\",[[23,[\"filteredItems\"]],[23,[\"isLoading\"]]],null]],null,{\"statements\":[[7,\"ul\"],[11,\"class\",\"tag-list\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"filteredItems\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[9],[1,[27,\"get\",[[22,1,[]],[23,[\"labelProperty\"]]],null],true],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"info-message\",null,[[\"class\",\"type\"],[\"margin-bottom-2\",\"WARNING\"]],{\"statements\":[[0,\"    \"],[1,[27,\"t\",[\"No items found for \\\"{{searchText}}\\\"!\"],[[\"searchText\"],[[23,[\"filterTerm\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/api-data-viewer/template.hbs"
    }
  });

  _exports.default = _default;
});