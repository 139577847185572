define("@vollersgroup/hub-frontend/components/draggable-object/component", ["exports", "ember-drag-drop/components/draggable-object"], function (_exports, _draggableObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Overrides only draggable-object template to show handle with tooltip.
   *
   * @namespace Component
   * @class DraggableObject
   * @extens DraggableObject
   */
  var _default = _draggableObject.default.extend({
    classNameBindings: ['isDraggable:is-draggable:is-not-draggable'],
    showHandle: true
  });

  _exports.default = _default;
});