define("@vollersgroup/hub-frontend/order/serializer", ["exports", "@vollersgroup/hub-frontend/application/serializer"], function (_exports, _serializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _serializer.default.extend({
    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      var key = relationship.key;

      if (Ember.typeOf(json[key]) === 'undefined') {
        var ids = snapshot.hasMany(key, {
          ids: true
        });
        ids = ids.filter(function (id) {
          return id;
        });
        json[key] = ids;
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});