define("@vollersgroup/hub-frontend/admin/company/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9zaRpyjC",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"page-title\",[[27,\"t\",[\"Company\"],null]],null],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"fg-container\"],[9],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"fg-row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"fg-column-12 fg-column-2-l\"],[9],[0,\"\\n      \"],[7,\"ul\"],[11,\"class\",\"third-nav\"],[9],[0,\"\\n        \"],[7,\"li\"],[9],[4,\"link-to\",[\"admin.company.index\"],[[\"class\"],[\"third-nav__item\"]],{\"statements\":[[1,[27,\"t\",[\"Company profile\"],null],false]],\"parameters\":[]},null],[10],[0,\"\\n        \"],[7,\"li\"],[9],[4,\"link-to\",[\"admin.company.users\"],[[\"class\"],[\"third-nav__item\"]],{\"statements\":[[1,[27,\"t\",[\"Company members\"],null],false]],\"parameters\":[]},null],[10],[0,\"\\n\"],[4,\"if\",[[27,\"variation\",[\"subscription-tab\"],null]],null,{\"statements\":[[0,\"          \"],[7,\"li\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"admin.company.subscription\"],[[\"class\"],[\"third-nav__item\"]],{\"statements\":[[0,\"              \"],[1,[27,\"t\",[\"Company subscription\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"          \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"fg-column-12 fg-column-10-l\"],[9],[0,\"\\n      \"],[1,[21,\"outlet\"],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/admin/company/template.hbs"
    }
  });

  _exports.default = _default;
});