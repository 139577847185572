define("@vollersgroup/hub-frontend/components/message-list-item-small/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A compact message item.
   *
   * ```hbs
   * {{message-list-item-small conversation=myConversation}}
   *
   * @namespace Component
   * @class MessageListItemSmall
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    /**
     * @property messageCenter
     * @type Service.MessageCenter
     * @protected
     */
    messageCenter: Ember.inject.service(),

    /**
     * The conversation to display.
     *
     * @attribute conversation
     * @type Model.Conversation
     */
    conversation: null
  });

  _exports.default = _default;
});