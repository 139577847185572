define("@vollersgroup/hub-frontend/components/dropdown-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MODE_RADIOBUTTON = _exports.MODE_CHECKBOX = _exports.MODE_DEFAULT = void 0;

  /**
   * @constant MODE_DEFAULT
   * @type {String}
   * @public
   */
  var MODE_DEFAULT = 'DEFAULT';
  /**
   * @constant MODE_CHECKBOX
   * @type {String}
   * @public
   */

  _exports.MODE_DEFAULT = MODE_DEFAULT;
  var MODE_CHECKBOX = 'CHECKBOX';
  /**
   * @constant MODE_RADIOBUTTON
   * @type {String}
   * @public
   */

  _exports.MODE_CHECKBOX = MODE_CHECKBOX;
  var MODE_RADIOBUTTON = 'RADIOBUTTON';
  /**
   * A custom dropdown that acts similar to a select.
   *
   * ```html
   * {{dropdown-select
   *    mode=<String>
   *    action=<String>
   *    options=<Array.{}>
   *    alignment=<String>
   *    placeholder=<String>
   *    buttonClass=<String>
   *    openOnHover=<Boolean>}}
   * ```
   *
   * @namespace Component
   * @class DropdownSelect
   * @extens Ember.Component
   */

  _exports.MODE_RADIOBUTTON = MODE_RADIOBUTTON;
  var DropdownSelect = Ember.Component.extend({
    tabIndex: 1,
    classNames: ['dropdown-select'],
    attributeBindings: ['tabIndex'],
    // -------------------------------------------------------------------------
    // Dependencies
    l10n: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * An array of options for routeable items like:
     *
     * [
     *  {
     *    label: 'My Label',
     *    route: 'my.route',
     *    selected: <Boolean>,
     *    params: {
     *      param1: 'Query Param 1',
     *      param2: 'Query Param 2'
     *    }
     *  },
     *  ...
     * ]
     *
     * Alternatively, an array of 'plain' items like:
     *
     * [
     *  {
     *    label: 'My Label',
     *    value: 'myValue',
     *    selected: <Boolean>,
     *  },
     *  ...
     * ]
     *
     * @property options
     * @type {Array|Object}
     */
    options: Ember.computed(function () {
      return [];
    }),

    /**
     * One of `DEFAULT`, `CHECKBOX` or `RADIOBUTTON`.
     *
     * @property mode
     * @type {String}
     */
    mode: MODE_DEFAULT,

    /**
     * Action for changed selection.
     *
     * @attribute action
     * @type {String}
     * @optional
     */
    action: null,

    /**
     * If set to true, common button styles will apply.
     * Per default, the drop down button is styled with
     * no hovering effects.
     *
     * @attribute buttonClass
     * @type {String}
     * @default ''
     */
    buttonClass: '',

    /**
     * Alignment of drop down item, can be one of 'LEFT' or 'RIGHT'.
     *
     * @attribute alignment
     * @type {String}
     * @default 'LEFT'
     */
    alignment: 'LEFT',

    /**
     * If set to true, drop down will open on hovering.
     *
     * @attribute isFlat
     * @type {Boolean}
     * @default true
     */
    openOnHover: true,

    /**
     * Set to null if you want to display current selection(s).
     *
     * @attribute placeholder
     * @type {String}
     * @default 'Select...'
     * @optional
     */
    placeholder: Ember.computed('l10n', function () {
      return Ember.get(this, 'l10n').t('Select...');
    }),
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Sets `selectedOption` and tries to invoke `action`.
       * Handles context depending on current `mode` setting.
       *
       * @event changeSelection
       * @param {Object} option
       * @private
       */
      select: function select(option) {
        var _this = this;

        var toggleSelection = function toggleSelection() {
          var selected = !!Ember.get(option, 'selected');
          Ember.set(option, 'selected', !selected);
        };

        var swapSelection = function swapSelection() {
          var options = Ember.get(_this, 'options');
          options.setEach('selected', false);
          Ember.set(option, 'selected', true);
        };

        switch (Ember.get(this, 'mode')) {
          case MODE_DEFAULT:
            {
              // behave like radio buttons, but also
              // unset `isOpen` to close the dropdown
              Ember.set(this, 'isOpen', false);
              swapSelection();
              break;
            }

          case MODE_CHECKBOX:
            {
              // toggle `selected` property of option
              toggleSelection();
              break;
            }

          case MODE_RADIOBUTTON:
            {
              // reset `selected` for all items except
              // the current selected one (radiobutton)
              swapSelection();
              break;
            }

          default:
        }

        var action = Ember.get(this, 'action');

        if (Ember.typeOf(action) === 'function') {
          action(option);
        }
      },

      /**
       * Toggles the `isOpen` flag.
       *
       * @event toggleOpen
       * @private
       */
      toggle: function toggle() {
        if (Ember.get(this, 'isDestroyed')) {
          return;
        }

        this.toggleProperty('isOpen');
      }
    },
    // -------------------------------------------------------------------------
    // Properties

    /**
     * If the dropdown is opened or not.
     *
     * @property isOpen
     * @type Boolean
     * @default false
     * @private
     */
    isOpen: false,

    /**
     * If current mode is `CHECKBOX`.
     *
     * @property checkBoxes
     * @type {Boolean}
     * @default false
     */
    checkBoxes: Ember.computed.equal('mode', MODE_CHECKBOX),

    /**
     * If current mode is `RADIOBUTTON`.
     *
     * @property radioButtons
     * @type {Boolean}
     * @default false
     */
    radioButtons: Ember.computed.equal('mode', MODE_RADIOBUTTON),

    /**
     * The currently selected option.
     *
     * @property selectedOption
     * @type Array
     * @private
     */
    selected: Ember.computed('options.@each.selected', function () {
      var options = Ember.get(this, 'options');
      var selected = options.filterBy('selected');

      if (!Ember.isEmpty(selected)) {
        return selected;
      }

      return options.slice(0, 1);
    }),

    /**
     * The max height of the options of this dropdown
     *
     * @property optionsStyle
     * @type Number
     * @private
     */
    optionsStyle: Ember.computed('alignment', 'options.length', function () {
      var count = Ember.get(this, 'options.length');
      var alignment = Ember.get(this, 'alignment');
      var overflow = count > 10 ? 'auto' : 'hidden';
      var right = alignment === 'LEFT' ? 'auto' : 0;
      var factor = count > 10 ? 10 : count;
      return Ember.String.htmlSafe("\n      max-height: ".concat(factor * 50, "px;\n      overflow: ").concat(overflow, ";\n      right: ").concat(right, ";\n    "));
    }),

    /**
     * Internal flag for controlling mouse effects.
     *
     * @property _scheduledToClose
     * @type Boolean
     * @private
     */
    _scheduledToClose: false,
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Registers `click` event on document to
     * automatically close drop down if user
     * clicks outside component element.
     *
     * @public
     * @method didInsertElement
     * @return {Void}
     */
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.$(document).on('click', {
        sender: this
      }, this._documentClick);
    },

    /**
     * Unregisters `click` event on document
     * when component gets removed from dom.
     *
     * @public
     * @method didInsertElement
     * @return {Void}
     */
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      Ember.$(document).off('click', this._documentClick);
    },

    /**
     * Automatically opens drop down if `openOnHover` is true.
     *
     * @public
     * @method mouseEnter
     * @return {Void}
     */
    mouseEnter: function mouseEnter() {
      Ember.set(this, '_scheduledToClose', false);

      if (!Ember.get(this, 'openOnHover')) {
        return;
      }

      Ember.set(this, 'isOpen', true);
    },

    /**
     * Automatically closes drop down if `openOnHover` is true.
     *
     * @public
     * @method mouseEnter
     * @return {Void}
     */
    mouseLeave: function mouseLeave() {
      var _this2 = this;

      Ember.set(this, '_scheduledToClose', true);

      if (!Ember.get(this, 'openOnHover')) {
        return;
      }

      var runCallback = function runCallback() {
        if (!Ember.get(_this2, '_scheduledToClose')) {
          return;
        }

        if (Ember.get(_this2, 'isDestroyed')) {
          return;
        }

        Ember.set(_this2, 'isOpen', false);
      };

      Ember.run.debounce(this, runCallback, 250);
    },

    /**
     * Will be triggered if any element is clicked
     * outside of component, as all actions uses
     * `bubbles=false` to prevent propagation, so
     * we can close drop down automatically.
     *
     * @private
     * @method _documentClick
     * @param {Object} event jQuery event.
     * @return {Void}
     */
    _documentClick: function _documentClick(event) {
      var self = event.data.sender;

      if (!self.get('isOpen')) {
        return;
      }

      self.send('toggle');
    }
  });
  var _default = DropdownSelect;
  _exports.default = _default;
});