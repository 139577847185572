define("@vollersgroup/hub-frontend/message/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var belongsTo = _emberData.default.belongsTo;
  var attr = _emberData.default.attr;
  /**
   * The message model
   *
   * A message always belongs to a conversation. A conversation, which is neither a private offering nor a system notification,
   * always has one or multiple messages.
   *
   * @namespace Model
   * @class Message
   * @extends Model
   */

  var _default = Model.extend({
    /**
     * The text of this message
     *
     * @attribute text
     * @type String
     */
    text: attr('string'),

    /**
     * The date when this message was sent.
     *
     * @attribute created
     * @type Date
     */
    created: attr('timestamp'),

    /**
     * The company which sent this message.
     *
     * @attribute fromCompany
     * @type Model.Company
     */
    fromCompany: belongsTo('company', {
      async: true
    }),

    /**
     * The user which sent this message.
     *
     * @attribute fromUser
     * @type Model.User
     */
    fromUser: belongsTo('user', {
      async: true
    }),

    /**
     * The conversation to which this message belongs.
     *
     * @attribute conversation
     * @type Model.Conversation
     */
    conversation: belongsTo('conversation', {
      async: true,
      inverse: 'messages'
    })
  });

  _exports.default = _default;
});