define("@vollersgroup/hub-frontend/transforms/image", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var Transform = _emberData.default.Transform;

  var _default = Transform.extend({
    deserialize: function deserialize(serialized) {
      if (_typeof(serialized) !== 'object') {
        return null;
      }

      var obj = Ember.Object.create(serialized);

      obj.toString = function () {
        return this.get('fullxfull');
      };

      return obj;
    },
    serialize: function serialize(deserialized) {
      if (!deserialized) {
        return null;
      }

      return Ember.get(deserialized, 'id');
    }
  });

  _exports.default = _default;
});