define("@vollersgroup/hub-frontend/admin/manage/overview/auction/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "@vollersgroup/hub-frontend/mixins/company-verified"], function (_exports, _authenticatedRouteMixin, _companyVerified) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Display all auctions of the current user's company.
   *
   * @namespace Route
   * @class ManageAuctionIndex
   * @extends Ember.Route
   * @uses Mixin.ResetScroll
   * @uses SimpleAuth.Mixin.AuthenticatedRouteMixin
   */
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _companyVerified.default, {
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Checks if `auctionsEnabled` flag is set on current company.
     * If not, it will redirect to manage overview index page.
     *
     * @public
     * @method beforeModel
     * @return Promise
     */
    beforeModel: function beforeModel() {
      var _this = this;

      var companySuccessCallback = function companySuccessCallback() {
        if (!_this.can('create auction')) {
          Ember.get(_this, 'toast').info(Ember.get(_this, 'l10n').t('Sorry, but you cannot manage auctions because the module is not active.'));

          _this.transitionTo('admin.manage.index');
        }
      };

      return Ember.get(this, 'userSession.currentCompany').then(companySuccessCallback);
    },

    /**
     * Delivers auctioneer's with pending registrations.
     *
     * @public
     * @method model
     * @return Promise
     */
    model: function model() {
      var query = {
        auctioneerCompany: Ember.get(this, 'userSession.currentCompany.id'),
        registrationStates: ['PENDING']
      };
      return Ember.RSVP.hash({
        pendingRegistrations: this.store.query('auction-participant', query)
      });
    },
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Reloads model if registrations were updated.
       *
       * @event registrationsUpdated
       * @return {Void}
       * @private
       */
      registrationsUpdated: function registrationsUpdated() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});