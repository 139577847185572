define("@vollersgroup/hub-frontend/components/user-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A list of users.
   *
   * ```hbs
   * {{user-list users=myUsers isEditable=false}}
   * ```
   *
   * @namespace Component
   * @class UserList
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    availableData: Ember.inject.service(),

    /**
     * The users to display in this list.
     *
     * @attribute users
     * @type Model.User[]
     */
    users: null,
    // using a custom sort function
    usersSorting: Object.freeze(['active:desc']),
    sortedUsers: Ember.computed.sort('users', 'usersSorting'),

    /**
     * If this is true, edit buttons might be displayed for administrators.
     *
     * @attribute isEditable
     * @type Boolean
     * @default false
     */
    isEditable: false,
    actions: {
      cancelUserEdit: function cancelUserEdit(user) {
        user.rollbackAttributes();
      },
      changeUserStatus: function changeUserStatus(user) {
        user.toggleProperty('active');
        user.save();
      },
      saveUser: function saveUser(user) {
        user.save();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._resetUsers();

      this._super.apply(this, arguments);
    },
    _resetUsers: function _resetUsers() {
      this.getAttr('users').forEach(function (user) {
        user.set('showChangeRole', undefined);
      });
    }
  });

  _exports.default = _default;
});