define("@vollersgroup/hub-frontend/components/search-agent-item/component", ["exports", "@vollersgroup/hub-frontend/utils/get-first-adapter-error-message"], function (_exports, _getFirstAdapterErrorMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Search agent item for managing search agents.
   *
   * ```html
   * {{search-agent-item searchAgent=searchAgent}}
   * ```
   *
   * @namespace Component
   * @class SearchAgentItem
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    classNames: ['box', 'box--highlight', 'padding-all-0', 'search-agent-item'],
    // -------------------------------------------------------------------------
    // Dependencies
    toast: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Current busy mode state.
     *
     * @property searchAgent
     * @type Model.SearchAgent
     */
    searchAgent: null,
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Current busy mode state.
     *
     * @property isBusy
     * @type Boolean
     */
    isBusy: false,

    /**
     * Current edit mode state.
     *
     * @property isEdit
     * @type Boolean
     */
    isEdit: false,

    /**
     * New name for search agent.
     *
     * @property newName
     * @type String
     */
    newName: null,
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Sets `isEdit` to true.
       *
       * @event editName
       * @private
       */
      edit: function edit() {
        Ember.set(this, 'isEdit', true);
      },

      /**
       * Sets `isEdit` to false.
       *
       * @event cancel
       * @private
       */
      cancel: function cancel() {
        Ember.set(this, 'isEdit', false);
        Ember.set(this, 'newName', null);
      },

      /**
       * Invokes save on toggling
       * mail notification checkbox.
       *
       * @event cancel
       * @private
       */
      toggle: function toggle() {
        this.send('save');
      },

      /**
       * Saves search agent.
       *
       * @event save
       * @private
       */
      save: function save() {
        var _this = this;

        var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

        if (!Ember.isEmpty(name)) {
          Ember.set(this, 'searchAgent.name', name);
        }

        var successCallback = function successCallback() {
          var successMessage = Ember.get(_this, 'l10n').t('Your changes has been saved successfully!');
          Ember.get(_this, 'toast').success(successMessage);

          _this.send('cancel');
        };

        var failureCallback = function failureCallback(adapterError) {
          var serverErrorMessage = (0, _getFirstAdapterErrorMessage.default)(adapterError);
          var errorMessage = Ember.get(_this, 'l10n').t('An error occurred while saving your changes: {{serverErrorMessage}}', {
            serverErrorMessage: serverErrorMessage
          });
          Ember.get(_this, 'toast').error(errorMessage);
        };

        var finallyCallback = function finallyCallback() {
          Ember.set(_this, 'isBusy', false);
        };

        Ember.set(this, 'isBusy', true);
        var searchAgent = Ember.get(this, 'searchAgent');
        return searchAgent.save().then(successCallback).catch(failureCallback).finally(finallyCallback);
      },

      /**
       * Deletes search agent.
       *
       * @event delete
       * @private
       */
      delete: function _delete() {
        var _this2 = this;

        var successCallback = function successCallback() {
          var successMessage = Ember.get(_this2, 'l10n').t('Your search agent has been deleted successfully!');
          Ember.get(_this2, 'toast').success(successMessage);
        };

        var failureCallback = function failureCallback(error) {
          var serverErrorMessage = Ember.get(error, 'payload.error');
          var errorMessage = Ember.get(_this2, 'l10n').t('An error occurred while deleting your search agent: {{serverErrorMessage}}', {
            serverErrorMessage: serverErrorMessage
          });
          Ember.get(_this2, 'toast').error(errorMessage);
        };

        var searchAgent = Ember.get(this, 'searchAgent');
        var promise = searchAgent.destroyRecord();
        promise.then(successCallback, failureCallback);
        return promise;
      }
    }
  });

  _exports.default = _default;
});