define("@vollersgroup/hub-frontend/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YiwFt30H",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"page-title\",[[27,\"t\",[\"Log in\"],null]],null],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"search-bar\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"wrapper\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"layout__full padding-top-2\"],[9],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"button__container button__container--inline search-bar__nav\"],[9],[0,\"\\n        \"],[4,\"link-to\",[\"register\"],[[\"class\"],[\"button button--secondary button--half-height\"]],{\"statements\":[[1,[27,\"t\",[\"Create account\"],null],false]],\"parameters\":[]},null],[0,\"\\n      \"],[10],[0,\"\\n\\n\"],[4,\"link-to\",[\"index\"],[[\"class\"],[\"search-bar__logo\"]],{\"statements\":[[0,\"        \"],[7,\"img\"],[11,\"src\",\"/assets/img/v-hub-logo.png\"],[11,\"alt\",\"V-Hub\"],[11,\"class\",\"search-bar__logo--big\"],[9],[10],[0,\"\\n        \"],[7,\"img\"],[11,\"src\",\"/assets/img/v-hub-logo-small.png\"],[11,\"alt\",\"V-Hub\"],[11,\"class\",\"search-bar__logo--small\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\\n\"],[7,\"section\"],[11,\"class\",\"section flex-grow-1\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"wrapper\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"layout__full\"],[9],[0,\"\\n      \"],[7,\"h1\"],[11,\"class\",\"text-center padding-top-4 padding-bottom-3 font-size-3\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"Log in\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"wrapper wrapper--small\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"max-width-6 margin-auto\"],[9],[0,\"\\n      \"],[1,[27,\"login-form\",null,[[\"afterLogin\",\"gotoRoute\",\"gotoModel\"],[[23,[\"afterLogin\"]],\"admin\",null]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[21,\"footer-main\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/login/template.hbs"
    }
  });

  _exports.default = _default;
});