define("@vollersgroup/hub-frontend/mixins/country", ["exports", "@vollersgroup/hub-frontend/utils/country-data"], function (_exports, _countryData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This mixin injects a countryData property into an object
   *
   * It expects a `country` property which is a 2-letter country code.
   * `countryData` will then include data for this country (e.g. the full name, flag url, ...)
   *
   * @namespace Mixin
   * @class Country
   * @extends Ember.Mixin
   */
  var _default = Ember.Mixin.create({
    /**
     * Get the country data for a 2-letter country code
     *
     * @property countryData
     * @type {object} The countryData object
     * @public
     */
    countryData: Ember.computed('country', function () {
      var countryCode = this.get('country');
      var countryName = _countryData.default[countryCode];

      if (Ember.isEmpty(countryName)) {
        return null;
      }

      var data = {
        name: countryName,
        code: countryCode
      };
      Ember.run.next(function () {
        Ember.set(data, 'flag', Ember.String.htmlSafe(data.code.toLowerCase() + '.png'));
      });
      return data;
    }),

    /**
     * If the location requires VAT number,
     * which is currently only true for EU
     * countries.
     *
     * @property hasVatNumber
     * @type Boolean
     */
    hasVatNumber: Ember.computed('country', function () {
      var countryCode = this.get('country');
      var vatCountries = ['AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'EL', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE', 'GB'];
      return vatCountries.includes(countryCode);
    })
  });

  _exports.default = _default;
});