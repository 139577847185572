define("@vollersgroup/hub-frontend/components/notification-list-item/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A notification item.
   *
   * ```html
   * {{notification-list-item conversation=notification dismissAction="dismiss"}}
   * ```
   *
   * @namespace Component
   * @class NotificationListItem
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    userSession: Ember.inject.service(),
    messageCenter: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Element Properties

    /**
     * @property tagName
     * @type String
     * @final
     * @private
     */
    tagName: '',
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * The conversation object for this notification
     *
     * @attribute conversation
     * @type Model.Conversation
     * @required
     */
    conversation: null,

    /**
     * If the notification should be removed or dismissed (=marked as read) on click.
     *
     * @attribute dismissAction
     * @type "remove"|"dismiss"
     * @default "dismiss"
     * @optional
     */
    dismissAction: 'dismiss',
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Delete this notification.
       *
       * @event remove
       * @private
       */
      remove: function remove() {
        this.get('conversation').deleteRecord();
        this.get('conversation').save();
      },

      /**
       * Mark this notification as unread.
       *
       * @event dismiss
       * @private
       */
      dismiss: function dismiss() {
        this.get('messageCenter').markConversationRead(this.getAttr('conversation'));
      }
    },
    // -------------------------------------------------------------------------
    // Properties
    thresholdDate: Ember.computed(function () {
      return (0, _moment.default)().subtract(7, 'days');
    })
  });

  _exports.default = _default;
});