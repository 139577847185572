define("@vollersgroup/hub-frontend/transforms/sensorial-descriptors", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Transform = _emberData.default.Transform;

  var _default = Transform.extend({
    deserialize: function deserialize(serialized, _options) {
      return serialized;
    },
    serialize: function serialize(deserialized, _options) {
      if (!deserialized) {
        return null;
      }

      return deserialized.map(function (d) {
        return d.id;
      });
    }
  });

  _exports.default = _default;
});