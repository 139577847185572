define("@vollersgroup/hub-frontend/store/shop/route", ["exports", "@vollersgroup/hub-frontend/mixins/search", "@vollersgroup/hub-frontend/mixins/reset-scroll"], function (_exports, _search, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_resetScroll.default, _search.SearchRouteMixin, {
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Fetches search agents from user session.
     *
     * @public
     * @method beforeModel
     * @return {RSVP.Promise}
     */
    beforeModel: function beforeModel() {
      return Ember.get(this, 'userSession.searchAgents');
    },

    /**
     * Fetches shop either by slug or id.
     *
     * @public
     * @method model
     * @return {Model.Shop}
     */
    model: function model(params) {
      // support both shop id AND slug for links
      // that may have been already published yet
      var isSlug = !params.shop_id.match(/^\d+$/);

      if (!isSlug) {
        return this.store.find('shop', params.shop_id);
      }

      return this.store.query('shop', {
        slug: params.shop_id
      }).then(function (shops) {
        return shops.get('firstObject');
      });
    },

    /**
     * Serializes slug as shop id for routes.
     *
     * @public
     * @method serialize
     * @return {Object}
     */
    serialize: function serialize(model) {
      var shop_id = ':shop_id';

      if (!Ember.isNone(model)) {
        shop_id = Ember.get(model, 'slug');

        if (!shop_id) {
          shop_id = Ember.get(model, 'id');
        }
      }

      return {
        shop_id: shop_id
      };
    },

    /**
     * Sets up search.
     *
     * @public
     * @method afterModel
     * @return {Void}
     */
    afterModel: function afterModel(model) {
      var _this = this;

      if (!model) {
        return;
      }

      Ember.set(this, 'search.shop', model.id);

      if (Ember.get(model, 'listingActiveCount') > 0) {
        return Ember.get(this, 'userSession.searchAgents');
      } // redirect to auctions if no listings,
      // but auctions exist within this shop


      var auctions = Ember.get(model, 'auctions');
      auctions.then(function () {
        if (Ember.get(auctions, 'length') === 0) {
          return;
        }

        _this.transitionTo('store.shop.auctions', Ember.get(model, 'slug'));
      });
      return auctions;
    },
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Sets shop status to "ACTIVE", saves and shows publication message for user.
       *
       * @event publishDraftShop
       * @returns Promise
       * @private
       */
      publishDraftShop: function publishDraftShop(shop) {
        var _this2 = this;

        Ember.set(shop, 'status', 'ACTIVE');
        shop.save().then(function () {
          Ember.get(_this2, 'toast').success(Ember.get(_this2, 'l10n').t('You have successfully published the shop {{shopName}}', {
            shopName: Ember.get(shop, 'name')
          }));
        });
      }
    }
  });

  _exports.default = _default;
});