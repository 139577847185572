define("@vollersgroup/hub-frontend/components/cropster-verified-badge/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CeZGf/WZ",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"svg-icon\",[\"verified\"],[[\"class\"],[\"h2 z-index-10\"]]],false],[0,\"\\n\"],[7,\"span\"],[11,\"class\",\"bg-color-primary font-default lh-dense bold color-white padding-top-1 padding-bottom-1 margin-left-3 padding-left-3 padding-right-1 border-radius-1 font-size-6 absolute nowrap\"],[9],[0,\"\\n  \"],[1,[27,\"t\",[\"Vollers verified\"],null],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/cropster-verified-badge/template.hbs"
    }
  });

  _exports.default = _default;
});