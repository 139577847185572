define("@vollersgroup/hub-frontend/components/message-modal/component", ["exports", "moment", "@vollersgroup/hub-frontend/utils/get-first-adapter-error-message"], function (_exports, _moment, _getFirstAdapterErrorMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The new message modal.
   *
   * @namespace Component
   * @class MessageModal
   */
  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies

    /**
     * @property store
     * @type DS.Store
     * @private
     */
    store: Ember.inject.service(),

    /**
     * @property messageCenter
     * @type Service.MessageCenter
     * @private
     */
    messageCenter: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._closeIfNotAuthenticated();
    },
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      dismiss: function dismiss() {
        if (this.get('isSaving')) {
          return;
        }

        this.set('messageRecipient', null);
        this.set('message', null);
        this.set('messageSubjectUse', null);
        this.set('invalidItem', false);
        this.attrs.closeModal();
      },
      selectUser: function selectUser(user) {
        this.set('toUser', user);

        if (user) {
          this.set('toCompany', user.get('company'));
        } else {
          this.set('toCompany', null);
        }
      },
      selectCompany: function selectCompany(company) {
        this.set('toUser', null);
        this.set('toCompany', company);
      },

      /**
       * Create a new conversation
       *
       * @event sendMessage
       * @private
       */
      sendMessage: function sendMessage() {
        var _this = this;

        if (this.get('isSaving')) {
          return;
        }

        var fromUser = this.get('userSession.currentUser');
        var fromCompany = this.get('userSession.currentUser.company');
        var subject = this.get('messageSubjectUse');
        var curDate = (0, _moment.default)();
        var messageContent = this.get('message');
        var errors = [];
        var listing = this.get('messageListing');

        if (!messageContent || messageContent.length < 5) {
          errors.pushObject({
            title: this.get('l10n').t('Message'),
            content: this.get('l10n').t('Your message has to be at least 5 characters long.')
          });
        }

        if (!subject || subject.length < 5) {
          errors.pushObject({
            title: this.get('l10n').t('Subject'),
            content: this.get('l10n').t('Your subject has to be at least 5 characters long.')
          });
        }

        if (Ember.isEmpty(this.get('toCompany'))) {
          errors.pushObject({
            title: this.get('l10n').t('Recipient'),
            content: this.get('l10n').t('Please select a valid company to send your message to.')
          });
        }

        this.set('errors', errors);

        if (errors.length) {
          return;
        }

        this.set('isSaving', true);
        var conversation = this.get('store').createRecord('conversation', {
          toUser: this.get('toUser'),
          toCompany: this.get('toCompany'),
          fromUser: fromUser,
          fromCompany: fromCompany,
          subject: subject,
          fromCompanyUnreadCount: 0,
          toCompanyUnreadCount: 1,
          lastMessageDate: curDate,
          listing: listing
        });

        var messageSuccessCallback = function messageSuccessCallback() {
          _this.get('toast').success(_this.get('l10n').t('Your message has been sent.'));

          _this.get('messageCenter').update();

          _this.set('isSaving', false);

          _this.send('dismiss');
        };

        var messageFailureCallback = function messageFailureCallback(adapterError) {
          var serverErrorMessage = (0, _getFirstAdapterErrorMessage.default)(adapterError);
          var errorMessage = Ember.get(_this, 'l10n').t('An error occurred while trying to send message: {{serverErrorMessage}}', {
            serverErrorMessage: serverErrorMessage
          });

          _this.get('errors').pushObject({
            title: 'Sending Error',
            content: errorMessage
          }); // don't keep empty conversation
          // to avoid database trashing...


          conversation.destroyRecord();
        };

        var messageFinallyCallback = function messageFinallyCallback() {
          _this.set('isSaving', false);
        };

        var conversationSuccessCallback = function conversationSuccessCallback(conversation) {
          var message = _this.get('store').createRecord('message', {
            fromUser: fromUser,
            fromCompany: fromCompany,
            text: messageContent,
            conversation: conversation,
            created: curDate
          });

          message.save().then(messageSuccessCallback, messageFailureCallback).finally(messageFinallyCallback);
        };

        var conversationFailureCallback = function conversationFailureCallback(error) {
          var serverErrorMessage = Ember.get(error, 'payload.error');
          var errorMessage = Ember.get(_this, 'l10n').t('An error occurred while trying to send message: {{serverErrorMessage}}', {
            serverErrorMessage: serverErrorMessage
          });

          _this.get('errors').pushObject({
            title: 'Sending Error',
            content: errorMessage
          });
        };

        var conversationFinallyCallback = function conversationFinallyCallback() {
          _this.set('isSaving', false);
        };

        conversation.save().then(conversationSuccessCallback).catch(conversationFailureCallback).finally(conversationFinallyCallback);
      }
    },
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Is true while the created conversation is in a saving/loading state
     *
     * @property isSaving
     * @type Boolean
     * @private
     */
    isSaving: false,

    /**
     * Is true if the autocomple-search for users/companies shows no results
     *
     * @property invalidItem
     * @type Boolean
     * @private
     */
    invalidItem: false,

    /**
     * An array of errors
     *
     * @property errors
     * @type Array
     * @private
     */
    errors: Ember.computed(function () {
      return [];
    }),

    /**
     * The actual message subject which is used
     *
     * @property messageSubjectUse
     * @type String
     * @private
     */
    messageSubjectUse: '',

    /**
     * The actual message which is used
     *
     * @property message
     * @type String
     * @private
     */
    message: '',

    /**
     * The message Recipient which is used.
     * This is a String which can contain a company or a company & a user
     *
     * @property messageRecipient
     * @type String
     * @private
     */
    messageRecipient: '',

    /**
     * Whether message should be sent to company or directly to user.
     * Can be one of "COMPANY" or "USER"
     *
     * @property messageMode
     * @type String
     * @private
     * @default "COMPANY"
     */
    messageMode: 'COMPANY',

    /**
     * The company to send the message to.
     *
     * @property toCompany
     * @type Model.Company
     * @private
     */
    toCompany: null,

    /**
     * The user to send the message to. May be null.
     *
     * @property toUser
     * @type Model.User
     * @private
     */
    toUser: null,

    /**
     * If messageAttachListing is a valid listing id, this contains the listing which is attached to the conversation
     *
     * @property messageListing
     * @type Model.Listing
     * @private
     */
    messageListing: Ember.computed('messageAttachListing', 'store', function () {
      if (this.get('messageAttachListing') && this.get('messageAttachListing') !== 'null') {
        return this.get('store').findRecord('listing', this.get('messageAttachListing'));
      } else {
        return null;
      }
    }),
    // -------------------------------------------------------------------------
    // Observers

    /**
     * Initialise the message content and subject from the query params
     *
     * @method _initialiseMessageContent
     * @private
     */
    _initialiseMessageContent: Ember.observer('messageContent', 'messageSubject', function () {
      if (this.get('messageContent') !== 'null' && this.get('messageContent')) {
        this.set('message', this.get('messageContent'));
      }

      if (this.get('messageSubject') !== 'null' && this.get('messageSubject')) {
        this.set('messageSubjectUse', this.get('messageSubject'));
      }
    }).on('init'),

    /**
     * Initialise the message recipient if a company is set as recipient
     */
    _initialiseMessageRecipient: Ember.observer('messageTo', function () {
      var _this2 = this;

      var messageMode = this.get('messageMode');
      var messageTo = this.get('messageTo');

      if (Ember.isEmpty(messageTo)) {
        return;
      }

      if (messageMode === 'COMPANY') {
        this.get('store').find('company', messageTo).then(function (company) {
          _this2.send('selectCompany', company);
        });
      }

      if (messageMode === 'USER') {
        this.get('store').find('user', messageTo).then(function (user) {
          _this2.send('selectUser', user);
        });
      }
    }).on('didInsertElement'),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Set defaults from modal.
     *
     * @method init
     * @override
     * @private
     */
    init: function init() {
      this._super.apply(this, arguments);

      var messageMode = this.get('messageMode');
      this.set('messageMode', messageMode || 'COMPANY');
    },

    /**
     * Close the modal if the user is not logged in.
     *
     * @method _closeIfNotAuthenticated
     * @private
     */
    _closeIfNotAuthenticated: function _closeIfNotAuthenticated() {
      if (!this.get('session.isAuthenticated')) {
        this.send('dismiss');
      }
    }
  });

  _exports.default = _default;
});