define("@vollersgroup/hub-frontend/transforms/timestamp", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Transform = _emberData.default.Transform;

  var _default = Transform.extend({
    deserialize: function deserialize(timestamp) {
      if (!timestamp) {
        return null;
      }

      var date = (0, _moment.default)(timestamp);
      return date;
    },
    serialize: function serialize(date) {
      if (!date) {
        return null;
      }

      return date.valueOf();
    }
  });

  _exports.default = _default;
});