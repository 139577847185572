define("@vollersgroup/hub-frontend/components/timezone-checker/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userSession: Ember.inject.service(),
    localTimezone: Ember.computed(function () {
      return _moment.default.tz.guess();
    }),
    timezoneDiffers: Ember.computed('userSession.timezone', 'localTimezone', function () {
      var tz = this.get('userSession.timezone');
      var currentTimezone = this.get('localTimezone');
      return _moment.default.tz(tz).format('Z') !== _moment.default.tz(currentTimezone).format('Z');
    }),
    actions: {
      updateTimezone: function updateTimezone() {
        var user = this.get('userSession.currentUser');
        user.set('timezone', this.get('localTimezone'));
        user.save();
      }
    }
  });

  _exports.default = _default;
});