define("@vollersgroup/hub-frontend/info-center/getting-started/faq-flavors/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u3uzhU1Y",
    "block": "{\"symbols\":[\"text\"],\"statements\":[[1,[27,\"page-title\",[[27,\"t\",[\"FAQs\"],null]],null],false],[0,\"\\n\\n\"],[7,\"h1\"],[11,\"class\",\"font-size-3\"],[9],[0,\"\\n  \"],[1,[27,\"t\",[\"FAQs\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"simple-box\",null,[[\"class\",\"title\"],[\"margin-bottom-3\",[27,\"t\",[\"Flavors\"],null]]],{\"statements\":[[4,\"api-data-viewer\",null,[[\"dataSet\",\"filterPlaceholder\"],[\"descriptors\",[27,\"t\",[\"Filter flavors\"],null]]],{\"statements\":[[4,\"get-text\",null,[[\"message\"],[[27,\"t\",[\"This list shows all currently supported flavors on V-Hub. If you are missing one, please feel free to {{linkToSupport 'contact us'}}.\"],null]]],{\"statements\":[[0,\"      \"],[7,\"a\"],[11,\"href\",\"mailto:v-hub@vollers.com\"],[9],[1,[22,1,[]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/info-center/getting-started/faq-flavors/template.hbs"
    }
  });

  _exports.default = _default;
});