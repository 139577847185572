define("@vollersgroup/hub-frontend/checkout/policy/route", ["exports", "@vollersgroup/hub-frontend/mixins/reset-scroll"], function (_exports, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_resetScroll.default, {
    checkout: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var checkout = this.get('checkout');
      var checkoutSteps = checkout.get('steps');

      if (!Ember.get(checkoutSteps[0], 'isDone')) {
        this.transitionTo(Ember.get(checkoutSteps[0], 'route'));
        return;
      } // Check if all required fields from previous page are filled


      if (!this.get('checkout.shippingAddress.isValid') || !this.get('checkout.billingAddress.isValid')) {
        this.get('flashMessages').addError(this.get('l10n').t('Please fill in all required fields'));
        this.transitionTo('checkout.index');
        return;
      }

      this.get('checkout').set('currentStep', 1);
    },
    actions: {
      completeStep: function completeStep() {
        var checkout = this.get('checkout');

        if (!checkout.get('policyAccepted')) {
          this.get('flashMessages').addError(this.get('l10n').t('You have to accept the policies in order to proceed with the checkout.'));
          return;
        }

        var gotoRoute = checkout.completeStep(1);

        if (gotoRoute) {
          this.transitionTo(gotoRoute);
        }
      }
    }
  });

  _exports.default = _default;
});