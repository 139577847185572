define("@vollersgroup/hub-frontend/helpers/array-contains", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.arrayContains = arrayContains;
  _exports.default = void 0;

  function arrayContains(params
  /*, hash*/
  ) {
    var arr = params[0];
    var value = params[1];

    if (!arr || Ember.typeOf(arr) !== 'array') {
      return false;
    }

    return arr.includes(value);
  }

  var _default = Ember.Helper.helper(arrayContains);

  _exports.default = _default;
});