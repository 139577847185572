define("@vollersgroup/hub-frontend/utils/orders-labels", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getOrdersLabels = getOrdersLabels;

  function getOrdersLabels(orders) {
    return orders.map(function (order) {
      var address = order.get('shippingAddress');
      var parts = [];

      if (address.get('company')) {
        parts.push(address.get('company'));
      }

      if (address.get('fullName')) {
        parts.push(address.get('fullName'));
      }

      if (address.get('street')) {
        parts.push(address.get('street'));
      }

      if (address.get('addressAddition')) {
        parts.push(address.get('addressAddition'));
      }

      if (address.get('state')) {
        parts.push(address.get('countryData.name') + ' - ' + address.get('zip') + ' ' + address.get('city') + ', ' + address.get('state'));
      } else {
        parts.push(address.get('countryData.name') + ' - ' + address.get('zip') + ' ' + address.get('city'));
      }

      return parts;
    });
  }
});