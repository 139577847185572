define("@vollersgroup/hub-frontend/components/info-message/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['flex', 'font-size-6', 'padding-all-3', 'border-all', 'border-radius-1', 'color-grey-dark-2'],
    classNameBindings: ['isInfo:border-color-info', 'isInfo:bg-color-info-light', 'isSuccess:border-color-success', 'isSuccess:bg-color-success-light', 'isWarning:border-color-warning', 'isWarning:bg-color-warning-light', 'isError:border-color-error', 'isError:bg-color-error-light'],
    svgIconName: Ember.computed('type', function () {
      // All the states match icons name
      return this.get('type').toLowerCase();
    }).readOnly(),

    /*
     * Type of the message.
     * Possible values are INFO, SUCCESS, WARNING, ERROR.
     */
    type: 'INFO',
    isInfo: Ember.computed.equal('type', 'INFO').readOnly(),
    isSuccess: Ember.computed.equal('type', 'SUCCESS').readOnly(),
    isWarning: Ember.computed.equal('type', 'WARNING').readOnly(),
    isError: Ember.computed.equal('type', 'ERROR').readOnly(),
    init: function init() {
      this._super.apply(this, arguments);

      (false && !(['INFO', 'SUCCESS', 'WARNING', 'ERROR'].includes(this.get('type'))) && Ember.assert('type is unknown', ['INFO', 'SUCCESS', 'WARNING', 'ERROR'].includes(this.get('type'))));
    }
  });

  _exports.default = _default;
});