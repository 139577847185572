define("@vollersgroup/hub-frontend/components/manage-plan-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pqSELFxt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[11,\"class\",\"button\"],[11,\"type\",\"button\"],[9],[0,\"\\n  \"],[1,[27,\"t\",[\"Manage plans\"],null],false],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"managePlanClick\"]],[10],[0,\"\\n\\n\"],[7,\"form\"],[11,\"class\",\"hidden\"],[11,\"id\",\"manage-plan-form\"],[11,\"method\",\"post\"],[12,\"action\",[21,\"vBillingLoginUrl\"]],[9],[0,\"\\n  \"],[7,\"input\"],[11,\"id\",\"username\"],[11,\"name\",\"username\"],[12,\"value\",[21,\"username\"]],[11,\"type\",\"text\"],[9],[10],[0,\"\\n  \"],[7,\"input\"],[11,\"id\",\"token\"],[11,\"name\",\"token\"],[11,\"value\",\"\"],[11,\"type\",\"text\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/manage-plan-button/template.hbs"
    }
  });

  _exports.default = _default;
});