define("@vollersgroup/hub-frontend/admin/messages/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "@vollersgroup/hub-frontend/mixins/reset-scroll"], function (_exports, _authenticatedRouteMixin, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _resetScroll.default, {
    messageCenter: Ember.inject.service(),
    queryParams: {
      page: {
        refreshModel: true
      },
      term: {
        refreshModel: true
      },
      pageSize: {
        refreshModel: true
      },
      company: {
        refreshModel: true
      }
    },
    activate: function activate() {
      this._super.apply(this, arguments);

      var messageCenter = Ember.get(this, 'messageCenter');
      messageCenter.on('updated', this, this._messageCenterUpdated);
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      var messageCenter = Ember.get(this, 'messageCenter');
      messageCenter.off('updated', this, this._messageCenterUpdated);
    },
    model: function model(params) {
      params.company = params.company || null;
      params.subject = params.subject || null;
      params.pageSize = params.pageSize || 20;
      params.page = params.page || 1;
      params.messageType = 'MESSAGE';
      var store = Ember.get(this, 'store');
      return Ember.RSVP.hash({
        company: params.company ? store.findRecord('company', params.company) : null,
        conversations: store.query('conversation', params)
      });
    },
    _messageCenterUpdated: function _messageCenterUpdated() {
      var page = Ember.get(this, 'controller.page');

      if (Ember.isNone(page) || page > 1) {
        return;
      }

      this.refresh();
    }
  });

  _exports.default = _default;
});