define("@vollersgroup/hub-frontend/components/filter-box/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A filter box containing one or multiple filter items.
   *
   * ```html
   * {{filter-box filter=filter isOpen=true}}
   *  Optional Header
   * {{/filter-box}}
   * ```
   *
   * @namespace Component
   * @class FilterBox
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    availableData: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * @property classNames
     * @type Array
     * @final
     * @private
     */
    classNames: ['card', 'filter__box'],

    /**
     * @property classNameBindings
     * @type Array
     * @final
     * @private
     */
    classNameBindings: ['isOpen:filter__box--open', 'isLoading:filter__box--loading'],
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * Filter represents model.
     *
     * @attribute filter
     * @type {Object}
     */
    filter: null,

    /**
     * While loading filters are disabled.
     *
     * @property isLoading
     * @type Boolean
     * @default false
     */
    isLoading: false,

    /**
     * If the box is open or not.
     *
     * @property isOpen
     * @type Boolean
     * @default false
     */
    isOpen: Ember.computed('filter.parameterName', {
      set: function set(key, value) {
        switch (this.get('filter.parameterName')) {
          case 'format':
            // open filter initially
            if (Ember.isNone(value)) {
              return true;
            }

            return value;

          default:
            return value;
        }
      }
    }),
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Toggle filter's `isOpen` property
       * and calls `toggle` closure action.
       *
       * @event toggleOpen
       * @private
       */
      toggle: function toggle() {
        this.toggleProperty('isOpen');
        var isOpen = this.get('isOpen');

        if (this.attrs.toggle) {
          this.attrs.toggle(isOpen);
        }
      },

      /**
       * Propagates item to parent action
       * by calling `update` closure method.
       *
       * @event update
       * @param {Object} item Updated filter item.
       * @private
       */
      update: function update(item) {
        if (this.attrs.update) {
          this.attrs.update(item);
        }
      }
    },
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Maps filter type to subcomponents.
     *
     * @public
     * @method didReceiveAttrs
     * @return {Void}
     */
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var type = this.get('filter.parameterType');

      switch (type) {
        case 'ARRAY':
          this.set('subComponent', 'filter-item-array');
          break;

        case 'VALUE':
          this.set('subComponent', 'filter-item-value');
          break;

        case 'RANGE':
          this.set('subComponent', 'filter-item-range');
          break;

        case 'OTHER':
          this.set('subComponent', 'filter-item-other');
          break;

        default:
          throw new Error("Filter type ".concat(type, " is not implemented!"));
      }
    }
  });

  _exports.default = _default;
});