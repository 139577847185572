define("@vollersgroup/hub-frontend/admin/manage/print-offer-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AUVO4pP9",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"page-title\",[[27,\"t\",[\"Print offer list\"],null]],null],false],[0,\"\\n\\n\"],[7,\"section\"],[11,\"class\",\"section padding-bottom-4\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"wrapper\"],[9],[0,\"\\n    \"],[1,[27,\"listing-offer-list-print\",null,[[\"listings\"],[[23,[\"model\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/admin/manage/print-offer-list/template.hbs"
    }
  });

  _exports.default = _default;
});