define("@vollersgroup/hub-frontend/user/serializer", ["exports", "@vollersgroup/hub-frontend/application/serializer"], function (_exports, _serializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _serializer.default.extend({
    normalize: function normalize(type, json, property) {
      json.companyId = json.company;
      return this._super(type, json, property);
    },
    serialize: function serialize(payload, options) {
      var json = this._super(payload, options);

      delete json.companyId;
      return json;
    }
  });

  _exports.default = _default;
});