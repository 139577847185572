define("@vollersgroup/hub-frontend/components/order-info/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Display info messages for an order.
   *
   * ```html
   * {{order-info order=order}}
   * ```
   *
   * @namespace Component
   * @class OrderInfo
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    l10n: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * The order which should be displayed.
     *
     * @attribute order
     * @type Model.Order
     */
    order: null,
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Displayes correct seller wording dependent on user session.
     *
     * @property seller
     * @type String
     * @public
     */
    seller: Ember.computed('l10n', 'order.isSeller', 'order.shop.name', {
      get: function get() {
        if (Ember.get(this, 'order.isSeller')) {
          return Ember.get(this, 'l10n').t('you');
        }

        return Ember.get(this, 'order.shop.name');
      }
    }),

    /**
     * Displayes correct buyer wording dependent on user session.
     *
     * @property buyer
     * @type String
     * @public
     */
    buyer: Ember.computed('l10n', 'order.buyerCompany.name', 'order.isBuyer', {
      get: function get() {
        if (Ember.get(this, 'order.isBuyer')) {
          return Ember.get(this, 'l10n').t('you');
        }

        return Ember.get(this, 'order.buyerCompany.name');
      }
    })
  });

  _exports.default = _default;
});