define("@vollersgroup/hub-frontend/components/flickering-fire/component", ["exports", "@vollersgroup/hub-frontend/config/environment", "moment"], function (_exports, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Displays an animated fire icon when listing
   * has a quick bids within a 20s time frame.
   */
  var _default = Ember.Component.extend({
    classNames: ['inline-block', 'vertical-align-middle'],
    scale: 1,
    width: 29,
    // svg width
    height: 33,
    // svg height
    style: Ember.computed('height', 'scale', 'width', function () {
      var width = this.get('width');
      var scale = +this.get('scale');
      var height = this.get('height');
      return Ember.String.htmlSafe("      width: ".concat(Math.floor(width * scale), "px;      height: ").concat(Math.floor(height * scale), "px;    "));
    }),
    sortedBids: null,
    isOnFire: false,
    quickBidsTimeframeMs: 20000,
    hideInMs: Ember.computed(function () {
      // Use just 1s in test environment to not slow down tests too much
      return _environment.default.environment === 'test' ? 1000 : 10000;
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super();

      if (Ember.get(this, 'isOnFire')) {
        // if the fire is still on just postpone the hiding
        this._scheduleHide();
      } else {
        this._checkIsOnFire();
      }
    },

    /*
     * To display fire, difference between latest bid and fourth most recent bid
     * must be lass than {quickBidsTimeframeMs} and latest
     * bid must be in last {hideInMs} from now.
     */
    _checkIsOnFire: function _checkIsOnFire() {
      var sortedBids = Ember.get(this, 'sortedBids');

      if (Ember.isEmpty(sortedBids)) {
        this._hide();

        return;
      }

      var fourthMostRecentBid = sortedBids.get(3);
      var latestBid = sortedBids.get(0);

      if (Ember.isEmpty(fourthMostRecentBid) || Ember.isEmpty(latestBid)) {
        this._hide();

        return;
      }

      var quickBidsTimeframeMs = Ember.get(this, 'quickBidsTimeframeMs');
      var hideInMs = Ember.get(this, 'hideInMs');
      var fourthBidDate = Ember.get(fourthMostRecentBid, 'createdDate');
      var lastBidDate = Ember.get(latestBid, 'createdDate');
      var now = (0, _moment.default)();
      var isOnFire = lastBidDate.diff(fourthBidDate) <= quickBidsTimeframeMs && now.diff(lastBidDate) <= hideInMs;

      if (isOnFire) {
        this._scheduleHide();
      }

      Ember.set(this, 'isOnFire', isOnFire);
    },
    _scheduleHide: function _scheduleHide() {
      Ember.run.debounce(this, this._hide, Ember.get(this, 'hideInMs'));
    },
    _hide: function _hide() {
      Ember.set(this, 'isOnFire', false);
    }
  });

  _exports.default = _default;
});