define("@vollersgroup/hub-frontend/components/auction-bidders-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    userSession: Ember.inject.service(),
    auctionBidders: null,
    listing: null,
    listingBidsChanged: Ember.observer('listing.bidCount', function () {
      Ember.run.once(this, '_loadListingBids');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this._loadListingBids();
    },
    // Auction participant doesn't have bids loaded by default.
    // We need to load them manually at the moment.
    _loadListingBids: function _loadListingBids() {
      var _this = this;

      var listingId = Ember.get(this, 'auctionBidders');
      var store = Ember.get(this, 'store');
      store.findRecord('listing', listingId).then(function (listing) {
        Ember.set(_this, 'listing', listing);
        return listing.get('bids');
      }).then(function (bids) {
        return bids.reload();
      });
    }
  });

  _exports.default = _default;
});