define("@vollersgroup/hub-frontend/components/bulk-workflow/component", ["exports", "ember-bulk-manager/components/bulk-import-file", "@vollersgroup/hub-frontend/components/bulk-workflow/master/create/template", "@vollersgroup/hub-frontend/components/bulk-workflow/master/edit/template"], function (_exports, _bulkImportFile, _template, _template2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var testing = Ember.testing;
  /**
   * Abstraction layer for ember-bulk-manager addon usage of contained
   * components to enable easy and reusable import, mapping, prefilling
   * and editing with bulk components and services for specified model.
   *
   * ```html
   * {{#bulk-workflow
   *    isEdit=isEdit
   *    isReady=isReady
   *    saveState=saveState
   *    modelName=modelName
   *    extraSteps=extraSteps
   *    canContinue=canContinue
   *    currentStep=currentStep
   *    onSave=(action "onSave")
   *    onFinish=(action "onFinish")
   *    availableFields=availableFields
   *    modelTranslations=modelTranslations
   *    handsontableInput=handsontableInput
   *    onPrefillChange(action "onPrefillChange")
   *    onUploadSuccess=(action "onUploadSuccess")
   *    onMappingSuccess=(action "onMappingSuccess")
   *    handsontableSettings=handsontableSettings as |component slot|}}
   *
   *    {{#if (eq currentStep.id 'my_extra_step_id')}}
   *      {{#if (eq slot 'step_content')}}
   *        {{!-- custom template --}}
   *      {{/if}}
   *      {{#if (eq slot 'before_component')}}
   *        {{!-- custom template --}}
   *      {{/if}}
   *      {{#if (eq slot 'after_component')}}
   *        {{!-- custom template --}}
   *      {{/if}}
   *      {{#if (eq slot 'extra_buttons')}}
   *        {{!-- custom template --}}
   *      {{/if}}
   *      {{#if (eq slot 'extra_footer')}}
   *        {{!-- custom template --}}
   *      {{/if}}
   *    {{/if}}
   *
   *  {{/bulk-workflow}}
   * ```
   *
   * Action signatures:
   *
   * - goToStep(currentStep)
   * - onUploadSuccess(result,template)
   * - onMappingSuccess(template)
   * - onChangePrefill(availableField,value)
   * - onSave(data)
   * - onFinish()
   *
   * @namespace Component
   * @class APIDataViewer
   * @extends Ember.Component
   */

  var _default = Ember.Component.extend({
    layout: Ember.computed('isEdit', {
      get: function get()
      /*key*/
      {
        if (Ember.get(this, 'isEdit')) {
          return _template2.default;
        }

        return _template.default;
      }
    }),
    // -------------------------------------------------------------------------
    // Dependencies
    l10n: Ember.inject.service(),
    toast: Ember.inject.service(),
    bulkTemplate: Ember.inject.service(),
    bulkSpreadsheet: Ember.inject.service(),
    keenTracking: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * If only `bulk-grid` for editing
     * given model data should be shown.
     *
     * @attribute isEdit
     * @type {Boolean}
     */
    isEdit: false,

    /**
     * If component is ready to start. This is
     * useful in combination with `extraSteps`,
     * which has to be performed before default
     * steps are then initialized.
     *
     * @attribute isReady
     * @type {Boolean}
     * @default true
     * @public
     */
    isReady: true,

    /**
     * Hash keeping save state information and consists
     * of `isRunning`, `isFinished`, `logs`, `addCount`,
     * `editCount`, `errorCount`, `totalCount` and
     * `currentCount`.
     *
     * @attribute saveState
     * @type {Object}
     * @public
     */
    saveState: null,

    /**
     * If component is allowed to continue to next step.
     * This is useful if you want to control workflow
     * from outside in case there are async tasks.
     *
     * @attribute canContinue
     * @type {Boolean}
     * @default true
     * @public
     */
    canContinue: true,

    /**
     * Hashmap of available fields.
     *
     * @attribute availableFields
     * @type {Object}
     * @public
     */
    availableFields: null,

    /**
     * Name of the current bulk model.
     *
     * @attribute modelName
     * @type {String}
     * @public
     */
    modelName: null,

    /**
     * Hashmap of singular and plural
     * translation for current model.
     *
     * @attribute modelTranslations
     * @type {Object}
     * @public
     */
    modelTranslations: null,

    /**
     * Extra steps to be performed
     * in advance to internal steps.
     *
     * @attribute extraSteps
     * @type {Object}
     * @public
     */
    extraSteps: null,

    /**
     * Current bulk workflow step.
     *
     * @attribute currentStep
     * @type {Object}
     * @public
     */
    currentStep: Ember.computed('currentStepId', 'isEdit', 'stepKeys', 'steps', {
      set: function set(key, value
      /*, oldValue*/
      ) {
        // accept both string and object values
        // to be used as query parameteres too!
        switch (Ember.typeOf(value)) {
          case 'string':
            value = Ember.get(this, "steps.".concat(value));
            break;

          case 'object':
            // no change
            break;

          default:
            value = null;
        } // cache `id` of step for `get()`


        var id = value && value.id;
        Ember.set(this, 'currentStepId', id);
        return value;
      },
      get: function get()
      /*key*/
      {
        var isEdit = Ember.get(this, 'isEdit');
        var steps = Ember.get(this, 'steps');

        if (isEdit) {
          return Ember.get(steps, 'import');
        }

        var keys = Ember.get(this, 'stepKeys');
        var id = Ember.get(this, 'currentStepId');
        return Ember.get(steps, Ember.isNone(id) ? keys[0] : id);
      }
    }),

    /**
     * Reference to handsontable input data.
     *
     * @attribute handsontableInput
     * @type {Array}
     * @public
     */
    handsontableInput: Ember.computed({
      get: function get()
      /*key*/
      {
        return [];
      }
    }),

    /**
     * Reference to handsontable output data.
     *
     * @attribute handsontableOutput
     * @type {Array}
     * @private
     */
    handsontableOutput: Ember.computed({
      get: function get()
      /*key*/
      {
        return [];
      }
    }),

    /**
     * Custom settings for handsontable instance.
     *
     * @attribute handsontableSettings
     * @type {Array}
     * @public
     */
    handsontableSettings: Ember.computed({
      get: function get()
      /*key*/
      {
        return {};
      }
    }),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * If component is performing work.
     *
     * @property isBusy
     * @type {Boolean}
     * @public
     */
    isBusy: false,

    /**
     * Internal reference to current step id.
     *
     * @property currentStepId
     * @type {String}
     * @public
     */
    currentStepId: null,

    /**
     * One of 'EXCEL', 'CSV' or 'EMPTY'.
     *
     * @property currentUploadMode
     * @type {String}
     * @public
     */
    currentUploadMode: null,

    /**
     * Reference to current mapping template.
     *
     * @property currentTemplate
     * @type {Object}
     * @public
     */
    currentTemplate: null,

    /**
     * Reference to current spreadsheet import result.
     *
     * @property currentUploadResult
     * @type {Object}
     * @public
     */
    currentUploadResult: null,

    /**
     * Extra template data
     *
     * @property currentTemplate
     * @type {Object}
     * @public
     */
    currentTemplateData: Ember.computed('modelName', {
      get: function get()
      /*key*/
      {
        return {
          type: Ember.get(this, 'modelName')
        };
      }
    }),

    /**
     * Composed handsontable settings.
     *
     * @attribute handsontableConfig
     * @type {Object}
     * @public
     */
    handsontableConfig: Ember.computed('handsontableSettings', 'isEdit', {
      get: function get()
      /*key*/
      {
        var custom = Ember.get(this, 'handsontableSettings');
        var isEdit = Ember.get(this, 'isEdit');
        var settings = {
          modifyColWidth: function modifyColWidth(width) {
            var maxWidth = 500;
            return width > maxWidth ? maxWidth : width;
          },
          manualColumnResize: true,
          columnHeaderHeight: 30,
          manualRowResize: true,
          rowHeights: 30
        };

        if (!isEdit) {
          Ember.merge(settings, {
            minSpareRows: 1,
            minRows: 10
          });
        }

        switch (Ember.typeOf(custom)) {
          case 'object':
            Ember.merge(settings, custom);
            break;

          default:
        }

        return settings;
      }
    }),

    /**
     * Current partial name of content area.
     *
     * @property partialBody
     * @type {String}
     * @public
     */
    partialPath: 'components/bulk-workflow',

    /**
     * Current partial name of content area.
     *
     * @property partialBody
     * @type {String}
     * @public
     */
    partialBody: Ember.computed('currentStep', 'isEdit', 'isSaving', 'partialPath', {
      get: function get()
      /*key*/
      {
        var partialPath = Ember.get(this, 'partialPath');
        var currentStep = Ember.get(this, 'currentStep');

        if (Ember.isNone(currentStep)) {
          return null;
        }

        return !Ember.get(this, 'isSaving') ? Ember.get(currentStep, 'partial') : "".concat(partialPath, "/save");
      }
    }),

    /**
     * Partial name of footer area.
     *
     * @property partialFooter
     * @type {String}
     * @public
     */
    partialFooter: Ember.computed('partialPath', {
      get: function get()
      /*key*/
      {
        var partialPath = Ember.get(this, 'partialPath');
        return "".concat(partialPath, "/master/footer");
      }
    }),

    /**
     * Simple computed property if current mode is
     * 'EMPTY', cause this gets special treatment.
     *
     * @property isModeEmpty
     * @type {Boolean}
     * @public
     */
    isModeEmpty: Ember.computed.equal('currentUploadMode', _bulkImportFile.MODE_EMPTY),

    /**
     * Flag if user is currently editing cells.
     *
     * @property isEditing
     * @type {Boolean}
     * @public
     */
    isEditing: false,

    /**
     * Flag if user can save current state.
     * This depends on `isEditing` as well
     * as `validRowCount` of bulk-grid.
     *
     * @property isEditing
     * @type {Boolean}
     * @public
     */
    canSave: Ember.computed('isEditing', 'validRowsCount', {
      get: function get()
      /*key*/
      {
        var validRowsCount = Ember.get(this, 'validRowsCount');
        var isEditing = Ember.get(this, 'isEditing');

        if (isEditing) {
          return false;
        }

        return validRowsCount > 0;
      }
    }),

    /**
     * Checks if either `isRunning` or `isFinished`
     * flag is set on `saveState` property provided
     * from consumer template.
     *
     * @property isSaving
     * @type {Boolean}
     * @public
     */
    isSaving: Ember.computed.or('saveState.isRunning', 'saveState.isFinished'),

    /**
     * Simple alias to `isFinished` of `saveState`.
     *
     * @property hasSaved
     * @type {Boolean}
     * @public
     */
    hasSaved: Ember.computed.reads('saveState.isFinished'),

    /**
     * Definition of all bulk process steps.
     *
     * @attribute steps
     * @type {Object}
     * @public
     */
    steps: Ember.computed('extraSteps', 'importDisabled', 'l10n', 'mappingDisabled', 'partialPath', 'prefillDisabled', 'uploadDisabled', {
      get: function get()
      /*key*/
      {
        var partialPath = Ember.get(this, 'partialPath');
        var extraSteps = Ember.get(this, 'extraSteps');
        var steps = Ember.merge({}, extraSteps || {});
        steps.upload = {
          id: 'upload',
          partial: "".concat(partialPath, "/upload"),
          label: Ember.get(this, 'l10n').t('Upload'),
          disabled: Ember.get(this, 'uploadDisabled')
        };
        steps.mapping = {
          id: 'mapping',
          partial: "".concat(partialPath, "/mapping"),
          label: Ember.get(this, 'l10n').t('Mapping'),
          disabled: Ember.get(this, 'mappingDisabled')
        };
        steps.prefill = {
          id: 'prefill',
          partial: "".concat(partialPath, "/prefill"),
          label: Ember.get(this, 'l10n').t('Prefill'),
          disabled: Ember.get(this, 'prefillDisabled')
        };
        steps.import = {
          id: 'import',
          partial: "".concat(partialPath, "/import"),
          label: Ember.get(this, 'l10n').t('Import'),
          disabled: Ember.get(this, 'importDisabled')
        };
        return steps;
      }
    }),

    /**
     * Object keys from `steps`.
     *
     * @property stepKeys
     * @type {Array}
     * @public
     */
    stepKeys: Ember.computed('steps', {
      get: function get()
      /*key*/
      {
        var steps = Ember.get(this, 'steps');
        return Object.keys(steps);
      }
    }),

    /**
     * Computes the next step depending on
     * current bulk progress for `Continue`.
     *
     * @property nextStep
     * @type {String}
     * @public
     */
    nextStep: Ember.computed('currentStep', 'importDisabled', 'isSaving', 'mappingDisabled', 'prefillDisabled', 'stepKeys', 'steps', 'uploadDisabled', {
      get: function get()
      /*key*/
      {
        var currentStep = Ember.get(this, 'currentStep');
        var isSaving = Ember.get(this, 'isSaving');

        if (Ember.isNone(currentStep) || isSaving) {
          return false;
        }

        var currentId = Ember.get(currentStep, 'id');
        var keys = Ember.get(this, 'stepKeys');
        var steps = Ember.get(this, 'steps');
        var max = keys.length;
        var useNext = false;
        var nextStep = null;

        for (var i = 0; i < max; i++) {
          var step = Ember.get(steps, keys[i]);
          var disabled = Ember.get(step, 'disabled');

          if (useNext && !disabled) {
            nextStep = step;
            break;
          }

          var stepId = Ember.get(step, 'id');

          if (stepId === currentId) {
            useNext = true;
          }
        }

        return nextStep;
      }
    }),

    /**
     * Computes the prev step depending on
     * current bulk progress for `Back`.
     *
     * @property prevStep
     * @type {String}
     * @public
     */
    prevStep: Ember.computed('currentStep', 'importDisabled', 'isSaving', 'mappingDisabled', 'prefillDisabled', 'stepKeys', 'steps', 'uploadDisabled', {
      get: function get()
      /*key*/
      {
        var currentStep = Ember.get(this, 'currentStep');
        var isSaving = Ember.get(this, 'isSaving');

        if (Ember.isNone(currentStep) || isSaving) {
          return false;
        }

        var currentId = Ember.get(currentStep, 'id');
        var keys = Ember.get(this, 'stepKeys');
        var steps = Ember.get(this, 'steps');
        var max = keys.length - 1;
        var usePrev = false;
        var prevStep = null;

        for (var i = max; i >= 0; i--) {
          var step = Ember.get(steps, keys[i]);
          var disabled = Ember.get(step, 'disabled');

          if (usePrev && !disabled) {
            prevStep = step;
            break;
          }

          var stepId = Ember.get(step, 'id');

          if (stepId === currentId) {
            usePrev = true;
          }
        }

        return prevStep;
      }
    }),

    /**
     * Simple computed property if current route is
     * the first step (import page) for save button.
     *
     * @property isFirstStep
     * @type {Boolean}
     * @public
     */
    isFirstStep: Ember.computed('currentStep', 'isSaving', 'stepKeys', 'steps', {
      get: function get()
      /*key*/
      {
        var currentStep = Ember.get(this, 'currentStep');
        var isSaving = Ember.get(this, 'isSaving');

        if (Ember.isNone(currentStep) || isSaving) {
          return false;
        }

        var steps = Ember.get(this, 'steps');
        var keys = Ember.get(this, 'stepKeys');
        var firstStep = Ember.get(steps, keys[0]);
        return currentStep.id === firstStep.id;
      }
    }),

    /**
     * Simple computed property if current route is
     * the last step (editing page) for save button.
     *
     * @property isLastStep
     * @type {Boolean}
     * @public
     */
    isLastStep: Ember.computed('currentStep', 'isSaving', 'stepKeys', 'steps', {
      get: function get()
      /*key*/
      {
        var currentStep = Ember.get(this, 'currentStep');
        var isSaving = Ember.get(this, 'isSaving');

        if (Ember.isNone(currentStep) || isSaving) {
          return false;
        }

        var steps = Ember.get(this, 'steps');
        var keys = Ember.get(this, 'stepKeys');
        var key = keys[keys.length - 1];
        var lastStep = Ember.get(steps, key);
        return currentStep.id === lastStep.id;
      }
    }),

    /**
     * If `import` step is disabled.
     *
     * @property uploadDisabled
     * @type {Boolean}
     * @public
     */
    uploadDisabled: Ember.computed('isBusy', 'isReady', 'isSaving', 'canContinue', {
      get: function get()
      /*key*/
      {
        return Ember.get(this, 'isBusy') || Ember.get(this, 'isSaving') || !Ember.get(this, 'isReady') || !Ember.get(this, 'canContinue');
      }
    }),

    /**
     * Indicates if `upload` step is finished.
     *
     * @property hasUpload
     * @type {Boolean}
     * @public
     */
    hasUpload: Ember.computed.notEmpty('currentUploadResult'),

    /**
     * If `mapping` step is disabled.
     *
     * @property mappingDisabled
     * @type {Boolean}
     * @public
     */
    mappingDisabled: Ember.computed('isBusy', 'isSaving', 'hasUpload', 'canContinue', {
      get: function get()
      /*key*/
      {
        return Ember.get(this, 'isBusy') || Ember.get(this, 'isSaving') || !Ember.get(this, 'hasUpload') || !Ember.get(this, 'canContinue');
      }
    }),

    /**
     * Indicates if `mapping` step is finished.
     *
     * @property hasUpload
     * @type {Boolean}
     * @public
     */
    hasMapping: Ember.computed('hasUpload', 'currentTemplate', {
      get: function get()
      /*key*/
      {
        if (!Ember.get(this, 'hasUpload')) {
          return false;
        }

        if (Ember.isNone(Ember.get(this, 'currentTemplate'))) {
          return false;
        }

        return this._validateTemplate();
      }
    }),

    /**
     * If `prefill` step is disabled.
     *
     * @property prefillDisabled
     * @type {Boolean}
     * @public
     */
    prefillDisabled: Ember.computed('isBusy', 'hasData', 'isSaving', 'hasMapping', 'isModeEmpty', 'canContinue', {
      get: function get()
      /*key*/
      {
        return Ember.get(this, 'isBusy') || Ember.get(this, 'hasData') || Ember.get(this, 'isSaving') || !Ember.get(this, 'canContinue') || !Ember.get(this, 'isModeEmpty') && !Ember.get(this, 'hasMapping');
      }
    }),

    /**
     * Indicates if `prefilling` step is finished.
     *
     * @property hasUpload
     * @type {Boolean}
     * @public
     */
    hasPrefill: Ember.computed.reads('hasMapping'),

    /**
     * If `import` step is disabled.
     *
     * @property importDisabled
     * @type {Boolean}
     * @public
     */
    importDisabled: Ember.computed('isBusy', 'isSaving', 'hasPrefill', 'isModeEmpty', 'canContinue', {
      get: function get()
      /*key*/
      {
        return Ember.get(this, 'isBusy') || Ember.get(this, 'isSaving') || !Ember.get(this, 'canContinue') || !Ember.get(this, 'isModeEmpty') && !Ember.get(this, 'hasPrefill');
      }
    }),

    /**
     * Indicates if `edit` step is finished.
     *
     * @property hasUpload
     * @type {Boolean}
     * @public
     */
    hasData: Ember.computed.notEmpty('handsontableOutput'),

    /**
     * Counter for invalid cells in handsontable.
     *
     * @property invalidCellCount
     * @type {Number}
     * @public
     */
    invalidCellCount: 0,

    /**
     * Counter for fuzzy cells in handsontable.
     *
     * @property fuzzyCellCount
     * @type {Number}
     * @public
     */
    fuzzyCellCount: 0,

    /**
     * Counter for valid rows in handsontable.
     *
     * @property validRowsCount
     * @type {Number}
     * @public
     */
    validRowsCount: 0,
    // -------------------------------------------------------------------------
    // Methods
    init: function init() {
      this._super.apply(this, arguments);

      this.keenTracking.addEvent('BulkWorkflowStart');
    },

    /**
     * Validates fields of `currentUploadResult` with `currentTemplate`.
     *
     * @private
     * @method _validateTemplate
     * @return {Boolean}
     */
    _validateTemplate: function _validateTemplate() {
      var importFields = Ember.get(this, 'currentUploadResult.fields');
      var currentTemplate = Ember.get(this, 'currentTemplate');
      var bulkTemplate = Ember.get(this, 'bulkTemplate');

      try {
        bulkTemplate.validate(currentTemplate, importFields);
      } catch (exception) {
        return false;
      }

      return true;
    },

    /**
     * Saves `currentTemplate` with `bulk-template` service.
     *
     * @private
     * @method _saveTemplate
     * @return {Void}
     */
    _saveTemplate: function _saveTemplate() {
      var _this = this;

      var currentTemplate = Ember.get(this, 'currentTemplate');
      var bulkTemplate = Ember.get(this, 'bulkTemplate');

      var successCallback = function successCallback()
      /*template*/
      {
        Ember.get(_this, 'toast').success(Ember.get(_this, 'l10n').t('Template has been saved successfully.'));
      };

      var failureCallback = function failureCallback()
      /*reason*/
      {
        Ember.get(_this, 'toast').error(Ember.get(_this, 'l10n').t('Could not save template successfully!'));
      };

      var finallyCallback = function finallyCallback() {
        Ember.set(_this, 'isBusy', false);
      };

      Ember.set(this, 'isBusy', true);
      bulkTemplate.save(currentTemplate).then(successCallback, failureCallback).finally(finallyCallback);
    },
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      //
      // Generic
      //
      afterChange: function afterChange() {
        return this.keenTracking.addEvent('BulkWorkflowDataChange');
      },

      /**
       * Transitions to next step in bulk process.
       *
       * @event goToStep
       * @return {Void}
       * @private
       */
      goToStep: function goToStep(step) {
        Ember.set(this, 'currentStep', step);

        try {
          this.attrs.goToStep(step);
        } catch (e) {
          /* noop */
        }
      },

      /**
       * Transitions to next step in bulk process.
       *
       * @event goToNextStep
       * @return {Void}
       * @private
       */
      goToNextStep: function goToNextStep() {
        var nextStep = Ember.get(this, 'nextStep');

        if (Ember.isNone(nextStep)) {
          return;
        }

        this.send('goToStep', nextStep);
      },

      /**
       * Transitions to previous step in bulk process.
       *
       * @event goToPrevStep
       * @return {Void}
       * @private
       */
      goToPrevStep: function goToPrevStep() {
        var prevStep = Ember.get(this, 'prevStep');

        if (Ember.isNone(prevStep)) {
          return;
        }

        this.send('goToStep', prevStep);
      },
      //
      // Import
      //

      /**
       * Updates `currentUploadMode` and resets
       * all import related data properties too.
       *
       * @event onModeChange
       * @param {String} mode
       * @return {Void}
       * @private
       */
      onModeChange: function onModeChange(mode) {
        // update `currentUploadMode` property
        Ember.set(this, 'currentUploadMode', mode); // reset handsontable I/O after change

        Ember.set(this, 'currentUploadResult', null);
        Ember.set(this, 'handsontableOutput', []);
        Ember.set(this, 'handsontableInput', []);
      },

      /**
       * Shows info toast to inform user what's happening
       * and why file references have to be cleared here.
       *
       * @event onModeHeaderChange
       * @param {Boolean} header
       * @return {Void}
       * @private
       */
      onModeHeaderChange: function onModeHeaderChange()
      /*header*/
      {
        var currentUploadResult = Ember.get(this, 'currentUploadResult');

        if (Ember.isNone(currentUploadResult)) {
          return;
        }

        this.send('onModeChange', Ember.get(this, 'currentUploadMode'));
        Ember.get(this, 'toast').info(Ember.get(this, 'l10n').t('Please re-upload your file, it needs to be parsed again.'));
      },

      /**
       * Updates `currentTemplate` property.
       *
       * @event onTemplateChange
       * @param {Object} template
       * @return {Void}
       * @private
       */
      onTemplateChange: function onTemplateChange(template) {
        Ember.set(this, 'currentTemplate', template);
      },

      /**
       * Shows success/error toast to inform user
       * after template deletion has been performed.
       *
       * @event onTemplateDelete
       * @param {Boolean} status
       * @return {Void}
       * @private
       */
      onTemplateDelete: function onTemplateDelete(response) {
        if (response === true) {
          Ember.get(this, 'toast').success(Ember.get(this, 'l10n').t('Template has been deleted successfully.'));
        } else {
          Ember.get(this, 'toast').error(Ember.get(this, 'l10n').t('Could not delete template successfully!'));
        }
      },

      /**
       * Reset `currentUploadResult` property.
       *
       * @event onUploadFailure
       * @return {Void}
       * @private
       */
      onUploadFailure: function onUploadFailure()
      /*reason*/
      {
        Ember.set(this, 'currentUploadResult', null);
      },

      /**
       * Forwards result and template to `onUploadSuccess`.
       * Additionally, the invalidation message is shown.
       *
       * @event onUploadInvalid
       * @param {Object} reason
       * @param {Object} result
       * @return {Void}
       * @private
       */
      onUploadInvalid: function onUploadInvalid(reason, result, template) {
        this.send('onUploadSuccess', result, template);
      },

      /**
       * Persists the current import result as
       * well as the correspondning template.
       *
       * @event onUploadSuccess
       * @param {Object} result
       * @param {Object} template
       * @return {Void}
       * @private
       */
      onUploadSuccess: function onUploadSuccess(result, template) {
        // update I/O stream after successful import
        Ember.set(this, 'handsontableOutput', []);
        Ember.set(this, 'handsontableInput', result.data); // persist import result and template for all
        // dependent computed properties of component

        Ember.set(this, 'currentUploadResult', result);
        this.send('onTemplateChange', template);
        this.keenTracking.addEvent('BulkWorkflowUpload');

        try {
          var _this$attrs;

          (_this$attrs = this.attrs).onUploadSuccess.apply(_this$attrs, arguments);
        } catch (exception) {
          /* no action handler */
        }
      },
      //
      // Step 3 (Mapping)
      //

      /**
       * Tries to update template's name.
       *
       * @event onSetTemplateName
       * @param {String} name
       * @return {Void}
       * @private
       */
      onSetTemplateName: function onSetTemplateName(name) {
        // try to set new name - could throw an
        // error if the new template not exists
        try {
          Ember.set(this, 'currentTemplate.name', name);
        } catch (exception) {
          return;
        } // persist template now


        this._saveTemplate();
      },

      /**
       * Updates template reference once the
       * mapping process has been finished.
       *
       * @event onMappingSuccess
       * @param {Object} template
       * @return {Void}
       * @private
       */
      onMappingSuccess: function onMappingSuccess(template) {
        // persist the new reference in property
        Ember.set(this, 'currentTemplate', template); // persist template now

        this._saveTemplate();

        try {
          var _this$attrs2;

          (_this$attrs2 = this.attrs).onMappingSuccess.apply(_this$attrs2, arguments);
        } catch (exception) {
          /* no action handler */
        }
      },
      //
      // Prefill
      //

      /**
       * Propages `onChange` to consumer.
       *
       * @event onChangePrefill
       * @param {Object} availableField
       * @param {Mixed} value
       * @return {Void}
       * @private
       */
      onChangePrefill: function onChangePrefill()
      /*availableField,value*/
      {
        try {
          var _this$attrs3;

          (_this$attrs3 = this.attrs).onChangePrefill.apply(_this$attrs3, arguments);
        } catch (exception) {
          /* no action handler */
        }
      },
      //
      // Edit / Save
      //

      /**
       * Sets `__bulk__grid__hot` on window object
       * for accessing it in testing environment.
       *
       * @event onGridInit
       * @return {Void}
       * @private
       */
      onGridInit: function onGridInit(table) {
        if (testing) {
          Ember.set(window, '__bulk__grid__hot', table);
        }
      },

      /**
       * Syncs `handsontableInput` with output data.
       *
       * @event onGridLoad
       * @return {Void}
       * @private
       */
      onGridLoad: function onGridLoad(table, outputData) {
        Ember.set(this, 'handsontableInput', outputData);
      },

      /**
       * Tries to invoke `onSave` action.
       *
       * @event onSave
       * @return {Void}
       * @private
       */
      onSave: function onSave() {
        this.keenTracking.addEvent('BulkWorkflowSave', this.currentStep);

        try {
          var _this$attrs4;

          (_this$attrs4 = this.attrs).onSave.apply(_this$attrs4, arguments);
        } catch (exception) {
          /* no action handler */
        }
      },

      /**
       * Tries to invoke `onFinish` action.
       *
       * @event onFinish
       * @return {Void}
       * @private
       */
      onFinish: function onFinish() {
        this.keenTracking.addEvent('BulkWorkflowFinish');

        try {
          var _this$attrs5;

          (_this$attrs5 = this.attrs).onFinish.apply(_this$attrs5, arguments);
        } catch (exception) {
          /* no action handler */
        }
      }
    },
    // -------------------------------------------------------------------------
    // Observers

    /**
     * Proxies `disabled` states to corresponding
     * step from `steps` map to avoid rerendering.
     *
     * @type observer _watchDisabled
     * @private
     */
    _watchDisabled: Ember.observer('importDisabled', 'uploadDisabled', 'mappingDisabled', 'prefillDisabled', function (sender, key) {
      switch (key) {
        case 'importDisabled':
          {
            var disabled = Ember.get(this, 'importDisabled');
            Ember.set(this, 'steps.import.disabled', disabled);
            break;
          }

        case 'uploadDisabled':
          {
            var _disabled = Ember.get(this, 'uploadDisabled');

            Ember.set(this, 'steps.upload.disabled', _disabled);
            break;
          }

        case 'mappingDisabled':
          {
            var _disabled2 = Ember.get(this, 'mappingDisabled');

            Ember.set(this, 'steps.mapping.disabled', _disabled2);
            break;
          }

        case 'prefillDisabled':
          {
            var _disabled3 = Ember.get(this, 'prefillDisabled');

            Ember.set(this, 'steps.prefill.disabled', _disabled3);
            break;
          }

        default:
      }
    })
  });

  _exports.default = _default;
});