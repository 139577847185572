define("@vollersgroup/hub-frontend/components/markdown-wysiwyg/component", ["exports", "quill", "marked"], function (_exports, _quill, _marked) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Markdown wysiwyg editor using Quill.
   *
   * ```html
   * {{markdown-wysiwyg content=content id="my_textarea"}}
   * ```
   *
   * @namespace Component
   * @class MarkdownWysiwyg
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    classNames: ['markdown-wysiwyg'],
    classNameBindings: ['hasError:markdown-wysiwyg--has-error'],
    attributeBindings: ['id:data-test-markdown-wysiwyg'],
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * Markdown content
     *
     * @attribute value
     * @type String
     * @public
     */
    content: '',

    /**
     * Indicated wheter editor should display error.
     *
     * @attribute hasError
     * @optional
     * @type Boolean
     * @default false
     */
    hasError: false,
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Private editor instance.
     *
     * @property _editor
     * @type String
     * @private
     */
    _editor: null,

    /**
     * Cached value avoid to set content twice.
     *
     * @attribute _cached
     * @type String
     * @private
     */
    _cached: null,
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Handles initial setup of our editor when the DOM's ready.
     *
     * @public
     * @method didInsertElement
     * @return {Void}
     */
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var editor = Ember.set(this, '_editor', this._makeEditor());
      editor.on('text-change', Ember.run.bind(this, this._setMarkdown));

      this._setHTML();
    },

    /**
     * Run when we do a re-render
     *
     * @public
     * @method didUpdateAttrs
     * @return {Void}
     */
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var content = Ember.get(this, 'content');

      var _cached = Ember.get(this, '_cached');

      if (_cached === content) {
        Ember.set(this, '_cached', null);
        return; // this *might* not do what we want
      }

      this._setHTML();
    },

    /**
     * Tears down editor on destruction.
     *
     * @public
     * @method willDestroyElement
     * @return {Void}
     */
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var editor = Ember.get(this, '_editor');
      editor.off('text-change', this._setMarkdown);
    },

    /**
     * Provides editor's current HTML contents.
     *
     * @private
     * @method _getHTML
     * @return {Void}
     */
    _getHTML: function _getHTML() {
      // https://github.com/quilljs/quill/issues/903
      return Ember.get(this, '_editor.root.innerHTML');
    },

    /**
     * Updates editor from markdown `content.`
     *
     * @private
     * @method _setHTML
     * @return {Void}
     */
    _setHTML: function _setHTML() {
      var clipboard = Ember.get(this, '_editor.clipboard');

      if (Ember.isNone(clipboard)) {
        return;
      }

      var markdown = Ember.get(this, 'content');
      var html = (0, _marked.default)(markdown || '');
      clipboard.dangerouslyPasteHTML(html);
    },

    /**
     * Updates our `content` value using editor html after initial setup, when values change or when we're tearing down
     *
     * @private
     * @method _setMarkdown
     * @return {Void}
     */
    _setMarkdown: function _setMarkdown(delta, oldDelta, source) {
      var markdown = toMarkdown(this._getHTML());

      if (source !== 'api') {
        if (this.id == 'shop_story') {
          console.warn('we just updated our content as our editor sent us new changes that were not triggered by us');
        }

        Ember.set(this, 'content', markdown);

        if (this.onFocusOut) {
          if (this.id == 'shop_story') {
            console.warn('we just triggered our focus out action as well');
          }

          this.onFocusOut();
        }
      }

      Ember.set(this, '_cached', markdown);
    },

    /**
     * Factory for quill editor.
     *
     * @private
     * @method _makeEditor
     * @return {Object}
     */
    _makeEditor: function _makeEditor() {
      return new _quill.default('#' + this.attrs.id, {
        modules: {
          toolbar: [['bold', 'italic', 'link'], [{
            list: 'ordered'
          }, {
            list: 'bullet'
          }], [{
            header: [false, 2, 3]
          }], ['clean']]
        },
        formats: ['bold', 'italic', 'link', 'header', 'list'],
        theme: 'snow'
      });
    }
  });

  _exports.default = _default;
});