define("@vollersgroup/hub-frontend/admin/company/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "566RAgGD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\"],[11,\"class\",\"font-size-3\"],[9],[0,\"\\n  \"],[1,[27,\"t\",[\"Company\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[27,\"company-settings-form\",null,[[\"company\",\"onSaveCompany\"],[[27,\"readonly\",[[23,[\"model\"]]],null],\"saveCurrentCompany\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/admin/company/index/template.hbs"
    }
  });

  _exports.default = _default;
});