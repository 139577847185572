define("@vollersgroup/hub-frontend/admin/messages/offerings/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    messageCenter: Ember.inject.service(),
    actions: {
      remove: function remove(conversation) {
        conversation.deleteRecord();
        conversation.save();
      },
      dismiss: function dismiss(conversation) {
        conversation.set('toCompanyUnreadCount', 0);
        conversation.save();
      }
    }
  });

  _exports.default = _default;
});