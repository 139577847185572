define("@vollersgroup/hub-frontend/instance-initializers/global", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(applicationInstance) {
    // invoke singleton creation to invoke loading
    // of corresponding language files as soon as
    // possible when using l10n by global methods
    applicationInstance.lookup('service:l10n');
    window.AppInstance = applicationInstance;
  }

  var _default = {
    name: 'global',
    initialize: initialize
  };
  _exports.default = _default;
});