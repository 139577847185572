define("@vollersgroup/hub-frontend/components/pricing-plans/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/jaKKqNi",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"bg-gray-50\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"relative bg-v-blue-500\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"aria-hidden\",\"true\"],[11,\"class\",\"hidden absolute bg-gray-50 w-full h-4 bottom-0 lg:block z-30\"],[9],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"relative max-w-2xl mx-auto pt-16 px-4 text-center sm:pt-32 sm:px-6 lg:max-w-7xl lg:px-8\"],[9],[0,\"\\n      \"],[7,\"h1\"],[11,\"class\",\"text-4xl font-extrabold tracking-tight text-white sm:text-6xl\"],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"block lg:inline\"],[9],[0,\"Simple pricing,\"],[10],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"block lg:inline\"],[9],[0,\"no commitment.\"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"mt-4 text-xl text-v-blue-200\"],[9],[0,\"Everything you need, nothing you don't. Pick a plan that best suits your business.\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"h2\"],[11,\"class\",\"sr-only\"],[9],[0,\"Plans\"],[10],[0,\"\\n\\n    \"],[1,[27,\"pricing-plans/billing-toggle\",null,[[\"billingType\",\"onChange\"],[[22,0,[\"billingType\"]],[27,\"action\",[[22,0,[]],\"onBillingChanged\"],null]]]],false],[0,\"\\n\\n    \"],[1,[27,\"pricing-plans/cards\",null,[[\"currentPlan\",\"billingType\",\"currency\",\"plans\",\"cards\"],[[22,0,[\"currentPlan\"]],[22,0,[\"billingType\"]],[22,0,[\"currency\"]],[22,0,[\"data\",\"plans\"]],[22,0,[\"data\",\"cards\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[1,[27,\"pricing-plans/feature-comparison\",null,[[\"currentPlan\",\"plans\",\"features\"],[[22,0,[\"currentPlan\"]],[22,0,[\"data\",\"plans\"]],[22,0,[\"data\",\"features\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/pricing-plans/template.hbs"
    }
  });

  _exports.default = _default;
});