define("@vollersgroup/hub-frontend/admin/manage/overview/producer/controller", ["exports", "@vollersgroup/hub-frontend/mixins/orderable", "@vollersgroup/hub-frontend/utils/country-data"], function (_exports, _orderable, _countryData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_orderable.default, {
    // -------------------------------------------------------------------------
    // Dependencies
    can: Ember.inject.service(),
    l10n: Ember.inject.service(),
    userSession: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Query parameter definition.
     *
     * @property queryParams
     * @type {Array}
     * @public
     */
    queryParams: [{
      selectedCountry: {
        as: 'country'
      }
    }, {
      showArchived: {
        as: 'archived'
      }
    }],

    /**
     * ISO-code
     *
     * @public
     * @property selectedCountry
     * @type String
     */
    selectedCountry: null,

    /**
     * If only archived producers should be displayed.
     *
     * @property showArchived
     * @type Boolean
     * @default false
     */
    showArchived: false,

    /**
     * All producers for this company.
     *
     * @property producers
     * @type Model.Producer[]
     */
    producers: Ember.computed('orderBy', 'selectedCountry', 'showArchived', 'store', 'userSession.currentCompany.id', {
      get: function get()
      /*key*/
      {
        var _this = this;

        var query = {
          company: Ember.get(this, 'userSession.currentCompany.id'),
          sort: Ember.get(this, 'orderBy')
        };

        if (Ember.get(this, 'selectedCountry')) {
          query.country = Ember.get(this, 'selectedCountry');
        }

        query.archived = this.get('showArchived');
        var promise = this.store.query('producer', query);
        promise.then(function () {
          _this.send('toggleSelectAll', false);
        });
        return promise;
      }
    }),

    /**
     * All producers for this company (without filtering applied)
     *
     * @property allProducers
     * @type Model.Producer[]
     */
    allProducers: Ember.computed('store', 'userSession.currentCompany.id', {
      get: function get()
      /*key*/
      {
        return this.store.query('producer', {
          company: Ember.get(this, 'userSession.currentCompany.id')
        });
      }
    }),

    /**
     * Options for order dropdown.
     *
     * @property orderByOptions
     * @type Array
     */
    orderByOptions: Ember.computed('l10n', {
      get: function get()
      /*key*/
      {
        return [{
          value: 'DATE',
          label: Ember.get(this, 'l10n').t('Most recent')
        }, {
          value: 'ORIGIN',
          label: Ember.get(this, 'l10n').t('Country')
        }, {
          value: 'NAME',
          label: Ember.get(this, 'l10n').t('Origin name')
        }, {
          value: 'FARM_NAME',
          label: Ember.get(this, 'l10n').t('Farm name')
        }, {
          value: 'TYPE',
          label: Ember.get(this, 'l10n').t('Farm type')
        }];
      }
    }),

    /**
     * Options for country filter dropdown.
     *
     * @property countryOptions
     * @type Array
     */
    countryOptions: Ember.computed('allProducers.[]', {
      get: function get()
      /*key*/
      {
        var cache = {};
        var options = [];
        Ember.get(this, 'allProducers').forEach(function (producer) {
          var countryCode = producer.get('location.country');
          var country = _countryData.default[countryCode];

          if (!country || cache[countryCode]) {
            return;
          }

          options.push({
            label: country,
            value: countryCode
          });
          cache[countryCode] = true;
        });
        return options;
      }
    }),

    /**
     * Currently active listings as hashmap,
     * where all selected ids are persisted.
     *
     * @attribute selectedMap
     * @type {Object}
     */
    selectedMap: Ember.computed({
      get: function get()
      /*key*/
      {
        return {};
      }
    }),

    /**
     * Currently active listings as hashmap,
     * where all selected ids are persisted.
     *
     * @attribute selectedProducers
     * @type {Object}
     */
    selectedProducers: Ember.computed({
      get: function get()
      /*key*/
      {
        return [];
      }
    }),
    quickLinkProducerOptions: Ember.computed('can', 'l10n', function () {
      var quickLinksProducerOptions = [{
        label: this.get('l10n').t('Add one'),
        route: 'admin.manage.producer.create'
      }];

      if (Ember.get(this, 'can').can('edit settings')) {
        quickLinksProducerOptions.push({
          label: this.get('l10n').t('Add many'),
          route: 'admin.manage.bulk.producer'
        });
      }

      return quickLinksProducerOptions;
    }),
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Archive a producer.
       *
       * @event archiveProducer
       * @param Model.Producer producer
       * @private
       */
      archiveProducer: function archiveProducer(producer) {
        var _this2 = this;

        producer.set('archived', true);
        producer.save().then(function () {
          _this2.notifyPropertyChange('orderBy');

          _this2.get('toast').info(_this2.get('l10n').t('The origin has been archived. It will still show up in related listings.'));
        });
      },

      /**
       * Unarchive a producer.
       *
       * @event unarchiveProducer
       * @param Model.Producer producer
       * @private
       */
      unarchiveProducer: function unarchiveProducer(producer) {
        var _this3 = this;

        producer.set('archived', false);
        producer.save().then(function () {
          _this3.notifyPropertyChange('orderBy');

          _this3.get('toast').info(_this3.get('l10n').t('The origin has been unarchived.'));
        });
      },

      /**
       * Invokes 'selectMultiple' action.
       *
       * @event toggleSelect
       * @param {Model.Producer} producer
       * @param {Boolean} checked
       * @private
       */
      toggleSelect: function toggleSelect(producer, checked) {
        var update = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

        if (!Ember.get(producer, 'isEditable')) {
          return;
        }

        var map = Ember.get(this, 'selectedMap');
        var id = Ember.get(producer, 'id');

        if (checked) {
          Ember.set(map, id, producer);
        } else {
          Ember.set(map, id, null);
          delete map[id];
        }

        if (update) {
          Ember.set(this, 'selectedProducers', Object.keys(map));
        }
      },

      /**
       * (Un)checks all items and invokes 'selectMultiple' action.
       *
       * @event toggleSelectAll
       * @param {Boolean} checked
       * @private
       */
      toggleSelectAll: function toggleSelectAll(checked) {
        var _this4 = this;

        Ember.get(this, 'producers').forEach(function (producer) {
          _this4.send('toggleSelect', producer, checked, false);
        });
        var map = Ember.get(this, 'selectedMap');
        Ember.set(this, 'selectedProducers', Object.keys(map));
      },

      /**
       * Transitions to bulk-edit view with selected producers as query param.
       *
       * @event editSelection
       * @private
       */
      editSelection: function editSelection() {
        this.transitionToRoute('admin.manage.bulk.producer-edit', {
          queryParams: {
            ids: Ember.get(this, 'selectedProducers')
          }
        });
      }
    }
  });

  _exports.default = _default;
});