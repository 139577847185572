define("@vollersgroup/hub-frontend/admin/manage/overview/warehouses/create/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9mKqpcR2",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"page-title\",[[27,\"t\",[\"Create Warehouse\"],null]],null],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"wrapper padding-bottom-3\"],[9],[0,\"\\n  \"],[7,\"h1\"],[11,\"class\",\"font-size-3\"],[9],[1,[27,\"t\",[\"Create Warehouse\"],null],false],[10],[0,\"\\n\"],[4,\"if\",[[22,0,[\"legacyWarehouseMigration\",\"selectedLegacyWarehouse\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\"],[11,\"class\",\"text-gray-500\"],[9],[0,\"\\n      \"],[5,\"get-text\",[],[[\"@message\"],[[27,\"t\",[\"Please fill in the address and contact information for the warehouse {{WAREHOUSE_NAME}}.\"],null]]],{\"statements\":[[0,\"\\n        \"],[7,\"b\"],[9],[1,[22,0,[\"legacyWarehouseMigration\",\"selectedLegacyWarehouse\",\"name\"]],false],[10],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"wrapper padding-bottom-4\"],[9],[0,\"\\n  \"],[1,[27,\"warehouses/create-form\",null,[[\"owner\"],[[23,[\"model\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/admin/manage/overview/warehouses/create/template.hbs"
    }
  });

  _exports.default = _default;
});