define("@vollersgroup/hub-frontend/components/message-list-item/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A short preview of a conversation
   *
   * ```html
   * {{message-list-item conversation=conversation}}
   * ```
   *
   * @namespace Component
   * @class MessageListItem
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    userSession: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Element Properties

    /**
     * @property tagName
     * @type String
     * @final
     * @private
     */
    tagName: '',
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * The name of the filter category this item belongs to.
     *
     * @attribute conversation
     * @type Model.Conversation
     * @required
     */
    conversation: null,
    // -------------------------------------------------------------------------
    // Properties
    thresholdDate: Ember.computed(function () {
      return (0, _moment.default)().subtract(7, 'days');
    })
  });

  _exports.default = _default;
});