define("@vollersgroup/hub-frontend/components/embeded-video/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qbfHzYas",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"isYoutube\"]]],null,{\"statements\":[[0,\"  \"],[7,\"iframe\"],[12,\"src\",[28,[\"https://www.youtube.com/embed/\",[21,\"videoId\"],\"?rel=0&showinfo=0\"]]],[11,\"class\",\"aspect-ratio--object\"],[11,\"frameborder\",\"0\"],[11,\"allow\",\"autoplay; encrypted-media\"],[11,\"allowfullscreen\",\"\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"isVimeo\"]]],null,{\"statements\":[[0,\"  \"],[7,\"iframe\"],[12,\"src\",[28,[\"https://player.vimeo.com/video/\",[21,\"videoId\"],\"?title=0&byline=0&portrait=1\"]]],[11,\"class\",\"aspect-ratio--object\"],[11,\"frameborder\",\"0\"],[11,\"allowfullscreen\",\"\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"info-message\",null,[[\"type\",\"class\"],[\"WARNING\",\"margin-bottom-2\"]],{\"statements\":[[0,\"    \"],[1,[27,\"t\",[\"The url \\\"{{url}}\\\" is not supported. Please paste Youtube or Vimeo share link.\"],[[\"url\"],[[23,[\"url\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/embeded-video/template.hbs"
    }
  });

  _exports.default = _default;
});