define("@vollersgroup/hub-frontend/services/keen-tracking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import { later } from '@ember/runloop';
  //import config from '@vollersgroup/hub-frontend/config/environment';
  var _default = Ember.Service.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    userSession: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties
    init: function init() {
      this._super.apply(this, arguments);
    },

    /**
     * Provides the current page url from `window.location.href`.
     *
     * @property _pageUrl
     * @type String
     * @private
     */
    _pageUrl: Ember.computed({
      get: function get() {
        return window.location.href;
      },
      set: function set(key, value, oldValue) {
        if (oldValue && value !== oldValue) {
          Ember.set(this, '_referrerUrl', oldValue);
        }

        return value;
      }
    }),

    /**
     * As `document.referrer` doesn't work for SPA, we've to track it on our own.
     *
     * @property _referrerUrl
     * @type String
     * @private
     */
    _referrerUrl: document.referrer,

    /**
     * Keen addons to be mixed in with custom event data.
     * Please see: https://keen.io/docs/data-collection/
     *
     * @method _getAddons
     * @private
     * @return {Object}
     */
    _getAddons: function _getAddons() {
      // set page url to reflect referrer changes
      Ember.set(this, '_pageUrl', window.location.href);
      var width = window.innerWidth;
      var height = window.innerHeight;
      var biggerDimension = Math.max(width, height);
      var smallerDimenstion = Math.min(width, height);
      var ratio = calculateRatio(biggerDimension, smallerDimenstion); // and provide updated tracking information

      return {
        page: {
          url: Ember.get(this, '_pageUrl')
        },
        referrer: {
          url: Ember.get(this, '_referrerUrl')
        },
        device: {
          locale: window.navigator.language,
          screen: {
            width: width,
            height: height,
            bigger_dimension: biggerDimension,
            smaller_dimension: smallerDimenstion,
            is_landscape: width > height,
            ratio: "".concat(ratio[0], ":").concat(ratio[1])
          }
        }
      };
    },

    /**
     * Send an event to immediately.
     *
     * @method addEvent
     * @param {String} eventName The name of the collection
     * @param {Object} eventData The data to send to the collection
     */
    addEvent: function addEvent(_eventName, _eventData) {}
  });

  _exports.default = _default;

  function calculateRatio(width, height) {
    var w = width / height;
    var h = 1;
    var ratio = w;

    while (w % 1 || h % 1) {
      h++;
      w = h * ratio;
    }

    return [w, h];
  }
});