define("@vollersgroup/hub-frontend/reset-password/controller", ["exports", "@vollersgroup/hub-frontend/mixins/error-validation"], function (_exports, _errorValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_errorValidation.default, {
    ajax: Ember.inject.service(),
    queryParams: ['token', 'email'],
    actions: {
      resetPassword: function resetPassword() {
        var _this = this;

        if (this.get('isLoading')) {
          return;
        }

        if (!this.checkValidations()) {
          return;
        }

        this.set('allErrors', []);
        this.set('isLoading', true);
        this.get('ajax').post('/user/resetPassword', {
          data: {
            token: this.get('token'),
            newPassword: this.get('newPassword')
          }
        }).then(function () {
          _this.get('session').authenticate('authenticator:custom', {
            email: _this.get('email'),
            password: _this.get('newPassword')
          }).then(function () {
            _this.set('newPassword', null);

            _this.set('token', null);

            _this.set('email', null);

            _this.transitionToRoute('admin');
          }).catch(function () {
            _this.set('newPassword', null);

            _this.set('token', null);

            _this.set('email', null);

            _this.transitionToRoute('index');
          });
        }).catch(function (error) {
          var serverErrorMessage = Ember.get(error, 'payload.error');

          var errorMessage = _this.get('l10n').t('An error occurred: {{serverErrorMessage}}', {
            serverErrorMessage: serverErrorMessage
          });

          _this.set('allErrors', [{
            errorMessage: errorMessage
          }]);
        }).finally(function () {
          _this.set('isLoading', false);
        });
      }
    },

    /**
     * If a loading process is currently happening.
     *
     * @property isLoading
     * @type Boolean
     * @default false
     */
    isLoading: false,

    /**
     * The token of the user. This comes from the link in the email.
     *
     * @property token
     * @type String
     */
    token: null,

    /**
     * The email/username of the user. This comes from the link in the email.
     *
     * @property email
     * @type String
     */
    email: null,

    /**
     * The new password for the user.
     *
     * @property newPassword
     * @type String
     */
    newPassword: null,

    /**
     * Validations configuration.
     *
     * @property validations
     * @type Array
     */
    validations: Ember.computed('l10n', function () {
      var _this2 = this;

      return [{
        field: 'newPassword',
        validate: function validate(val) {
          return _this2._validateLength(val, 8);
        },
        errorTitle: this.get('l10n').t('Password'),
        errorMessage: this.get('l10n').t('Your password must have at least 8 characters.')
      }];
    })
  });

  _exports.default = _default;
});