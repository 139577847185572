define("@vollersgroup/hub-frontend/admin/sales/buyer/route", ["exports", "@vollersgroup/hub-frontend/mixins/reset-scroll"], function (_exports, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_resetScroll.default, {
    model: function model(params) {
      return this.store.findRecord('order', params.order_id);
    }
  });

  _exports.default = _default;
});