define("@vollersgroup/hub-frontend/components/auction-slug-input/component", ["exports", "@vollersgroup/hub-frontend/utils/normalize-for-slug", "@vollersgroup/hub-frontend/components/validated-form-component/component"], function (_exports, _normalizeForSlug, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    router: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    auction: null,
    model: Ember.computed.alias('auction'),
    valuePath: Ember.computed(function () {
      return 'slug';
    }),
    slugBaseUrl: Ember.computed('router', function () {
      var protocol = window.location.protocol;
      var hostname = window.location.hostname;
      var port = window.location.port ? ':' + window.location.port : '';
      var routePath = 'store.auctions.show';
      var path = Ember.get(this, 'router').urlFor(routePath, null);
      var parts = path.split('/'); // Remove last part of the url
      // (e.g.: /store/auction/:auction_id -> /store/auction)

      parts.splice(-1);
      path = parts.join('/');
      return "".concat(protocol, "//").concat(hostname).concat(port).concat(path, "/");
    }),
    _suggestSlug: Ember.observer('auction.name', function () {
      var name = Ember.get(this, 'auction.name');

      if (!Ember.get(this, 'auction.isNew') || Ember.isBlank(name)) {
        return;
      }

      Ember.run.debounce(this, this._debouncedSlugUpdate, 500);
    }),
    _debouncedSlugUpdate: function _debouncedSlugUpdate() {
      var name = Ember.get(this, 'auction.name');
      this.send('updateSlug', (0, _normalizeForSlug.default)(name));
    },
    slugIsUnique: Ember.computed('validation.error.type', function () {
      var errorType = Ember.get(this, 'validation.error.type');
      return errorType != 'unique-auction-slug';
    }),
    suggestedSlugYear: Ember.computed('auction.slug', function () {
      var slug = Ember.get(this, 'auction.slug');
      return slug + '-' + new Date().getFullYear().toString();
    }),
    suggestedSlugNumeric: Ember.computed('auction.slug', function () {
      var slug = Ember.get(this, 'auction.slug');
      return slug + '-' + '1';
    }),
    actions: {
      updateSlug: function updateSlug(slug) {
        Ember.set(this, 'auction.slug', slug);
      }
    }
  });

  _exports.default = _default;
});