define("@vollersgroup/hub-frontend/components/manage-verification/component", ["exports", "@vollersgroup/hub-frontend/utils/scroll-into-the-first-error-if-needed", "ember-cp-validations"], function (_exports, _scrollIntoTheFirstErrorIfNeeded, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    phone: (0, _emberCpValidations.validator)('format', {
      allowBlank: false,
      // this is very basic validation that provided value is phone number
      // It must contain at least one number
      regex: /\d/,
      message: 'Please enter a phone number.'
    })
  });
  /**
   * A module to verify that you are a valid seller.
   *
   * ```html
   * {{manage-verification action="myAction"}}
   * ```
   *
   * The action is called when a verification request has been successfully made.
   *
   * @namespace Component
   * @class ManageVerification
   * @extends Ember.Component
   * @uses Mixin.ErrorValidation
   */

  var _default = Ember.Component.extend(Validations, {
    userSession: Ember.inject.service(),
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    l10n: Ember.inject.service(),
    toast: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * The name of the action to call when the verification is done.
     * This action has no parameters.
     *
     * @attribute action
     * @type string
     * @required
     */
    action: null,

    /**
     * Flag indicating whether all error messages should be displayed.
     */
    didValidate: false,
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Submit the phone number and continue.
       * This calls the default action of this component.
       *
       * @event verify
       * @private
       */
      verify: function verify() {
        var _this = this;

        this.validate().then(function (_ref) {
          var validations = _ref.validations;

          _this.set('didValidate', true);

          if (!validations.get('isValid')) {
            (0, _scrollIntoTheFirstErrorIfNeeded.default)();
            return Promise.reject();
          }
        }).then(function () {
          _this.set('isLoading', true);

          return _this.get('ajax').post('company/verification', {
            contentType: 'application/json; charset=utf-8',
            data: JSON.stringify({
              phoneNumber: _this.get('phone')
            })
          }).then(function () {
            Ember.run.next(function () {
              _this.get('userSession.currentCompany').setProperties({
                verificationStatus: 'PENDING',
                verificationRequestedDate: new Date().getTime() // Current time as UTC timestamp

              });

              _this.sendAction('action');
            });
          }).catch(function () {
            _this.set('isLoading', false);

            var errorMessage = _this.get('l10n').t('An error occurred. Please review your phone number and try again or contact our support team.');

            _this.get('toast').error(errorMessage);
          });
        });
      },

      /**
       * Continue without changing the verification status.
       *
       * @event continue
       * @private
       */
      continue: function _continue() {
        this.sendAction();
      },

      /**
       * Cancel the creation and redirect back to home.
       *
       * @event cancel
       * @private
       */
      cancel: function cancel() {
        this.get('router').transitionTo('index');
      },
      goToAndScrollToAnchor: function goToAndScrollToAnchor(route, selector) {
        var _this2 = this;

        this.get('router').transitionTo(route).then(function () {
          Ember.run.scheduleOnce('afterRender', _this2, function () {
            var target = document.querySelector(selector);

            if (target instanceof HTMLElement) {
              target.scrollIntoView();
            }
          });
        });
      }
    },
    // -------------------------------------------------------------------------
    // Properties

    /**
     * The phone number that should be used for verification.
     *
     * @property phone
     * @type String
     * @private
     */
    phone: '',

    /**
     * When this is true, some computation is currently occurring.
     *
     * @property isLoading
     * @type Boolean
     * @private
     */
    isLoading: false
  });

  _exports.default = _default;
});