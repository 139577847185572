define("@vollersgroup/hub-frontend/services/bulk-asset-loader", ["exports", "ember-bulk-manager/services/bulk-asset-loader"], function (_exports, _bulkAssetLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _bulkAssetLoader.default;
    }
  });
});