define("@vollersgroup/hub-frontend/components/c-select-country/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "urDz+USl",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"c-select\",null,[[\"id\",\"placeholder\",\"value\",\"required\",\"options\",\"searchable\",\"class\",\"disabled\",\"hasError\",\"action\"],[[23,[\"id\"]],[23,[\"placeholder\"]],[23,[\"value\"]],[23,[\"required\"]],[23,[\"options\"]],true,[23,[\"class\"]],[23,[\"disabled\"]],[23,[\"hasError\"]],\"updateAction\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/c-select-country/template.hbs"
    }
  });

  _exports.default = _default;
});