define("@vollersgroup/hub-frontend/components/filter-item-array/component", ["exports", "@vollersgroup/hub-frontend/components/filter-item/component", "awesomplete"], function (_exports, _component, _awesomplete) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A filter item handling filter type "ARRAY" with checkboxes
   *
   * ```html
   * {{filter-item-array filter=filter update=(action "{YOUR_ACTION}")}}
   * ```
   *
   * @namespace Component
   * @class FilterItemArray
   * @extends Component.FilterItem
   */
  var _default = _component.default.extend({
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Amount of total items used as limit for showing search box.
     *
     * @property displayable
     * @type {Number}
     */
    maxItems: 6,

    /**
     * Amount of displayed items if exceeding limit of `maxItems`.
     *
     * @property displayItems
     * @type {Number}
     */
    displayItems: 4,

    /**
     * If there are more than six items, an autocomplete
     * searchbox will be shown below the checkbox list.
     *
     * @property searchable
     * @type {Boolean}
     */
    searchable: Ember.computed('filter.items.length', 'maxItems', function () {
      var count = Ember.get(this, 'filter.items.length');
      var max = Ember.get(this, 'maxItems');
      return count > max;
    }),

    /**
     * Finds selected item for form sub component by `active`.
     *
     * @property selectedItem
     * @type {Object}
     */
    filteredItems: Ember.computed('displayItems', 'filter.items', 'searchable', function () {
      var items = Ember.get(this, 'filter.items');

      if (!Ember.get(this, 'searchable')) {
        return items;
      }

      return items.slice(0, Ember.get(this, 'displayItems'));
    }),
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Invokes update() with selected item from
       * search input field using autocompletion.
       *
       * @event select
       * @param {String} name Name of selected item.
       * @private
       */
      select: function select(name) {
        var items = Ember.get(this, 'filter.items');
        var item = items.findBy('name', name);

        if (Ember.isNone(item)) {
          return;
        }

        Ember.set(item, 'active', true);
        this.send('update', item);
      }
    },
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Adds autocomplete feature.
     * Triggers select() action
     * with selected item name.
     *
     * @method didInsertElement
     * @return {Void}
     */
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      if (!Ember.get(this, 'searchable')) {
        return;
      }

      var items = Ember.get(this, 'filter.items');
      var input = this.$('input[type=text]');
      var list = items.map(function (item) {
        return Ember.get(item, 'name');
      });
      new _awesomplete.default(input.get(0), {
        list: list,
        minChars: 1,
        autoFirst: true
      });
      input.on('awesomplete-selectcomplete', function () {
        Ember.run.scheduleOnce('actions', function () {
          _this.send('select', input.val());

          Ember.set(_this, 'noResults', false);
          input.val('');
        });
      });
      input.on('awesomplete-close', function () {
        Ember.run.scheduleOnce('sync', function () {
          if (!input.is(':focus')) {
            Ember.set(_this, 'noResults', false);
            return;
          }

          Ember.set(_this, 'noResults', !!input.val());
        });
      });
      input.on('awesomplete-open', function () {
        Ember.set(_this, 'noResults', false);
      });
    }
  });

  _exports.default = _default;
});