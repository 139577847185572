define("@vollersgroup/hub-frontend/store/listing/controller", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    shoppingCart: Ember.inject.service(),
    userSession: Ember.inject.service(),
    store: Ember.inject.service(),
    keenTracking: Ember.inject.service(),
    queryParams: ['addToWishlist'],
    addToWishlist: 0,
    activeTabIndex: 0,
    actions: {
      toggleProducer: function toggleProducer(show) {
        this.set('showProducerMore', show);
      },
      addToWishlist: function addToWishlist() {
        var _this = this;

        return Ember.get(this, 'store').createRecord('wishlist-item', {
          user: Ember.get(this, 'userSession.currentUser'),
          listing: Ember.get(this, 'model'),
          created: (0, _moment.default)()
        }).save().then(function () {
          var message = Ember.get(_this, 'l10n').t('{{coffeeName}} is now on your wish list.', {
            coffeeName: Ember.get(_this, 'model.name')
          });
          Ember.get(_this, 'toast').success(message);
          Ember.get(_this, 'keenTracking').addEvent('add-to-wishlist-on-listing-page', {
            listingName: Ember.get(_this, 'model.name'),
            listingId: Ember.get(_this, 'model.id')
          });
        });
      },
      removeFromWishlist: function removeFromWishlist() {
        var _this2 = this;

        return Ember.get(this, 'model.wishlistItem').destroyRecord().then(function () {
          var message = Ember.get(_this2, 'l10n').t('{{coffeeName}} was removed from your wish list.', {
            coffeeName: Ember.get(_this2, 'model.name')
          });
          Ember.get(_this2, 'toast').success(message);
          Ember.get(_this2, 'keenTracking').addEvent('remove-from-wishlist-on-listing-page', {
            listingName: Ember.get(_this2, 'model.name'),
            listingId: Ember.get(_this2, 'model.id')
          });
        });
      }
    }
  });

  _exports.default = _default;
});