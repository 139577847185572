define("@vollersgroup/hub-frontend/components/manage-warehouse-modal/component", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A modal to edit warehouse.
   */
  var _default = Ember.Component.extend(_emberCan.CanMixin, {
    store: Ember.inject.service(),
    userSession: Ember.inject.service(),
    availableData: Ember.inject.service(),
    toast: Ember.inject.service(),
    didValidate: false,
    showAddressAddition: false,
    address: null,
    availablePrefillAddresses: Ember.computed.alias('availableData.prefillWarehouses'),
    prefillAddressOptions: Ember.computed.map('availablePrefillAddresses', function (item, i) {
      return {
        value: i,
        label: item.name
      };
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this._loadAddress();
    },
    _loadAddress: function _loadAddress() {
      var _this = this;

      var editWarehouseId = Ember.get(this, 'editWarehouseId');

      if (editWarehouseId) {
        Ember.get(this, 'store').findRecord('location', editWarehouseId).then(function (address) {
          return Ember.set(_this, 'address', address);
        }).catch(function () {
          return _this.send('dismissModal');
        });
      } else {
        Ember.set(this, 'address', Ember.get(this, 'store').createRecord('location', {
          type: 'WAREHOUSE',
          company: Ember.get(this, 'userSession.currentCompany')
        }));
      }
    },
    willRender: function willRender() {
      this._super.apply(this, arguments);

      if (!this.can('edit location')) {
        this.send('dismissModal');
      }
    },
    actions: {
      prefillAddress: function prefillAddress(selectedPrefillAddress) {
        var addresses = Ember.get(this, 'availablePrefillAddresses');
        var address = addresses[selectedPrefillAddress];

        if (address) {
          Ember.get(this, 'address').setProperties({
            name: address.name,
            country: address.country,
            city: address.city,
            street: address.street,
            addressAddition: address.addressAddition,
            zip: address.zip,
            state: address.state
          });
        } else {
          Ember.get(this, 'address').setProperties({
            name: null,
            country: null,
            city: null,
            street: null,
            addressAddition: null,
            zip: null,
            state: null
          });
        }
      },
      updateWarehouse: function updateWarehouse() {
        var _this2 = this;

        var address = Ember.get(this, 'address');
        address.validate().then(function (_ref) {
          var validations = _ref.validations;
          Ember.set(_this2, 'didValidate', true);

          if (!validations.get('isValid')) {
            return Ember.RSVP.Promise.reject();
          }
        }).then(function () {
          address.save().then(function () {
            var message = Ember.get(_this2, 'l10n').t('The address has been saved.');
            Ember.get(_this2, 'toast').success(message);

            _this2.get('availableData').notifyPropertyChange('locations');

            _this2.send('dismissModal');
          }).catch(function () {
            var message = Ember.get(_this2, 'l10n').t('An error has occurred! Please try again later.');
            Ember.get(_this2, 'toast').error(message);
          });
        });
      },
      toggleAddress2: function toggleAddress2() {
        this.toggleProperty('showAddressAddition');
      },
      dismissModal: function dismissModal() {
        if (Ember.get(this, 'address') && !Ember.get(this, 'address.isRejected') && Ember.get(this, 'address.id')) {
          Ember.get(this, 'address').rollbackAttributes();
        }

        return this.attrs.closeModal();
      }
    }
  });

  _exports.default = _default;
});