define("@vollersgroup/hub-frontend/services/local-storage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Provides a generic interface for accessing local storage
   * with fallback to cookie
   *
   * @namespace Service
   * @class LocalStorage
   * @extends Ember.Service
   */
  var _default = Ember.Service.extend({
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Flag indicating if cookie storage should be forced.
     * Extend the service if you want to override this.
     *
     * @property useCookies
     * @type {Boolean}
     * @default false
     * @public
     */
    useCookies: false,

    /**
     * Corresponding storage layer depending on browser
     * support and current setting of `useCookies` prop.
     * This will be lazy initialized in `init` method.
     *
     * @property storage
     * @type {Object}
     * @private
     */
    _storage: null,
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Initializes the corresponding storage layer depending
     * on browser support and current setting of `useCookies`.
     *
     * @public
     * @method init
     * @return {Void}
     */
    init: function init() {
      this._super.apply(this, arguments);

      var useCookies = this.get('useCookies') || !this.supportsLocalStorage();
      this.set('_storage', useCookies ? this._getCookieProxy() : this._getLocalStorageProxy());
    },

    /**
     * Checks if browser supports local storage.
     *
     * @public
     * @method supportsLocalStorage
     * @return {Boolean}
     */
    supportsLocalStorage: function supportsLocalStorage() {
      try {
        return 'localStorage' in window && window['localStorage'] !== null;
      } catch (e) {
        return false;
      }
    },

    /**
     * Retrieves an item from store if it exists.
     *
     * @public
     * @method getItem
     * @param {String} key
     * @return {Mixed}
     */
    getItem: function getItem() {
      var _this$get;

      return (_this$get = this.get('_storage')).getItem.apply(_this$get, arguments);
    },

    /**
     * Retrieves all items from store.
     *
     * @public
     * @method getAllItems
     * @return {Mixed}
     */
    getAllItems: function getAllItems() {
      return this.get('_storage').getAllItems();
    },

    /**
     * Pushes an item to store.
     *
     * @public
     * @method setItem
     * @param {String} key
     * @param {Mixed} value
     * @param {String} [expires] Only for cookie storage.
     * @param {String} [domain] Only for cookie storage.
     * @param {String} [path] Only for cookie storage.
     * @param {Boolean} [secure] Only for cookie storage.
     * @return {Void}
     */
    setItem: function setItem() {
      var _this$get2;

      (_this$get2 = this.get('_storage')).setItem.apply(_this$get2, arguments);
    },

    /**
     * Removes an item from store if it exists.
     *
     * @public
     * @method remove
     * @param {String} key
     * @return {Void}
     */
    removeItem: function removeItem() {
      var _this$get3;

      (_this$get3 = this.get('_storage')).removeItem.apply(_this$get3, arguments);
    },

    /**
     * Removes all items from store.
     *
     * @public
     * @method removeAllItems
     * @return {Void}
     */
    removeAllItems: function removeAllItems() {
      this.get('_storage').removeAllItems();
    },

    /**
     * Stringifies and uri encodes a value.
     *
     * @private
     * @param {Mixed} value
     * @method _encode
     *
     * @return {String}
     */
    _encode: function _encode(value) {
      try {
        value = JSON.stringify(value);
      } catch (e) {
        value = undefined;
      }

      return encodeURIComponent(value);
    },

    /**
     * Decodes a stringified and uri encoded value.
     *
     * @private
     * @param value
     * @method _decodeValue
     *
     * @return {Mixed}
     */
    _decode: function _decode(value) {
      var decoded;

      switch (Ember.typeOf(value)) {
        case 'string':
          decoded = decodeURIComponent(value);

          try {
            decoded = JSON.parse(decoded);
          } catch (e) {
            /* noop */
          }

          break;

        default:
          decoded = undefined;
      }

      if (decoded === 'undefined') {
        decoded = undefined;
      }

      if (decoded === undefined) {
        decoded = null;
      }

      return decoded;
    },

    /**
     * Provides cookie storage proxy layer.
     *
     * @private
     * @method _getCookieProxy
     *
     * @return {Object}
     */
    _getCookieProxy: function _getCookieProxy() {
      var self = this;

      var _getAll = function _getAll(parse) {
        var items = {};
        var cookies = document[0].cookie.split('; ');

        if (cookies.length === 1 && cookies[0] === '') {
          return items;
        }

        for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i].split('=');

          if (!parse) {
            items[cookie[0]] = cookie[1];
            continue;
          }

          items[cookie[0]] = self._decode(cookie[1]);
        }

        return items;
      };

      var setCookie = function setCookie(key, value, expires, domain, path, secure) {
        value = self._encode(value);

        try {
          var date = new Date(expires);

          if (isNaN(date)) {
            var input = expires;
            expires = undefined;
            throw new Error("Storage.set(): \"".concat(input, "\" cannot be converted to date string!"));
          }

          expires = date.toUTCString(); // eslint-disable-next-line no-empty
        } catch (e) {}

        expires = expires ? expires : false;
        var cookie = "".concat(key, "=").concat(value);
        cookie += expires ? ";expires=".concat(expires) : '';
        cookie += domain ? ";domain=".concat(domain) : '';
        cookie += path ? ";path=".concat(path) : '';
        cookie += secure ? ';secure' : '';
        document[0].cookie = cookie;
      };

      var getCookie = function getCookie(key) {
        var cookies = _getAll(false); // eslint-disable-next-line no-prototype-builtins


        if (cookies.hasOwnProperty(key)) {
          return self._decode(cookies[key]);
        }

        return null;
      };

      var getAllCookies = function getAllCookies() {
        return _getAll(true);
      };

      var removeCookie = function removeCookie(key) {
        setCookie(key, '', -1);
      };

      var removeAllCookies = function removeAllCookies() {
        for (var key in getAllCookies()) {
          removeCookie(key);
        }
      };

      return {
        getItem: getCookie,
        getAllItems: getAllCookies,
        setItem: setCookie,
        removeItem: removeCookie,
        removeAllItems: removeAllCookies
      };
    },

    /**
     * Provides local storage proxy layer.
     *
     * @private
     * @method _getLocalStorageProxy
     *
     * @return {Object}
     */
    _getLocalStorageProxy: function _getLocalStorageProxy() {
      var self = this;

      var setItem = function setItem(key, value) {
        value = self._encode(value);
        localStorage.setItem(key, value);
      };

      var getItem = function getItem(key) {
        var value = localStorage.getItem(key);
        return self._decode(value);
      };

      var getAllItems = function getAllItems() {
        var items = {};

        for (var i = 0; i < localStorage.length; i++) {
          var key = localStorage.key(i);
          items[key] = getItem(key);
        }

        return items;
      };

      var removeItem = function removeItem(key) {
        localStorage.removeItem(key);
      };

      var removeAllItems = function removeAllItems() {
        localStorage.clear();
      };

      return {
        getItem: getItem,
        getAllItems: getAllItems,
        setItem: setItem,
        removeItem: removeItem,
        removeAllItems: removeAllItems
      };
    }
  });

  _exports.default = _default;
});