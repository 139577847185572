define("@vollersgroup/hub-frontend/components/pricing-plans/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var pricingData = {
    plans: [{
      key: 'V-FREE',
      bgColor: 'bg-v-blue-600',
      prices: {
        USD: {
          monthly: 0,
          yearly: 0
        },
        EUR: {
          monthly: 0,
          yearly: 0
        }
      }
    }, {
      key: 'V-PLUS',
      bgColor: 'bg-v-blue-700',
      prices: {
        USD: {
          monthly: 19,
          yearly: 200
        },
        EUR: {
          monthly: 18,
          yearly: 180
        }
      }
    }, {
      key: 'V-PRO',
      bgColor: 'bg-v-blue-800',
      prices: {
        USD: {
          monthly: 100,
          yearly: 1100
        },
        EUR: {
          monthly: 90,
          yearly: 990
        }
      }
    }, {
      key: 'V-ENTERPRISE',
      bgColor: 'bg-v-blue-700',
      prices: 'custom'
    }],
    cards: {
      'V-FREE': ['5 Coffee Listings', '1 Shop per company', 'Unlimited number of users'],
      'V-PLUS': ['V-FREE features, plus:', '25 Coffee Listings', '2 Shops per company', 'V-Hub Tag: Scannable labels to collect instant feedback', 'Automatic offer list widget for your homepage', 'Order management for 3rd party warehouse'],
      'V-PRO': ['V-PLUS features, plus:', '100 Coffee Listings', '5 Shop per company', 'Stock synchronization', 'Host coffee auctions', 'Dashboard & statistics', 'V-Connect integration'],
      'V-ENTERPRISE': ['V-PRO features, plus:', 'Unlimited coffee Listings', 'Unlimited shops per company', 'See your shop visitors & contact possibilities', 'V-Shop on own URL']
    },
    features: [{
      title: 'Main Features',
      items: [{
        title: 'Informative and attractive coffee listings',
        plans: {
          'V-FREE': {
            type: 'text',
            value: '5'
          },
          'V-PLUS': {
            type: 'text',
            value: '25'
          },
          'V-PRO': {
            type: 'text',
            value: '100'
          },
          'V-ENTERPRISE': {
            type: 'text',
            value: 'unlimited'
          }
        }
      }, {
        title: 'Shops per company (24/7 open)',
        plans: {
          'V-FREE': {
            type: 'text',
            value: '1'
          },
          'V-PLUS': {
            type: 'text',
            value: '2'
          },
          'V-PRO': {
            type: 'text',
            value: '5'
          },
          'V-ENTERPRISE': {
            type: 'text',
            value: 'unlimited'
          }
        }
      }, {
        title: 'Unlimited number of users',
        plans: {
          'V-FREE': {
            type: 'boolean',
            value: true
          },
          'V-PLUS': {
            type: 'boolean',
            value: true
          },
          'V-PRO': {
            type: 'boolean',
            value: true
          },
          'V-ENTERPRISE': {
            type: 'boolean',
            value: true
          }
        }
      }, {
        title: 'Unmilited number of origins, coorperatives, farms and producer',
        plans: {
          'V-FREE': {
            type: 'boolean',
            value: true
          },
          'V-PLUS': {
            type: 'boolean',
            value: true
          },
          'V-PRO': {
            type: 'boolean',
            value: true
          },
          'V-ENTERPRISE': {
            type: 'boolean',
            value: true
          }
        }
      }, {
        title: 'Global visibility on the green coffee market V-Hub',
        plans: {
          'V-FREE': {
            type: 'boolean',
            value: true
          },
          'V-PLUS': {
            type: 'boolean',
            value: true
          },
          'V-PRO': {
            type: 'boolean',
            value: true
          },
          'V-ENTERPRISE': {
            type: 'boolean',
            value: true
          }
        }
      }, {
        title: 'Sampling orders with credit card integration',
        plans: {
          'V-FREE': {
            type: 'boolean',
            value: true
          },
          'V-PLUS': {
            type: 'boolean',
            value: true
          },
          'V-PRO': {
            type: 'boolean',
            value: true
          },
          'V-ENTERPRISE': {
            type: 'boolean',
            value: true
          }
        }
      }, {
        title: 'Notify your follower about new coffee listings',
        plans: {
          'V-FREE': {
            type: 'boolean',
            value: true
          },
          'V-PLUS': {
            type: 'boolean',
            value: true
          },
          'V-PRO': {
            type: 'boolean',
            value: true
          },
          'V-ENTERPRISE': {
            type: 'boolean',
            value: true
          }
        }
      }, {
        title: 'V-Hub Tag: Scannable labels to collect instant feedback',
        plans: {
          'V-FREE': {
            type: 'boolean',
            value: false
          },
          'V-PLUS': {
            type: 'boolean',
            value: true
          },
          'V-PRO': {
            type: 'boolean',
            value: true
          },
          'V-ENTERPRISE': {
            type: 'boolean',
            value: true
          }
        }
      }, {
        title: 'Automatic offer list widget for your homepage',
        plans: {
          'V-FREE': {
            type: 'boolean',
            value: false
          },
          'V-PLUS': {
            type: 'boolean',
            value: true
          },
          'V-PRO': {
            type: 'boolean',
            value: true
          },
          'V-ENTERPRISE': {
            type: 'boolean',
            value: true
          }
        }
      }]
    }, {
      title: 'Coffee selling & delivery',
      items: [{
        title: 'Payment methods',
        plans: {
          'V-FREE': {
            type: 'text',
            value: 'Invoice with prepayment'
          },
          'V-PLUS': {
            type: 'text',
            value: 'Invoice, credit card'
          },
          'V-PRO': {
            type: 'text',
            value: 'Invoice, credit card and other payment providers'
          },
          'V-ENTERPRISE': {
            type: 'text',
            value: 'Invoice, credit card and other payment providers'
          }
        }
      }, {
        title: 'Invoice & delivery note',
        plans: {
          'V-FREE': {
            type: 'boolean',
            value: true
          },
          'V-PLUS': {
            type: 'boolean',
            value: true
          },
          'V-PRO': {
            type: 'boolean',
            value: true
          },
          'V-ENTERPRISE': {
            type: 'boolean',
            value: true
          }
        }
      }, {
        title: 'Transport costs for DAP delivery (limited regions)',
        plans: {
          'V-FREE': {
            type: 'text',
            value: 'manually'
          },
          'V-PLUS': {
            type: 'text',
            value: 'manually'
          },
          'V-PRO': {
            type: 'text',
            value: 'automatic'
          },
          'V-ENTERPRISE': {
            type: 'text',
            value: 'automatic'
          }
        }
      }, {
        title: 'Stock synchronization',
        plans: {
          'V-FREE': {
            type: 'boolean',
            value: false
          },
          'V-PLUS': {
            type: 'boolean',
            value: false
          },
          'V-PRO': {
            type: 'boolean',
            value: true
          },
          'V-ENTERPRISE': {
            type: 'boolean',
            value: true
          }
        }
      }, {
        title: 'Quantity disounts',
        plans: {
          'V-FREE': {
            type: 'boolean',
            value: false
          },
          'V-PLUS': {
            type: 'boolean',
            value: false
          },
          'V-PRO': {
            type: 'boolean',
            value: true
          },
          'V-ENTERPRISE': {
            type: 'boolean',
            value: true
          }
        }
      }, {
        title: 'Dynamic princing (ICE Europe & US)',
        plans: {
          'V-FREE': {
            type: 'boolean',
            value: false
          },
          'V-PLUS': {
            type: 'boolean',
            value: false
          },
          'V-PRO': {
            type: 'boolean',
            value: true
          },
          'V-ENTERPRISE': {
            type: 'boolean',
            value: true
          }
        }
      }, {
        title: 'Feature to reserve coffee',
        plans: {
          'V-FREE': {
            type: 'boolean',
            value: false
          },
          'V-PLUS': {
            type: 'boolean',
            value: false
          },
          'V-PRO': {
            type: 'boolean',
            value: true
          },
          'V-ENTERPRISE': {
            type: 'boolean',
            value: true
          }
        }
      }, {
        title: 'Provisional invoicing for reweight',
        plans: {
          'V-FREE': {
            type: 'boolean',
            value: false
          },
          'V-PLUS': {
            type: 'boolean',
            value: false
          },
          'V-PRO': {
            type: 'boolean',
            value: true
          },
          'V-ENTERPRISE': {
            type: 'boolean',
            value: true
          }
        }
      }, {
        title: 'Provisional release (Quality approval and/or payment receipt) ',
        plans: {
          'V-FREE': {
            type: 'boolean',
            value: false
          },
          'V-PLUS': {
            type: 'boolean',
            value: false
          },
          'V-PRO': {
            type: 'boolean',
            value: true
          },
          'V-ENTERPRISE': {
            type: 'boolean',
            value: true
          }
        }
      }, {
        title: 'Order management for 3rd party warehouse',
        plans: {
          'V-FREE': {
            type: 'boolean',
            value: false
          },
          'V-PLUS': {
            type: 'boolean',
            value: true
          },
          'V-PRO': {
            type: 'boolean',
            value: true
          },
          'V-ENTERPRISE': {
            type: 'boolean',
            value: true
          }
        }
      }, {
        title: 'Web portal for 3rd-party-warehouse',
        plans: {
          'V-FREE': {
            type: 'boolean',
            value: false
          },
          'V-PLUS': {
            type: 'boolean',
            value: false
          },
          'V-PRO': {
            type: 'boolean',
            value: true
          },
          'V-ENTERPRISE': {
            type: 'boolean',
            value: true
          }
        }
      }, {
        title: 'Feature to consign coffee',
        plans: {
          'V-FREE': {
            type: 'boolean',
            value: false
          },
          'V-PLUS': {
            type: 'boolean',
            value: false
          },
          'V-PRO': {
            type: 'boolean',
            value: false
          },
          'V-ENTERPRISE': {
            type: 'boolean',
            value: true
          }
        }
      }]
    }, {
      title: 'Other perks',
      items: [{
        title: 'Host coffee auctions',
        plans: {
          'V-FREE': {
            type: 'boolean',
            value: false
          },
          'V-PLUS': {
            type: 'boolean',
            value: false
          },
          'V-PRO': {
            type: 'boolean',
            value: true
          },
          'V-ENTERPRISE': {
            type: 'boolean',
            value: true
          }
        }
      }, {
        title: 'Coffee offer list generator',
        plans: {
          'V-FREE': {
            type: 'boolean',
            value: false
          },
          'V-PLUS': {
            type: 'boolean',
            value: false
          },
          'V-PRO': {
            type: 'boolean',
            value: true
          },
          'V-ENTERPRISE': {
            type: 'boolean',
            value: true
          }
        }
      }, {
        title: 'Coffee fact sheet upload',
        plans: {
          'V-FREE': {
            type: 'boolean',
            value: false
          },
          'V-PLUS': {
            type: 'boolean',
            value: false
          },
          'V-PRO': {
            type: 'boolean',
            value: true
          },
          'V-ENTERPRISE': {
            type: 'boolean',
            value: true
          }
        }
      }, {
        title: 'Dashboard & statistics',
        plans: {
          'V-FREE': {
            type: 'boolean',
            value: false
          },
          'V-PLUS': {
            type: 'boolean',
            value: false
          },
          'V-PRO': {
            type: 'boolean',
            value: true
          },
          'V-ENTERPRISE': {
            type: 'boolean',
            value: true
          }
        }
      }, {
        title: 'Shop reputation indicators',
        plans: {
          'V-FREE': {
            type: 'boolean',
            value: false
          },
          'V-PLUS': {
            type: 'boolean',
            value: false
          },
          'V-PRO': {
            type: 'boolean',
            value: true
          },
          'V-ENTERPRISE': {
            type: 'boolean',
            value: true
          }
        }
      }, {
        title: 'No banner advertising at your shop',
        plans: {
          'V-FREE': {
            type: 'boolean',
            value: false
          },
          'V-PLUS': {
            type: 'boolean',
            value: false
          },
          'V-PRO': {
            type: 'boolean',
            value: true
          },
          'V-ENTERPRISE': {
            type: 'boolean',
            value: true
          }
        }
      }, {
        title: 'V-Connect integration',
        plans: {
          'V-FREE': {
            type: 'boolean',
            value: false
          },
          'V-PLUS': {
            type: 'boolean',
            value: false
          },
          'V-PRO': {
            type: 'boolean',
            value: true
          },
          'V-ENTERPRISE': {
            type: 'boolean',
            value: true
          }
        }
      }, {
        title: 'See your shop visitors & contact possibilities',
        plans: {
          'V-FREE': {
            type: 'boolean',
            value: false
          },
          'V-PLUS': {
            type: 'boolean',
            value: false
          },
          'V-PRO': {
            type: 'boolean',
            value: false
          },
          'V-ENTERPRISE': {
            type: 'boolean',
            value: true
          }
        }
      }, {
        title: 'V-Shop on own URL',
        plans: {
          'V-FREE': {
            type: 'boolean',
            value: false
          },
          'V-PLUS': {
            type: 'boolean',
            value: false
          },
          'V-PRO': {
            type: 'boolean',
            value: false
          },
          'V-ENTERPRISE': {
            type: 'boolean',
            value: true
          }
        }
      }, {
        title: 'V-Interface to sellers ERP',
        plans: {
          'V-FREE': {
            type: 'boolean',
            value: false
          },
          'V-PLUS': {
            type: 'boolean',
            value: false
          },
          'V-PRO': {
            type: 'boolean',
            value: false
          },
          'V-ENTERPRISE': {
            type: 'text',
            value: 'price upon request project basis'
          }
        }
      }, {
        title: 'V-Interface to 3rd-party-warehouse ERP',
        plans: {
          'V-FREE': {
            type: 'boolean',
            value: false
          },
          'V-PLUS': {
            type: 'boolean',
            value: false
          },
          'V-PRO': {
            type: 'boolean',
            value: false
          },
          'V-ENTERPRISE': {
            type: 'text',
            value: 'price upon request project basis'
          }
        }
      }]
    }]
  };

  var _default = Ember.Component.extend({
    data: pricingData,
    currency: 'EUR',
    billingType: 'monthly',
    currentPlan: 'V-PLUS',
    actions: {
      onBillingChanged: function onBillingChanged(type) {
        this.set('billingType', type);
      }
    }
  });

  _exports.default = _default;
});