define("@vollersgroup/hub-frontend/admin/account/ratings/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "@vollersgroup/hub-frontend/mixins/reset-scroll"], function (_exports, _authenticatedRouteMixin, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The route for ratings management.
   *
   * @namespace Route
   * @class RatingsRoute
   * @extends Ember.Route
   * @uses Mixin.ResetScroll
   * @uses SimpleAuth.Mixin.AuthenticatedRouteMixin
   */
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _resetScroll.default, {
    // -------------------------------------------------------------------------
    // Dependencies
    userSession: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Loads ratings.
     *
     * @public
     * @method model
     * @return {Promise}
     */
    model: function model() {
      return Ember.get(this, 'userSession.currentUser.ratings');
    }
  });

  _exports.default = _default;
});