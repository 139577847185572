define("@vollersgroup/hub-frontend/components/auction-listings/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FAVORITE_LISTINGS_KEY = 'auction-favorite-listings';

  var _default = Ember.Component.extend({
    userSession: Ember.inject.service(),
    l10n: Ember.inject.service(),
    localStorage: Ember.inject.service(),
    filter: 'ALL',
    sort: 'name',
    auction: null,
    favoriteListingIds: Ember.computed('auction.isAccepted', 'localStorage', function () {
      if (!Ember.get(this, 'auction.isAccepted')) {
        return [];
      }

      var favoriteListingIds = Ember.get(this, 'localStorage').getItem(FAVORITE_LISTINGS_KEY);

      if (Ember.isEmpty(favoriteListingIds)) {
        return [];
      }

      return favoriteListingIds;
    }),
    sortProperty: Ember.computed('sort', 'sortDirection', function () {
      var sort = Ember.get(this, 'sort');
      var sortDirection = sort !== 'listingFullName' ? 'desc' : 'asc';
      return ["".concat(sort, ":").concat(sortDirection)];
    }),
    showScore: Ember.computed('auction.listings.@each.sensorialScore', function () {
      return Ember.get(this, 'auction.listings').uniqBy('sensorialScore') // If all the scores are same, do not show them
      .get('length') > 1;
    }),
    filterOptions: Ember.computed('l10n', function () {
      var l10n = Ember.get(this, 'l10n');
      return [{
        value: 'ALL',
        label: l10n.t('All lots')
      }, {
        value: 'FAVORITE_LISTINGS',
        label: l10n.t('Favorite lots')
      }, {
        value: 'BID',
        label: l10n.t('Lots that I have bid on')
      }, {
        value: 'HIGHEST_BID',
        label: l10n.t('Lots I am the highest bidder for')
      }];
    }),
    sortOptions: Ember.computed('l10n', 'showScore', function () {
      var l10n = Ember.get(this, 'l10n');
      var options = [{
        value: 'listingFullName',
        label: l10n.t('Lot name')
      }, {
        value: 'availableAmount',
        label: l10n.t('Available amount')
      }, {
        value: 'currentPrice',
        label: l10n.t('Highest bid')
      }];

      if (Ember.get(this, 'showScore')) {
        options.push({
          value: 'sensorialScore',
          label: l10n.t('Cupping score')
        });
      }

      return options;
    }),
    sortedListings: Ember.computed.sort('filteredListings', 'sortProperty'),
    filteredListings: Ember.computed('filter', 'auction.listings', 'auction.isAccepted', 'auction.listings.@each.highestBid', 'auction.listings.@each.currentAutoBid', 'favoriteListingIds.[]', function () {
      var listings = Ember.get(this, 'auction.listings');

      if (!Ember.get(this, 'auction.isAccepted')) {
        return listings;
      }

      var filter = Ember.get(this, 'filter');

      switch (filter) {
        case 'ALL':
          return listings;

        case 'FAVORITE_LISTINGS':
          // eslint-disable-next-line no-case-declarations
          var favoriteListingIds = Ember.get(this, 'favoriteListingIds');
          return listings.filter(function (l) {
            return favoriteListingIds.includes(l.get('id'));
          });

        case 'BID':
          return listings.filterBy('currentAutoBid');

        case 'HIGHEST_BID':
          return listings.filterBy('highestBid.isOwn');

        default:
          (false && !(false) && Ember.assert("Unknown filter type '".concat(filter)));
          return listings;
      }
    }),
    listings: Ember.computed.alias('sortedListings'),
    actions: {
      toggleFavorite: function toggleFavorite(listing) {
        var favoriteListingIds = Ember.get(this, 'favoriteListingIds');
        var listingId = listing.get('id');

        if (favoriteListingIds.includes(listingId)) {
          favoriteListingIds.removeObject(listingId);
        } else {
          favoriteListingIds.pushObject(listingId);
        }

        Ember.get(this, 'localStorage').setItem(FAVORITE_LISTINGS_KEY, favoriteListingIds);
      }
    }
  });

  _exports.default = _default;
});