define("@vollersgroup/hub-frontend/blacklist-item/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var belongsTo = _emberData.default.belongsTo;
  var attr = _emberData.default.attr;

  var _default = Model.extend({
    notes: attr('string'),
    created: attr('timestamp'),
    flaggedCompany: belongsTo('company', {
      async: true
    })
  });

  _exports.default = _default;
});