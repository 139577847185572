define("@vollersgroup/hub-frontend/gql/hub-backend/schema.graphql", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var doc = {
    "kind": "Document",
    "definitions": [{
      "kind": "ObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "Query"
      },
      "interfaces": [],
      "directives": [],
      "fields": [{
        "kind": "FieldDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Fetch the currently authenticated user's profile and company information\nPermissions: Authenticated",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "currentUser"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "CurrentUser"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Paginate a list of warehouse owners\nPermissions: Authenticated",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "warehouseOwners"
        },
        "arguments": [{
          "kind": "InputValueDefinition",
          "name": {
            "kind": "Name",
            "value": "page"
          },
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Int"
              }
            }
          },
          "defaultValue": {
            "kind": "IntValue",
            "value": "1"
          },
          "directives": []
        }, {
          "kind": "InputValueDefinition",
          "name": {
            "kind": "Name",
            "value": "pageSize"
          },
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Int"
              }
            }
          },
          "defaultValue": {
            "kind": "IntValue",
            "value": "50"
          },
          "directives": []
        }, {
          "kind": "InputValueDefinition",
          "name": {
            "kind": "Name",
            "value": "order"
          },
          "type": {
            "kind": "ListType",
            "type": {
              "kind": "NonNullType",
              "type": {
                "kind": "NamedType",
                "name": {
                  "kind": "Name",
                  "value": "WarehouseOwnerSortBy"
                }
              }
            }
          },
          "directives": []
        }, {
          "kind": "InputValueDefinition",
          "name": {
            "kind": "Name",
            "value": "where"
          },
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "WarehouseOwnerClauses"
            }
          },
          "directives": []
        }],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "WarehouseOwnerPage"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Fetch a specific warehouse owner by id\nPermissions: Authenticated",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "warehouseOwner"
        },
        "arguments": [{
          "kind": "InputValueDefinition",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "ID"
            }
          },
          "directives": []
        }],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "WarehouseOwner"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Paginate a list of warehouses\nPermissions: Authenticated",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "warehouses"
        },
        "arguments": [{
          "kind": "InputValueDefinition",
          "name": {
            "kind": "Name",
            "value": "page"
          },
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Int"
              }
            }
          },
          "defaultValue": {
            "kind": "IntValue",
            "value": "1"
          },
          "directives": []
        }, {
          "kind": "InputValueDefinition",
          "name": {
            "kind": "Name",
            "value": "pageSize"
          },
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Int"
              }
            }
          },
          "defaultValue": {
            "kind": "IntValue",
            "value": "50"
          },
          "directives": []
        }, {
          "kind": "InputValueDefinition",
          "name": {
            "kind": "Name",
            "value": "order"
          },
          "type": {
            "kind": "ListType",
            "type": {
              "kind": "NonNullType",
              "type": {
                "kind": "NamedType",
                "name": {
                  "kind": "Name",
                  "value": "WarehouseSortBy"
                }
              }
            }
          },
          "directives": []
        }, {
          "kind": "InputValueDefinition",
          "name": {
            "kind": "Name",
            "value": "where"
          },
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "WarehouseClauses"
            }
          },
          "directives": []
        }],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "WarehousePage"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "description": {
          "kind": "StringValue",
          "value": "List of current company warehouses\nPermissions: Authenticated",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "selfCompanyWarehouses"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "ListType",
            "type": {
              "kind": "NonNullType",
              "type": {
                "kind": "NamedType",
                "name": {
                  "kind": "Name",
                  "value": "Warehouse"
                }
              }
            }
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Fetch a specify warehouse by id\nPermissions: Authenticated",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "warehouse"
        },
        "arguments": [{
          "kind": "InputValueDefinition",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "ID"
            }
          },
          "directives": []
        }],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Warehouse"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Paginate a list of legacy warehouses\nPermissions: GLOBAL_ADMIN",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "legacyWarehouses"
        },
        "arguments": [{
          "kind": "InputValueDefinition",
          "name": {
            "kind": "Name",
            "value": "page"
          },
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Int"
              }
            }
          },
          "defaultValue": {
            "kind": "IntValue",
            "value": "1"
          },
          "directives": []
        }, {
          "kind": "InputValueDefinition",
          "name": {
            "kind": "Name",
            "value": "pageSize"
          },
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Int"
              }
            }
          },
          "defaultValue": {
            "kind": "IntValue",
            "value": "50"
          },
          "directives": []
        }, {
          "kind": "InputValueDefinition",
          "name": {
            "kind": "Name",
            "value": "order"
          },
          "type": {
            "kind": "ListType",
            "type": {
              "kind": "NonNullType",
              "type": {
                "kind": "NamedType",
                "name": {
                  "kind": "Name",
                  "value": "LegacyWarehouseSortBy"
                }
              }
            }
          },
          "directives": []
        }, {
          "kind": "InputValueDefinition",
          "name": {
            "kind": "Name",
            "value": "where"
          },
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "LegacyWarehouseClauses"
            }
          },
          "directives": []
        }],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "LegacyWarehousePage"
          }
        },
        "directives": []
      }]
    }, {
      "kind": "ObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "Mutation"
      },
      "interfaces": [],
      "directives": [],
      "fields": [{
        "kind": "FieldDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Create a new Warehouse Owner\nPermissions: MANAGER",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "createWarehouseOwner"
        },
        "arguments": [{
          "kind": "InputValueDefinition",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "CreateWarehouseOwnerInput"
              }
            }
          },
          "directives": []
        }],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "CreateWarehouseOwnerPayload"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Update a Warehouse Owner.\nPermissions: GLOBAL_ADMIN",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "updateWarehouseOwner"
        },
        "arguments": [{
          "kind": "InputValueDefinition",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "UpdateWarehouseOwnerInput"
              }
            }
          },
          "directives": []
        }],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UpdateWarehouseOwnerPayload"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Mark a list of Warehouse Owners as approved\nPermissions: GLOBAL_ADMIN",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "approveWarehouseOwner"
        },
        "arguments": [{
          "kind": "InputValueDefinition",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "ApproveWarehouseOwnerInput"
              }
            }
          },
          "directives": []
        }],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ApproveWarehouseOwnerPayload"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Create new Warehouse\nPermissions: MANAGER",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "createWarehouse"
        },
        "arguments": [{
          "kind": "InputValueDefinition",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "CreateWarehouseInput"
              }
            }
          },
          "directives": []
        }],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "CreateWarehousePayload"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Update a Warehouse\nPermissions: GLOBAL_ADMIN",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "updateWarehouse"
        },
        "arguments": [{
          "kind": "InputValueDefinition",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "UpdateWarehouseInput"
              }
            }
          },
          "directives": []
        }],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UpdateWarehousePayload"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Mark a list of Warehouses as approved\nPermissions: GLOBAL_ADMIN",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "approveWarehouses"
        },
        "arguments": [{
          "kind": "InputValueDefinition",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "ApproveWarehouseInput"
              }
            }
          },
          "directives": []
        }],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ApproveWarehousePayload"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Mark a list of Warehouses as archived\nPermissions: GLOBAL_ADMIN",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "archiveWarehouses"
        },
        "arguments": [{
          "kind": "InputValueDefinition",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "ArchiveWarehouseInput"
              }
            }
          },
          "directives": []
        }],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ArchiveWarehousePayload"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Assign a list of Warehouses to a specific company\nPermissions: GLOBAL_ADMIN",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "assignWarehouses"
        },
        "arguments": [{
          "kind": "InputValueDefinition",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "AssignWarehouseInput"
              }
            }
          },
          "directives": []
        }],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "AssignWarehousePayload"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Unassign a list of Warehouses from a specific company\nPermissions: GLOBAL_ADMIN",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "unassignWarehouses"
        },
        "arguments": [{
          "kind": "InputValueDefinition",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "AssignWarehouseInput"
              }
            }
          },
          "directives": []
        }],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "AssignWarehousePayload"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Assign a list of Warehouses to the Company of the currently authenticated user\nPermissions: MANAGER",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "selfAssignWarehouses"
        },
        "arguments": [{
          "kind": "InputValueDefinition",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "SelfAssignWarehouseInput"
              }
            }
          },
          "directives": []
        }],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SelfAssignWarehousePayload"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Unassign a list of Warehouses to the Company of the currently authenticated user\nPermissions: MANAGER",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "selfUnassignWarehouses"
        },
        "arguments": [{
          "kind": "InputValueDefinition",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "SelfAssignWarehouseInput"
              }
            }
          },
          "directives": []
        }],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SelfAssignWarehousePayload"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Migrate legacy warehouses to the new warehouse model\nAll legacy warehouses referenced by ID in the input will be mapped to the referenced Warehouse.\nPermissions: GLOBAL_ADMIN",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "migrateLegacyWarehouses"
        },
        "arguments": [{
          "kind": "InputValueDefinition",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "MigrateLegacyWarehousesInput"
              }
            }
          },
          "directives": []
        }],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "MigrateLegacyWarehousesPayload"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Transfer warehouse Listings and Sellers to another warehouse to\nremove duplication\nPermissions: GLOBAL_ADMIN",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "transferDuplicateWarehouse"
        },
        "arguments": [{
          "kind": "InputValueDefinition",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "TransferDuplicateWarehouseInput"
              }
            }
          },
          "directives": []
        }],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "TransferDuplicateWarehousePayload"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Migrate legacy warehouses to the new warehouse model for the Company of the currently authenticated user\nThe legacy warehouses referenced by ID in the input will be mapped to the referenced Warehouse.\nPermissions: MANAGER",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "selfMigrateLegacyWarehouses"
        },
        "arguments": [{
          "kind": "InputValueDefinition",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "SelfMigrateLegacyWarehousesInput"
              }
            }
          },
          "directives": []
        }],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SelfMigrateLegacyWarehousesPayload"
          }
        },
        "directives": []
      }]
    }, {
      "kind": "InputObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "CreateAddressInput"
      },
      "directives": [],
      "fields": [{
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "String"
            }
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "nameAddition"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "street"
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "String"
            }
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "addressAddition"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "city"
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "String"
            }
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "state"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "country"
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "CountryCode"
            }
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "zip"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "phone"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }]
    }, {
      "kind": "InputObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "UpdateAddressInput"
      },
      "directives": [],
      "fields": [{
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "nameAddition"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "street"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "addressAddition"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "city"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "state"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "country"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "CountryCode"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "zip"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "phone"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }]
    }, {
      "kind": "InputObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "CreateContactPersonInput"
      },
      "directives": [],
      "fields": [{
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "firstName"
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "String"
            }
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "lastName"
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "String"
            }
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "email"
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "String"
            }
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "phone"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }]
    }, {
      "kind": "InputObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "UpdateContactPersonInput"
      },
      "directives": [],
      "fields": [{
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "firstName"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "lastName"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "email"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "phone"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }]
    }, {
      "kind": "InputObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "SelfMigrateLegacyWarehousesInput"
      },
      "directives": [],
      "fields": [{
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "migrations"
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "ListType",
            "type": {
              "kind": "NonNullType",
              "type": {
                "kind": "NamedType",
                "name": {
                  "kind": "Name",
                  "value": "SelfMigrateLegacyWarehouseInput"
                }
              }
            }
          }
        },
        "directives": []
      }]
    }, {
      "kind": "InputObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "SelfMigrateLegacyWarehouseInput"
      },
      "directives": [],
      "fields": [{
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "legacyWarehouseId"
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "ID"
            }
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "warehouseId"
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "ID"
            }
          }
        },
        "directives": []
      }]
    }, {
      "kind": "ObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "SelfMigrateLegacyWarehousesPayload"
      },
      "interfaces": [],
      "directives": [],
      "fields": [{
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "countMigratedLegacyWarehouses"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "Int"
            }
          }
        },
        "directives": []
      }]
    }, {
      "kind": "EnumTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "CompanyVerificationStatus"
      },
      "directives": [],
      "values": [{
        "kind": "EnumValueDefinition",
        "name": {
          "kind": "Name",
          "value": "NOT_REQUESTED"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "name": {
          "kind": "Name",
          "value": "PENDING"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "name": {
          "kind": "Name",
          "value": "VERIFIED"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "name": {
          "kind": "Name",
          "value": "REJECTED"
        },
        "directives": []
      }]
    }, {
      "kind": "ObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "Avatar"
      },
      "interfaces": [],
      "directives": [],
      "fields": [{
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "id"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "s300x300"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Url"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "fullxfull"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Url"
          }
        },
        "directives": []
      }]
    }, {
      "kind": "ObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "Logo"
      },
      "interfaces": [],
      "directives": [],
      "fields": [{
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "id"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "s500x500"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Url"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "fullxfull"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Url"
          }
        },
        "directives": []
      }]
    }, {
      "kind": "ObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "Company"
      },
      "interfaces": [],
      "directives": [],
      "fields": [{
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "id"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "active"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "Boolean"
            }
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "verificationStatus"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "CompanyVerificationStatus"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "Timestamp"
            }
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "verifiedAt"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Timestamp"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "rejectedAt"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Timestamp"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "verificationRequestedAt"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Timestamp"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "verificationPhoneNumber"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "vatNumber"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "logo"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Logo"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "address"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Address"
          }
        },
        "directives": []
      }]
    }, {
      "kind": "ObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "CurrentUser"
      },
      "interfaces": [],
      "directives": [],
      "fields": [{
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "id"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "role"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "company"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Company"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "fullName"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "email"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "dateFormat"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "timeFormat"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "avatar"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Avatar"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "locale"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "username"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "String"
            }
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Timestamp"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "timezone"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "pendingEmailChange"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Boolean"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "lastLoginDate"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Date"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "lastEmailSentDate"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Date"
          }
        },
        "directives": []
      }]
    }, {
      "kind": "ObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "ContactPerson"
      },
      "interfaces": [],
      "directives": [],
      "fields": [{
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "firstName"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "lastName"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "email"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "phone"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }]
    }, {
      "kind": "ObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "Address"
      },
      "interfaces": [],
      "directives": [],
      "fields": [{
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "nameAddition"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "street"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "addressAddition"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "city"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "state"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "country"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "CountryCode"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "zip"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "phone"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }]
    }, {
      "kind": "ObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "PageInfo"
      },
      "interfaces": [],
      "directives": [],
      "fields": [{
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "hasNextPage"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Boolean"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "totalCount"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        },
        "directives": []
      }]
    }, {
      "kind": "ObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "WarehouseOwnerPage"
      },
      "interfaces": [],
      "directives": [],
      "fields": [{
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "edges"
        },
        "arguments": [],
        "type": {
          "kind": "ListType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "WarehouseOwnerEdge"
            }
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "pageInfo"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "PageInfo"
            }
          }
        },
        "directives": []
      }]
    }, {
      "kind": "ObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "WarehouseOwnerEdge"
      },
      "interfaces": [],
      "directives": [],
      "fields": [{
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "WarehouseOwner"
          }
        },
        "directives": []
      }]
    }, {
      "kind": "ObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "WarehouseOwner"
      },
      "interfaces": [],
      "directives": [],
      "fields": [{
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "id"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "ID"
            }
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "owningCompany"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Company"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "createdBy"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "CurrentUser"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "legalName"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "String"
            }
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "active"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "Boolean"
            }
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "legalAddress"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "Address"
            }
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "region"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "Region"
            }
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "website"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Url"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "contactPerson"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "ContactPerson"
            }
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "approvedAt"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Timestamp"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "Timestamp"
            }
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "Timestamp"
            }
          }
        },
        "directives": []
      }]
    }, {
      "kind": "InputObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "CreateWarehouseOwnerInput"
      },
      "directives": [],
      "fields": [{
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "legalName"
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "String"
            }
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "legalAddress"
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "CreateAddressInput"
            }
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "website"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Url"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "contactPerson"
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "CreateContactPersonInput"
            }
          }
        },
        "directives": []
      }]
    }, {
      "kind": "ObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "CreateWarehouseOwnerPayload"
      },
      "interfaces": [],
      "directives": [],
      "fields": [{
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "warehouseOwner"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "WarehouseOwner"
            }
          }
        },
        "directives": []
      }]
    }, {
      "kind": "InputObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "UpdateWarehouseOwnerInput"
      },
      "directives": [],
      "fields": [{
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "id"
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "ID"
            }
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "owningCompanyId"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "legalName"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "legalAddress"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UpdateAddressInput"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "website"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Url"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "contactPerson"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UpdateContactPersonInput"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "active"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Boolean"
          }
        },
        "directives": []
      }]
    }, {
      "kind": "InputObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "ApproveWarehouseOwnerInput"
      },
      "directives": [],
      "fields": [{
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "ids"
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "ListType",
            "type": {
              "kind": "NonNullType",
              "type": {
                "kind": "NamedType",
                "name": {
                  "kind": "Name",
                  "value": "ID"
                }
              }
            }
          }
        },
        "directives": []
      }]
    }, {
      "kind": "ObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "ApproveWarehouseOwnerPayload"
      },
      "interfaces": [],
      "directives": [],
      "fields": [{
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "warehouseOwners"
        },
        "arguments": [],
        "type": {
          "kind": "ListType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "WarehouseOwner"
            }
          }
        },
        "directives": []
      }]
    }, {
      "kind": "ObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "UpdateWarehouseOwnerPayload"
      },
      "interfaces": [],
      "directives": [],
      "fields": [{
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "warehouseOwner"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "WarehouseOwner"
          }
        },
        "directives": []
      }]
    }, {
      "kind": "InputObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "WarehouseOwnerClauses"
      },
      "directives": [],
      "fields": [{
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "legalName"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StrWhere"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "approvedAt"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "TimestampWhere"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "active"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "BoolWhere"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "region"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StrWhere"
          }
        },
        "directives": []
      }]
    }, {
      "kind": "EnumTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "WarehouseOwnerSortByField"
      },
      "directives": [],
      "values": [{
        "kind": "EnumValueDefinition",
        "name": {
          "kind": "Name",
          "value": "legalName"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "name": {
          "kind": "Name",
          "value": "id"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "name": {
          "kind": "Name",
          "value": "approvedAt"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        },
        "directives": []
      }]
    }, {
      "kind": "InputObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "WarehouseOwnerSortBy"
      },
      "directives": [],
      "fields": [{
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "field"
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "WarehouseOwnerSortByField"
            }
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "direction"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SortDirection"
          }
        },
        "defaultValue": {
          "kind": "EnumValue",
          "value": "ASC"
        },
        "directives": []
      }]
    }, {
      "kind": "InputObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "WarehouseClauses"
      },
      "directives": [],
      "fields": [{
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "warehouseOwnerId"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "displayName"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StrWhere"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "active"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "BoolWhere"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "region"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StrWhere"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "approvedAt"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "TimestampWhere"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "TimestampWhere"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "TimestampWhere"
          }
        },
        "directives": []
      }]
    }, {
      "kind": "EnumTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "WarehouseSortByField"
      },
      "directives": [],
      "values": [{
        "kind": "EnumValueDefinition",
        "name": {
          "kind": "Name",
          "value": "displayName"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "name": {
          "kind": "Name",
          "value": "warehouseOwnerName"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "name": {
          "kind": "Name",
          "value": "region"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "name": {
          "kind": "Name",
          "value": "countOfListings"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "name": {
          "kind": "Name",
          "value": "countOfSellers"
        },
        "directives": []
      }]
    }, {
      "kind": "InputObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "WarehouseSortBy"
      },
      "directives": [],
      "fields": [{
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "field"
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "WarehouseSortByField"
            }
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "direction"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SortDirection"
          }
        },
        "defaultValue": {
          "kind": "EnumValue",
          "value": "ASC"
        },
        "directives": []
      }]
    }, {
      "kind": "ObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "WarehousePage"
      },
      "interfaces": [],
      "directives": [],
      "fields": [{
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "edges"
        },
        "arguments": [],
        "type": {
          "kind": "ListType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "WarehouseEdge"
            }
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "pageInfo"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "PageInfo"
            }
          }
        },
        "directives": []
      }]
    }, {
      "kind": "ObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "WarehouseEdge"
      },
      "interfaces": [],
      "directives": [],
      "fields": [{
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Warehouse"
          }
        },
        "directives": []
      }]
    }, {
      "kind": "ObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "Warehouse"
      },
      "interfaces": [],
      "directives": [],
      "fields": [{
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "id"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "ID"
            }
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "displayName"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "String"
            }
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "active"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "Boolean"
            }
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "address"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "Address"
            }
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "website"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Url"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "region"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "Region"
            }
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "approvedAt"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Timestamp"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "Timestamp"
            }
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "Timestamp"
            }
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "warehouseOwner"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "WarehouseOwner"
            }
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "createdBy"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "CurrentUser"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "companies"
        },
        "arguments": [],
        "type": {
          "kind": "ListType",
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "CompanyWarehouse"
              }
            }
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "countOfListings"
        },
        "arguments": [{
          "kind": "InputValueDefinition",
          "name": {
            "kind": "Name",
            "value": "where"
          },
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "WarehouseListingClauses"
            }
          },
          "directives": []
        }],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "countOfSellers"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        },
        "directives": []
      }]
    }, {
      "kind": "EnumTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "ListingPublishState"
      },
      "directives": [],
      "values": [{
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "a new Listing is in state DRAFT; it's only visible for the creating company",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "DRAFT"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "follow up state of DRAFT and INACTIVE; Listing is visible for everyone on the platform\n(except if it is private)",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "ACTIVE"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "similar to draft, but the Listing was already published once, meaning only certain fields of\nthe Listing can be changed",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "INACTIVE"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "active period of the Listing has expired, customer can re-publish the Listing as is",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "EXPIRED"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "Listing was sold completely (available amount = 0). Any state change is disallowed. Listing\ncan only be archived.",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "SOLD_OUT"
        },
        "directives": []
      }]
    }, {
      "kind": "InputObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "ListingPublishStateWhere"
      },
      "directives": [],
      "fields": [{
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "in"
        },
        "type": {
          "kind": "ListType",
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "ListingPublishState"
              }
            }
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "eq"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ListingPublishState"
          }
        },
        "directives": []
      }]
    }, {
      "kind": "InputObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "WarehouseListingClauses"
      },
      "directives": [],
      "fields": [{
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "publishState"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ListingPublishStateWhere"
          }
        },
        "directives": []
      }]
    }, {
      "kind": "ObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "CompanyWarehouse"
      },
      "interfaces": [],
      "directives": [],
      "fields": [{
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "company"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "Company"
            }
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "countOfListings"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "Int"
            }
          }
        },
        "directives": []
      }]
    }, {
      "kind": "InputObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "CreateWarehouseInput"
      },
      "directives": [],
      "fields": [{
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "warehouseOwnerId"
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "ID"
            }
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "displayName"
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "String"
            }
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "address"
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "CreateAddressInput"
            }
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "website"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Url"
          }
        },
        "directives": []
      }]
    }, {
      "kind": "InputObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "UpdateWarehouseInput"
      },
      "directives": [],
      "fields": [{
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "id"
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "ID"
            }
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "displayName"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "address"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UpdateAddressInput"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "website"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Url"
          }
        },
        "directives": []
      }]
    }, {
      "kind": "ObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "UpdateWarehousePayload"
      },
      "interfaces": [],
      "directives": [],
      "fields": [{
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "warehouse"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "Warehouse"
            }
          }
        },
        "directives": []
      }]
    }, {
      "kind": "ObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "CreateWarehousePayload"
      },
      "interfaces": [],
      "directives": [],
      "fields": [{
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "warehouse"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "Warehouse"
            }
          }
        },
        "directives": []
      }]
    }, {
      "kind": "InputObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "ApproveWarehouseInput"
      },
      "directives": [],
      "fields": [{
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "ids"
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "ListType",
            "type": {
              "kind": "NonNullType",
              "type": {
                "kind": "NamedType",
                "name": {
                  "kind": "Name",
                  "value": "ID"
                }
              }
            }
          }
        },
        "directives": []
      }]
    }, {
      "kind": "ObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "ApproveWarehousePayload"
      },
      "interfaces": [],
      "directives": [],
      "fields": [{
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "warehouses"
        },
        "arguments": [],
        "type": {
          "kind": "ListType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "Warehouse"
            }
          }
        },
        "directives": []
      }]
    }, {
      "kind": "InputObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "ArchiveWarehouseInput"
      },
      "directives": [],
      "fields": [{
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "ids"
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "ListType",
            "type": {
              "kind": "NonNullType",
              "type": {
                "kind": "NamedType",
                "name": {
                  "kind": "Name",
                  "value": "ID"
                }
              }
            }
          }
        },
        "directives": []
      }]
    }, {
      "kind": "ObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "ArchiveWarehousePayload"
      },
      "interfaces": [],
      "directives": [],
      "fields": [{
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "warehouses"
        },
        "arguments": [],
        "type": {
          "kind": "ListType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "Warehouse"
            }
          }
        },
        "directives": []
      }]
    }, {
      "kind": "InputObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "AssignWarehouseInput"
      },
      "directives": [],
      "fields": [{
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "companyId"
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "ID"
            }
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "warehouseIds"
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "ListType",
            "type": {
              "kind": "NonNullType",
              "type": {
                "kind": "NamedType",
                "name": {
                  "kind": "Name",
                  "value": "ID"
                }
              }
            }
          }
        },
        "directives": []
      }]
    }, {
      "kind": "ObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "AssignWarehousePayload"
      },
      "interfaces": [],
      "directives": [],
      "fields": [{
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "warehouses"
        },
        "arguments": [],
        "type": {
          "kind": "ListType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "Warehouse"
            }
          }
        },
        "directives": []
      }]
    }, {
      "kind": "InputObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "SelfAssignWarehouseInput"
      },
      "directives": [],
      "fields": [{
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "warehouseIds"
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "ListType",
            "type": {
              "kind": "NonNullType",
              "type": {
                "kind": "NamedType",
                "name": {
                  "kind": "Name",
                  "value": "ID"
                }
              }
            }
          }
        },
        "directives": []
      }]
    }, {
      "kind": "ObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "SelfAssignWarehousePayload"
      },
      "interfaces": [],
      "directives": [],
      "fields": [{
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "warehouses"
        },
        "arguments": [],
        "type": {
          "kind": "ListType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "Warehouse"
            }
          }
        },
        "directives": []
      }]
    }, {
      "kind": "InputObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "LegacyWarehouseClauses"
      },
      "directives": [],
      "fields": [{
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StrWhere"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "active"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "BoolWhere"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "country"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "CountryCodeWhere"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "isMigrated"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "BoolWhereNonNull"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "TimestampWhere"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "migratedAt"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "TimestampWhere"
          }
        },
        "directives": []
      }]
    }, {
      "kind": "EnumTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "LegacyWarehouseSortByField"
      },
      "directives": [],
      "values": [{
        "kind": "EnumValueDefinition",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "name": {
          "kind": "Name",
          "value": "country"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "name": {
          "kind": "Name",
          "value": "migratedAt"
        },
        "directives": []
      }]
    }, {
      "kind": "InputObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "LegacyWarehouseSortBy"
      },
      "directives": [],
      "fields": [{
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "field"
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "LegacyWarehouseSortByField"
            }
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "direction"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SortDirection"
          }
        },
        "defaultValue": {
          "kind": "EnumValue",
          "value": "ASC"
        },
        "directives": []
      }]
    }, {
      "kind": "ObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "LegacyWarehousePage"
      },
      "interfaces": [],
      "directives": [],
      "fields": [{
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "edges"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "ListType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "LegacyWarehouseEdge"
              }
            }
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "pageInfo"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "PageInfo"
            }
          }
        },
        "directives": []
      }]
    }, {
      "kind": "ObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "LegacyWarehouseEdge"
      },
      "interfaces": [],
      "directives": [],
      "fields": [{
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "LegacyWarehouse"
          }
        },
        "directives": []
      }]
    }, {
      "kind": "ObjectTypeDefinition",
      "description": {
        "kind": "StringValue",
        "value": "A LegacyWarehouse is the old method of storing warehouse records in the backend.\nThey are based on the Location backend entity, and have one to one relationship with a Company.",
        "block": true
      },
      "name": {
        "kind": "Name",
        "value": "LegacyWarehouse"
      },
      "interfaces": [],
      "directives": [],
      "fields": [{
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "id"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "ID"
            }
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "String"
            }
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "active"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "Boolean"
            }
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "address"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "Address"
            }
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "website"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "company"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "Company"
            }
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "migratedWarehouse"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Warehouse"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "migratedAt"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Timestamp"
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        },
        "arguments": [],
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Timestamp"
          }
        },
        "directives": []
      }]
    }, {
      "kind": "InputObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "MigrateLegacyWarehousesInput"
      },
      "directives": [],
      "fields": [{
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "legacyWarehouseIds"
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "ListType",
            "type": {
              "kind": "NonNullType",
              "type": {
                "kind": "NamedType",
                "name": {
                  "kind": "Name",
                  "value": "ID"
                }
              }
            }
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "warehouseId"
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "ID"
            }
          }
        },
        "directives": []
      }]
    }, {
      "kind": "ObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "MigrateLegacyWarehousesPayload"
      },
      "interfaces": [],
      "directives": [],
      "fields": [{
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "legacyWarehouseIds"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "ListType",
            "type": {
              "kind": "NonNullType",
              "type": {
                "kind": "NamedType",
                "name": {
                  "kind": "Name",
                  "value": "ID"
                }
              }
            }
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "warehouseId"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "ID"
            }
          }
        },
        "directives": []
      }]
    }, {
      "kind": "InputObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "TransferDuplicateWarehouseInput"
      },
      "directives": [],
      "fields": [{
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "originalWarehouseId"
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "ID"
            }
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "duplicateWarehouseId"
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "ID"
            }
          }
        },
        "directives": []
      }]
    }, {
      "kind": "ObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "TransferDuplicateWarehousePayload"
      },
      "interfaces": [],
      "directives": [],
      "fields": [{
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "originalWarehouse"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "Warehouse"
            }
          }
        },
        "directives": []
      }, {
        "kind": "FieldDefinition",
        "name": {
          "kind": "Name",
          "value": "duplicateWarehouse"
        },
        "arguments": [],
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "Warehouse"
            }
          }
        },
        "directives": []
      }]
    }],
    "loc": {
      "start": 0,
      "end": 11427
    }
  };
  var _default = doc;
  _exports.default = _default;
});