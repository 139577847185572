define("@vollersgroup/hub-frontend/components/warehouses/migration-banner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "McRQ/l4d",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"and\",[[27,\"variation\",[\"new-warehouses\"],null],[22,0,[\"legacyWarehouses\",\"length\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"-mt-6 mb-8\"],[9],[0,\"\\n\"],[4,\"info-message\",null,[[\"type\"],[\"ERROR\"]],{\"statements\":[[4,\"get-text\",null,[[\"message\"],[[27,\"t\",[\"Your coffee is not discoverable in V-Hub search! You need to perform a one-time transition to our new Warehouse system. Please visit {{linkToMigraton}} to get started. It should only take a few minutes.\"],null]]],{\"statements\":[[4,\"link-to\",[\"admin.manage.overview.warehouse-new.migration\"],[[\"class\"],[\"font-medium\"]],{\"statements\":[[0,\"          \"],[1,[27,\"t\",[\"the new Warehouse management\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/warehouses/migration-banner/template.hbs"
    }
  });

  _exports.default = _default;
});