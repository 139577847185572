define("@vollersgroup/hub-frontend/register/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['createShop', {
      companyToken: 'token'
    }, 'companyName'],
    companyToken: null,
    createShop: null,
    companyName: null,
    actions: {
      afterRegister: function afterRegister() {
        if (Ember.get(this, 'createShop')) {
          this.transitionToRoute('admin.manage.shop.create');
        } else {
          this.transitionToRoute('admin.welcome');
        }
      }
    }
  });

  _exports.default = _default;
});