define("@vollersgroup/hub-frontend/components/bulk-workflow/upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oeUQD1AC",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"h3\"],[9],[1,[27,\"t\",[\"Upload your data\"],null],false],[10],[0,\"\\n\"],[7,\"p\"],[9],[0,\"\\n  \"],[1,[27,\"t\",[\"You can upload different spreadsheet formats containing data and create a template in the next step for future uploads.\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[14,1,[[22,0,[]],\"before_component\"]],[0,\"\\n\\n\"],[1,[27,\"bulk-import-file\",null,[[\"onModeChange\",\"selectedMode\",\"onImportFailure\",\"onImportInvalid\",\"onImportSuccess\",\"selectedTemplate\",\"onTemplateChange\",\"onTemplateDelete\",\"templateFilter\",\"onModeHeaderChange\"],[[27,\"action\",[[22,0,[]],\"onModeChange\"],null],[27,\"readonly\",[[23,[\"currentUploadMode\"]]],null],[27,\"action\",[[22,0,[]],\"onUploadFailure\"],null],[27,\"action\",[[22,0,[]],\"onUploadInvalid\"],null],[27,\"action\",[[22,0,[]],\"onUploadSuccess\"],null],[27,\"readonly\",[[23,[\"currentTemplate\"]]],null],[27,\"action\",[[22,0,[]],\"onTemplateChange\"],null],[27,\"action\",[[22,0,[]],\"onTemplateDelete\"],null],[27,\"readonly\",[[23,[\"currentTemplateData\"]]],null],[27,\"action\",[[22,0,[]],\"onModeHeaderChange\"],null]]]],false],[0,\"\\n\\n\"],[14,1,[[22,0,[]],\"after_component\"]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/bulk-workflow/upload/template.hbs"
    }
  });

  _exports.default = _default;
});