define("@vollersgroup/hub-frontend/admin/manage/overview/producer/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "@vollersgroup/hub-frontend/mixins/company-verified"], function (_exports, _authenticatedRouteMixin, _companyVerified) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Display all producers of the current user's company.
   *
   * @namespace Route
   * @class ManageProducerIndex
   * @extends Ember.Route
   * @uses Mixin.ResetScroll
   * @uses SimpleAuth.Mixin.AuthenticatedRouteMixin
   */
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _companyVerified.default, {
    activate: function activate() {
      this.controllerFor(this.get('routeName')).notifyPropertyChange('orderBy');
    }
  });

  _exports.default = _default;
});