define("@vollersgroup/hub-frontend/helpers/country-label", ["exports", "@vollersgroup/hub-frontend/utils/country-data"], function (_exports, _countryData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.countryLabel = countryLabel;
  _exports.default = void 0;

  function countryLabel(params
  /*, hash*/
  ) {
    var code = params[0];

    if (!code) {
      return '';
    }

    return _countryData.default[code] || code;
  }

  var _default = Ember.Helper.helper(countryLabel);

  _exports.default = _default;
});