define("@vollersgroup/hub-frontend/components/select-crop-year/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GsV7fthB",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"c-select\",null,[[\"id\",\"value\",\"options\",\"placeholder\"],[\"listing_crop-year\",[22,0,[\"value\"]],[22,0,[\"options\"]],[27,\"t\",[\"Crop year\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/select-crop-year/template.hbs"
    }
  });

  _exports.default = _default;
});