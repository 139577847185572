define("@vollersgroup/hub-frontend/components/bulk-workflow/prefill/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Fg1cBrV3",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"h3\"],[9],[1,[27,\"t\",[\"Assign default values\"],null],false],[10],[0,\"\\n\"],[7,\"p\"],[9],[1,[27,\"t\",[\"For certain fields it may make sense to define defaults. Most of these defaults can be setup globally within your shop settings and will be filled in automatically here. You can overwrite them on demand for each import. If a field doesn't show up, it already contains full data from your uploaded spreadsheet.\"],null],false],[10],[0,\"\\n\\n\"],[14,1,[[22,0,[]],\"before_component\"]],[0,\"\\n\\n\"],[1,[27,\"bulk-import-prefill\",null,[[\"onChange\",\"template\",\"availableFields\"],[[27,\"action\",[[22,0,[]],\"onChangePrefill\"],null],[27,\"readonly\",[[23,[\"currentTemplate\"]]],null],[27,\"mut\",[[23,[\"availableFields\"]]],null]]]],false],[0,\"\\n\\n\"],[14,1,[[22,0,[]],\"after_component\"]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/bulk-workflow/prefill/template.hbs"
    }
  });

  _exports.default = _default;
});