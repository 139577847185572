define("@vollersgroup/hub-frontend/gql/hub-backend/shared.graphql", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var doc = {
    "kind": "Document",
    "definitions": [{
      "kind": "ScalarTypeDefinition",
      "description": {
        "kind": "StringValue",
        "value": "Type represents date and time as number of milliseconds from start of UNIX epoch, and is serialized as an integer number.",
        "block": true
      },
      "name": {
        "kind": "Name",
        "value": "Timestamp"
      },
      "directives": []
    }, {
      "kind": "ScalarTypeDefinition",
      "description": {
        "kind": "StringValue",
        "value": "A date string, such as 2007-12-03, compliant with the full-date format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar.",
        "block": true
      },
      "name": {
        "kind": "Name",
        "value": "Date"
      },
      "directives": []
    }, {
      "kind": "ScalarTypeDefinition",
      "description": {
        "kind": "StringValue",
        "value": "A field whose value conforms to the standard URL format as specified in RFC3986.",
        "block": true
      },
      "name": {
        "kind": "Name",
        "value": "Url"
      },
      "directives": []
    }, {
      "kind": "EnumTypeDefinition",
      "description": {
        "kind": "StringValue",
        "value": "Column ordering options",
        "block": true
      },
      "name": {
        "kind": "Name",
        "value": "SortDirection"
      },
      "directives": [],
      "values": [{
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "in ascending order, nulls last",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "ASC"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "in ascending order, nulls first",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "ASC_NULLS_FIRST"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "in ascending order, nulls last",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "ASC_NULLS_LAST"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "in descending order, nulls first",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "DESC"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "in descending order, nulls first",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "DESC_NULLS_FIRST"
        },
        "directives": []
      }, {
        "kind": "EnumValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "in descending order, nulls last",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "DESC_NULLS_LAST"
        },
        "directives": []
      }]
    }, {
      "kind": "InputObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "StrWhere"
      },
      "directives": [],
      "fields": [{
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "eq"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "in"
        },
        "type": {
          "kind": "ListType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "String"
            }
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "isNull"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Boolean"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "description": {
          "kind": "StringValue",
          "value": "A case insensitive comparison search. Use % as a wildcard.",
          "block": true
        },
        "name": {
          "kind": "Name",
          "value": "like"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }]
    }, {
      "kind": "InputObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "IntMinMaxWhere"
      },
      "directives": [],
      "fields": [{
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "max"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "min"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        },
        "directives": []
      }]
    }, {
      "kind": "InputObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "IntWhere"
      },
      "directives": [],
      "fields": [{
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "eq"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "gt"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "gte"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "in"
        },
        "type": {
          "kind": "ListType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "Int"
            }
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "lt"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "lte"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        },
        "directives": []
      }]
    }, {
      "kind": "InputObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "TimestampWhere"
      },
      "directives": [],
      "fields": [{
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "between"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "IntMinMaxWhere"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "eq"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "gt"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "gte"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "isNull"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Boolean"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "lt"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "lte"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        },
        "directives": []
      }]
    }, {
      "kind": "InputObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "BoolWhere"
      },
      "directives": [],
      "fields": [{
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "eq"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Boolean"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "isNull"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Boolean"
          }
        },
        "directives": []
      }]
    }, {
      "kind": "InputObjectTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "CountryCodeWhere"
      },
      "directives": [],
      "fields": [{
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "in"
        },
        "type": {
          "kind": "ListType",
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "CountryCode"
              }
            }
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "eq"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "CountryCode"
          }
        },
        "directives": []
      }, {
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "isNull"
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Boolean"
          }
        },
        "directives": []
      }]
    }, {
      "kind": "InputObjectTypeDefinition",
      "description": {
        "kind": "StringValue",
        "value": "A filter condition where the filtered value must be either true or false, but never null",
        "block": true
      },
      "name": {
        "kind": "Name",
        "value": "BoolWhereNonNull"
      },
      "directives": [],
      "fields": [{
        "kind": "InputValueDefinition",
        "name": {
          "kind": "Name",
          "value": "eq"
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "Boolean"
            }
          }
        },
        "directives": []
      }]
    }],
    "loc": {
      "start": 0,
      "end": 1587
    }
  };
  var _default = doc;
  _exports.default = _default;
});