define("@vollersgroup/hub-frontend/components/auction-live-management/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    toast: Ember.inject.service(),
    showForceTimerModal: false,
    showAuctionManagement: false,
    auctioneerMessage: null,
    auction: null,
    init: function init() {
      this._super.apply(this, arguments);

      (false && !(Ember.get(this, 'auction')) && Ember.assert('auction is required', Ember.get(this, 'auction')));
    },
    actions: {
      showForceTimerModal: function showForceTimerModal() {
        if (!Ember.get(this, 'auction.isRunning')) {
          return;
        }

        if (Ember.get(this, 'auction.isTimerRunning')) {
          return;
        }

        Ember.set(this, 'showForceTimerModal', true);
      },
      dismissForceTimerModal: function dismissForceTimerModal() {
        Ember.set(this, 'showForceTimerModal', false);
      },
      forceTimerStart: function forceTimerStart() {
        this._sendPost("/auction/".concat(Ember.get(this, 'auction.id'), "/forceTimerStart"));

        this.send('dismissForceTimerModal');
      },
      toggleAuctionManagement: function toggleAuctionManagement() {
        this.toggleProperty('showAuctionManagement');
      },
      pauseAuction: function pauseAuction() {
        if (!Ember.get(this, 'auction.isRunning')) {
          return;
        }

        this._sendPost("/auction/".concat(Ember.get(this, 'auction.id'), "/pause"));
      },
      resumeAuction: function resumeAuction() {
        if (!Ember.get(this, 'auction.isOnHold')) {
          return;
        }

        this._sendPost("/auction/".concat(Ember.get(this, 'auction.id'), "/resume"));
      },
      sendMessage: function sendMessage() {
        var _this = this;

        if (!Ember.get(this, 'auctioneerMessage')) {
          return;
        }

        var url = "/auction/".concat(Ember.get(this, 'auction.id'), "/notification");
        var options = {
          data: {
            message: Ember.get(this, 'auctioneerMessage')
          }
        };

        this._sendPost(url, options).then(function () {
          return Ember.set(_this, 'auctioneerMessage', '');
        });
      }
    },
    _sendPost: function _sendPost(url) {
      var _this2 = this;

      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var handleError = function handleError(error) {
        var serverErrorMessage = Ember.get(error, 'payload.error');
        var errorMessage = Ember.get(_this2, 'l10n').t('An error occurred while sending request: {{serverErrorMessage}}', {
          serverErrorMessage: serverErrorMessage
        });
        Ember.get(_this2, 'toast').error(errorMessage);
      };

      return this.get('ajax').post(url, options).catch(handleError);
    }
  });

  _exports.default = _default;
});