define("@vollersgroup/hub-frontend/components/c-select-country/component", ["exports", "@vollersgroup/hub-frontend/components/c-select/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A select-box  with all country as options
   *
   * @namespace Component
   * @class CSelectCountry
   * @extends Select
   */
  var _default = _component.default.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    availableData: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Element Properties

    /**
     * Don't wrap element with a container element
     *
     * @property tagName
     * @final
     * @type String
     * @private
     */
    tagName: '',
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * The id for the input
     *
     * @attribute id
     * @optional
     * @type String
     */
    id: null,

    /**
     * Indicated wheter it should display error.
     *
     * @attribute hasError
     * @optional
     * @type Boolean
     * @default false
     */
    hasError: false,

    /**
     * A class string for the input
     *
     * @attribute class
     * @optional
     * @type String
     */
    class: null,

    /**
     * If the input is required
     *
     * @attribute required
     * @optional
     * @default false
     * @type Boolean
     */
    required: false,

    /**
     * If the input is disabled
     *
     * @attribute disabled
     * @optional
     * @default false
     * @type Boolean
     */
    disabled: false,

    /**
     * The value of the input
     *
     * @attribute value
     * @required
     * @type String
     */
    value: '',

    /**
     * The placeholder of the input
     * This will automatically be translated!
     *
     * @attribute placeholder
     * @optional
     * @type String
     */
    placeholder: null,

    /**
     * The action to call when the select is updated.
     *
     * @event action
     * @param {String} The selected country code
     * @public
     */
    action: null,
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      updateAction: function updateAction(val) {
        if (this.getAttr('action')) {
          this.sendAction('action', val);
        }

        if (this.onFocusOut) {
          this.onFocusOut(); // we want to handle a multiple options case? hadn't before ...
        }
      }
    },
    // -------------------------------------------------------------------------
    // Properties

    /**
     * The options are automatically built from all countries
     *
     * @property options
     * @type Array
     * @private
     */
    options: Ember.computed.reads('availableData.countries')
  });

  _exports.default = _default;
});