define("@vollersgroup/hub-frontend/admin/manage/bulk/listing/controller", ["exports", "@vollersgroup/hub-frontend/admin/manage/bulk/bulk-listing-controller", "ember-launch-darkly"], function (_exports, _bulkListingController, _emberLaunchDarkly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Bulk listing controller for create.
   *
   * @namespace Controller
   * @class BulkListingCreateController
   * @extends BulkListingController
   */
  var _default = _bulkListingController.default.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    toast: Ember.inject.service(),
    localStorage: Ember.inject.service(),
    availableData: Ember.inject.service(),
    legacyWarehouseMigration: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * @property step
     * @type {String}
     * @public
     */
    step: 'shop',

    /**
     * Additional steps for `bulk-workflow`.
     *
     * @property extraSteps
     * @type {Object}
     * @public
     */
    extraSteps: Ember.computed('availableData.activeShop.length', 'availableData.activeShops.[]', 'isSaving', 'l10n', {
      get: function get()
      /*key*/
      {
        // no `shop` step necessary if there's only one active shop
        // it will then be automatically set in this case - `init()`
        var shopCount = Ember.get(this, 'availableData.activeShop.length');

        if (shopCount === 1) {
          return {};
        }

        return {
          shop: {
            id: 'shop',
            partial: null,
            disabled: Ember.get(this, 'isSaving'),
            label: Ember.get(this, 'l10n').t('Select shop')
          }
        };
      }
    }),

    /**
     * Checks if either `isRunning` or `isFinished`
     * flag is set on `saveState` property provided
     * from consumer template.
     *
     * @property isSaving
     * @type {Boolean}
     * @public
     */
    isSaving: Ember.computed.or('saveState.isRunning', 'saveState.isFinished'),

    /**
     * Will be true if `hasProducers` and `hasWarehouses` are true.
     *
     * @property canSelectShop
     * @type {Boolean}
     * @public
     */
    canSelectShop: Ember.computed.and('hasProducers', 'hasLocations'),

    /**
     * Will be true if warehouses are available.
     *
     * @property hasProducers
     * @type {Boolean}
     * @public
     */
    hasProducers: Ember.computed.notEmpty('availableData.producers'),

    /**
     * Will be true if locations are available.
     *
     * @property hasLocations
     * @type {Boolean}
     * @public
     */
    hasLocations: Ember.computed.notEmpty('availableData.locations'),

    /**
     * Fuzzy mappings for handsontable grid.
     *
     * @property fuzzyMappings
     * @type {Object}
     * @public
     */
    fuzzyMappings: Ember.computed({
      get: function get()
      /*key*/
      {
        return {
          sensorialDescriptors: null
        };
      }
    }),
    disabledContinue: Ember.computed('canContinue', 'legacyWarehouseMigration.hasLegacyWarehouses', function () {
      if (!(0, _emberLaunchDarkly.variation)('new-warehouses')) {
        return this.canContinue;
      }

      return this.canContinue && !this.legacyWarehouseMigration.hasLegacyWarehouses;
    }),

    /**
     * Control flag for `bulk-workflow` if there are
     * asynchronous tasks which has to be finished.
     *
     * @property canContinue
     * @type {Boolean}
     * @default true
     * @public
     */
    canContinue: true,

    /**
     * Control flag for `bulk-workflow` if there are
     * asynchronous tasks which has to be finished.
     *
     * @property _storageKey
     * @type {String}
     * @private
     */
    _storageKey: 'hub_bulk_grid_listings',
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Checks shops and tries to load state of `fuzzyMappings`.
     *
     * @public
     * @method init
     * @return {Void}
     */
    init: function init() {
      this._super.apply(this, arguments);

      var shops = Ember.get(this, 'availableData.activeShops');

      if (Ember.get(shops, 'length') === 1) {
        var shop = Ember.get(shops, 'firstObject');
        this.send('selectShop', shop);
      }

      var storage = Ember.get(this, 'localStorage');
      var key = Ember.get(this, '_storageKey');
      var val = storage.getItem(key);

      if (Ember.isNone(val)) {
        return;
      }

      this.setProperties(val);
    },
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Sets `currentShop` and corresponding defaults from
       * company settings on the `bulk-lookup-data` service.
       *
       * @event onSelectShop
       * @param {Model.Shop} shop
       * @return {Void}
       * @private
       */
      selectShop: function selectShop(shop) {
        var slug = Ember.get(shop, 'slug');
        Ember.set(this, 'currentShop', shop);
        var defaults = Ember.get(this, "model.settings.contents.".concat(slug, ".defaults"));

        if (Ember.isNone(defaults)) {
          return;
        }

        if (defaults.warehouse && !defaults.legacyWarehouse) {
          defaults.legacyWarehouse = defaults.warehouse;
        }

        var availableFields = Ember.get(this, 'bulkLookupData.availableFields.listing');

        for (var key in availableFields) {
          var field = availableFields[key];
          var value = defaults[key];

          if (Ember.isNone(value)) {
            delete field.default;
            continue;
          }

          Ember.set(field, 'default', value);
        }
      },

      /**
       * Tries to merge data from existing listings
       * by their `erpId` if it's contained both in
       * uploaded data and template's mapping. Thus,
       * only imported data will be updated while
       * existing data stays and gets displayed.
       *
       * @event uploadSuccess
       * @param  {Object} result
       * @param  {Object} template
       * @return {Void}
       * @private
       */
      uploadSuccess: function uploadSuccess(result, template) {
        var _this = this;

        var setCanContinue = function setCanContinue(state) {
          Ember.set(_this, 'canContinue', state);
        };

        setCanContinue(false); // if this is an upload without a chosen
        // template there's nothing to do here...

        if (Ember.isNone(template)) {
          setCanContinue(true);
          return;
        } // try to get external column from mappings
        // for our internal `erpId` model property


        var erpIdColumn;
        var mappings = Ember.get(template, 'mappings');

        for (var externalColumn in mappings) {
          switch (mappings[externalColumn]) {
            case 'erpId':
              erpIdColumn = externalColumn;
              break;

            default:
          }
        }

        if (Ember.isNone(erpIdColumn)) {
          setCanContinue(true);
          return;
        } // collect `erpId` from uploaded data and
        // fetch listings via dedicated query route


        var data = result.data;

        if (!Ember.isArray(data)) {
          setCanContinue(true);
          return;
        }

        var company = Ember.get(this, 'userSession.currentCompany.id');
        var erpIds = data.reduce(function (ids, row) {
          // eslint-disable-next-line no-prototype-builtins
          if (row.hasOwnProperty(erpIdColumn)) {
            ids.push(row[erpIdColumn]);
          }

          return ids;
        }, []); // assert that there's no request made without
        // actual erpIds - this would fetch ALL items!

        if (Ember.isEmpty(erpIds)) {
          setCanContinue(true);
          return;
        } // note: we have to avoid super long URLs,
        // therefore we have to split the request


        var fields = Ember.get(this, 'tableFields');
        var maximum = erpIds.length;
        var map = {
          /* erpId: JSON */
        };
        var size = 50;
        var cursor = 0; // merge each row data with existing model if
        // found by `query()`, otherwise let untouched

        var successCallback = function successCallback(listings) {
          cursor += size;
          listings.forEach(function (model) {
            var json = _this.transformToData_(model, fields);

            if (Ember.isNone(json.erpId)) {
              return;
            }

            map[json.erpId] = json;
          });

          if (cursor < maximum) {
            fetchListings();
            return;
          }

          Ember.set(_this, 'handsontableInput', data.map(function (row) {
            return Ember.merge(map[row[erpIdColumn]] || {}, row);
          }));
          setCanContinue(true);
        };

        var failureCallback = function failureCallback(error) {
          var serverErrorMessage = Ember.get(error, 'payload.error');
          var errorMessage = Ember.get(_this, 'l10n').t('An error occurred merging coffees with uploaded data: {{serverErrorMessage}}', {
            serverErrorMessage: serverErrorMessage
          });
          Ember.get(_this, 'toast').error(errorMessage);
        };

        var fetchListings = function fetchListings() {
          var start = cursor;
          var end = start + size;
          var ids = erpIds.slice(start, end);

          _this.store.query('listing', {
            erpIds: ids,
            company: company
          }).then(successCallback, failureCallback);
        };

        fetchListings();
      },

      /**
       * Toggles disabled states in prefill mode for
       * `price` and `cmarketPremiumAmount` depending
       * on the current state of `priceDynamic` flag.
       *
       * @event changePrefill
       * @param  {Object} availableField
       * @param  {Mixed} value
       * @return {Void}
       * @private
       */
      changePrefill: function changePrefill(availableField, value) {
        var price = Ember.get(this, 'availableFields.price');
        var premium = Ember.get(this, 'availableFields.cmarketPremiumAmount');

        switch (Ember.get(availableField, 'value')) {
          case 'priceDynamic':
            Ember.set(premium, 'prefillOptions.disabled', !value);
            Ember.set(price, 'prefillOptions.disabled', value);
            break;

          default:
        }
      },

      /**
       * Toggles `fuzzyMappings` property and saves
       * corresponding settings in local storage.
       *
       * @event toggleFuzzyMappings
       * @param  {Boolean} state
       * @return {Void}
       * @private
       */
      toggleFuzzyMappings: function toggleFuzzyMappings(state) {
        var sensorialDescriptors = state ? 'sensorialCuppingDescription' : null;
        var fuzzyMappings = {
          sensorialDescriptors: sensorialDescriptors
        };
        Ember.set(this, 'fuzzyMappings', fuzzyMappings);
        var storage = Ember.get(this, 'localStorage');
        var key = Ember.get(this, '_storageKey');
        var val = storage.getItem(key) || {};

        if (sensorialDescriptors) {
          val['fuzzyMappings'] = fuzzyMappings;
          storage.setItem(key, val);
        } else {
          storage.removeItem(key);
        }
      }
    }
  });

  _exports.default = _default;
});