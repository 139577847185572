define("@vollersgroup/hub-frontend/components/invite-user-modal/component", ["exports", "ember-can", "ember-cp-validations", "@vollersgroup/hub-frontend/utils/scroll-into-the-first-error-if-needed"], function (_exports, _emberCan, _emberCpValidations, _scrollIntoTheFirstErrorIfNeeded) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    userEmail: {
      validators: [(0, _emberCpValidations.validator)('format', {
        allowBlank: false,
        type: 'email',
        message: 'Please enter an email.'
      })]
    }
  });
  /**
   * A modal to invite a user.
   *
   * @namespace Component
   * @class InviteUserModal
   */

  var _default = Ember.Component.extend(_emberCan.CanMixin, Validations, {
    store: Ember.inject.service(),
    userSession: Ember.inject.service(),
    ajax: Ember.inject.service(),
    availableData: Ember.inject.service(),
    l10n: Ember.inject.service(),
    role: 'VIEWER',
    userEmail: null,
    isLoading: false,

    /**
     * Flag indicating whether all error messages should be displayed.
     */
    didValidate: false,
    willInsertElement: function willInsertElement() {
      this._super.apply(this, arguments);

      this._checkAccessRights();
    },
    _checkAccessRights: function _checkAccessRights() {
      if (!this.can('invite user for company')) {
        this.get('closeModal')();
      }
    },
    actions: {
      sendInvitation: function sendInvitation() {
        var _this = this;

        return this.validate().then(function (_ref) {
          var validations = _ref.validations;

          _this.set('didValidate', true);

          if (!validations.get('isValid')) {
            (0, _scrollIntoTheFirstErrorIfNeeded.default)();
            return Promise.reject();
          }
        }).then(function () {
          var email = _this.get('userEmail');

          var role = _this.get('role');

          _this.set('isLoading', true);

          var currentCompanyId = Ember.get(_this, 'userSession.currentCompany.id');
          var url = "/company/".concat(currentCompanyId, "/inviteUser");
          return _this.get('ajax').post(url, {
            contentType: 'application/json; charset=utf-8',
            data: JSON.stringify({
              email: email,
              role: role
            })
          }).then(function () {
            _this.get('toast').success(_this.get('l10n').t('An invitation has been sent to {{email}}.', {
              email: email
            }));

            _this.get('availableData').notifyPropertyChange('userInvitations');

            _this.get('closeModal')();
          }).catch(function (_error) {
            var errorMessage = _this.get('l10n').t('Error has occurred while inviting user. Please let us know and we will look into it.');

            _this.get('toast').error(errorMessage);
          }).finally(function () {
            _this.set('isLoading', false);
          });
        });
      }
    }
  });

  _exports.default = _default;
});