define("@vollersgroup/hub-frontend/components/listing-running-auction/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A component for a listing while the auction of the listing is running
   *
   * ```html
   * {{listing-running-auction listing=myListing}}
   * ```
   *
   * @namespace Component
   * @class ListingRunningAuction
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    userSession: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * The listing to display
     *
     * @attribute listing
     * @type Model.Listing
     * @required
     */
    listing: null
  });

  _exports.default = _default;
});