define("@vollersgroup/hub-frontend/admin/manage/print-labels/route", ["exports", "@vollersgroup/hub-frontend/mixins/reset-scroll", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _resetScroll, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The base route for the import part of the app.
   *
   * @namespace Route
   * @class ManageImport
   * @extends Ember.Route
   * @uses Mixin.ResetScroll
   * @uses SimpleAuth.Mixin.AuthenticatedRouteMixin
   */
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _resetScroll.default, {
    queryParams: {
      ids: {
        refreshModel: true
      }
    },
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Renders templates for admin route manually.
     * Per default, it renders secondary nav items
     * from manage pod to `secondary-nav` outlet.
     *
     * @public
     * @method renderTemplate
     * @return {Void}
     */
    renderTemplate: function renderTemplate() {
      this.controller.set('navbar', {
        route: 'admin.manage.overview',
        title: Ember.get(this, 'l10n').t('Print labels')
      });
      this.render();
      this.render('admin/manage/navbar-subpage', {
        outlet: 'secondary-nav',
        into: 'admin'
      });
    },

    /**
     * Redirects if no `ids` param's given.
     *
     * @public
     * @method beforeModel
     * @param {Object} transition
     * @return {Void}
     */
    beforeModel: function beforeModel(transition) {
      if (Ember.isEmpty(transition.queryParams.ids)) {
        this.transitionTo('admin.manage.overview');
      }
    },

    /**
     * Loads listings from `ids` param.
     *
     * @public
     * @method model
     * @param {Object} params
     * @return {Promise}
     */
    model: function model(params) {
      return this.store.query('listing', {
        ids: params.ids
      });
    }
  });

  _exports.default = _default;
});