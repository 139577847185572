define("@vollersgroup/hub-frontend/services/shopping-cart", ["exports", "ember-can", "@vollersgroup/hub-frontend/utils/get-first-adapter-error-message"], function (_exports, _emberCan, _getFirstAdapterErrorMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This service handles the shopping cart of the user
   *
   * @namespace Service
   * @class ShoppingCart
   * @extends Ember.Service
   * @uses DS.Store
   * @uses Service.UserSession
   */
  var _default = Ember.Service.extend(_emberCan.CanMixin, {
    // -------------------------------------------------------------------------
    // Dependencies
    l10n: Ember.inject.service(),
    store: Ember.inject.service(),
    toast: Ember.inject.service(),
    userSession: Ember.inject.service(),
    keenTracking: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * All the carts the user currently has.
     *
     * @property carts
     * @type Array
     */
    carts: Ember.computed({
      get: function get() {
        return [];
      }
    }),

    /**
     * If the cart has been loaded yet.
     *
     * @property cartIsLoaded
     * @type Boolean
     */
    cartIsLoaded: false,

    /**
     * The total number of all items in the cart.
     *
     * @property cartSize
     * @type Number
     */
    cartSize: 0,

    /**
     * Hash map containing key value pairs consisting
     * of listing ids (=keys) and order items (=value).
     *
     * @property listingMap
     * @type Object
     */
    listingMap: Ember.computed({
      get: function get() {
        return {// listingId: orderItem
        };
      }
    }),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Adds a listing as order item to shop"s cart.
     *
     * @method addListing
     * @param Model.Listing listing The listing to add to the cart
     * @param {Number} amount The amount of units to add to the cart
     * @param {Boolean} [sample=false] If the listing should be added as a sample or not
     * @public
     */
    addListing: function addListing(listing) {
      var _this = this;

      var amount = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      var sample = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      var promiseCallback = function promiseCallback(resolve, reject) {
        var shop = Ember.get(listing, 'shop');
        var carts = Ember.get(_this, 'carts'); // 1) get corresponding cart from this shop or create

        var cart = carts.findBy('shop.id', Ember.get(shop, 'id'));

        if (Ember.isNone(cart)) {
          cart = Ember.get(_this, 'store').createRecord('order', {
            buyerCompany: Ember.get(_this, 'userSession.currentCompany'),
            requestUser: Ember.get(_this, 'userSession.currentUser'),
            sellerCompany: Ember.get(listing, 'company'),
            orderItems: [],
            status: 'CART',
            shop: shop
          });
          carts.pushObject(cart);
        } // 2) get corresponding item from cart or create


        var listingId = Ember.get(listing, 'id');
        var orderItems = Ember.get(cart, 'orderItems');
        var orderItem = orderItems.find(function (item) {
          var itemId = Ember.get(item, 'listing.id');
          var itemSample = Ember.get(item, 'sample');
          return listingId === itemId && sample === itemSample;
        });

        if (Ember.isNone(orderItem)) {
          orderItem = _this.get('store').createRecord('order-item', {
            priceInitialCurrency: sample || Ember.get(listing, 'priceHidden') ? null : Ember.get(listing, 'priceCurrency'),
            priceInitialAmount: sample || Ember.get(listing, 'priceHidden') ? null : Ember.get(listing, 'price'),
            initialAmountUnit: sample ? 'NUMBER' : Ember.get(listing, 'availableAmountUnit'),
            initialAmount: amount,
            listing: listing,
            sample: sample,
            order: cart
          });
          orderItems.pushObject(orderItem);
        }

        Ember.set(orderItem, 'initialAmount', amount); // 3) save cart and corresponding order item

        var orderItemSuccessCallback = function orderItemSuccessCallback() {
          _this._trackEvent(orderItem, 'add-to-cart');

          _this._addToMap(orderItem);

          resolve();
        };

        var orderItemFailureCallback = function orderItemFailureCallback(adapterError) {
          var serverErrorMessage = (0, _getFirstAdapterErrorMessage.default)(adapterError);
          var errorMessage = Ember.get(_this, 'l10n').t('An error occurred while creating cart item: {{serverErrorMessage}}', {
            serverErrorMessage: serverErrorMessage
          });
          Ember.get(_this, 'toast').error(errorMessage);
          reject(adapterError);
        };

        var cartSuccessCallback = function cartSuccessCallback() {
          orderItem.save().then(orderItemSuccessCallback, orderItemFailureCallback);
        };

        var cartFailureCallback = function cartFailureCallback(adapterError) {
          var serverErrorMessage = (0, _getFirstAdapterErrorMessage.default)(adapterError);
          var errorMessage = Ember.get(_this, 'l10n').t('An error occurred while creating cart: {{serverErrorMessage}}', {
            serverErrorMessage: serverErrorMessage
          });
          Ember.get(_this, 'toast').error(errorMessage);
          reject(adapterError);
        };

        if (Ember.get(cart, 'isNew')) {
          cart.save().then(cartSuccessCallback, cartFailureCallback);
          return;
        }

        cartSuccessCallback();
      };

      return new Ember.RSVP.Promise(promiseCallback);
    },

    /**
     * Remove an order cart item from shop"s cart.
     * Optionally, removes cart if no items remain.
     *
     * @method removeOrderItem
     * @param {Model.OrderItem} orderItem The order item to be removed.
     * @public
     */
    removeOrderItem: function removeOrderItem(orderItem) {
      var _this2 = this;

      var promiseCallback = function promiseCallback(resolve, reject) {
        Ember.get(orderItem, 'order').then(function (order) {
          var successCallback = function successCallback() {
            _this2._trackEvent(orderItem, 'remove-from-cart');

            var orderItems = Ember.get(order, 'orderItems');
            orderItems.removeObject(orderItem);

            if (Ember.get(orderItems, 'length')) {
              resolve();
              return;
            }

            _this2._deleteCart(order).then(function () {
              resolve();
            }, function () {
              reject();
            });
          };

          var failureCallback = function failureCallback(error) {
            var serverErrorMessage = Ember.get(error, 'payload.error');
            var errorMessage = Ember.get(_this2, 'l10n').t('An error occurred while removing cart item: {{serverErrorMessage}}', {
              serverErrorMessage: serverErrorMessage
            });
            Ember.get(_this2, 'toast').error(errorMessage);
            reject();
          };

          _this2._removeFromMap(orderItem);

          orderItem.destroyRecord().then(successCallback, failureCallback);
        }, reject);
      };

      return new Ember.RSVP.Promise(promiseCallback);
    },

    /**
     * Remove all order items of a cart.
     * Optionally deletes cart from DB.
     *
     * @method removeCart
     * @param {Model.Order} cart The cart to remove.
     * @param {Boolean} [softDelete=false] If cart should be removed from DB.
     * @public
     */
    removeCart: function removeCart(cart) {
      var _this3 = this;

      var softDelete = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      // https://cropster.atlassian.net/browse/CHF-261
      var orderItems = Ember.get(cart, 'orderItems').slice();

      if (orderItems.length === 0 || softDelete) {
        orderItems.forEach(function (orderItem) {
          _this3._removeFromMap(orderItem);
        });

        this._deleteCart(cart, softDelete);

        return;
      }

      var recursor = function recursor() {
        var orderItem = orderItems.pop();

        if (Ember.isNone(orderItem)) {
          return;
        }

        _this3.removeOrderItem(orderItem).then(recursor);
      };

      recursor();
    },

    /**
     * Adds an order item to `listingMap` for lookup without request.
     *
     * @method _addToMap
     * @param {Model.OrderItem} orderItem
     * @private
     */
    _addToMap: function _addToMap(orderItem) {
      var listing = orderItem.belongsTo('listing').id();
      Ember.set(this, "listingMap.".concat(listing), orderItem);
      this.incrementProperty('cartSize');
    },

    /**
     * Removes an order item from `listingMap`.
     *
     * @method _addToMap
     * @param {Model.OrderItem} orderItem
     * @private
     */
    _removeFromMap: function _removeFromMap(orderItem) {
      var listing = orderItem.belongsTo('listing').id();
      Ember.set(this, "listingMap.".concat(listing), null);

      if (Ember.get(this, 'cartSize') === 0) {
        return;
      }

      this.decrementProperty('cartSize');
    },

    /**
     * Primitive method to remove cart from database.
     *
     * @method _deleteCart
     * @param {Model.Order} cart
     * @param {Boolean} [softDelete=false] If cart should be removed from DB.
     * @private
     */
    _deleteCart: function _deleteCart(cart) {
      var _this4 = this;

      var softDelete = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      var promiseCallback = function promiseCallback(resolve, reject) {
        if (softDelete) {
          _this4._removeCart(cart);

          resolve();
          return;
        }

        var successCallback = function successCallback() {
          _this4._removeCart(cart);

          resolve();
        };

        var failureCallback = function failureCallback(error) {
          var serverErrorMessage = Ember.get(error, 'payload.error');
          var errorMessage = Ember.get(_this4, 'l10n').t('An error occurred while removing cart: {{serverErrorMessage}}', {
            serverErrorMessage: serverErrorMessage
          });
          Ember.get(_this4, 'toast').error(errorMessage);
          reject();
        };

        cart.destroyRecord().then(successCallback, failureCallback);
      };

      return new Ember.RSVP.Promise(promiseCallback);
    },

    /**
     * Primitive method to remove cart from `carts`.
     *
     * @method _removeCart
     * @param {Model.Order} cart
     * @private
     */
    _removeCart: function _removeCart(cart) {
      Ember.get(this, 'carts').removeObject(cart);
    },

    /**
     * Tracks information via Keen for
     * 'add' and 'remove' interactions.
     *
     * @param {Model.OrderItem} orderItem
     * @param {String} eventName
     * @private
     */
    _trackEvent: function _trackEvent(orderItem, eventName) {
      var keenTracking = Ember.get(this, 'keenTracking');

      switch (eventName) {
        case 'add-to-cart':
        case 'remove-from-cart':
          Ember.RSVP.all([Ember.get(orderItem, 'order.sellerCompany'), Ember.get(orderItem, 'order.buyerCompany'), Ember.get(orderItem, 'order.sellerUser'), Ember.get(orderItem, 'order.requestUser'), Ember.get(orderItem, 'listing'), Ember.get(orderItem, 'order')]).then(function (results) {
            var eventData = {
              sellerCompany: results[0] ? results[0].toJSON(true) : null,
              buyerCompany: results[1] ? results[1].toJSON(true) : null,
              sellerUser: results[2] ? results[2].toJSON(true) : null,
              requestUser: results[3] ? results[3].toJSON(true) : null,
              listing: results[4] ? results[4].toJSON(true) : null,
              order: results[5] ? results[5].toJSON(true) : null,
              sample: Ember.get(orderItem, 'sample')
            };
            keenTracking.addEvent(eventName, eventData);
          });
          break;

        default:
      }
    },
    // -------------------------------------------------------------------------
    // Observers

    /**
     * Loads cart data if there"s a current user session.
     * Detects session changes and reloads data properly.
     *
     * @method _observeCurrentCompanyId
     * @private
     */
    _observeCurrentCompanyId: Ember.on('init', Ember.observer('userSession.currentUser', function () {
      var _this5 = this;

      var currentUser = Ember.get(this, 'userSession.currentUser');

      if (Ember.isNone(currentUser) || !this.can('create order')) {
        return;
      }

      var successCallback = function successCallback(orders) {
        var carts = Ember.get(_this5, 'carts');
        Ember.set(_this5, 'cartIsLoaded', true);
        orders.forEach(function (order) {
          Ember.get(order, 'orderItems').then(function (items) {
            items.forEach(_this5._addToMap.bind(_this5));
          });
          carts.pushObject(order);
        });
      };

      var failureCallback = function failureCallback(error) {
        var serverErrorMessage = Ember.get(error, 'payload.error');
        var errorMessage = Ember.get(_this5, 'l10n').t('An error occurred while loading cart items: {{serverErrorMessage}}', {
          serverErrorMessage: serverErrorMessage
        });
        Ember.get(_this5, 'toast').error(errorMessage);
      };

      Ember.get(this, 'store').query('order', {
        status: ['CART']
      }).then(successCallback, failureCallback);
    }))
  });

  _exports.default = _default;
});