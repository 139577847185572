define("@vollersgroup/hub-frontend/admin/manage/overview/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "@vollersgroup/hub-frontend/mixins/reset-scroll", "@vollersgroup/hub-frontend/mixins/company-verified"], function (_exports, _authenticatedRouteMixin, _resetScroll, _companyVerified) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _resetScroll.default, _companyVerified.default, {
    availableData: Ember.inject.service(),
    afterModel: function afterModel() {
      var _this = this;

      if (!this.get('userSession.currentCompany') || !this.get('availableData.shops')) {
        return this._super.apply(this, arguments);
      }

      return new Ember.RSVP.Promise(function (resolve) {
        _this.get('availableData.shops').then(function (shops) {
          if (shops.get('length')) {
            resolve();
          } else {
            _this.transitionTo('admin.manage.shop.create');

            resolve();
          }
        });
      });
    },
    actions: {
      publishDraftShop: function publishDraftShop(shop) {
        var _this2 = this;

        if (typeof shop.then === 'function') {
          shop.then(function (shop) {
            shop.set('status', 'ACTIVE');
            shop.save().then(function () {
              _this2.get('toast').success(_this2.get('l10n').t('You have successfully published the shop {{shopName}}!', {
                shopName: shop.get('name')
              }));

              _this2.refresh();
            });
          });
        } else {
          shop.set('status', 'ACTIVE');
          shop.save().then(function () {
            _this2.get('toast').success(_this2.get('l10n').t('You have successfully published the shop {{shopName}}!', {
              shopName: shop.get('name')
            }));

            _this2.refresh();
          });
        }
      }
    }
  });

  _exports.default = _default;
});