define("@vollersgroup/hub-frontend/components/manage-added-listings/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies

    /**
     * @property store
     * @type DS.Store
     * @private
     */
    store: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * The auction to which the coffee should be added.
     *
     * @attribute auction
     * @type Model.Auction
     * @optional
     */
    auction: null,

    /**
     * If this is set to true, this is an edit dialog (instead of a create dialog)
     *
     * @attribute isEdit
     * @type Boolean
     * @optional
     */
    isEdit: false,

    /**
     * The name of the action to call when the the adding is done.
     *
     * @event action
     * @param {Model.Listing[]} addedListings
     * @param {Model.Listing[]} newlyAddedListings
     * @param {Model.Listing[]} removedListings
     * @required
     */
    action: null,

    /**
     * The name of the action to call when the adding is done. Afterwards, it should exit the process.
     *
     * @event actionExit
     * @param {Model.Listing[]} addedListings
     * @param {Model.Listing[]} newlyAddedListings
     * @param {Model.Listing[]} removedListings
     * @required
     */
    actionExit: null,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this._initialiseListings();
    },
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      addListing: function addListing(listing) {
        this.get('addedListings').pushObject(listing);
        this.get('availableListings').removeObject(listing);
        this.get('newlyAddedListings').pushObject(listing);
        this.get('removedListings').removeObject(listing);
      },
      removeListing: function removeListing(listing) {
        this.get('addedListings').removeObject(listing);
        this.get('availableListings').pushObject(listing);
        this.get('removedListings').pushObject(listing);
        this.get('newlyAddedListings').removeObject(listing);
      },
      continue: function _continue() {
        this.sendAction('action', this.get('addedListings'), this.get('newlyAddedListings'), this.get('removedListings'));
      },
      exitProcess: function exitProcess() {
        this.sendAction('actionExit', this.get('addedListings'), this.get('newlyAddedListings'), this.get('removedListings'));
      },
      changeFilter: function changeFilter(filter) {
        this.set('filterAvailableListings', filter);
      }
    },
    // -------------------------------------------------------------------------
    // Properties
    addedListings: Ember.computed(function () {
      return [];
    }),
    availableListings: Ember.computed(function () {
      return [];
    }),
    removedListings: Ember.computed(function () {
      return [];
    }),
    newlyAddedListings: Ember.computed(function () {
      return [];
    }),
    isLoading: false,
    hasAnyListings: Ember.computed.or('addedListings.length', 'availableListings.length'),
    filterAvailableListings: '',
    filteredAvailableListings: Ember.computed('availableListings.[]', 'filterAvailableListings', function () {
      var filter = this.get('filterAvailableListings');
      return this.get('availableListings').filter(function (listing) {
        return listing.get('listingFullName').toLowerCase().indexOf(filter.toLowerCase()) > -1;
      });
    }),
    // -------------------------------------------------------------------------
    // Methods
    _initialiseListings: function _initialiseListings() {
      var _this = this;

      this.set('isLoading', true);
      this.set('newlyAddedListings', []);
      this.set('removedListings', []);
      this.getAttr('auction').get('listings').reload();
      this.getAttr('auction').get('listings').then(function (listings) {
        _this.set('addedListings', listings.toArray());
      });
      this.get('store').query('listing', {
        company: this.getAttr('auction').get('company.id'),
        shop: this.getAttr('auction').get('shop.id'),
        status: ['DRAFT', 'ACTIVE', 'INACTIVE'],
        pageSize: 999
      }).then(function (listings) {
        var arr = [];
        listings.forEach(function (listing) {
          if (Ember.isEmpty(listing.get('auction')) || Ember.isEmpty(listing.get('auction.content'))) {
            arr.pushObject(listing);
          }
        });

        _this.set('isLoading', false);

        _this.set('availableListings', arr);
      });
    }
  });

  _exports.default = _default;
});