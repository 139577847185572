define("@vollersgroup/hub-frontend/admin/manage/producer/create/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "@vollersgroup/hub-frontend/mixins/reset-scroll", "ember-can", "moment"], function (_exports, _authenticatedRouteMixin, _resetScroll, _emberCan, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The route to edit a producer.
   *
   * @namespace Route
   * @class ManageShopCreate
   * @extends Ember.Route
   * @uses Mixin.CanMixin
   * @uses Mixin.ResetScroll
   * @uses SimpleAuth.Mixin.AuthenticatedRouteMixin
   */
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _resetScroll.default, _emberCan.CanMixin, {
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Renders templates for admin route manually.
     * Per default, it renders secondary nav items
     * from manage pod to `secondary-nav` outlet.
     *
     * @public
     * @method renderTemplate
     * @return {Void}
     */
    renderTemplate: function renderTemplate() {
      this.controller.set('navbar', {
        route: 'admin.manage.overview.producer',
        title: Ember.get(this, 'l10n').t('Create new origin')
      });
      this.render();
      this.render('admin/manage/navbar-subpage', {
        outlet: 'secondary-nav',
        into: 'admin'
      });
    },

    /**
     * Performs ability check if user can create a producer.
     *
     * @public
     * @method beforeModel
     * @return {Promise}
     */
    beforeModel: function beforeModel() {
      if (!this.can('create producer')) {
        this.transitionTo('admin.manage.overview.producer');
        return;
      }

      return this._super.apply(this, arguments);
    },

    /**
     * Create a new producer object.
     *
     * @method model
     * @returns Model.Producer
     */
    model: function model() {
      var location = this.store.createRecord('location', {
        type: 'PRODUCER'
      });
      return this.store.createRecord('producer', {
        createdDate: (0, _moment.default)(),
        company: this.get('userSession.currentCompany'),
        location: location,
        images: []
      });
    },

    /**
     * Resets route's model and controller step.
     *
     * @public
     * @method deactivate
     * @return {Void}
     */
    deactivate: function deactivate() {
      this.currentModel.rollbackAttributes();

      if (this.currentModel.get('isDestroyed')) {
        this.currentModel.save();
      }

      this.controller.set('step', 0);
    }
  });

  _exports.default = _default;
});