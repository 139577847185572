define("@vollersgroup/hub-frontend/components/filter-item-other/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BlEpXCcN",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"c-nested-tree\",null,[[\"id\",\"filter\",\"valueProperty\",\"labelProperty\",\"activeProperty\",\"toggleChildren\",\"items\",\"updateAction\"],[[23,[\"id\"]],false,\"id\",\"name\",\"active\",false,[27,\"mut\",[[23,[\"filter\",\"items\"]]],null],[27,\"action\",[[22,0,[]],\"update\"],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/filter-item-other/template.hbs"
    }
  });

  _exports.default = _default;
});