define("@vollersgroup/hub-frontend/admin/manage/shop/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The base route manage shop index.
   *
   * @namespace Route
   * @class Manage
   * @extends Ember.Route
   */
  var _default = Ember.Route.extend({
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Redirects to overview.
     *
     * @public
     * @method redirect
     * @return {Void}
     */
    redirect: function redirect() {
      this.transitionTo('admin.manage.overview.shop');
    }
  });

  _exports.default = _default;
});