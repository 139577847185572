define("@vollersgroup/hub-frontend/store/auctions/index/route", ["exports", "@vollersgroup/hub-frontend/mixins/reset-scroll"], function (_exports, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_resetScroll.default, {
    // -------------------------------------------------------------------------
    // Properties
    queryParams: {
      shopId: {
        refreshModel: true,
        as: 'shop'
      },
      searchText: {
        refreshModel: true,
        as: 'q'
      }
    },
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Searches either for auction by search term or
     * shop id and delivers an RSVP promise with up-
     * coming and closed auctions.
     *
     * @public
     * @method model
     * @return {RSVP.Promise}
     */
    model: function model(params) {
      var query = {
        status: ['ACTIVE', 'RUNNING', 'ON_HOLD'],
        sort: 'DATE',
        sortDirection: 'ASC'
      };

      if (params.shopId) {
        query.shop = params.shopId;
      }

      if (params.searchText) {
        query.term = params.searchText;
      }

      var queryPast = Ember.assign({}, query, {
        status: ['CLOSED'],
        sortDirection: 'DESC'
      });
      return Ember.RSVP.hash({
        upcoming: this.store.query('auction', query),
        closed: this.store.query('auction', queryPast),
        shopsWithAuction: this.store.query('shop', {
          hasAuction: true
        })
      });
    },

    /**
     * Resets potentially remaining query parameters
     * in case you use breadcrumb navigation from a
     * listing view, which would keep params sticky!
     *
     * @public
     * @method setupController
     * @return {Void}
     */
    setupController: function setupController() {
      this._super.apply(this, arguments);

      var controller = this.controllerFor('store.auctions.show');
      controller.setProperties(controller.get('defaults'));
    }
  });

  _exports.default = _default;
});