define("@vollersgroup/hub-frontend/components/listing-producer-info-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      (false && !(Ember.get(this, 'listing')) && Ember.assert('listing is required', Ember.get(this, 'listing')));
    }
  });

  _exports.default = _default;
});