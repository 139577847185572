define("@vollersgroup/hub-frontend/components/manage-shop/component", ["exports", "@vollersgroup/hub-frontend/utils/scroll-into-the-first-error-if-needed", "@vollersgroup/hub-frontend/config/environment", "@vollersgroup/hub-frontend/utils/get-first-adapter-error-message"], function (_exports, _scrollIntoTheFirstErrorIfNeeded, _environment, _getFirstAdapterErrorMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A module to create/edit a shop. To create a new shop, create an empty model and pass it to the component.
   *
   * ```html
   * {{manage-shop shop=myShop action="myAction"}}
   * ```
   *
   * The action should have the following format:
   *
   * ```js
   * myAction: function(shop) {
   *     // Do something with the shop
   *     shop.save();
   * }
   * ```
   *
   * The action is only called when the shop is valid.
   *
   * @namespace Component
   * @class ManageShop
   * @extends Ember.Component
   * @uses Mixin.Slug
   */
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    toast: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    availableData: Ember.inject.service(),
    l10n: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * The shop that should be edited / created.
     *
     * @attribute shop
     * @type Model.Shop
     * @required
     */
    shop: null,

    /**
     * The name of the action to call when the creation / editing of the shop is done.
     * This action receives the edited shop as a single parameter.
     *
     * @attribute action
     * @type string
     * @required
     */
    action: null,

    /**
     * If this is set to true, this is an edit dialog (instead of a create dialog)
     *
     * @attribute isEdit
     * @type Boolean
     * @optional
     */
    isEdit: false,

    /**
     * The name of the action to call when the creation / editing of the shop is done.
     * This action receives the edited shop as a single parameter.
     * This action is called when the process should be ended instead of continuing to the next step.
     *
     * @attribute actionExit
     * @type string
     * @required
     */
    actionExit: null,
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Reads `cmarketPriceCurrency` from company settings.
     *
     * @property cmarketPriceCurrency
     * @type {Number}
     * @public
     */
    cmarketPriceCurrency: Ember.computed.reads('userSession.currentCompany.settings.cmarketPriceCurrency'),

    /**
     * Reads `dynamicPricingEnabled` from company settings.
     *
     * @property dynamicPricingEnabled
     * @type {Boolean}
     * @public
     */
    dynamicPricingEnabled: Ember.computed.reads('userSession.currentCompany.settings.dynamicPricingEnabled'),

    /**
     * Reads `dynamicPricingEnabled` from company settings.
     *
     * @property hasDynamicPricing
     * @type {Boolean}
     * @public
     */
    requireExchangeRate: Ember.computed('shop.currency', 'cmarketPriceCurrency', 'dynamicPricingEnabled', {
      get: function get()
      /*key*/
      {
        var cmarketCurrency = Ember.get(this, 'cmarketPriceCurrency');
        var cmarketEnabled = Ember.get(this, 'dynamicPricingEnabled');
        var shopCurrency = Ember.get(this, 'shop.currency');

        if (!cmarketEnabled) {
          return false;
        }

        return cmarketCurrency !== shopCurrency;
      }
    }),

    /**
     * When this is true, some computation is currently occurring.
     *
     * @property isLoading
     * @type Boolean
     * @private
     */
    isLoading: false,

    /**
     * Flag indicating wheter all error messages should be displayed.
     */
    didValidate: false,
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Upload a logo for this shop.
       *
       * @event uploadShopLogo
       * @param {Object} file
       * @private
       */
      uploadShopLogo: function uploadShopLogo(file) {
        var _this = this;

        var headers = {
          'Cropster-Authenticate': 'suppress-www-authenticate'
        };
        var data = {
          entity: 'SHOP_LOGO'
        };
        var url = _environment.default.apiBaseUrl + '/' + _environment.default.apiNamespace + '/media';
        file.upload({
          url: url,
          data: data,
          headers: headers
        }).then(function (response) {
          var data = response.body;
          var id = data.id;
          var url = data.url;
          var shop = Ember.get(_this, 'shop');
          shop.set('logo', Ember.Object.create({
            id: id,
            fullxfull: url,
            s500x500: url
          }));
        }).catch(function () {
          var l10n = Ember.get(_this, 'l10n');
          var errMessage = l10n.t('An error occurred while trying to upload the image. Please try again with a different file.');

          _this.get('toast').error(errMessage); // Remove file itself from queue so user can try upload again


          file.get('queue').remove(file);
        });
      },

      /**
       * Upload a header image for this shop.
       *
       * @event uploadShopHeaderImage
       * @param {Object} file
       * @private
       */
      uploadShopHeaderImage: function uploadShopHeaderImage(file) {
        var _this2 = this;

        var headers = {
          'Cropster-Authenticate': 'suppress-www-authenticate'
        };
        var data = {
          entity: 'SHOP_HEADER'
        };
        var url = _environment.default.apiBaseUrl + '/' + _environment.default.apiNamespace + '/media';
        file.upload({
          url: url,
          data: data,
          headers: headers
        }).then(function (response) {
          var data = response.body;
          var id = data.id;
          var url = data.url;
          var shop = Ember.get(_this2, 'shop');
          shop.set('header', Ember.Object.create({
            id: id,
            fullxfull: url,
            s1920x500: url,
            s500x500: url,
            s300x300: url
          }));
        }).catch(function () {
          var l10n = Ember.get(_this2, 'l10n');
          var errMessage = l10n.t('An error occurred while trying to upload the image. Please try again with a different file.');

          _this2.get('toast').error(errMessage); // Remove file itself from queue so user can try upload again


          file.get('queue').remove(file);
        });
      },

      /**
       * Use the company logo as shop logo.
       *
       * @event useCompanyLogo
       * @private
       */
      useCompanyLogo: function useCompanyLogo() {
        var model = this.getAttr('shop');
        var companyImageId = this.getAttr('shop').get('company.logo.id');
        this.get('ajax').post('/media/' + companyImageId + '/copy', {
          data: {
            entity: 'SHOP_LOGO'
          }
        }).then(function (response) {
          var url = response.url;
          var id = response.id;
          model.set('logo', Ember.Object.create({
            id: id,
            fullxfull: url,
            s1920x500: url,
            s500x500: url,
            s300x300: url
          }));
        });
      },

      /**
       * Saves and invokes `action` action.
       *
       * @event save
       * @private
       */
      save: function save() {
        var _this3 = this;

        this._save(Ember.get(this, 'shop')).then(function (shop) {
          _this3.sendAction('action', shop);
        });
      },

      /**
       * Saves and invokes `actionExit` action.
       *
       * @event saveExit
       * @private
       */
      saveExit: function saveExit() {
        var _this4 = this;

        this._save(Ember.get(this, 'shop')).then(function (shop) {
          _this4.sendAction('actionExit', shop);
        });
      }
    },

    /**
     * Primitive method to save a shop instance.
     *
     * @private
     * @method _save
     * @param {Model.Shop} shop
     * @return {Promise}
     */
    _save: function _save(shop) {
      var _this5 = this;

      return shop.validate().then(function (_ref) {
        var validations = _ref.validations;

        _this5.set('didValidate', true);

        if (!validations.get('isValid')) {
          (0, _scrollIntoTheFirstErrorIfNeeded.default)();
          return Ember.RSVP.Promise.reject();
        }
      }).then(function () {
        var flashMessages = Ember.get(_this5, 'flashMessages');
        var changeSet = shop.changedAttributes();
        Ember.set(_this5, 'isLoading', true);
        return shop.save().then(function () {
          var availableData = Ember.get(_this5, 'availableData');
          availableData.notifyPropertyChange('shops');

          if (changeSet.usdExchangeRate) {
            _this5._updateCmarketPrice(shop);
          }

          if (Ember.get(shop, 'logo.id')) {
            _this5._copyCompanyLogo(shop);
          }

          return shop;
        }).catch(function (adapterError) {
          var serverErrorMessage = (0, _getFirstAdapterErrorMessage.default)(adapterError);
          var errorMessage = Ember.get(_this5, 'l10n').t('An error occurred while saving the shop: {{serverErrorMessage}}', {
            serverErrorMessage: serverErrorMessage
          });
          flashMessages.setErrors([errorMessage]);
          window.scrollTo(0, 0);
          return Ember.RSVP.Promise.reject();
        }).finally(function () {
          return Ember.set(_this5, 'isLoading', false);
        });
      });
    },

    /**
     * Copies shop logo to company if
     * there's currently no logo set.
     *
     * @private
     * @method _copyCompanyLogo
     * @param {Model.Shop} shop
     * @return {Void}
     */
    _copyCompanyLogo: function _copyCompanyLogo(shop) {
      var _this6 = this;

      var companyCallback = function companyCallback(company) {
        if (!Ember.isNone(Ember.get(company, 'logo'))) {
          return;
        }

        var successCallback = function successCallback(response) {
          company.set('logo', Ember.Object.create({
            fullxfull: response.url,
            s500x500: response.url,
            s300x300: response.url,
            id: response.id
          }));
          company.save();
        };

        var logoId = Ember.get(shop, 'logo.id');
        Ember.get(_this6, 'ajax').post("/media/".concat(logoId, "/copy"), {
          data: {
            entity: 'COMPANY_LOGO'
          }
        }).then(successCallback);
      };

      Ember.get(this, 'userSession.currentCompany').then(companyCallback);
    },

    /**
     * Invokes a POST if `usdExchangeRate`
     * has been changed to trigger update
     * of C-Market prices for listings.
     *
     * @private
     * @method _updateCmarketPrice
     * @param {Model.Shop} shop
     * @return {Void}
     */
    _updateCmarketPrice: function _updateCmarketPrice()
    /*shop*/
    {
      var _this7 = this;

      var settingsCallback = function settingsCallback(settings) {
        var url = "/settings/".concat(settings.id, "/updateCmarketPrice");

        var successCallback = function successCallback() {
          Ember.get(_this7, 'toast').success(Ember.get(_this7, 'l10n').t('All coffees using dynamic pricing have been updated with new exchange rate.'));
        };

        var failureCallback = function failureCallback(error) {
          var serverErrorMessage = Ember.get(error, 'payload.error');
          var errorMessage = Ember.get(_this7, 'l10n').t('An error occurred while updating coffees with new exchange rate: {{serverErrorMessage}}', {
            serverErrorMessage: serverErrorMessage
          });
          Ember.get(_this7, 'toast').error(errorMessage);
        };

        Ember.get(_this7, 'ajax').post(url).then(successCallback, failureCallback);
      };

      Ember.get(this, 'userSession.currentCompany.settings').then(settingsCallback);
    }
  });

  _exports.default = _default;
});