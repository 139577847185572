define("@vollersgroup/hub-frontend/components/shopping-cart-order/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * The order to display.
     *
     * @attribute cart
     * @type Model.Order
     */
    cart: null,

    /**
     * This action is called when a cart item should be removed from the cart.
     *
     * @attribute removeCartItem
     * @type Function
     */
    removeCartItem: null,

    /**
     * Remove a cart with all its items.
     *
     * @event removeCart
     * @param {Model.Order}
     */
    removeCart: null,
    actions: {
      removeCart: function removeCart(cart) {
        this.sendAction('removeCart', cart);
      }
    }
  });

  _exports.default = _default;
});