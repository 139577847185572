define("@vollersgroup/hub-frontend/store/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OQMyoOT/",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"page-title\",[[27,\"t\",[\"Browse coffee\"],null]],null],false],[0,\"\\n\\n\"],[7,\"section\"],[11,\"class\",\"margin-all-0 padding-top-3 section--highlighted padding-bottom-4 flex-grow-1\"],[9],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"wrapper\"],[9],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"layout__sidebar--left\"],[9],[0,\"\\n      \"],[1,[27,\"filter-container\",null,[[\"search\",\"update\",\"searchAgentModal\"],[[23,[\"search\"]],\"updateFilter\",[23,[\"searchAgentModal\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"layout__sidebar--main relative\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"filter__display\"],[9],[0,\"\\n        \"],[1,[27,\"filter-display\",null,[[\"search\"],[[23,[\"search\"]]]]],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[1,[27,\"listing-list\",null,[[\"search\"],[[23,[\"search\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/store/index/template.hbs"
    }
  });

  _exports.default = _default;
});