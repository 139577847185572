define("@vollersgroup/hub-frontend/components/c-input-password/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R5peHgU9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"input__group input__password\"],[9],[0,\"\\n  \"],[1,[27,\"c-input\",null,[[\"class\",\"type\",\"placeholder\",\"value\",\"required\",\"disabled\",\"hasError\",\"id\",\"autocomplete\"],[[23,[\"class\"]],[27,\"if\",[[23,[\"show\"]],\"text\",\"password\"],null],[23,[\"placeholder\"]],[23,[\"value\"]],[23,[\"required\"]],[23,[\"disabled\"]],[23,[\"hasError\"]],[23,[\"id\"]],[23,[\"autocomplete\"]]]]],false],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"input__label--right\"],[9],[0,\"\\n    \"],[1,[27,\"c-checkbox\",null,[[\"id\",\"checked\",\"class\"],[[23,[\"showId\"]],[23,[\"show\"]],\"input__checkbox\"]]],false],[0,\"\\n    \"],[7,\"label\"],[11,\"class\",\"input__checkbox--label\"],[12,\"for\",[21,\"showId\"]],[9],[1,[27,\"t\",[\"Show\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/c-input-password/template.hbs"
    }
  });

  _exports.default = _default;
});