define("@vollersgroup/hub-frontend/company/ability", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend({
    userSession: Ember.inject.service(),
    roleLevel: Ember.computed.alias('userSession.currentUser.roleLevel'),
    canInviteUser: Ember.computed.gte('roleLevel', 4),
    canViewInvitedUsers: Ember.computed.gte('roleLevel', 4),
    canVerify: Ember.computed.gte('roleLevel', 4),
    canEdit: Ember.computed.gte('roleLevel', 4),
    canEditIntegrations: Ember.computed.gte('roleLevel', 4),
    canManageUsers: Ember.computed('roleLevel', 'model.roleLevel', 'model.id', 'userSession.currentUser.id', function () {
      if (!this.get('model')) {
        return this.get('roleLevel') >= 4;
      }

      if (this.get('userSession.currentUser.id') === this.get('model.id')) {
        return false;
      }

      if (this.get('model.roleLevel') > this.get('roleLevel')) {
        return false;
      }

      return this.get('roleLevel') >= 4;
    })
  });

  _exports.default = _default;
});