define("@vollersgroup/hub-frontend/order/model", ["exports", "ember-data", "moment", "@vollersgroup/hub-frontend/custom-objects/address-object"], function (_exports, _emberData, _moment, _addressObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var hasMany = _emberData.default.hasMany;
  var belongsTo = _emberData.default.belongsTo;
  var attr = _emberData.default.attr;

  /**
   * The order model
   *
   * An order can have one of the following states: CART, REQUEST, REQUEST_REJECTED, OFFER, OFFER_REJECTED, OFFER_CANCELED, ACCEPTED
   *
   * @namespace Model
   * @class Order
   * @extends Model
   */
  var _default = Model.extend({
    /**
     * A message from the seller to the buyer.
     *
     * @attribute orderNotes
     * @type String
     * @optional
     */
    orderNotes: attr('string'),

    /**
     * If the cart is non-editable.
     * For example, if a cart is automatically generated for an auction, the cart's content cannot be changed.
     *
     * @attribute fixed
     * @type Boolean
     * @default false
     * @optional
     */
    fixed: attr('boolean', {
      defaultValue: false
    }),

    /**
     * The billing address of the order.
     *
     * @attribute billingAddress
     * @type CustomObject.AddressObject
     * @optional
     */
    billingAddress: attr('address', {
      defaultValue: function defaultValue() {
        return _addressObject.default.create();
      }
    }),

    /**
     * The shipping address of the order.
     *
     * @attribute shippingAddress
     * @type CustomObject.AddressObject
     * @optional
     */
    shippingAddress: attr('address', {
      defaultValue: function defaultValue() {
        return _addressObject.default.create();
      }
    }),

    /**
     * The VAT number of the order.
     *
     * @attribute vatNumber
     * @type String
     * @optional
     */
    vatNumber: attr('string'),

    /**
     * If payment is requested by seller when creating an offer.
     *
     * @attribute paymentRequested
     * @type {Boolean}
     */
    paymentRequested: attr('boolean', {
      defaultValue: false
    }),

    /**
     * If payment is required during checkout.
     *
     * @attribute priorPaymentRequired
     * @type {Boolean}
     */
    priorPaymentRequired: attr('boolean', {
      defaultValue: false
    }),

    /**
     * If the order has been paid via V-Hub.
     *
     * @attribute paid
     * @type {Boolean}
     */
    paid: attr('boolean', {
      defaultValue: false
    }),

    /**
     * The payment status if the order was paid via V-Hub.
     *
     * @attribute paymentStatus
     * @type {String}
     */
    paymentStatus: attr('string'),

    /**
     * The date when an order was paid via V-Hub.
     *
     * @attribute paymentDate
     * @type {Date}
     */
    paymentDate: attr('timestamp'),

    /**
     * The state of this order. Use this to check if the order is still a cart or already completed.
     *
     * @attribute status
     * @type "CART"|"REQUEST"|"OFFER"|"REQUEST_REJECTED"|"OFFER_CANCELED"|"OFFER_REJECTED"|"ACCEPTED"
     * @default "CART"
     */
    status: attr('string', {
      defaultValue: 'CART'
    }),

    /**
     * The internal number of this order.
     *
     * @attribute orderNumber
     * @type String
     */
    orderNumber: attr('string'),

    /**
     * The shipping terms for this order.
     *
     * @attribute shippingTerms
     * @type String
     */
    shippingTerms: attr('string'),

    /**
     * If roasted samples are ordered or not.
     *
     * @attribute roastedSamples
     * @type {Boolean}
     */
    roastedSamples: attr('boolean', {
      defaultValue: false
    }),

    /**
     * The date when the initial request was made. (= the order was converted from CART to REQUEST)
     *
     * @attribute requestCreatedDate
     * @type Date
     */
    requestCreatedDate: attr('timestamp'),

    /**
     * The date when the request was converted into an offer.
     *
     * @attribute offerCreatedDate
     * @type Date
     */
    offerCreatedDate: attr('timestamp'),

    /**
     * The date when the order was closed. It is closed when it's state is one of ACCEPTED, OFFER_CANCELED, OFFER_REJECTED, REQUEST_REJECTED
     *
     * @attribute closedDate
     * @type Date
     */
    closedDate: attr('timestamp'),

    /**
     * An array of objects which contain additional costs added by the seller, like shipping, taxes etc.
     *
     * The objects of the array have two properties: label and amount:
     *
     * ```js
     * {
     *     label: "Shipping",
     *     amount: 300
     * }
     * ```
     *
     * @attribute additionalCosts
     * @type Array
     * @default []
     */
    additionalCosts: attr('array', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),

    /**
     * Files that are attached to this order by the seller.
     * An array of objects. Every object has two properties: url & fileName: { url: "https://fullurl.com/my_file.pdf", fileName: "my_file.pdf" }
     * Can be a pdf or an image.
     *
     * @attribute documents
     * @type Array
     * @default []
     */
    documents: attr('image-array', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),

    /**
     * The policies of the selling shop at the time when the REQUEST is created.
     * This is used for logging purposes. It is simply a combination of all policy* fields of the shop.
     *
     * @attribute policies
     * @type String
     */
    policies: attr('string'),

    /**
     * The order-items in this order.
     *
     * @attribute orderItems
     * @type Model.OrderItem[]
     */
    orderItems: hasMany('order-item', {
      async: true
    }),

    /**
     * The shop this order belongs to.
     *
     * @attribute shop
     * @type Model.Shop
     */
    shop: belongsTo('shop', {
      async: true
    }),

    /**
     * A conversation belonging to this order.
     *
     * @attribute conversation
     * @type Model.Conversation
     */
    conversation: belongsTo('conversation', {
      async: true,
      inverse: null
    }),

    /**
     * The user which created the order. (buyer)
     *
     * @attribute requestUser
     * @type Model.User
     */
    requestUser: belongsTo('user', {
      async: true
    }),

    /**
     * The company which created the order. (buyer)
     *
     * @attribute buyerCompany
     * @type Model.Company
     */
    buyerCompany: belongsTo('company', {
      async: true
    }),

    /**
     * The user which made the offer. (seller)
     *
     * @attribute sellerUser
     * @type Model.User
     */
    sellerUser: belongsTo('user', {
      async: true
    }),

    /**
     * The seller company.
     *
     * @attribute sellerCompany
     * @type Model.Company
     */
    sellerCompany: belongsTo('company', {
      async: true
    }),

    /**
     * An optional auction to which this order belongs to.
     *
     * @attribute auction
     * @type Model.Auction
     */
    auction: belongsTo('auction', {
      async: true,
      inverse: null
    }),

    /**
     * The orderItems from this order which have active listings.
     *
     * @property orderItemsActive
     * @type Model.OrderItem[]
     * @readOnly
     */
    orderItemsActive: Ember.computed.filterBy('orderItems', 'isActive'),

    /**
     * Appropriate order items for current order `status`.
     * For status `CART` only active items, otherwise all.
     *
     * @property orderItemsForStatus
     * @type Model.OrderItem[]
     * @readOnly
     */
    orderItemsForStatus: Ember.computed('status', 'orderItems.[]', 'orderItemsActive.[]', function () {
      switch (this.get('status')) {
        case 'CART':
          // while being in cart, we only want to deal with
          // active items - order items w/ inactive listing
          // CANNOT be checked out, thus filter them here
          return this.get('orderItemsActive');

        default:
          return this.get('orderItems');
      }
    }),

    /**
     * The orderItems from this order which are samples.
     *
     * @property orderItemsSample
     * @type Model.OrderItem[]
     * @readOnly
     */
    orderItemsSample: Ember.computed.filterBy('orderItemsForStatus', 'sample', true),

    /**
     * Amount of ordered sample items.
     *
     * @property orderItemsSampleAmount
     * @type Number
     * @readOnly
     */
    orderItemsSampleAmount: Ember.computed.reads('orderItemsSample.length'),

    /**
     * The orderItems from this order which are not samples.
     *
     * @property orderItemsCoffee
     * @type Model.OrderItem[]
     * @readOnly
     */
    orderItemsCoffee: Ember.computed.filterBy('orderItemsForStatus', 'sample', false),

    /**
     * If the order contains at least one sample.
     *
     * @property hasSample
     * @type Boolean
     * @readOnly
     */
    hasSample: Ember.computed.gt('orderItemsSampleAmount', 0),

    /**
     * If the order contains at least one listing,
     * which has `priceHidden` flag set to true.
     *
     * @property hasSample
     * @type Boolean
     * @readOnly
     */
    hasHiddenPrice: Ember.computed('orderItemsCoffee.@each.priceInitialAmount', function () {
      return !!this.get('orderItemsCoffee').find(function (item) {
        return !item.get('priceInitialAmount');
      });
    }),

    /**
     * If the order contains at least one coffee that is not a sample.
     *
     * @property hasCoffee
     * @type Boolean
     * @readOnly
     */
    hasCoffee: Ember.computed.gt('orderItemsCoffee.length', 0),

    /*
     * Indicates whether current order is auction samples order.
     *
     * @type Boolean
     */
    isAuctionSamplesBundle: Ember.computed('hasSample', 'auction.content', function () {
      if (!Ember.get(this, 'hasSample')) {
        return false;
      }

      return !!Ember.get(this, 'auction.content');
    }),

    /*
     * Amount how many times is each sample withing this order.
     *
     * NOTE: It's the same for each sample, so we can take the the first sample and it's amount.
     */
    sampleBundleAmount: Ember.computed.alias('orderItemsSample.firstObject.amount'),

    /**
     * The estimated total price of all coffee purchases of this order.
     * Is the sum of the estimated prices of all order-items.
     *
     * @property estimatedTotalCoffeePrice
     * @type Number
     * @readOnly
     */
    estimatedTotalCoffeePrice: Ember.computed('orderItemsCoffee.@each.estimatedTotalPrice', function () {
      var total = 0;
      this.get('orderItemsCoffee').forEach(function (item) {
        if (item.get('estimatedTotalPrice')) {
          total += item.get('estimatedTotalPrice') * 1;
        }
      });
      return total;
    }),

    /**
     * The estimated total price of all samples purchases of this order.
     * Is the sum of the estimated prices of all order-items.
     *
     * @property estimatedTotalSamplePrice
     * @type Number
     * @readOnly
     */
    estimatedTotalSamplePrice: Ember.computed('orderItemsSample.@each.estimatedTotalPrice', function () {
      var total = 0;
      this.get('orderItemsSample').forEach(function (item) {
        if (item.get('estimatedTotalPrice')) {
          total += item.get('estimatedTotalPrice') * 1;
        }
      });
      return total;
    }),

    /**
     * The estimated total price of all order-items of this order.
     * Is the sum of the estimated prices of all order-items.
     *
     * @property estimatedTotalPrice
     * @type Number
     * @readOnly
     */
    estimatedTotalPrice: Ember.computed('estimatedTotalSamplePrice', 'estimatedTotalCoffeePrice', 'additionalCosts.@each.costAmount', function () {
      var total = this.get('estimatedTotalSamplePrice') + this.get('estimatedTotalCoffeePrice');
      this.get('additionalCosts').forEach(function (item) {
        total += item.costAmount * 1;
      });
      return total;
    }),

    /**
     * If this order is closed.
     *
     * @property isClosed
     * @type Boolean
     */
    isClosed: Ember.computed('status', function () {
      if (['REQUEST_REJECTED', 'OFFER_REJECTED', 'OFFER_CANCELED', 'ACCEPTED'].indexOf(this.get('status')) > -1) {
        return true;
      }

      return false;
    }),

    /**
     * If this order is rejected.
     *
     * @property isRejected
     * @type Boolean
     */
    isRejected: Ember.computed('status', function () {
      if (['REQUEST_REJECTED', 'OFFER_REJECTED', 'OFFER_CANCELED'].indexOf(this.get('status')) > -1) {
        return true;
      }

      return false;
    }),

    /**
     * If any price or amount has changed in the OFFER coming from the REQUEST.
     * This is true if either any priceFinalAmount or any finalAmount has changed.
     *
     * @property offerHasChanged
     * @type Boolean
     */
    offerHasChanged: Ember.computed('orderItems.@each.isLoaded', 'orderItems.@each.isFulFilled', 'orderItems.@each.priceHasChanged', 'orderItems.@each.amountHasChanged', function () {
      var changed = false;
      this.get('orderItems').forEach(function (orderItem) {
        if (orderItem.get('priceHasChanged') || orderItem.get('amountHasChanged')) {
          changed = true;
        }
      });
      return changed;
    }),
    lastDate: Ember.computed('requestCreatedDate', 'offerCreatedDate', 'closedDate', function () {
      if (this.get('closedDate')) {
        return this.get('closedDate');
      }

      if (this.get('offerCreatedDate')) {
        return this.get('offerCreatedDate');
      }

      return this.get('requestCreatedDate');
    }),
    billingAddressIsShippingAddress: Ember.computed('billingAddress', 'shippingAddress', function () {
      var shipping = this.get('shippingAddress');
      var billing = this.get('billingAddress');
      return shipping.isEqualStrict(billing);
    }),

    /**
     * If current user is buyer of this order.
     *
     * @property isBuyer
     * @type Boolean
     */
    isBuyer: Ember.computed('buyerCompany.id', 'userSession.currentCompany.id', function () {
      var userCompanyId = this.get('userSession.currentCompany.id');
      var buyerCompanyId = this.get('buyerCompany.id');
      return userCompanyId === buyerCompanyId;
    }),

    /**
     * If current user is seller of this order.
     *
     * @property isSeller
     * @type Boolean
     */
    isSeller: Ember.computed('sellerCompany.id', 'userSession.currentCompany.id', function () {
      var userCompanyId = this.get('userSession.currentCompany.id');
      var sellerCompanyId = this.get('sellerCompany.id');
      return userCompanyId === sellerCompanyId;
    }),

    /**
     * If `requestCreatedDate` is not older than 24h.
     *
     * @property isNewRequest
     * @type Boolean
     */
    isNewRequest: Ember.computed('requestCreatedDate', function () {
      var requestCreatedDate = this.get('requestCreatedDate');

      if (Ember.isNone(requestCreatedDate)) {
        return false;
      }

      var yesterday = (0, _moment.default)().subtract(1, 'days');
      requestCreatedDate = (0, _moment.default)(requestCreatedDate);
      return requestCreatedDate.isSameOrAfter(yesterday);
    })
  });

  _exports.default = _default;
});