define("@vollersgroup/hub-frontend/components/flash-messages/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    flashMessages: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * If margin-bottom should be added to the messages.
     *
     * @attribute noMargin
     * @type Boolean
     * @default false
     */
    noMargin: false,

    /**
     * If true, will empty flash messages from service
     * each time the component gets detached from DOM.
     *
     * @attribute autoClear
     * @type Boolean
     * @default true
     */
    autoClear: true,
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Clear messages on destruction of component
     * if `autoClear` property is not set to false.
     *
     * @public
     * @method willDestroy
     * @return {Void}
     */
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      if (!Ember.get(this, 'autoClear')) {
        return;
      }

      Ember.get(this, 'flashMessages').clear();
    }
  });

  _exports.default = _default;
});