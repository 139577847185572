define("@vollersgroup/hub-frontend/application/authorizer", ["exports", "ember-simple-auth/authorizers/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    authorize: function authorize(data, block) {
      block('Cropster-Authenticate', 'suppress-www-authenticate');
    }
  });

  _exports.default = _default;
});