define("@vollersgroup/hub-frontend/components/order-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A single order which is not a cart anymore.
   *
   * ```html
   * {{order-item order=order}}
   * ```
   *
   * @namespace Component
   * @class OrderItem
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    classNames: ['listing__item', 'card', 'card__order', 'card--grey'],
    router: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * The order which should be displayed.
     *
     * @attribute order
     * @type Model.Order
     */
    order: null,
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Links to order if clicked in an area
     * outside of the default one specified.
     *
     * @param  {Object} event jQuery event.
     * @return {Void}
     */
    click: function click() {
      this.get('router').transitionTo('admin.orders.show', this.get('order'));
    }
  });

  _exports.default = _default;
});