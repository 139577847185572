define("@vollersgroup/hub-frontend/components/c-checkbox/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A simple bound checkbox
   *
   * ```html
   * {{c-checkbox checked=isChecked id="my_checkbox" required=false}}
   * ```
   *
   * @namespace Component
   * @class CCheckbox
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    tagName: '',
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * The id for the input
     *
     * @attribute id
     * @type String
     * @optional
     * @public
     */
    id: null,

    /**
     * A class string for the input
     *
     * @attribute class
     * @type String
     * @optional
     * @public
     */
    class: null,

    /**
     * If the input is required
     *
     * @attribute required
     * @type Boolean
     * @default false
     * @optional
     * @public
     */
    required: false,

    /**
     * If the input is checked (=active)
     *
     * @attribute checked
     * @type Boolean
     * @required
     * @public
     */
    checked: null,

    /**
     * This action is called whenever the selection changes.
     *
     * @attribute action
     * @type String|Function
     * @optional
     */
    action: null,

    /**
     * If this is set to true, the checkbox will work without a label.
     *
     * @attribute standalone
     * @type Boolean
     * @optional
     * @default false
     */
    standalone: false,
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Update the `checked` attribute of <input>.
       *
       * @event update
       * @param {Boolean} checked
       * @private
       */
      update: function update(checked) {
        Ember.set(this, 'checked', checked);
        this.sendAction('action', checked);
      }
    },
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Calculates dom id for <select> either by given
     * `id` attribute or autoassigned select dom id.
     *
     * @property domId
     * @type {String}
     * @private
     */
    domId: Ember.computed('id', {
      get: function get()
      /*key*/
      {
        var id = Ember.get(this, 'id');

        if (!Ember.isNone(id)) {
          return id;
        }

        id = Ember.guidFor(this);
        return "input-".concat(id);
      }
    })
  });

  _exports.default = _default;
});