define("@vollersgroup/hub-frontend/store/shop/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    parentController: Ember.inject.controller('store.shop'),
    search: Ember.computed.alias('parentController.search'),
    searchAgentModal: Ember.computed.alias('parentController.searchAgentModal')
  });

  _exports.default = _default;
});