define("@vollersgroup/hub-frontend/store/company/route", ["exports", "@vollersgroup/hub-frontend/mixins/reset-scroll"], function (_exports, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_resetScroll.default, {
    model: function model(params) {
      return this.store.find('company', params.company_id);
    },
    actions: {
      /**
       * Redirect to 404 page when an error occurs while loading the model.
       *
       * This happens especially if the model could not be loaded because it
       * doesn't exist (anymore) or because of missing access rights.
       *
       * @event error
       * @returns Promise
       * @private
       */
      error: function error() {
        // https://github.com/emberjs/ember.js/issues/12945
        return this.replaceWith('/page-not-found');
      }
    }
  });

  _exports.default = _default;
});