define("@vollersgroup/hub-frontend/helpers/darken-background-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.darkenBackgroundImage = darkenBackgroundImage;
  _exports.default = void 0;

  function darkenBackgroundImage(params
  /*, hash*/
  ) {
    // returning `background-image: undefined` results
    // in a proxied call to the backend, thus the auth
    // dialog from basic authentication would popup...
    var url = encodeURI(params.join(''));

    if (Ember.isEmpty(url)) {
      return null;
    }

    var darkenColor = 'rgba(51, 59, 70, 0.45)'; // Value copied over from css

    var darkenLayer = "linear-gradient(".concat(darkenColor, ",").concat(darkenColor, ")");
    var bg = "background-size:cover;background-position:center;background-repeat:no-repeat;";
    return Ember.String.htmlSafe("background:".concat(darkenLayer, ",url(").concat(url, ");").concat(bg));
  }

  var _default = Ember.Helper.helper(darkenBackgroundImage);

  _exports.default = _default;
});