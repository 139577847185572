define("@vollersgroup/hub-frontend/admin/messages/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'term', 'company', 'pageSize'],
    page: 1,
    term: '',
    pageSize: 10,
    company: null,
    maxPages: Ember.computed.reads('model.conversations.meta.maxPages'),
    actions: {
      changePageSize: function changePageSize() {
        Ember.set(this, 'page', 1);
      },
      setCompany: function setCompany(company) {
        company = company && company.id;
        Ember.set(this, 'company', company);
        Ember.set(this, 'page', 1);
      },
      setTerm: function setTerm(term) {
        Ember.run.debounce(this, this._setTermDebounced, term, 250);
      }
    },
    _setTermDebounced: function _setTermDebounced(term) {
      Ember.set(this, 'term', term);
      Ember.set(this, 'page', 1);
    }
  });

  _exports.default = _default;
});