define("@vollersgroup/hub-frontend/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DoV6D+Y2",
    "block": "{\"symbols\":[],\"statements\":[[1,[21,\"head-layout\"],false],[0,\"\\n\\n\"],[1,[27,\"page-title\",[\"V-Hub\"],null],false],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"environmentBadge\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"corner-ribbon corner-ribbon--primary corner-ribbon--default-left-top\"],[9],[0,\"\\n    \"],[1,[21,\"environmentBadge\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[21,\"outlet\"],false],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"addSampleToCart\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"sample-modal\",null,[[\"listingId\",\"closeModal\"],[[23,[\"addSampleToCart\"]],[27,\"action\",[[22,0,[]],\"dismissAddSampleToCartModal\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"sendMessage\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"message-modal\",null,[[\"messageTo\",\"messageMode\",\"messageSubject\",\"messageContent\",\"messageAttachListing\",\"closeModal\"],[[23,[\"messageTo\"]],[23,[\"messageMode\"]],[23,[\"messageSubject\"]],[23,[\"messageContent\"]],[23,[\"messageAttachListing\"]],[27,\"action\",[[22,0,[]],\"dismissMessageModal\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"showLogin\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"login-modal\",null,[[\"afterLogin\",\"gotoRoute\",\"gotoModel\",\"closeModal\"],[[23,[\"afterLogin\"]],[23,[\"gotoRoute\"]],[23,[\"gotoModel\"]],[27,\"action\",[[22,0,[]],\"dismissLoginModal\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"addToCart\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"add-to-cart-modal\",null,[[\"listingId\",\"closeModal\"],[[23,[\"addToCart\"]],[27,\"action\",[[22,0,[]],\"dismissAddToCartModal\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\"],[11,\"id\",\"modal-wormhole\"],[9],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/application/template.hbs"
    }
  });

  _exports.default = _default;
});