define("@vollersgroup/hub-frontend/user/model", ["exports", "ember-data", "ember-cp-validations", "@vollersgroup/hub-frontend/config/environment"], function (_exports, _emberData, _emberCpValidations, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.UserValidations = void 0;
  var Model = _emberData.default.Model;
  var hasMany = _emberData.default.hasMany;
  var belongsTo = _emberData.default.belongsTo;
  var attr = _emberData.default.attr;
  var assetPrependURL = _environment.default.assetPrependURL;
  var UserValidations = (0, _emberCpValidations.buildValidations)({
    fullName: {
      validators: [(0, _emberCpValidations.validator)('length', {
        allowNone: false,
        min: 3,
        max: 250,
        message: 'Please enter at least 3 characters.'
      })]
    },
    email: {
      validators: [(0, _emberCpValidations.validator)('format', {
        allowBlank: false,
        type: 'email',
        message: 'Please enter an email.'
      })]
    }
  });
  /**
   * The user model
   *
   * @namespace Model
   * @class User
   * @extends Model
   */

  _exports.UserValidations = UserValidations;

  var _default = Model.extend(UserValidations, {
    // -------------------------------------------------------------------------
    // Attributes
    active: attr('boolean'),

    /**
     * The user's full name.
     *
     * @attribute fullName
     * @type String
     */
    fullName: attr('string'),

    /**
     * The user's primary email address.
     *
     * @attribute email
     * @type String
     */
    email: attr('string'),

    /**
     * User has a pending email change request.
     * @attribute pendingEmailChange
     * @type Boolean
     */
    pendingEmailChange: attr('boolean'),

    /**
     * The date and time the user was created, in milliseconds since 1970-01-01.
     *
     * @attribute createdDate
     * @type Date
     */
    createdDate: attr('timestamp'),

    /**
     * The date format of this user.
     *
     * @attribute dateFormat
     * @type String
     */
    dateFormat: attr('string', {
      defaultValue: 'MMM D, YYYY'
    }),

    /**
     * The time format of this user.
     *
     * @attribute timeFormat
     * @type String
     * @default "HH:mm"
     */
    timeFormat: attr('string', {
      defaultValue: 'HH:mm'
    }),

    /**
     * The time zone of the user, in the format "Europe/Vienna".
     *
     * @attribute timezone
     * @type String
     */
    timezone: attr('string'),

    /**
     * The preferred locale of the user, in the format "en".
     *
     * @attribute locale
     * @type String
     */
    locale: attr('string', {
      defaultValue: 'en'
    }),

    /**
     * The URL of the avatar.
     *
     * @attribute avatar
     * @type Object
     */
    avatar: attr('image'),

    /**
     * The actual URL of the avatar, in 300x300.
     *
     * @property avatarUrl
     * @type String
     * @readOnly
     */
    avatarUrl: Ember.computed('avatar.s300x300', function () {
      var url = this.get('avatar.s300x300');
      return url ? url : assetPrependURL + '/assets/img/placeholder-profile-image.png';
    }),

    /**
     * The role of this user.
     *
     * @attribute role
     * @type String
     */
    role: attr('string'),

    /**
     * The company the user belongs to.
     *
     * @attribute company
     * @type Model.Company
     */
    company: belongsTo('company', {
      async: true,
      inverse: 'users'
    }),

    /**
     * The id of the company.
     *
     * @attribute companyId
     * @type String
     */
    companyId: attr('string'),

    /**
     * Claimed ratings from the user.
     *
     * @attribute ratings
     * @type Array<Model.Ratings>
     */
    ratings: hasMany('rating', {
      async: true
    }),

    /**
     * Wishlisted items from the user.
     *
     * @attribute wishlistItems
     * @type Array<Model.WishlistItem>
     */
    wishlistItems: hasMany('wishlist-item', {
      async: true
    }),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Hash map of wish list items with listing id as key.
     *
     * @property wishlistListings
     * @type Object
     */
    wishlistListings: Ember.computed('wishlistItems.[]', function () {
      var map = {};
      Ember.get(this, 'wishlistItems').forEach(function (wishlistItem) {
        map[Ember.get(wishlistItem, 'listing.id')] = wishlistItem;
      });
      return map;
    }),

    /**
     * Hash map of wish list items with listing id as key.
     *
     * @property ratingListings
     * @type Object
     */
    ratingListings: Ember.computed('ratings.[]', function () {
      var map = {};
      Ember.get(this, 'ratings').toArray().sort(function (a, b) {
        var dateA = Ember.get(a, 'createdDate');
        var dateB = Ember.get(b, 'createdDate');
        return dateA > dateB;
      }).forEach(function (rating) {
        map[Ember.get(rating, 'listing.id')] = rating;
      });
      return map;
    }),

    /**
     * The full date format of the user.
     * Built from dateFormat and timeFormat.
     *
     * @property fullDateFormat
     * @type String
     */
    fullDateFormat: Ember.computed('dateFormat', 'timeFormat', function () {
      return this.get('dateFormat') + ' - ' + this.get('timeFormat');
    }),

    /**
     * At the moment, username is simply an alias for email.
     *
     * @property username
     * @type String
     *
     */
    username: Ember.computed.alias('email'),

    /**
     * For a fully completed profile user have to have a valid
     * user, company and location model.
     *
     * @property hasCompleteProfile
     * @type Boolean
     */
    hasCompleteProfile: Ember.computed('validations.isInvalid', 'company.validations.isInvalid', 'hasDirtyAttributes', 'company.hasDirtyAttributes', 'company.location.hasDirtyAttributes', function () {
      if (Ember.get(this, 'validations.isInvalid')) {
        return false;
      }

      if (Ember.get(this, 'company.validations.isInvalid')) {
        return false;
      }

      if (Ember.get(this, 'hasDirtyAttributes')) {
        return false;
      }

      if (Ember.get(this, 'company.hasDirtyAttributes')) {
        return false;
      }

      if (Ember.get(this, 'company.location.hasDirtyAttributes')) {
        return false;
      }

      return true;
    }),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * The access level of this user. The higher, the more rights.
     */
    roleLevel: Ember.computed('company.{content,isFulfilled}', 'role', function () {
      if (Ember.isEmpty(this.get('company')) || Ember.isEmpty(this.get('company.content'))) {
        return 0;
      }

      var role = this.get('role');

      switch (role) {
        case 'GLOBAL_ADMIN':
          return 5;

        case 'ADMIN':
          return 4;

        case 'MANAGER':
          return 3;

        case 'COLLABORATOR':
          return 2;

        case 'VIEWER':
          return 1;
      }

      return 0;
    })
  });

  _exports.default = _default;
});