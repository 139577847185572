define("@vollersgroup/hub-frontend/store/auctions/show/policy/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kSC3bthl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"wrapper\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"layout__full\"],[9],[0,\"\\n    \"],[7,\"h2\"],[9],[1,[27,\"t\",[\"Auction policy\"],null],false],[10],[0,\"\\n    \"],[1,[27,\"auction-terms\",null,[[\"auction\"],[[23,[\"model\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/store/auctions/show/policy/template.hbs"
    }
  });

  _exports.default = _default;
});