define("@vollersgroup/hub-frontend/components/auction-heartbeat/component", ["exports", "@vollersgroup/hub-frontend/config/environment", "moment"], function (_exports, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var THIRTY_MINUTES = 30 * 60 * 1000;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    userSession: Ember.inject.service(),
    classNames: ['hidden'],
    roleLevel: Ember.computed.alias('userSession.currentUser.roleLevel'),
    auctionIsOver: Ember.computed('auction.closedDate', function () {
      // Keep a 30 minute interval where we still send heartbeat
      // For null it always returns false
      return (0, _moment.default)(Ember.get(this, 'auction.closedDate')).isAfter((0, _moment.default)().add(30, 'minutes'));
    }),
    tooSoonToSend: Ember.computed('auction.remainingTimeToStart', function () {
      var remainingTimeToStart = Ember.get(this, 'auction.remainingTimeToStart'); // Send heartbeat 30 minutes before the auction starts.

      return remainingTimeToStart > THIRTY_MINUTES;
    }),
    isTest: Ember.computed(function () {
      return _environment.default.environment === 'test';
    }),
    _watchForChanges: Ember.observer('roleLevel', 'auction.isAccepted', function () {
      this._sendHeartbeat();
    }),
    auction: null,

    /*
     * Interval for posting to server in ms.
     * By default 30 seconds
     */
    interval: 30 * 1000,
    init: function init() {
      this._super.apply(this, arguments);

      (false && !(Ember.get(this, 'auction')) && Ember.assert('Auction must be provided', Ember.get(this, 'auction')));

      this._sendHeartbeat();
    },
    _sendHeartbeat: function _sendHeartbeat() {
      if (Ember.get(this, 'isTest')) {
        return;
      }

      if (Ember.get(this, 'isDestroyed')) {
        return;
      }

      if (Ember.get(this, 'roleLevel') < 3) {
        return;
      }

      if (!Ember.get(this, 'auction.isAccepted')) {
        return;
      }

      if (Ember.get(this, 'auctionIsOver')) {
        return;
      }

      if (Ember.get(this, 'tooSoonToSend')) {
        var thirtyMinutesBeforeTheAuction = Ember.get(this, 'auction.remainingTimeToStart') - THIRTY_MINUTES;
        Ember.run.later(this, this._sendHeartbeat, thirtyMinutesBeforeTheAuction);
        return;
      }

      var auctionId = Ember.get(this, 'auction.id');
      Ember.get(this, 'ajax').post("/auction/".concat(auctionId, "/declareOnline"));
      Ember.run.later(this, this._sendHeartbeat, Ember.get(this, 'interval'));
    }
  });

  _exports.default = _default;
});