define("@vollersgroup/hub-frontend/admin/manage/shop/create/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "@vollersgroup/hub-frontend/mixins/reset-scroll", "ember-can", "moment"], function (_exports, _authenticatedRouteMixin, _resetScroll, _emberCan, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The route to create a new shop.
   *
   * @namespace Route
   * @class ManageShopCreate
   * @extends Ember.Route
   * @uses Mixin.CanMixin
   * @uses Mixin.ResetScroll
   * @uses SimpleAuth.Mixin.AuthenticatedRouteMixin
   */
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _resetScroll.default, _emberCan.CanMixin, {
    availableData: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Renders templates for admin route manually.
     * Per default, it renders secondary nav items
     * from manage pod to `secondary-nav` outlet.
     *
     * @public
     * @method renderTemplate
     * @return {Void}
     */
    renderTemplate: function renderTemplate() {
      this.controller.set('navbar', {
        route: Ember.get(this, 'availableData.shops.length') ? 'admin.manage.overview.shop' : 'admin',
        title: Ember.get(this, 'l10n').t('Create new shop')
      });
      this.render();
      this.render('admin/manage/navbar-subpage', {
        outlet: 'secondary-nav',
        into: 'admin'
      });
    },

    /**
     * Invokes a change for controller's `allSteps`.
     *
     * @public
     * @method deactivate
     * @return {Void}
     */
    activate: function activate() {
      var _this = this;

      Ember.run.once(function () {
        _this.controller.notifyPropertyChange('allSteps');
      });
    },

    /**
     * Performs ability check if user can create a shop.
     *
     * @public
     * @method beforeModel
     * @return {Promise}
     */
    beforeModel: function beforeModel() {
      // Only managers are allowed to create / edit a shop
      if (!this.can('create shop')) {
        this.transitionTo('admin.manage.overview.shop');
        return;
      }

      return this._super.apply(this, arguments);
    },

    /**
     * Create a new shop object.
     *
     * @public
     * @method model
     * @returns {Model.Shop}
     */
    model: function model() {
      return this.store.createRecord('shop', {
        company: this.get('userSession.currentCompany'),
        story: '',
        logo: null,
        location: this.store.createRecord('location', {
          type: 'COMPANY'
        }),
        status: 'DRAFT',
        createdDate: (0, _moment.default)()
      });
    },

    /**
     * Resets route's model and controller step.
     *
     * @public
     * @method deactivate
     * @return {Void}
     */
    deactivate: function deactivate() {
      this.currentModel.rollbackAttributes();

      if (Ember.get(this, 'currentModel.isDestroyed')) {
        this.currentModel.save();
      }

      var step = Ember.get(this, 'userSession.currentCompany.isVerified') ? 1 : 0;
      Ember.set(this, 'controller.step', step);
    }
  });

  _exports.default = _default;
});