define("@vollersgroup/hub-frontend/services/available-data", ["exports", "ember-data", "moment", "@vollersgroup/hub-frontend/gql/hub-backend/queries/self-warehouses-dropdown.graphql", "ember-apollo-client"], function (_exports, _emberData, _moment, _selfWarehousesDropdown, _emberApolloClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PromiseArray = _emberData.default.PromiseArray;
  /**
   * This service contains all data which the current user can chose from at different places.
   * It loads all data (e.g. available shops, available processings, ...) for the given user/company.
   * The service also ensures that data is only loaded once in order to prevent unnecessary requests.
   *
   * Always use data from this service to built select boxes etc.!
   *
   * @namespace Service
   * @class AvailableData
   * @extends Ember.Component
   * @uses Service.UserSession
   * @uses Store
   * @uses Service.Ajax
   */

  var _default = Ember.Service.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    l10n: Ember.inject.service(),
    store: Ember.inject.service(),
    userSession: Ember.inject.service(),
    ajax: Ember.inject.service(),
    apollo: (0, _emberApolloClient.queryManager)({
      service: 'hb-apollo'
    }),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * All shops of the current company.
     *
     * @property shops
     * @type Model.Shop[]
     */
    shops: Ember.computed('store', 'userSession.currentCompany.id', function () {
      var companyId = this.get('userSession.currentCompany.id');

      if (!companyId) {
        return [];
      }

      var query = {
        company: companyId,
        sort: 'NAME'
      };
      return this.get('store').query('shop', query);
    }),

    /**
     * All shops not archived or with status `INACTIVE`.
     *
     * @property availableShops
     * @type {Model.Shop[]}
     * @public
     */
    activeShops: Ember.computed('shops.@each.status', 'shops.@each.archived', function () {
      var shops = this.get('shops');
      return shops.filter(function (shop) {
        if (shop.get('archived')) {
          return false;
        }

        switch (shop.get('status')) {
          case 'INACTIVE':
            return false;

          default:
            return true;
        }
      });
    }),

    /**
     * All producers of the current company.
     *
     * @property producers
     * @type Model.Producer[]
     */
    producers: Ember.computed('store', 'userSession.currentCompany.id', function () {
      var query = {
        company: this.get('userSession.currentCompany.id'),
        sort: 'NAME'
      };
      return this.get('store').query('producer', query);
    }),

    /**
     * All archived producers of the current company.
     *
     * @public
     * @property archivedProducers
     * @type Model.Location[]
     */
    archivedProducers: Ember.computed('store', 'userSession.currentCompany.id', function () {
      var query = {
        company: this.get('userSession.currentCompany.id'),
        type: 'WAREHOUSE',
        archived: true,
        sort: 'NAME'
      };
      return this.get('store').query('producer', query);
    }),

    /**
     * Composition of archived/unarchived producers.
     *
     * @public
     * @property allProducers
     * @type Model.Location[]
     */
    allProducers: Ember.computed.union('producers', 'archivedProducers'),

    /**
     * All unarchived locations of the current company.
     *
     * @public
     * @property locations
     * @type Model.Location[]
     */
    locations: Ember.computed('store', 'userSession.currentCompany.id', function () {
      var query = {
        company: this.get('userSession.currentCompany.id'),
        type: 'WAREHOUSE',
        sort: 'NAME'
      };
      return this.get('store').query('location', query);
    }),
    warehouses: Ember.computed('apollo', function () {
      var promise = this.apollo.query({
        query: _selfWarehousesDropdown.default,
        fetchPolicy: 'network-only'
      }, 'selfCompanyWarehouses');
      return PromiseArray.create({
        promise: promise
      });
    }),

    /**
     * All archived locations of the current company.
     *
     * @public
     * @property archivedLocations
     * @type Model.Location[]
     */
    archivedLocations: Ember.computed('store', 'userSession.currentCompany.id', function () {
      var query = {
        company: this.get('userSession.currentCompany.id'),
        type: 'WAREHOUSE',
        archived: true,
        sort: 'NAME'
      };
      return this.get('store').query('location', query);
    }),

    /**
     * Composition of archived/unarchived locations.
     *
     * @public
     * @property locations
     * @type Model.Location[]
     */
    allLocations: Ember.computed.union('locations', 'archivedLocations'),
    prefillWarehouses: Ember.computed(function () {
      return [{
        name: 'Vollers - Bremen, Germany/Europe',
        country: 'DE',
        city: 'Bremen',
        street: 'Speicherhof 308',
        addressAddition: null,
        zip: '28217',
        state: null
      }, {
        name: 'Vollers - Hamburg, Germany/Europe',
        country: 'DE',
        city: 'Hamburg',
        street: 'Rossweg 20',
        addressAddition: null,
        zip: '20457',
        state: null
      }, {
        name: 'Vollers - Bury St Edmunds, UK/Europe',
        country: 'GB',
        city: 'Bury St Edmunds',
        street: 'Rougham Industrial Estate',
        addressAddition: null,
        zip: 'IP30 9ND',
        state: null
      }, {
        name: 'Vollers - Antwerp, Belgium/Europe',
        country: 'BE',
        city: 'Antwerp',
        street: 'Wilmarsdonksteenweg 33',
        addressAddition: null,
        zip: '2030',
        state: null
      }, {
        name: 'Vollers - Amsterdam, Netherlands/Europe',
        country: 'NL',
        city: 'Amsterdam',
        street: 'Sardiniëweg 4',
        addressAddition: null,
        zip: '1044 AE',
        state: null
      }, {
        name: 'Vollers - Moscow, Russia/Europe',
        country: 'RU',
        city: 'Moscow',
        street: 'Avenariusa 5',
        addressAddition: null,
        zip: '142050',
        state: null
      }, {
        name: 'Vollers - Genoa, Italy/Europe',
        country: 'IT',
        city: 'Genoa',
        street: 'Via Rivarolo, 2',
        addressAddition: null,
        zip: '16161',
        state: null
      }, {
        name: 'Vollers - Trieste, Italy/Europe',
        country: 'IT',
        city: 'Genoa',
        street: 'Via Rivarolo, 2',
        addressAddition: null,
        zip: '16161',
        state: null
      }, {
        name: 'Vollers - Riga, Latvia/Europe',
        country: 'LV',
        city: 'Riga',
        street: 'Lindes, Salaspils novads',
        addressAddition: null,
        zip: '2118',
        state: null
      }, {
        name: 'Vollers - Tallinn, Estonia/Europe',
        country: 'EE',
        city: 'Maardu',
        street: 'Hoidla tea 4',
        addressAddition: null,
        zip: '74115',
        state: null
      }, {
        name: 'ENITI Ltd.- UK/Europe',
        country: 'GB',
        city: 'Bury St. Edmunds',
        street: null,
        addressAddition: null,
        zip: null,
        state: null
      }, {
        name: 'Continental - Kearny, NJ',
        country: 'US',
        city: 'Jersey City',
        street: null,
        addressAddition: null,
        zip: null,
        state: 'NJ'
      }, {
        name: 'Continental - Charleston, SC',
        country: 'US',
        city: 'Charleston',
        street: null,
        addressAddition: null,
        zip: null,
        state: 'SC'
      }, {
        name: 'The Annex - Oakland, CA',
        country: 'US',
        city: 'Oakland',
        street: null,
        addressAddition: null,
        zip: null,
        state: 'CA'
      }, {
        name: 'Universal - Long Beach, CA',
        country: 'US',
        city: 'Long Beach',
        street: null,
        addressAddition: null,
        zip: null,
        state: 'CA'
      }, {
        name: 'The Green Room - Seattle, WA',
        country: 'US',
        city: 'Seattle',
        street: null,
        addressAddition: null,
        zip: null,
        state: 'WA'
      }, {
        name: 'Costa Oro - Portland, OR',
        country: 'US',
        city: 'Portland',
        street: null,
        addressAddition: null,
        zip: null,
        state: 'OR'
      }, {
        name: 'Dupuy Houston - Houston, TX',
        country: 'US',
        city: 'Houston',
        street: null,
        addressAddition: null,
        zip: null,
        state: 'TX'
      }, {
        name: 'Dupuy New Orleans - New Orleans, LA',
        country: 'US',
        city: 'New Orleans',
        street: null,
        addressAddition: null,
        zip: null,
        state: 'LA'
      }, {
        name: 'Paris Brothers - Kansas City, MO',
        country: 'US',
        city: 'Kansas City',
        street: null,
        addressAddition: null,
        zip: null,
        state: 'MO'
      }, {
        name: 'CALM - Melbourne, Australia',
        country: 'AU',
        city: 'Melbourne',
        street: null,
        addressAddition: null,
        zip: null,
        state: null
      }, {
        name: 'Molenbergnatie - Antwerp, Belgium/Europe',
        country: 'BE',
        city: 'Antwerp',
        street: null,
        addressAddition: null,
        zip: null,
        state: null
      }, {
        name: 'Sitos Commodities - London, UK/Europe',
        country: 'GB',
        city: 'London',
        street: null,
        addressAddition: null,
        zip: null,
        state: null
      }, {
        name: 'Schwarze & Cons - Hamburg, Germany/Europe',
        country: 'DE',
        city: 'Hamburg',
        street: null,
        addressAddition: null,
        zip: null,
        state: null
      }, {
        name: 'RPM Freight & Logistics - Padstow, Australia',
        country: 'AU',
        city: 'Padstow',
        street: null,
        addressAddition: null,
        zip: null,
        state: 'NSW'
      }, {
        name: 'Jonker & Schut - Barneveld, Netherlands/Europe',
        country: 'NL',
        city: 'Barneveld',
        street: null,
        addressAddition: null,
        zip: null,
        state: null
      }, {
        name: 'Vollers - Rotterdam, Netherlands/Europe',
        country: 'NL',
        city: 'Rotterdam',
        street: null,
        addressAddition: null,
        zip: null,
        state: null
      }, {
        name: 'Kawanishi - Yokohama, Japan',
        country: 'JP',
        city: 'Yokohama',
        street: null,
        addressAddition: null,
        zip: null,
        state: null
      }];
    }),

    /**
     * All auctions of the current company.
     *
     * @property auctions
     * @type Model.Auction[]
     */
    auctions: Ember.computed('store', 'userSession.currentCompany.id', function () {
      return this.get('store').query('auction', {
        company: this.get('userSession.currentCompany.id'),
        sort: 'NAME'
      });
    }),

    /**
     * All user invitations of the current company.
     *
     * @property userInvitations
     * @type Array
     */
    userInvitations: Ember.computed('ajax', 'userSession.currentCompany.id', function () {
      return this.get('ajax').request('/company/' + this.get('userSession.currentCompany.id') + '/inviteUser');
    }),

    /**
     * All available descriptors.
     *
     * @property descriptors
     * @type Array
     */
    descriptors: Ember.computed('ajax', function () {
      return PromiseArray.create({
        promise: this.get('ajax').request('/sensorialDescriptor')
      });
    }),
    descriptorsMap: Ember.computed('descriptors.isFulfilled', function () {
      var map = {};
      Ember.get(this, 'descriptors').forEach(function (descriptor) {
        map[descriptor.id] = descriptor;
      });
      return map;
    }),

    /**
     * All available varieties.
     *
     * @property varieties
     * @type Array
     */
    varieties: Ember.computed('ajax', function () {
      return PromiseArray.create({
        promise: this.get('ajax').request('/variety')
      });
    }),

    /**
     * All available certifications.
     *
     * @property certifications
     * @type Array
     */
    certifications: Ember.computed('ajax', function () {
      return PromiseArray.create({
        promise: this.get('ajax').request('/certification')
      });
    }),

    /**
     * All available importer requirements, e.g. SELF_IMPORT.
     *
     * @property importerRequirements
     * @type Array
     * @final
     */
    importerRequirements: Ember.computed('l10n', function () {
      return [{
        value: 'SELF_IMPORT',
        label: this.get('l10n').t('I am an importer')
      }, {
        value: 'IMPORTER_NEEDED',
        label: this.get('l10n').t('I have no importer')
      }, {
        value: 'AIR_SHIPPING',
        label: this.get('l10n').t('Air shipping')
      }, {
        value: 'IMPORTER_PROVIDED',
        label: this.get('l10n').t('I have a preferred importer')
      }];
    }),

    /**
     * All available coffee ratings.
     *
     * @type Array
     * @final
     */
    coffeeRatings: Ember.computed('l10n.locale', function () {
      return [{
        value: 'BAD',
        label: this.get('l10n').t('No'),
        iconName: 'rating-no',
        iconClass: 'icon--warning'
      }, {
        value: 'SO_SO',
        label: this.get('l10n').t('Maybe'),
        iconName: 'rating-maybe',
        iconClass: 'icon--light-grey'
      }, {
        value: 'GOOD',
        label: this.get('l10n').t('Yes'),
        iconName: 'rating-yes',
        iconClass: 'icon--green'
      }];
    }),

    /**
     * All available producer types, e.g. COOP.
     *
     * @property producerTypes
     * @type Array
     * @final
     */
    producerTypes: Ember.computed('l10n.locale', function () {
      return [{
        value: 'COOP',
        label: this.get('l10n').t('Cooperation')
      }, {
        value: 'ASSOCIATION',
        label: this.get('l10n').t('Association')
      }, {
        value: 'FARM',
        label: this.get('l10n').t('Farm')
      }, {
        value: 'MILL',
        label: this.get('l10n').t('Mill')
      }];
    }),

    /**
     * All available currencies, e.g. EUR.
     *
     * @property currencies
     * @type Array
     * @final
     */
    currencies: Ember.computed('l10n.locale', function () {
      return [{
        value: 'USD',
        label: this.get('l10n').t('USD'),
        sign: '$'
      }, {
        value: 'CAD',
        label: this.get('l10n').t('CAD'),
        sign: '$'
      }, {
        value: 'AUD',
        label: this.get('l10n').t('AUD'),
        sign: '$'
      }, {
        value: 'NZD',
        label: this.get('l10n').t('NZD'),
        sign: '$'
      }, {
        value: 'EUR',
        label: this.get('l10n').t('EUR'),
        sign: '€'
      }, {
        value: 'GBP',
        label: this.get('l10n').t('GBP'),
        sign: '£'
      }, {
        value: 'JPY',
        label: this.get('l10n').t('JPY'),
        sign: '¥'
      }, {
        value: 'CHY',
        label: this.get('l10n').t('CHY'),
        sign: '¥'
      }, {
        value: 'KRW',
        label: this.get('l10n').t('KRW'),
        sign: '₩'
      }];
    }),

    /**
     * All available user roles, e.g. ADMIN.
     *
     * @property roles
     * @type Array
     * @final
     */
    roles: Ember.computed('l10n.locale', function () {
      return [{
        value: 'ADMIN',
        label: this.get('l10n').t('Admin')
      }, {
        value: 'MANAGER',
        label: this.get('l10n').t('Manager')
      }, {
        value: 'COLLABORATOR',
        label: this.get('l10n').t('Collaborator')
      }, {
        value: 'VIEWER',
        label: this.get('l10n').t('Viewer')
      }];
    }),

    /**
     * All available listing availabilities, e.g. SPOT.
     *
     * @property listingAvailabilities
     * @type Array
     * @final
     */
    listingAvailabilities: Ember.computed('l10n.locale', function () {
      return [{
        value: 'SPOT',
        label: this.get('l10n').t('Spot')
      }, {
        value: 'AFLOAT',
        label: this.get('l10n').t('Afloat')
      }, {
        value: 'ORIGIN',
        label: this.get('l10n').t('Origin')
      }];
    }),

    /**
     * All available base units, e.g. KG.
     *
     * @property baseUnits
     * @type Array
     * @final
     */
    baseUnits: Ember.computed('l10n.locale', function () {
      return [{
        value: 'LBS',
        label: this.get('l10n').t('lb')
      }, {
        value: 'KG',
        label: this.get('l10n').t('kg')
      }];
    }),

    /**
     * All available package units, e.g. BOX24KG.
     *
     * @property packageUnits
     * @type Array
     * @final
     */
    packageUnits: Ember.computed('l10n.locale', function () {
      return [{
        value: 'BARREL15KG',
        label: this.get('l10n').t('15kg barrel')
      }, {
        value: 'BAG1KG',
        label: this.get('l10n').t('1kg bag')
      }, {
        value: 'BAG2KG',
        label: this.get('l10n').t('2kg bag')
      }, {
        value: 'BAG4KG',
        label: this.get('l10n').t('4kg bag')
      }, {
        value: 'BAG8KG',
        label: this.get('l10n').t('8kg bag')
      }, {
        value: 'BAG10KG',
        label: this.get('l10n').t('10kg bag')
      }, {
        value: 'BAG15KG',
        label: this.get('l10n').t('15kg bag')
      }, {
        value: 'BAG16KG',
        label: this.get('l10n').t('16kg bag')
      }, {
        value: 'BAG17KG',
        label: this.get('l10n').t('17kg bag')
      }, {
        value: 'BOX12_1KG',
        label: this.get('l10n').t('12.1kg box')
      }, {
        value: 'BOX16KG',
        label: this.get('l10n').t('16kg box')
      }, {
        value: 'BOX20KG',
        label: this.get('l10n').t('20kg box')
      }, {
        value: 'BOX24KG',
        label: this.get('l10n').t('24kg box')
      }, {
        value: 'BOX24_2KG',
        label: this.get('l10n').t('24.2kg box')
      }, {
        value: 'BOX25KG',
        label: this.get('l10n').t('25kg box')
      }, {
        value: 'BOX30KG',
        label: this.get('l10n').t('30kg box')
      }, {
        value: 'BAG30KG',
        label: this.get('l10n').t('30kg bag')
      }, {
        value: 'BOX35KG',
        label: this.get('l10n').t('35kg box')
      }, {
        value: 'BAG20KG',
        label: this.get('l10n').t('20kg bag')
      }, {
        value: 'BAG25KG',
        label: this.get('l10n').t('25kg bag')
      }, {
        value: 'BAG32KG',
        label: this.get('l10n').t('32kg bag')
      }, {
        value: 'BAG35KG',
        label: this.get('l10n').t('35kg bag')
      }, {
        value: 'BAG45KG',
        label: this.get('l10n').t('45kg bag')
      }, {
        value: 'BAG46KG',
        label: this.get('l10n').t('46kg bag')
      }, {
        value: 'BAG50KG',
        label: this.get('l10n').t('50kg bag')
      }, {
        value: 'BAG60KG',
        label: this.get('l10n').t('60kg bag')
      }, {
        value: 'BAG62_5KG',
        label: this.get('l10n').t('62.5kg bag')
      }, {
        value: 'BAG64KG',
        label: this.get('l10n').t('64kg bag')
      }, {
        value: 'BAG69KG',
        label: this.get('l10n').t('69kg bag')
      }, {
        value: 'BAG70KG',
        label: this.get('l10n').t('70kg bag')
      }, {
        value: 'BAG50LBS',
        label: this.get('l10n').t('50lb bag')
      }, {
        value: 'BOX50LBS',
        label: this.get('l10n').t('50lb box')
      }, {
        value: 'BOX65LBS',
        label: this.get('l10n').t('65lb box')
      }, {
        value: 'BAG100LBS',
        label: this.get('l10n').t('100lb bag')
      }, {
        value: 'LBS',
        label: this.get('l10n').t('lb')
      }, {
        value: 'KG',
        label: this.get('l10n').t('kg')
      }];
    }),

    /**
     * All available packagings, e.g. JUTE.
     *
     * @property packagings
     * @type Array
     * @final
     */
    packagings: Ember.computed('l10n.locale', function () {
      return [{
        value: 'JUTE',
        label: this.get('l10n').t('Jute/Burlap')
      }, {
        value: 'GRAIN_PRO',
        label: this.get('l10n').t('Grainpro')
      }, {
        value: 'VACUUM',
        label: this.get('l10n').t('Vacuum packed')
      }, {
        value: 'COTTON',
        label: this.get('l10n').t('Cotton')
      }, {
        value: 'OTHER',
        label: this.get('l10n').t('Other')
      }];
    }),

    /**
     * All available processings, e.g. NATURAL.
     *
     * @property processings
     * @type Array
     * @final
     */
    processings: Ember.computed('l10n.locale', function () {
      return [{
        value: 'NATURAL',
        label: this.get('l10n').t('Natural')
      }, {
        value: 'FULLY_WASHED',
        label: this.get('l10n').t('Washed')
      }, {
        value: 'SEMI_WASHED',
        label: this.get('l10n').t('Semi washed')
      }, {
        value: 'PULPED_NATURAL',
        label: this.get('l10n').t('Pulped natural')
      }, {
        value: 'HONEY',
        label: this.get('l10n').t('Honey')
      }, {
        value: 'YEAST_FERMENTED',
        label: this.get('l10n').t('Yeast fermented')
      }, {
        value: 'MISC',
        label: this.get('l10n').t('Miscellaneous')
      }, {
        value: 'OTHER',
        label: this.get('l10n').t('Other')
      }];
    }),

    /**
     * All available shipping terms, e.g. FOB.
     *
     * @property shippingTerms
     * @type Array
     * @final
     */
    shippingTerms: Ember.computed('l10n.locale', function () {
      return [{
        value: 'FOB',
        label: this.get('l10n').t('FOB')
      }, {
        value: 'NVOCC',
        label: this.get('l10n').t('NVOCC')
      }, {
        value: 'FCL',
        label: this.get('l10n').t('FCL')
      }, {
        value: 'LCL',
        label: this.get('l10n').t('LCL')
      }, {
        value: 'EXW',
        label: this.get('l10n').t('EXW')
      }, {
        value: 'FOT',
        label: this.get('l10n').t('FOT')
      }, {
        value: 'CFR',
        label: this.get('l10n').t('CFR')
      }, {
        value: 'FCA',
        label: this.get('l10n').t('FCA')
      }, {
        value: 'FAS',
        label: this.get('l10n').t('FAS')
      }, {
        value: 'CIF',
        label: this.get('l10n').t('CIF')
      }, {
        value: 'CPT',
        label: this.get('l10n').t('CPT')
      }, {
        value: 'CIP',
        label: this.get('l10n').t('CIP')
      }, {
        value: 'DAF',
        label: this.get('l10n').t('DAF')
      }, {
        value: 'DES',
        label: this.get('l10n').t('DES')
      }, {
        value: 'DDP',
        label: this.get('l10n').t('DDP')
      }, {
        value: 'DDU',
        label: this.get('l10n').t('DDU')
      }, {
        value: 'DEQ',
        label: this.get('l10n').t('DEQ')
      }];
    }),

    /**
     * All available date formats for moment.js.
     *
     * @property dateFormats
     * @type Array
     * @final
     */
    dateFormats: Ember.computed(function () {
      return [{
        value: 'MMM D, YYYY',
        label: (0, _moment.default)().format('MMM Do, YYYY')
      }, {
        value: 'D. MMM, YYYY',
        label: (0, _moment.default)().format('D. MMM, YYYY')
      }, {
        value: 'YYYY/MM/DD',
        label: (0, _moment.default)().format('YYYY/MM/DD')
      }, {
        value: 'DD.MM.YYYY',
        label: (0, _moment.default)().format('DD.MM.YYYY')
      }];
    }),

    /**
     * All available time formats for moment.js.
     *
     * @property timeFormats
     * @type Array
     * @final
     */
    timeFormats: Ember.computed(function () {
      return [{
        value: 'HH:mm',
        label: (0, _moment.default)().format('HH:mm')
      }, {
        value: 'hh:mm a',
        label: (0, _moment.default)().format('hh:mm a')
      }];
    }),

    /**
     * All available conversation types.
     *
     * @property conversationTypes
     * @type Array
     * @final
     */
    conversationTypes: Ember.computed('l10n.locale', function () {
      return [{
        value: 'GENERAL',
        label: this.get('l10n').t('General')
      }, {
        value: 'ORDER',
        label: this.get('l10n').t('Order')
      }, {
        value: 'PRIVATE_OFFERING',
        label: this.get('l10n').t('Private offering')
      }, {
        value: 'SOCIAL',
        label: this.get('l10n').t('People')
      }, {
        value: 'AUCTION',
        label: this.get('l10n').t('Auction')
      }, {
        value: 'LISTING',
        label: this.get('l10n').t('Listing')
      }];
    }),

    /**
     * All available listing states.
     *
     * @property listingStates
     * @type Array
     * @final
     */
    listingStates: Ember.computed('l10n.locale', function () {
      return [{
        value: 'ACTIVE',
        label: this.get('l10n').t('Active')
      }, {
        value: 'INACTIVE',
        label: this.get('l10n').t('Inactive')
      }, {
        value: 'DRAFT',
        label: this.get('l10n').t('Draft')
      }, {
        value: 'ARCHIVED',
        label: this.get('l10n').t('Archived')
      }, {
        value: 'SOLD_OUT',
        label: this.get('l10n').t('Sold out')
      }, {
        value: 'EXPIRED',
        label: this.get('l10n').t('Expired')
      }];
    }),

    /**
     * All available auction states.
     *
     * @property auctionStates
     * @type Array
     * @final
     */
    auctionStates: Ember.computed('l10n.locale', function () {
      return [{
        value: 'ACTIVE',
        label: this.get('l10n').t('Active')
      }, {
        value: 'INACTIVE',
        label: this.get('l10n').t('Inactive')
      }, {
        value: 'DRAFT',
        label: this.get('l10n').t('Draft')
      }, {
        value: 'CLOSED',
        label: this.get('l10n').t('Past')
      }, {
        value: 'RUNNING',
        label: this.get('l10n').t('Running')
      }, {
        value: 'ON_HOLD',
        label: this.get('l10n').t('Paused')
      }];
    }),

    /**
     * All available shop states.
     *
     * @property shopStates
     * @type Array
     * @final
     */
    shopStates: Ember.computed('l10n.locale', function () {
      return [{
        value: 'ACTIVE',
        label: this.get('l10n').t('Active')
      }, {
        value: 'INACTIVE',
        label: this.get('l10n').t('Inactive')
      }, {
        value: 'DRAFT',
        label: this.get('l10n').t('Draft')
      }];
    }),

    /**
     * All available auction registration states.
     *
     * @property auctionRegistrationStates
     * @type Array
     * @final
     */
    auctionRegistrationStates: Ember.computed('l10n.locale', function () {
      return [{
        value: 'PENDING',
        label: this.get('l10n').t('Pending')
      }, {
        value: 'ACCEPTED',
        label: this.get('l10n').t('Accepted')
      }, {
        value: 'REJECTED',
        label: this.get('l10n').t('Rejected')
      }];
    }),

    /**
     * All available order states.
     *
     * @property orderStates
     * @type Array
     * @final
     */
    orderStates: Ember.computed('l10n.locale', function () {
      return [{
        value: 'CART',
        label: this.get('l10n').t('In cart')
      }, {
        value: 'REQUEST',
        label: this.get('l10n').t('Request')
      }, {
        value: 'OFFER',
        label: this.get('l10n').t('Offer')
      }, {
        value: 'ACCEPTED',
        label: this.get('l10n').t('Accepted')
      }, {
        value: 'REQUEST_REJECTED',
        label: this.get('l10n').t('Request rejected')
      }, {
        value: 'OFFER_CANCELED',
        label: this.get('l10n').t('Offer canceled')
      }, {
        value: 'OFFER_REJECTED',
        label: this.get('l10n').t('Offer rejected')
      }];
    }),

    /**
     * All available show types states.
     *
     * @property showTypeStates
     * @type Array
     * @final
     */
    showTypeStates: Ember.computed('l10n.locale', function () {
      return [{
        value: 'ALL',
        label: this.get('l10n').t('All')
      }, {
        value: 'SAMPLE',
        label: this.get('l10n').t('Sample')
      }, {
        value: 'PURCHASE',
        label: this.get('l10n').t('Purchase')
      }];
    }),

    /**
     * All available transaction states.
     *
     * @property transactionStates
     * @type Array
     * @final
     */
    transactionStates: Ember.computed('l10n.locale', function () {
      return [{
        value: 'AUTHORIZATION',
        label: this.get('l10n').t('Payment authorized')
      }, {
        value: 'PAYMENT',
        label: this.get('l10n').t('Paid')
      }, {
        value: 'NO_PAYMENT',
        label: this.get('l10n').t('No payment')
      }, {
        value: 'REFUND',
        label: this.get('l10n').t('Payment refunded')
      }, {
        value: 'DISPUTE',
        label: this.get('l10n').t('Payment disputed')
      }];
    }),

    /**
     * All available filter names.
     *
     * @property filterNames
     * @type Array
     * @final
     */
    filterNames: Ember.computed('l10n.locale', function () {
      return [{
        value: 'warehouses',
        label: this.get('l10n').t('Warehouses')
      }, {
        value: 'shipsTo',
        label: this.get('l10n').t('Shipping to')
      }, {
        value: 'descriptors',
        label: this.get('l10n').t('Flavors')
      }, {
        value: 'varieties',
        label: this.get('l10n').t('Varieties')
      }, {
        value: 'processings',
        label: this.get('l10n').t('Processings')
      }, {
        value: 'availabilities',
        label: this.get('l10n').t('Listing status')
      }, {
        value: 'origins',
        label: this.get('l10n').t('Origins')
      }, {
        value: 'certifications',
        label: this.get('l10n').t('Certifications')
      }, {
        value: 'format',
        label: this.get('l10n').t('Format')
      }, {
        value: 'grades',
        label: this.get('l10n').t('Grades')
      }, {
        value: 'scoreRanges',
        label: this.get('l10n').t('Cupping score')
      }, {
        value: 'amount',
        label: this.get('l10n').t('Amount')
      }, {
        value: 'other',
        label: this.get('l10n').t('Other')
      }, {
        value: 'decaf',
        label: this.get('l10n').t('Decaf')
      }, {
        value: 'roastedSampleAvailable',
        label: this.get('l10n').t('Has roasted samples')
      }, {
        value: 'expressCoffee',
        label: this.get('l10n').t('Express Coffee')
      }, {
        value: 'specialOffer',
        label: this.get('l10n').t('Special offer')
      }];
    }),

    /**
     * All available filter names.
     *
     * @property filterNames
     * @type Array
     * @final
     */
    filterFormats: Ember.computed('l10n.locale', function () {
      return [{
        value: 'ALL',
        label: this.get('l10n').t('All')
      }, {
        value: 'TRADER_OFFERING',
        label: this.get('l10n').t('Trader offering')
      }, {
        value: 'AUCTION',
        label: this.get('l10n').t('Auction')
      }];
    }),

    /**
     * All available countries.
     *
     * @property countries
     * @type Array
     * @final
     */
    countries: Ember.computed('l10n.locale', function () {
      return [{
        value: 'AF',
        label: this.get('l10n').t('Afghanistan')
      }, {
        value: 'AX',
        label: this.get('l10n').t('Åland Islands')
      }, {
        value: 'AL',
        label: this.get('l10n').t('Albania')
      }, {
        value: 'DZ',
        label: this.get('l10n').t('Algeria')
      }, {
        value: 'AS',
        label: this.get('l10n').t('American Samoa')
      }, {
        value: 'AD',
        label: this.get('l10n').t('Andorra')
      }, {
        value: 'AO',
        label: this.get('l10n').t('Angola')
      }, {
        value: 'AI',
        label: this.get('l10n').t('Anguilla')
      }, {
        value: 'AQ',
        label: this.get('l10n').t('Antarctica')
      }, {
        value: 'AG',
        label: this.get('l10n').t('Antigua And Barbuda')
      }, {
        value: 'AR',
        label: this.get('l10n').t('Argentina')
      }, {
        value: 'AM',
        label: this.get('l10n').t('Armenia')
      }, {
        value: 'AW',
        label: this.get('l10n').t('Aruba')
      }, {
        value: 'AU',
        label: this.get('l10n').t('Australia')
      }, {
        value: 'AT',
        label: this.get('l10n').t('Austria')
      }, {
        value: 'AZ',
        label: this.get('l10n').t('Azerbaijan')
      }, {
        value: 'BS',
        label: this.get('l10n').t('Bahamas')
      }, {
        value: 'BH',
        label: this.get('l10n').t('Bahrain')
      }, {
        value: 'BD',
        label: this.get('l10n').t('Bangladesh')
      }, {
        value: 'BB',
        label: this.get('l10n').t('Barbados')
      }, {
        value: 'BY',
        label: this.get('l10n').t('Belarus')
      }, {
        value: 'BE',
        label: this.get('l10n').t('Belgium')
      }, {
        value: 'BZ',
        label: this.get('l10n').t('Belize')
      }, {
        value: 'BJ',
        label: this.get('l10n').t('Benin')
      }, {
        value: 'BM',
        label: this.get('l10n').t('Bermuda')
      }, {
        value: 'BT',
        label: this.get('l10n').t('Bhutan')
      }, {
        value: 'BO',
        label: this.get('l10n').t('Bolivia, Plurinational State Of')
      }, {
        value: 'BQ',
        label: this.get('l10n').t('Bonaire, Saint Eustatius And Saba')
      }, {
        value: 'BA',
        label: this.get('l10n').t('Bosnia & Herzegovina')
      }, {
        value: 'BW',
        label: this.get('l10n').t('Botswana')
      }, {
        value: 'BV',
        label: this.get('l10n').t('Bouvet Island')
      }, {
        value: 'BR',
        label: this.get('l10n').t('Brazil')
      }, {
        value: 'IO',
        label: this.get('l10n').t('British Indian Ocean Territory')
      }, {
        value: 'BN',
        label: this.get('l10n').t('Brunei Darussalam')
      }, {
        value: 'BG',
        label: this.get('l10n').t('Bulgaria')
      }, {
        value: 'BF',
        label: this.get('l10n').t('Burkina Faso')
      }, {
        value: 'BI',
        label: this.get('l10n').t('Burundi')
      }, {
        value: 'CV',
        label: this.get('l10n').t('Cabo Verde')
      }, {
        value: 'KH',
        label: this.get('l10n').t('Cambodia')
      }, {
        value: 'CM',
        label: this.get('l10n').t('Cameroon')
      }, {
        value: 'CA',
        label: this.get('l10n').t('Canada')
      }, {
        value: 'KY',
        label: this.get('l10n').t('Cayman Islands')
      }, {
        value: 'CF',
        label: this.get('l10n').t('Central African Republic')
      }, {
        value: 'TD',
        label: this.get('l10n').t('Chad')
      }, {
        value: 'CL',
        label: this.get('l10n').t('Chile')
      }, {
        value: 'CN',
        label: this.get('l10n').t('China')
      }, {
        value: 'CX',
        label: this.get('l10n').t('Christmas Island')
      }, {
        value: 'CC',
        label: this.get('l10n').t('Cocos (Keeling) Islands')
      }, {
        value: 'CO',
        label: this.get('l10n').t('Colombia')
      }, {
        value: 'KM',
        label: this.get('l10n').t('Comoros')
      }, {
        value: 'CK',
        label: this.get('l10n').t('Cook Islands')
      }, {
        value: 'CR',
        label: this.get('l10n').t('Costa Rica')
      }, {
        value: 'CI',
        label: this.get('l10n').t("Cote d'Ivoire")
      }, {
        value: 'HR',
        label: this.get('l10n').t('Croatia')
      }, {
        value: 'CU',
        label: this.get('l10n').t('Cuba')
      }, {
        value: 'CW',
        label: this.get('l10n').t('Curacao')
      }, {
        value: 'CY',
        label: this.get('l10n').t('Cyprus')
      }, {
        value: 'CZ',
        label: this.get('l10n').t('Czech Republic')
      }, {
        value: 'CD',
        label: this.get('l10n').t('Congo, Democratic Republic of (CD)')
      }, {
        value: 'DK',
        label: this.get('l10n').t('Denmark')
      }, {
        value: 'DJ',
        label: this.get('l10n').t('Djibouti')
      }, {
        value: 'DM',
        label: this.get('l10n').t('Dominica')
      }, {
        value: 'DO',
        label: this.get('l10n').t('Dominican Republic')
      }, {
        value: 'TL',
        label: this.get('l10n').t('East Timor')
      }, {
        value: 'EC',
        label: this.get('l10n').t('Ecuador')
      }, {
        value: 'EG',
        label: this.get('l10n').t('Egypt')
      }, {
        value: 'SV',
        label: this.get('l10n').t('El Salvador')
      }, {
        value: 'GQ',
        label: this.get('l10n').t('Equatorial Guinea')
      }, {
        value: 'ER',
        label: this.get('l10n').t('Eritrea')
      }, {
        value: 'EE',
        label: this.get('l10n').t('Estonia')
      }, {
        value: 'ET',
        label: this.get('l10n').t('Ethiopia')
      }, {
        value: 'FK',
        label: this.get('l10n').t('Falkland Islands')
      }, {
        value: 'FO',
        label: this.get('l10n').t('Faroe Islands')
      }, {
        value: 'FJ',
        label: this.get('l10n').t('Fiji')
      }, {
        value: 'FI',
        label: this.get('l10n').t('Finland')
      }, {
        value: 'FR',
        label: this.get('l10n').t('France')
      }, {
        value: 'GF',
        label: this.get('l10n').t('French Guiana')
      }, {
        value: 'PF',
        label: this.get('l10n').t('French Polynesia')
      }, {
        value: 'TF',
        label: this.get('l10n').t('French Southern Territories')
      }, {
        value: 'GA',
        label: this.get('l10n').t('Gabon')
      }, {
        value: 'GM',
        label: this.get('l10n').t('Gambia')
      }, {
        value: 'GE',
        label: this.get('l10n').t('Georgia')
      }, {
        value: 'DE',
        label: this.get('l10n').t('Germany')
      }, {
        value: 'GH',
        label: this.get('l10n').t('Ghana')
      }, {
        value: 'GI',
        label: this.get('l10n').t('Gibraltar')
      }, {
        value: 'GR',
        label: this.get('l10n').t('Greece')
      }, {
        value: 'GL',
        label: this.get('l10n').t('Greenland')
      }, {
        value: 'GD',
        label: this.get('l10n').t('Grenada')
      }, {
        value: 'GP',
        label: this.get('l10n').t('Guadeloupe')
      }, {
        value: 'GU',
        label: this.get('l10n').t('Guam')
      }, {
        value: 'GT',
        label: this.get('l10n').t('Guatemala')
      }, {
        value: 'GG',
        label: this.get('l10n').t('Guernsey')
      }, {
        value: 'GN',
        label: this.get('l10n').t('Guinea')
      }, {
        value: 'GW',
        label: this.get('l10n').t('Guinea-bissau')
      }, {
        value: 'GY',
        label: this.get('l10n').t('Guyana')
      }, {
        value: 'HT',
        label: this.get('l10n').t('Haiti')
      }, {
        value: 'HM',
        label: this.get('l10n').t('Heard Island And McDonald Islands')
      }, {
        value: 'HN',
        label: this.get('l10n').t('Honduras')
      }, {
        value: 'HK',
        label: this.get('l10n').t('Hong Kong')
      }, {
        value: 'HU',
        label: this.get('l10n').t('Hungary')
      }, {
        value: 'IS',
        label: this.get('l10n').t('Iceland')
      }, {
        value: 'IN',
        label: this.get('l10n').t('India')
      }, {
        value: 'ID',
        label: this.get('l10n').t('Indonesia')
      }, {
        value: 'IR',
        label: this.get('l10n').t('Iran, Islamic Republic Of')
      }, {
        value: 'IQ',
        label: this.get('l10n').t('Iraq')
      }, {
        value: 'IE',
        label: this.get('l10n').t('Ireland')
      }, {
        value: 'IM',
        label: this.get('l10n').t('Isle Of Man')
      }, {
        value: 'IL',
        label: this.get('l10n').t('Israel')
      }, {
        value: 'IT',
        label: this.get('l10n').t('Italy')
      }, {
        value: 'JM',
        label: this.get('l10n').t('Jamaica')
      }, {
        value: 'JP',
        label: this.get('l10n').t('Japan')
      }, {
        value: 'JE',
        label: this.get('l10n').t('Jersey')
      }, {
        value: 'JO',
        label: this.get('l10n').t('Jordan')
      }, {
        value: 'KZ',
        label: this.get('l10n').t('Kazakhstan')
      }, {
        value: 'KE',
        label: this.get('l10n').t('Kenya')
      }, {
        value: 'KI',
        label: this.get('l10n').t('Kiribati')
      }, {
        value: 'KP',
        label: this.get('l10n').t("Korea, Democratic People's Republic Of")
      }, {
        value: 'KR',
        label: this.get('l10n').t('Korea, Republic Of')
      }, {
        value: 'KW',
        label: this.get('l10n').t('Kuwait')
      }, {
        value: 'KG',
        label: this.get('l10n').t('Kyrgyzstan')
      }, {
        value: 'LA',
        label: this.get('l10n').t("Lao People's Democratic Republic")
      }, {
        value: 'LV',
        label: this.get('l10n').t('Latvia')
      }, {
        value: 'LB',
        label: this.get('l10n').t('Lebanon')
      }, {
        value: 'LS',
        label: this.get('l10n').t('Lesotho')
      }, {
        value: 'LR',
        label: this.get('l10n').t('Liberia')
      }, {
        value: 'LY',
        label: this.get('l10n').t('Libya')
      }, {
        value: 'LI',
        label: this.get('l10n').t('Liechtenstein')
      }, {
        value: 'LT',
        label: this.get('l10n').t('Lithuania')
      }, {
        value: 'LU',
        label: this.get('l10n').t('Luxembourg')
      }, {
        value: 'MO',
        label: this.get('l10n').t('Macao')
      }, {
        value: 'MK',
        label: this.get('l10n').t('Macedonia, The Former Yugoslav Republic Of')
      }, {
        value: 'MG',
        label: this.get('l10n').t('Madagascar')
      }, {
        value: 'MW',
        label: this.get('l10n').t('Malawi')
      }, {
        value: 'MY',
        label: this.get('l10n').t('Malaysia')
      }, {
        value: 'MV',
        label: this.get('l10n').t('Maldives')
      }, {
        value: 'ML',
        label: this.get('l10n').t('Mali')
      }, {
        value: 'MT',
        label: this.get('l10n').t('Malta')
      }, {
        value: 'MH',
        label: this.get('l10n').t('Marshall Islands')
      }, {
        value: 'MQ',
        label: this.get('l10n').t('Martinique')
      }, {
        value: 'MR',
        label: this.get('l10n').t('Mauritania')
      }, {
        value: 'MU',
        label: this.get('l10n').t('Mauritius')
      }, {
        value: 'YT',
        label: this.get('l10n').t('Mayotte')
      }, {
        value: 'MX',
        label: this.get('l10n').t('Mexico')
      }, {
        value: 'FM',
        label: this.get('l10n').t('Micronesia, Federated States Of')
      }, {
        value: 'MD',
        label: this.get('l10n').t('Moldova')
      }, {
        value: 'MC',
        label: this.get('l10n').t('Monaco')
      }, {
        value: 'MN',
        label: this.get('l10n').t('Mongolia')
      }, {
        value: 'ME',
        label: this.get('l10n').t('Montenegro')
      }, {
        value: 'MS',
        label: this.get('l10n').t('Montserrat')
      }, {
        value: 'MA',
        label: this.get('l10n').t('Morocco')
      }, {
        value: 'MZ',
        label: this.get('l10n').t('Mozambique')
      }, {
        value: 'MM',
        label: this.get('l10n').t('Myanmar')
      }, {
        value: 'NA',
        label: this.get('l10n').t('Namibia')
      }, {
        value: 'NR',
        label: this.get('l10n').t('Nauru')
      }, {
        value: 'NP',
        label: this.get('l10n').t('Nepal')
      }, {
        value: 'NL',
        label: this.get('l10n').t('Netherlands')
      }, {
        value: 'NC',
        label: this.get('l10n').t('New Caledonia')
      }, {
        value: 'NZ',
        label: this.get('l10n').t('New Zealand')
      }, {
        value: 'NI',
        label: this.get('l10n').t('Nicaragua')
      }, {
        value: 'NE',
        label: this.get('l10n').t('Niger')
      }, {
        value: 'NG',
        label: this.get('l10n').t('Nigeria')
      }, {
        value: 'NU',
        label: this.get('l10n').t('Niue')
      }, {
        value: 'NF',
        label: this.get('l10n').t('Norfolk Island')
      }, {
        value: 'MP',
        label: this.get('l10n').t('Northern Mariana Islands')
      }, {
        value: 'NO',
        label: this.get('l10n').t('Norway')
      }, {
        value: 'OM',
        label: this.get('l10n').t('Oman')
      }, {
        value: 'PK',
        label: this.get('l10n').t('Pakistan')
      }, {
        value: 'PW',
        label: this.get('l10n').t('Palau')
      }, {
        value: 'PS',
        label: this.get('l10n').t('Palestinian Territory, Occupied')
      }, {
        value: 'PA',
        label: this.get('l10n').t('Panama')
      }, {
        value: 'PG',
        label: this.get('l10n').t('Papua New Guinea')
      }, {
        value: 'PY',
        label: this.get('l10n').t('Paraguay')
      }, {
        value: 'PE',
        label: this.get('l10n').t('Peru')
      }, {
        value: 'PH',
        label: this.get('l10n').t('Philippines')
      }, {
        value: 'PN',
        label: this.get('l10n').t('Pitcairn')
      }, {
        value: 'PL',
        label: this.get('l10n').t('Poland')
      }, {
        value: 'PT',
        label: this.get('l10n').t('Portugal')
      }, {
        value: 'PR',
        label: this.get('l10n').t('Puerto Rico')
      }, {
        value: 'QA',
        label: this.get('l10n').t('Qatar')
      }, {
        value: 'CG',
        label: this.get('l10n').t('Congo, Republic of (CG)')
      }, {
        value: 'RE',
        label: this.get('l10n').t('Reunion')
      }, {
        value: 'RO',
        label: this.get('l10n').t('Romania')
      }, {
        value: 'RU',
        label: this.get('l10n').t('Russian Federation')
      }, {
        value: 'RW',
        label: this.get('l10n').t('Rwanda')
      }, {
        value: 'BL',
        label: this.get('l10n').t('Saint Barthélemy')
      }, {
        value: 'SH',
        label: this.get('l10n').t('Saint Helena, Ascension And Tristan Da Cunha')
      }, {
        value: 'KN',
        label: this.get('l10n').t('Saint Kitts And Nevis')
      }, {
        value: 'LC',
        label: this.get('l10n').t('Saint Lucia')
      }, {
        value: 'MF',
        label: this.get('l10n').t('Saint Martin')
      }, {
        value: 'PM',
        label: this.get('l10n').t('Saint Pierre And Miquelon')
      }, {
        value: 'VC',
        label: this.get('l10n').t('Saint Vincent And The Grenadines')
      }, {
        value: 'WS',
        label: this.get('l10n').t('Samoa')
      }, {
        value: 'SM',
        label: this.get('l10n').t('San Marino')
      }, {
        value: 'ST',
        label: this.get('l10n').t('São Tomé and Príncipe')
      }, {
        value: 'SA',
        label: this.get('l10n').t('Saudi Arabia')
      }, {
        value: 'SN',
        label: this.get('l10n').t('Senegal')
      }, {
        value: 'RS',
        label: this.get('l10n').t('Serbia')
      }, {
        value: 'SC',
        label: this.get('l10n').t('Seychelles')
      }, {
        value: 'SL',
        label: this.get('l10n').t('Sierra Leone')
      }, {
        value: 'SG',
        label: this.get('l10n').t('Singapore')
      }, {
        value: 'SX',
        label: this.get('l10n').t('Sint Maarten')
      }, {
        value: 'SK',
        label: this.get('l10n').t('Slovakia')
      }, {
        value: 'SI',
        label: this.get('l10n').t('Slovenia')
      }, {
        value: 'SB',
        label: this.get('l10n').t('Solomon Islands')
      }, {
        value: 'SO',
        label: this.get('l10n').t('Somalia')
      }, {
        value: 'ZA',
        label: this.get('l10n').t('South Africa')
      }, {
        value: 'GS',
        label: this.get('l10n').t('South Georgia And The South Sandwich Islands')
      }, {
        value: 'SS',
        label: this.get('l10n').t('South Sudan')
      }, {
        value: 'ES',
        label: this.get('l10n').t('Spain')
      }, {
        value: 'LK',
        label: this.get('l10n').t('Sri Lanka')
      }, {
        value: 'SD',
        label: this.get('l10n').t('Sudan')
      }, {
        value: 'SR',
        label: this.get('l10n').t('Suriname')
      }, {
        value: 'SJ',
        label: this.get('l10n').t('Svalbard And Jan Mayen')
      }, {
        value: 'SZ',
        label: this.get('l10n').t('Swaziland')
      }, {
        value: 'SE',
        label: this.get('l10n').t('Sweden')
      }, {
        value: 'CH',
        label: this.get('l10n').t('Switzerland')
      }, {
        value: 'SY',
        label: this.get('l10n').t('Syrian Arab Republic')
      }, {
        value: 'TW',
        label: this.get('l10n').t('Taiwan, Province Of China')
      }, {
        value: 'TJ',
        label: this.get('l10n').t('Tajikistan')
      }, {
        value: 'TZ',
        label: this.get('l10n').t('Tanzania, United Republic Of')
      }, {
        value: 'TH',
        label: this.get('l10n').t('Thailand')
      }, {
        value: 'TG',
        label: this.get('l10n').t('Togo')
      }, {
        value: 'TK',
        label: this.get('l10n').t('Tokelau')
      }, {
        value: 'TO',
        label: this.get('l10n').t('Tonga')
      }, {
        value: 'TT',
        label: this.get('l10n').t('Trinidad And Tobago')
      }, {
        value: 'TN',
        label: this.get('l10n').t('Tunisia')
      }, {
        value: 'TR',
        label: this.get('l10n').t('Turkey')
      }, {
        value: 'TM',
        label: this.get('l10n').t('Turkmenistan')
      }, {
        value: 'TC',
        label: this.get('l10n').t('Turks And Caicos Islands')
      }, {
        value: 'TV',
        label: this.get('l10n').t('Tuvalu')
      }, {
        value: 'UG',
        label: this.get('l10n').t('Uganda')
      }, {
        value: 'UA',
        label: this.get('l10n').t('Ukraine')
      }, {
        value: 'AE',
        label: this.get('l10n').t('United Arab Emirates')
      }, {
        value: 'GB',
        label: this.get('l10n').t('United Kingdom')
      }, {
        value: 'US',
        label: this.get('l10n').t('United States')
      }, {
        value: 'UM',
        label: this.get('l10n').t('United States Minor Outlying Islands')
      }, {
        value: 'UY',
        label: this.get('l10n').t('Uruguay')
      }, {
        value: 'UZ',
        label: this.get('l10n').t('Uzbekistan')
      }, {
        value: 'VU',
        label: this.get('l10n').t('Vanuatu')
      }, {
        value: 'VA',
        label: this.get('l10n').t('Vatican City State')
      }, {
        value: 'VE',
        label: this.get('l10n').t('Venezuela, Bolivarian Republic Of')
      }, {
        value: 'VN',
        label: this.get('l10n').t('Viet Nam')
      }, {
        value: 'VG',
        label: this.get('l10n').t('Virgin Islands (British)')
      }, {
        value: 'VI',
        label: this.get('l10n').t('Virgin Islands (US)')
      }, {
        value: 'WF',
        label: this.get('l10n').t('Wallis And Futuna')
      }, {
        value: 'EH',
        label: this.get('l10n').t('Western Sahara')
      }, {
        value: 'YE',
        label: this.get('l10n').t('Yemen')
      }, {
        value: 'ZM',
        label: this.get('l10n').t('Zambia')
      }, {
        value: 'ZW',
        label: this.get('l10n').t('Zimbabwe')
      }];
    }),

    /**
     * All constant translations as hash map for more
     * efficient lookup within components/templates.
     * Each hash entry is an array of objects with
     * translated `label` and `context` property.
     * In case there are multiple entries for one
     * translation key (message id), you could use
     * context for retrieving the correct object.
     *
     * @property constants
     * @type Array
     * @final
     */
    constants: Ember.computed('auctionRegistrationStates', 'auctionStates', 'baseUnits', 'conversationTypes', 'countries', 'currencies', 'filterFormats', 'filterNames', 'importerRequirements', 'l10n.locale', 'listingAvailabilities', 'listingStates', 'orderStates', 'packageUnits', 'packagings', 'processings', 'producerTypes', 'roles', 'shippingTerms', 'shopStates', 'transactionStates', function () {
      var constants = this.getProperties(['importerRequirements', 'producerTypes', 'currencies', 'roles', 'listingAvailabilities', 'baseUnits', 'packageUnits', 'packagings', 'processings', 'shippingTerms', 'conversationTypes', 'listingStates', 'auctionStates', 'shopStates', 'auctionRegistrationStates', 'orderStates', 'transactionStates', 'filterNames', 'filterFormats', 'countries']);
      var translations = {};

      for (var key in constants) {
        var item = constants[key];

        if (!Ember.isArray(item)) {
          continue;
        }

        for (var i = 0; i < item.length; i++) {
          var entry = item[i];

          if (!translations[entry.value]) {
            translations[entry.value] = [];
          }

          translations[entry.value].push({
            label: entry.label,
            context: key
          });
        }
      }

      return translations;
    })
  });

  _exports.default = _default;
});