define("@vollersgroup/hub-frontend/components/region-chooser/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9QhTiz99",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"region-chooser input__wrapper inline margin-left-xsmall\"],[9],[0,\"\\n  \"],[7,\"label\"],[12,\"class\",[28,[\"label__standard \",[27,\"if\",[[23,[\"inline\"]],\"inline margin-left-xsmall\"],null]]]],[9],[0,\"\\n    \"],[1,[27,\"if\",[[23,[\"label\"]],[23,[\"label\"]],[27,\"t\",[\"Region\"],null]],null],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[1,[27,\"c-select\",null,[[\"value\",\"options\",\"id\",\"searchable\",\"action\"],[[22,0,[\"selectedObject\"]],[22,0,[\"options\"]],\"region-chooser\",true,[23,[\"onChange\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/region-chooser/template.hbs"
    }
  });

  _exports.default = _default;
});