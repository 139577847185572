define("@vollersgroup/hub-frontend/store/shop/shipment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EVLGd16T",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"wrapper content__editable\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"layout__full\"],[9],[0,\"\\n    \"],[7,\"h2\"],[9],[1,[27,\"t\",[\"Shipping information\"],null],false],[10],[0,\"\\n\\n    \"],[7,\"h3\"],[9],[1,[27,\"t\",[\"Shipping countries\"],null],false],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"shippingInfos\"]]],null,{\"statements\":[[0,\"      \"],[7,\"p\"],[9],[1,[27,\"t\",[\"We ship to the following countries:\"],null],false],[10],[0,\"\\n      \"],[7,\"p\"],[9],[1,[27,\"expandable-text\",null,[[\"class\",\"text\"],[\"font-size-6\",[27,\"join-array\",[[23,[\"model\",\"shippingInfos\"]]],[[\"labelProperty\"],[\"country\"]]]]]],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"p\"],[9],[1,[27,\"t\",[\"We ship world-wide.\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n    \"],[7,\"h3\"],[9],[1,[27,\"t\",[\"Shipping policies\"],null],false],[10],[0,\"\\n    \"],[7,\"div\"],[9],[0,\"\\n      \"],[1,[27,\"markdown-to-html\",[[23,[\"model\",\"policyShipping\"]]],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/store/shop/shipment/template.hbs"
    }
  });

  _exports.default = _default;
});