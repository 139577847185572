define("@vollersgroup/hub-frontend/components/timezone-checker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5Nj17+RP",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"timezoneDiffers\"]]],null,{\"statements\":[[4,\"info-message\",null,[[\"type\",\"class\",\"title\"],[\"INFO\",\"margin-bottom-2\",[27,\"t\",[\"Your configured time zone differs from your computer's time zone.\"],null]]],{\"statements\":[[0,\"    \"],[1,[27,\"t\",[\"Your configured time zone is {{configuredTimezone}}, while your computer's time zone is {{computerTimezone}}.\"],[[\"configuredTimezone\",\"computerTimezone\"],[[27,\"prettify-timezone\",[[23,[\"userSession\",\"timezone\"]]],null],[27,\"prettify-timezone\",[[23,[\"localTimezone\"]]],null]]]],false],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"margin-top-2\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"button button--info\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"Change your setting to {{date}}\"],[[\"date\"],[[27,\"prettify-timezone\",[[23,[\"localTimezone\"]]],null]]]],false],[0,\"\\n      \"],[3,\"action\",[[22,0,[]],\"updateTimezone\"]],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/timezone-checker/template.hbs"
    }
  });

  _exports.default = _default;
});