define("@vollersgroup/hub-frontend/helpers/join-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This helper joins array values and accepts
   * an optional `context` hash property, as it
   * implicitly calls `get-constant-label` too.
   * If the array contains objects it will look
   * for `label` and `name` properties for join,
   * otherwise the array item itself is used.
   *
   * ```html
   * {{join-array array context=context}}
   * ```
   *
   * @namespace Helper
   * @extends Ember.Helper
   * @class JoinArray
   * @public
   */
  var _default = Ember.Helper.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    availableData: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Cached `get-constant-label` helper.
     *
     * @public
     * @method getConstantLabelHelper
     * @type {Object}
     */
    getConstantLabelHelper: Ember.computed(function () {
      return Ember.getOwner(this).factoryFor('helper:get-constant-label').create();
    }),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Tries to join given array and implicitly
     * calls `get-constant-label` helper
     *
     * @method compute
     * @param {Array} params
     * @param {Object} [hash={}]
     * @public
     *
     * @return {String}
     */
    compute: function compute(params) {
      var hash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var array = params[0] || hash.array;

      if (Ember.isEmpty(array)) {
        return '';
      }

      if (Ember.get(this, '_array')) {
        this._stopWatching();
      }

      this._startWatching();

      Ember.set(this, '_array', array);
      var label = hash.labelProperty;
      var context = hash.context || {};
      var helper = Ember.get(this, 'getConstantLabelHelper');
      var joinedArray = '';

      if (!Ember.isArray(array)) {
        joinedArray = helper.compute([array], context);
      } else {
        joinedArray = array.map(function (item) {
          var text = !Ember.isNone(label) ? Ember.get(item, label) : item;
          return Ember.get(item, 'name') || Ember.get(item, 'label') || helper.compute([text], context);
        }).join(', ');
      }

      return joinedArray;
    },

    /**
     * Cleans observer on desctruction.
     *
     * @public
     * @method destroy
     * @return {Void}
     */
    destroy: function destroy() {
      this._stopWatching();

      this._super.apply(this, arguments);
    },

    /**
     * Adds observer to `availableData.constants`.
     *
     * @private
     * @method _startWatching
     * @return {Void}
     */
    _startWatching: function _startWatching() {
      this.addObserver('availableData.constants', this, this.recompute);
    },

    /**
     * Removes observer from `availableData.constants`.
     *
     * @private
     * @method _stopWatching
     * @return {Void}
     */
    _stopWatching: function _stopWatching() {
      this.removeObserver('availableData.constants', this, this.recompute);
    }
  });

  _exports.default = _default;
});