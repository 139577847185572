define("@vollersgroup/hub-frontend/components/head-layout", ["exports", "ember-cli-head/templates/components/head-layout"], function (_exports, _headLayout) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    layout: _headLayout.default
  });

  _exports.default = _default;
});