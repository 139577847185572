define("@vollersgroup/hub-frontend/store/auctions/show/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PCm7MQnz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"wrapper\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"model\",\"isOnHold\"]]],null,{\"statements\":[[4,\"info-message\",null,[[\"type\",\"class\",\"title\"],[\"WARNING\",\"margin-bottom-2\",[27,\"t\",[\"The auction is currently paused.\"],null]]],{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"Please wait. The auctioneer has paused the auction. During this time no bids will be accepted. When the auction resumes bidding will continue immediately.\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"auction-listings\",null,[[\"auction\"],[[23,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"currentAuctionParticipant\",\"isAccepted\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"text-right margin-top-2\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"Your total: {{auctionTotalPrice}}\"],[[\"auctionTotalPrice\"],[[27,\"format-currency\",[[23,[\"model\",\"currentAuctionParticipant\",\"totalParticipantPrice\"]],[23,[\"model\",\"shop\",\"currency\"]]],null]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/store/auctions/show/index/template.hbs"
    }
  });

  _exports.default = _default;
});