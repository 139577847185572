define("@vollersgroup/hub-frontend/components/error-messages/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eB/8s2Ju",
    "block": "{\"symbols\":[\"error\"],\"statements\":[[4,\"if\",[[23,[\"errors\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"mt-4 rounded-md bg-red-50 p-4\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"flex\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"flex-shrink-0\"],[9],[0,\"\\n\"],[0,\"        \"],[7,\"svg\"],[11,\"class\",\"h-5 w-5 text-red-400\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[11,\"viewBox\",\"0 0 20 20\"],[11,\"fill\",\"currentColor\"],[11,\"aria-hidden\",\"true\"],[9],[0,\"\\n          \"],[7,\"path\"],[11,\"fill-rule\",\"evenodd\"],[11,\"d\",\"M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z\"],[11,\"clip-rule\",\"evenodd\"],[9],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"ml-3\"],[9],[0,\"\\n        \"],[7,\"h3\"],[11,\"class\",\"text-sm font-medium text-red-800\"],[9],[0,\"Errors:\"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"mt-2 text-sm text-red-700\"],[9],[0,\"\\n          \"],[7,\"ul\"],[11,\"role\",\"list\"],[11,\"class\",\"list-disc pl-5 space-y-1\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"errors\"]]],null,{\"statements\":[[0,\"              \"],[7,\"li\"],[9],[1,[22,1,[\"message\"]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/error-messages/template.hbs"
    }
  });

  _exports.default = _default;
});