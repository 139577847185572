define("@vollersgroup/hub-frontend/store/index/route", ["exports", "@vollersgroup/hub-frontend/mixins/search", "@vollersgroup/hub-frontend/mixins/reset-scroll"], function (_exports, _search, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_resetScroll.default, _search.SearchRouteMixin, {
    // -------------------------------------------------------------------------
    // Dependencies
    toast: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Dependencies
    init: function init() {
      this._super.apply(this, arguments);

      var search = Ember.get(this, 'search');
      search.on('search_agent_changed', this, this._searchAgentChanged);
    },
    destroy: function destroy() {
      this._super.apply(this, arguments);

      var search = Ember.get(this, 'search');
      search.on('search_agent_changed', this, this._searchAgentChanged);
    },
    _searchAgentChanged: function _searchAgentChanged() {
      var searchAgent = Ember.get(this, 'search.searchAgent');
      var orderBy = searchAgent ? 'DATE' : 'DEFAULT';
      Ember.set(this, 'controller.orderBy', orderBy);
    },
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Checks to show toast messages for filters.
       *
       * @private
       * @event updateFilter
       * @param {Object} filter Group of updated item.
       * @param {Object} item Filter item to update.
       * @return Void
       */
      updateFilter: function updateFilter(filter, item) {
        switch (filter.parameterName) {
          case 'expressCoffee':
            if (item.active) {
              var message = Ember.get(this, 'l10n').t('Make sure to also set a shipping country to ensure delivery is possible.');
              Ember.get(this, 'toast').info(message, '', {
                timeOut: 10000
              });
            }

            break;

          default:
        }
      }
    }
  });

  _exports.default = _default;
});