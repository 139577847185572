define("@vollersgroup/hub-frontend/admin/sales/show/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "@vollersgroup/hub-frontend/mixins/reset-scroll", "moment", "ember-can", "@vollersgroup/hub-frontend/utils/get-first-adapter-error-message"], function (_exports, _authenticatedRouteMixin, _resetScroll, _moment, _emberCan, _getFirstAdapterErrorMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _resetScroll.default, _emberCan.CanMixin, {
    toast: Ember.inject.service(),
    model: function model(params) {
      if (!params.order_id || params.order_id === 'undefined') {
        return null;
      }

      return this.store.findRecord('order', params.order_id, {
        reload: true
      });
    },

    /**
     * Redirect to admin.orders.show if this is not a sale but an order.
     */
    afterModel: function afterModel(model) {
      var _this = this;

      if (!model) {
        this.transitionTo('admin.sales.list');
        return;
      }

      if (model.get('status') === 'CART') {
        this.transitionTo('cart');
        return;
      }

      if (model.get('status') === 'REQUEST') {
        // Set finalAmount to initialAmount & priceFinalAmount to priceInitial for all orderItems
        model.set('documents', []);
        model.set('additionalCosts', []);
        model.get('orderItems').then(function (orderItems) {
          if (!orderItems) {
            return;
          }

          orderItems.forEach(function (orderItem) {
            orderItem.set('finalAmount', orderItem.get('initialAmount'));
            orderItem.set('priceFinalAmount', orderItem.get('priceInitialAmount'));
            orderItem.set('priceFinalCurrency', orderItem.get('priceInitialCurrency'));
            orderItem.set('finalAmountUnit', orderItem.get('initialAmountUnit'));
          });
        });
      }

      return new Ember.RSVP.Promise(function (resolve) {
        model.get('sellerCompany').then(function (sellerCompany) {
          model.get('buyerCompany').then(function () {
            model.get('shop').then(function () {
              if (sellerCompany.get('id') !== _this.get('userSession.currentCompany.id')) {
                _this.transitionTo('admin.orders.show', model);
              }

              model.reload();
              model.get('orderItems').reload();
              resolve();
            });
          });
        });
      });
    },
    actions: {
      cancelOffer: function cancelOffer() {
        var _this2 = this;

        var model = this.currentModel;
        model.set('status', 'OFFER_CANCELED');
        model.set('closedDate', (0, _moment.default)());
        model.save().then(function () {
          _this2.get('toast').info(_this2.get('l10n').t('You have cancelled the offer!'));

          _this2.transitionTo('admin.sales.list');
        });
      },
      error: function error() {
        this.transitionTo('admin.sales.list');
      },

      /**
       * Reject this request.
       *
       * @event rejectRequest
       * @private
       */
      rejectRequest: function rejectRequest() {
        var _this3 = this;

        var model = this.currentModel;
        model.set('status', 'REQUEST_REJECTED');
        model.set('closedDate', (0, _moment.default)());
        model.save().then(function () {
          _this3.get('toast').info(_this3.get('l10n').t('You have rejected this request. The buyer will be notified of this rejection.'));

          _this3.transitionTo('admin.sales.list');
        });
      },

      /**
       * Make an offer from this request.
       *
       * @event sendOffer
       * @private
       */
      sendOffer: function sendOffer(data) {
        var _this4 = this;

        var model = this.currentModel;
        model.setProperties({
          orderNumber: data.orderNumber,
          orderNotes: data.orderNotes,
          shippingTerms: data.shippingTerms,
          additionalCosts: data.additionalCosts,
          documents: data.documents
        }); // Validate

        var hasError = false;
        model.get('orderItems').forEach(function (orderItem) {
          if (!orderItem.checkValidations()) {
            hasError = true;
          }
        });

        if (hasError) {
          return;
        } // Save the order items


        var controller = this.controllerFor(this.get('routeName'));
        controller.set('isSaving', true);
        var openOrderItems = model.get('orderItems').toArray();

        var _saveNextOrderItem = function _saveNextOrderItem() {
          var firstOrderItem = openOrderItems.splice(0, 1);
          firstOrderItem = firstOrderItem.length === 1 ? firstOrderItem[0] : null;

          if (firstOrderItem) {
            if (firstOrderItem.get('hasDirtyAttributes')) {
              firstOrderItem.save().then(function () {
                _saveNextOrderItem();
              }).catch(function (adapterError) {
                var serverErrorMessage = (0, _getFirstAdapterErrorMessage.default)(adapterError);
                var errorMessage = Ember.get(_this4, 'l10n').t('An error occurred while trying to save the order items: {{serverErrorMessage}}', {
                  serverErrorMessage: serverErrorMessage
                });
                controller.set('isSaving', false);

                _this4.get('toast').error(errorMessage);
              });
            } else {
              _saveNextOrderItem();
            }
          } else {
            _saveOrder();
          }
        };

        var _saveOrder = function _saveOrder() {
          if (model.get('priorPaymentRequired') || !model.get('offerHasChanged') && !model.get('hasCoffee') && !model.get('estimatedTotalPrice')) {
            model.set('status', 'ACCEPTED');
            model.set('offerCreatedDate', (0, _moment.default)());
            model.set('closedDate', (0, _moment.default)());
            model.save().then(function () {
              controller.set('isSaving', false);

              _this4.get('toast').info(_this4.get('l10n').t('You have accepted the request. The buyer will be notified.'));

              _this4.transitionTo('admin.sales.list', {
                queryParams: {
                  filterStatus: ['ACCEPTED']
                }
              });
            }).catch(function () {
              controller.set('isSaving', false);
              model.rollbackAttributes();

              _this4.get('toast').error(_this4.get('l10n').t('An error occurred while trying to update the order.'));
            });
            return;
          }

          model.set('status', 'OFFER');
          model.set('offerCreatedDate', (0, _moment.default)());
          model.save().then(function () {
            controller.set('isSaving', false);

            _this4.get('toast').info(_this4.get('l10n').t('You have made an offer. The buyer will be notified.'));

            _this4.transitionTo('admin.sales.list');
          }).catch(function () {
            controller.set('isSaving', false);
            model.rollbackAttributes();

            _this4.get('toast').error(_this4.get('l10n').t('An error occurred while trying to update the order.'));
          });
        };

        _saveNextOrderItem();
      }
    }
  });

  _exports.default = _default;
});