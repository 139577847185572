define("@vollersgroup/hub-frontend/user/adapter", ["exports", "@vollersgroup/hub-frontend/application/adapter"], function (_exports, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    },
    queryRecord: function queryRecord(store, type, query) {
      if (Ember.typeOf(query.user) === 'undefined' || query.user !== 'current') {
        return this._super(store, type, query);
      }

      var url = [];
      var host = Ember.get(this, 'host');
      var prefix = this.urlPrefix();
      url.push('user/current');

      if (prefix) {
        url.unshift(prefix);
      }

      url = url.join('/');

      if (!host && url && url.charAt(0) !== '/') {
        url = '/' + url;
      }

      delete query.user;

      if (this.sortQueryParams) {
        query = this.sortQueryParams(query);
      }

      return this.ajax(url, 'GET', {
        data: query
      });
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot);
      var id = snapshot.id;
      var url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');
      return this.ajax(url, 'PUT', {
        data: data
      });
    }
  });

  _exports.default = _default;
});