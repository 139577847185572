define("@vollersgroup/hub-frontend/checkout/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    checkout: Ember.inject.service(),
    l10n: Ember.inject.service(),
    shippingCountryError: null,
    showBillingAddress2: false,
    showShippingAddress2: false,
    _observer: Ember.on('init', Ember.observer('checkout.cart', 'checkout.shippingCountry', 'checkout.billingAddress.addressAddition', 'checkout.shippingAddress.addressAddition', function () {
      if (Ember.get(this, 'checkout.shippingAddress.addressAddition')) {
        Ember.set(this, 'showShippingAddress2', true);
      }

      if (Ember.get(this, 'checkout.billingAddress.addressAddition')) {
        Ember.set(this, 'showBillingAddress2', true);
      }

      this._validateShippingCountry();
    })),
    _validateShippingCountry: function _validateShippingCountry() {
      var _this = this;

      var shippingCountry = Ember.get(this, 'checkout.shippingCountry');
      var cart = Ember.get(this, 'checkout.cart');

      if (!cart) {
        return;
      }

      Ember.get(cart, 'shop').then(function (shop) {
        var shippingInfos = Ember.get(shop, 'shippingInfos');

        if (Ember.isEmpty(shippingInfos)) {
          return;
        }

        var servicedCountry = shippingInfos.findBy('country', shippingCountry);
        var message = Ember.get(_this, 'l10n').t('Sorry, but this shipping country is not serviced by shop {{shopName}}.', {
          shopName: Ember.get(shop, 'name')
        });
        Ember.set(_this, 'shippingCountryError', !servicedCountry ? message : null);
      });
    },
    actions: {
      toggleShippingAddress2: function toggleShippingAddress2() {
        this.toggleProperty('showShippingAddress2');
      },
      toggleBillingAddress2: function toggleBillingAddress2() {
        this.toggleProperty('showBillingAddress2');
      }
    }
  });

  _exports.default = _default;
});