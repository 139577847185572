define("@vollersgroup/hub-frontend/admin/sales/buyer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pzFy8hZH",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"page-title\",[[27,\"t\",[\"Buyer details\"],null]],null],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"wrapper\"],[9],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"margin-bottom-3\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"admin.sales.show\",[23,[\"model\"]]],[[\"class\"],[\"inline-block margin-bottom-3\"]],{\"statements\":[[0,\"      « \"],[1,[27,\"t\",[\"Back to sale\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"     | \\n    \"],[1,[27,\"t\",[\"Profile: {{companyName}}, {{userName}}\"],[[\"companyName\",\"userName\"],[[23,[\"model\",\"buyerCompany\",\"name\"]],[23,[\"model\",\"requestUser\",\"fullName\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[1,[27,\"user-profile\",null,[[\"userRole\",\"user\",\"billingAddress\",\"shippingAddress\",\"blacklistItem\"],[\"BUYER\",[23,[\"model\",\"requestUser\"]],[23,[\"model\",\"billingAddress\"]],[23,[\"model\",\"shippingAddress\"]],[27,\"get\",[[23,[\"model\",\"sellerCompany\",\"blacklistCompanies\"]],[23,[\"model\",\"buyerCompany\",\"id\"]]],null]]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/admin/sales/buyer/template.hbs"
    }
  });

  _exports.default = _default;
});