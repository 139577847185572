define("@vollersgroup/hub-frontend/order/ability", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend({
    userSession: Ember.inject.service(),
    roleLevel: Ember.computed.alias('userSession.currentUser.roleLevel'),
    canView: Ember.computed.gte('roleLevel', 1),
    canCreate: Ember.computed.gte('roleLevel', 3),
    canChangeStatus: Ember.computed.gte('roleLevel', 3),
    canEditDocuments: Ember.computed('roleLevel', 'model.sellerCompany.id', 'model.status', 'userSession.currentCompany.id', function () {
      return this.get('model.status') === 'ACCEPTED' && this.get('userSession.currentCompany.id') === this.get('model.sellerCompany.id');
    })
  });

  _exports.default = _default;
});