define("@vollersgroup/hub-frontend/components/listing-timer-price/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uYyLGy0S",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"float-left\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"listing\",\"bidCount\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"n\",[\"{{count}} bid\",\"{{count}} bids\",[23,[\"listing\",\"bidCount\"]]],[[\"count\"],[[23,[\"listing\",\"bidCount\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[27,\"and\",[[23,[\"listing\",\"auction\",\"isRunning\"]],[23,[\"listing\",\"auction\",\"remainingClockTime\"]]],null]],null,{\"statements\":[[0,\"      | \"],[1,[23,[\"listing\",\"auction\",\"endDateDuration\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"t\",[\"waiting for bids\"],null],false],[7,\"span\"],[11,\"class\",\"running\"],[9],[7,\"span\"],[9],[0,\".\"],[10],[7,\"span\"],[9],[0,\".\"],[10],[7,\"span\"],[9],[0,\".\"],[10],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\\n\"],[4,\"if\",[[27,\"can\",[\"view price listing\",[23,[\"listing\"]]],null]],null,{\"statements\":[[7,\"div\"],[11,\"class\",\"font-highlight\"],[9],[0,\"\\n  \"],[1,[27,\"format-currency\",[[23,[\"listing\",\"currentPrice\"]],[23,[\"listing\",\"priceCurrency\"]]],null],false],[0,\"\\n  / \"],[1,[27,\"get-constant-label\",[[23,[\"listing\",\"priceBaseUnit\"]]],[[\"context\"],[\"baseUnits\"]]],false],[0,\"\\n\"],[4,\"if\",[[23,[\"listing\",\"auction\",\"remainingClockTime\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"listing__item__total\"],[9],[0,\"\\n      (\"],[1,[27,\"t\",[\"Total: {{highestBidTotalPrice}}\"],[[\"highestBidTotalPrice\"],[[27,\"format-currency\",[[23,[\"listing\",\"totalPrice\"]],[23,[\"listing\",\"priceCurrency\"]]],null]]]],false],[0,\")\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/listing-timer-price/template.hbs"
    }
  });

  _exports.default = _default;
});