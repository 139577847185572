define("@vollersgroup/hub-frontend/register/auction/route", ["exports", "@vollersgroup/hub-frontend/custom-objects/address-object", "ember-can"], function (_exports, _addressObject, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_emberCan.CanMixin, {
    checkout: Ember.inject.service(),
    availableData: Ember.inject.service(),
    l10n: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Delivers route's model consisting of
     * selected auction and current/new user.
     *
     * @param  {Object} params Route params
     * @return {Ember.RSVP.Promise}
     */
    model: function model(params) {
      if (!isNaN(parseInt(params.auction_id))) {
        return this.store.findRecord('auction', params.auction_id);
      }

      return this.store.query('auction', {
        slug: params.auction_id
      }).then(function (auctions) {
        return auctions.get('firstObject');
      });
    },

    /**
     * Sets up both parent and current controller.
     */
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var parentController = this.controllerFor('register');
      parentController.set('queryParamName', 'checkUser');
      parentController.set('queryParamValue', '1');
      parentController.set('route', null); // We need to pass ownerInjection into AddressObject to make validations work.
      // https://github.com/offirgolan/ember-cp-validations/issues/430#issuecomment-270902671

      var shippingAddress = _addressObject.default.create(Ember.getOwner(this).ownerInjection());

      var orderableSampleBundles = model.get('orderableSampleBundles');
      controller.set('user', this.modelFor('register'));
      controller.set('isInitializing', true);
      controller.set('sampleAmount', 1);
      controller.set('sampleOptions', function () {
        var options = [];

        for (var i = 0; i <= orderableSampleBundles; i++) {
          options.push({
            value: i,
            label: i + 'x'
          });
        }

        return options;
      }());
      controller.set('shippingSameAsBilling', true);
      controller.set('shippingAddress', shippingAddress);
      controller.set('importerRequirement', model.get('availableImporterRequirements.firstObject.value'));
      controller.set('importerName', null);

      if (this._isAuctionActive()) {
        this._checkUser();
      }
    },

    /**
     * Serializes auction id with slug.
     *
     * @param  {Model.Auction} model
     * @return {Object}
     */
    serialize: function serialize(model) {
      var param = model.get('slug') || model;

      if (!param) {
        param = model.get('id');
      }

      return {
        auction_id: param
      };
    },

    /**
     * Checks if the auction is currently active
     * indicating that registrations are allowed.
     *
     * @method _isAuctionActive
     * @return {Boolean}
     * @private
     */
    _isAuctionActive: function _isAuctionActive() {
      var model = this.modelFor(this.routeName);

      if (!model.get('isActiveOrRunning')) {
        this._redirectUser(this.get('l10n').t('This auction is complete - take a look at other auctions, redirecting...'), 'store.auctions');

        return false;
      }

      return true;
    },

    /**
     * Checks if there's an active user session
     * and swaps with new user from dat store,
     * which has been retrieved from parent.
     *
     * @method _checkUser
     * @return {Void}
     * @private
     */
    _checkUser: function _checkUser() {
      var _this = this;

      var currentUser = this.get('userSession.currentUser');
      var controller = this.controllerFor(this.routeName);

      if (Ember.isEmpty(currentUser)) {
        controller.set('isInitializing', false);
        return;
      }

      controller.set('isInitializing', true);
      var model = this.modelFor(this.routeName);

      var companySuccessCallback = function companySuccessCallback(company) {
        // exchange with register user
        var parentModel = _this.modelFor('register');

        controller.set('user', currentUser);
        parentModel.rollbackAttributes(); // check if this is auction owner

        if (model.get('isOwner')) {
          _this._redirectUser(_this.get('l10n').t('You are the owner of this auction, redirecting...'));

          return;
        }

        var auctionParticipantSuccessCallback = function auctionParticipantSuccessCallback(participants) {
          if (participants.findBy('auction.id', model.get('id'))) {
            _this._redirectUser(_this.get('l10n').t('You are already registered for this auction, redirecting...'));

            return;
          }

          controller.set('isInitializing', false);
        };

        var auctionParticipantFailureCallback = function auctionParticipantFailureCallback(error) {
          var serverErrorMessage = Ember.get(error, 'payload.error');

          var errorMessage = _this.get('l10n').t('An error occurred while trying to fetch your registration data: {{serverErrorMessage}}', {
            serverErrorMessage: serverErrorMessage
          });

          _this.get('toast').error(errorMessage);
        }; // check if this user company's already registered


        _this.get('store').query('auction-participant', {
          participatingCompany: company.get('id')
        }).then(auctionParticipantSuccessCallback, auctionParticipantFailureCallback);
      };

      var companyFailureCallback = function companyFailureCallback(error) {
        var serverErrorMessage = Ember.get(error, 'payload.error');
        var errorMessage = Ember.get(_this, 'l10n').t('An error occurred while trying to fetch your company data: {{serverErrorMessage}}', {
          serverErrorMessage: serverErrorMessage
        });

        _this.get('toast').error(errorMessage);
      };

      currentUser.get('company').then(companySuccessCallback, companyFailureCallback);
    },

    /**
     * Redirects user to auction(s) site.
     *
     * @param {String} redirectMessage Redirect message.
     * @param {String} redirectRoute Redirect route name.
     * @return {Void}
     */
    _redirectUser: function _redirectUser(redirectMessage) {
      var _this2 = this;

      var redirectRoute = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var controller = this.controllerFor(this.routeName);

      if (!Ember.isBlank(redirectMessage)) {
        controller.set('redirectMessage', redirectMessage);
      }

      Ember.run.later(function () {
        if (!Ember.isEmpty(redirectRoute)) {
          _this2.transitionTo(redirectRoute);

          return;
        }

        var model = _this2.modelFor(_this2.routeName);

        var options = {
          queryParams: {
            checkUser: null
          }
        };

        _this2.transitionTo('store.auctions.show', model, options);
      }, 2500);
    },
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Called whenever a query parameter changes.
       *
       * @event queryParamsDidChange
       * @param {Object} changed Changed query parameters.
       * @return {Void}
       */
      queryParamsDidChange: function queryParamsDidChange(changed) {
        if (changed.checkUser) {
          this._checkUser();
        }

        return true;
      }
    }
  });

  _exports.default = _default;
});