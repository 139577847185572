define("@vollersgroup/hub-frontend/company/serializer", ["exports", "ember-data", "@vollersgroup/hub-frontend/application/serializer", "moment"], function (_exports, _emberData, _serializer, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;

  var _default = _serializer.default.extend(EmbeddedRecordsMixin, {
    attrs: {
      location: {
        serialize: 'records',
        deserialize: 'records'
      },
      subscription: {
        serialize: 'records',
        deserialize: 'records'
      }
    },
    normalize: function normalize(type, json, property) {
      if (json.verificationStatus === 'NOT_REQUESTED') {
        json.verificationStatus = null;
      }

      return this._super(type, json, property);
    },
    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);

      if (!json.verificationStatus) {
        json.verificationStatus = 'NOT_REQUESTED';
      }

      if (!json.createdDate) {
        json.createdDate = (0, _moment.default)().valueOf();
      }

      json.name = json.name.trim();
      return json;
    }
  });

  _exports.default = _default;
});