define("@vollersgroup/hub-frontend/company/adapter", ["exports", "@vollersgroup/hub-frontend/application/adapter"], function (_exports, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    queryRecord: function queryRecord(store, type, query) {
      if (Ember.typeOf(query.token) === 'undefined') {
        return this._super(store, type, query);
      }

      var url = [];
      var host = Ember.get(this, 'host');
      var prefix = this.urlPrefix();
      url.push('company/byToken');
      url.push(query.token);

      if (prefix) {
        url.unshift(prefix);
      }

      url = url.join('/');

      if (!host && url && url.charAt(0) !== '/') {
        url = '/' + url;
      }

      delete query.token;

      if (this.sortQueryParams) {
        query = this.sortQueryParams(query);
      }

      return this.ajax(url, 'GET', {
        data: query
      });
    }
  });

  _exports.default = _default;
});