define("@vollersgroup/hub-frontend/helpers/asset-url", ["exports", "@vollersgroup/hub-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.assetUrl = assetUrl;
  _exports.default = void 0;
  var assetPrependURL = _environment.default.assetPrependURL;

  function assetUrl(params
  /*, hash*/
  ) {
    var url = params[0] + '';

    if (url[0] === '/' && assetPrependURL[assetPrependURL.length - 1] === '/') {
      url = url.substr(1);
    }

    return assetPrependURL + url;
  }

  var _default = Ember.Helper.helper(assetUrl);

  _exports.default = _default;
});