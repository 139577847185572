define("@vollersgroup/hub-frontend/producer/model", ["exports", "ember-data", "@vollersgroup/hub-frontend/config/environment", "ember-cp-validations", "@vollersgroup/hub-frontend/utils/get-video-metadata"], function (_exports, _emberData, _environment, _emberCpValidations, _getVideoMetadata) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var belongsTo = _emberData.default.belongsTo;
  var attr = _emberData.default.attr;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      validators: [(0, _emberCpValidations.validator)('length', {
        allowNone: false,
        min: 3,
        max: 128,
        // This is a constraint in a db
        message: 'Please enter from 3 to 128 characters.'
      })]
    },
    type: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Please select a type.'
      })]
    },
    foundingYear: {
      validators: [(0, _emberCpValidations.validator)('number', {
        allowNone: true,
        allowString: false,
        integer: true,
        gt: 1500,
        lte: new Date().getFullYear(),
        message: 'Please enter enter a valid founding year.',
        disabled: Ember.computed.empty('model.foundingYear')
      })]
    },
    numFarms: {
      validators: [(0, _emberCpValidations.validator)('number', {
        allowString: false,
        integer: true,
        gt: 0,
        message: 'Please enter a valid number of farms.',
        disabled: Ember.computed('model.type', 'model.numFarms', function () {
          if (!Ember.get(this, 'model.numFarms')) {
            return true;
          }

          var type = Ember.get(this, 'model.type');
          return !['COOP', 'ASSOCIATION'].includes(type);
        })
      })]
    },
    numMembers: {
      validators: [(0, _emberCpValidations.validator)('number', {
        allowString: false,
        integer: true,
        gt: 0,
        message: 'Please enter a valid number of farm members.',
        disabled: Ember.computed('model.type', 'model.numMembers', function () {
          if (!Ember.get(this, 'model.numMembers')) {
            return true;
          }

          var type = Ember.get(this, 'model.type');
          return !['COOP', 'ASSOCIATION'].includes(type);
        })
      })]
    },
    'location.country': {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Please select a country.'
      })]
    },
    'location.latitude': {
      validators: [(0, _emberCpValidations.validator)('number', {
        allowNone: false,
        allowString: false,
        message: 'Please enter latitude.',
        dependentKeys: ['model.validateGeolocation'],
        // @todo(apf): https://github.com/offirgolan/ember-cp-validations/issues/596
        disabled: Ember.computed.not('model.validateGeolocation').volatile()
      })]
    },
    'location.longitude': {
      validators: [(0, _emberCpValidations.validator)('number', {
        allowNone: false,
        allowString: false,
        message: 'Please enter longitude.',
        dependentKeys: ['model.validateGeolocation'],
        // @todo(apf): https://github.com/offirgolan/ember-cp-validations/issues/596
        disabled: Ember.computed.not('model.validateGeolocation').volatile()
      })]
    },
    video: {
      validators: [// Validate if the video url is valid
      (0, _emberCpValidations.validator)(function (value, _options, _model, _attribute) {
        if (Ember.isEmpty(value)) {
          // Empty video is also valid. We just don't display anything.
          return true;
        }

        var videoMetadata = (0, _getVideoMetadata.default)(value);

        if (videoMetadata && videoMetadata.service && videoMetadata.id) {
          return true;
        }

        return 'Video URL is not in a valid format.';
      })]
    }
  });
  /**
   * The producer model
   *
   * @namespace Model
   * @class Producer
   * @extends Model
   */

  var _default = Model.extend(Validations, {
    /**
     * The name of the producer.
     *
     * @attribute name
     * @type String
     */
    name: attr('string'),

    /**
     * The name of the farm.
     *
     * @attribute farmName
     * @type String
     */
    farmName: attr('string'),

    /**
     * The story of the producer
     *
     * @attribute story
     * @type String
     */
    story: attr('string'),

    /**
     * The altitude of the producer. Can be a random string (like 1500-1700m)
     *
     * @attribute altitude
     * @type String
     * @optional
     */
    altitude: attr('string'),

    /**
     * The location of this producer.
     *
     * @attribute location
     * @type Model.Location
     */
    location: belongsTo('location', {
      async: false
    }),

    /**
     * The type of the producer, e.g. COOP, ...
     *
     * @attribute type
     * @type "COOP"|"ASSOCIATION"|"FARM"|"MILL"
     */
    type: attr('string'),

    /**
     * The founding year of the producer.
     *
     * @attribute foundingYear
     * @type Number
     * @optional
     */
    foundingYear: attr('number'),

    /**
     * The number of farms for this producer. Is only set for type=COOP
     *
     * @attribute numFarms
     * @type Number
     * @optional
     */
    numFarms: attr('number'),

    /**
     * The number of members of this producer. Is only set for type=COOP
     *
     * @attribute numMembers
     * @type Number
     * @optional
     */
    numMembers: attr('number'),

    /**
     * The total size of the producer's farm in square m.
     *
     * @attribute farmSize
     * @type Number
     * @optional
     */
    farmSize: attr('number'),

    /**
     * The total size of the farm on which coffee is grown in square m.
     *
     * @attribute coffeeGrowingArea
     * @type Number
     * @optional
     */
    coffeeGrowingArea: attr('number'),

    /**
     * If the producer has been archived.
     *
     * @attribute archived
     * @type Boolean
     * @optional
     */
    archived: attr('boolean'),

    /**
     * An array of image objects for the producer.
     *
     * @attribute images
     * @type Array
     * @optional
     */
    images: attr('image-array'),

    /**
     * The company to which this producer belongs.
     *
     * @attribute company
     * @type Model.Company
     */
    company: belongsTo('company', {
      async: true
    }),

    /**
     * The URL to Youtube/Vimeo video presenting this producer.
     */
    video: attr('string'),

    /**
     * The URL for the map of this producer (fetched from mapbox)
     *
     * @property mapUrl
     * @type String
     * @readOnly
     */
    mapUrl: Ember.computed('location.latitude', 'location.longitude', function () {
      var latitude = this.get('location.latitude');
      var longitude = this.get('location.longitude');

      if (!latitude || !longitude) {
        return '';
      } // Create static image with mapbox


      var token = _environment.default.mapboxToken;

      if (!token) {
        return null;
      }

      var zoom = 6;
      var bearing = 0;
      var pitch = 0;
      var dims = '500x500';
      var url = "https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/".concat(longitude, ",").concat(latitude, ",").concat(zoom, ",").concat(bearing, ",").concat(pitch, "/").concat(dims, "?access_token=").concat(token);
      return url;
    }),
    isEditable: Ember.computed.not('archived'),

    /*
     * By default, this is always false.
     * Only in a case where user edits the producer form and
     * enables manual geolocation entry, it should be enabled.
     */
    validateGeolocation: false,
    isGeolocationEnteredManually: Ember.computed.readOnly('validateGeolocation'),
    save: function save() {
      return this._super.apply(this, arguments).then(function (model) {
        model.set('validateGeolocation', false);
        return model;
      });
    }
  });

  _exports.default = _default;
});