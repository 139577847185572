define("@vollersgroup/hub-frontend/components/unread-conversations/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    messageCenter: Ember.inject.service(),
    actions: {
      dismiss: function dismiss(conversation) {
        conversation.set('toCompanyUnreadCount', 0);
        conversation.save();
      }
    }
  });

  _exports.default = _default;
});