define("@vollersgroup/hub-frontend/subscription/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var belongsTo = _emberData.default.belongsTo;
  var attr = _emberData.default.attr;

  var _default = Model.extend({
    planId: attr('string'),
    nextBillAt: attr('timestamp'),
    trialActiveUntil: attr('timestamp'),
    updatedAt: attr('timestamp'),
    createdAt: attr('timestamp'),
    company: belongsTo('company'),
    isTrialActive: Ember.computed('trialActiveUntil', function () {
      return this.trialActiveUntil && this.trialActiveUntil.isAfter();
    }),
    isFreePlan: Ember.computed.equal('planId', 'V-FREE')
  });

  _exports.default = _default;
});