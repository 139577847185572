define("@vollersgroup/hub-frontend/add-to-wishlist/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    keenTracking: Ember.inject.service(),
    userSession: Ember.inject.service(),
    toast: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    l10n: Ember.inject.service(),
    queryParams: ['listingName'],
    username: null,
    password: null,
    listingName: null,
    isBusy: false,
    isError: false,
    authOption: 'LOGIN',
    authOptions: Ember.computed('l10n', function () {
      return [{
        value: 'LOGIN',
        label: Ember.get(this, 'l10n').t('Yes, I have an account')
      }, {
        value: 'REGISTER',
        label: Ember.get(this, 'l10n').t('No, I do not have an account')
      }];
    }),
    addToWishlist: function addToWishlist() {
      var _this = this;

      var _Ember$get = Ember.get(this, 'model'),
          ratingId = _Ember$get.ratingId,
          newWishlistItem = _Ember$get.newWishlistItem;

      Ember.set(this, 'isBusy', true);

      var _wishlistSuccessCallback = function _wishlistSuccessCallback() {
        var message = Ember.get(_this, 'l10n').t('"{{coffeeName}}" is now on your wish list.', {
          coffeeName: Ember.get(_this, 'listingName')
        });

        _this.get('toast').success(message);

        Ember.get(_this, 'keenTracking').addEvent('add-to-wishlist-from-email', {
          listingName: Ember.get(_this, 'listingName')
        });

        _this.transitionToRoute('admin.wishlist');
      };

      var _wishlistFailureCallback = function _wishlistFailureCallback() {
        var message = Ember.get(_this, 'l10n').t('Could not add "{{coffeeName}}" to your wish list. Please, try it again in a moment.', {
          coffeeName: Ember.get(_this, 'listingName')
        });
        Ember.get(_this, 'toast').error(message);
        Ember.set(_this, 'isError', true);
      };

      var _wishlistFinallyCallback = function _wishlistFinallyCallback() {
        Ember.set(_this, 'isBusy', false);
      };

      var _listingSuccessCallback = function _listingSuccessCallback(listing) {
        var user = Ember.get(_this, 'userSession.currentUser'); // avoid duplicate creation of wish list item
        // even if server would return the old record,
        // ember data would complain because of this:
        // https://github.com/emberjs/data/issues/4972

        if (Ember.get(user, "wishlistListings.".concat(listing.id))) {
          _wishlistSuccessCallback();

          return;
        }

        Ember.set(newWishlistItem, 'listing', listing);
        Ember.set(newWishlistItem, 'user', user);
        newWishlistItem.save().then(_wishlistSuccessCallback, _wishlistFailureCallback).finally(_wishlistFinallyCallback);
      };

      var _listingFailureCallback = function _listingFailureCallback() {
        _wishlistFailureCallback();

        _wishlistFinallyCallback();
      };

      var _claimSuccessCallback = function _claimSuccessCallback(response) {
        /**
         * {
         *   id: <string>,
         *   email: <string>,
         *   user: <number>,
         *   listing: <number>,
         *   flavors: <array>,
         *   notes: <string>,
         *   rating: <string>,
         *   private: <boolean>
         * }
         */
        Ember.get(_this, 'store').findRecord('listing', response.listing).then(_listingSuccessCallback, _listingFailureCallback);
      };

      var _claimFailureCallback = function _claimFailureCallback() {
        _wishlistFailureCallback();

        _wishlistFinallyCallback();
      };

      Ember.get(this, 'ajax').post("/rating/".concat(ratingId, "/claim")).then(_claimSuccessCallback, _claimFailureCallback);
    },
    actions: {
      addToWishlist: function addToWishlist() {
        this.addToWishlist();
      },
      trackNewUserAndAddToWishlist: function trackNewUserAndAddToWishlist() {
        Ember.get(this, 'keenTracking').addEvent('register-from-add-to-wishlist-form', {
          listingName: Ember.get(this, 'listingName')
        });
        this.addToWishlist();
      },
      toggleAuthOption: function toggleAuthOption(mode) {
        switch (mode) {
          case 'LOGIN':
            {
              Ember.set(this, 'username', Ember.get(this, 'model.newUser.username'));
              break;
            }

          case 'REGISTER':
            {
              Ember.set(this, 'model.newUser.username', Ember.get(this, 'username'));
              break;
            }

          default:
        }
      }
    }
  });

  _exports.default = _default;
});