define("@vollersgroup/hub-frontend/shop/serializer", ["exports", "ember-data", "@vollersgroup/hub-frontend/application/serializer"], function (_exports, _emberData, _serializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;

  var _default = _serializer.default.extend(EmbeddedRecordsMixin, {
    attrs: {
      location: {
        serialize: 'records',
        deserialize: 'records'
      }
    },
    serialize: function serialize(payload, options) {
      var json = this._super(payload, options);

      json.name = json.name.trim();
      return json;
    }
  });

  _exports.default = _default;
});