define("@vollersgroup/hub-frontend/index/route", ["exports", "@vollersgroup/hub-frontend/mixins/reset-scroll"], function (_exports, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_resetScroll.default, {
    model: function model() {
      return {
        shops: this.store.query('shop', {
          page: 1,
          pageSize: 6,
          sort: 'DATE',
          status: ['ACTIVE']
        }),
        auctions: this.store.query('auction', {
          limit: 3,
          sort: 'DATE',
          status: ['ACTIVE', 'RUNNING']
        }),
        listings: this.store.query('listing', {
          pageSize: 4,
          page: 1,
          sort: 'DATE',
          status: ['ACTIVE'],
          sortDirection: 'DESC'
        })
      };
    }
  });

  _exports.default = _default;
});