define("@vollersgroup/hub-frontend/mixins/label-print", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    localAssetLoader: Ember.inject.service('local-asset-loader'),
    jsPDFLoaded: Ember.computed('localAssetLoader', function () {
      return this.get('localAssetLoader').loadScript('/assets/jspdf.min.js');
    }),
    printLabels: function printLabels(labels) {
      var _this = this;

      var filename = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var format = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [70.5, 100.5];
      Ember.run.next(function () {
        _this.get('jsPDFLoaded').then(function () {
          //noinspection JSPotentiallyInvalidConstructorUsage
          var doc = new jsPDF('l', 'mm', format);
          doc.setFontSize(12);
          labels.forEach(function (label, i) {
            if (i > 0) {
              doc.addPage();
            }

            var yOffset = 0;
            label.forEach(function (row, j) {
              var parts = doc.splitTextToSize(row, format[1] - 40);

              if (j === 1) {
                yOffset += 3;
              } else if (j > 1) {
                yOffset += 1;
              }

              parts.forEach(function (part) {
                yOffset += 5;
                doc.text(20, 15 + yOffset, part);
              });
            });
          });

          if (!filename) {
            filename = 'cropster_hub_labels_' + (0, _moment.default)().format('YYYY-MM-DD');
          }

          doc.save(filename + '.pdf');
        });
      });
    }
  });

  _exports.default = _default;
});