define("@vollersgroup/hub-frontend/components/simple-box/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['border-all'],

    /**
     * Title of the box.
     *
     * @attribute title
     * @type String
     * @optional
     */
    title: null
  });

  _exports.default = _default;
});