define("@vollersgroup/hub-frontend/components/manage-auction-publish/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A module to publish a auction.
   *
   * ```html
   * {{manage-auction-publish auction=myAuction action="myAction"}}
   * ```
   *
   * The action is called without parameters when the auction is published.
   *
   * @namespace Component
   * @class ManageAuctionPublish
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * The auction that should be published.
     *
     * @attribute auction
     * @type Model.Auction
     * @required
     */
    auction: null,

    /**
     * The name of the action to call when the auction is published.
     * This action receives no parameters.
     *
     * @attribute action
     * @type string
     * @required
     */
    action: null,
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Actually publish the auction.
       *
       * @event publish
       * @private
       */
      publish: function publish() {
        var _this = this;

        var auction = this.getAttr('auction');
        auction.set('status', 'ACTIVE');
        this.set('isLoading', true);
        auction.save().then(function () {
          _this.get('toast').success(_this.get('l10n').t('You have successfully published your auction!'));

          _this.sendAction('action', _this.get('createCoffee'));
        }, function () {
          _this.set('isLoading', false);

          _this.get('toast').error(_this.get('l10n').t('Your auction could not be published.'));
        });
      },

      /**
       * Deactivate the auction.
       *
       * @event deactivate
       * @private
       */
      deactivate: function deactivate() {
        var _this2 = this;

        var auction = this.getAttr('auction');
        auction.set('status', 'INACTIVE');
        this.set('isLoading', true);
        auction.save().then(function () {
          _this2.get('toast').success(_this2.get('l10n').t('You have successfully deactivated your auction!'));

          _this2.sendAction('action', _this2.get('createCoffee'));
        }, function () {
          _this2.set('isLoading', false);

          _this2.get('toast').error(_this2.get('l10n').t('Your auction could not be deactivated.'));
        });
      },

      /**
       * Simply continue without changing anything.
       *
       * @event continue
       * @private
       */
      continue: function _continue() {
        this.sendAction('action', this.get('createCoffee'));
      }
    },
    // -------------------------------------------------------------------------
    // Properties

    /**
     * When this is true, some computation is currently occuring.
     *
     * @property isLoading
     * @type Boolean
     * @private
     */
    isLoading: false,

    /**
     * If this is true, then the user will be redirected to "create a coffee" after he finished.
     *
     * @property createCoffee
     * @type Boolean
     * @private
     */
    createCoffee: false
  });

  _exports.default = _default;
});