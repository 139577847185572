define("@vollersgroup/hub-frontend/admin/loading/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "@vollersgroup/hub-frontend/mixins/reset-scroll"], function (_exports, _authenticatedRouteMixin, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _resetScroll.default, {});

  _exports.default = _default;
});