define("@vollersgroup/hub-frontend/services/l10n", ["exports", "ember-l10n/services/l10n", "@vollersgroup/hub-frontend/config/environment"], function (_exports, _l10n, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getSingleton = _exports.default = void 0;
  var assetPrependURL = _environment.default.assetPrependURL || '';

  if (assetPrependURL[assetPrependURL.length - 1] === '/') {
    assetPrependURL = assetPrependURL.substr(0, assetPrependURL.length - 1);
  }

  var _default = _l10n.default.extend({
    jsonPath: Ember.computed(function () {
      return assetPrependURL + '/assets/locales';
    }),
    _getPathForLocale: function _getPathForLocale(locale) {
      var basePath = Ember.get(this, 'jsonPath');
      var assetMap = Ember.get(this, 'assetMap');
      var fullPath = ''.concat(basePath, '/').concat(locale, '.json');

      if (!Ember.get(this, 'useAssetMap')) {
        return fullPath;
      }

      var path = fullPath;

      if (path.indexOf('/') === 0) {
        path = path.substr(1);
      }

      var finalPathPart = assetMap.resolve(path) || fullPath;
      var finalPath = '/'.concat(finalPathPart);
      return finalPath;
    },

    /**
     * Hash map of all available locales.
     * @see: /translations for all PO files.
     * @see: /assets/locales for all JSON files.
     *
     * @property availableLocales
     * @return {Object}
     * @public
     */
    availableLocales: Ember.computed(function () {
      return {
        en: this.t('en') //'de': this.t('de'),

      };
    }),

    /**
     * If set to false, l10n service has to be
     * invoked via a setLocale() call instead of
     * autodetecting and trying to load detected
     * translations. We do this, as have to differ
     * between anonymous and authenticated users.
     * @see: /app/authenticators/custom.js
     *
     * @property autoInitialize
     * @type {Boolean}
     * @public
     */
    autoInitialize: false,

    /**
     * Custom property transforming current locale
     * to appropriate culture name. Full list here:
     * https://msdn.microsoft.com/en-us/library/ee825488(v=cs.20).aspx
     *
     * @property culture
     * @type {String}
     * @public
     */
    culture: Ember.computed('locale', function () {
      switch (this.get('locale')) {
        case 'de':
          return 'de-DE';

        case 'en':
          return 'en-US';
      }
    })
  });

  _exports.default = _default;

  var getSingleton = function getSingleton() {
    try {
      return window.AppInstance.lookup('service:l10n');
    } catch (e) {
      /* TODO: REMOVE ME! */
    }
  };

  _exports.getSingleton = getSingleton;
});