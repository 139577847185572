define("@vollersgroup/hub-frontend/components/auction-participant-table-row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    participant: null,
    showHighestLots: false,
    actions: {
      toggleHighestLots: function toggleHighestLots() {
        this.toggleProperty('showHighestLots');
      }
    }
  });

  _exports.default = _default;
});