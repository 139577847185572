define("@vollersgroup/hub-frontend/components/search-agent-modal/component", ["exports", "@vollersgroup/hub-frontend/utils/get-first-adapter-error-message"], function (_exports, _getFirstAdapterErrorMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Modal for managing search agents based
   * on current search settings from query.
   *
   * @namespace Component
   * @class SearchAgentModal
   */
  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    toast: Ember.inject.service(),
    store: Ember.inject.service(),
    userSession: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * Current search instance.
     *
     * @attribute search
     * @type {Object}
     * @public
     */
    search: null,

    /**
     * Current selected search agent.
     *
     * @attribute searchAgent
     * @type {Object}
     * @public
     */
    searchAgent: null,
    // -------------------------------------------------------------------------
    // Properties

    /**
     * A custom name of this search if it's new.
     *
     * @property name
     * @type {String}
     * @private
     */
    name: '',

    /**
     * Flag if currently persisting search agents.
     *
     * @property isBusy
     * @type {Boolean}
     * @private
     */
    isBusy: false,

    /**
     * An existing search agent if editing existing.
     *
     * @property selected
     * @type {Object}
     * @private
     */
    selected: Ember.computed.reads('searchAgent'),

    /**
     * Flag if creating or updating existing agents.
     *
     * @property isNew
     * @type {Boolean}
     * @private
     */
    isNew: Ember.computed('selected', {
      get: function get() {
        return Ember.isEmpty(Ember.get(this, 'selected'));
      }
    }),

    /**
     * Notification flag of this search if it's new.
     *
     * @property mailEnabled
     * @type {Boolean}
     * @private
     */
    mailEnabled: Ember.computed('selected', {
      get: function get() {
        var searchAgent = Ember.get(this, 'selected');

        if (Ember.isEmpty(searchAgent)) {
          return true;
        }

        return Ember.get(searchAgent, 'mailEnabled');
      }
    }),

    /**
     * List of all search agents.
     *
     * @property searchAgents
     * @type Model.SearchAgent[]
     */
    searchAgents: Ember.computed.reads('userSession.searchAgents'),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Gets payload for search agent model with sanitized query
     * object from current search's `query` parameter without
     * sorting and paging information.
     *
     * @private
     * @method _getSearchAgentProperties
     * @return {Void}
     */
    _getSearchAgentProperties: function _getSearchAgentProperties() {
      var listingFilter = Ember.get(this, 'search.query');
      var mailEnabled = Ember.get(this, 'mailEnabled');
      var name = Ember.get(this, 'name');
      delete listingFilter.sortDirection;
      delete listingFilter.pageSize;
      delete listingFilter.sort;
      delete listingFilter.page;
      return {
        name: name,
        mailEnabled: mailEnabled,
        listingFilter: listingFilter
      };
    },

    /**
     * Saves a search-agent model and handles callbacks.
     *
     * @private
     * @method _saveSearchAgent
     * @return {Promise}
     */
    _saveSearchAgent: function _saveSearchAgent(searchAgent) {
      var _this = this;

      var successCallback = function successCallback() {
        var successMessage = Ember.get(_this, 'l10n').t('Your search agent has been saved successfully!');
        Ember.get(_this, 'toast').success(successMessage);

        _this.attrs.closeModal();
      };

      var failureCallback = function failureCallback(adapterError) {
        var serverErrorMessage = (0, _getFirstAdapterErrorMessage.default)(adapterError);
        var errorMessage = Ember.get(_this, 'l10n').t('An error occurred while saving your search agent: {{serverErrorMessage}}', {
          serverErrorMessage: serverErrorMessage
        });
        Ember.get(_this, 'toast').error(errorMessage);
      };

      var finallyCallback = function finallyCallback() {
        Ember.set(_this, 'isBusy', false);
      };

      Ember.set(this, 'isBusy', true);
      return searchAgent.save().then(successCallback).catch(failureCallback).finally(finallyCallback);
    },

    /**
     * Updates `mailEnabled` property according to selection.
     *
     * @private
     * @method _updateMailEnabled
     * @return {Void}
     */
    _updateMailEnabled: function _updateMailEnabled() {
      var mailEnabled = true;
      var isNew = Ember.get(this, 'isNew');

      if (!isNew) {
        var searchAgent = Ember.get(this, 'selected');
        mailEnabled = !Ember.isEmpty(searchAgent) ? Ember.get(searchAgent, 'mailEnabled') : true;
      }

      Ember.set(this, 'mailEnabled', mailEnabled);
    },
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Saves current search settings as agent with a custom name.
       * After creation it will be forwarded to search instance.
       *
       * @event save
       * @private
       */
      save: function save() {
        var _this2 = this;

        Ember.set(this, 'isBusy', true);
        var store = Ember.get(this, 'store');

        var properties = this._getSearchAgentProperties();

        var searchAgent = store.createRecord('search-agent', properties);

        var successCallback = function successCallback() {
          var search = Ember.get(_this2, 'search');
          search.setSearchAgent(searchAgent);
        };

        var promise = this._saveSearchAgent(searchAgent);

        promise.then(successCallback);
        return promise;
      },

      /**
       * Updates an existing search agent with current settings.
       *
       * @event update
       * @private
       */
      update: function update() {
        var searchAgent = Ember.get(this, 'selected');

        var properties = this._getSearchAgentProperties();

        delete properties.name;
        searchAgent.setProperties(properties);
        return this._saveSearchAgent(searchAgent);
      },

      /**
       * Updates `mailEnabled` property.
       *
       * @event select
       * @private
       */
      select: function select() {
        this._updateMailEnabled();
      },

      /**
       * Resets `name` to empty string.
       *
       * @event resetName
       * @private
       */
      resetName: function resetName() {
        Ember.set(this, 'name', '');
      },

      /**
       * Toggles `isNew` property and
       * updates `mailEnabled` property.
       *
       * @event toggleIsNew
       * @private
       */
      toggleIsNew: function toggleIsNew() {
        this.toggleProperty('isNew');

        this._updateMailEnabled();
      }
    }
  });

  _exports.default = _default;
});