define("@vollersgroup/hub-frontend/components/navigation-steps/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A numbered navigation with optional routing.
   *
   * ```html
   * {{navigation-steps
   *    steps=steps
   *    current=current
   *    onClick=(action "myAction")}}
   * ```
   *
   * @namespace Component
   * @class BulkProgressBar
   * @extends Ember
   * @public
   */
  var _default = Ember.Component.extend({
    classNames: ['navigation-steps'],
    router: Ember.inject.service(),
    //
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * Steps consisting of an array or hash
     * with objects containing `label` and
     * optional `disabled` and `route` attrs.
     *
     * @attribute steps
     * @type {Array|Object}
     * @public
     */
    steps: null,

    /**
     * Optional reference to current item.
     *
     * @attribute current
     * @type {Object}
     * @public
     */
    current: Ember.computed({
      set: function set(key, value, oldValue) {
        if (!Ember.isNone(oldValue)) {
          Ember.set(oldValue, 'active', false);
        }

        if (!Ember.isNone(value)) {
          Ember.set(value, 'active', true);
        }

        return value;
      },
      get: function get()
      /*key*/
      {
        return null;
      }
    }),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Delivers type information about `steps`.
     *
     * @property type
     * @type {String}
     * @public
     */
    type: Ember.computed('steps', {
      get: function get()
      /*key*/
      {
        return Ember.typeOf(Ember.get(this, 'steps'));
      }
    }),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Initializes `current` once.
     *
     * @public
     * @method init
     * @return {Void}
     */
    init: function init() {
      this._super.apply(this, arguments);

      var current = Ember.get(this, 'current');

      if (!Ember.isNone(current)) {
        Ember.run.once(this, this._postInit);
        return;
      }

      if (Ember.isNone(current)) {
        var steps = Ember.get(this, 'steps');

        switch (Ember.typeOf(steps)) {
          case 'object':
            // eslint-disable-next-line no-case-declarations
            var keys = Object.keys(steps);
            current = steps[keys[0]];
            break;

          case 'array':
            current = steps[0];
            break;

          default:
        }
      }

      Ember.set(this, 'current', current);
      Ember.run.once(this, this._postInit);
    },

    /**
     * Tries to route to `current`.
     *
     * @public
     * @method _postInit
     * @return {Void}
     */
    _postInit: function _postInit() {
      var route = Ember.get(this, 'current.route');

      if (Ember.isNone(route)) {
        return;
      }

      var router = Ember.get(this, 'router');
      router.transitionTo(route);
    },
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Handles `click` event of step element.
       *
       * @event onClick
       * @param {Object} step
       * @return {Void}
       * @private
       */
      onClick: function onClick(step) {
        var disabled = Ember.get(step, 'disabled');

        if (disabled) {
          return;
        }

        Ember.set(this, 'current', step);

        try {
          this.attrs.onClick(step);
        } catch (e) {
          /* noop */
        }
      }
    }
  });

  _exports.default = _default;
});