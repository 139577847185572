define("@vollersgroup/hub-frontend/components/prompt-buttons/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    isEditing: false,
    afterSave: function afterSave() {
      return null;
    },
    afterCancel: function afterCancel() {
      return null;
    },
    actions: {
      changeEditMode: function changeEditMode() {
        this.toggleProperty('isEditing');
      },
      save: function save() {
        this.afterSave();
        this.toggleProperty('isEditing');
      },
      cancel: function cancel() {
        this.afterCancel();
        this.toggleProperty('isEditing');
      }
    }
  });

  _exports.default = _default;
});