define("@vollersgroup/hub-frontend/components/shop-list-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Display a shop item
   *
   * ```html
   * {{shop-list-item shop=myShop}}
   * ```
   *
   * @namespace Component
   * @class ShopListItem
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    /**
     * @property tagName
     * @type String
     * @final
     * @private
     */
    tagName: '',

    /**
     * The shop to display.
     *
     * @attribute shop
     * @type Model.Shop
     */
    shop: null
  });

  _exports.default = _default;
});