define("@vollersgroup/hub-frontend/components/c-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4jEazoNb",
    "block": "{\"symbols\":[\"opt\"],\"statements\":[[7,\"select\"],[12,\"id\",[21,\"domId\"]],[12,\"required\",[21,\"required\"]],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"update\"],[[\"value\"],[\"target.value\"]]]],[12,\"name\",[21,\"domId\"]],[12,\"class\",[28,[[21,\"class\"],\" \",[27,\"if\",[[23,[\"hasError\"]],\"select--has-error\"],null]]]],[12,\"disabled\",[21,\"disabled\"]],[12,\"multiple\",[21,\"multiple\"]],[9],[0,\"\\n\\n\"],[4,\"unless\",[[23,[\"multiple\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"placeholder\"]]],null,{\"statements\":[[0,\"      \"],[7,\"option\"],[11,\"value\",\"\"],[9],[1,[21,\"placeholder\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[23,[\"parsedOptions\"]]],null,{\"statements\":[[0,\"    \"],[7,\"option\"],[12,\"value\",[22,1,[\"value\"]]],[12,\"hidden\",[22,1,[\"hidden\"]]],[12,\"selected\",[27,\"if\",[[22,1,[\"selected\"]],true,false],null]],[12,\"disabled\",[27,\"if\",[[27,\"and\",[[22,1,[\"disabled\"]],[27,\"not\",[[22,1,[\"selected\"]]],null]],null],true,false],null]],[9],[0,\"\\n        \"],[1,[27,\"if\",[[22,1,[\"label\"]],[22,1,[\"label\"]],[22,1,[\"value\"]]],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/c-select/template.hbs"
    }
  });

  _exports.default = _default;
});