define("@vollersgroup/hub-frontend/components/manage-steps/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Attribute

    /**
     * The current step.
     *
     * @attribute currentStep
     * @type Number
     */
    currentStep: 0,

    /**
     * An array of steps which are available in this form.
     * The objects should have the following format:
     *
     * ```js
     * {
     *      label: "Create shop",
     *       step: 1,
     *       isNew: true,
     *       subSteps: [
     *           {
     *               label: "Shop information",
     *               anchor: "#shop-manage-1"
     *           }
     *       }
     *}
     * ```
     *
     * The sub-steps are optional.
     *
     * @attribute steps
     * @type Array
     * @required
     */
    steps: Ember.computed(function () {
      return [];
    }),

    /**
     * The name of the action to call when the step is changed.
     * This action receives the nwe step as a single parameter.
     * It is only possible to move to a previous step.
     *
     * @attribute action
     * @type string
     * @required
     */
    action: null,
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      gotoStep: function gotoStep(step) {
        this.sendAction('action', step);
      },
      gotoSubStep: function gotoSubStep(e) {
        var el = e.target;

        if (el && el.getAttribute('href')) {
          var target = el.getAttribute('href');

          if (Ember.$(target).length) {
            Ember.$('html, body').animate({
              scrollTop: Ember.$(target).offset().top - 30
            }, 300);
          }
        }
      }
    },
    // -------------------------------------------------------------------------
    // Properties
    classNames: ['manage-steps'],
    remainingSteps: Ember.computed('currentStep', 'steps.[]', function () {
      var total = Ember.get(this, 'steps.length');
      var count = Ember.get(this, 'currentStep');
      return total - count;
    })
  });

  _exports.default = _default;
});