define("@vollersgroup/hub-frontend/components/filter-item-value/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h+Jd9PQR",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"component\",[[23,[\"subComponent\"]]],[[\"id\",\"translate\",\"searchable\",\"value\",\"action\",\"options\",\"placeholder\"],[[23,[\"id\"]],true,true,[23,[\"selectedValue\"]],[27,\"action\",[[22,0,[]],\"update\"],null],[27,\"mut\",[[23,[\"filter\",\"items\"]]],null],[27,\"t\",[\"Type to search\"],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/hub-frontend/components/filter-item-value/template.hbs"
    }
  });

  _exports.default = _default;
});