define("@vollersgroup/hub-frontend/admin/sales/list/coffee/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    parent: Ember.inject.controller('admin.sales.list'),
    ordersByCoffee: Ember.computed.reads('parent.ordersByCoffee'),
    showShippingAddress: Ember.computed.reads('parent.showShippingAddress')
  });

  _exports.default = _default;
});