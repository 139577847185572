define("@vollersgroup/hub-frontend/rating/adapter", ["exports", "@vollersgroup/hub-frontend/application/adapter"], function (_exports, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    userSession: Ember.inject.service(),
    isAuthenticated: Ember.computed.alias('userSession.session.isAuthenticated'),
    urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {
      if (!Ember.get(this, 'isAuthenticated')) {
        // Anonymous users can save rating only with proper listing encoded id.
        return this._super.apply(this, arguments) + '/' + snapshot.adapterOptions.listingEncId;
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});