define("@vollersgroup/hub-frontend/admin/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "@vollersgroup/hub-frontend/mixins/reset-scroll", "ember-can"], function (_exports, _authenticatedRouteMixin, _resetScroll, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _resetScroll.default, _emberCan.CanMixin, {
    userSession: Ember.inject.service(),
    model: function model() {
      var model = {
        upcomingAuctions: this.store.query('auction', {
          participating: true,
          status: ['ACTIVE', 'RUNNING'],
          sort: 'DATE',
          limit: 3
        }),
        recentOrders: [],
        recentSales: [],
        currentUser: this.get('userSession.currentUser'),
        currentCompany: this.get('userSession.currentCompany')
      };

      if (this.can('view order')) {
        model.recentOrders = this.store.query('order', {
          buyerCompany: this.get('userSession.currentCompany.id'),
          sort: 'DATE',
          status: ['OFFER', 'ACCEPTED', 'REQUEST_REJECTED', 'OFFER_CANCELED'],
          limit: 3
        });
        model.recentSales = this.store.query('order', {
          sellerCompany: this.get('userSession.currentCompany.id'),
          sort: 'DATE',
          status: ['REQUEST', 'ACCEPTED'],
          limit: 3
        });
      }

      return model;
    },
    _resetController: Ember.on('deactivate', function () {
      this.controllerFor(this.get('routeName')).set('_showUserSettingsForm', false);
      this.controllerFor(this.get('routeName')).notifyPropertyChange('showUserSettingsForm');
    })
  });

  _exports.default = _default;
});